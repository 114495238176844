import { t } from "i18next";

export default function StepName(props) {
  const { step, form, error } = props;

  return (
    step === 'nome' && (
      <div className="page__content fadeIn u-mb-5">
        <div className="new-questions__header">
          <h2 className="new-questions__title">{t('courses.buttonNewCourse')}</h2>
        </div>

        <div className="new-questions__body">
          <div className="form__row">
            <label
              className="form__label"
              htmlFor="title"
            >
              *{t('filter.courseName')}
            </label>
            <input
              className="form__control"
              placeholder={t('courses.placeholderCourseName')}
              id="title"
              name="title"
              type="text"
              value={form?.values?.title}
              onChange={e => form?.setFieldValue('title', e.target.value)}
            />
            {error && <span style={{ color: 'red' }}>{form?.errors?.title}</span>}
          </div>
        </div>
      </div>
    )
  );
}
