import React, { useState, useContext } from 'react';
import { SessionContext } from '../providers/SessionProvider';
import toast from 'react-hot-toast';
import { useClient, useMutation } from 'jsonapi-react';
import { FiThumbsUp, FiMessageCircle, FiTrash2 } from 'react-icons/fi';
import Swal from 'sweetalert2';
import getPermission from 'app/utils/getPermission';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';
import { Tooltip } from 'react-tooltip';
import { MdOutlinePushPin, MdPushPin } from 'react-icons/md';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';

function PostActions({ setPosts, deletePost, post: postParam }) {
  const { t } = useTranslation();
  const [post, setPost] = useState(postParam);
  const client = useClient();
  const [addUserInteraction] = useMutation('user_interactions');
  const state = useContext(SessionContext);
  const authorityLevel = getAuthorityLevel();
  const basePath = window.env.REACT_APP_BASE_PATH;
  const likeUnlikePost = e => {
    e.preventDefault();
    e.stopPropagation();
    if (post['like-id'] === null) {
      addUserInteraction({
        action: 'liked',
        'interactionable-type': 'Post',
        'interactionable-id': post.id
      })
        .then()
        .then(response => {
          if (response.error) {
            toast.error(response.error.title);
          } else {
            let newPost = post;
            newPost['likes-count'] += 1;
            newPost['like-id'] = response.data.id;
            setPost({ ...newPost });
          }
        })
        .catch(error => {
          toast.error(t('toast.error'));
          console.error(error);
        });
    } else {
      api
        .delete(`user_interactions/${post['like-id']}`)
        .then(response => {
          let newPost = post;
          newPost['likes-count'] -= 1;
          newPost['like-id'] = null;
          setPost({ ...newPost });
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  const handleFixPost = async () => {
    const { data, error } = await client.mutate(`posts/${post.id}`, {
      pinned: post.pinned ? false : true
    });

    if (error) {
      toast.error('Erro ao fixar postagem');
      return;
    }

    setPost(prevState => {
      return {
        ...prevState,
        pinned: post.pinned ? false : true
      };
    });

    if (!post.pinned) {
      setPosts(prev => [data, ...prev.filter(item => item.id !== data.id)]);
    }
  };

  return (
    <div className="post__actions">
      <Tooltip id="fixed-post" />
      <div className="post__action">
        {authorityLevel !== 'student' && (
          <div
            className="post__action-icon"
            style={{ width: 20 }}
            onClick={handleFixPost}
          >
            {post.pinned ? (
              <MdPushPin
                size={24}
                data-tooltip-id="fixed-post"
                data-tooltip-content="Desafixar"
              />
            ) : (
              <MdOutlinePushPin
                size={24}
                data-tooltip-id="fixed-post"
                data-tooltip-content="Fixar"
              />
            )}
          </div>
        )}
      </div>

      {state.session && state.session.user.id === post?.['user-id'] && (
        <div className="post__action">
          <div
            className="post__action-icon"
            onClick={event => {
              event.stopPropagation();
              Swal.fire({
                title: t('projects.warningDeleteCommentTitle'),
                text: t('projects.warningDeleteCannotBeUndoneText'),
                confirmButtonText: t('button.yes'),
                showDenyButton: true,
                denyButtonText: t('button.no'),
                showCloseButton: true
              }).then(res => {
                if (res.isConfirmed) {
                  deletePost(post);
                }
              });
            }}
          >
            <FiTrash2 />
          </div>
        </div>
      )}

      <div
        className="post__action"
        onClick={e => likeUnlikePost(e)}
      >
        {post['like-id'] === null ? (
          <div className="post__action-icon">
            <FiThumbsUp />
          </div>
        ) : (
          <div className="post__action-icon filled">
            <FiThumbsUp />
          </div>
        )}
        {post['likes-count'] > 0 && <div className="post__action-count">{post['likes-count']}</div>}
      </div>

      {getPermission('Visualizar postagens e comentários', 'Feed') && (
        <div
          className="post__action"
          onClick={event => {
            event.stopPropagation();
            navigate(`${basePath}/posts/${post.id}`);
          }}
        >
          <div className="post__action-icon">
            <FiMessageCircle />
          </div>
          {post['comments-count'] > 0 && <div className="post__action-count">{post['comments-count']}</div>}
        </div>
      )}
    </div>
  );
}

export default PostActions;
