import React, { useEffect, useState } from 'react';
import { Link, useParams } from '@reach/router';
import ActivityCard from './ActivityCard';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import Loader from 'app/components/loader';
import EmptyState from 'app/components/EmptyState';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { t } from 'i18next';

export default function TabActivities({ currentModuleId, courseId }) {
  const client = useClient();
  const [activities, setActivities] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [filteredActivities, setFilteredActivities] = useState();
  const basePath = window.env.REACT_APP_BASE_PATH;

  const hasSelectedModule = currentModuleId !== 'm';

  const getActivities = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`/activities?filter[learning_system_id]=${currentModuleId}`);
    if (error) {
    } else {
      setActivities(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (currentModuleId) {
      getActivities();
    }
  }, [currentModuleId]);

  useEffect(() => {
    const filteredActivities = activities.filter(activity => {
      if (searchTerm === '') {
        return true;
      }
      if (activity.title.toLowerCase().includes(searchTerm.toLowerCase())) {
        return true;
      }
    });

    setFilteredActivities(filteredActivities);
  }, [activities, searchTerm]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const updateActivitiesList = async values => {
    setLoading(true);
    const { destination, source } = values;
    if (!destination) {
      return null;
    }
    if (destination.index === source.index) {
      return null;
    }

    const dIndex = destination.index;
    const sIndex = source.index;
    const newItems = reorder(filteredActivities, sIndex, dIndex);

    const newActivitiesOrder = newItems.map((activity, index) => {
      return { id: activity.id, order: index };
    });

    const { error } = await client.mutate(
      ['learning_systems', currentModuleId, 'sort'],
      {
        activities: newActivitiesOrder
      },
      { method: 'PUT' }
    );
    if (error) {
      toast.error('Erro ao reordenar disciplinas!');
    } else {
      getActivities();
      toast.success('Disciplinas reordenadas com sucesso!');
    }
  };

  return (
    <DragDropContext onDragEnd={values => updateActivitiesList(values)}>
      <div className="tab__pane fadeIn">
        <div className="filter-bar">
          <div className="filter-bar__inner">
            <div className="filter-bar__row">
              <label
                htmlFor="search"
                className="filter-bar__label"
              >
                {t('textsCommon.search')}
              </label>
              <input
                className="form__control form__control--search-with-icon"
                placeholder={t('filter.searchActivity')}
                type="search"
                name="search"
                id="search"
                onChange={e => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          <div className="filter-bar__actions">
            <Link
              to={`${basePath}/atividades/criar/nome/${currentModuleId}/${courseId}`}
              className={hasSelectedModule ? 'btn btn--wide btn--primary' : 'btn btn--wide btn--primary disabled'}
            >
              {t('textsCommon.newActivity')}
            </Link>
          </div>
        </div>

        {loading && <Loader />}

        {!loading && filteredActivities?.length === 0 && hasSelectedModule && <EmptyState type="data" />}

        {!loading && !hasSelectedModule && (
          <EmptyState
            type="selectCard"
            text='Selecione um módulo para habilitar "Nova atividade".'
          />
        )}

        {!loading && (
          <Droppable droppableId="droppable">
            {provided => (
              <div
                className="round-dg-wrapper"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <table className="round-dg round-dg--light">
                  <tbody className="round-dg__body">
                    {filteredActivities?.length > 0 &&
                      filteredActivities?.map((activity, i) => (
                        <Draggable
                          key={i}
                          draggableId={activity.id.toString()}
                          index={i}
                        >
                          {provided => (
                            <tr
                              className="round-dg__row"
                              key={i}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <ActivityCard
                                setFilteredActivities={setFilteredActivities}
                                activity={activity}
                                courseId={courseId}
                              />
                            </tr>
                          )}
                        </Draggable>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </Droppable>
        )}
      </div>
    </DragDropContext>
  );
}
