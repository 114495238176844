import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from '@reach/router';
import { FiChevronDown, FiUsers } from 'react-icons/fi';
import { t } from 'i18next';
import { checkIncludeProfiles } from 'app/utils/getAuthorityLevel';
import Dropdown from 'app/components/Dropdown';
import moment from 'moment';
import { useAuditorium } from 'app/hooks/useAuditorium';
import { useFormik } from 'formik';
import IconVerified from 'app/components/Icons/IconVerified';
import { useSession } from 'app/hooks/useSession';
import getPermission from 'app/utils/getPermission';

export default function AuditoriumCard({ auditorium, setActiveAuditorium }) {
  const { deleteAuditorium, updateAuditorium } = useAuditorium();
  const isAdmin = !checkIncludeProfiles({ only: ['student'] });

  const currentUser = useSession().session.user;
  const basePath = window.env.REACT_APP_BASE_PATH;

  const auditoriumForm = useFormik({
    initialValues: {
      published: auditorium?.published
    }
  });

  const badgeColor = auditorium.status === 'Online' ? 'badge--success' : 'badge--warning';

  const handleChangePublishedAuditorium = async () => {
    auditoriumForm.setFieldValue('published', !auditoriumForm.values.published);
    await updateAuditorium({ published: !auditoriumForm.values.published }, auditorium.id);
  };

  const shouldRenderOptions = useMemo(() => {
    if (auditorium?.['authored-by-user'] === true) {
      if (auditorium?.creator?.id !== currentUser.id) {
        return false;
      } else return true;
    }

    if (auditorium?.['authored-by-user'] === false) {
      if (getPermission('Editar auditórios', 'Auditórios') || getPermission('Excluir auditórios', 'Auditórios')) {
        return true;
      } else return false;
    }
  }, [auditorium]);

  return (
    <div className="card">
      {isAdmin && (
        <div className="card__inner-header">
          <div
            className="form__check form__switch u-mb-0"
            style={{ flexGrow: '1' }}
          >
            <>
              <input
                className="form__check-input"
                id={`published-${auditorium.id}`}
                name={`published-${auditorium.id}`}
                type="checkbox"
                defaultChecked={auditoriumForm.values.published}
                onClick={handleChangePublishedAuditorium}
              />
              <label htmlFor={`published-${auditorium.id}`}>Publicar</label>
            </>
          </div>

          <span className="card__text-schedule">{auditorium['published-at'] && `Publicação agendada para ${moment(auditorium['published-at']).format('DD/MM/YYYY')}`}</span>
        </div>
      )}

      <div className="card__header">
        <div className="card__inner-header u-mb-1">
          {auditorium?.['official-content'] && <IconVerified />}

          <h2
            className="card__title"
            title="Nome do auditório"
          >
            {auditorium.name}
          </h2>
        </div>

        <p className="card__subtitle u-text-truncate">{auditorium?.['auditorium-lesson']?.name}</p>

        <div>
          <span className={`badge badge--tiny ${badgeColor}`}>{auditorium.status} </span>
        </div>
      </div>

      <div className="card__body">
        <div className="card__columns">
          <p
            className="card__count card__count--clickable"
            onClick={() => setActiveAuditorium(auditorium)}
          >
            <span className="card__count-label">{t('textsCommon.participants')}</span>
            <FiUsers className="card__icon" /> {auditorium?.['student-count']}
          </p>
          <p className="card__count">
            <span>{t('projects.updatedIn')}</span>
            {moment(auditorium?.['auditorium-lesson'] ? auditorium?.['auditorium-lesson']?.['updated-at'] : auditorium['updated-at']).format('DD/MM/YYYY')}
          </p>
        </div>
      </div>

      <div className="card__footer">
        {!checkIncludeProfiles({ only: ['student'] }) ? (
          <div
            className="btn-group btn-group--with-dropdown"
            role="group"
            aria-label="Ações"
          >
            <Link
              to={`${basePath}/auditorio/${auditorium.id}`}
              className="btn btn--outline btn--small"
            >
              {t('button.access')}
            </Link>
            {shouldRenderOptions && (
              <Dropdown className="btn--small btn btn--outline">
                <Dropdown.Toggle>
                  <FiChevronDown />
                </Dropdown.Toggle>

                <Dropdown.Content>
                  {getPermission('Editar auditórios', 'Auditórios') && <Dropdown.Item to={`${basePath}/auditorio/editar/nome/${auditorium.id}`}>{t('button.edit')}</Dropdown.Item>}
                  {getPermission('Excluir auditórios', 'Auditórios') && <Dropdown.Item onClick={() => deleteAuditorium(auditorium.id)}>{t('button.delete')}</Dropdown.Item>}
                </Dropdown.Content>
              </Dropdown>
            )}
          </div>
        ) : (
          <Link
            to={`${basePath}/auditorio/${auditorium.id}`}
            className="btn btn--outline btn--small"
          >
            {t('button.access')}
          </Link>
        )}
      </div>
    </div>
  );
}
