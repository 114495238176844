import React, { useEffect, useState } from 'react';
import { useParams } from '@reach/router';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';

import './globals.css';
import './styleguide.css';
import './style.css';

import check from './img/check.svg';
import iconDefaultUsers from './img/icon-default-users.svg';
import iconDefaultUser from './img/icon-default-user.svg';
import iconDefaultTrophy2 from './img/icon-default-trophy-2.svg';
import iconDefaultTrophy4 from './img/icon-default-trophy-4.svg';
import iconDefaultTrophy5 from './img/icon-default-trophy-5.svg';
import iconDefaultTrophy6 from './img/icon-default-trophy-6.svg';
import medal from '../img/medal.svg';
import { navigate } from '@reach/router';
import { FiChevronLeft } from 'react-icons/fi';
import { FiChevronDown } from 'react-icons/fi';
import { FiChevronUp } from 'react-icons/fi';
import MainSidebar from 'app/components/mainSidebar';
import MainNav from 'app/components/mainNav';

export default function SeducPiGRE() {
  const client = useClient();
  const [data, setData] = useState([]);
  const [fristItem, setFristItem] = useState(true);
  const [lastItem, setLastItem] = useState(true);
  const [regional, setRegional] = useState();
  const { questionBookId, greId } = useParams();
  const [questionBook, setQuestionBook] = useState();
  const [orderByNotes, setOrderByNotes] = useState();
  const [orderBySubscribers, setOrderBySubscribers] = useState();
  const [orderByPercentageSubscribers, setOrderByPercentageSubscriber] = useState();
  const [ordenedInstitutions, setOrdenedInstitutions] = useState(data.items ?? []);

  const getOpenQuestionBooks = async () => {
    const { data, error } = await client.fetch(`/question_books/${questionBookId}`);
    if (error) {
      toast.error('Falha ao carregar simulado');
    } else {
      setQuestionBook(data);
    }
  };

  const getData = async () => {
    const { data, error } = await client.fetch(`reports/user_registrations?filter[regional_id]=${greId}&question_book_id=${questionBookId}`);

    if (error) {
      toast.error('Falha ao carregar dados');
    } else {
      setData(data);
      setFristItem(data.items[0]);
      setLastItem(data.items[data.items.length - 1]);
      setRegional(data.items[0].regional);
      setOrdenedInstitutions(data.items);
    }
  };

  const getTrophyIcon = index => {
    switch (index) {
      case 0:
        return iconDefaultTrophy6;
      case 1:
        return iconDefaultTrophy5;
      case 2:
        return iconDefaultTrophy4;
      default:
        return iconDefaultTrophy2;
    }
  };

  useEffect(() => {
    getData();
    getOpenQuestionBooks();
  }, []);

  const handleChangeOrder = orderType => {
    let institutions = data?.items;
    switch (orderType) {
      case 'notes':
        setOrderBySubscribers(false);
        setOrderByPercentageSubscriber(false);
        if (orderByNotes) {
          setOrderByNotes(false);
          institutions = institutions?.sort((a, b) => a.average - b.average);
        } else {
          setOrderByNotes(true);
          institutions = institutions?.sort((a, b) => b.average - a.average);
        }
        setOrdenedInstitutions(institutions);
        break;
      case 'subscribers':
        setOrderByNotes(false);
        setOrderByPercentageSubscriber(false);
        if (orderBySubscribers) {
          setOrderBySubscribers(false);
          institutions = institutions?.sort((a, b) => a.studentsCount - b.studentsCount);
        } else {
          setOrderBySubscribers(true);
          institutions = institutions?.sort((a, b) => b.studentsCount - a.studentsCount);
        }
        setOrdenedInstitutions(institutions);
        break;
      case 'percentage':
        setOrderByNotes(false);
        setOrderBySubscribers(false);
        if (orderByPercentageSubscribers) {
          setOrderByPercentageSubscriber(false);
          institutions = institutions?.sort((a, b) => a.studentsPercentage - b.studentsPercentage);
        } else {
          setOrderByPercentageSubscriber(true);
          institutions = institutions?.sort((a, b) => b.studentsPercentage - a.studentsPercentage);
        }
        setOrdenedInstitutions(institutions);
        break;
    }
  };

  return (
    <div className="main-content">
      <div className="page">
        <div className="page__col">
          <div className="relatrio-geral-GRE">
            <div className="filter-bar">
              <div className="filter-bar__inner">
                <button
                  onClick={() => navigate(-1)}
                  className="filter-bar__back"
                  style={{ alignSelf: 'center' }}
                >
                  <span className="card__icon-wrapper">
                    <FiChevronLeft className="card__icon" />
                  </span>

                  <span className="filter-bar__back-container">
                    <span className="text-wrapper">Simulados e notas - {regional?.name}</span>
                  </span>
                </button>
              </div>
            </div>
            <h2
              className="text-wrapper"
              title="Relatório Geral"
            >
              {questionBook?.title}
            </h2>
            <p className="text-wrapper-3">Acompanhamento de cadastros e média</p>
            <div className="frame-2">
              <div className="frame-3">
                <div className="KPI">
                  <div className="frame-4">
                    <div className="tipo-de-notificaes">
                      <img
                        className="check"
                        src={check}
                      />
                    </div>
                    <div className="text-wrapper-3">Total de estudantes cadastrados</div>
                  </div>
                  <div className="text-wrapper-4">{data.totalStudentsCount}</div>
                </div>
                <div className="KPI-2">
                  <div className="frame-5">
                    <div className="tipo-de-notificaes">
                      <img
                        className="icon-default-users"
                        src={iconDefaultUsers}
                      />
                    </div>
                    <div className="text-wrapper-3">Lotação com maior número de cadastros</div>
                  </div>
                  <div className="text-wrapper-4">
                    {fristItem.name} - {fristItem.studentsCount} cadastros
                  </div>
                </div>
                <div className="KPI-2">
                  <div className="frame-5">
                    <div className="tipo-de-notificaes">
                      <img
                        className="icon-default-user"
                        src={iconDefaultUser}
                      />
                    </div>
                    <div className="text-wrapper-3">Lotação com menor número de cadastros</div>
                  </div>
                  <div className="text-wrapper-4">
                    {lastItem.name} - {lastItem.studentsCount} cadastros
                  </div>
                </div>
                <div className="KPI-2">
                  <div className="frame-5">
                    <div className="tipo-de-notificaes">
                      <img
                        className="icon-default-user"
                        src={medal}
                      />
                    </div>
                    <div className="text-wrapper-3">Média geral do último simulado</div>
                  </div>
                  <div className="text-wrapper-4">{data?.totalAverage}</div>
                </div>
              </div>
              <div className="frame-6">
                <div className="text-wrapper-5">Ranking das instituições</div>
                <div className="frame-7">
                  <div className="group-2">
                    <div className="escola-3">Posição</div>
                    <div className="escola">Instituição</div>
                    <div
                      onClick={() => handleChangeOrder('notes')}
                      className="escola-2"
                      style={{ paddingRight: 40 }}
                    >
                      <span style={{ paddingRight: 5 }}>Nota média</span>

                      {orderByNotes ? <FiChevronUp size={20} /> : <FiChevronDown size={20} />}
                    </div>
                    <div
                      className="escola"
                      onClick={() => handleChangeOrder('subscribers')}
                    >
                      <span>Cadastrados</span>

                      {orderBySubscribers ? <FiChevronUp size={20} /> : <FiChevronDown size={20} />}
                    </div>
                    <div
                      className="escola"
                      onClick={() => handleChangeOrder('percentage')}
                    >
                      <span>% do total de cadastros da secretaria</span>

                      {orderByPercentageSubscribers ? <FiChevronUp size={20} /> : <FiChevronDown size={20} />}
                    </div>
                  </div>
                  <div className="frame-8">
                    {ordenedInstitutions?.map((item, index) => (
                      <div
                        className="nome"
                        key={index}
                      >
                        <div className="frame-9">
                          <div className="frame-10">
                            <div className="ordem">{index + 1}</div>
                            <div className="nome-2">{item.name}</div>
                          </div>
                          <img
                            className="icon-default-trophy"
                            src={getTrophyIcon(index)}
                          />
                          <div
                            className="escola-4"
                            style={{ paddingRight: 30, marginLeft: 40 }}
                          >
                            {item.average.toFixed(2)}
                          </div>
                          <div className="escola-4">{item.studentsCount}</div>
                          <div className="nota">{item.studentsPercentage ? item.studentsPercentage.toString().replace('.', ',') : 0}%</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
