/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import { useFormik } from 'formik';

import ModalTaxonomies from '../../../components/modalTaxonomies';
import EmptyState from '../../../components/EmptyState';
import { UpdateQuestionBookContext } from './provider';
import getPermission from 'app/utils/getPermission';
import { useTranslation } from 'react-i18next';

export const ContentQuestion = () => {
  const { t } = useTranslation();
  const { selectedQuestion, firstLevelTaxonomies, updateQuestion } = useContext(UpdateQuestionBookContext);

  const [isOpenModalTaxonomies, setIsOpenModalTaxonomies] = useState(false);

  const updateQuestionForm = useFormik({
    initialValues: {
      published: false,
      description: '',
      'motivator-texts-attributes': [
        {
          title: '',
          body: ''
        }
      ],
      'alternatives-attributes': [],
      selectedTaxonomies: [],
      modality: '',
      isDraft: false
    },
    onSubmit: values => updateQuestion(values)
  });

  const { values, setFieldValue, handleChange, submitForm } = updateQuestionForm;

  useEffect(() => {
    if (selectedQuestion) {
      updateQuestionForm.setValues({
        published: selectedQuestion.published || true,
        description: selectedQuestion.description || '',
        modality: selectedQuestion.modality || '',
        'motivator-texts-attributes': [
          selectedQuestion['motivator-texts'][0] || {
            title: '',
            body: '',
            print: ''
          }
        ],
        'alternatives-attributes': selectedQuestion.alternatives.filter(a => a.description !== 'Deixar em branco'),
        selectedTaxonomies: selectedQuestion.taxonomies || [],
        'correct-alternative': selectedQuestion['correct-alternative'],
        isDraft: selectedQuestion.isDraft || false
      });
    }
  }, [selectedQuestion]);

  const disabled = !getPermission('Editar questões', 'Questões e apostilas');

  return (
    <div className="new-questions__content">
      {!selectedQuestion ? (
        <EmptyState
          type="select"
          text={t('emptyState.selectSubjectPerformanceText')}
        />
      ) : (
        <>
          <div className="new-questions__body">
            <h2 className="new-questions__subtitle">{selectedQuestion.id ? `Questão ${selectedQuestion.id}` : 'Rascunho'}</h2>

            {selectedQuestion.isDraft ? (
              <fieldset className="form__row">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Tipo"
                >
                  <input
                    disabled={disabled}
                    id="right_wrong"
                    name="right_wrong"
                    type="radio"
                    autoComplete="off"
                    checked={values.modality === 'right_wrong'}
                  />

                  <label
                    htmlFor="right_wrong"
                    className="btn btn--outline"
                    onClick={() => {
                      setFieldValue('modality', 'right_wrong');
                      setFieldValue('alternatives-attributes', [
                        {
                          id: 1,
                          name: '1',
                          description: 'Certo',
                          isNew: true
                        },
                        {
                          id: 2,
                          name: '2',
                          description: 'Errado',
                          isNew: true
                        }
                      ]);
                    }}
                  >
                    Certo ou errado
                  </label>

                  <input
                    id="multiple_choice"
                    name="multiple_choice"
                    type="radio"
                    autoComplete="off"
                    checked={values.modality === 'multiple_choice'}
                  />
                  <label
                    htmlFor="medio"
                    className="btn btn--outline"
                    onClick={() => {
                      setFieldValue('modality', 'multiple_choice');
                      setFieldValue('alternatives-attributes', []);
                    }}
                  >
                    {t('exams.selectMultipleChoice')}
                  </label>
                </div>
              </fieldset>
            ) : (
              <p>{selectedQuestion.modality === 'right_wrong' ? 'Certo ou errado' : 'Múltipla escolha'}</p>
            )}

            <div className="new-questions__page">
              <div className="new-questions__col">
                <div
                  action="#"
                  className="form fadeIn"
                >
                  <div className="form__row">
                    <div className="form__check form__switch">
                      <input
                        disabled={disabled}
                        className="form__check-input"
                        id="publish"
                        type="checkbox"
                        checked={values.published}
                        onClick={() => setFieldValue('published', !values.published)}
                      />
                      <label
                        className="form__check-label"
                        htmlFor="publish"
                      >
                        Publicar questão
                      </label>
                    </div>
                  </div>

                  <div className="form__row">
                    <label
                      className="form__label"
                      htmlFor="title"
                    >
                      {t('textsCommon.title')}
                    </label>
                    <input
                      disabled={disabled}
                      className="form__control"
                      id="title"
                      name="title"
                      type="text"
                      value={values['motivator-texts-attributes'][0].title}
                      onChange={e =>
                        setFieldValue('motivator-texts-attributes', [
                          {
                            ...values['motivator-texts-attributes'][0],
                            title: e.target.value
                          }
                        ])
                      }
                    />
                  </div>

                  <div className="form__row">
                    <label
                      className="form__label"
                      htmlFor="textoMotivador"
                    >
                      {t('exams.formTitleMotivatorText')}
                    </label>
                    <TextareaAutosize
                      disabled={disabled}
                      id="textoMotivador"
                      name="textoMotivador"
                      value={values['motivator-texts-attributes'][0].body}
                      onChange={e =>
                        setFieldValue('motivator-texts-attributes', [
                          {
                            ...values['motivator-texts-attributes'][0],
                            body: e.target.value
                          }
                        ])
                      }
                    />
                  </div>

                  <div className="form__row">
                    <label
                      className="form__label"
                      htmlFor="imprenta"
                    >
                      Imprenta
                    </label>
                    <input
                      disabled={disabled}
                      className="form__control"
                      id="imprenta"
                      name="imprenta"
                      type="text"
                      value={values['motivator-texts-attributes'][0].print}
                      onChange={e =>
                        setFieldValue('motivator-texts-attributes', [
                          {
                            ...values['motivator-texts-attributes'][0],
                            print: e.target.value
                          }
                        ])
                      }
                    />
                  </div>

                  <div className="form__row">
                    <label
                      className="form__label"
                      htmlFor="description"
                    >
                      Enunciado
                    </label>
                    <TextareaAutosize
                      disabled={disabled}
                      id="description"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                    />
                  </div>

                  <fieldset className="form__row">
                    <legend className="form__label">Alternativas (Escolha a alternativa correta)</legend>

                    {values['alternatives-attributes'].length > 0 &&
                      values['alternatives-attributes'].map((a, i) => (
                        <div className="form__check">
                          <input
                            disabled={disabled}
                            type="radio"
                            name="alternativa"
                            id={a.id}
                            className="form__check-input"
                            checked={values['correct-alternative'] && values['correct-alternative'].id === a.id}
                            onChange={e => {
                              if (e.target.value === 'on') {
                                setFieldValue('correct-alternative', values['alternatives-attributes'][i]);
                              }
                            }}
                          />
                          <span className="form__check-label">
                            <input
                              disabled={disabled}
                              type="text"
                              name="textoAlternativa1"
                              id="textoAlternativa1"
                              className="form__control form__control--small"
                              placeholder="Insira o texto da alternativa"
                              value={a.description}
                              onChange={e => {
                                const newAlternative = {
                                  ...values['alternatives-attributes'][i],
                                  description: e.target.value
                                };

                                const newAlternatives = [...values['alternatives-attributes']];
                                newAlternatives[i] = newAlternative;

                                setFieldValue('alternatives-attributes', newAlternatives);
                              }}
                            />
                          </span>
                        </div>
                      ))}

                    {values.modality === 'multiple_choice' && (
                      <button
                        disabled={disabled}
                        type="button"
                        className="btn btn--primary"
                        style={{ marginTop: 20 }}
                        onClick={() =>
                          setFieldValue('alternatives-attributes', [
                            ...values['alternatives-attributes'],
                            {
                              id: values['alternatives-attributes'][0] ? values['alternatives-attributes'].lastItem.id + 1 : 1,
                              is_correct: false,
                              name: '',
                              description: '',
                              isNew: true
                            }
                          ])
                        }
                      >
                        Adicionar alternativa
                      </button>
                    )}
                  </fieldset>

                  <fieldset className="form__row">
                    <legend className="form__label">{t('tasks.mattersRelated')}</legend>
                    {values.selectedTaxonomies.length > 0 &&
                      values.selectedTaxonomies.map(t => (
                        <div className="form__check form__check--inline">
                          <input
                            disabled={disabled}
                            className="form__check-input"
                            type="checkbox"
                            checked
                            onChange={() => {
                              const newSelectedTaxonomies = values.selectedTaxonomies.filter(t2 => t.id !== t2.id);

                              setFieldValue('selectedTaxonomies', newSelectedTaxonomies);
                            }}
                          />
                          <label className="form__check-label">{t.name}</label>
                        </div>
                      ))}
                    <br />
                    <button
                      disabled={disabled}
                      type="button"
                      className="btn btn--primary"
                      onClick={() => setIsOpenModalTaxonomies(true)}
                    >
                      {values.selectedTaxonomies.length > 0 ? 'Editar matérias' : 'Adicionar matérias'}
                    </button>
                  </fieldset>

                  <button
                    type="submit"
                    className="btn btn--primary btn--full"
                    onClick={() => submitForm()}
                    style={{ marginTop: 50 }}
                  >
                    {t('button.save')}
                  </button>
                </div>
              </div>

              <div className="new-questions__aside">
                <div className="new-questions__info">
                  <p>Questões com o mesmo texto motivador serão automáticamente agrupadas pelo sistema.</p>
                  <p>Tudo está sendo salvo, as alterações são salvas em backgound pelo sistema</p>
                  <p>
                    Para destacar uma citação nos textos e nas altarnativas use a tag: <br />
                    <b>{'<destaq></destaque>'}</b>
                    <br />
                    <br />
                    <b className="new-questions__tag-example">{'..<destaq> O rei de roma </destaque>...'}</b>
                  </p>
                </div>
              </div>

              {firstLevelTaxonomies.length > 0 && isOpenModalTaxonomies && (
                <ModalTaxonomies
                  taxonomies={firstLevelTaxonomies}
                  selectedTaxonomies={values.selectedTaxonomies}
                  setFieldValue={setFieldValue}
                  closeModal={() => setIsOpenModalTaxonomies(false)}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
