import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';

export default function StudentCard(props) {
  const client = useClient();
  const { student, classrooms, courses, id, getStudents } = props;
  const [studentStatus, setStudentStatus] = useState();
  const [sendExam, setSendExam] = useState(false);
  const basePath = window.env.REACT_APP_BASE_PATH;

  useEffect(() => {
    if (student['question-books-user']?.['answer-sheet'] === null) {
      return setStudentStatus('Realizado online');
    } else {
      setStudentStatus(student['question-books-user']?.['answer-sheet']?.['processing-status']);
    }
  }, [student]);

  const _handleReaderLoaded = async readerEvt => {
    try {
      let bynaryString = readerEvt.target.result;
      await client
        .mutate(`question_books/${id}/answer_sheets`, {
          url: `data:image/jpeg;base64,${btoa(bynaryString)}`,
          'question-books-user-attributes': {
            'user-id': student.id,
            'question-book-id': id
          }
        })
        .then(response => {
          toast.success('Prova enviada');
        });
    } catch {
      toast.error('Erro ao enviar prova');
    }
  };

  async function handleSendExam(e) {
    const toastLoading = toast.loading('Enviando');
    setSendExam(true);
    try {
      let file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        reader.readAsBinaryString(file);
      }
    } catch {
      toast.error('Erro ao enviar prova');
    }
    toast.dismiss(toastLoading);
    getStudents();
    setSendExam(false);
  }

  const getStudentCourses = () => {
    return courses.filter(course => student?.['course-ids'].includes(course.id));
  };

  const getStudentClassrooms = () => {
    return classrooms.filter(classroom => student?.['classroom-ids'].includes(classroom.id));
  };
  const studentClassrooms = getStudentClassrooms();
  const studentCourses = getStudentCourses();

  const examDownloadUrl = student?.['question-books-user']?.['answer-sheet']?.url;

  //pendente até descobrir os outros estados
  function getStatus(status) {
    switch (status) {
      case 'processing':
        return { text: 'Processando', badgeClass: 'warning', buttonStatus: 'disabled' };
      case 'completed':
        return { text: 'Concluido', badgeClass: 'success', buttonStatus: 'disabled' };
      case 'failed':
        return { text: 'Erro ao processar', badgeClass: 'danger', buttonStatus: '' };
      case 'Realizado online':
        return { text: 'Realizado online', badgeClass: 'success', buttonStatus: 'disabled' };
      default:
        return { text: 'Ainda não realizada', badgeClass: 'primary', buttonStatus: '' };
    }
  }

  return (
    <tr className="round-dg__row">
      <th className="round-dg__cell-header round-dg__cell-header--row">
        <div
          className="round-dg__user u-cursor-pointer"
          onClick={() => navigate(`${basePath}/usuario/metricas/${student?.id}`, { state: { isRedirected: true } })}
        >
          <img
            className="avatar avatar--sm"
            src={student.image.url}
            alt="Imagem do usuário"
          />
          <div className="round-dg__inner-user">
            <h3 className="round-dg__title">{student.name.length > 15 ? `${student.name.slice(0, 15)}...` : student.name}</h3>
          </div>
        </div>
      </th>
      <td className="round-dg__cell">{studentCourses.length > 0 ? studentCourses.map(course => course.title).join(', ') : 'Nenhum curso'}</td>
      <td className="round-dg__cell">{studentClassrooms.length > 0 ? studentClassrooms.map(classroom => classroom.title).join(', ') : 'Nenhuma turma'}</td>
      <td className="round-dg__cell">
        <span className={`badge badge--tiny badge--${getStatus(studentStatus).badgeClass}`}>{getStatus(studentStatus).text}</span>
      </td>
      <td className="round-dg__cell">
        <label
          className={sendExam || getStatus(studentStatus).buttonStatus === 'disabled' ? 'btn btn--tiny btn--full btn--outline disabled' : 'btn btn--tiny btn--full btn--outline '}
          style={{ pointerEvents: sendExam || getStatus(studentStatus).buttonStatus === 'disabled' ? 'none' : 'all' }}
        >
          <input
            type="file"
            name="file"
            id="file"
            style={{ display: 'none' }}
            onChange={e => {
              handleSendExam(e);
            }}
          />
          Enviar prova offline
        </label>
      </td>
      <td className="round-dg__cell">
        <a
          href={student?.['question-books-user']?.['answer-sheet']?.url}
          target="_blank"
          download
          rel="noopener noreferrer"
          className={!examDownloadUrl ? 'btn btn--small btn--outline disabled' : 'btn btn--small btn--outline'}
        >
          Baixar
        </a>
      </td>
    </tr>
  );
}
