import React, { useState } from 'react';
import { useFormik } from 'formik';
import { navigate } from '@reach/router';
import DateRangePicker from '@wojtekmaj/react-daterange-picker/dist/DateRangePicker';
import { useClient } from 'jsonapi-react';
import { useSession } from '../../hooks/useSession';
import toast from 'react-hot-toast';
import Loader from '../../components/loader';
import { useTranslation } from 'react-i18next';

export const QuestionBookForm = ({ relationshipsLoading, relationships, currentQuestionBook }) => {
  const { t } = useTranslation();
  const client = useClient();
  const { session } = useSession();
  const [selectedAlternative, setSelectedAlternative] = useState(0);
  const basePath = window.env.REACT_APP_BASE_PATH;

  const profiles = session.user?.profileNames || (session.user && session.user['profile-names']) || [];
  const notebookAlternatives = profiles.includes('Professor')
    ? [
        { id: 1, name: 'Turma' },
        { id: 2, name: 'Material' }
      ]
    : [
        { id: 0, name: 'Curso' },
        { id: 1, name: 'Turma' },
        { id: 2, name: 'Material' }
      ];

  const formQuestionBook =
    currentQuestionBook != null
      ? useFormik({
          initialValues: {
            title: currentQuestionBook.title,
            'resolution-time': currentQuestionBook['resolution-time'],
            'published-at': currentQuestionBook['published-at'],
            'finished-at': currentQuestionBook['finished-at'],
            'can-publish': currentQuestionBook['can-publish'],
            'course-ids': currentQuestionBook['course-ids'],
            'classroom-ids': currentQuestionBook['classroom-ids'],
            'content-ids': currentQuestionBook['content-ids']
          },
          onSubmit: values => {
            updateQuestionBook({ ...values });
          }
        })
      : useFormik({
          initialValues: {
            title: '',
            'resolution-time': '',
            'published-at': new Date(),
            'finished-at': new Date(),
            'can-publish': false,
            'course-ids': [],
            'classroom-ids': [],
            'content-ids': []
          },
          onSubmit: values => {
            createQuestionBook({ ...values });
          }
        });

  const createQuestionBook = async form => {
    const { error, data } = await client.mutate('question_books', form);

    if (error) {
      toast.error(t('toast.errorCreateQuestionBook'));
    } else {
      navigate(`${basePath}/ementa/cadernos-de-questoes/${data.id}`);
    }
  };

  const updateQuestionBook = async form => {
    const { data, error } = await client.mutate(['question_books', currentQuestionBook.id], form);

    if (error) {
      toast.error('Erro ao editar cadernos de questões.');
    } else {
      navigate(`${basePath}/ementa/cadernos-de-questoes/${data.id}`);
    }
  };

  return (
    <>
      {formQuestionBook && (
        <form
          method="post"
          onSubmit={formQuestionBook.submitForm}
        >
          <h2 className="form__title">{`${currentQuestionBook ? 'Editar' : 'Criar uma nova'} apostila`}</h2>

          {relationshipsLoading ? (
            <Loader />
          ) : (
            <>
              <div className="form__row">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Escolha o tipo de caderno"
                >
                  {notebookAlternatives.map((alternative, index) => (
                    <React.Fragment key={index}>
                      <input
                        type="radio"
                        name="notebookType"
                        id={alternative.id}
                        autoComplete="off"
                        defaultChecked={index === selectedAlternative}
                        onChange={() => {
                          if (alternative.name === 'Curso') {
                            formQuestionBook.setFieldValue('classroom-ids', []);
                            formQuestionBook.setFieldValue('content-ids', []);
                          }

                          if (alternative.name === 'Turma') {
                            formQuestionBook.setFieldValue('course-ids', []);
                            formQuestionBook.setFieldValue('content-ids', []);
                          }

                          if (alternative.name === 'Material') {
                            formQuestionBook.setFieldValue('classroom-ids', []);
                            formQuestionBook.setFieldValue('course-ids', []);
                          }
                          setSelectedAlternative(alternative.id);
                        }}
                      />
                      <label
                        htmlFor={alternative.id}
                        className="btn btn--outline"
                      >
                        {alternative.name}
                      </label>
                    </React.Fragment>
                  ))}
                </div>
              </div>

              {selectedAlternative === 0 && (
                <div className="form__row form__row--hr">
                  <select
                    className="form__select"
                    name="courses"
                    id="courses"
                    aria-label="Escolha o curso"
                    value={formQuestionBook.values['course-ids'][0]}
                    onChange={e => formQuestionBook.setFieldValue('course-ids', [e.target.value])}
                  >
                    <option>{t('filter.labelSelectTheCourse')}</option>
                    {relationships.courses.map(c => (
                      <option value={c.id}>{c.title}</option>
                    ))}
                  </select>
                </div>
              )}

              {selectedAlternative === 1 && (
                <div className="form__row form__row--hr">
                  <select
                    className="form__select"
                    name="classrooms"
                    id="classrooms"
                    aria-label="Escolha a turma"
                    value={formQuestionBook.values['classroom-ids'][0]}
                    onChange={e => formQuestionBook.setFieldValue('classroom-ids', [e.target.value])}
                  >
                    <option>{t('filter.labelSelectTheClassroom')}</option>
                    {relationships.classrooms.map(c => (
                      <option value={c.id}>{c.title}</option>
                    ))}
                  </select>
                </div>
              )}

              {selectedAlternative === 2 && (
                <div className="form__row form__row--hr">
                  <select
                    className="form__select"
                    name="contents"
                    id="contents"
                    aria-label="Escolha o material"
                    value={formQuestionBook.values['content-ids'][0]}
                    onChange={e => formQuestionBook.setFieldValue('content-ids', [e.target.value])}
                  >
                    <option>Selecione o material</option>
                    {relationships.contents.map(c => (
                      <option value={c.id}>{c.title}</option>
                    ))}
                  </select>
                </div>
              )}

              <div className="form__row">
                <label
                  className="form__label"
                  htmlFor="title"
                >
                  Título da apostila
                </label>
                <input
                  className="form__control"
                  id="title"
                  name="title"
                  type="text"
                  placeholder="Digite o título da apostila"
                  value={formQuestionBook.values.title}
                  onChange={formQuestionBook.handleChange}
                />
              </div>

              <div className="form__row">
                <label
                  className="form__label"
                  htmlFor="limitTime"
                >
                  Tempo limite para resolução
                </label>
                <select
                  className="form__select"
                  name="resolution-time"
                  id="resolution-time"
                  value={formQuestionBook.values['resolution-time']}
                  onChange={formQuestionBook.handleChange}
                >
                  <option value="">Sem tempo limite</option>
                  <option value="01:00">1 {t('tasks.hour')}</option>
                  <option value="02:00">2 {t('tasks.hours')}</option>
                  <option value="03:00">3 {t('tasks.hours')}</option>
                </select>
              </div>

              <div className="form__row">
                <label
                  className="form__label"
                  htmlFor="availablePeriod"
                >
                  {t('nps.resolutionPeriod')}
                </label>
                <DateRangePicker
                  id="availablePeriod"
                  style={{ width: '100%' }}
                  clearIcon={null}
                  format="dd-MM-y"
                  minDate={new Date()}
                  value={[formQuestionBook.values['published-at'], formQuestionBook.values['finished-at']]}
                  onChange={values => {
                    formQuestionBook.setFieldValue('published-at', values[0]);
                    formQuestionBook.setFieldValue('finished-at', values[1]);
                  }}
                />
              </div>

              <div className="form__row">
                <div className="form__check">
                  <input
                    id="can-publish"
                    name="can-publish"
                    type="checkbox"
                    className="form__check-input"
                    defaultChecked={formQuestionBook.values['can-publish']}
                    onChange={formQuestionBook.handleChange}
                  />
                  <label
                    htmlFor="can-publish"
                    className="form__check-label"
                  >
                    Tornar público depois de vencido?
                  </label>
                </div>
              </div>

              <button
                type="button"
                className="btn btn--primary btn--wide"
                onClick={formQuestionBook.submitForm}
              >
                {t('button.save')}
              </button>
            </>
          )}
        </form>
      )}
    </>
  );
};
