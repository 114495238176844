import React from 'react';
import { Link } from '@reach/router';

export default function Submenu({ data }) {
  return (
    <div className="submenu">
      <div className="submenu__header">
        <img
          className="submenu__img"
          src={data?.img}
          alt=""
        />
        <h3 className="submenu__title">{data?.title}</h3>
        <p className="submenu__subtitle">{data?.subtitle}</p>
      </div>

      <div className="submenu__body">
        <nav className="submenu__nav">
          <ul className="submenu__list">
            {data?.nav
              ?.filter(item => item?.visible)
              .map((item, key) => (
                <li
                  className="submenu__item"
                  key={key}
                >
                  <Link
                    className="submenu__link"
                    to={item?.route}
                  >
                    {item?.icon}
                    <span className="submenu__link-content">
                      <span className="submenu__link-title">{item?.title}</span>
                      <span className="submenu__link-description">{item?.description}</span>
                    </span>
                  </Link>
                </li>
              ))}
          </ul>
        </nav>
      </div>
    </div>
  );
}
