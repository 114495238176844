import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FiHelpCircle, FiCalendar } from 'react-icons/fi';
import { ReactComponent as UserAvatar } from '../images/user-avatar.svg';
import { useSession } from '../hooks/useSession';
import 'moment/locale/pt';
import Notes from './Notes';
import Notifications from './Notifications';
import getPermission from '../utils/getPermission';
import Medal from './Medal';
import { useClient } from 'jsonapi-react';
import getAuthorityLevel, { checkIncludeProfiles } from 'app/utils/getAuthorityLevel';
import { Link, navigate } from '@reach/router';
import DropdownMessages from './DropdownMessages';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import Dropdown from 'app/components/Dropdown';
import Search from './Search';
import Select from 'react-select';

function MainNav() {
  const client = useClient();
  const [medals, setMedals] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const { session, connections, dbNumber, ssoToken } = useSession();
  const isAdmin = !checkIncludeProfiles({ only: ['student'] });
  const basePath = window.env.REACT_APP_BASE_PATH;

  const platformSupportUrl = window.env.REACT_APP_SUPPORT_LINK;
  const appName = window.env.REACT_APP_NAME;

  let supportUrl = '';

  switch (appName) {
    case 'maiseducacao':
      supportUrl = 'https://suportemaisformacao.zendesk.com/hc/pt-br/requests/new';
      break;
    case 'canaleducacao':
      supportUrl = 'https://suportecanaleducacao.zendesk.com/hc/pt-br/requests/new';
      break;
    case 'egepi':
      supportUrl = 'https://escoladegoverno.zendesk.com/hc/pt-br/requests/new';
      break;
    default:
      supportUrl = 'https://wa.me/5511978432586';
  }

  const getMedals = async () => {
    setLoading(true);
    const { data, error } = await client.fetch('medals');
    if (error) {
      toast.error(t('toast.errorMedals'));
    } else {
      setMedals(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    i18n.changeLanguage(window.env.REACT_APP_LOCALE);
    if (!isAdmin) {
      getMedals();
    }
  }, []);

  const authorityLevel = getAuthorityLevel();
  const sso = window.env.REACT_APP_SSO_API_URL;
  const filteredConnections = connections?.filter(item => item.db_number !== dbNumber);
  const currentConnection = connections?.find(item => item.db_number == dbNumber);

  const handleSelectConnection = async select => {
    if (select && select.value && select.label) {
      const { value: dbNumber, host_url: host_url } = select;

      const redirectURL = `${host_url}/login-callback?token=${ssoToken}&db_number=${dbNumber}`;
      navigate(redirectURL);
    }
  };

  return (
    <div className="main-nav">
      <div className="main-nav__user">
        {/* {session?.user?.image?.url ? (
          <img
            className="main-nav__user-avatar"
            src={session?.user?.image?.url}
            alt="foto do usuário"
          />
        ) : (
          <UserAvatar className="main-nav__user-avatar" />
        )}

        <div className="main-nav__user-body">
          <div className="main-nav__user-name"> {session && session.user?.name} </div>

          <div className="main-nav__user-day">
            <div className="main-nav__user-day-icon">
              <FiCalendar />{' '}
            </div>
            <div className="main-nav__user-day-date">{moment().format(t('datesForm.formatDate'))}</div>
          </div>
        </div>
        <Link to={`${basePath}/usuario/medalhas/${session?.user.id}`}>{authorityLevel === 'student' && medals.length > 0 && !loading ? <Medal value={medals?.length} /> : null}</Link> */}
      </div>

      <div className="main-nav__links">
        {sso && connections?.length > 1 && (
          <Select
            value={[]}
            options={[
              ...(filteredConnections?.map(connection => ({
                value: connection?.db_number,
                label: `Ir para o ${connection?.name}`,
                host_url: connection?.host_url
              })) || [])
            ]}
            className="react-multi-select filter-bar__multi-select"
            classNamePrefix="react-multi-select"
            placeholder={connections?.length === 0 ? t('loader.loading2') : `Você está no ${currentConnection?.name}`}
            noOptionsMessage={() => 'Sem opções'}
            onChange={handleSelectConnection}
          />
        )}

        <Search />

        {/* <Dropdown>
          <Dropdown.Toggle style={{ display: 'flex', alignItems: 'center', gap: '4px', color: 'var(--color-primary)', padding: '0' }}>
            <FiHelpCircle
              className="notifications__toggle"
              style={{ stroke: 'var(--color-primary)' }}
            />
            <div className="main-nav__help-text">{t('header.help')}</div>
          </Dropdown.Toggle>
          <Dropdown.Content>
            <Dropdown.Item>
              <a
                target="blank"
                href={platformSupportUrl}
              >
                Perguntas frequentes
              </a>
            </Dropdown.Item>
            <a
              target="blank"
              href={supportUrl}
            >
              <Dropdown.Item>Falar com suporte</Dropdown.Item>
            </a>
          </Dropdown.Content>
        </Dropdown> */}

        <DropdownMessages />
        <Notifications />
      </div>

      {getPermission('Criar anotações', 'Anotações') || getPermission('Editar anotações', 'Anotações') || getPermission('Excluir anotações', 'Anotações') || getPermission('Visualizar anotações', 'Anotações') ? <Notes /> : null}
    </div>
  );
}

export default MainNav;
