import { t } from 'i18next';
import { useEffect } from 'react';
import TextareaAutosize from 'react-autosize-textarea/lib';

const NameStep = props => {
  const { values, setFieldValue, createModuleForm, currentCourse, setDeadlineError, deadlineError, submitted } = props;

  useEffect(() => {
    if (values?.['finished-at']) {
      setDeadlineError(new Date(values?.['finished-at']) >= new Date(currentCourse?.['finished-at']));
    }
    if (values?.['finished-at'] === '') {
      setDeadlineError(true);
    }
  }, [values?.['finished-at']]);

  const courseDeadline = new Date(currentCourse?.['finished-at']).toLocaleDateString();

  return (
    <div className="fadeIn">
      <div className="form__row">
        <label
          className="form__label"
          htmlFor="title"
        >
          * {t('lms.moduleName')}
        </label>
        <input
          className="form__control"
          placeholder={t('lms.placeholderModuleName')}
          id="title"
          name="title"
          type="text"
          value={values.title}
          onChange={e => setFieldValue('title', e.target.value)}
        />
        {createModuleForm.touched.title && createModuleForm.errors.title && <span style={{ color: 'red' }}>{createModuleForm.errors.title}</span>}
      </div>

      <div className="form__row">
        <label
          className="form__label"
          htmlFor="shortDescription"
        >
          * {t('lms.shortDescription')}
        </label>
        <input
          className="form__control"
          placeholder={t('lms.placeholderShortDescription')}
          id="shortDescription"
          name="shortDescription"
          type="text"
          value={values.shortDescription}
          onChange={e => setFieldValue('shortDescription', e.target.value)}
        />
        {createModuleForm.touched.shortDescription && createModuleForm.errors.shortDescription && <span style={{ color: 'red' }}>{createModuleForm.errors.shortDescription}</span>}
      </div>
      <div className="form__row">
        <label
          className="form__label"
          htmlFor="description"
        >
          * {t('lms.aboutModule')}
        </label>

        <TextareaAutosize
          placeholder={t('lms.placeholderAboutModule')}
          id="description"
          name="description"
          value={values.description}
          onChange={e => setFieldValue('description', e.target.value)}
        />
        {createModuleForm.touched.description && createModuleForm.errors.description && <span style={{ color: 'red' }}>{createModuleForm.errors.description}</span>}
      </div>

      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 15 }}>
        <div className="form__row">
          <label
            className="form__label"
            htmlFor="workload"
          >
            {t('lms.workload')}
          </label>

          <input
            className="form__control"
            placeholder={t('lms.typeAnumber')}
            id="workload"
            name="workload"
            type="text"
            maxLength={5}
            value={values.workload}
            onChange={e => setFieldValue('workload', e.target.value.replace(/[^\d]/g, ''))}
          />
        </div>

        <div className="form__row">
          <label
            className="form__label"
            htmlFor="order"
          >
            ** Ordem do módulo
          </label>

          <input
            className="form__control"
            placeholder={t('lms.typeAnumber')}
            id="order"
            name="order"
            type="text"
            value={values.order}
            onChange={e => setFieldValue('order', e.target.value.replace(/[^\d]/g, ''))}
          />
        </div>

        <div className="form__row">
          <label
            className="form__label"
            htmlFor="finished-at"
          >
            *Selecione uma data para a conclusão do módulo
          </label>
          <input
            className="form__control"
            placeholder={t('lms.typeAnumber')}
            id="finished-at"
            name="finished-at"
            type="date"
            value={values['finished-at']}
            onChange={e => setFieldValue('finished-at', e.target.value)}
          />
          {submitted && deadlineError ? <p style={{ color: 'red' }}>Os módulos devem ser concluídos antes da data final do curso em {courseDeadline}</p> : ''}
        </div>
      </div>
    </div>
  );
};

export default NameStep;
