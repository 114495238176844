import React, { useEffect, useState } from 'react';
import Modal from 'app/components/Modal';
import StarRating from 'app/components/StarRating';
import { AvatarStack } from 'app/components/Avatar';
import InfoWidget from './InfoWidget';
import { toast } from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import { t } from 'i18next';

export default function PreVisualizeModal(props) {
  const { show, onClose, src, form, courseId } = props;

  const client = useClient();
  const [detailedCourse, setDetailedCourse] = useState();
  const [managers, setManagers] = useState([]);
  const [students, setStudents] = useState([]);

  const getCourseDetails = async () => {
    const { data, error } = await client.fetch(`/courses/${courseId}?detailed=true`);
    if (error) {
      toast.error('Erro ao carregar dados do curso');
    } else {
      setDetailedCourse(data);
      const users = data.users;
      const managers = users.filter(item => !item['profile-names'].includes('Aluno'));
      const students = users.filter(item => item['profile-names'].includes('Aluno'));

      setManagers(managers);
      setStudents(students);
    }
  };

  useEffect(() => {
    if (courseId) {
      getCourseDetails();
    }
  }, []);

  return (
    <Modal
      show={show}
      onClose={onClose}
      contentClassName="modal__content--md"
    >
      <div
        className="course-about"
        style={{ boxShadow: 'none', padding: '0' }}
      >
        <div
          className="show-course-banner"
          style={{
            backgroundImage: `url(${src})`
          }}
        >
          <div className="show-course-banner__inner">
            <div className="show-course-banner__container">
              <span className="badge badge--tiny badge--primary">{form?.values?.knowledge}</span>
              <div className="show-course-banner__title">{form?.values?.title}</div>
              <div className="show-course-banner__description">{form?.values?.about}</div>
            </div>
          </div>
        </div>

        <div className="course-about__header">
          <div className="course-about__container-header">
            <div className="course-about__inner-header">
              <div className="course-about__infos">
                <div className="course-about__rating">
                  <StarRating
                    staticRating
                    rating={courseId ? detailedCourse?.rating : 0}
                  />
                  <p className="course-about__rating-text">
                    <strong>{courseId ? parseFloat(detailedCourse?.rating).toFixed(2) : 0}</strong> ({courseId ? detailedCourse?.['total-reviews'] : 0} {t('courses.evaluations')})
                  </p>
                </div>
              </div>
            </div>

            <div className="course-about__participants">
              <div className="card__avatar">
                <h4 className="card__avatar-title">{t('courses.managers')} ({courseId ? managers?.length : 0})</h4>
                {managers?.length > 0 && (
                  <AvatarStack
                    data={managers}
                    modalTitle={t('projects.titleProjectManagers')}
                  />
                )}
              </div>
              <div className="card__avatar">
                <h4 className="card__avatar-title">{t('projects.titleStudents')} ({courseId ? students?.length : 0})</h4>
                {students?.length > 0 && (
                  <AvatarStack
                    data={students}
                    modalTitle={t('projects.titleProjectStudents')}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <InfoWidget course={detailedCourse} />
      </div>
    </Modal>
  );
}
