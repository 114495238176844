import React, { useState } from 'react';
import api from '../services/api.js';
import { useMutation, useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

function QuestionCardOpen(props) {
  const { t } = useTranslation();
  const { question, updateLastAnswerSubmited, questionBooksUser, disabledSaveAnswer, activeAnswer } = props;
  const client = useClient();
  const [answer, setAnswer] = useState(activeAnswer);
  const [addAnswer] = useMutation('answers');

  const submitAnswer = alternativeId => {
    if (disabledSaveAnswer) {
      return false;
    }

    if (alternativeId !== null) {
      if (!answer) {
        addAnswer({
          'alternative-id': alternativeId,
          'question-id': question.id,
          'question-books-user-id': questionBooksUser?.id
        })
          .then(response => {
            if (response.error) {
              toast.error(response.error.title);
            } else {
              const newAnswer = {
                id: response.data.id,
                alternativeId: response.data['alternative-id'],
                questionId: response.data['question-id']
              };
              setAnswer(newAnswer);
              updateLastAnswerSubmited(newAnswer);
            }
          })
          .catch(error => {
            toast.error(t('toast.error'));
            console.error(error);
          });
      } else {
        client
          .mutate(['answers', answer.id], {
            'alternative-id': alternativeId,
            'question-id': question.id,
            'question-books-user-id': questionBooksUser?.id
          })
          .then(response => {
            if (response.error) {
              toast.error(response.error.title);
            } else {
              const newAnswer = {
                id: response.data.id,
                alternativeId: response.data['alternative-id'],
                questionId: response.data['question-id']
              };

              setAnswer(newAnswer);
              updateLastAnswerSubmited(newAnswer);
            }
          })
          .catch(error => {
            toast.error(t('toast.error'));
            console.error(error);
          });
      }
    } else {
      toast.error(t('toast.errorSelectAlternative'));
    }
  };

  return (
    <>
      <div
        className="question-card__statement"
        dangerouslySetInnerHTML={{ __html: question.description }}
      />
      <div className="question-card__answers">
        {question.alternatives &&
          _.sortBy(question.alternatives, ['name']).map((alternative, index) => (
            <div
              className="question-card__answer"
              key={alternative.id}
            >
              <div className="question-card__answer-letter">{alternative.name}</div>
              {disabledSaveAnswer ? (
                <input
                  type="radio"
                  id={alternative.id}
                  checked={answer && answer.alternativeId === alternative.id}
                />
              ) : (
                <input
                  type="radio"
                  id={alternative.id}
                  checked={answer && answer.alternativeId === alternative.id}
                  onChange={e => submitAnswer(alternative.id)}
                />
              )}

              <label
                htmlFor={alternative.id}
                dangerouslySetInnerHTML={{ __html: alternative.description }}
              />
            </div>
          ))}
      </div>
    </>
  );
}

export default QuestionCardOpen;
