import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useClient } from 'jsonapi-react';
import TabNavBar from '../TabNavBar';
import { checkIncludeProfiles } from 'app/utils/getAuthorityLevel';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import EmptyState from 'app/components/EmptyState';
import CertificateModal from './CertificateModal';
import { t } from 'i18next';
import Loader from 'app/components/loader';
import { FiPrinter } from 'react-icons/fi';
import toast from 'react-hot-toast';
import moreMedal from 'app/images/more-medal.svg';
import courseMedal from 'app/images/course-medal.svg';

export function CourseCertificate() {
  const [relationships, setRelationships] = useState({
    courses: []
  });
  const [showModal, setShowModal] = useState(false);
  const [certificates, setCertificates] = useState([]);
  const [currentCertificate, setCurrentCertificate] = useState();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const client = useClient();

  const filters = useFormik({
    initialValues: {
      course: 'blank',
      type: 'blank'
    }
  });

  const options = [
    { name: 'Curso', id: 1 },
    { name: 'Módulo', id: 2 }
  ];

  const filterBySearch = certificates.filter(
    item => searchTerm === '' || item?.['learning-system']?.title?.toLowerCase().includes(searchTerm?.toLowerCase()) || item?.['learning-system']?.course?.title?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const filterByType = filterBySearch.filter(item => {
    if (filters.values.type === 'blank') {
      return true;
    }

    if (filters.values.type == 1) {
      return item?.['certificate-type'] === 'Curso';
    }

    if (filters.values.type == 2) {
      return item?.['certificate-type'] === 'Módulo';
    }
  });

  const certificatesFilteredByCourse = filterByType.filter(item => filters.values.course === 'blank' || item?.['learning-system']?.course?.id == filters.values.course);

  const getRelationships = async () => {
    const { data: courses } = await client.fetch('courses');
    setRelationships({
      courses: courses || []
    });
  };

  const getCertificates = async () => {
    setLoading(true);
    const { data, error } = await client.fetch('user_certificates');
    if (error) {
      toast.error(t('toast.errorGetCertifications'));
    } else {
      setCertificates(data);
    }
    setLoading(false);
  };

  const downloadCertificate = async item => {
    window.open(`${window.env.REACT_APP_DEV_URL}//user_certificates/download/${item['hash-id']}.pdf`, '_blank');
  };

  useEffect(() => {
    getRelationships();
    getCertificates();
  }, []);

  if (checkIncludeProfiles({ only: ['admin', 'designer', 'student'] }) === false) return <Error404 />;

  const orderedCertificates = certificatesFilteredByCourse.sort((a, b) => {
    const dateA = new Date(b['created-at'].split('/').reverse().join('/'));
    const dateB = new Date(a['created-at'].split('/').reverse().join('/'));
    return dateA - dateB;
  });

  return (
    <>
      <main className="main-content main-content--block">
        {checkIncludeProfiles({ only: ['student'] }) && <TabNavBar selectedTab="certificados" />}

        <div className="filter-bar">
          <div className="filter-bar__inner">
            <div className="filter-bar__row">
              <label
                htmlFor="search"
                className="filter-bar__label"
              >
                {t('textsCommon.search')}
              </label>
              <input
                aria-label="Buscar"
                className="form__control form__control--search-with-icon"
                style={{ width: 'auto' }}
                placeholder={t('textsCommon.research')}
                type="search"
                name="search"
                id="search"
                onChange={e => setSearchTerm(e.target.value)}
              />
            </div>

            <FilterSelectionBox
              blankLabel={t('filter.blankLabelAll2')}
              label={'Selecione um tipo'}
              value={filters.values.type}
              onChange={e => filters.setFieldValue('type', e.target.value)}
              options={options}
            />

            <FilterSelectionBox
              label={t('exams.selectCourse')}
              value={filters.values.course}
              onChange={e => filters.setFieldValue('course', e.target.value)}
              options={relationships.courses.map(o => ({
                id: o.id,
                name: o.title
              }))}
            />
          </div>
        </div>

        {!loading && (
          <div className="round-dg-wrapper">
            {orderedCertificates.length > 0 && !loading && (
              <table
                className="round-dg round-dg--light"
                style={{ minWidth: '730px' }}
              >
                <thead className="round-dg__header">
                  <tr className="round-dg__row">
                    <th
                      className="round-dg__cell-header"
                      style={{ width: '72px' }}
                    />

                    <th
                      className="round-dg__cell-header"
                      style={{ width: '100px' }}
                    />
                    <th className="round-dg__cell-header">Curso/Módulo</th>
                    <th
                      className="round-dg__cell-header"
                      style={{ width: '120px' }}
                    >
                      {t('lms.tableHeaderConcludedIn')}
                    </th>
                    <th
                      className="round-dg__cell-header"
                      style={{ width: '130px' }}
                    />
                    <th
                      className="round-dg__cell-header"
                      style={{ width: '73px' }}
                    />
                  </tr>
                </thead>

                <tbody className="round-dg__body">
                  {orderedCertificates.map((item, index) => {
                    return (
                      <tr
                        className="round-dg__row"
                        key={index}
                      >
                        <td className="round-dg__cell">
                          <div className="medal medal--sm">
                            <img
                              src={item?.['certificate-type'] === 'Módulo' ? moreMedal : courseMedal}
                              alt="nova medalha"
                              className="medal__img"
                            />
                            <span className="medal__value">+1</span>
                          </div>
                        </td>
                        <td className="round-dg__cell">
                          <span className="badge badge--tiny">{item?.['certificate-type']}</span>
                        </td>
                        <th
                          scope="row"
                          className="round-dg__cell-header round-dg__cell-header--row"
                        >
                          <div className="round-dg__user">
                            <div className="round-dg__user-inner">
                              <h3 className="round-dg__title">{item?.['learning-system']?.title}</h3>
                            </div>
                          </div>
                        </th>

                        <td className="round-dg__cell">{item?.['created-at']}</td>

                        <td className="round-dg__cell">
                          <a
                            className="btn btn--link u-p-0"
                            onClick={() => {
                              setCurrentCertificate(item);
                              setShowModal(true);
                            }}
                          >
                            Detalhamento
                          </a>
                        </td>
                        <td className="round-dg__cell">
                          <button
                            className="card__print-btn"
                            title={t('lms.printCertificate')}
                            onClick={() => {
                              downloadCertificate(item);
                            }}
                          >
                            <FiPrinter />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}

            {orderedCertificates.length === 0 && !loading && (
              <EmptyState
                type="content"
                title={t('emptyState.titleCertificate')}
                text={t('emptyState.textCertificate')}
              />
            )}
          </div>
        )}

        {loading && <Loader />}
      </main>

      <CertificateModal
        show={showModal}
        onClose={() => setShowModal(false)}
        currentCertificate={currentCertificate}
      />
    </>
  );
}
