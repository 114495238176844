import React, { useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import { FiChevronLeft, FiCheck, FiUser, FiUsers, FiHelpCircle } from 'react-icons/fi';
import { t } from 'i18next';
import { useFormik } from 'formik';
import { useQuery } from 'jsonapi-react';
import moment from 'moment';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import ViewGRE from './ViewGRE';
import Loader from 'app/components/loader';
import { TableGRE } from './TableGRE';
import { ReportsChart } from 'app/components/ReportsChart';
import useReportAbilities from 'app/hooks/useReportAbilities';

export default function RegistrationReport() {
  const filters = useFormik({
    initialValues: {
      course: 'blank',
      'start-date': moment().startOf('month').format('DD MMM YYYY'),
      'end-date': moment().endOf('month').format('DD MMM YYYY')
    }
  });

  const { data: courses = [], isLoading: isLoadingCourses } = useQuery('courses?simple=true');

  const [showModule, setShowModule] = useState(false);
  const [selectedRegional, setSelectedRegional] = useState(null);
  const today = moment().format(t('datesForm.formatDate'));
  const { reportAbilities, getReportAbilities } = useReportAbilities();

  let filter = {};

  if (filters.values.course && filters.values.course !== 'blank') {
    filter.course_id = filters.values.course;
  }

  if (filters.values['start-date'] && filters.values['end-date']) {
    filter.date_between = {
      initial: filters.values['start-date'],
      final: filters.values['end-date']
    };
  }

  const { data: report, isLoadingReport } = useQuery([
    'regionals/registration_report',
    {
      filter
    }
  ]);
  const [items, setItems] = useState([]);

  const kpis = [
    {
      id: 1,
      icon: <FiCheck />,
      title: 'Estudantes cadastrados',
      value: report?.registeredStudents,
      help: 'Número total de estudantes cadastrados de acordo com o filtro selecionado.'
    },
    {
      id: 2,
      icon: <FiUsers />,
      title: 'Maior número de cadastros',
      value: report?.mostRegistrations?.name,
      help: 'Analise as estratégias da região com maior número de cadastros para identificar práticas bem-sucedidas.'
    },
    {
      id: 3,
      icon: <FiUser />,
      title: 'Menor número de cadastros',
      value: report?.leastRegistrations?.name,
      help: 'Analise as estratégias da região com menor número de cadastros para desenvolver ações de melhoria.'
    }
  ];

  useEffect(() => {
    filters.setFieldValue('start-date', moment().startOf('month').format('YYYY-MM-DD'));
    filters.setFieldValue('end-date', moment().endOf('month').format('YYYY-MM-DD'));
    getReportAbilities();
  }, []);

  useEffect(() => {
    setSelectedRegional(null);
    setShowModule(false);
    if (report?.regionals) {
      setItems(report.regionals);
    }
  }, [report]);

  const appName = window.env.REACT_APP_NAME;

  return (
    <main className="main-content main-content--block">
      <div className="filter-bar">
        <div className="filter-bar__inner">
          <button
            className="filter-bar__back"
            onClick={() => navigate(-1)}
          >
            <span className="card__icon-wrapper">
              <FiChevronLeft className="card__icon" />
            </span>
            <span className="filter-bar__back-container">
              <span className="filter-bar__title">Visão global dos cadastros de usuários</span>
            </span>
          </button>
        </div>
      </div>

      <div className="filter-bar filter-bar--borderless">
        <div className="filter-bar__inner">
          <div className="filter-bar__row">
            <label
              htmlFor="start-date"
              className="filter-bar__label"
            >
              Selecione um período
            </label>
            <div className="form__columns">
              <div className="form__col">
                <input
                  className="form__control"
                  id="start-date"
                  name="start-date"
                  type="date"
                  min={today}
                  value={moment(filters.values['start-date']).format('YYYY-MM-DD')}
                  onChange={e => filters.setFieldValue('start-date', e.target.value)}
                  placeholder="Data de início"
                />
              </div>
              <div className="form__col">
                <input
                  className="form__control"
                  id="end-date"
                  name="end-date"
                  type="date"
                  min={today}
                  value={moment(filters.values['end-date']).format('YYYY-MM-DD')}
                  onChange={e => filters.setFieldValue('end-date', e.target.value)}
                  placeholder="Data de fim"
                />
              </div>
            </div>
          </div>

          <FilterSelectionBox
            label={t('exams.selectCourse')}
            value={filters.values.course}
            blankLabel={t('metrics.blankLabelSelect')}
            onChange={e => filters.setFieldValue('course', e.target.value)}
            options={courses.map(o => ({
              id: o.id,
              name: o.title
            }))}
          />
        </div>
      </div>

      {isLoadingReport && <Loader />}

      {!isLoadingReport && (
        <>
          <div className="widget-wrapper">
            <div className="kpi-cards">
              {kpis.map(kpi => (
                <div
                  className="kpi-card"
                  key={kpi.id}
                >
                  <FiHelpCircle
                    className="kpi-card__help-icon"
                    data-tooltip-id={kpi.id}
                    data-tooltip-content={kpi.help}
                  />
                  <ReactTooltip
                    id={kpi.id}
                    className="tooltip"
                  />
                  <div className="kpi-card__icon">{kpi.icon}</div>
                  <div className="kpi-card__title">{kpi.title}</div>
                  <div className="kpi-card__value">{kpi.value}</div>
                </div>
              ))}
            </div>
          </div>

          {reportAbilities?.['visao-global-dos-cadastros-de-usuarios']?.['mapa-de-usuarios-cadastrados'] && (
            <div className="widget-wrapper">
              <div className="widget">
                <div className="widget__header">
                  <div className="widget__title-with-btn u-mb-2">
                    <h3 className="widget__title">Mapa de usuários cadastrados</h3>
                    <FiHelpCircle
                      className="widget__help-icon"
                      data-tooltip-id="access"
                      data-tooltip-content="O gráfico de bolhas exibe o número de usuários cadastrados por região. O tamanho de cada bolha corresponde à quantidade de usuários, facilitando a comparação visual entre as regiões."
                    />
                    <ReactTooltip
                      id="access"
                      className="tooltip"
                    />
                  </div>
                </div>
                <div className="widget__body">
                  <ReportsChart
                    report={items}
                    setSelectedRegional={setSelectedRegional}
                    setShowModule={setShowModule}
                  />
                </div>
              </div>
            </div>
          )}
          {reportAbilities?.['visao-global-dos-cadastros-de-usuarios']?.['ranking'] && (
            <div className="widget-wrapper">
              <div className="widget">
                <div className="widget__header">
                  <h3 className="widget__title u-mb-4">Ranking</h3>

                  <div className={!showModule ? 'filter-bar filter-bar--borderless' : 'filter-bar'}>
                    <div className="filter-bar__inner">
                      {!showModule && (
                        <div className="filter-bar__row">
                          <label
                            htmlFor="search"
                            className="filter-bar__label"
                          >
                            Buscar
                          </label>
                          <input
                            className="form__control form__control--search-with-icon"
                            placeholder={t('textsCommon.research')}
                            type="search"
                            name="search"
                            id="search"
                            onChange={e => filters.setFieldValue('searchTerm', e.target.value)}
                          />
                        </div>
                      )}

                      {showModule && (
                        <>
                          <button
                            className="filter-bar__back filter-bar__back--disabled"
                            style={{ alignSelf: 'center' }}
                            onClick={() => setShowModule(false)}
                          >
                            <span className="filter-bar__back-container">
                              <span className="filter-bar__title">GREs</span>
                            </span>
                          </button>
                          <button
                            className="filter-bar__back"
                            onClick={() => {
                              setItems(report?.regionals);
                              setShowModule(false);
                            }}
                          >
                            <span className="card__icon-wrapper">
                              <FiChevronLeft className="card__icon" />
                            </span>
                            <span className="filter-bar__back-container">
                              <span className="filter-bar__title">{selectedRegional.name}</span>
                            </span>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="widget__body">
                  {!showModule ? (
                    <div className="round-dg-wrapper">
                      <TableGRE
                        regionals={report?.regionals}
                        searchValue={filters.values.searchTerm}
                        onOpen={item => {
                          setSelectedRegional(item);
                          setShowModule(true);
                        }}
                      />
                    </div>
                  ) : (
                    <ViewGRE
                      gre={selectedRegional}
                      setItems={setItems}
                      filter={filter}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </main>
  );
}
