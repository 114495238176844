export function ShadowContainer({ children, width }) {
  return (
    <div
      style={{
        borderRadius: '10px',
        padding: '20px',
        boxShadow: '0px 4px 10px 3px rgba(68, 43, 140, 0.20)',
        marginTop: '16px',
        width: width ? width : '100%'
      }}
    >
      {children}
    </div>
  );
}
