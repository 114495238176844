import React, { useState, useEffect } from 'react';
import { navigate, useParams } from '@reach/router';
import { Link } from '@reach/router';
import { i18n } from 'app/components/i18n/i18n';
import * as Yup from 'yup';
import { useSession } from 'app/hooks/useSession';
import { useFormik } from 'formik/dist';
import { useTranslation } from 'react-i18next';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import InputMask from 'react-input-mask';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import Select from 'react-select';
import { validateCPF } from 'app/utils/validateCPF';
import moment from 'moment';
import './check-in.sass';

export default function CheckIn() {
  const { t } = useTranslation();
  const client = useClient();
  const { session } = useSession();

  const [institutions, setInstitutions] = useState([]);
  const [courses, setCourses] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState(false);
  const [loading, setLoading] = useState(false);
  const [radio, setRadio] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const appName = window.env.REACT_APP_NAME;
  const enviroment = window.env.REACT_APP_SSO_API_URL;

  const logo = require(`app/images/${appName}/horizontal-logo.svg`);
  const logoPreSaeb = require(`app/images/${appName}/logo_pre_saeb.png`);
  const institutionsRadioString = ['Já sou formado', 'Sem escola'];
  const institutionsRadioButton = institutions?.filter(item => institutionsRadioString.includes(item.name));
  const isStudentEnrolled = radio === 'enrolled';
  const isStudentNoSchool = radio === 'Sem escola';
  const filteredInstitutions = institutions?.filter(item => !institutionsRadioString.includes(item.name));

  const schema = Yup.object({
    institution: Yup.object().required(t('warning.requiredField')),
    cpfCnpj: Yup.string().notRequired(),
    ra: Yup.string().nullable().notRequired(),
    name: Yup.string().required(t('warning.requiredField')),
    email: Yup.string().required(t('warning.requiredField')),
    phone: Yup.string().nullable().notRequired(),
    classroom_id: Yup.string().required(t('warning.requiredField')),
    course: Yup.string().required(t('warning.requiredField'))
  });

  const authenticateUser = async () => {
    const requestParams = { ...studentForm.values };
    requestParams.classroom_id = Number(requestParams.classroom_id);
    delete requestParams.course;
    delete requestParams.institution;

    const { error } = await client.mutate('check_ins', requestParams);

    if (error) {
      toast.error(error.title);
    } else {
      toast.success('Conta criada com sucesso!');
      setShowSuccess(true);

      // signIn(studentForm.values);
    }
  };

  const studentForm = useFormik({
    initialValues: {
      classroom_id: '',
      cpfCnpj: '',
      ra: '',
      name: '',
      email: '',
      phone: '',
      institution: '',
      course: ''
    },
    validationSchema: schema,
    onSubmit: authenticateUser
  });

  const InfoIcon = () => {
    return (
      <div style={{ background: 'var(--brand-semantic-alerta, #FFD661)', maxWidth: 32, width: '100%', height: 32, borderRadius: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <svg
          width="2"
          height="22"
          viewBox="0 0 2 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.16992 1L1.16992 12.9035"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1.83981 16.9386C1.83981 17.1783 1.59917 17.4737 1.1699 17.4737C0.740632 17.4737 0.5 17.1783 0.5 16.9386C0.5 16.6989 0.740632 16.4035 1.1699 16.4035C1.59917 16.4035 1.83981 16.6989 1.83981 16.9386Z"
            fill="white"
            stroke="white"
          />
        </svg>
      </div>
    );
  };

  const handleBlurCPF = async () => {
    if (validateCPF(studentForm.values.cpfCnpj)) {
      setLoading(true);
      toast.loading('Carregando', {
        id: 'toast-loading'
      });
      const { data } = await client.mutate('canaleducacao/alunos', { cpf: studentForm.values.cpfCnpj });
      if (data?.mensagem === 'Nenhum resultado encontrado!') {
        const userCpf = studentForm.values.cpfCnpj;
        studentForm.resetForm();
        studentForm.setFieldValue('cpfCnpj', userCpf);
        toast.error('Usuário não encontrado. Faça o cadastro e acesse a plataforma', {
          position: 'top-center',
          style: {
            fontWeight: 'bold',
            color: 'var(--brand-primary-dark, #110B3E)'
          },
          icon: <InfoIcon />
        });
      } else {
        fillETIPIFields(data);
        toast.success('Cadastro encontrado.', {
          position: 'top-center'
        });
      }
      toast.dismiss('toast-loading');
      setLoading(false);
      setShowForm(true);
      if (!radio) {
        setRadio('enrolled');
      }
    }
  };

  const handleBlurRA = async () => {
    if (studentForm.values.ra?.replaceAll('_', '').length === 11) {
      toast.loading('Carregando', {
        id: 'toast-loading'
      });
      const { data } = await client.mutate('canaleducacao/alunos', { ra: studentForm.values.ra });

      if (data?.mensagem === 'Nenhum resultado encontrado!') {
        studentForm.resetForm();
        const userRA = studentForm.values.ra;
        studentForm.resetForm();
        studentForm.setFieldValue('ra', userRA);
        toast.error('Usuário não encontrado. Faça o cadastro e acesse a plataforma', {
          position: 'top-center',
          style: {
            fontWeight: 'bold',
            color: 'var(--brand-primary-dark, #110B3E)'
          },
          icon: <InfoIcon />
        });
      } else {
        fillETIPIFields(data);
        toast.success('Cadastro encontrado.', {
          position: 'top-center'
        });
      }

      toast.dismiss('toast-loading');
      setLoading(false);
      setShowForm(true);
      if (!radio) {
        setRadio('enrolled');
      }
    }
  };

  const fillETIPIFields = data => {
    if (data?.ALUNO) {
      if (data?.ALUNO?.nomePessoa) {
        studentForm.setFieldValue('name', data.ALUNO.nomePessoa);
      }
      if (data?.ALUNO?.Email.replaceAll(' - ', '')) {
        studentForm.setFieldValue('email', data.ALUNO.Email.replaceAll(' - ', ''));
      }
      if (data?.ALUNO?.TelefoneEstudante) {
        studentForm.setFieldValue('phone', data.ALUNO.TelefoneEstudante);
      }
      const inepEscola = data?.TURMA[0]?.inepEscola || data?.TURMA?.inepEscola;
      const nomeTurno = data?.TURMA[0]?.nomeTurno || data?.TURMA?.nomeTurno;
      if (inepEscola) {
        const institution = institutions.find(e => e.code === inepEscola);
        if (institution) {
          studentForm.setFieldValue('institution', { value: institution?.id, label: institution?.name });
          setSelectedRadio(false);
        }
      }
      if (nomeTurno) {
        let course = null;
        if (nomeTurno.includes('MANHA')) {
          course = courses.find(c => c.title.toLocaleLowerCase().includes('manhã'));
        } else if (nomeTurno.includes('TARDE')) {
          course = courses.find(c => c.title.toLocaleLowerCase().includes('tarde'));
        } else if (nomeTurno.includes('NOITE')) {
          course = courses.find(c => c.title.toLocaleLowerCase().includes('tarde'));
        }

        if (course) {
          studentForm.setFieldValue('course', course?.id);
        }
      }
      if (data?.ALUNO?.cpf) {
        studentForm.setFieldValue('cpfCnpj', data.ALUNO.cpf);
      }
      if (data?.ALUNO?.codigoEscolarAluno) {
        studentForm.setFieldValue('ra', data.ALUNO.codigoEscolarAluno);
      }
    }
  };

  const banner = appName === 'canaleducacao' ? logoPreSaeb : logo;
  console.log('appName', appName);
  console.log('banner', banner);

  useEffect(() => {
    if (selectedRadio) {
      studentForm.setFieldValue('course', courses[0].id);
    }
  }, [selectedRadio]);

  const getInstitutions = async () => {
    return await client.fetch('/institutions');
  };

  const getCourses = async () => {
    return await client.fetch('/courses');
  };

  const getClassrooms = async () => {
    const url = `/classrooms?filter[institution_id]=${studentForm.values.institution?.value}&filter[course_id]=${studentForm.values.course}`;
    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error('Erro ao buscar turmas');
    } else {
      setClassrooms(data);
      studentForm.setFieldValue('classroom_id', data[0]?.id);
    }
  };

  useEffect(() => {
    setLoading(true);
    Promise.allSettled([getInstitutions(), getCourses()])
      .then(results => {
        if (Object.keys(results[0].value).includes('error')) {
          toast.error('Erro ao buscar instituições');
        } else {
          setInstitutions(results[0].value.data);
        }
        if (Object.keys(results[1].value).includes('error')) {
          toast.error('Erro ao buscar cursos');
        } else {
          setCourses(results[1].value.data);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (studentForm.values.course !== '' && studentForm.values.institution?.value !== '') {
      getClassrooms();
    }
  }, [studentForm.values.course, studentForm.values.institution?.value]);

  if (showSuccess)
    return (
      <div className="check-in-wrapper">
        <div className="check-in-wrapper__nav">
          <a
            onClick={() => {
              studentForm.resetForm();
              navigate(`${enviroment}/check-in`);
            }}
            className="btn btn--link u-ml-auto"
          >
            Novo cadastro
          </a>
        </div>

        <main className="check-in fadeIn">
          <div className="check-in__success">
            <img
              className="check-in__logo"
              src={banner}
              alt="logo"
            />
            <p className="check-in__success-text">Sua presença foi registrada com sucesso!</p>
          </div>
        </main>
      </div>
    );

  return (
    <div className="check-in-wrapper">
      <span className="check-in__date">{moment().format('DD/MM/YYYY')}</span>
      <div className="check-in-wrapper__nav">
        <button
          className="btn btn--link"
          href="/"
          onClick={() => history.back()}
        >
          Voltar
        </button>
      </div>

      <main className="check-in">
        <div className="check-in__header">
          <img
            className="check-in__logo"
            src={banner}
            alt="logo"
          />
          <h1 className="check-in__title">
            <span className="square-tag">2</span> Finalize inserindo seus dados
          </h1>
        </div>

        <div className="check-in__body">
          <form
            className="form"
            method="post"
            onSubmit={studentForm.handleSubmit}
          >
            <div className="form__row form__row--columns">
              <div className="form__col">
                <label
                  className="form__label"
                  htmlFor="cpfCnpj"
                >
                  CPF
                </label>
                <InputMask
                  className="form__control"
                  name="cpfCnpj"
                  id="cpfCnpj"
                  mask="999.999.999-99"
                  placeholder="Digite o número"
                  value={studentForm.values.cpfCnpj}
                  onBlur={handleBlurCPF}
                  onChange={e => studentForm.setFieldValue('cpfCnpj', e.target.value)}
                />
                <div className="error-message">{studentForm?.values?.cpfCnpj?.replaceAll('_', '').length === 14 && !validateCPF(studentForm.values.cpfCnpj) && 'CPF Inválido'}</div>
              </div>

              <span className="text">ou</span>

              <div className="form__col">
                <label
                  className="form__label"
                  htmlFor="ra"
                >
                  RA (Registro Acadêmico)
                </label>
                <InputMask
                  disabled={loading}
                  className="form__control"
                  mask="99999999999"
                  name="ra"
                  id="ra"
                  placeholder="Digite o número"
                  value={studentForm.values.ra}
                  onBlur={handleBlurRA}
                  onChange={e => studentForm.setFieldValue('ra', e.target.value)}
                />
                {<div className="error-message">{studentForm?.values?.ra?.replaceAll('_', '').length === 11 && studentForm?.errors?.ra}</div>}
              </div>
            </div>

            <div className="form__row">
              <div className="form__check form__check--inline">
                <input
                  type="radio"
                  name="enrolled"
                  id="enrolled"
                  value="enrolled"
                  checked={isStudentEnrolled}
                  onChange={() => setRadio('enrolled')}
                  className="form__check-input"
                />
                <label htmlFor="enrolled">Matriculado na escola</label>
              </div>

              {!loading &&
                institutionsRadioButton?.map(institution => (
                  <div className="form__check form__check--inline">
                    <input
                      disabled={loading}
                      type="radio"
                      id={`institution-${institution.id}`}
                      name="institution-radio"
                      checked={institution.name === radio}
                      onChange={() => {
                        studentForm.setFieldValue('institution', { value: institution.id, label: institution.name });
                        setSelectedRadio(true);
                        setRadio(institution.name);
                      }}
                      className="form__check-input"
                    />
                    <label htmlFor={`institution-${institution.id}`}>{institution.name}</label>
                  </div>
                ))}
            </div>

            {(radio || showForm) && (
              <>
                {isStudentEnrolled && (
                  <div
                    className="form__row"
                    style={{ marginTop: 0 }}
                  >
                    <label className="form__label">*Escola:</label>
                    <Select
                      isDisabled={loading}
                      value={studentForm.values.institution}
                      openMenuOnFocus
                      className="react-multi-select filter-bar__multi-select u-w-100"
                      classNamePrefix="react-multi-select"
                      placeholder={loading ? 'Carregando...' : t('filter.blankLabelAll')}
                      noOptionsMessage={() => 'Sem opções'}
                      options={[
                        { value: '', label: t('filter.blankLabelAll') },
                        ...filteredInstitutions?.map(item => {
                          return { value: item.id, label: item.name };
                        })
                      ]}
                      components={{
                        IndicatorSeparator: () => null,
                        ClearIndicator: () => null
                      }}
                      onChange={e => {
                        studentForm.setFieldValue('institution', e);
                        setSelectedRadio(false);
                      }}
                    />
                    {studentForm.touched.institution && studentForm.errors.institution && <span style={{ color: 'red' }}>{studentForm.errors.institution}</span>}
                  </div>
                )}

                <div className="container-grid-student">
                  <div className="form__Row">
                    <label className="form__label">*{t('textsCommon.course')}:</label>
                    <FilterSelectionBox
                      blankLabel={loading ? 'Carregando...' : t('filter.blankLabel')}
                      value={studentForm.values.course}
                      disabled={loading}
                      options={courses}
                      onChange={e => studentForm.setFieldValue('course', e.target.value)}
                      className="u-w-100"
                    />
                    {studentForm.touched.course && studentForm.errors.course && <span style={{ color: 'red' }}>{studentForm.errors.course}</span>}
                  </div>
                  <div className="form__Row">
                    <label className="form__label">*{t('textsCommon.class')}:</label>
                    <FilterSelectionBox
                      blankLabel={loading ? 'Carregando...' : t('filter.blankLabel')}
                      value={studentForm.values.classroom_id}
                      onChange={e => studentForm.setFieldValue('classroom_id', e.target.value)}
                      options={classrooms}
                      className="u-w-100"
                    />
                    {studentForm.touched.classroom_id && studentForm.errors.classroom_id && <span style={{ color: 'red' }}>{studentForm.errors.classroom_id}</span>}
                  </div>
                </div>

                <div className="form__row">
                  <label
                    className="form__label"
                    htmlFor="name"
                  >
                    *{t('createAccount.name')}
                  </label>
                  <input
                    className="form__control disabled"
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Digite seu nome"
                    value={studentForm.values.name}
                    onChange={e => studentForm.setFieldValue('name', e.target.value)}
                  />
                  {studentForm.touched.name && studentForm.errors.name && <span style={{ color: 'red' }}>{studentForm.errors.name}</span>}
                </div>

                <div className="form__row">
                  <label
                    className="form__label"
                    htmlFor="email"
                  >
                    *Email:
                  </label>
                  <input
                    className="form__control disabled"
                    type="email"
                    name="email"
                    id="email"
                    value={studentForm.values.email}
                    onChange={e => studentForm.setFieldValue('email', e.target.value)}
                  />
                  {studentForm.touched.email && studentForm.errors.email && <span style={{ color: 'red' }}>{studentForm.errors.email}</span>}
                </div>

                <div className="form__row">
                  <label
                    className="form__label"
                    htmlFor="cellphone"
                  >
                    {t('createAccount.cellphone')}
                  </label>
                  <InputMask
                    className="form__control"
                    mask="(99) 99999-9999"
                    name="cellphone"
                    id="cellphone"
                    value={studentForm.values.phone}
                    onChange={e => studentForm.setFieldValue('phone', e.target.value)}
                  />
                  {studentForm.touched.phone && studentForm.errors.phone && <span style={{ color: 'red' }}>{studentForm.errors.phone}</span>}
                </div>

                <div className="form__row u-text-center">
                  <button
                    type="submit"
                    className="btn btn--wide btn--review"
                  >
                    Registrar minha presença
                  </button>
                </div>
              </>
            )}
          </form>
        </div>
      </main>
    </div>
  );
}
