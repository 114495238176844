import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useClient } from 'jsonapi-react';
import { FilterSelectionBox } from '../../components/FilterSelectionBox';
import { QuestionBookCard } from '../../components/ProgramOfStudies/QuestionBookCard';
import Modal from '../../components/Modal';
import Loader from '../../components/loader';
import { QuestionBookForm } from './QuestionBookForm';
import EmptyState from 'app/components/EmptyState';
import getPermission from 'app/utils/getPermission';
import FilterMultiSelect from 'app/components/FilterMultiSelect';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';
import toast from 'react-hot-toast';

export default function QuestionBook({ courses, classrooms, questionBooks, loading }) {
  const { t } = useTranslation();
  const client = useClient();
  const basePath = window.env.REACT_APP_BASE_PATH;

  // const [showModal, setShowModal] = useState(false);
  // const [relationshipsLoading, setRelationshipsLoading] = useState(true);
  const [filteredClassrooms, setfilteredClassrooms] = useState([]);
  const [currentQuestionBook, setcurrentQuestionBook] = useState(null);
  const [filteredQuestionBooks, setFilteredQuestionBooks] = useState([]);

  const filters = useFormik({
    initialValues: {
      course: 'blank',
      classroom: 'blank',
      visibility: {
        published: false,
        nonPublished: false
      }
    }
  });

  useEffect(() => {
    const filteredClassrooms = (courses || []).find(item => item.id == filters.values.course)?.classrooms;
    setfilteredClassrooms(filteredClassrooms);

    if (filters.values.course !== 'blank' || filters.values.course === 'blank') {
      filters.setFieldValue('classroom', 'blank');
    }
  }, [filters.values.course]);

  function filterQuestionBooksByCourse(id, questionBooks) {
    return questionBooks.filter(function (qb) {
      return qb['course-ids'].includes(id);
    });
  }

  function filterQuestionBooksByClassroom(id, questionBooks) {
    return questionBooks.filter(function (qb) {
      return qb['classroom-ids'].includes(id);
    });
  }

  useEffect(() => {
    let newQuestionBooks = questionBooks;
    if (filters.values.course !== 'blank') {
      const id = +filters.values.course;
      newQuestionBooks = filterQuestionBooksByCourse(id, questionBooks);
    }
    if (filters.values.classroom !== 'blank') {
      const id = +filters.values.classroom;
      newQuestionBooks = filterQuestionBooksByClassroom(id, questionBooks);
    }
    if (filters.values.visibility.published === true) {
      newQuestionBooks = newQuestionBooks.filter(qb => qb.published === true);
    }
    if (filters.values.visibility.nonPublished === true) {
      newQuestionBooks = newQuestionBooks.filter(qb => qb.published === false);
    }
    setFilteredQuestionBooks(newQuestionBooks);
  }, [filters.values, questionBooks]);

  useEffect(() => {
    if (currentQuestionBook !== null) {
      setShowModal(true);
    }
    // setcurrentQuestionBook(null);
  }, [currentQuestionBook]);

  const deleteQuestionBook = async id => {
    const { error } = await client.delete(`question_books/${id}`);
    if (error) {
      toast.error(t('toast.errorDeleteQuestionBook'));
    } else {
      toast.success(t('toast.successDeleteQuestionBook'));
      setFilteredQuestionBooks(filteredQuestionBooks.filter(q => q.id !== id));
    }
  };

  return (
    <>
      <div className="tab__pane">
        <div className="filter-bar">
          <div className="filter-bar__inner">
            <FilterSelectionBox
              blankLabel={t('filter.labelSelect')}
              label={t('filter.labelSelectCourse')}
              placeholder={t('exams.selectCourse')}
              value={filters.values.course}
              onChange={e => {
                filters.setFieldValue('course', e.target.value);
                filters.setFieldValue('classroom', 'blank');
              }}
              options={courses.map(c => ({
                ...c,
                name: c.title
              }))}
            />
            <FilterSelectionBox
              blankLabel={t('filter.labelSelect')}
              label={t('filter.labelSelectClassroom')}
              placeholder={t('createAccount.selectClass')}
              value={filters.values.classroom}
              onChange={e => {
                filters.setFieldValue('classroom', e.target.value);
              }}
              options={filteredClassrooms}
            />
            <FilterMultiSelect
              data={[
                {
                  label: 'Publicado',
                  value: filters.values.visibility.published,
                  onChange: () => {
                    filters.setFieldValue('visibility', {
                      published: !filters.values.visibility.published,
                      nonPublished: false
                    });
                    filters.setFieldValue('nonPublished', false);
                  }
                },
                {
                  label: 'Não publicado',
                  value: filters.values.visibility.nonPublished,
                  onChange: () => {
                    filters.setFieldValue('visibility', {
                      published: false,
                      nonPublished: !filters.values.visibility.nonPublished
                    });
                  }
                }
              ]}
            />
          </div>
          {getPermission('Criar apostilas', 'Questões e apostilas') && (
            <button
              type="button"
              onClick={() => navigate(`${basePath}/apostilas/criar/nome`)}
              className="btn btn--wide btn--primary"
            >
              {t('exams.newQuestionBook')}
            </button>
          )}
          {getPermission('Criar apostilas', 'Questões e apostilas') && (
            <button
              type="button"
              onClick={() => navigate(`${basePath}/apostilas/gerenciar`)}
              className="btn btn--wide btn--primary"
            >
              {t('button.manageQuestionBooks')}
            </button>
          )}
        </div>

        {loading && <Loader />}

        {!loading && !filteredQuestionBooks?.length > 0 && <EmptyState type="data" />}

        <div className="tab__cards">
          {!loading &&
            filteredQuestionBooks.map(
              qb =>
                qb['creator-profile'] !== 'Aluno' && (
                  <QuestionBookCard
                    deleteQuestionBook={deleteQuestionBook}
                    questionBook={qb}
                    setcurrentQuestionBook={setcurrentQuestionBook}
                  />
                )
            )}
        </div>

        {/* <Modal
          show={showModal}
          onClose={() => {
            setShowModal(false);
            setcurrentQuestionBook(null);
          }}
        >
          <QuestionBookForm
            relationshipsLoading={relationshipsLoading}
            relationships={relationships}
            currentQuestionBook={currentQuestionBook}
          />
        </Modal> */}
      </div>
    </>
  );
}
