import React, { useState, useEffect } from 'react';
import TabNavBar from './TabNavBar';
import QuestionBooksView from './QuestionBooksView';
import QuestionBooksMetricsView from './QuestionBooksMetricsView';
import { useSession } from 'app/hooks/useSession';
import Onboarding from 'app/components/Onboarding';
import { useTranslation } from 'react-i18next';

function UserQuestionBooks(props) {
  const { t } = useTranslation();
  const { selectedTab, path } = props;
  const { session } = useSession();
  const profiles = session?.user?.profileNames || (session?.user && session?.user['profile-names']) || [];
  const [showOnboarding, setShowOnboarding] = useState(false);

  return (
    <>
      <main className="main-content main-content--block">
        <TabNavBar selectedTab={selectedTab} />

        <div className="tab__content">
          {selectedTab === 'apostilas' && <QuestionBooksView />}
          {selectedTab === 'resultados-das-apostilas' && <QuestionBooksMetricsView />}
        </div>
      </main>

      <Onboarding
        show={showOnboarding}
        setShowOnboarding={setShowOnboarding}
        onClose={() => setShowOnboarding(false)}
        path={path}
        profiles={profiles}
        title={t('onboarding.titleQuestionBooks')}
        videoId="0SrjT4xP2-g"
      />
    </>
  );
}

export default UserQuestionBooks;
