import React from 'react';
import { FiMoreHorizontal, FiMessageCircle } from 'react-icons/fi';
import { Link } from '@reach/router';
import Dropdown from 'app/components/Dropdown';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

export default function DisciplineCard(props) {
  const { t } = useTranslation();
  const { deleteDiscipline, moduleId, item, setEditItemSelected, hasNewMessage } = props;
  const basePath = window.env.REACT_APP_BASE_PATH;

  return (
    <div className="card">
      <div className="card__header">
        <div className="card__inner-header">
          <h3 className="card__title">{item.body}</h3>

          <Dropdown>
            <Dropdown.Toggle>
              <FiMoreHorizontal />
            </Dropdown.Toggle>

            <Dropdown.Content>
              <Dropdown.Item onClick={() => setEditItemSelected(item)}>{t('button.edit')}</Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  Swal.fire({
                    title: t('projects.warningDeleteMatter'),
                    confirmButtonText: t('button.yes'),
                    showDenyButton: true,
                    denyButtonText: t('button.no'),
                    showCloseButton: true
                  }).then(res => {
                    if (res.isConfirmed) {
                      deleteDiscipline(item.id);
                    }
                  });
                }}
              >
                {t('button.delete')}
              </Dropdown.Item>
            </Dropdown.Content>
          </Dropdown>
        </div>

        {hasNewMessage && (
          <div className="card__badges">
            <span className="badge badge--tiny badge--warning">{t('lms.tagNewMessage')}</span>
          </div>
        )}
      </div>

      <div className="card__body">
        <p className="card__count">
          <h3
            className="card__count-label"
            title="Mensagens"
          >
            Mensagens
          </h3>
          <FiMessageCircle className="card__icon" /> {item['message-count']}
        </p>
      </div>

      <div className="card__footer">
        <Link
          className="btn btn--outline"
          to={`${basePath}/lms/visualizar/modulo/${moduleId}/disciplina/${item.id}`}
        >
          {t('button.access')}
        </Link>
      </div>
    </div>
  );
}
