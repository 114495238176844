import React, { useState } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import EmptyState from 'app/components/EmptyState';
import Modal from 'app/components/Modal';
import LessonCard from './LessonCard';
import LessonForm from './LessonForm';
import { useEffect } from 'react';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import Loader from 'app/components/loader';
import { checkIncludeProfiles, checkIncludeAppName } from '../../../utils/getAuthorityLevel';

export function Lesson(props) {
  const client = useClient();
  const [showModal, setShowModal] = useState(false);
  const [discipline, setDiscipline] = useState();
  const [lessons, setLessons] = useState([]);
  const [showListLessons, setShowListLessons] = useState([]);
  const [key, setKey] = useState(1);
  const [loading, setLoading] = useState();
  const [selectedLesson, setSelectedLesson] = useState();

  const getDiscipline = async () => {
    const { data, error } = await client.fetch(`learning_system_contents/${props?.disciplineId}`);
    if (error) {
      toast.error('Erro ao carregar disciplina');
    } else {
      setDiscipline(data);
      setLessons(data['learning-system-items']);
    }
  };

  const deleteLesson = async id => {
    try {
      await client.delete([`/learning_system_items/${id}`]);
      setShowListLessons(showListLessons?.filter(Lesson => Lesson?.id !== id));
      toast.success(t('toast.successDeleteLesson'));
      showLesson();
    } catch (e) {
      console.error(e);
      toast.error(t('toast.errorDeleteLesson'));
    }
  };

  const showLesson = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`learning_system_items?filter[learning_system_content_id]=${props?.disciplineId}`);

    setLoading(false);

    if (error) {
      toast.error('Erro ao carregar aulas');
    } else {
      setShowListLessons(data);
      setSelectedLesson(undefined);
    }
  };

  const editLesson = item => {
    setSelectedLesson(item);
    setShowModal(true);
  };

  useEffect(() => {
    getDiscipline();
    showLesson();
  }, [key]);

  return (
    <>
      <main className="main-content main-content--block">
        <div className="filter-bar">
          <button
            onClick={() => history.back()}
            className="filter-bar__back"
          >
            <FiChevronLeft />

            <span className="filter-bar__back-container">
              <span className="filter-bar__title">Disciplina: {discipline?.body}</span>
            </span>
          </button>

          <button
            className="btn btn--primary btn--wide u-ml-auto"
            onClick={() => setShowModal(true)}
          >
            Criar aula
          </button>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <>
            {showListLessons.length === 0 ? (
              <EmptyState
                type="select"
                title="Criar aula"
                text="Crie novas aulas à disciplina"
              />
            ) : (
              <>
                <p>
                  Para visualizar os conteúdos de uma aula, clique em <strong>acessar</strong>
                </p>

                <div className="tab__cards">
                  {showListLessons.map((item, index) => (
                    <LessonCard
                      moduleId={props?.id}
                      disciplineId={props?.disciplineId}
                      deleteLesson={deleteLesson}
                      item={item}
                      editLesson={editLesson}
                      hasNewMessage={item.has_new_message}
                    />
                  ))}
                </div>
              </>
            )}
          </>
        )}
      </main>

      <Modal
        show={showModal}
        onClose={() => {
          setShowModal(false);
          setSelectedLesson(undefined);
        }}
      >
        <LessonForm
          onSuccess={() => setKey(key + 1)}
          disciplineId={props?.disciplineId}
          setShowModal={setShowModal}
          selectedLesson={selectedLesson}
        />
      </Modal>
    </>
  );
}
