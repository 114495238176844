import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { FiX } from 'react-icons/fi';
import { CSSTransition } from 'react-transition-group';

function SimpleModal(props) {
  const { show, onClose, children, contentClassName, hideCloseButton } = props;

  function closeOnEscapeKeyDown(event) {
    if (event.key === 'Escape') onClose();
  }

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);

    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
      document.body.style.overflow = 'visible';
    };
  }, []);

  useEffect(() => {
    if (show) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'visible';
  }, [show]);

  const nodeRef = React.useRef(null); //Fix react-transition-group

  return ReactDOM.createPortal(
    <CSSTransition
      in={show}
      unmountOnExit
      nodeRef={nodeRef}
      timeout={{ enter: 0, exit: 300 }}
    >
      <div
        className={`simple-modal ${show ? 'active' : ''}`}
        onClick={onClose}
        ref={nodeRef}
      >
        <div
          className={`simple-modal__content ${contentClassName}`}
          onClick={event => event.stopPropagation()}
        >
          {!hideCloseButton && (
            <div className="simple-modal__simple-header">
              <button
                className="card__icon-wrapper card__icon-wrapper--sm u-ml-auto"
                onClick={onClose}
              >
                <FiX className="card__icon" />
              </button>
            </div>
          )}

          {children}
        </div>
      </div>
    </CSSTransition>,
    document.getElementById('modal_root')
  );
}

export default SimpleModal;
