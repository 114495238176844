import React, { useState, createContext, useEffect } from 'react';
import { navigate } from '@reach/router';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { t } from 'i18next';

export const ProgramOfStudiesContext = createContext();

export const ProgramOfStudiesProvider = ({ children }) => {
  const [avaliations, setAvaliations] = useState([]);
  const [students, setStudents] = useState([]);
  const [firstLevelTaxonomies, setFirstLevelTaxonomies] = useState([]);
  const [selectedTaxonomy, setSelectedTaxonomy] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [selectingTaxonomy, setSelectingTaxonomy] = useState(false);
  const [questionsPageNumber, setQuestionsPageNumber] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const basePath = window.env.REACT_APP_BASE_PATH;

  const [loading, setLoading] = useState(true);
  const client = useClient();

  // ----- Avaliations -----

  const getAvaliations = async () => {
    try {
      setLoading(true);
      const { data } = await client.fetch('avaliations');

      setMaterials(data);
      setLoading(false);
    } catch (e) {
      console.error(e);
      toast.error(t('toast.errorGetExams'));
    }
  };

  const createAvaliation = async ({ title }) => {
    try {
      setLoading(true);
      await client.mutate('avaliation', { title });

      navigate(`${basePath}/ementa/cadernos-de-questoes`);

      setLoading(false);
    } catch (e) {
      console.error(e);
      toast.error(t('toast.errorCreateExam'));
    }
  };

  const deleteAvaliation = async id => {
    try {
      setLoading(true);
      await client.delete(['avaliations', id]);

      getAvaliations();
    } catch (e) {
      console.error(e);
      toast.error(t('toast.errorDeleteExam'));
    }
  };

  // ----- Students -----

  const getStudents = async () => {
    try {
      setLoading(true);
      const { data } = await client.fetch('students');

      setMaterials(data);
      setLoading(false);
    } catch (e) {
      console.error(e);
      toast.error(t('toast.errorGetStudents'));
    }
  };

  // ----- Questions Database

  const getQuestions = async (taxonomyId, page, searchTerm) => {
    try {
      let url = `questions?filter[taxonomies.id]=${taxonomyId}&page[number]=${page}`;

      if (searchTerm) {
        url = `questions?filter[taxonomies.id]=${taxonomyId}&page[number]=${page}&q[q_cont]=${searchTerm}`;
      }
      const { data } = await client.fetch(url);

      return data;
    } catch (e) {
      console.error(e);
      toast.error(t('toast.errorGetFirstLvlTaxonomy'));
    }
  };

  const changeSelectedTaxonomy = async (taxonomy, questionsPage) => {
    setSelectingTaxonomy(true);

    const questions = await getQuestions(taxonomy.id, questionsPage || 1, searchTerm);

    setSelectedTaxonomy({ ...taxonomy, questions });
    setSelectedQuestion(questions[0]);
    setQuestionsPageNumber(questionsPage || 1);
    setSelectingTaxonomy(false);
  };

  useEffect(() => {
    if (selectedTaxonomy) {
      changeSelectedTaxonomy(selectedTaxonomy, questionsPageNumber);
    }
  }, [questionsPageNumber, searchTerm]);

  const fetchTaxonomies = async () => {
    setLoading(true);

    const { data, error } = await client.fetch('taxonomies');

    if (error) {
      toast.error(t('toast.errorGetFirstLvlTaxonomy'));
    } else {
      setFirstLevelTaxonomies(data);
      await changeSelectedTaxonomy(data[0]);
      setLoading(false);
    }
  };

  const getQuestionBookQuestions = async (questionBookId, page) => {
    const { data, error } = await client.fetch(`questions?filter[question_book_id]=${questionBookId}&page[number]=${page || 1}`);

    if (error) {
      toast.error(t('toast.errorGetFirstLvlTaxonomy'));
    }

    return data;
  };

  const updateQuestion = async formValues => {
    const alternatives = formValues['alternatives-attributes'].map(a => ({
      ...a,
      'is-correct': a.id === formValues['correct-alternative'].id,
      question: undefined
    }));
    const taxonomyIds = formValues.selectedTaxonomies.map(t => t.id);

    const parsedForm = {
      ...formValues,
      selectedTaxonomies: undefined,
      'alternatives-attributes': alternatives,
      'correct-alternative': undefined,
      'taxonomy-ids': taxonomyIds
    };

    const { error } = await client.mutate(['questions', selectedQuestion.id], parsedForm);

    if (error) {
      toast.error(t('toast.errorEditQuestion'));
    }

    await changeSelectedTaxonomy(selectedTaxonomy, questionsPageNumber);
  };

  return (
    <ProgramOfStudiesContext.Provider
      value={{
        loading,
        getAvaliations,
        createAvaliation,
        deleteAvaliation,
        getStudents,
        fetchTaxonomies,
        firstLevelTaxonomies,
        selectedTaxonomy,
        changeSelectedTaxonomy,
        selectedQuestion,
        setSelectedQuestion,
        selectingTaxonomy,
        getQuestionBookQuestions,
        updateQuestion,
        questionsPageNumber,
        setQuestionsPageNumber,
        searchTerm,
        setSearchTerm
      }}
    >
      {children}
    </ProgramOfStudiesContext.Provider>
  );
};
