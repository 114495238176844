import React, { useEffect, useState } from 'react';
import { useClient } from 'jsonapi-react';
import { Link, useParams } from '@reach/router';
import moment from 'moment';
import toast from 'react-hot-toast';
import Loader from 'app/components/loader';
import EmptyState from 'app/components/EmptyState';
import InfiniteScroll from 'react-infinite-scroll-component';
import EndScroll from 'app/components/endScroll';
import { AiOutlineTrophy } from 'react-icons/ai';
import getIcon from 'app/helpers/getIcon';
import { useTranslation } from 'react-i18next';
import { useNotification } from 'app/hooks/useNotification';

export default function ActivityLog({ useType, courseId }) {
  const { t } = useTranslation();
  moment.locale(t('language.language'));
  const { getNotificationForTimeline, setNotificationsTimeline, pageNumber, updateNotificationsList, notificationsTimeline, hasMore, loading, setPageNumber, meta } = useNotification();
  const client = useClient();
  const basePath = window.env.REACT_APP_BASE_PATH;

  const handleNotificationRead = async notification => {
    const { data, error, errors } = await client.mutate(['notifications', notification.id], { unread: false });
    setNotificationsTimeline(notificationsTimeline);
  };

  useEffect(() => {
    getNotificationForTimeline(useType, courseId);
  }, []);

  useEffect(() => {
    if (pageNumber !== 1) {
      updateNotificationsList(useType, courseId);
    }
  }, [pageNumber]);

  return (
    <>
      {loading && <Loader />}
      {!loading && notificationsTimeline.length > 0 && (
        <InfiniteScroll
          dataLength={notificationsTimeline.length}
          next={() => setPageNumber(pageNumber + 1)}
          hasMore={hasMore}
          loader={<Loader />}
          // endMessage={<EndScroll />}
        >
          <ul
            className="activity-log"
            aria-label="Registro de atividades"
          >
            <li className="activity-log__header">
              <h3 className="activity-log__header-title">{t('dashboard.latestUpdates')}</h3>
              <p className="activity-log__count">
                <strong>{meta?.unread}</strong> {t('dashboard.unreadNotifications')}
              </p>
            </li>

            {notificationsTimeline &&
              notificationsTimeline.map((notification, index) => {
                return (
                  <li
                    className={`activity-log__item ${notification.unread ? '' : 'is-read'}`}
                    key={index}
                  >
                    <div className="activity-log__icon">{getIcon(notification.icon)}</div>

                    <div className="activity-log__icon">{getIcon(notification['notificable-type'])}</div>

                    {notification['notificable-type'] == 'LearningSystemUserItem' && (notification['use-type'] == 'timeline' || notification['use-type'] == 'timelineAdmin') ? (
                      <div className="activity-log__icon activity-log__icon--trophy">
                        <AiOutlineTrophy />
                      </div>
                    ) : null}

                    <time className="activity-log__datetime">{moment(notification['created-at']).fromNow()}</time>
                    <h3 className="activity-log__title">
                      {notification.name} {notification.tag && <span className="activity-log__tag activity-log__tag--course">{notification.tag}</span>}
                      {notification['notificable-type'] == 'LearningSystemUserItem' && notification['use-type'] == 'timeline' ? <span className="badge badge--tiny badge--success">{t('textsCommon.tagConcluded')}</span> : null}
                    </h3>
                    <p className="activity-log__description">
                      {notification.body &&
                        notification.body.split('*f6$').map((body, index) => {
                          if (body[0] == '>') {
                            return (
                              <Link
                                to={`${basePath}${notification.url}` || '/'}
                                className="activity-log__link"
                                onClick={() => handleNotificationRead(notification)}
                                key={index}
                              >
                                {body.substring(1, body.lenght)}
                              </Link>
                            );
                          } else {
                            return body;
                          }
                        })}
                    </p>
                  </li>
                );
              })}
          </ul>
        </InfiniteScroll>
      )}
      {!loading && !notificationsTimeline.length > 0 && (
        <div className="first-time">
          <EmptyState
            type="register"
            title={t('emptyState.noNewsTitle')}
            text={t('emptyState.noNewsText')}
          />
        </div>
      )}
    </>
  );
}
