import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Link } from '@reach/router';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import QuestionCard from './QuestionCard';
import CreateQuestion from './CreateQuestion';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import Loader from 'app/components/loader';
import EmptyState from 'app/components/EmptyState';
import InfiniteScroll from 'react-infinite-scroll-component';
import EndScroll from 'app/components/endScroll';
import getPermission from 'app/utils/getPermission';
import { useTranslation } from 'react-i18next';
import CommentQuestion from './CommentQuestion';

function Questions({ taxonomies }) {
  const { t } = useTranslation();
  const [questions, setQuestions] = useState();
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const client = useClient();
  const basePath = window.env.REACT_APP_BASE_PATH;

  const getQuestions = async () => {
    setHasMore(true);
    setPageNumber(1);
    setLoading(true);
    let url = 'questions?page[number]=1&page[size]=10';
    if (filters.values.modality !== 'blank' && filters.values.matter == 'blank') {
      url = `questions?page[number]=1&page[size]=10&filter[modality]=${filters.values.modality}`;
    }
    if (filters.values.matter !== 'blank' && filters.values.modality == 'blank') {
      url = `questions?page[number]=1&page[size]=10&filter[taxonomies.id]=${filters.values.matter}`;
    }
    if (filters.values.matter !== 'blank' && filters.values.modality !== 'blank') {
      url = `/questions?page[number]=1&page[size]=10&filter[modality]=${filters.values.modality}&filter[taxonomies.id]=${filters.values.matter}`;
    }
    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error(t('toast.errorGetQuestions'));
    } else {
      if (data.length < 10) {
        setHasMore(false);
      }
      setQuestions(data);
      setLoading(false);
    }
  };

  const updateQuestionsList = async () => {
    let url = `questions?page[number]=${pageNumber}&page[size]=10`;
    if (filters.values.modality !== 'blank' && filters.values.matter == 'blank') {
      url = `questions?page[number]=${pageNumber}&page[size]=10&filter[modality]=${filters.values.modality}`;
    }
    if (filters.values.matter !== 'blank' && filters.values.modality == 'blank') {
      url = `questions?page[number]=${pageNumber}&page[size]=10&filter[taxonomies.id]=${filters.values.matter}`;
    }
    if (filters.values.matter !== 'blank' && filters.values.modality !== 'blank') {
      url = `/questions?page[number]=${pageNumber}&page[size]=10&filter[modality]=${filters.values.modality}&filter[taxonomies.id]=${filters.values.matter}`;
    }

    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error(t('toast.errorGetQuestions'));
    } else {
      if (data.length === 0) {
        setHasMore(false);
      }
      setQuestions([...questions, ...data]);
    }
  };

  useEffect(() => {
    if (pageNumber !== 1) {
      updateQuestionsList();
    }
  }, [pageNumber]);

  const filters = useFormik({
    initialValues: {
      matter: 'blank',
      modality: 'blank'
    }
  });

  const modalitys = [
    { id: 1, name: t('exams.selectMultipleChoice') },
    { id: 2, name: t('exams.selectTrueOrFalse') }
  ];

  useEffect(() => {
    getQuestions();
  }, [filters.values.matter, filters.values.modality]);

  const handleDeleteQuestion = questionId => {
    setQuestions(questions.filter(q => q.id !== questionId));
  };

  return (
    <>
      <div className="filter-bar">
        <div className="filter-bar__inner">
          <FilterSelectionBox
            label={t('filter.selectSubject')}
            blankLabel={t('filter.blankLabelAll')}
            value={filters.values.matter}
            onChange={e => {
              filters.setFieldValue('matter', e.target.value);
            }}
            options={taxonomies}
          />
          <FilterSelectionBox
            label={t('exams.labelQuestionType')}
            blankLabel={t('filter.blankLabelAll')}
            value={filters.values.modality}
            onChange={e => {
              filters.setFieldValue('modality', e.target.value);
            }}
            options={modalitys.map(item => item)}
          />
        </div>
        {getPermission('Criar questões', 'Questões e apostilas') && (
          <Link
            className="btn btn--primary btn--wide"
            to={`${basePath}/questoes/criar/nome`}
          >
            {t('exams.buttonNewQuestion')}
          </Link>
        )}
      </div>

      {!loading && questions?.length > 0 && (
        <InfiniteScroll
          dataLength={questions.length}
          next={() => setPageNumber(pageNumber + 1)}
          hasMore={hasMore}
          loader={<Loader />}
          // endMessage={<EndScroll />}
          className="infinite-scroll__full"
        >
          <div
            className="round-dg-wrapper"
            style={{ minWidth: '890px' }}
          >
            <table className="round-dg round-dg--light">
              <thead className="round-dg__header">
                <tr className="round-dg__row">
                  <th
                    className="round-dg__cell-header round-dg__cell-header--sm"
                    style={{ width: '60px' }}
                  >
                    {t('exams.tableHeaderType')}
                  </th>
                  <th className="round-dg__cell-header round-dg__cell-header--sm">{t('exams.tableHeaderQuestion')}</th>
                  <th
                    className="round-dg__cell-header round-dg__cell-header--sm"
                    style={{ width: '80px' }}
                  >
                    ID
                  </th>
                  <th
                    className="round-dg__cell-header round-dg__cell-header--sm"
                    style={{ width: '120px' }}
                  >
                    {t('exams.tableHeaderArea')}
                  </th>
                  <th
                    className="round-dg__cell-header round-dg__cell-header--sm"
                    style={{ width: '144px' }}
                  >
                    {t('textsCommon.matters')}
                  </th>
                  <th
                    className="round-dg__cell-header round-dg__cell-header--sm"
                    style={{ width: '80px' }}
                  >
                    {t('exams.tableHeaderPublication')}
                  </th>
                  <th
                    className="round-dg__cell-header round-dg__cell-header--sm"
                    style={{ width: '88px' }}
                  >
                    {t('exams.tableHeaderExclusivity')}
                  </th>
                  <th
                    className="round-dg__cell-header round-dg__cell-header--sm"
                    style={{ width: '132px' }}
                  >
                    {t('exams.tableHeaderAuthorship')}
                  </th>
                  <th
                    className="round-dg__cell-header round-dg__cell-header--sm"
                    style={{ width: '48px' }}
                  />
                </tr>
              </thead>

              <tbody className="round-dg__body">
                {questions?.map((q, i) => (
                  <QuestionCard
                    handleDeleteQuestion={handleDeleteQuestion}
                    question={q}
                    key={i}
                    id={i}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </InfiniteScroll>
      )}

      {loading === true && <Loader />}

      {questions !== undefined && questions.length === 0 && loading === false && (
        <div style={{ paddingTop: 50 }}>
          <EmptyState type="data" />
        </div>
      )}
    </>
  );
}

export { Questions as default, CreateQuestion, CommentQuestion };
