import React, { useState, useEffect } from 'react';
import Modal from 'app/components/Modal';
import Loader from '../components/loader';
import Progress from '../components/Progress';
import { useSession } from '../hooks/useSession';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import userAvatar from '../images/user-avatar.svg';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

function OpenResults(props) {
  const { t } = useTranslation();
  const client = useClient();
  const { session } = useSession();
  const [isLoading, setIsLoading] = useState(true);
  const [myResult, setMyResult] = useState();
  const [questionBook, setQuestionBook] = useState();
  const [groupedAnswers, setGroupedAnswers] = useState();
  const [harderTaxonomy, setHarderTaxonomy] = useState();
  const [easyestTaxonomy, setEasyestTaxonomy] = useState();
  const [activeModality, setActiveModality] = useState();
  const [groupedQuestionBooksUsers, setGroupedQuestionBooksUsers] = useState();
  const [show, setShow] = useState(false);

  useEffect(() => {
    getQuestionBook();
    getQuestionBooksUsers();
  }, []);

  const getQuestionBook = async () => {
    try {
      setIsLoading(true);
      const filterQuestionBook = { filter: { visibility: 'open' } };
      const { data } = await client.fetch(['question_books', props.id, filterQuestionBook]);
      setQuestionBook(data);

      setGroupedAnswers(data['ranking-results'].grouped_answers);
      const easyestTaxonomyId = data['ranking-results'].easyest_taxonomy?.taxonomy_id;
      const harderTaxonomyId = data['ranking-results'].harder_taxonomy?.taxonomy_id;
      if (easyestTaxonomyId) {
        setEasyestTaxonomy(data.taxonomies.find(t => t.id === easyestTaxonomyId)?.name);
      }
      if (harderTaxonomyId) {
        setHarderTaxonomy(data.taxonomies.find(t => t.id === harderTaxonomyId)?.name);
      }

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      toast.error('Erro ao buscar caderno do open.');
    }
  };

  const getQuestionBooksUsers = async () => {
    try {
      setIsLoading(true);
      const filterQuestionBook = { filter: { question_book_id: props.id, done: true, ranking: true } };
      const { data } = await client.fetch(['question_books_users', null, filterQuestionBook]);

      const groupedResponse = _.groupBy(data, 'modality');
      setActiveModality('Geral');
      setGroupedQuestionBooksUsers(groupedResponse);

      let userResult = data.find(qbu => session.user.id === qbu.user?.id);
      if (userResult) {
        userResult.position = (groupedResponse[userResult.modality].indexOf(userResult) || 0) + 1;
      }
      setMyResult(userResult);

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      toast.error('Erro ao buscar resultados do open.');
    }
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className="main-screen">
      <main className="main-content">
        <div className="open-ranking__page-header">
          <div className="open-ranking__page-header__title">{questionBook?.title}</div>
          <div>
            <a
              className="btn btn--primary"
              href={`/answer-book/${questionBook?.id}`}
            >
              Caderno de respostas
            </a>
            <a
              className="btn btn--primary"
              href="https://estudologia-4secretariado.s3.us-west-2.amazonaws.com/uploads/Lista-Open7-com-gabarito.pdf"
              target="_blank"
            >
              Gabarito oficial
            </a>
            <a
              className="btn btn--primary"
              href="https://estudologia-4secretariado.s3.us-west-2.amazonaws.com/uploads/Lista-Open7-com-gabarito-comentado.pdf"
              target="_blank"
            >
              Gabarito comentado
            </a>
          </div>
        </div>

        <div className="side-content side-content--transparent">
          <div className="open-ranking">
            <div className="tab">
              {groupedQuestionBooksUsers &&
                Object.keys(groupedQuestionBooksUsers).map((key, index) => (
                  <div
                    className={`tab__link ${key == activeModality ? 'active' : ''}`}
                    onClick={() => setActiveModality(key)}
                  >
                    {key}
                  </div>
                ))}
            </div>

            {groupedQuestionBooksUsers &&
              activeModality &&
              groupedQuestionBooksUsers[activeModality].map((questionBooksUser, index) => {
                const customClass = session.user.id === questionBooksUser.user?.id ? 'active' : '';
                return (
                  <div
                    key={index}
                    className={`open-ranking__item ${customClass}`}
                  >
                    <div className="open-ranking__body">
                      <div
                        className="open-ranking__avatar"
                        style={{ backgroundImage: `url(${questionBooksUser.user?.image?.url || userAvatar})` }}
                      ></div>
                      <div className="open-ranking__description">
                        <b>{questionBooksUser.user?.name}</b>{' '}
                      </div>
                    </div>
                    <div className="open-ranking__place">{index + 1}º </div>
                    <div className="open-ranking__percentual">{parseFloat(questionBooksUser.score)} pts</div>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="side-content side-content--transparent">
          <div className="side-content--transparent">
            <div className="open-ranking__metrics">
              <div className="open-ranking__metrics-item">
                <div className="open-ranking__metrics-item__name">Média geral</div>
                <div className="open-ranking__metrics-item__value">{questionBook && parseFloat(questionBook['ranking-results'].average_score_percentage).toFixed(2)}%</div>
              </div>
              <div className="open-ranking__metrics-item">
                <div className="open-ranking__metrics-item__name">+ Difícil</div>
                <div className="open-ranking__metrics-item__value">{harderTaxonomy}</div>
              </div>
              <div className="open-ranking__metrics-item">
                <div className="open-ranking__metrics-item__name">Dificuldade</div>
                <div className="open-ranking__metrics-item__value">{questionBook && questionBook['t-difficulty']}</div>
              </div>
              <div className="open-ranking__metrics-item">
                <div className="open-ranking__metrics-item__name">+ Fácil</div>
                <div className="open-ranking__metrics-item__value">{easyestTaxonomy}</div>
              </div>
            </div>
          </div>
          <div className="side-content side-content--full">
            <div className="open-ranking__result-header">
              {myResult && (
                <div className="open-ranking__cta-btn">
                  <a
                    className="btn btn--outline btn--full"
                    onClick={() => {
                      setShow(true);
                    }}
                    type="button"
                  >
                    Meu caderno de respostas
                  </a>
                </div>
              )}
            </div>
            {myResult && (
              <div className="open-ranking__header">
                <div className="open-ranking__header-content">
                  <div className="open-ranking__header-place">
                    <b>{myResult.position}º</b> lugar
                  </div>
                  <div className="open-ranking__header-percentual">
                    <b>{myResult.modality}</b>
                  </div>
                </div>
                {myResult && (
                  <div className="open-ranking__circles">
                    <div className="open-ranking__circle">
                      <Progress.Circular valueNow={myResult['score-results'].correct.percentage.toFixed(1)} />
                      <div className="open-ranking__circle__label">Acertou</div>
                    </div>

                    <div className="open-ranking__circle">
                      <Progress.Circular valueNow={myResult['score-results'].wrong.percentage.toFixed(1)} />
                      <div className="open-ranking__circle__label">Errou</div>
                    </div>

                    <div className="open-ranking__circle">
                      <Progress.Circular valueNow={myResult['score-results'].blank.percentage.toFixed(1)} />
                      <div className="open-ranking__circle__label">Em branco</div>
                    </div>

                    <div className="open-ranking__circle open-ranking__circle--final">
                      <Progress.Circular valueNow={parseFloat(myResult['score-percentage']).toFixed(1)} />
                      <div className="open-ranking__circle__label">Resultado final</div>
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className="open-ranking__results">
              {myResult ? (
                <>
                  <div className="open-ranking__results-item open-ranking__results-item--header">
                    <div className="open-ranking__results-item__name">
                      <b>{t("textsCommon.matter")}</b>
                    </div>
                    <div className="open-ranking__results-item__progress"></div>
                    <div className="open-ranking__results-item__percentual">Eu</div>
                    <div className="open-ranking__results-item__percentual">{t('nps.toggleAll')}</div>
                  </div>

                  {myResult.results.map((result, index) => (
                    <div
                      key={index}
                      className="open-ranking__results-item"
                    >
                      <div className="open-ranking__results-item__name">{result.name}</div>
                      <div className="open-ranking__results-item__progress">
                        <div
                          className="open-ranking__results-item__progress-bar"
                          style={{ width: `${result.score}%` }}
                        ></div>
                      </div>
                      <div className="open-ranking__results-item__percentual">{parseFloat(result.score).toFixed(2)}%</div>
                      <div className="open-ranking__results-item__percentual">{parseFloat(result.score_global).toFixed(2)}%</div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="user-dashboard-task-list__empty">
                  <div className="user-dashboard-task-list__empty-title">
                    <b>Você não participou deste simulado.</b>
                  </div>
                </div>
              )}

              <div className="open-ranking__cta">
                <div className="open-ranking__cta-btn"></div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          show={show}
          onClose={() => setShow(false)}
        >
          <div className="feedback">
            {myResult &&
              groupedAnswers &&
              myResult.answers.map((result, index) => {
                let response = 'deixou em branco';
                if (result.is_correct != null) {
                  response = result.is_correct ? 'acertou' : 'errou';
                }
                const responseClass = result.is_correct == false ? 'danger' : 'success';
                const question = groupedAnswers.find(e => e.question_id === result.question_id);

                if (result.canceled) {
                  return (
                    <div
                      key={index}
                      className="feedback__item"
                    >
                      <div className="feedback__item-answer">{result.label}</div>
                      <div className="feedback__item-response feedback__item-response--canceled">Questão anulada</div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={index}
                      className="feedback__item"
                    >
                      <div className="feedback__item-answer">{result.label}</div>
                      <div className={`feedback__item-response ${responseClass}`}>{response}</div>
                      {question?.items.map((item, index) => (
                        <div
                          key={item}
                          className="feedback__item-response-percentual"
                        >
                          {parseFloat(item.percentage).toFixed(0)}% {item.label}
                        </div>
                      ))}
                    </div>
                  );
                }
              })}
          </div>
        </Modal>
      </main>
    </div>
  );
}

export default OpenResults;
