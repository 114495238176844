import React from 'react';
import { Link, navigate } from '@reach/router';
import { FiChevronLeft } from 'react-icons/fi';

export default function BreadCrumbs(props) {
  const { data, actions, borderless, innerContent } = props;

  return (
    <div className={borderless ? 'filter-bar filter-bar--bread-crumbs filter-bar--borderless' : 'filter-bar filter-bar--bread-crumbs'}>
      {data?.title && <h1 className="filter-bar__hat">{data?.title}</h1>}

      <div
        className="filter-bar__inner"
        style={{ alignSelf: 'center' }}
      >
        {data?.nav?.map((item, index, arr) => (
          <div
            className={!item?.isActive ? 'filter-bar__back filter-bar__back--disabled' : 'filter-bar__back'}
            onClick={() => {
              navigate(index >= 1 ? arr[index - 1]?.route : item.route);
            }}
            key={index}
          >
            <span className="card__icon-wrapper">
              <FiChevronLeft className="card__icon" />
            </span>
            <span className="filter-bar__back-container">
              <span className="filter-bar__title">{item?.name}</span>
            </span>
          </div>
        ))}

        {innerContent && innerContent}
      </div>

      {actions && actions}
    </div>
  );
}
