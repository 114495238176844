import React, { useEffect, useMemo, useState } from 'react';
import { FiChevronLeft, FiTrash2 } from 'react-icons/fi';
import { navigate } from '@reach/router';
import { useFormik } from 'formik';
import 'tippy.js/dist/tippy.css';
import TextareaAutosize from 'react-autosize-textarea';
import userAvatar from 'app/images/user-avatar.svg';
import { useClient, useQuery } from 'jsonapi-react';
import toast from 'react-hot-toast';
import Modal, { SimpleModal } from 'app/components/Modal';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from 'app/components/BreadCrumbs';

export default function EssayCorrection({ studentId, essayId, uri }) {
  const client = useClient();
  const { t } = useTranslation();
  const [studentEssay, setStudentEssay] = useState({});
  const [initialIndex, setInitialIndex] = useState(null);
  const [finalIndex, setFinalIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [observation, setObservation] = useState('');
  const [show, setShow] = useState(false);
  const [deletedCorrections, setDeletedCorrections] = useState([]);
  const [isOpenCancel, setIsOpenCancel] = useState(false);
  const [parsedText, setParsedText] = useState('');
  const isAnnulled = studentEssay?.status === 'annulled';
  const basePath = window.env.REACT_APP_BASE_PATH;

  const { data: essay } = useQuery(`/essays/${essayId}/essay_students/${studentId}`);
  const { data: essayAnnulments } = useQuery(studentEssay?.status && !isAnnulled ? `essay_annulments` : null);

  useEffect(() => {
    setStudentEssay(essay);
  }, [essay]);

  const switchDigital = useFormik({
    initialValues: {
      digital: true,
      competence: 'c1'
    }
  });

  useEffect(() => {
    if (studentEssay && studentEssay?.status === 'annulled') {
      switchDigital.setFieldValue('digital', false);
    }
  }, [studentEssay]);

  const essayForm = useFormik({
    initialValues: {
      suggestion_to_improve: '',
      body: '',
      essay_student_competencies_attributes: [],
      essay_student_corrections_attributes: []
    }
  });

  const competenceSelected = essayForm?.values.essay_student_competencies_attributes?.find(item => item?.slug === switchDigital?.values?.competence);

  const competencies = [
    { name: 'Competencia 1', description: 'Domínio da Norma Culta da Língua Portuguesa' },
    { name: 'Competencia 2', description: 'Compreensão e Aplicação Interdisciplinar do Tema Proposto' },
    { name: 'Competencia 3', description: 'Seleção e Organização Lógica de Argumentos' },
    { name: 'Competencia 4', description: 'Construção Coesa e Coerente da Argumentação' },
    { name: 'Competencia 5', description: 'Proposição de Intervenção Respeitosa aos Direitos Humanos' }
  ];

  function valuesToArray(obj) {
    return Object.keys(obj).map(function (key) {
      return obj[key];
    });
  }

  useEffect(() => {
    if (studentEssay) {
      essayForm.setValues({
        body: studentEssay.body,
        suggestion_to_improve: studentEssay.suggestion_to_improve,
        essay_student_corrections_attributes: studentEssay?.corrections,
        essay_student_competencies_attributes: studentEssay?.competencies
      });
    }
  }, [studentEssay]);

  const handleSubmitCorrection = () => {
    if (initialIndex !== null && finalIndex !== null) {
      essayForm.setFieldValue(`essay_student_corrections_attributes`, [...essayForm.values.essay_student_corrections_attributes, { body: observation, start_index: initialIndex, end_index: finalIndex }]);
    }
  };

  const sendEvaluation = async () => {
    if (isAnnulled) {
      const { data: essayUpdated, error } = await client.mutate(['essays', essayId, 'essay_students', studentId], {
        essay_student_content_attributes: {
          suggestion_to_improve: essayForm.values.suggestion_to_improve
        }
      });

      if (error) {
        toast.error('Erro ao avaliar redação');
      } else {
        toast.success('Correção enviada!');
        setStudentEssay(prev => ({ ...prev, suggestion_to_improve: essayUpdated.suggestion_to_improve }));
      }
    } else {
      const auxValues = { ...essayForm.values };
      //auxValues.id = studentEssay.essay_student_content_id;
      auxValues.essay_student_corrections_attributes = [...auxValues.essay_student_corrections_attributes, ...deletedCorrections];
      auxValues.essay_student_competencies_attributes = essayForm.values.essay_student_competencies_attributes.map(item => ({
        competency_id: String(item.competency_id)
      }));

      //Remove id
      auxValues.essay_student_corrections_attributes = auxValues.essay_student_corrections_attributes.map(item => {
        const { id, ...itemWithoutId } = item;
        return itemWithoutId;
      });

      const { error } = await client.mutate(['essays', essayId, 'essay_students', studentId], { essay_student_content_attributes: { ...auxValues } });

      if (error) {
        toast.error('Erro ao avaliar redação');
      } else {
        toast.success('Correção enviada!');
        navigate(`${basePath}/redacao/acompanhamento/${essayId}`);
      }
    }
  };

  useEffect(() => {
    renderTextWithMarks();
  }, [essayForm.values.essay_student_corrections_attributes]);

  const renderTextWithMarks = () => {
    const text = essayForm.values.body;
    const selections = essayForm?.values.essay_student_corrections_attributes?.sort((a, b) => a.start_index - b.start_index);
    if (selections === undefined || selections.length === 0) {
      return text;
    } else {
      let new_text = '';
      let acumulator = '';
      let selectionIndex = 0;
      let counter = 0;

      text?.split('').forEach((c, i) => {
        if (c === '<' && text[i + 2] === '>') {
          counter += 3;
        } else if (c === '<' && text[i + 3] === '>') {
          counter += 4;
        }

        if (i >= selections[selectionIndex]?.start_index + counter && i < selections[selectionIndex]?.end_index + counter) {
          acumulator += c;
        } else if (i === selections[selectionIndex]?.end_index + counter) {
          new_text += `<mark>${acumulator}</mark>${c}`;
          acumulator = '';
          if (selections.length > selectionIndex) {
            selectionIndex += 1;
          }
        } else {
          new_text += c;
        }
      });
      setParsedText(new_text);
    }
  };

  const handleMouseUp = () => {
    const selection = window.getSelection();
    const start = selection.anchorOffset;
    const end = selection.focusOffset;

    if (start !== end) {
      setShowModal(true);
      setFinalIndex(end);
      setInitialIndex(start);
    }
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmitCorrection();
      setShowModal(false);
      setInitialIndex(null);
      setFinalIndex(null);
      setObservation('');
    }
  };

  const handleDeleteCorrection = (startIndex, endIndex) => {
    Swal.fire({
      title: 'Tem certeza que deseja excluir a marcação?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('button.delete'),
      cancelButtonText: t('button.cancel'),
      showCloseButton: true
    }).then(result => {
      if (result.isConfirmed) {
        const filteredCorrections = essayForm?.values?.essay_student_corrections_attributes?.filter(item => {
          if (item.start_index !== startIndex && item.end_index !== endIndex) {
            return true;
          } else if (item.id) {
            setDeletedCorrections(prevState => [...prevState, { ...item, _destroy: true }]);
          }
        });

        essayForm.setFieldValue(`essay_student_corrections_attributes`, filteredCorrections);
      }
    });
  };

  const handleOpenCancelEssayModal = () => {
    setIsOpenCancel(prev => !prev);
  };

  const handleSubmitCancel = async e => {
    e.preventDefault();

    const data = new FormData(e.target);
    const formProps = Object.fromEntries(data);

    if (!formProps.reason) {
      toast.error('Selecione um motivo');
      return;
    }

    const { data: annulmentData, error } = await client.mutate(['essays', essayId, 'essay_students', studentId], { essay_annulment_id: formProps.reason });

    if (error) {
      toast.error('Erro ao anular redação');
    } else {
      setStudentEssay(annulmentData);
      toast.success('Redação anulada com sucesso!');
      setIsOpenCancel(false);
    }
  };

  const essayStatus = {
    accepting_proposal: { text: 'Aceitando respostas', badgeColor: 'warning' },
    close_for_submissions: { text: 'Prazo encerrado', badgeColor: 'danger' },
    processing: { text: 'Em correção', badgeColor: 'warning' },
    unreviewed: { text: 'Em correção', badgeColor: 'warning' },
    reviewed: { text: 'Corrigida', badgeColor: 'success' },
    annulled: { text: 'Anulado', badgeColor: 'danger' }
  };

  const breadCrumbs = {
    title: 'Atividades',
    nav: [
      {
        route: `${basePath}/redacao`,
        name: 'Redação',
        isActive: false
      },
      {
        route: `${basePath}/redacao/acompanhamento/${essayId}`,
        name: essay?.topic,
        isActive: false
      },
      {
        route: uri,
        name: 'Correção',
        isActive: true
      }
    ]
  };

  return (
    <main className="main-content main-content--block">
      <BreadCrumbs data={breadCrumbs} />

      <div className="filter-bar">
        <div className="filter-bar__inner">
          <button
            onClick={() => navigate(`${basePath}/redacao/acompanhamento/${essayId}`)}
            className="filter-bar__back"
          >
            <span className="card__icon-wrapper">
              <FiChevronLeft className="card__icon" />
            </span>

            <span className="filter-bar__back-container">
              <span className="filter-bar__title">Correção de redação</span>
            </span>
          </button>
        </div>
      </div>

      <div className="widget-wrapper">
        <div className="widget widget--center">
          <div className="widget__header">
            <h3 className="widget__title">Aluno</h3>
          </div>

          <div className="widget__body">
            <div
              className="round-dg__user u-cursor-pointer"
              onClick={() => navigate(`${basePath}/usuario/metricas/${studentEssay?.user?.id}`)}
            >
              <img
                className="avatar avatar--xs"
                src={studentEssay?.user?.image || userAvatar}
                alt="Imagem do usuário"
              />

              <div className="round-dg__user-inner">
                <h3
                  className="round-dg__title"
                  title={studentEssay?.user?.name}
                >
                  {studentEssay?.user?.name}
                </h3>
              </div>
            </div>
          </div>
        </div>

        <div className="widget widget--center">
          <div className="widget__header">
            <h3 className="widget__title">Nota</h3>
          </div>

          <div className="widget__body">
            <span className="square-tag square-tag--md square-tag--warning">
              {essayForm?.values?.essay_student_competencies_attributes?.reduce((acc, item) => {
                return acc + item?.score;
              }, 0)}
              <span>/{studentEssay?.max_grade}</span>
            </span>
          </div>
        </div>

        <div className="widget widget--center">
          <div className="widget__header">
            <h3 className="widget__title">Data da entrega</h3>
          </div>

          <div className="widget__body">
            <span className="kpi-card__value">{new Date(studentEssay?.delivery_date).toLocaleDateString()}</span>
          </div>
        </div>

        <div className="widget widget--center">
          <div className="widget__header">
            <h3 className="widget__title">Status da correção</h3>
          </div>

          <div className="widget__body">
            <span className={`badge badge--${essayStatus[studentEssay?.status]?.badgeColor}`}>{essayStatus[studentEssay?.status]?.text}</span>
          </div>
        </div>
      </div>

      <div className="skill-evaluation">
        <div className="skill-evaluation__header">
          <h1 className="skill-evaluation__title">Sugestão para avaliação de competências</h1>
        </div>

        <div className="skill-evaluation__body">
          <div className="skill-evaluation__col">
            <div className="skill-evaluation__essay">
              {studentEssay?.status !== 'annulled' && <span className="badge badge--redaction">Para adicionar observações ao texto, basta selecionar o trecho desejado </span>}

              <div className="skill-evaluation__essay-header">
                <h2 className="skill-evaluation__essay-title">Redação avaliada</h2>
                {studentEssay?.status !== 'annulled' && (
                  <div className="form__check form__switch u-mb-0">
                    <input
                      className="form__check-input"
                      id="digital"
                      name="digital"
                      type="checkbox"
                      checked={switchDigital.values.digital}
                      onChange={() => {
                        switchDigital.setFieldValue('digital', !switchDigital.values.digital);
                      }}
                    />
                    <label htmlFor="digital">{switchDigital.values.digital ? 'Digital' : 'Cursiva'}</label>
                  </div>
                )}
              </div>

              {switchDigital.values.digital ? (
                <div className="skill-evaluation__essay-body">
                  <div className="skill-evaluation__essay-text">
                    <span
                      className="skill-evaluation__essay-raw_text"
                      // onMouseDown={() => handleMouseDown(index)}
                      onMouseUp={handleMouseUp}
                      dangerouslySetInnerHTML={{ __html: essayForm.values.body }}
                    />
                    <span
                      className="skill-evaluation__essay-mark"
                      dangerouslySetInnerHTML={{ __html: parsedText }}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <img
                    className="u-cursor-pointer"
                    src={studentEssay?.essay_image?.url}
                    alt="prova digitalizada"
                    onClick={() => setShow(true)}
                  />
                  <SimpleModal
                    show={show}
                    onClose={() => setShow(false)}
                    contentClassName="simple-modal__content--lg"
                  >
                    <img
                      src={studentEssay?.essay_image?.url}
                      alt="prova digitalizada"
                    />
                  </SimpleModal>
                </>
              )}
            </div>
          </div>

          <div className="skill-evaluation__col">
            {!isAnnulled && (
              <div className="skill-evaluation__card u-mb-4">
                <div
                  className="u-mb-4"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Navegue pelas competências"
                  >
                    {essayForm?.values?.essay_student_competencies_attributes?.length > 0 &&
                      essayForm?.values?.essay_student_competencies_attributes?.map(btn => {
                        return (
                          <React.Fragment key={btn?.competency_id}>
                            <input
                              type="radio"
                              id={btn?.competency_id}
                              name="competence"
                              value={btn?.slug}
                              autoComplete="off"
                              checked={switchDigital.values.competence === btn?.slug}
                              onChange={e => switchDigital.setFieldValue('competence', e.target.value)}
                            />
                            <label
                              htmlFor={btn?.competency_id}
                              className="btn btn--outline"
                            >
                              {btn?.slug?.toUpperCase()}
                            </label>
                          </React.Fragment>
                        );
                      })}
                  </div>

                  <button
                    type="button"
                    className="btn btn--primary"
                    onClick={handleOpenCancelEssayModal}
                    style={{ height: '32px' }}
                  >
                    Anular Redação
                  </button>
                </div>

                {essayForm?.values?.essay_student_competencies_attributes?.map((item, index, array) => {
                  if (item?.slug === switchDigital.values.competence) {
                    return (
                      <div
                        key={index}
                        className="skill-evaluation__competence fadeIn"
                      >
                        <div className="skill-evaluation__competence-header">
                          <div className="skill-evaluation__competence-header-inner">
                            <h3 className="skill-evaluation__competence-title">{competencies?.[index]?.name}</h3>
                            <p className="skill-evaluation__competence-subtitle">{item?.comment}</p>
                          </div>

                          <div
                            className="skill-evaluation__points"
                            style={{ pointerEvents: 'none' }}
                          >
                            <input
                              type="text"
                              name="points"
                              id="points"
                              value={item?.score}
                              min={1}
                              max={item.max_grade}
                              size={2}
                              maxLength={3}
                              // onChange={e => essayForm.setFieldValue(`essay_student_competencies_attributes[${index}.score]`, parseInt(e.target.value))}
                            />
                            <span className="skill-evaluation__points-max">/{item.max_grade}</span>
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="level"
                            className="skill-evaluation__comment-title"
                          >
                            Nível e comentário
                          </label>
                          <select
                            id="level"
                            name="level"
                            className="form__select"
                            value={essayForm?.values?.essay_student_competencies_attributes?.[index]?.competency_id}
                            onChange={e => {
                              const selectedOption = e.target.options[e.target.selectedIndex];
                              const selectedScore = selectedOption.getAttribute('data-score');
                              essayForm.setFieldValue(`essay_student_competencies_attributes[${index}].competency_id`, e.target.value);
                              essayForm.setFieldValue(`essay_student_competencies_attributes[${index}].score`, parseInt(selectedScore));
                            }}
                          >
                            {valuesToArray(studentEssay?.competencies_list?.[competenceSelected?.name])?.map((item, index) => (
                              <option
                                key={item.id}
                                value={item.id}
                                data-score={item?.score}
                              >
                                Nível {index}
                              </option>
                            ))}
                          </select>

                          <TextareaAutosize
                            name="commentary"
                            style={{ minHeight: 60, marginTop: 8 }}
                            disabled
                            value={valuesToArray(studentEssay?.competencies_list?.[competenceSelected?.name]).find(comp => comp.id === Number(item.competency_id))?.comment}
                          />
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            )}
            <div className="skill-evaluation__card u-mb-4">
              {!isAnnulled && essayForm?.values?.essay_student_corrections_attributes?.length > 0 && (
                <div className="skill-evaluation__competence fadeIn">
                  <div className="skill-evaluation__competence-body">
                    <div className="skill-evaluation__comment">
                      <h4 className="skill-evaluation__comment-title">Marcações</h4>
                      <ul className="skill-evaluation__comment-list">
                        {essayForm?.values?.essay_student_corrections_attributes
                          ?.sort((a, b) => a.start_index - b.start_index)
                          .map((item, index) => {
                            return (
                              <li
                                key={index}
                                className="skill-evaluation__comment-item"
                              >
                                <TextareaAutosize
                                  style={{ minHeight: 60 }}
                                  value={item.body}
                                  onChange={e => essayForm.setFieldValue(`essay_student_corrections_attributes[${index}].body`, e.target.value)}
                                />
                                <FiTrash2
                                  className="skill-evaluation__icon skill-evaluation__icon--danger"
                                  onClick={() => handleDeleteCorrection(item.start_index, item.end_index)}
                                />
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              )}

              {isAnnulled && (
                <div className="skill-evaluation__competence-body">
                  <h3 className="skill-evaluation__competence-title">Redação Anulada</h3>
                  <p className="skill-evaluation__competence-subtitle">{studentEssay?.annulment?.body}</p>
                </div>
              )}

              <div className="skill-evaluation__improve">
                <h4 className="skill-evaluation__improve-title">Sugestões gerais para melhorar a redação</h4>
                <TextareaAutosize
                  onChange={e => essayForm.setFieldValue('suggestion_to_improve', e.target.value)}
                  value={essayForm?.values?.suggestion_to_improve}
                />
              </div>
            </div>

            <button
              onClick={sendEvaluation}
              className="btn btn--wide btn--primary"
            >
              Enviar correção
            </button>
          </div>
        </div>
      </div>

      <Modal
        show={isOpenCancel}
        onClose={() => setIsOpenCancel(false)}
      >
        <div style={{ width: '100%', borderBottom: '1px solid rgba(223, 223, 223, 0.5)', paddingBottom: 20, marginBottom: 20 }}>
          <h2 className="form__title">Anular redação</h2>
          <span>Selecione o motivo da anulação:</span>
        </div>

        <form onSubmit={handleSubmitCancel}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 20, borderBottom: '1px solid rgba(223, 223, 223, 0.5)', paddingBottom: 20, marginBottom: 20 }}>
            {essayAnnulments?.map(item => (
              <label key={item.id}>
                <input
                  id="reason"
                  type="radio"
                  value={item.id}
                  name="reason"
                />
                {item.body}
              </label>
            ))}
          </div>

          <button
            type="submit"
            className="btn btn--primary btn--full"
          >
            Salvar
          </button>
        </form>
      </Modal>

      <SimpleModal
        show={showModal}
        onClose={() => setShowModal(false)}
        hideCloseButton
      >
        <label className="form__label">Observação</label>
        <textarea
          value={observation}
          onChange={e => setObservation(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </SimpleModal>
    </main>
  );
}
