import React, { useMemo, useState } from 'react';
import { FiChevronLeft, FiMoreHorizontal } from 'react-icons/fi';
import EmptyState from 'app/components/EmptyState';
import Modal from 'app/components/Modal';
import ParticipantsForm from './ParticipantsForm';
import Dropdown from 'app/components/Dropdown';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import getPermission from 'app/utils/getPermission';
import UserInfo from 'app/components/UserInfo';
import Loader from 'app/components/loader';
import moment from 'moment';
import { t } from 'i18next';
import BreadCrumbs from 'app/components/BreadCrumbs';

export function ManageClassroomParticipants(props) {
  const { classroomId, uri } = props;
  const client = useClient();
  const [showModal, setShowModal] = useState(false);
  const [classroom, setClassroom] = useState(null);
  const [loading, setLoading] = useState(true);
  const [allUsers, setAllUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [registerForm, setRegisterForm] = useState('Todos');
  const basePath = window.env.REACT_APP_BASE_PATH;

  const getClassroom = async () => {
    const { data, error } = await client.fetch(`classrooms/${classroomId}`);
    if (error) {
      toast.error(t('toast.errorGetClassesInfo'));
    } else {
      setClassroom(data);
    }
  };

  const getAllUsers = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`users/full?filter[classrooms.id]=${classroomId}`);
    if (error) {
      toast.error(t('toast.errorGetParticipants'));
    } else {
      setAllUsers(data);
      setLoading(false);
    }
  };

  const addParticipantToClassroom = async id => {
    const { data, error } = await client.mutate(['classrooms', classroom.id], {
      'user-ids': [...classroom?.['user-ids'], id]
    });

    if (error) {
      toast.error(t('toast.errorAddParticipant'));
    } else {
      setClassroom(data);
    }
  };

  const deleteParticipantFromClassroom = async id => {
    const { data, error } = await client.mutate(['classrooms', classroom.id], {
      'user-ids': classroom['user-ids'].filter(i => i !== id)
    });
    if (error) {
      toast.error(t('toast.errorDeleteParticipants'));
    } else {
      setClassroom(data);
      toast.success(t('toast.successAddParticipant'));
    }
  };

  const moreThanThreeDays = date => {
    const lastAccess = moment(date);
    const threeDaysAgo = moment().subtract(3, 'days');

    if (date === null) {
      return <span className="badge badge--tiny badge--warning">{t('journey.isInactive')}</span>;
    } else if (lastAccess.isBefore(threeDaysAgo)) {
      return <span className="badge badge--tiny badge--warning">{t('journey.isInactive')}</span>;
    } else {
      return <span className="badge badge--tiny badge--success">{t('journey.isActive')}</span>;
    }
  };

  const filteredUsers = useMemo(() => {
    const filterByText = allUsers.filter(item => searchTerm === '' || item.name.toLowerCase().includes(searchTerm.toLowerCase()));

    const filterByRegisterForm = filterByText.filter(item => registerForm === 'Todos' || item?.['registration-form'] === registerForm);

    return filterByRegisterForm;
  }, [searchTerm, allUsers, registerForm]);

  const registrationMethod = form => {
    switch (form) {
      case 'manual':
        return 'Manual';
      case 'invite':
        return 'Convite';
      case 'spreadsheet':
        return 'Importação por planilha';
      case 'organization_structure':
        return 'Organograma';
    }
  };

  const allRoles = useMemo(() => {
    const roles = [];

    allUsers.forEach(item => {
      roles.push(item?.['registration-form']);
    });

    return roles
      .filter((item, index) => {
        return item.indexOf(item) === index;
      })
      .map(item => ({ value: item, label: registrationMethod(item) }));
  }, [allUsers]);

  useEffect(() => {
    getClassroom();
    getAllUsers();
  }, []);

  const breadCrumbs = {
    title: 'Organização da Plataforma',
    nav: [
      {
        route: `${basePath}/turmas`,
        name: 'Turmas',
        isActive: false
      },
      {
        route: uri,
        name: classroom?.title,
        isActive: true
      }
    ]
  };

  return (
    <main className="main-content main-content--block">
      <BreadCrumbs data={breadCrumbs} />
      <div className="card__badge-group">
        {classroom?.['organizational-charts'].map(item => (
          <span
            title={item.title}
            style={{ backgroundColor: '#E4FEEE', maxWidth: 90 }}
            className="badge u-mb-3"
          >
            {item.title}
          </span>
        ))}
      </div>

      <div className="filter-bar">
        <div className="filter-bar__inner">
          <div className="filter-bar__row">
            <label
              htmlFor="search"
              className="filter-bar__label"
            >
              {t('textsCommon.research')}
            </label>
            <input
              className="form__control form__control--search-with-icon"
              placeholder={t('filter.participant')}
              type="search"
              name="search"
              id="search"
              onChange={e => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="filter-bar__row">
            <label
              htmlFor="search"
              className="filter-bar__label"
            >
              Forma de cadastro
            </label>
            <select
              className="form__select"
              placeholder={t('filter.participant')}
              name="registration-form"
              id="registration-form"
              onChange={e => setRegisterForm(e.target.value)}
            >
              <option value="Todos">Todos</option>
              {allRoles.map(item => (
                <option value={item.value}>{item.label}</option>
              ))}
            </select>
          </div>
        </div>

        {getPermission('Gerenciar turmas', 'Turmas') && (
          <div className="filter-bar__actions">
            <button
              className="btn btn--wide btn--primary"
              onClick={() => setShowModal(true)}
            >
              {t('courses.addParticpants')}
            </button>
          </div>
        )}
      </div>

      {loading && <Loader />}

      {!loading && !allUsers.length > 0 && (
        <EmptyState
          text={
            <span>
              <strong>Adicione</strong> à turma participantes que estão na plataforma.
            </span>
          }
        />
      )}

      {filteredUsers.length > 0 && (
        <div className="fadeIn">
          <section className="archive-section">
            <div className="round-dg-wrapper">
              <table
                className="round-dg round-dg--light"
                style={{ borderSpacing: '0 10px', minWidth: '800px' }}
              >
                <thead className="round-dg__header">
                  <tr className="round-dg__row">
                    <th className="round-dg__cell-header">Participante</th>
                    <th className="round-dg__cell-header">E-mail</th>
                    <th className="round-dg__cell-header">{t('courses.thRegistrationMethod')}</th>
                    <th className="round-dg__cell-header">Lotação</th>
                    <th className="round-dg__cell-header" />
                    <th
                      className="round-dg__cell-header"
                      style={{ width: '66px' }}
                    />
                  </tr>
                </thead>

                <tbody className="round-dg__body">
                  {filteredUsers?.map((user, i) => {
                    return (
                      <tr
                        key={user.id}
                        className="round-dg__row"
                      >
                        <th className="round-dg__cell-header round-dg__cell-header--row">
                          <div className="round-dg__user">
                            <UserInfo user={user} />
                          </div>
                        </th>
                        <td className="round-dg__cell round-dg__cell--wrap">{user.email}</td>
                        <td className="round-dg__cell">{registrationMethod(user['registration-form'])}</td>
                        <td className="round-dg__cell">{user?.['organizational-charts']?.[0]?.title}</td>
                        <td className="round-dg__cell">{moreThanThreeDays(user['last-access'])}</td>

                        <td className="round-dg__cell">
                          {getPermission('Gerenciar turmas', 'Turmas') && (
                            <Dropdown>
                              <Dropdown.Toggle>
                                <FiMoreHorizontal />
                              </Dropdown.Toggle>

                              <Dropdown.Content>
                                <Dropdown.Item
                                  onClick={() => {
                                    Swal.fire({
                                      title: t('projects.warningDeleteStudent'),
                                      text: t('projects.warningDeleteCannotBeUndoneText'),
                                      confirmButtonText: t('button.yes'),
                                      showDenyButton: true,
                                      denyButtonText: t('button.no'),
                                      showCloseButton: true
                                    }).then(res => {
                                      if (res.isConfirmed) {
                                        deleteParticipantFromClassroom(user.id);
                                      }
                                    });
                                  }}
                                >
                                  {t('button.remove')}
                                </Dropdown.Item>
                              </Dropdown.Content>
                            </Dropdown>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </section>
        </div>
      )}

      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
      >
        <ParticipantsForm
          setShowModal={setShowModal}
          classroom={classroom}
          allUsers={allUsers}
          addParticipantToClassroom={addParticipantToClassroom}
          deleteParticipantFromClassroom={deleteParticipantFromClassroom}
        />
      </Modal>
    </main>
  );
}
