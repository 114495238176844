import React, { useState, useEffect } from 'react';
import { navigate, useLocation, useParams } from '@reach/router';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import ModalTaxonomies from 'app/components/modalTaxonomies';
import StepName from './StepName';
import StepVideolesson from './StepVideolesson';
import StepVideoQuestions from './StepVideoQuestions';
import { useTranslation } from 'react-i18next';
import promiseRequest from 'app/utils/promiseToast';
import ScheduleAlert from 'app/components/ScheduleAlert';
import ScheduleModal from 'app/components/ScheduleModal';
import moment from 'moment';

export default function LessonContent(props) {
  const { t } = useTranslation();
  const { promiseToast, isSubmitting } = promiseRequest();
  const { setCurrentStep, step, steps, currentStep, learningSystemContentId, moduleId, disciplineId, editLesson, abilities } = props;
  const basePath = window.env.REACT_APP_BASE_PATH;

  const client = useClient();
  const [taxonomies, setTaxonomies] = useState([]);
  const [isOpenModalTaxonomies, setIsOpenModalTaxonomies] = useState(false);
  const [currentDisciplineId, setCurrentDisciplineId] = useState(learningSystemContentId);
  const [lessons, setLessons] = useState([]);
  const [deletedQuestions, setDeletedQuestions] = useState([]);
  const { lessonId } = useParams();
  const [loading, setLoading] = useState(true);
  const [originalIds, setOriginalIds] = useState();
  const [urlError, setUrlError] = useState();
  const [showScheduleAlert, setShowScheduleAlert] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const [navigateLink, setNavigateLink] = useState(null);
  const [loadingTaxonomies, setLoadingTaxonomies] = useState(true);

  const getLessons = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`/learning_system_items?filter[learning_system_content_id]=${learningSystemContentId}`);
    if (error) {
      toast.error('Falha ao carregar aulas');
    } else {
      setLessons(data);
      const currentLesson = data.find(item => item.id == lessonId);

      setOriginalIds(currentLesson?.quiz_items?.map(item => item.id));
      createLessonForm?.setFieldValue('unrequired', !currentLesson?.unrequired);
      createLessonForm?.setFieldValue('body', currentLesson?.body);
      createLessonForm?.setFieldValue(
        'selectedTaxonomies',
        currentLesson?.taxonomies.map(item => ({ label: item.name, value: item.id }))
      );
      createLessonForm?.setFieldValue('url', currentLesson?.url);
      createLessonForm?.setFieldValue('quizItemsAttributes', currentLesson?.quiz_items);
      createLessonForm?.setFieldValue('published_at', currentLesson?.['published_at']);
      createLessonForm?.setFieldValue('published', currentLesson?.['published']);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (lessonId) {
      getLessons();
    } else {
      setLoading(false);
    }
  }, []);

  const createLessonForm = useFormik({
    initialValues: {
      body: '',
      'learning-system-content-id': learningSystemContentId,
      url: '',
      selectedTaxonomies: null,
      quizItemsAttributes: [],
      learningSystemQuestionBooksAttributes: [],
      learningSystemFilesAttributes: [],
      learningSystemActivitiesAttributes: [],
      unrequired: true
    },
    onSubmit: () => {
      handleSubmit;
    }
  });

  const fetchTaxonomies = async () => {
    setLoadingTaxonomies(true);
    try {
      const { data } = await client.fetch('taxonomies');
      setTaxonomies(data);
    } catch (e) {
      toast.error(t('toast.errorGetTags'));
    } finally {
      setLoadingTaxonomies(false);
    }
  };

  useEffect(() => {
    fetchTaxonomies();
  }, []);

  const createLesson = async () => {
    if (!createLessonForm.values.url) {
      return toast.error('Não é possível criar uma aula sem vídeo');
    } else {
      const auxValues = { ...createLessonForm.values };

      auxValues.unrequired = !auxValues.unrequired;

      auxValues['taxonomy-ids'] = auxValues.selectedTaxonomies.map(item => item.value);

      let quizItemUpdate = [];

      if (lessonId) {
        quizItemUpdate = auxValues.quizItemsAttributes.map(item => {
          return {
            duration: item.duration,
            id: item.id,
            'quiz-item-alternatives-attributes': item.quiz_item_alternatives,
            body: item.body,
            'taxonomy-ids': (item.selectedTaxonomies || []).map(t => t.id)
          };
        });
      }
      let quizItemDelete = deletedQuestions.map(item => {
        return { id: item.id, _destroy: 1 };
      });

      let quizItemCreate = [];
      if (!lessonId) {
        quizItemCreate = auxValues.quizItemsAttributes.map(item => {
          return {
            duration: item.duration,
            id: item.id,
            'quiz-item-alternatives-attributes': item.quiz_item_alternatives,
            body: item.body,
            'taxonomy-ids': (item.selectedTaxonomies || []).map(t => t.id)
          };
        });
      }

      auxValues['quiz-items-attributes'] = [...quizItemDelete, ...quizItemUpdate, ...quizItemCreate];

      auxValues['learning-system-question-books-attributes'] = [];

      auxValues['learning-system-files-attributes'] = [];

      auxValues['learning-system-activities-attributes'] = [];

      delete auxValues.selectedTaxonomies;
      delete auxValues.learningSystemQuestionBooksAttributes;
      delete auxValues.learningSystemFilesAttributes;
      delete auxValues.learningSystemActivitiesAttributes;
      delete auxValues.quizItemsAttributes;

      const path = lessonId ? ['/learning_system_items', lessonId] : '/learning_system_items';

      promiseToast({
        url: path,
        request: auxValues,
        successText: 'Salvo com sucesso!',
        errorText: 'Erro ao salvar Aula' //default
      })
        .then(data => {
          if (data) {
            setCurrentDisciplineId(data.id);
            setShowScheduleAlert(true);
          }
        })
        .catch(error => {
          if (error?.id === 'url') toast.error(error?.title);
        });
    }
  };

  const handleCloseAlertSchedule = () => {
    setShowScheduleAlert(false);
    navigate(`${basePath}/curso/admin/gerenciar/${location.state.courseId}/${location.state.moduleId}/disciplinas-e-aulas/${disciplineId}`, {
      state: {
        courseId: location.state.courseId,
        moduleId: location.state.moduleId,
        disciplineId: location.state.disciplineId
      }
    });
  };

  const btnsStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '24px'
  };
  const [submited, setSubmited] = useState(false);

  useEffect(() => {
    if (submited) {
      if (!Boolean(createLessonForm.values.body)) {
        setBodyError('Campo obrigatório');
      }
      if (!Boolean(createLessonForm.values.selectedTaxonomies)) {
        setTaxonomieError('Campo obrigatório');
      }
    }
  }, [createLessonForm.values, submited]);

  const [bodyError, setBodyError] = useState();
  const [taxonomieError, setTaxonomieError] = useState();
  console.log({ urlError });
  const handleSubmit = e => {
    setSubmited(true);
    e.preventDefault();
    switch (step) {
      case 'nome':
        if (lessonId) {
          if (Boolean(createLessonForm.values.body) && Boolean(createLessonForm.values.selectedTaxonomies)) {
            navigate(`${basePath}/lms/criar/aula/${learningSystemContentId}/videoaula/${lessonId}/${location.state.courseId}`, { state: { moduleId: moduleId, disciplineId: disciplineId, courseId: location.state.courseId, editLesson: true } });
            setCurrentStep(1);
            setSubmited(false);
          }
          break;
        } else {
          if (Boolean(createLessonForm.values.body) && Boolean(createLessonForm.values.selectedTaxonomies)) {
            navigate(`${basePath}/lms/criar/aula/${learningSystemContentId}/videoaula/${location.state.courseId}`, {
              state: { moduleId: moduleId, disciplineId: disciplineId, courseId: location.state.courseId }
            });
            setCurrentStep(1);
            setSubmited(false);
          }
          break;
        }
      case 'videoaula':
        if (createLessonForm.values.url) {
          const youtubeRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
          const vimeoRegex = /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;
          const youtubeResult = createLessonForm.values.url.match(youtubeRegex);
          const vimeoResult = createLessonForm.values.url.match(vimeoRegex);
          if (youtubeResult || vimeoResult) {
            if (lessonId && abilities?.quiz && editLesson) {
              navigate(`${basePath}/lms/criar/aula/${learningSystemContentId}/perguntas/${lessonId}/${location.state.courseId}`, {
                state: {
                  moduleId: location.state.moduleId,
                  disciplineId: location.state.disciplineId,
                  courseId: location.state.courseId
                }
              });
              setSubmited(false);
              setCurrentStep(2);
              setUrlError(null);
            } else {
              createLesson();
            }
          } else {
            if (submited) {
              setUrlError('Link do vídeo inválido');
            }
          }
          break;
        } else if (!createLessonForm.values.url) {
          return setUrlError(t('warning.requiredField'));
        }
      case 'perguntas':
        createLesson();
        break;
    }
  };

  const updateSchedule = async publishedDate => {
    const scheduleDate = publishedDate ? { 'published-at': moment(publishedDate).format() } : { published: true };

    const { error } = await client.mutate(`/learning_system_items/${lessonId ? lessonId : currentDisciplineId}`, scheduleDate);

    if (error) {
      toast.error('Erro ao agendar aula');
      return;
    }
  };

  useEffect(() => {
    setNavigateLink(`${basePath}/curso/admin/gerenciar/${location.state.courseId}/${location.state.moduleId}/disciplinas-e-aulas/${disciplineId}`, {
      state: {
        courseId: location.state.courseId,
        moduleId: location.state.moduleId,
        disciplineId: location.state.disciplineId
      }
    });
  }, [location]);

  return (
    <div className="page page--wrap">
      <div className="page__col">
        <form
          className="form form--step-by-step"
          method="post"
          onSubmit={handleSubmit}
        >
          <StepName
            loadingTaxonomies={loadingTaxonomies}
            bodyError={bodyError}
            taxonomieError={taxonomieError}
            step={step}
            createLessonForm={createLessonForm}
            setIsOpenModalTaxonomies={setIsOpenModalTaxonomies}
            loading={loading}
            taxonomies={taxonomies}
            editLesson={editLesson}
          />

          <StepVideolesson
            urlError={urlError}
            step={step}
            createLessonForm={createLessonForm}
            editLesson={editLesson}
          />

          <StepVideoQuestions
            setDeletedQuestions={setDeletedQuestions}
            deletedQuestions={deletedQuestions}
            step={step}
            createLessonForm={createLessonForm}
            lessonId={lessonId}
            lessons={lessons}
            editLesson={editLesson}
          />

          <div style={btnsStyles}>
            {step !== 'nome' && (
              <button
                type="button"
                className="btn btn--outline btn--wide"
                onClick={() => {
                  if (lessonId) {
                    navigate(`${basePath}/lms/criar/aula/${learningSystemContentId}/${steps?.[currentStep - 1]?.route}/${lessonId}/${location.state.courseId}`, {
                      state: {
                        moduleId: location.state.moduleId,
                        disciplineId: location.state.disciplineId,
                        courseId: location.state.courseId
                      }
                    });
                    setCurrentStep(currentStep - 1);
                  } else {
                    navigate(`${basePath}/lms/criar/aula/${learningSystemContentId}/${steps?.[currentStep - 1]?.route}/${location.state.courseId}`, {
                      state: {
                        moduleId: location.state.moduleId,
                        disciplineId: location.state.disciplineId,
                        courseId: location.state.courseId
                      }
                    });
                    setCurrentStep(currentStep - 1);
                  }
                }}
              >
                {t('exams.previous')}
              </button>
            )}

            <button
              type="submit"
              className={isSubmitting ? 'btn btn--primary btn--wide disabled' : 'btn btn--primary'}
            >
              {!editLesson && step === 'videoaula' ? t('button.finish') : t('button.next') && step !== 'perguntas' ? t('button.next') : t('button.finish')}
            </button>

            <ScheduleAlert
              show={showScheduleAlert}
              onClose={() => handleCloseAlertSchedule()}
              setShowModal={setShowModal}
              onUpdate={updateSchedule}
              navigateLink={navigateLink}
              title="Você deseja publicar a aula?"
            />

            <ScheduleModal
              show={showModal}
              onClose={() => setShowModal(true)}
              onUpdate={updateSchedule}
              navigateLink={navigateLink}
              fieldValue={lessonId ? moment(createLessonForm?.values?.['published-at']).format('YYYY-MM-DD') : ''}
            />

            {createLessonForm.values.title && createLessonForm.values.videoUrl && step !== 'perguntas' && (
              <button
                type="submit"
                className="btn btn--link u-ml-auto"
              >
                {t('button.finish')}
              </button>
            )}
          </div>
        </form>

        {taxonomies.length > 0 && isOpenModalTaxonomies && (
          <ModalTaxonomies
            taxonomies={taxonomies}
            selectedTaxonomies={createLessonForm?.values?.selectedTaxonomies}
            setFieldValue={createLessonForm.setFieldValue}
            closeModal={() => setIsOpenModalTaxonomies(false)}
          />
        )}
      </div>

      <div className="page__aside" />
    </div>
  );
}
