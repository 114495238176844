import React, { createContext, useState, useCallback } from 'react';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { t } from 'i18next';

export const NoteContext = createContext();

export const NoteProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('anotacoes-privadas');
  const [activeNote, setActiveNote] = useState(null);
  const [notes, setNotes] = useState([]);
  const [newNotesClasses, setNewNoteClasses] = useState(); //Tag nova anotação da classe
  const [nextBody, setNextBody] = useState('');
  const [hasMoreNewClassroomNotes, setHasMoreNewClassroomNotes] = useState(false);

  const client = useClient();

  const getNotes = useCallback(
    async classroom => {
      setLoading(true);
      let filter = 'notes';
      if (activeTab === 'anotacoes-de-turma' && !!classroom) {
        setLoading(true);
        filter = `notes?filter[classrooms.id]=${classroom.id}`;
      } else if (activeTab === 'anotacoes-importantes') {
        setLoading(true);
        filter = `notes?filter[bookmarked]=true`;
      }
      const { data, error } = await client.fetch(filter);

      if (error) {
        toast.error('Erro ao buscar anotações.');
      } else {
        setNotes(data);
      }

      setLoading(false);
    },
    [client, activeTab]
  );

  const getNewClassroomNotes = async () => {
    const { data, error } = await client.fetch(`/classrooms?has_new_note=true&page[size]=10&page[number]=1`);
    if (error) {
      toast.error(t('Erro ao buscar informação das turmas'));
    } else {
      setNewNoteClasses(data);
    }
  };

  const updateNewClassroomNotes = async pageNumber => {
    setHasMoreNewClassroomNotes(true);
    const { data, error } = await client.fetch(`/classrooms?has_new_note=true&page[size]=10&page[number]=${pageNumber}`);

    if (error) {
      toast.error(t('Erro ao buscar informação das turmas'));
      setHasMoreNewClassroomNotes(false);
    } else {
      if (data.length === 0) {
        setHasMoreNewClassroomNotes(false);
      }
      setNewNoteClasses(oldState => [...oldState, ...data]);
    }
  };

  const createNote = async form => {
    setLoading(true);
    const { data, error } = await client.mutate('notes', form);
    if (error) {
      toast.error(t('toast.errorCreateNotes'));
    } else {
      toast.success(t('toast.successCreateNotes'));
      setNotes([data, ...notes]);
      setActiveNote(data);
      setLoading(false);
    }
  };

  const updateNote = async (note, nextBody) => {
    setLoading(true);
    const { data, error } = await client.mutate(['notes', note.id], {
      title: note.title,
      body: nextBody
    });
    if (error) {
      toast.error('Erro ao editar anotação.');
    } else {
      note.body = data;
      getNotes(note?.classroom);
    }
  };

  const deleteNote = async activeNote => {
    setLoading(true);
    const { error } = await client.delete(['notes', activeNote.id]);
    if (error) {
      toast.error(t('toast.errorDeleteNotes'));
    } else {
      toast.success(t('toast.successDeleteNotes'));
      const newNotes = notes.filter(note => note.id !== activeNote.id);
      setNotes(newNotes);
      setActiveNote('');
      setLoading(false);
    }
  };

  const setBookmarked = async activeNote => {
    setLoading(true);
    try {
      const { error, data } = await client.mutate(['notes', activeNote, 'bookmarked'], { bookmarked: true });
      if (error) {
        toast.error('Erro ao marcar anotação como importante.');
      } else {
        setNotes(notes.map(n => (n.id === activeNote ? data : n)));
        toast.success('Anotação marcada como importante!');
      }
    } catch (error) {
      toast.error('Erro ao marcar anotação como importante.');
    } finally {
      setLoading(false);
    }
  };

  const unmarkNote = async activeNote => {
    try {
      const { error, data } = await client.mutate(['notes', activeNote, 'unbookmarked'], { bookmarked: false });
      if (error) {
        toast.error('Erro ao desmarcar anotação como importante.');
      } else {
        setNotes(notes.map(n => (n.id === activeNote ? data : n)));
        toast.success('Anotação desmarcada como importante!');
      }
    } catch (error) {
      toast.error('Erro ao desmarcar anotação como importante.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <NoteContext.Provider
      value={{
        loading,
        createNote,
        updateNote,
        getNotes,
        notes,
        setNotes,
        nextBody,
        setNextBody,
        activeTab,
        setActiveTab,
        deleteNote,
        activeNote,
        setActiveNote,
        setBookmarked,
        unmarkNote,
        getNewClassroomNotes,
        newNotesClasses,
        updateNewClassroomNotes,
        hasMoreNewClassroomNotes,
        showModal,
        showNotes,
        notesModalOpen,
        setShowModal,
        setShowNotes,
        setNotesModalOpen
      }}
    >
      {children}
    </NoteContext.Provider>
  );
};
