import React, { useEffect, useState } from 'react';
import { navigate, useLocation, useParams } from '@reach/router';
import { FiChevronLeft, FiUsers } from 'react-icons/fi';
import MainSidebar from 'app/components/mainSidebar';
import TabNavBar from './TabNavBar';
import ChatWidget from './ChatWidget';
import LessonWidget from './LessonWidget';
import { useAuditorium } from 'app/hooks/useAuditorium';
import ActionCableConsumer from 'app/utils/actionCableConsumer';
import Loader from 'app/components/loader';
import getPermission from 'app/utils/getPermission';
import Error404 from 'app/components/Error404';
import { useClient } from 'jsonapi-react';
import ActionCable from 'actioncable';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import IconVerified from 'app/components/Icons/IconVerified';
import BreadCrumbs from 'app/components/BreadCrumbs';

const TEN_SECONDS_IN_MILLISECONDS = 10 * 1000;

export default function ViewAuditorium(props) {
  const { uri } = props;
  const basePath = window.env.REACT_APP_BASE_PATH;

  if (!getPermission('Visualizar auditórios', 'Auditórios')) {
    return <Error404 />;
  }

  const client = useClient();
  const { t } = useTranslation();
  const id = useParams().auditoriumId;
  const { getMessages, messages, setMessages, auditorium, getAuditorium, lastMessage, getFiles, files, setFiles, loading, resetState } = useAuditorium();
  const [chats, setChats] = useState([]);
  const [page, setPage] = useState(1);
  const [activeTab, setActiveTab] = useState('chat');
  const [messageChannel, setMessageChannel] = useState(null);
  const [messageChatChannel, setMessageChatChannel] = useState(null);
  const [hasMoreComments, setHasMoreComments] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [countUsers, setCountUsers] = useState(0);
  const [pageLesson, setPageLesson] = useState(1);
  const apiBaseUrl = client.config.url;
  const cableUrl = apiBaseUrl.replace(/^http(s)?:\/\/(.*?)\/api\/v1\/?$/, 'wss://$2/cable');
  const cable = ActionCable.createConsumer(cableUrl);
  let channel;

  useEffect(() => {
    const loadAuditorium = async () => {
      await getAuditorium(id);
      await getMessages(id, 1);
      await getFiles(id);
      setIsLoading(false);
    };

    resetState();
    loadAuditorium();

    channel = cable.subscriptions.create({
      channel: 'AuditoriumStatusChannel'
    });

    const intervalId = setInterval(() => {
      channel.send({
        action: 'relay_message',
        message: {
          auditorium_id: id,
          status: 'Online'
        }
      });
    }, TEN_SECONDS_IN_MILLISECONDS);

    return () => {
      channel.unsubscribe();
      clearInterval(intervalId);
    };
  }, [id]);

  const getChats = async () => {
    try {
      const { data, error } = await client.fetch(`auditoriums/${id}/auditorium_chat_messages?page[number]=${page}&page[size]=10&order=created_at desc`);

      if (error) {
        toast.error(t('toast.errorSearchChatMessage'));
        return;
      }

      if (data.length === 0) {
        setHasMoreComments(false);
        return;
      }

      if (data.length < 10) {
        setHasMoreComments(false);
      }

      if (chats) {
        setChats(prevState => [...data, ...prevState]);
      } else {
        setChats(data);
      }
    } catch {
      setHasMoreComments(false);
    }
  };

  useEffect(() => {
    if (messageChatChannel) {
      const parsedMessage = JSON.parse(messageChatChannel);
      if (parsedMessage.data.type === 'auditorium-chat-message') {
        const newMessage = parsedMessage.data.attributes;
        if (chats.length < 10) {
          setChats(prevState => [...prevState, newMessage]);
        } else {
          const lastTenComments = chats.slice(1, 10);
          setChats([...lastTenComments, newMessage]);
        }
      }
    }
  }, [messageChatChannel]);

  useEffect(() => {
    if (page !== 1) {
      getChats();
    }
  }, [page]);

  useEffect(() => {
    if (messageChannel) {
      if (messageChannel?.count) {
        setCountUsers(messageChannel?.count);
      } else {
        const parsedMessage = JSON.parse(messageChannel);
        if (parsedMessage?.data?.attributes?.file) {
          const file = files.find(item => item.id === parsedMessage?.data?.attributes?.id);
          if (!file) {
            setFiles(prevFiles => [...prevFiles, parsedMessage?.data?.attributes]);
          }
        } else {
          const message = messages.find(item => item.id === parsedMessage?.data?.attributes?.id);
          if (!message) {
            setMessages(prevMessages => [...prevMessages, parsedMessage?.data?.attributes]);
          }
        }
      }
    }
  }, [messageChannel, id]);

  useEffect(() => {
    channel = cable.subscriptions.create({
      channel: 'AuditoriumStatusChannel'
    });

    const intervalId = setInterval(() => {
      channel.send({
        action: 'relay_message',
        message: {
          auditorium_id: id,
          status: 'Online'
        }
      });
    }, TEN_SECONDS_IN_MILLISECONDS);

    async function loadData() {
      await Promise.all([getAuditorium(id), getMessages(id, pageLesson), getFiles(id), getChats()]).finally(() => setIsLoading(false));
    }

    loadData();

    return () => {
      channel.unsubscribe();
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (pageLesson !== 1) {
      getMessages(id, pageLesson);
    }
  }, [pageLesson]);

  const breadCrumbs = {
    title: 'Comunidade',
    nav: [
      {
        route: `${basePath}/auditorio`,
        name: 'Monitoria',
        isActive: false
      },
      {
        route: uri,
        name: auditorium?.name,
        isActive: true
      }
    ]
  };

  return (
    <div className="main-screen">
      <MainSidebar />
      <ActionCableConsumer
        channel="AuditoriumMessageChannel"
        setObjectChannel={setMessageChannel}
        channelKey={'auditorium_id'}
        channelId={id}
      />
      <ActionCableConsumer
        channel="AuditoriumChatMessageChannel"
        setObjectChannel={setMessageChatChannel}
        channelKey={'auditorium_id'}
        channelId={id}
      />

      <main className="main-content main-content--auditorium">
        <BreadCrumbs
          data={breadCrumbs}
          innerContent={auditorium?.['official-content'] && <IconVerified tooltipPlace="right" />}
        />

        <div className="page">
          {!auditorium.name || isLoading ? (
            <Loader />
          ) : (
            <>
              <div className="page__col page__col--lesson">
                {countUsers > 0 && (
                  <span
                    className="badge badge--tiny badge--more"
                    style={{ cursor: 'default', width: 'fit-content', marginBottom: '15px' }}
                  >
                    <FiUsers /> {countUsers} {t('textsCommon.watchingNow')}
                  </span>
                )}

                {messages && (
                  <LessonWidget
                    id={id}
                    lastMessage={lastMessage}
                    messages={messages}
                    auditorium={auditorium}
                    chats={chats}
                    setPage={setPageLesson}
                  />
                )}
              </div>

              <aside className="page__aside page__aside--chat">
                <TabNavBar
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />

                <ChatWidget
                  activeTab={activeTab}
                  id={id}
                  files={files}
                  messageChats={chats}
                  setChats={setChats}
                  hasMoreComments={hasMoreComments}
                  setPage={setPage}
                />
              </aside>
            </>
          )}
        </div>
      </main>
    </div>
  );
}
