import { useTranslation } from 'react-i18next';

import { TopBar } from './TopBar';
import { ShadowContainer } from './ShadowContainer';

import choosingChannelPng from '../../images/onboarding/feed/choosing-channel.png';
import publishingPng from '../../images/onboarding/feed/publishing.png';
import publishingInteractingPng from '../../images/onboarding/feed/publishing-interacting.png';
import participantsListPng from '../../images/onboarding/feed/participants-list.png';

export function FeedOnboarding() {
  const { t } = useTranslation();

  return (
    <section>
      <TopBar />

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.choosingChanel')}</h1>
        <p className="text-option-onboarding">{t('onboarding.choosingChanelDesc')}</p>

        <ShadowContainer width="fit-content">
          <img src={choosingChannelPng} />
        </ShadowContainer>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.publishingInFeed')}</h1>
        <p className="text-option-onboarding">{t('onboarding.publishingInFeedDesc')}</p>

        <img src={publishingPng} className="w-70" />
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.interactingWithPublications')}</h1>
        <p className="text-option-onboarding">{t('onboarding.interactingWithPublicationsDesc')}</p>

        <ShadowContainer width="70%">
          <img src={publishingInteractingPng} />
        </ShadowContainer>
      </div>

      <div>
        <h1 className="title-option-onboarding">{t('onboarding.participantsList')}</h1>
        <p className="text-option-onboarding">{t('onboarding.participantsListDesc')}</p>

        <ShadowContainer width="fit-content">
          <img src={participantsListPng} />
        </ShadowContainer>
      </div>
    </section>
  );
}
