import React, { useState, createContext } from 'react';
import { navigate } from '@reach/router';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { useSession } from '../hooks/useSession';
import { t } from 'i18next';
import promiseRequest from 'app/utils/promiseToast';

export const SurveyContext = createContext();

export const SurveyProvider = ({ children }) => {
  const [mySurvey, setMySurvey] = useState([]);
  const [surveys, setSurveys] = useState([]);
  const [activeSurvey, setActiveSurvey] = useState(null);
  const [currentSurvey, setCurrentSurvey] = useState([]);

  const { session } = useSession();
  const { promiseToast } = promiseRequest();
  //Student hooks
  const [studentSurvey, setStudentSurvey] = useState([]);
  const [currentStudentSurvey, setCurrentStudentSurvey] = useState([]);

  const [loading, setLoading] = useState(true);
  const client = useClient();

  const basePath = window.env.REACT_APP_BASE_PATH;

  const getSurvey = async filters => {
    setLoading(true);

    let url = 'surveys';

    if (filters) {
      if (filters.course !== 'blank') {
        url += `?filter[courses.id]=${filters.course}&`;
      }
    }

    const { data, error } = await client.fetch(url);

    if (error) {
      toast.error(t('toast.errorGetQuiz'));
    } else {
      const mySurveys = data.filter(item => item['user-id'] === session?.user?.id);
      setMySurvey(mySurveys);
      setSurveys(data);
      setLoading(false);
    }
  };

  const createSurvey = async form => {
    promiseToast({
      url: 'surveys',
      request: form,
      successText: t('toast.successCreateQuiz'),
      errorText: t('toast.errorCreateQuiz')
    }).then(data => {
      const representation = data?.representation === 'stars' ? 'estrela' : 'aberto';
      navigate(`${basePath}/questionario/criar/${representation}/${data.id}`);
    });
  };

  const deleteSurvey = async id => {
    setLoading(true);
    const { error } = await client.delete(['surveys', id]);
    if (error) {
      toast.error(t('toast.errorDeleteQuiz'));
    } else {
      toast.success(t('toast.successDeleteQuiz'));
      getSurvey();
    }
  };

  const updateSurvey = async (id, form) => {
    const { error } = await client.mutate(`surveys/${id}`, form);
    if (error) {
      toast.error(t('toast.errorEditQuiz'));
    } else {
      getSurvey();
      toast.success(t('toast.successEditQuiz'));
    }
  };

  // Student Part

  const getStudentSurvey = async () => {
    setLoading(true);
    setCurrentStudentSurvey(null);
    const { data, error } = await client.fetch('surveys');

    if (error) {
      toast.error(t('toast.errorGetQuiz'));
    } else {
      setStudentSurvey(data);
      setCurrentStudentSurvey(data[0]);
      setLoading(false);
    }
  };

  return (
    <SurveyContext.Provider
      value={{
        updateSurvey,
        loading,
        getSurvey,
        createSurvey,
        deleteSurvey,
        activeSurvey,
        setActiveSurvey,
        currentSurvey,
        setCurrentSurvey,
        mySurvey,
        surveys,
        getStudentSurvey,
        studentSurvey,
        currentStudentSurvey,
        setCurrentStudentSurvey
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
};
