import { useTranslation } from 'react-i18next';
import React from 'react';
import { navigate } from '@reach/router';

function TabNavBar({ selectedTab }) {
  const { t } = useTranslation();
  const basePath = window.env.REACT_APP_BASE_PATH;
  return (
    <>
      <div className="tab">
        <button
          className={`tab__link ${selectedTab === 'apostilas' ? 'active' : ''}`}
          onClick={() => navigate(`${basePath}/questions/apostilas`)}
        >
          {t('questionBooks.toggleQuestionBooks')}
        </button>
        <button
          className={`tab__link ${selectedTab === 'resultados-das-apostilas' ? 'active' : ''}`}
          onClick={() => navigate(`${basePath}/questions/resultados-das-apostilas`)}
        >
          {t('questionBooks.toggleResultExercises')}
        </button>
      </div>
    </>
  );
}

export default TabNavBar;
