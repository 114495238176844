import { ReactFlowProvider } from 'reactflow';
import TabNavBar from '../TabNavBar';
import InstitutionCard from './InstitutionCard';
import FlowModal from './modals/FlowModal';
import AddEditNodeForm from './modals/AddEditNodeForm';
import { useEffect, useState } from 'react';
import { getInstitutions } from './utils/api';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import AddEditInstitution from './modals/AddEditInstitution';
import Loader from 'app/components/loader';
import BreadCrumbs from 'app/components/BreadCrumbs';

const Institutions = (props) => {
  const { uri } = props;
  const client = useClient();

  const [institutions, setInstitutions] = useState(null);
  const [selectedInstitution, setSelectedInstitution] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const openModal = institution => {
    setSelectedInstitution(institution);
    setModalVisible(true);
  };
  const closeModal = () => {
    setSelectedInstitution(null);
    setModalVisible(false);
    setEditing(false);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await getInstitutions(client);
      setInstitutions(response);
    } catch (error) {
      console.log(error);
      toast.error('Erro ao buscar instituições');
    } finally {
      setLoading(false);
    }
  };

  const afterSubmit = () => {
    fetchData();
    closeModal();
  };

  const removeFromState = (id) => {

    const updatedInstitutions = institutions.filter( institution => institution.id !== id)
    setInstitutions(updatedInstitutions)

  }

  useEffect(() => {
    fetchData();
  }, []);

  const breadCrumbs = {
    title: 'Organização da Plataforma',
    nav: [
      {
        route: uri,
        name: 'Usuário',
        isActive: true
      }
    ]
  };

  return (
    <>
      <main className="main-content main-content--block">
        <BreadCrumbs
          data={breadCrumbs}
          borderless
        />
        <div className="filter-bar-institutions">
          <TabNavBar selectedTab={'instituicoes'} />
          <button
            className="btn btn--primary"
            onClick={() => openModal()}
          >
            Adicionar instituição
          </button>
        </div>
        <div className="fadeIn institutions-container">
          {loading && <Loader />}
          {institutions &&
            institutions.map(institution => (
              <InstitutionCard
                key={institution.id}
                institution={institution}
                onEdit={openModal}
                setEditing={setEditing}
                afterSubmit={removeFromState}
              />
            ))}
        </div>
      </main>

      {modalVisible && (
        <FlowModal
          onClose={closeModal}
          isVisible={modalVisible}
        >
          <AddEditInstitution
            institution={selectedInstitution}
            afterSubmit={afterSubmit}
            editing={editing}
          />
        </FlowModal>
      )}
    </>
  );
};

export default Institutions;
