import React from 'react';
import userAvatar from 'app/images/user-avatar.svg';
import { Link } from '@reach/router';

export default function ActivityStudentCard(props) {
  const { activityStudent } = props;
  const sent = activityStudent.comments.some(comment => comment.user.includes('Administrador'));
  const statusClass = sent ? 'badge--success' : 'badge--warning';
  const basePath = window.env.REACT_APP_BASE_PATH;

  return (
    <tr className="round-dg__row">
      <th className="round-dg__cell-header round-dg__cell-header--row">
        <div className="round-dg__user">
          <Link
            to={`${basePath}/usuario/metricas/${activityStudent.user.id}`}
            state={{ isRedirected: true }}
          >
            <img
              className="avatar avatar--sm"
              src={activityStudent?.user?.image || userAvatar}
              alt="Imagem do usuário"
            />
          </Link>

          <div className="round-dg__user-inner">
            <h3
              className="round-dg__title"
              title={activityStudent?.user?.name}
            >
              <Link
                to={`${basePath}/usuario/metricas/${activityStudent.user.id}`}
                state={{ isRedirected: true }}
              >
                {activityStudent?.user?.name}
              </Link>
            </h3>
            {/* <p className="round-dg__subtitle">email</p> */}
          </div>
        </div>
      </th>

      <td
        className="round-dg__cell"
        style={{ width: '180px' }}
      >
        <span className={`badge badge--tiny ${statusClass}`}>{sent ? 'Correção realizada' : 'Aguardando correção'}</span>
      </td>
    </tr>
  );
}
