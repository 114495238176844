import React from 'react';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useState } from 'react';
import getPermission from 'app/utils/getPermission';
import { Link } from '@reach/router';
import Loader from 'app/components/loader';
import EmptyState from 'app/components/EmptyState';
import MockQuestionBookCard from 'app/components/ProgramOfStudies/MockQuestionBookCard';
import FilterMultiSelect from 'app/components/FilterMultiSelect';
import { t } from 'i18next';
import { FiChevronDown } from 'react-icons/fi';
import Dropdown from 'app/components/Dropdown';

function MockQuestionBooks({ mockQuestionBooks, loading, courses }) {
  const [filteredMockQuestionBooks, setFilteredMockQuestionBooks] = useState([]);
  const basePath = window.env.REACT_APP_BASE_PATH;

  const filters = useFormik({
    initialValues: {
      course: 'blank',
      status: {
        realizado: false,
        naoRealizado: false
      }
    }
  });

  useEffect(() => {
    const filterByCourse = mockQuestionBooks.filter(item => {
      if (filters.values.course === 'blank') {
        return true;
      }
      if (filters.values.course) {
        return item?.['course-ids'].includes(parseInt(filters.values.course));
      }
    });

    const statusList = [];
    const items = {
      realizado: 'Realizado',
      naoRealizado: 'Não realizado'
    };

    Object.keys(items).map(key => {
      if (filters.values.status[key]) {
        statusList.push(items[key]);
      }
    });

    const filterByStatus = filterByCourse.filter(item => {
      if (statusList.length === 0) {
        return true;
      }
      if (statusList.includes(item.status)) {
        return true;
      }
    });

    setFilteredMockQuestionBooks(filterByStatus);
  }, [filters.values.course, filters.values.status.realizado, filters.values.status.naoRealizado, mockQuestionBooks]);

  const handleChange = field => {
    const newStatus = filters.values.status;
    newStatus[field] = !newStatus[field];
    filters.setFieldValue('status', newStatus);
  };

  return (
    <>
      <div className="tab__pane">
        <div className="filter-bar">
          <div className="filter-bar__inner">
            <FilterSelectionBox
              blankLabel={t('filter.blankLabelAll')}
              label={t('filter.labelSelectCourse')}
              value={filters.values.course}
              onChange={e => {
                filters.setFieldValue('course', e.target.value);
              }}
              options={courses.map(c => ({
                ...c,
                name: c.title
              }))}
            />
            <FilterMultiSelect
              data={[
                {
                  label: t('filter.done'),
                  value: filters.values.status.realizado,
                  onChange: () => handleChange('realizado')
                },
                {
                  label: t('filter.unDone'),
                  value: filters.values.status.naoRealizado,
                  onChange: () => handleChange('naoRealizado')
                }
              ]}
            />
          </div>

          <div className="filter-bar__actions">
            {getPermission('Criar simulados', 'Avaliações') || (getPermission('Gerenciar objetivos de simulados', 'Avaliações') && getPermission('Gerenciar simulados', 'Avaliações')) ? (
              <div
                className="btn-group btn-group--with-dropdown"
                role="group"
                aria-label="Ações"
              >
                {getPermission('Criar simulados', 'Avaliações') && (
                  <Link
                    to={`${basePath}/simulados/criar/curso`}
                    className="btn btn--outline btn--wide btn--small"
                  >
                    {t('button.newSimulation')}
                  </Link>
                )}

                <Dropdown className="btn btn--outline btn--small">
                  <Dropdown.Toggle>
                    <FiChevronDown />
                  </Dropdown.Toggle>
                  <Dropdown.Content>
                    {getPermission('Gerenciar objetivos de simulados', 'Avaliações') && <Dropdown.Item to={'/provas/objetivos'}>{'Acessar objetivos'}</Dropdown.Item>}
                    {getPermission('Gerenciar simulados', 'Avaliações') && <Dropdown.Item to={'/simulados/gerenciar'}>{t('button.manageSimulations')}</Dropdown.Item>}
                  </Dropdown.Content>
                </Dropdown>
              </div>
            ) : null}
          </div>
        </div>

        {loading && <Loader />}

        {!loading && (mockQuestionBooks.length === 0 || filteredMockQuestionBooks.length === 0) && <EmptyState type="data" />}

        <div className="tab__cards">
          {!loading &&
            filteredMockQuestionBooks.map(m => (
              <MockQuestionBookCard
                setFilteredMockQuestionBooks={setFilteredMockQuestionBooks}
                mockQuestionBook={m}
                key={m.id}
              />
            ))}
        </div>
      </div>
    </>
  );
}

export default MockQuestionBooks;
