import React, { useEffect } from 'react';
import { navigate } from '@reach/router';
import { FiChevronLeft, FiHelpCircle } from 'react-icons/fi';
import { t } from 'i18next';
import { useFormik } from 'formik';
import { useQuery } from 'jsonapi-react';
import moment from 'moment';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import MostAccessedTimesChart from './MostAccessedTimesChart';
import toast from 'react-hot-toast';
import { useFilteredQuery } from 'app/hooks/useFilteredQuery';
import { GeoChart } from './GeoChart';
import useReportAbilities from 'app/hooks/useReportAbilities';
import Loader from 'app/components/loader';

export default function AccessReport() {
  const filters = useFormik({
    initialValues: {
      course: 'blank',
      'start-date': moment().startOf('month').format('DD MMM YYYY'),
      'end-date': moment().endOf('month').format('DD MMM YYYY')
    }
  });

  const { data: courses = [], error, isLoading: isLoadingCourses } = useQuery('courses?simple=true');
  const { data: report, isLoading: isLoadingReport } = useFilteredQuery('regionals/access_report', filters?.values);
  const { reportAbilities, getReportAbilities } = useReportAbilities();
  const today = moment().format(t('datesForm.formatDate'));

  useEffect(() => {
    if (error) {
      toast.error('Erro ao buscar cursos');
    }
  }, [error]);

  const formatStartDate = filters.values['start-date'] ? moment(filters.values['start-date']).format('DD MMM YYYY') : null;
  const formatEndDate = filters.values['end-date'] ? moment(filters.values['end-date']).format('DD MMM YYYY') : null;

  const appName = window.env.REACT_APP_NAME;

  useEffect(() => {
    filters.setFieldValue('start-date', moment().startOf('month').format('YYYY-MM-DD'));
    filters.setFieldValue('end-date', moment().endOf('month').format('YYYY-MM-DD'));
    getReportAbilities();
  }, []);

  return (
    <main className="main-content main-content--block">
      <div className="filter-bar">
        <div className="filter-bar__inner">
          <button
            className="filter-bar__back"
            onClick={() => navigate(-1)}
          >
            <span className="card__icon-wrapper">
              <FiChevronLeft className="card__icon" />
            </span>
            <span className="filter-bar__back-container">
              <span className="filter-bar__title">Mapeamento de acesso do usuário</span>
            </span>
          </button>
        </div>
      </div>

      <div className="filter-bar filter-bar--borderless">
        <div className="filter-bar__inner">
          <div className="filter-bar__row">
            <label
              htmlFor="start-date"
              className="filter-bar__label"
            >
              Selecione um período
            </label>
            <div className="form__columns">
              <div className="form__col">
                <input
                  className="form__control"
                  id="start-date"
                  name="start-date"
                  min={today}
                  type="date"
                  value={moment(filters.values['start-date']).format('YYYY-MM-DD')}
                  onChange={e => filters.setFieldValue('start-date', e.target.value)}
                  placeholder="Data de início"
                />
              </div>
              <div className="form__col">
                <input
                  className="form__control"
                  id="end-date"
                  name="end-date"
                  type="date"
                  min={today}
                  value={moment(filters.values['end-date']).format('YYYY-MM-DD')}
                  onChange={e => filters.setFieldValue('end-date', e.target.value)}
                  placeholder="Data de fim"
                />
              </div>
            </div>
          </div>

          <FilterSelectionBox
            label={t('exams.selectCourse')}
            blankLabel={t('metrics.blankLabelSelect')}
            value={filters.values.course}
            onChange={e => filters.setFieldValue('course', e.target.value)}
            options={courses.map(o => ({
              id: o.id,
              name: o.title
            }))}
          />
        </div>
      </div>
      {isLoadingReport && <Loader />}
      {!isLoadingReport && (
        <div className="widget">
          {(appName === 'canaleducacao' || 'demo') && reportAbilities?.['mapeamento-de-acesso-do-usuario']?.['mapa-de-calor'] ? (
            <>
              <div className="widget__header">
                <div className="widget__title-with-btn u-mb-2">
                  <h3 className="widget__title">Acessos por Secretaria</h3>
                  <FiHelpCircle
                    className="widget__help-icon"
                    data-tooltip-id="access"
                    data-tooltip-content="O mapa de calor exibe no número de acessos por região entre o período selecionado, com cores variando de roxo (menos acessos) a amarelo (mais acessos)."
                  />
                  <ReactTooltip
                    id="access"
                    className="tooltip"
                  />
                </div>

                {formatStartDate && formatEndDate && (
                  <div className="u-text-right">
                    <span className="widget__date">
                      {formatStartDate} - {formatEndDate}
                    </span>
                  </div>
                )}
              </div>

              <div className="widget__body u-mb-4">
                <GeoChart regionals={report?.regionals} />
              </div>
            </>
          ) : null}

          {reportAbilities?.['mapeamento-de-acesso-do-usuario']?.['horarios-mais-acessados'] && (
            <>
              <div className="widget__header">
                <div className="widget__title-with-btn">
                  <h3 className="widget__title">Horários mais acessados (Acessos recorrentes)</h3>
                  <FiHelpCircle
                    className="widget__help-icon"
                    data-tooltip-id="timetable"
                    data-tooltip-content="O gráfico mostra a frequência de acessos por dia e hora. Tons mais escuros indicam mais atividade."
                  />
                  <ReactTooltip
                    id="timetable"
                    className="tooltip"
                  />
                </div>
              </div>
              <div className="widget__body">
                <MostAccessedTimesChart dayMetrics={report?.dayMetrics} />
              </div>
            </>
          )}
        </div>
      )}
    </main>
  );
}
