import React, { useState } from 'react';
import { FiChevronLeft, FiHeadphones, FiVideo, FiFileText, FiLink, FiFolder } from 'react-icons/fi';
import { Link, navigate } from '@reach/router';
import { MaterialsProvider } from 'app/providers/MaterialsProvider';
import { useEffect } from 'react';
import EmptyState from 'app/components/EmptyState';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import { useSession } from 'app/hooks/useSession';
import Loader from 'app/components/loader';
import { useParams } from '@reach/router';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

export const Materials = props => (
  <MaterialsProvider>
    <ViewMaterial {...props} />
  </MaterialsProvider>
);

const ViewMaterial = () => {
  const { materialId, moduleId } = useParams();
  const [loading, setLoading] = useState(false);
  const [materials, setMaterials] = useState([]);
  const client = useClient();
  const [moduleMaterials, setModuleMaterials] = useState([]);
  const [material, setMaterial] = useState([]);
  const basePath = window.env.REACT_APP_BASE_PATH;

  const getMaterial = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`/learning_system_files/${materialId}`);
    if (error) {
      toast.error('Erro ao carregar material');
    } else {
      setMaterial(data);
    }
    setLoading(false);
  };

  const getMaterials = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`/learning_system_files?filter[learning_system_id]=${moduleId}`);
    if (error) {
      toast.error('Erro ao carregar materiais deste módulo');
    } else {
      setMaterials(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    const moduleMaterials = materials.filter(item => item.id !== material.id);
    setModuleMaterials(moduleMaterials);
  }, [material, materials]);

  useEffect(() => {
    getMaterial();
    getMaterials();
  }, [materialId]);

  function icon(type) {
    switch (type) {
      case 'Áudio':
        return <FiHeadphones className="card__icon" />;
      case 'Vídeo':
        return <FiVideo className="card__icon" />;
      case 'Texto':
        return <FiFileText className="card__icon" />;
      case 'Link':
        return <FiLink className="card__icon" />;
      default:
        return <FiFileText className="card__icon" />;
    }
  }

  function backgroundImage(type) {
    switch (type?.media_type) {
      case 'audio':
        return 'https://images.pexels.com/photos/1181681/pexels-photo-1181681.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2';
      case 'video':
        return 'https://images.pexels.com/photos/4145153/pexels-photo-4145153.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2';
      case 'link':
        return 'https://images.unsplash.com/photo-1507297230445-ff678f10b524?.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2';
      case 'text':
        return 'https://images.pexels.com/photos/4050290/pexels-photo-4050290.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2';
      default:
        return 'https://images.pexels.com/photos/4050290/pexels-photo-4050290.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2';
    }
  }

  const handleClick = () => {
    setFadeIn(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(() => {
      setFadeIn(true);
    }, 50); // set the delay to match the duration of the transition
  };

  return (
    <>
      {loading && (
        <div className="main-content main-content--block">
          <Loader />
        </div>
      )}

      <div className="main-content main-content--block">
        <div className="filter-bar">
          <div className="filter-bar__inner">
            <div className="filter-bar__back">
              <div
                className="card__icon-wrapper"
                onClick={() => history.back()}
              >
                <FiChevronLeft />
              </div>
              <span className="filter-bar__back-container">
                <span className="filter-bar__title">{material?.title}</span>
              </span>
            </div>
          </div>

          <div className="filter-bar__actions">
            <Link
              className="btn btn--wide btn--small btn--outline"
              to={`${basePath}/curso/admin/gerenciar/${material?.['learning-system']?.course?.id}`}
            >
              {t('button.manageContent')}
            </Link>
          </div>
        </div>

        <article className={`page-detail fadeIn`}>
          <header className="page-detail__header u-mb-0">
            <figure className="page-detail__bg-wrapper">
              <img
                className="page-detail__bg"
                src={backgroundImage()}
                alt="imagem de fundo"
              />
            </figure>

            <div className="page-detail__inner">
              <div className="page-detail__container">
                <div className="page-detail__banner">
                  <h1 className="page-detail__title">
                    {material?.title}
                    <span
                      className="card__meta"
                      style={{ marginLeft: '8px' }}
                    >
                      {material?.['file-size']}
                    </span>
                  </h1>
                  <p className="page-detail__description">
                    {material?.['learning-system']?.title} | {material?.['learning-system']?.course?.title}
                  </p>
                  {/* <p className="page-detail__description">{selectedMaterialsFolder?.name}</p> */}
                  <a
                    className="btn btn--wide btn--small btn--outline"
                    href={material?.file?.url ?? material?.url}
                    target="__blank"
                    rel="noopener noreferrer"
                  >
                    {t('materials.buttonAccessMaterial')}
                  </a>
                </div>
              </div>

              <div className="page-detail__icon">{icon()}</div>
            </div>
          </header>

          <footer className="page-detail__footer">
            <p>{t('materials.exploreMaterials')}</p>

            <div className="round-dg-wrapper">
              <table className="round-dg round-dg--light">
                <tbody className="round-dg__body">
                  {moduleMaterials && !loading && moduleMaterials.length === 0 && <EmptyState type="data" />}
                  {moduleMaterials.length > 0 &&
                    !loading &&
                    moduleMaterials?.map(item => {
                      return (
                        <tr
                          className="round-dg__row round-dg__row--clickable"
                          onClick={() => navigate(`${basePath}/materiais/${item.id}/${moduleId}`)}
                          key={item.id}
                        >
                          <th className="round-dg__cell-header round-dg__cell-header--row">
                            <header className="card__header u-mb-0">
                              <div className="card__inner-header">
                                <div className="card__icon-wrapper">{icon()}</div>
                                <h2 className="card__title">
                                  {item.title}{' '}
                                  <span
                                    className="card__meta"
                                    style={{ marginLeft: '12px' }}
                                  >
                                    {item?.['file-size']}
                                  </span>
                                </h2>
                              </div>
                            </header>
                          </th>
                          <td
                            className="round-dg__cell round-dg__cell--text-right"
                            style={{ width: '200px' }}
                            title={`$nomeDaAula`}
                          >
                            <span className="expanded-calendar__tag expanded-calendar__tag--bright">{item?.['learning-system-items']?.[0]?.body ?? ''}</span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </footer>
        </article>
      </div>

      {!material && !loading && (
        <div className="main-content main-content--bloc">
          <EmptyState
            type="content"
            title={t('emptyState.contentNotAvaliable')}
            text={t('emptyState.contactATeacherText')}
          />
        </div>
      )}
    </>
  );
};

export { Materials as default };
