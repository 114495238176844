import React from 'react';
import Modal from 'app/components/Modal';
import { toUpper } from 'lodash';
import { t } from 'i18next';
import { navigate } from '@reach/router';
import IconVerified from 'app/components/Icons/IconVerified';
import { useSession } from 'app/hooks/useSession';

export default function QuestionModal(props) {
  const { show, onClose, form, question } = props;
  const basePath = window.env.REACT_APP_BASE_PATH;

  const correctAnswer = (item, index) => {
    const correctAnswerForm = toUpper(String.fromCharCode(97 + form?.values['alternatives-attributes'].findIndex(item => item['is-correct'] === true)));
    const correctAnswerQuestion = question?.['correct-alternative']?.name;

    if (!question) {
      return correctAnswerForm === toUpper(String.fromCharCode(97 + index));
    }
    if (question) {
      if (question?.modality === 'multiple_choice') {
        return correctAnswerQuestion === toUpper(String.fromCharCode(97 + index));
      } else {
        return question?.['correct-alternative']?.id == item.id;
      }
    }
  };

  return (
    <Modal
      show={show}
      onClose={onClose}
    >
      <div style={{ height: '100px', overflowY: 'auto', flexGrow: 1, marginBottom: '20px' }}>
        <div className="tag-list">
          {(form?.values?.selectedTaxonomies ?? question?.taxonomies ?? []).map((item, index) => (
            <div
              className="tag"
              key={index}
            >
              {item.name}
            </div>
          ))}
          {question?.['official-content'] && <IconVerified style={{ margin: 'auto 0 auto auto' }} />}
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: form?.values?.description ?? question?.description }}
          className="question-card__introduction"
        ></div>
        <div className="question-card__motivator-text">
          <div className="question-card__title"></div>
          <div>
            <p dangerouslySetInnerHTML={{ __html: form?.values?.['motivator-texts-attributes']?.[0]?.body ?? question?.['motivator-texts']?.[0]?.body }} />
          </div>
          <div className="question-card__title"></div>
          <div className="question-card__print">
            <b dangerouslySetInnerHTML={{ __html: form?.values?.['motivator-texts-attributes']?.[0]?.print ?? question?.['motivator-texts']?.[0]?.print }} />
          </div>
        </div>

        <div className="question-card__answers">
          {(form?.values?.['alternatives-attributes'] ?? question?.alternatives).map((item, index) => {
            if (parseInt(item?.name) !== 3) {
              return (
                <div
                  className="question-card__answer null"
                  key={index}
                >
                  <div className="question-card__answer-letter">{toUpper(String.fromCharCode(97 + index))}</div>
                  <input
                    checked={correctAnswer(item, index)}
                    name="alternative"
                    type="radio"
                    id={index}
                  />
                  {form?.values?.modality === 'multiple_choice' || question?.modality === 'multiple_choice' ? (
                    <label
                      htmlFor={index}
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    ></label>
                  ) : null}
                  {form?.values?.modality === 'right_wrong' || question?.modality === 'right_wrong' ? (
                    <>
                      {item.name == 1 && <label htmlFor={index}>{t('exams.alternativeTrue')}</label>}
                      {item.name == 2 && <label htmlFor={index}>{t('exams.alternativeFalse')}</label>}
                    </>
                  ) : null}
                </div>
              );
            }
          })}
        </div>
      </div>

      <div>
        <button
          className="btn btn--wide btn--primary"
          onClick={() => navigate(`${basePath}/provas/banco-de-questoes/comentar/${question.id}`)}
        >
          Comentar questão
        </button>
      </div>
    </Modal>
  );
}
