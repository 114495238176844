import React from 'react';
import { FiMoreHorizontal, FiEdit3, FiClock } from 'react-icons/fi';
import dragAndDrop from 'app/images/icons/drag-and-drop.svg';
import Dropdown from 'app/components/Dropdown';
import moment from 'moment';
import { navigate, useParams } from '@reach/router';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import Swal from 'sweetalert2';
import { t } from 'i18next';

export default function ActivityCard(props) {
  const { activity, courseId, setFilteredActivities } = props;
  const client = useClient();
  const { moduleId } = useParams();
  const basePath = window.env.REACT_APP_BASE_PATH;

  const activityLesson = activity?.['learning-system-activities'].find(item => {
    return activity?.['learning-system-items'].some(l => l.id === item.learning_system_item_id);
  });
  const activityLessonName = activity?.['learning-system-items'].find(item => item.id === activityLesson?.learning_system_item_id)?.body;

  const handleDelete = () => {
    Swal.fire({
      title: t('projects.warningDeleteActivity'),
      text: t('projects.warningDeleteCannotBeUndoneText'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteActivity();
      }
    });
  };

  const deleteActivity = async () => {
    const { error } = await client.delete(['/activities', activity.id]);
    if (error) {
      toast.error('Erro ao excluir atividade');
    } else {
      toast.success('Atividade excluida!');
      setFilteredActivities(prevState => prevState.filter(item => item.id !== activity.id));
    }
  };

  const getClocks = hours => {
    switch (hours) {
      case 0.5:
        return (
          <span className="card__icon card__icon--semicircle">
            <FiClock />
          </span>
        );
      case 1:
        return <FiClock className="card__icon" />;
      case 1.5:
        return (
          <>
            <FiClock className="card__icon" />
            <span className="card__icon card__icon--semicircle">
              <FiClock />
            </span>
          </>
        );
      case 2:
        return (
          <>
            <FiClock className="card__icon" />
            <FiClock className="card__icon" />
          </>
        );
      case 2.5:
        return (
          <>
            <FiClock className="card__icon" />
            <FiClock className="card__icon" />
            <span className="card__icon card__icon--semicircle">
              <FiClock />
            </span>
          </>
        );
      default:
        return (
          <>
            <FiClock className="card__icon" />
            <FiClock className="card__icon" />
            <FiClock className="card__icon" />
          </>
        );
    }
  };

  return (
    <>
      <th className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row">
        <div className="card__header u-mb-0">
          <div className="card__inner-header">
            <img
              src={dragAndDrop}
              alt="ícone de arrastar"
              style={{ width: '10px', cursor: 'move', cursor: 'grab' }}
            />
            <div className="card__icon-wrapper">
              <FiEdit3 className="card__icon" />
            </div>
            <h2
              className="card__title"
              title={'Atividade complementar'}
              style={{ flexGrow: 'initial' }}
            >
              {activity?.title}
            </h2>
            {activityLesson?.isRequired ? <span className="badge badge--tiny badge--danger">Obrigatória</span> : null}
          </div>
        </div>
      </th>
      <td
        className="round-dg__cell round-dg__cell--sm"
        style={{ width: '220px', verticalAlign: 'bottom' }}
      >
        <span className="expanded-calendar__tag expanded-calendar__tag--bright">{activityLessonName}</span>
      </td>
      <td
        className="round-dg__cell round-dg__cell--sm"
        style={{ width: '220px' }}
      >
        {t('textsCommon.createdAt')} {moment(activity?.['created-at']).format('DD/MM/YYYY')}
      </td>
      <td
        className="round-dg__cell round-dg__cell--sm"
        title={`${activity?.['class-hours']} hora`}
        style={{ width: '100px' }}
      >
        {getClocks(activity?.['class-hours'])}
      </td>
      <td
        className="round-dg__cell round-dg__cell--sm"
        style={{ width: '44px' }}
      >
        <Dropdown>
          <Dropdown.Toggle>
            <FiMoreHorizontal />
          </Dropdown.Toggle>

          <Dropdown.Content>
            <Dropdown.Item onClick={() => navigate(`${basePath}/atividades/criar/nome/${moduleId}/${courseId}/${activity.id}`)}>{t('button.edit')}</Dropdown.Item>
            <Dropdown.Item onClick={() => handleDelete()}>{t('button.delete')}</Dropdown.Item>
            <Dropdown.Item onClick={() => navigate(`${basePath}/atividade-complementar/${activity?.id}/${courseId}`)}>{t('button.view')}</Dropdown.Item>
          </Dropdown.Content>
        </Dropdown>
      </td>
    </>
  );
}
