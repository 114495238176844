import React from 'react';
import { FiEdit, FiVideo } from 'react-icons/fi';
import { Link } from '@reach/router';
import { FiMessageSquare } from 'react-icons/fi';
import getPermission from 'app/utils/getPermission';
import { usePlatformAbilities } from 'app/hooks/usePlatformAbilities';

export default function FrequentShortcutWidget() {
  const { abilities } = usePlatformAbilities();
  const basePath = window.env.REACT_APP_BASE_PATH;

  return (
    <div className="frequent-shortcut-widget-wrapper">
      <h3 className="frequent-shortcut-widget-wrapper__title">Atalhos frequentes</h3>

      <div className="frequent-shortcut-widget">
        {getPermission('Criar cursos', 'Cursos, módulos e aulas') && abilities?.cursos ? (
          <Link
            className="frequent-shortcut-widget__item"
            to={`${basePath}/curso/criar/nome`}
          >
            <FiVideo className="frequent-shortcut-widget__icon" />
            <span className="frequent-shortcut-widget__link-text">Novo curso</span>
          </Link>
        ) : null}
        {getPermission('Criar auditórios', 'Auditórios') && abilities?.auditorio ? (
          <Link
            className="frequent-shortcut-widget__item"
            to={`${basePath}/auditorio/criar/nome`}
          >
            <FiMessageSquare className="frequent-shortcut-widget__icon" />
            <span className="frequent-shortcut-widget__link-text">Novo auditório</span>
          </Link>
        ) : null}

        {getPermission('Criar redação', 'Redação') && abilities?.redacao ? (
          <Link
            className="frequent-shortcut-widget__item"
            to={`${basePath}/redacao/criar/curso`}
          >
            <FiEdit className="frequent-shortcut-widget__icon" />
            <span className="frequent-shortcut-widget__link-text">Nova redação</span>
          </Link>
        ) : null}
      </div>
    </div>
  );
}
