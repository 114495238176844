import { useClient } from 'jsonapi-react';
import { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const QuizModal = props => {
  const { t } = useTranslation();
  const { currentQuiz, answered, setAnswered } = props;
  const client = useClient();

  const [selectedAnswer, setSelectedAnswer] = useState();
  const [response, setResponse] = useState();
  const [loading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState(false);
  const letters = _.range(65, 91).map(n => String.fromCharCode(n));

  const sendAnswer = async () => {
    setLoading(true);
    const requestParams = {
      'quiz-item-alternative-id': selectedAnswer
    };
    const { data, error } = await client.mutate('quiz_answers', requestParams);
    if (error) {
      toast.error(t('toast.errorSendAnswer'));
    } else {
      setAnswered(true);
      setResponse(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loading) {
      setButtonText(t('lms.buttonTextSendingAnswer'));
    }
    if (!answered) {
      setButtonText(t('button.sendAnswer'));
    }
    if (answered && !loading) {
      if (response?.['is-correct']) {
        setButtonText(t('button.textAnswerCorret'));
      } else {
        const correctLetterPosition = currentQuiz?.['quiz-item-alternatives']?.map(item => item.id)?.indexOf(response?.['correct-quiz-item-alternative-id']);
        setButtonText(`${t('button.textAnswerIncorrect')} ${letters[correctLetterPosition]}`);
      }
    }
  }, [loading, answered]);

  const buttonClass = () => {
    if (!answered) {
      return 'btn btn--primary btn--wide';
    }
    if (answered) {
      if (response?.['is-correct']) {
        return 'btn btn--rightAnswer btn--wide';
      } else {
        return 'btn btn--danger btn--wide';
      }
    }
  };

  return (
    <>
      <div className="card__badges card__badges--one-line">
        {(currentQuiz?.taxonomies || []).map(item => (
          <span className="badge badge--primary">{item.name}</span>
        ))}
      </div>
      <div className="question-card__introduction">{t('lms.titleQuestionLms')}</div>
      <div className="question-card__statement">{currentQuiz?.body}</div>
      <div className="question-card__answers">
        {currentQuiz?.['quiz-item-alternatives']?.map((alternative, index) => (
          <div className="question-card__answer">
            <div className="question-card__answer-letter">{letters[index]}</div>
            <input
              name="answers"
              type="radio"
              id={`alternative-${index}`}
              value={alternative.id}
              onChange={() => setSelectedAnswer(alternative.id)}
            />
            <label htmlFor={`alternative-${index}`}>{alternative.title}</label>
          </div>
        ))}
      </div>
      <div className="form__row">
        <button
          className={buttonClass()}
          onClick={sendAnswer}
        >
          {buttonText}
        </button>
      </div>
    </>
  );
};

export default QuizModal;
