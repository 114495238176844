import React, { useState, useEffect } from 'react';
import { navigate, useParams } from '@reach/router';
import { FiAlertCircle, FiChevronLeft } from 'react-icons/fi';
import StudyPlanTimeline from './StudyPlanTimeline';
import StudyBlocks from './StudyBlocks';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import Loader from 'app/components/loader';
import { t } from 'i18next';
import { TfiMedallAlt } from 'react-icons/tfi';
import moreMedal from 'app/images/more-medal.svg';
import BreadCrumbs from 'app/components/BreadCrumbs';

export default function ViewStudyPlan(props) {
  const { uri } = props;

  const [studyPlan, setStudyPlan] = useState();
  const [currentWeek, setCurrentWeek] = useState(0);
  const [nowWeekIndex, setNowWeekIndex] = useState();
  const [isPendent, setIsPendent] = useState(false);
  const [activeWeek, setActiveWeek] = useState();
  const [loading, setLoading] = useState(true);
  const [studyStatus, setStudyStatus] = useState([]);
  const { planId } = useParams();
  const client = useClient();
  const [weeks, setWeeks] = useState([]);
  const basePath = window.env.REACT_APP_BASE_PATH;

  function getNowWeek(weeks) {
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0];

    for (let i = 0; i < weeks?.length; i++) {
      const { 'initial-date': startDate, 'end-date': endDate } = weeks[i];
      if (formattedToday >= startDate && formattedToday <= endDate) {
        return i;
      }
    }
    return 0;
  }

  const getStudyPlan = async () => {
    try {
      const { data } = await client.fetch(`/study_plans/${planId}`);
      if (data) {
        setStudyPlan(data);
      } else {
        // window.location.href = '/plano-de-estudos';
      }
    } catch {
      toast.error(t('Erro ao buscar plano de estudos'));
    }
  };
  const getWeeks = async () => {
    try {
      const { data } = await client.fetch(`/study_plan_blocks?filter[study_plan_id]=${planId}`);
      setWeeks(data);
      setActiveWeek(data[currentWeek]);
      setLoading(false);
    } catch {
      toast.error(t('Erro ao buscar blocos de estudos'));
    }
  };

  useEffect(() => {
    getStudyPlan();
    getWeeks();
  }, []);

  useEffect(() => {
    if (weeks?.length > 0) {
      let weekIndex = nowWeekIndex;
      if (nowWeekIndex === undefined) {
        weekIndex = getNowWeek(weeks);
        setNowWeekIndex(weekIndex);
        setCurrentWeek(weekIndex);
        setActiveWeek(weeks[weekIndex]);
      }

      let pendent = false;
      for (let i = 0; i < weekIndex; i++) {
        if (weekStatus[i] === 'incomplete') {
          pendent = true;
        }
      }
      setIsPendent(pendent);
    }
  }, [weeks]);

  const handleWeekChange = newWeek => {
    setCurrentWeek(newWeek);
    setActiveWeek(weeks[newWeek]);
  };

  const updateStudyStatus = week => {
    const currentWeekStudyBlocks = weeks.filter((_, index) => index === week);
    const allCompleted = currentWeekStudyBlocks.every(block => block.completed);

    setStudyStatus(prevStatus => prevStatus.map((status, index) => (index === week - 1 ? allCompleted : status)));
  };

  const weekStatus = weeks?.flatMap((item, index) => {
    const videos = item?.['study-plan-videos'];
    const hasIncompleteVideo = videos.some(video => video.done === false);
    return hasIncompleteVideo ? [`incomplete`] : [`complete`];
  });

  const hasIncompleteWeek = weekStatus.some(item => item === 'incomplete');

  const breadCrumbs = {
    title: 'Aprendizado Dinâmico',
    nav: [
      {
        route: `${basePath}/plano-de-estudos`,
        name: 'Guia de estudos',
        isActive: false
      },
      {
        route: uri,
        name: studyPlan?.title,
        isActive: true
      }
    ]
  };

  return loading ? (
    <Loader />
  ) : (
    <main className="main-content main-content--block">
      <BreadCrumbs
        data={breadCrumbs}
        actions={
          <div className="filter-bar__actions">
            {!hasIncompleteWeek ? (
              <p className="filter-bar__alert">
                <div dangerouslySetInnerHTML={{ __html: t('studyPlan.onTrack') }}></div>
              </p>
            ) : !isPendent && nowWeekIndex === weeks.length ? (
              <p className="filter-bar__alert">
                <div className="medal medal--sm">
                  <img
                    src={moreMedal}
                    alt="nova medalha"
                    className="medal__img"
                  />
                  <span className="medal__value">
                    <TfiMedallAlt />
                  </span>
                </div>
                {t('studyPlan.completed')}
              </p>
            ) : (
              <p className="filter-bar__alert">
                <span className="filter-bar__alert-icon">
                  <FiAlertCircle />
                </span>
                {t('studyPlan.unfinished')}
              </p>
            )}
          </div>
        }
      />

      <StudyPlanTimeline
        initialDate={studyPlan?.['initial-date']}
        endDate={studyPlan?.['end-date']}
        currentWeek={currentWeek}
        weekStatus={weekStatus}
        studyStatus={studyStatus}
        onWeekChange={handleWeekChange}
        totalWeeks={weeks.length}
        nowWeekIndex={nowWeekIndex}
        // todayWeek={todayWeek}
      />

      <StudyBlocks
        studyPlan={studyPlan}
        activeWeek={activeWeek}
        currentWeek={currentWeek}
        onUpdateStudyStatus={updateStudyStatus}
        setWeeks={setWeeks}
        handleWeekChange={handleWeekChange}
        totalWeeks={weeks.length}
      />
    </main>
  );
}
