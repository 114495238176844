import { useSession } from 'app/hooks/useSession';
import { useEffect } from 'react';

const Logout = () => {
  const { signOut } = useSession();

  useEffect(() => {
    signOut();
  }, []);

  return <></>;
};

export default Logout;
