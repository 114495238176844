import { useFormik } from 'formik';
import ModalHeader from './ModalHeader';
import { useClient } from 'jsonapi-react';
import TextareaAutosize from 'react-autosize-textarea';
import { useEffect, useRef, useState } from 'react';
import { FilterSelectionBoxInstitution } from 'app/components/FilterSelectionBox/FilterSelectionBoxInstitution';
import promiseRequest from 'app/utils/promiseToast';
import * as yup from 'yup';
import { t } from 'i18next';

const AddEditInstitution = ({ institution = null, afterSubmit, editing }) => {
  const client = useClient();
  const id = institution?.id;

  const { promiseToast, isSubmitting } = promiseRequest();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = values => {
    const data = { attributes: values };

    const url = id ? ['/institutions', id] : 'institutions';

    promiseToast({
      url: url,
      request: data,
      successText: `${editing ? 'Instituição editada' : 'Instituição criada'} com sucesso`,
      errorText: `Erro ao ${editing ? 'editar' : 'adicionar'} instituição.`
    })
      .then(data => {
        afterSubmit();
      })
      .error(e => afterSubmit());
  };

  const schema = yup.object({
    name: yup.string().required(t('warning.requiredField')),
    'regional-id': yup.string().required(t('warning.requiredField'))
  });

  const form = useFormik({
    initialValues: {
      name: institution?.name || '',
      'regional-id': institution?.['regional-id'] || '',
      description: institution?.description || ''
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: schema,
    onSubmit: handleSubmit
  });

  const fillForm = institution => {
    form.setFieldValue('name', institution.name);
    form.setFieldValue('regional-id', institution['regional-id']);
    form.setFieldValue('description', institution.description);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const { data } = await client.fetch('/regionals');
      const options = data.map(r => ({ id: r.id, name: r.name }));
      setData(options);
      setLoading(false);
    };
    fetchData();
    if (institution) {
      fillForm(institution);
    }
  }, []);

  return (
    <div>
      <ModalHeader
        title={`${editing ? 'Edite a' : 'Adicione uma'} instituição`}
        hasUnderline={true}
      />
      <form
        className="form"
        onSubmit={form.handleSubmit}
      >
        <div className="form__row">
          <label
            htmlFor="name"
            className="form__label"
          >
            *Nome
          </label>
          <input
            type="text"
            className="form__control"
            id="name"
            name="name"
            defaultValue={form.values?.name}
            onChange={e => form.setFieldValue('name', e.target.value)}
          />
          {form.errors.name && <p style={{ color: 'red' }}>{form.errors.name}</p>}
        </div>

        <div className="form__row">
          <FilterSelectionBoxInstitution
            blankLabel={loading ? 'Carregando...' : 'Selecione para filtrar'}
            optionBlank=""
            label="*Secretaria"
            value={form.values['regional-id']}
            onChange={e => form.setFieldValue('regional-id', e.target.value)}
            className="add-institution-gre-combo"
            options={data}
          />
          {form.errors['regional-id'] && <p style={{ color: 'red' }}>{form.errors['regional-id']}</p>}
        </div>

        <div className="form__row">
          <label
            htmlFor="description"
            className="form__label"
          >
            Descricão
          </label>
          <TextareaAutosize
            name="description"
            className="form__control"
            id="description"
            onChange={e => form.setFieldValue('description', e.target.value)}
            value={form.values?.description}
          />
        </div>

        <button
          type="submit"
          className="btn btn--primary"
          disabled={isSubmitting}
        >
          {editing ? 'Editar' : 'Adicionar'} Instituição
        </button>
      </form>
    </div>
  );
};

export default AddEditInstitution;
