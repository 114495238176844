import React, { useState } from 'react';
import { FiAlertCircle } from 'react-icons/fi';
import { Link } from '@reach/router';
import Modal from 'app/components/Modal';
import AddBookletCard from './AddBookletCard';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import { useEffect } from 'react';
import moment from 'moment';
import { t } from 'i18next';

export default function AddBookletModal(props) {
  const { show, onClose, currentModuleId, selectedBooklets, setSelectedBooklets } = props;
  const client = useClient();
  const [lessons, setLessons] = useState([]);
  const [booklets, setBooklets] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const basePath = window.env.REACT_APP_BASE_PATH;

  const getLessons = async () => {
    const { data, error } = await client.fetch(`/learning_system_items?simple=true&filter[learning_systems.id]=${currentModuleId}`);
    if (error) {
      toast.error('Erro ao buscar aulas');
    } else {
      setLessons(data);
    }
  };

  const getBooklets = async () => {
    const { data, error } = await client.fetch(`/question_books?filter[visibility]=train`);
    if (error) {
      toast.error('Erro ao buscar apostilas');
    } else {
      // const today = moment();

      // setBooklets(
      //   data.filter(item => {
      //     return today.isBetween(item['published-at'], item['finished-at']);
      //   })
      // );
      setBooklets(data.filter(item => item['creator-profile'] !== 'Aluno'));
    }
  };

  useEffect(() => {
    getLessons();
    getBooklets();
  }, [currentModuleId]);

  const filteredBooklets = booklets.filter(item => {
    if (searchTerm === '') {
      return true;
    }
    if (searchTerm && searchTerm !== '') {
      return item.title.toLowerCase().includes(searchTerm.toLowerCase());
    }
  });

  return (
    <Modal
      show={show}
      onClose={onClose}
      contentClassName="modal__content--lg"
    >
      <h2 className="modal__simple-title">Adicionar apostilas</h2>

      <div className="form__row">
        <label
          htmlFor="searchBooklet"
          className="form__label"
        >
          {t('textsCommon.search')}
        </label>
        <input
          id="searchBooklet"
          style={{ position: 'sticky', top: '0', width: 'auto', maxWidth: '100%' }}
          className="form__control form__control--search-with-icon"
          type="search"
          placeholder={t('filter.searchQuestionBook')}
          onChange={e => setSearchTerm(e.target.value)}
        />
      </div>

      <div
        className="alert u-mb-2"
        role="alert"
      >
        <FiAlertCircle className="alert__icon" />
        <span>
          <strong>Resposta obrigatória:</strong> O estudante só avançará para a próxima aula após a realização da apostila.
        </span>
      </div>

      <div
        className="u-overflow-y"
        style={{
          minHeight: '56px',
          maxHeight: '70vh',
          padding: '16px',
          margin: '0 -16px 1.5em -16px'
        }}
      >
        {filteredBooklets?.map((booklet, index) => {
          const selectedBooklet = selectedBooklets.find(item => item['question-book'].id === booklet.id);
          return (
            <AddBookletCard
              setSelectedBooklets={setSelectedBooklets}
              selectedBooklets={selectedBooklets}
              selectedBooklet={selectedBooklet}
              currentModuleId={currentModuleId}
              booklet={booklet}
              lessons={lessons}
              id={index}
              key={index}
            />
          );
        })}
      </div>

      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '12px'
        }}
      >
        <Link
          to={`${basePath}/apostilas/criar/nome`}
          className="btn btn--link"
          type="submit"
        >
          Criar um nova apostila
        </Link>
      </div>
    </Modal>
  );
}
