import { Link } from '@reach/router';
import disapproved from '../../components/EmptyState/img/disapproved.svg';

const DisapprovedExam = props => {
  const { unfinishedExam } = props;
  const basePath = window.env.REACT_APP_BASE_PATH;

  return (
    <div className="empty-state">
      <img
        className="empty-state__icon"
        alt=""
        src={disapproved}
      />

      <h3 className="empty-state__title"> Você não atingiu a nota de corte</h3>

      <p
        className="empty-state__text"
        style={{ fontSize: '1.5rem' }}
      >
        Para refazer a prova, basta clicar no botão abaixo. Boa sorte!
      </p>
      <Link
        className="btn btn--primary"
        to={`${basePath}/avaliacoes/sobre/${unfinishedExam?.id}`}
      >
        Refazer a prova
      </Link>
    </div>
  );
};

export default DisapprovedExam;
