import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { navigate, useParams } from '@reach/router';
import toast from 'react-hot-toast';
import StepName from './StepName';
import TipsSidebar from './TipsSidebar';
import StepInfo from './StepInfo';
import StepType from './StepType';
import StepVisibility from './StepVisibility';
import { useClient } from 'jsonapi-react';
import * as yup from 'yup';
import { t } from 'i18next';
import Loader from 'app/components/loader';

import promiseRequest from 'app/utils/promiseToast';

export default function QuestionContent(props) {
  const { step, steps, currentStep, setCurrentStep } = props;
  const [question, setQuestion] = useState();
  const questionId = useParams().id;
  const client = useClient();
  const [questionType, setQuestionType] = useState(t('exams.selectMultipleChoice'));
  const [removedAlternatives, setRemovedAlternatives] = useState([]);
  const [isLoadingQuestion, setIsLoadingQuestion] = useState(true);
  const [taxonomies, setTaxonomies] = useState([]);
  const { promiseToast, isSubmitting } = promiseRequest();
  const basePath = window.env.REACT_APP_BASE_PATH;

  const getQuestion = async () => {
    setIsLoadingQuestion(true);
    const { data, error } = await client.fetch(['questions', questionId]);
    if (!questionId) {
      setIsLoadingQuestion(false);
      return;
    }
    if (error) {
      toast.error('Falha ao buscar dados da questão');
    } else {
      setQuestion(data);
      if (data.modality === 'right_wrong') {
        setQuestionType(t('exams.selectTrueOrFalse'));
      }
    }

    setIsLoadingQuestion(false);
  };

  const fetchTaxonomies = async () => {
    try {
      const { data } = await client.fetch('taxonomies');
      setTaxonomies(data);
    } catch (e) {
      console.error(e);
      toast.error(t('toast.errorGetTags'));
    }
  };

  const createQuestionForm = useFormik({
    initialValues: {
      published: false,
      description: '',
      modality: 'Múltipla escolha',
      knowledge: [],
      visibility: '',
      'motivator-texts-attributes': [
        {
          title: '',
          body: '',
          print: ''
        }
      ],
      selectedTaxonomies: [],
      code: '',
      'alternatives-attributes': [
        {
          name: '',
          description: '',
          'is-correct': true
        },
        {
          name: '',
          description: '',
          'is-correct': false
        }
      ],
      authored_by_user: false,
      official_content: false
    },
    validationSchema: yup.object({
      code: yup.string().required(t('warning.requiredField')),
      knowledge: yup.array().min(1, t('warning.requiredField')).nullable(),
      selectedTaxonomies: yup.array().min(1, t('warning.selectTaxonomy')),
      description: yup.string().required(t('warning.requiredField')),
      'alternatives-attributes': yup
        .array()
        .of(
          yup.object().shape({
            description: yup.string().required(t('warning.requiredField'))
          })
        )
        .min(2, 'Pelo menos duas alternativas são obrigatórias')
    }),

    onSubmit: () => handleSubmit
  });

  const knowledgeList = [
    { title: t('exams.knowledgeAcademy'), id: 1 },
    { title: t('exams.knowledgeCommercial'), id: 2 },
    { title: t('exams.knowledgeMunicipality'), id: 3 },
    { title: t('exams.knowledgeData'), id: 4 },
    { title: t('exams.knowledgeEsocial'), id: 5 },
    { title: t('exams.knowledgeFacilities'), id: 6 },
    { title: t('exams.knowledgeImplantation'), id: 7 },
    { title: t('exams.knowledgeBusiness'), id: 8 },
    { title: t('exams.knowledgeOperation'), id: 9 },
    { title: t('exams.knowledgePD'), id: 10 },
    { title: t('exams.knowledgeParameterization'), id: 11 },
    { title: t('exams.knowledgeProcesss'), id: 12 },
    { title: t('exams.knowledgeProduct'), id: 13 },
    { title: t('exams.knowledgeProjects'), id: 14 },
    { title: t('exams.knowledgeSupport'), id: 15 }
  ];

  useEffect(() => {
    if (question) {
      createQuestionForm.setValues({
        published: question?.published,
        description: question?.description,
        modality: question?.modality,
        knowledge: knowledgeList?.find(item => item?.title === question?.knowledge)?.id,
        visibility: question?.visibility,
        'motivator-texts-attributes': question?.['motivator-texts'],
        selectedTaxonomies: question?.taxonomies,
        code: question?.code,
        'alternatives-attributes': question?.['alternatives'],
        official_content: question['official-content'],
        authored_by_user: question['authored-by-user']
      });
    }
  }, [question]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getQuestion();
      fetchTaxonomies();
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (createQuestionForm.values.modality === 'right_wrong' && !question) {
      const alternatives = createQuestionForm.values['alternatives-attributes'].map((alternative, index) => {
        let rightOrWrong = '';
        if (index === 0) {
          rightOrWrong = 'Certo';
        } else if (index === 1) {
          rightOrWrong = 'Errado';
        } else if (index === 2) {
          rightOrWrong = 'Deixar em branco';
        }
        return {
          ...alternative,
          'is-correct': index === 0 ? true : false,
          description: rightOrWrong
        };
      });
      createQuestionForm.setFieldValue('alternatives-attributes', alternatives);
    }
  }, [createQuestionForm.values.modality]);

  const createQuestion = async () => {
    const auxValues = { ...createQuestionForm.values };

    auxValues['taxonomy-ids'] = (auxValues.selectedTaxonomies || []).map(t => t.id);

    if (auxValues.visibility === 'exam') {
      auxValues.visibility = 'exam';
    } else if (auxValues.visibility === 'train') {
      auxValues.visibility = 'train';
    } else if (auxValues.visibility === 'mock') {
      auxValues.visibility = 'mock';
    }

    auxValues.knowledge = parseInt(auxValues.knowledge);

    if (questionType === 'Múltipla escolha') {
      auxValues['alternatives-attributes'] = auxValues['alternatives-attributes'].map((item, index) => {
        const alphabet = 'ABCDEFGH';
        return { ...item, name: alphabet[index] };
      });
    }

    const deletedAlternatives = removedAlternatives.map(item => {
      return { ...item, _destroy: true };
    });

    auxValues['alternatives-attributes'] = [...deletedAlternatives, ...auxValues['alternatives-attributes']];

    delete auxValues.selectedTaxonomies;

    const path = questionId ? ['questions', questionId] : 'questions';

    promiseToast({
      url: path,
      request: auxValues,
      successText: questionId ? t('toast.successEditQuestion') : t('toast.successCreateQuestion'),
      errorText: t('toast.errorCreateQuestion')
    })
      .then(data => {
        navigate(`${basePath}/avaliacoes/banco-de-questoes`);
      })
      .catch(e => {
        if (error?.id === 'visibility') toast.error(error?.title);
      });
  };

  const handleSubmit = e => {
    e.preventDefault();

    switch (step) {
      case 'nome':
        if (questionId) {
          createQuestionForm.setFieldTouched('code', true);
          createQuestionForm.setFieldTouched('knowledge', true);
          createQuestionForm.setFieldTouched('selectedTaxonomies', true);
          if (!createQuestionForm?.errors.code && !createQuestionForm?.errors.knowledge && !createQuestionForm?.errors.selectedTaxonomies) {
            navigate(`${basePath}/questoes/criar/informacoes/${questionId}`);
            setCurrentStep(1);
          }
          break;
        } else {
          createQuestionForm?.setFieldTouched('code', true);
          createQuestionForm?.setFieldTouched('knowledge', true);
          createQuestionForm?.setFieldTouched('selectedTaxonomies', true);
          if (!createQuestionForm?.errors.code && !createQuestionForm?.errors.knowledge && !createQuestionForm?.errors.selectedTaxonomies) {
            navigate(`${basePath}/questoes/criar/informacoes`);
            setCurrentStep(1);
          }
          break;
        }
      case 'informacoes':
        if (questionId) {
          createQuestionForm?.setFieldTouched('motivator-texts-attributes[0].body', true);
          createQuestionForm?.setFieldTouched('description', true);
          createQuestionForm?.setFieldTouched('motivator-texts-attributes[0].print', true);
          if (!createQuestionForm?.errors?.['motivator-texts-attributes']?.[0].body && !createQuestionForm?.errors?.description && !createQuestionForm?.errors?.['motivator-texts-attributes[0].print']?.[0]?.print) {
            navigate(`${basePath}/questoes/criar/tipo/${questionId}`);
            setCurrentStep(2);
          }
          break;
        } else {
          createQuestionForm?.setFieldTouched('motivator-texts-attributes[0].body', true);
          createQuestionForm?.setFieldTouched('description', true);
          createQuestionForm?.setFieldTouched('motivator-texts-attributes[0].print', true);
          if (!createQuestionForm?.errors?.['motivator-texts-attributes']?.[0]?.body && !createQuestionForm.errors.description && !createQuestionForm?.errors?.['motivator-texts-attributes']?.[0]?.print) {
            navigate(`${basePath}/questoes/criar/tipo`);
            setCurrentStep(2);
          }
          break;
        }
      case 'tipo':
        if (questionId) {
          createQuestionForm?.setFieldTouched('alternatives-attributes', true);
          if (!createQuestionForm?.errors['alternatives-attributes']) {
            navigate(`${basePath}/questoes/criar/visibilidade/${questionId}`);
            setCurrentStep(3);
          }
          break;
        } else {
          createQuestionForm?.setFieldTouched('alternatives-attributes', true);
          if (!createQuestionForm?.errors['alternatives-attributes']) {
            navigate(`${basePath}/questoes/criar/visibilidade`);
            setCurrentStep(3);
          }
          break;
        }

      case 'visibilidade':
        createQuestion();

        break;
    }
  };

  const btnsStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '24px'
  };

  return (
    <div className="page page--wrap">
      {isLoadingQuestion ? (
        <Loader />
      ) : (
        <div className="page__col">
          <form
            className="form form--step-by-step"
            method="post"
            onSubmit={handleSubmit}
          >
            <StepName
              question={question}
              step={step}
              form={createQuestionForm}
              taxonomies={taxonomies}
            />
            <StepInfo
              step={step}
              form={createQuestionForm}
              question={question}
            />
            <StepType
              setRemovedAlternatives={setRemovedAlternatives}
              removedAlternatives={removedAlternatives}
              questionType={questionType}
              setQuestionType={setQuestionType}
              step={step}
              form={createQuestionForm}
              question={question}
            />
            <StepVisibility
              step={step}
              form={createQuestionForm}
            />

            <div style={btnsStyles}>
              {step !== 'nome' && (
                <button
                  type="button"
                  className="btn btn--outline btn--wide"
                  onClick={() => {
                    if (questionId) {
                      navigate(`${basePath}/questoes/criar/${steps?.[currentStep - 1]?.route}/${questionId}`);
                      setCurrentStep(currentStep - 1);
                    } else {
                      navigate(`${basePath}/questoes/criar/${steps?.[currentStep - 1]?.route}`);
                      setCurrentStep(currentStep - 1);
                    }
                  }}
                >
                  {t('exams.previous')}
                </button>
              )}

              <button
                type="submit"
                className="btn btn--primary btn--wide"
                disabled={isSubmitting}
              >
                {step !== 'visibilidade' ? t('button.next') : t('button.finish')}
              </button>
            </div>
          </form>
        </div>
      )}

      <TipsSidebar
        step={step}
        form={createQuestionForm}
        knowledgeList={knowledgeList}
      />
    </div>
  );
}
