import React, { useState } from 'react';
import { Link } from '@reach/router';
import { FiCheckCircle } from 'react-icons/fi';
import Modal from 'app/components/Modal';
import AnswersGuide from '../../ExamMetrics/AnswersGuide';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export default function ExamCard({ exam }) {
  const { t } = useTranslation();
  const basePath = window.env.REACT_APP_BASE_PATH;

  const [showModal, setShowModal] = useState(false);

  const isAcceptingAnswers = exam.status === 'accepting_answers';
  const isExamDone = exam.status === 'approved' || exam.status === 'disapproved';

  const BottomButton = () => {
    if (isAcceptingAnswers) {
      return (
        <div
          className="btn-group btn-group--with-dropdown"
          role="group"
          aria-label="Ações"
        >
          <Link
            to={`${basePath}/avaliacoes/sobre/${exam?.id}`}
            className="btn btn--outline btn--small"
          >
            {t('exams.accessExam')}
          </Link>
        </div>
      );
    }

    if (isExamDone) {
      return (
        <button
          onClick={() => setShowModal(true)}
          className="btn btn--outline btn--small"
        >
          {t('button.answerKey')}
        </button>
      );
    }

    return (
      <button
        onClick={() => setShowModal(true)}
        className="btn btn--primary btn--small disabled"
      >
        {t('button.answerKey')}
      </button>
    );
  };

  return (
    <div className={isAcceptingAnswers ? 'card card--warning' : 'card'}>
      <div
        className="card__header"
        key={exam.id}
      >
        <div className="card__inner-header">
          <h2
            className="card__title"
            title="Nome da prova"
          >
            {exam?.title}
          </h2>
        </div>

        {exam.status === 'approved' ? (
          <span className="badge badge--tiny badge--success">{t('exams.statusInApproved')}</span>
        ) : exam.status === 'review' ? (
          <span className="badge badge--tiny badge--warning">{t('exams.statusInAnalysis')}</span>
        ) : isAcceptingAnswers ? (
          <span className="badge badge--tiny badge--warning">Aceitando respostas</span>
        ) : exam.status === 'disapproved' ? (
          <span className="badge badge--tiny badge--danger">{t('exams.statusInDisapproved')}</span>
        ) : (
          <span className="badge badge--tiny badge--danger">{t('exams.statusUnrealized')}</span>
        )}
      </div>

      <div className="card__body">
        <div className="card__badges card__badges--one-line">
          {exam?.taxonomies.length > 0 && (
            <div className="card__badges card__badges--one-line u-mb-0">
              {exam?.taxonomies.length <= 2 &&
                exam?.taxonomies.map(taxonomy => (
                  <span
                    className="badge badge--primary"
                    key={taxonomy.id}
                    title={taxonomy.name}
                  >
                    {taxonomy.name}
                  </span>
                ))}
              {exam?.taxonomies.length > 2 && (
                <>
                  {exam?.taxonomies.slice(0, 2).map(taxonomy => (
                    <span
                      className="badge badge--primary"
                      key={taxonomy.id}
                      title={taxonomy.name}
                    >
                      {taxonomy.name}
                    </span>
                  ))}
                  <span
                    className="badge badge--more"
                    title={exam?.taxonomies.slice(2).map(taxonomy => ` ${taxonomy.name}`)}
                  >
                    +{exam?.taxonomies.length - 2}
                  </span>
                </>
              )}
            </div>
          )}
        </div>
        <div className="card__columns">
          <p className="card__count">
            <span className="card__count-label">{t('textsCommon.questions')}</span>
            <FiCheckCircle className="card__icon" /> {exam?.['question-count']}
          </p>
          {exam?.['published-at'] === null ? (
            <p></p>
          ) : (
            <p className="card__count">
              <span>{t('exams.labelPublishedAt')}</span>
              {moment(exam?.['published-at']).format(t('datesForm.formatDate'))}
            </p>
          )}
        </div>
      </div>

      <div className="card__footer">
        <BottomButton />
      </div>

      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        contentClassName="modal__content--md"
      >
        <AnswersGuide
          examId={exam?.id}
          amountQuestions={exam['question-count']}
        />
      </Modal>
    </div>
  );
}
