import React, { useState } from 'react';
import { t } from 'i18next';
import StarRating from 'app/components/StarRating';
import { TbCopy } from 'react-icons/tb';
import { FiEdit3, FiTarget, FiVideo } from 'react-icons/fi';
import MonitoringModuleCard from './MonitoringModuleCard';
import Modal from 'app/components/Modal';
import { FiInfo } from 'react-icons/fi';
export default function ModuleWidget({ course }) {
  const [showModal, setShowModal] = useState(false);

  const totalLessons = () => {
    const parsedItems = course?.['learning-systems']?.map(item => item.learning_system_items_total_count);

    const totalLessons = parsedItems?.reduce((sum, curr) => {
      return sum + curr;
    }, 0);

    return totalLessons;
  };

  const modules = course?.['learning-systems'];

  return (
    <div className="course-about u-mb-4">
      <div className="course-about__header">
        <div className="course-about__container-header">
          <div className="course-about__inner-header">
            <div
              className="course-about__infos"
              style={{ justifyContent: 'space-between' }}
            >
              <div className="course-about__rating">
                <StarRating
                  staticRating
                  rating={Number(course?.rating)}
                />
                <p className="course-about__rating-text">
                  <strong>{course?.rating}</strong> ({course?.['total-reviews']} {t('courses.evaluations')})
                </p>
              </div>
              <div className="deadline">
                <FiInfo size={20} />
                <span>
                  O <b>certificado do curso</b> estará disponível a partir de {new Date(course?.['finished-at']).toLocaleDateString('pt-br')}
                </span>
              </div>
            </div>

            <p className="course-about__description">
              {course?.about}
              {course?.about?.length > 300 && '...'}

              {course?.about?.length > 300 && (
                <button
                  className="btn btn--link u-p-0"
                  style={{ height: 'auto' }}
                  onClick={() => setShowModal(true)}
                >
                  Ver mais
                </button>
              )}
            </p>

            <Modal
              show={showModal}
              onClose={() => setShowModal(false)}
              contentClassName="modal__content--md"
            >
              <h3 className="modal__simple-title">Sobre o curso</h3>

              <div className="modal__body u-mb-0">
                <p>{course?.about}</p>
              </div>
            </Modal>
          </div>
        </div>

        <div className="widget-wrapper u-mb-0">
          <div className="info-widget info-widget--t3">
            <div className="info-widget__header">
              <h3 className="info-widget__title">Módulos</h3>
            </div>
            <div className="info-widget__body">
              <p className="info-widget__value">
                <TbCopy /> {course?.['learning-systems']?.length}
              </p>
            </div>
          </div>

          <div className="info-widget info-widget--t3">
            <div className="info-widget__header">
              <h3 className="info-widget__title">Aulas</h3>
            </div>
            <div className="info-widget__body">
              <p className="info-widget__value">
                <FiVideo /> {totalLessons()}
              </p>
            </div>
          </div>

          <div className="info-widget info-widget--t3">
            <div className="info-widget__header">
              <h3 className="info-widget__title">Avaliações</h3>
            </div>
            <div className="info-widget__body">
              <p className="info-widget__value">
                <FiTarget /> {course?.['exam-count']}
              </p>
            </div>
          </div>

          <div className="info-widget info-widget--t3">
            <div className="info-widget__header">
              <h3 className="info-widget__title">Atividades</h3>
            </div>
            <div className="info-widget__body">
              <p className="info-widget__value">
                <FiEdit3 /> {course?.['activity-count']}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="course-about__body">
        <div className="module-wrapper u-mh-none">
          {modules?.map(module => {
            return (
              <MonitoringModuleCard
                courseId={course?.id}
                newMessage
                module={module}
                key={module.id}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
