import React from 'react';
import medalImg from './img/medal.svg';
import MedalStack from './MedalStack';

export function Medal(props) {
  const { value, size } = props;

  let medal = 'medal';
  if (size === 'md') medal = [medal, 'medal--md'].join(' ');

  return (
    <div className={medal}>
      <img className="medal__img" src={medalImg} alt="medalha" />
      {value !== "0" && <span className="medal__value">{value}</span>}
    </div>
  );
}

Medal.Stack = MedalStack;

export { Medal as default, MedalStack };
