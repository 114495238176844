import React from 'react';
import { Link } from '@reach/router';
import QuizDataPieChart from './QuizDataPieChart';
import { t } from 'i18next';

export default function QuestionChart(props) {
  const { surveyReport, surveyQuestion, surveyData } = props;
  const basePath = window.env.REACT_APP_BASE_PATH;

  return (
    <div className="question-chart">
      <div className="question-chart__header">
        <h3 className="question-chart__title">{surveyQuestion?.question?.question}</h3>
      </div>

      <div className="question-chart__body">{surveyReport && <QuizDataPieChart surveyQuestion={surveyQuestion} />}</div>

      <div className="question-chart__footer">
        <Link
          to={`${basePath}/questionario/dados/${surveyData.id}/propostas/${surveyQuestion.id}`}
          className="btn btn--outline btn--small"
        >
          {t('nps.buttonViewInterventionProposals')}
        </Link>
      </div>
    </div>
  );
}
