import React, { useEffect, useState } from 'react';
import userAvatar from 'app/images/user-avatar.svg';
import { useSession } from 'app/hooks/useSession';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import trashIcon from 'app/images/icons/trash-icon.svg';
import moment from 'moment';
import { Link } from '@reach/router';
import { AnswerCommentForm } from 'app/components/AnswerCommentForm';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';

export default function CourseClassroomCommentCard(props) {
  const { comment, deleteComment, comments, learningSystemItemId } = props;
  const { session } = useSession();
  const client = useClient();
  const authorityLevel = getAuthorityLevel(session);
  const [isOpenAnswer, setIsOpenAnswer] = useState(false);
  const [answers, setAnswers] = useState([]);
  const basePath = window.env.REACT_APP_BASE_PATH;

  const findAnswers = () => {
    const commentsWithAnswers = comments?.filter(comment => comment?.['comment-parent-id'] !== null);
    const answersByCommentId = commentsWithAnswers?.filter(item => item?.['comment-parent-id'] === comment?.id).reverse();

    setAnswers(answersByCommentId);
  };

  const handleCreateAnswer = async value => {
    try {
      const response = await client.mutate('/comments', {
        'comment-parent-id': comment.id,
        body: value,
        'learning-system-item-id': learningSystemItemId
      });

      setAnswers(prev => [response.data, ...prev]);
    } catch (error) {
      console.log(error);
      toast.error('Erro ao responder');
    }
  };

  const handleDeleteAnswer = async answerId => {
    const { error } = await client.delete(`/comments/${answerId}`);

    if (!error) {
      setAnswers(prev => prev.filter(item => item.id !== answerId));
    } else {
      console.log(error);
      toast.error('Erro ao deletar resposta');
    }
  };

  useEffect(() => {
    findAnswers();
  }, [comments, comment]);

  return (
    <div
      className="post-comment"
      key={comment.id}
    >
      <Link
        to={`${basePath}/usuario/metricas/${comment.user.id}`}
        state={{ isRedirected: true }}
      >
        <div
          className="post-comment__avatar"
          style={{
            backgroundImage: `url(${(comment.user && comment.user.image.url) || userAvatar})`
          }}
        />
      </Link>
      <div className="post-comment__body">
        <div className="post-comment__header">
          <Link
            to={`${basePath}/usuario/metricas/${comment.user.id}`}
            state={{ isRedirected: true }}
          >
            <div className="post-comment__header-name">{(comment.user && comment.user.name) || 'Usuário do sistema'}</div>
          </Link>
          <div className="post-comment__header-time">{moment(comment['created-at']).fromNow()}</div>
        </div>

        <div className="post-comment__description">
          <div className="post-comment__description-text">{comment.body}</div>
        </div>

        <AnswerCommentForm
          answers={answers}
          isOpenAnswer={isOpenAnswer}
          onSubmit={handleCreateAnswer}
          onDelete={handleDeleteAnswer}
        />
      </div>

      <div>
        <button
          className="btn--answers"
          style={{ opacity: isOpenAnswer ? 0.5 : 1 }}
          onClick={() => setIsOpenAnswer(prev => !prev)}
        >
          Responder
        </button>

        {(authorityLevel === 'admin' || comment.user?.id === session.user.id) && (
          <div
            className="post-comment__actions"
            onClick={() => deleteComment(comment.id)}
          >
            <div
              className="post-comment__action"
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <div
                className="post-comment__action-icon"
                style={{ margin: 0 }}
              >
                <img
                  alt=""
                  src={trashIcon}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
