import { useTranslation } from 'react-i18next';

import { TopBar } from './TopBar';
import { ShadowContainer } from './ShadowContainer';

import navigationPng from '../../images/onboarding/questions/navigation.png';
import results1Png from '../../images/onboarding/questions/results-1.png';
import results2Png from '../../images/onboarding/questions/results-2.png';
import courseSelectPng from '../../images/onboarding/avaliations/course-select.png';
import saveAndLeavePng from '../../images/onboarding/questions/save-and-leave.png';
import trackPerformance1Png from '../../images/onboarding/questions/track-performance-1.png';
import trackPerformance2Png from '../../images/onboarding/questions/track-performance-2.png';
import answerQuestionsPng from '../../images/onboarding/questions/answer-questions.png';
import exploreCardsPng from '../../images/onboarding/questions/explore-cards.png';

export function QuestionsOnboarding() {
  const { t } = useTranslation();

  return (
    <section>
      <TopBar />

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.selectHandoutsAndResults')}</h1>
        <p className="text-option-onboarding">{t('onboarding.selectHandoutsAndResultsDesc')}</p>

        <img
          src={navigationPng}
          className="w-auto"
        />
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.analyzeYourResults')}</h1>
        <p className="text-option-onboarding">{t('onboarding.analyzeYourResultsDesc')}</p>

        <div className="container-shadow">
          <ShadowContainer width="fit-content">
            <img src={results1Png} />
          </ShadowContainer>

          <ShadowContainer width="fit-content">
            <img src={results2Png} />
          </ShadowContainer>
        </div>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.handoutsFilter')}</h1>
        <p className="text-option-onboarding">{t('onboarding.handoutsFilterDesc')}</p>

        <ShadowContainer width="fit-content">
          <img src={courseSelectPng} />
        </ShadowContainer>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.exploreHandoutsCard')}</h1>
        <p className="text-option-onboarding">{t('onboarding.exploreHandoutsCardDesc')}</p>

        <img
          src={exploreCardsPng}
          className="w-70"
        />
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.answerQuestions')}</h1>
        <p className="text-option-onboarding">{t('onboarding.answerQuestionsDesc')}</p>

        <ShadowContainer>
          <img src={answerQuestionsPng} />
        </ShadowContainer>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.trackYourPerformance')}</h1>
        <p className="text-option-onboarding">{t('onboarding.trackYourPerformanceDesc')}</p>

        <div style={{ display: 'flex', gap: '18px' }}>
          <ShadowContainer width="fit-content">
            <img src={trackPerformance1Png} />
          </ShadowContainer>

          <ShadowContainer width="fit-content">
            <img src={trackPerformance2Png} />
          </ShadowContainer>
        </div>
      </div>

      <div>
        <h1 className="title-option-onboarding">{t('onboarding.saveAndLeave')}</h1>
        <p className="text-option-onboarding">{t('onboarding.saveAndLeaveDesc')}</p>

        <img
          src={saveAndLeavePng}
          className="w-auto"
        />
      </div>
    </section>
  );
}
