import React from 'react';
import { useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const SelectComponent = ({ i, courses, form }) => {
  const animatedComponents = makeAnimated();

  const filteredClassroomByCourse = courses?.find(item => item.id === form.values.course_ids[i])?.classrooms.map(item => ({ value: item.id, label: item.title + ' - ' + item.institution.name }));

  const deleteRow = () => {
    const classrooms = form.values.classroom_ids;
    classrooms.splice(i, 1);
    form.setFieldValue('classroom_ids', classrooms);

    const courses = form.values.course_ids;
    courses.splice(i, 1);
    form.setFieldValue('course_ids', courses);
  };

  return (
    <div
      className="form__row form__row--columns"
      key={i}
    >
      <div className="form__col">
        <label
          htmlFor=""
          className="form__label"
        >
          Curso
        </label>
        <select
          value={form.values.course_ids[i]}
          className="form__select"
          name="course"
          id="course"
          onChange={e => {
            const actualValue = [...form.values.course_ids];
            actualValue[i] = parseInt(e.target.value);
            form.setFieldValue('course_ids', actualValue);
          }}
        >
          <option value="blank"> Selecione um curso</option>
          {courses.map(c => (
            <option
              value={c.id}
              key={c.id}
            >
              {c.title}
            </option>
          ))}
        </select>
      </div>

      <fieldset className="form__col">
        <legend className="form__label">*Turma</legend>
        <Select
          classNamePrefix="react-multi-select"
          openMenuOnFocus
          noOptionsMessage={() => 'Sem opções'}
          isDisabled={false}
          value={form.values.classroom_ids[i]}
          placeholder={'Selecione as turmas'}
          onChange={item => form.setFieldValue(`classroom_ids.${i}`, item)}
          components={{
            animatedComponents,
            IndicatorSeparator: () => null,
            ClearIndicator: () => null
          }}
          closeMenuOnSelect={false}
          isSearchable={true}
          options={filteredClassroomByCourse}
          isMulti
        />
      </fieldset>

      {form.values.classroom_ids?.length > 1 && (
        <FiTrash2
          className="list-alternatives__icon"
          onClick={() => deleteRow()}
        />
      )}
    </div>
  );
};

export default SelectComponent;
