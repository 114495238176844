import React, { createContext, useState, useCallback } from 'react';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { t } from 'i18next';
import ActionCableConsumer from 'app/utils/actionCableConsumer';
import { useSession } from 'app/hooks/useSession';

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const client = useClient();
  const { session } = useSession();
  const [notificationsFeed, setNotificationsFeed] = useState([]);
  const [notificationFeedCount, setNotificationFeedCount] = useState();
  const [notificationsComment, setNotificationsComment] = useState([]);
  const [notificationCommentCount, setNotificationCommentCount] = useState();
  const [loading, setLoading] = useState(true);
  const [notificationsTimeline, setNotificationsTimeline] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [notificationsImportant, setNotificationsImportant] = useState([]);
  const [meta, setMeta] = useState();
  const isAdmin = session?.user?.profileNames.includes('Administrador');

  async function getNotificationsFeed() {
    setLoading(true);
    const { data, error } = await client.fetch('notifications?filter[use_type]=0&page[number]=1&page[size]=10');

    if (error) {
      toast.error(t('toast.errorNotification'));
    } else {
      setNotificationsFeed(data);
      setNotificationFeedCount(data.filter(n => n.unread).length);
    }
    setLoading(false);
  }

  async function getNotificationsComment() {
    setLoading(true);
    const { data, error } = await client.fetch('notifications?only_messages=true&page[number]=1&page[size]=10');

    if (error) {
      toast.error(t('toast.errorNotification'));
    } else {
      setNotificationsComment(data);
      setNotificationCommentCount(data.filter(n => n.unread).length);
    }
    setLoading(false);
  }

  const getNotificationForTimeline = async (useType, courseId) => {
    setLoading(true);
    setHasMore(true);
    setPageNumber(1);
    setLoading(true);

    const url = courseId ? `/notifications?filter[courses.id]=${courseId}&page[number]=${pageNumber}&page[size]=10` : `notifications?filter[use_type]=${useType}&page[number]=${pageNumber}&page[size]=10`;
    const { data, error, meta } = await client.fetch(url);
    setLoading(false);
    if (error) {
      toast.error(t('toast.errorNotificationTimeline'));
    } else {
      if (data.length < 10) {
        setHasMore(false);
      }
      setNotificationsTimeline([...data]);
    }
    if (meta) {
      setMeta(meta);
    }
    setLoading(false);
  };

  const updateNotificationsList = async (useType, courseId) => {
    setLoading(true);
    const url = courseId ? `/notifications?filter[courses.id]=${courseId}&page[number]=${pageNumber}&page[size]=10` : `notifications?filter[use_type]=${useType}&page[number]=${pageNumber}&page[size]=10`;
    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error(t('toast.errorNotification'));
    } else {
      if (data.length === 0) {
        setHasMore(false);
      }
      setNotificationsTimeline([...notificationsTimeline, ...data]);
    }
    setLoading(false);
  };

  const getNotificationsImportant = async courseId => {
    setLoading(true);
    const url = courseId ? `/notifications?filter[courses.id]=${courseId}&only_important=true&page[number]=1&page[size]=5` : '/notifications?only_important=true&page[number]=1&page[size]=5';
    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error('Erro ao carregar notificações');
    } else {
      setNotificationsImportant(data);
    }
    setLoading(false);
  };

  const handleReceivedNotification = data => {
    const parsedData = JSON.parse(data);
    const useType = parsedData.data.attributes.name;

    if (useType === 'Feed') {
      setNotificationsFeed(prevNotifications => [parsedData.data.attributes, ...prevNotifications]);
      setNotificationFeedCount(prevCount => prevCount + 1);
    } else if (useType === 'Notificação') {
      setNotificationsComment(prevNotifications => [parsedData.data.attributes, ...prevNotifications]);
      setNotificationCommentCount(prevCount => prevCount + 1);
    } else if (useType === 'Evento' || useType === 'Projeto' || useType === 'Nova Tarefa') {
      setNotificationsTimeline(prevNotifications => [parsedData.data.attributes, ...prevNotifications]);
    } else if ((isAdmin && useType === 'Atividade Complementar') || useType === 'Aulas') {
      setNotificationsTimeline(prevNotifications => [parsedData.data.attributes, ...prevNotifications]);
    } else if (useType === 'Avaliações' || useType === 'Atividade Complementar') {
      setNotificationsImportant(prevNotifications => [parsedData.data.attributes, ...prevNotifications]);
    }

    // if (useType === 'notification') {
    //   setNotificationsFeed(prevNotifications => [parsedData.data.attributes, ...prevNotifications]);
    //   setNotificationFeedCount(prevCount => prevCount + 1);
    // } else if (useType === 'timeline') {
    //   setNotificationsTimeline(prevNotifications => [parsedData.data.attributes, ...prevNotifications]);
    // } else if (isAdmin && useType === 'Atividade Complementar' || useType === 'Aulas' ) {
    //   setNotificationsTimeline(prevNotifications => [parsedData.data.attributes, ...prevNotifications]);
    // } else if (useType === 'Avaliações' || useType === 'Atividade Complementar') {
    //   setNotificationsImportant(prevNotifications => [parsedData.data.attributes, ...prevNotifications]);
    // }
  };

  return (
    <NotificationContext.Provider
      value={{
        getNotificationsFeed,
        setNotificationsFeed,
        notificationsFeed,
        setNotificationFeedCount,
        notificationFeedCount,
        notificationsComment,
        setNotificationsComment,
        notificationCommentCount,
        setNotificationCommentCount,
        getNotificationsComment,
        getNotificationForTimeline,
        setNotificationsTimeline,
        notificationsTimeline,
        pageNumber,
        updateNotificationsList,
        hasMore,
        meta,
        setPageNumber,
        getNotificationsImportant,
        setNotificationsImportant,
        notificationsImportant
      }}
    >
      <ActionCableConsumer
        channel="NotificationChannel"
        channelId={session?.user?.id}
        setObjectChannel={handleReceivedNotification}
        channelKey="user_id"
      />
      {children}
    </NotificationContext.Provider>
  );
};
