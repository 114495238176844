import React, { useState, useEffect } from 'react';
import ActivityContent from './ActivityContent';
import ActivitySidebar from './ActivitySidebar';
import { useParams } from '@reach/router';
import { t } from 'i18next';
import { i18n } from 'app/components/i18n/i18n';

export function CreateActivity(props) {
  const { step } = props;
  const [currentStep, setCurrentStep] = useState(0);
  const { courseId, moduleId } = useParams();

  useEffect(() => {
    i18n.changeLanguage(window.env.REACT_APP_LOCALE);
  }, []);

  const steps = [
    {
      id: 0,
      route: 'nome',
      text: t('activity.stepsActivityName')
    },
    {
      id: 1,
      route: 'sobre',
      text: t('activity.stepsActivityAbout')
    },
    {
      id: 2,
      route: 'prazo',
      text: t('activity.stepsActivityDeadline')
    }
  ];

  function getStep() {
    switch (step) {
      case 'nome':
        setCurrentStep(0);
        break;
      case 'sobre':
        setCurrentStep(1);
        break;
      case 'prazo':
        setCurrentStep(2);
        break;
      default:
        setCurrentStep(0);
        break;
    }
  }

  useEffect(() => getStep());

  return (
    <div className="main-screen">
      <ActivitySidebar
        courseId={courseId}
        moduleId={moduleId}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        steps={steps}
      />

      <main className="main-content">
        <ActivityContent
          step={step}
          steps={steps}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      </main>
    </div>
  );
}

export default CreateActivity;
