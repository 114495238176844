import React from 'react';
import { FiCalendar, FiChevronLeft, FiMousePointer } from 'react-icons/fi';
import { Link, navigate, useParams } from '@reach/router';
import { t } from 'i18next';
import { useFormik } from 'formik';
import EmptyState from 'app/components/EmptyState';
import EssayCover from 'app/images/placeholders/show-course.jpg';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import EssayStudentCard from './EssayStudentCard';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import { useState } from 'react';
import { useClient } from 'jsonapi-react';
import Loader from 'app/components/loader';
import moment from 'moment';
import BreadCrumbs from 'app/components/BreadCrumbs';

export default function EssayMonitoring({ essayId, uri }) {
  const [essay, setEssay] = useState({});
  const [essayStudents, setEssayStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const client = useClient();
  const basePath = window.env.REACT_APP_BASE_PATH;

  const filters = useFormik({
    initialValues: {
      status: 'blank',
      searchTerm: ''
    }
  });

  const essayStatus = {
    close_for_submissions: 'Prazo encerrado',
    accepting_proposal: 'Aguardando envio'
  };

  const getEssay = async () => {
    const { data, error } = await client.fetch(['essays', essayId]);
    if (error) {
      toast.error('Erro ao buscar redação');
    } else {
      setEssay(data);
    }
  };

  const getEssayStudents = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(['essays', essayId, 'essay_students']);
    if (error) {
      toast.error('Erro ao buscar estudantes');
    } else {
      setEssayStudents(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getEssay();
    getEssayStudents();
  }, []);

  const filteredUsersByName = essayStudents.filter(item => {
    if (filters.values.searchTerm === '') {
      return true;
    }
    if (filters.values.searchTerm !== '' && item.user.name.toLowerCase().includes(filters.values.searchTerm.toLowerCase())) {
      return true;
    } else return false;
  });

  const filteredUsersByStatus = filteredUsersByName.filter(item => {
    if (filters.values.status === 'blank') return true;
    if (filters.values.status === '1') {
      return item.status === 'reviewed';
    }
    if (filters.values.status === '2') {
      return item.status === 'unreviewed';
    }
    if (filters.values.status === '3') {
      return item.status === 'not_sent';
    }
    if (filters.values.status === '4') {
      return item.status === 'processing';
    }
    if (filters.values.status === '5') {
      return item.status === 'annulled';
    }
    if (filters.values.status === '6') {
      return item.status === 'minimum_length_not_satisfied';
    }
    if (filters.values.status === '7') {
      return item.status === 'failed_to_process';
    }
  });

  const options = [
    { name: 'Revisada', id: 1 },
    { name: 'Em revisão', id: 2 },
    { name: 'Aluno não enviou', id: 3 },
    { name: 'Em processamento', id: 4 },
    { name: 'Anulada', id: 5 },
    { name: 'Arquivo inválido', id: 6 },
    { name: 'Falha ao processar', id: 7 }
  ];

  const breadCrumbs = {
    title: 'Atividades',
    nav: [
      {
        route: `${basePath}/redacao`,
        name: 'Redação',
        isActive: false
      },
      {
        route: uri,
        name: essay?.topic?.length > 70 ? essay?.topic?.slice(0, 70) + '...' : essay?.topic,
        isActive: true
      }
    ]
  };

  return (
    <main className="main-content main-content--block">
      <BreadCrumbs data={breadCrumbs} />

      <div className="course-about u-mb-4">
        <div
          className="show-course-banner"
          style={{
            backgroundImage: `url(${null || EssayCover})`
          }}
        >
          <div className="show-course-banner__inner">
            <div className="show-course-banner__container">
              <span className="badge badge--tiny badge--warning">{essayStatus[essay.status]}</span>
              <div
                className="show-course-banner__title"
                title={essay?.topic}
              >
                {essay?.topic?.length > 70 ? essay?.topic?.slice(0, 70) + '...' : essay?.topic}
              </div>
            </div>
          </div>
        </div>

        <div className="widget-wrapper u-mb-0">
          <div className="info-widget info-widget--t3">
            <div className="info-widget__header">
              <h3 className="info-widget__title">Índice de envio</h3>
            </div>

            <div className="info-widget__body">
              <p className="info-widget__value">
                <FiMousePointer /> {essay?.['submissions-percent']}
              </p>
            </div>
          </div>

          <div className="info-widget info-widget--t3">
            <div className="info-widget__header">
              <h3 className="info-widget__title">Prazo de entrega</h3>
            </div>

            <div className="info-widget__body">
              <p className="info-widget__value">
                <FiCalendar /> {moment(essay?.['delivery-date']).format('DD/MM/YYYY')}
              </p>
            </div>
          </div>

          <div className="info-widget info-widget--t3">
            <div className="info-widget__header">
              <h3 className="info-widget__title">Nota média</h3>
            </div>

            <div className="info-widget__body">
              <p className="info-widget__value">
                <FiCalendar /> {essay?.['average-grade']}
              </p>
            </div>
          </div>
        </div>

        <div className="course-about__body">
          <div>
            <h3 className="course-about__title u-mb-1">Envios</h3>

            <div className="card__progress card__progress--value-first u-mb-0">
              <div className="card__progress-container">
                <div
                  className="card__progress-bar"
                  role="progressbar"
                  style={{ width: `${essay?.['submissions-percent']}` }}
                  aria-valuenow={essay?.['submissions-percent']}
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
              <div className="card__progress-text">{`${essay?.['submissions-count']}/${essay?.['total-of-students']}`}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="course-about">
        <div className="course-about__body">
          <div className="course-about__filter">
            <div className="filter-bar filter-bar--borderless">
              <div className="filter-bar__inner">
                <div className="filter-bar__row">
                  <label
                    htmlFor="search"
                    className="filter-bar__label"
                  >
                    {t('textsCommon.search')}
                  </label>
                  <input
                    className="form__control form__control--search-with-icon"
                    placeholder="Pesquisar por estudante"
                    type="search"
                    name="search"
                    id="search"
                    onChange={e => filters.setFieldValue('searchTerm', e.target.value)}
                  />
                </div>

                <FilterSelectionBox
                  blankLabel={t('filter.blankLabelAll2')}
                  label={t('filter.labelSelectStatus')}
                  value={filters.values.status}
                  onChange={e => filters.setFieldValue('status', e.target.value)}
                  options={options}
                />
              </div>
            </div>

            <div className="round-dg-wrapper">
              <table
                className="round-dg round-dg--light"
                style={{ minWidth: '710px' }}
              >
                <thead className="round-dg__header">
                  <tr className="round-dg__row">
                    <th className="round-dg__cell-header">Nome</th>
                    <th
                      className="round-dg__cell-header"
                      style={{ width: '116px' }}
                    >
                      Entrega
                    </th>
                    <th
                      className="round-dg__cell-header"
                      style={{ width: '76px' }}
                    >
                      Nota
                    </th>
                    <th
                      className="round-dg__cell-header"
                      style={{ width: '146px' }}
                    >
                      Status da correção
                    </th>
                    <th
                      className="round-dg__cell-header"
                      style={{ width: '190px' }}
                    />
                  </tr>
                </thead>

                <tbody className="round-dg__body">
                  {filteredUsersByStatus?.map((student, i) => (
                    <EssayStudentCard
                      essayId={essayId}
                      key={i}
                      student={student}
                    />
                  ))}
                </tbody>
              </table>
              {loading && <Loader />}
              {!loading && filteredUsersByStatus?.length === 0 && (
                <EmptyState
                  type="data"
                  title={'Nenhuma redação'}
                  text={'Ainda não temos redações para mostrar, mas você será notificado quando elas surgirem aqui.'}
                  bgless
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
