import { useClient } from 'jsonapi-react';
import React, { createContext, useEffect, useRef, useState } from 'react';

export const WeeklyChallengeContext = createContext();

export const WeeklyChallengeProvider = ({ children }) => {
  const client = useClient();
  const [currentStep, setCurrentStep] = useState(1);
  const [currentRound, setCurrentRound] = useState(1);
  const [amountRounds, setAmountRounds] = useState(null);
  const [currentTaxonomy, setCurrentTaxonomy] = useState(null);
  const [currentChallenge, setCurrentChallenge] = useState(null);
  const [currentAttempt, setCurrentAttempt] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [taxonomies, setTaxonomies] = useState([]);
  const [rightAnswers, setRightAnswers] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [challengeCurrentStatus, setChallengeCurrentStatus] = useState(null);
  const colors = ['#FF4B4B', '#EA2F2F', '#FFC800', '#EDBA00', '#58CC02', '#4FB305', '#1CB0F6', '#029CE5'];
  const [generalCount, setGeneralCount] = useState(0);
  const [start, setStart] = useState(false);
  const [counter, setCounter] = useState(30);
  const [showQuestionCountDown, setShowQuestionCountDown] = useState(false);
  const [questionCountDownTimer, setQuestionCountDownTimer] = useState(5);
  const initialRef = useRef(0);
  let intervalRef = useRef();

  const increaseCount = () => setGeneralCount(prev => prev + 1);

  useEffect(() => {
    if (start) {
      intervalRef.current = setInterval(increaseCount, 1000);

      return () => clearInterval(intervalRef.current);
    }
  }, [start]);

  useEffect(() => {
    setCounter(30);
  }, [currentRound, currentTaxonomy]);

  const getQuestions = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`/challenges/${currentChallenge.id}/challenge_questions?taxonomy_id=${currentTaxonomy.id}`);
    if (error) {
      toast.error('Erro ao buscar questões');
    } else {
      setQuestions(data);
      setAmountRounds(data.length);
      setCurrentRound(1);
    }
    setLoading(false);
  };

  const getTaxonomies = async () => {
    const { data, error } = await client.fetch(`challenges/${currentChallenge.id}/taxonomy_roulette`);
    if (error) {
      toast.error('Erro ao buscar taxonomias');
    } else {
      setData(data);
    }
  };

  useEffect(() => {
    if (data) {
      const taxonomiesWithColor = data?.data?.map((item, index) => {
        return { ...item, color: (item.color = colors[index % colors.length]) };
      });

      setTaxonomies(taxonomiesWithColor);
    }
  }, [data]);

  const rest = taxonomies?.length <= 8 && [...Array(8 - taxonomies?.length)].map(slice => ({ name: '', color: '#eee' }));
  const slices = taxonomies?.length <= 8 ? [...taxonomies, ...rest] : taxonomies;

  return (
    <WeeklyChallengeContext.Provider
      value={{
        setQuestionCountDownTimer,
        questionCountDownTimer,
        setShowQuestionCountDown,
        showQuestionCountDown,
        loading,
        initialRef,
        generalCount,
        setStart,
        start,
        setChallengeCurrentStatus,
        challengeCurrentStatus,
        setRightAnswers,
        rightAnswers,
        getTaxonomies,
        taxonomies,
        slices,
        questions,
        getQuestions,
        setQuestions,
        currentStep,
        currentRound,
        setCurrentRound,
        currentChallenge,
        currentTaxonomy,
        amountRounds,
        currentAttempt,
        setCurrentChallenge,
        setCurrentStep,
        setCurrentTaxonomy,
        setAmountRounds,
        setCurrentAttempt,
        setGeneralCount,
        setCounter,
        counter
      }}
    >
      {children}
    </WeeklyChallengeContext.Provider>
  );
};
