import { t } from 'i18next';

const CourseStep = props => {
  const { courses, createModuleForm, coursesLoading } = props;

  return (
    <div className="form__row">
      <label
        className="form__label"
        htmlFor="courseId"
      >
        * {t('exams.thCourse')}
      </label>
      <select
        className="form__select"
        name="courseId"
        id="courseId"
        {...createModuleForm.getFieldProps('courseId')}
      >
        {coursesLoading && <option>{t('exams.loading')}</option>}
        {!coursesLoading && (
          <>
            <option value="blank">{t('filter.labelSelectCourse')}</option>
            {courses &&
              courses.map(course => (
                <option
                  key={course.id}
                  value={course.id}
                >
                  {course.title}
                </option>
              ))}
          </>
        )}
      </select>
      {createModuleForm.touched.courseId && createModuleForm.errors.courseId && <span style={{ color: 'red' }}>{createModuleForm.errors.courseId}</span>}
    </div>
  );
};

export default CourseStep;
