import React from 'react';
import { Link } from '@reach/router';
import { useTranslation } from 'react-i18next';

export default function TabNavBar({ selectedTab }) {
  const { t } = useTranslation();
  const basePath = window.env.REACT_APP_BASE_PATH;
  return (
    <div className="tab">
      <Link
        className={selectedTab === 'lms' ? 'tab__link active' : 'tab__link'}
        to={`${basePath}/lms`}
      >
        {t('lms.toggleLms')}
      </Link>
      <Link
        className={selectedTab === 'certificados' ? 'tab__link active' : 'tab__link'}
        to={`${basePath}/lms/certificado`}
      >
        {t('lms.toggleCertifications')}
      </Link>
    </div>
  );
}
