import { useParams } from '@reach/router';
import Loader from 'app/components/loader';
import { useSession } from 'app/hooks/useSession';
import { useClient } from 'jsonapi-react';
import React, { useEffect, useRef, useState } from 'react';
import { useMemo, memo } from 'react';
import toast from 'react-hot-toast';
import Content from './Content';
import Sidebar from './Sidebar';
import Error404 from 'app/components/Error404';
import { useTranslation } from 'react-i18next';
import { i18n } from 'app/components/i18n/i18n';

function StudentResponse(props) {
  const { t } = useTranslation();
  const client = useClient();
  const { id } = useParams();
  const { session } = useSession();
  const [isLoading, setIsLoading] = useState(true);
  const [question, setQuestion] = useState();
  const [currentQuestion, setCurrentQuestion] = useState();
  const [questions, setQuestions] = useState([]);
  const [taxonomies, setTaxonomies] = useState([]);
  const [questionBook, setQuestionBook] = useState({});
  const [questionBookUser, setQuestionBookUser] = useState();
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [timeLeft, setTimeLeft] = useState(null);
  const [progressPercent, setProgressPercent] = useState(0);
  const [activeGroupedQuestions, setActiveGroupedQuestions] = useState(0);
  const [notAllowed, setNotAllowed] = useState(false);
  const [questionBooksUsers, setQuestionBooksUsers] = useState([]);
  const reference = useRef(null);

  useEffect(() => {
    i18n.changeLanguage(window.env.REACT_APP_LOCALE);
  }, []);

  const callgetQuestionBook = () => {
    try {
      getQuestionBook();
    } catch (e) {
      console.error(e);
      toast.error(t('toast.errorGetExam'));
    }
  };

  useEffect(() => {
    clearTimeout(reference.current);
    reference.current = setTimeout(getQuestionBook, 500);
    return () => clearTimeout(reference.current);
  }, [questionBooksUsers]);

  const examTime = parseInt(questionBook?.['resolution-time'], 10);

  const isBetweenOpenDays = questionBook => {
    return true;
  };

  const groupQuestionsByCode = items => {
    if (items?.length > 0) {
      return _.groupBy(items, 'code');
    }
  };

  const selectQuestionBookTaxonomy = newIndex => {
    setActiveGroupedQuestions(newIndex);
  };

  const getQuestionBook = async () => {
    setIsLoading(true);

    const { data, error } = await client.fetch(`/questions?filter[question_book_id]=${id}`);
    if (error) {
      setNotAllowed(true);
    } else {
      setQuestions(data);

      const filterQuestionBook = {
        current_user: session.user.id
      };

      const questionBookResponse = await client.fetch(['question_books', id, filterQuestionBook]);

      const examTime = parseInt(questionBookResponse.data?.['resolution-time'], 10);
      setTimeLeft(examTime * 60 * 60);

      setQuestionBook(questionBookResponse.data);

      if (isBetweenOpenDays(questionBookResponse.data)) {
        if (questionBookResponse.data['last-question-books-user-id']) {
          if (questionBookResponse.data['is-question-book-done'] && !questionBookResponse.data['learning-system-ids']?.[0]) {
            window.location.pathname = '/questions/finish';
          } else if (questionBookResponse.data['is-question-book-done'] && questionBookResponse.data['learning-system-ids']?.[0]) {
            const response = await client.mutate('question_books_users', {
              question_book_id: id
            });
            setQuestionBookUser(response);
            questionBookResponse.data.taxonomies = questionBookResponse.data.taxonomies.map(taxonomy => {
              return { ...taxonomy, answers: [] };
            });
          } else {
            const response = await client.fetch(['question_books_users', questionBookResponse.data['last-question-books-user-id']]);
            setQuestionBookUser(response);
          }
        } else {
          const response = await client.mutate('question_books_users', {
            question_book_id: id
          });
          setQuestionBookUser(response);
        }

        const questionId = questionBookResponse.data['question-book-taxonomies']?.[0]?.question_ids?.[0];
        setCurrentQuestion(questionId);
        setQuestion(data.find(q => q.id === questionId));

        let questionTaxonomies = questionBookResponse.data.taxonomies;
        questionTaxonomies.forEach(questionTaxonomy => {
          const questionIds = questionBookResponse.data['question-book-taxonomies'].find(e => e.taxonomy_id === questionTaxonomy.id).question_ids;
          const tQuestions = data.filter(q => questionIds.includes(q.id));
          questionTaxonomy.selected = false;
          questionTaxonomy.questions = tQuestions;
          questionTaxonomy.groupedQuestions = groupQuestionsByCode(tQuestions);
        });

        if (questionTaxonomies[0]) {
          questionTaxonomies[0].selected = true;
        }
        setTaxonomies(questionTaxonomies);
      } else {
        window.location.pathname = '/open-onboarding';
      }

      setIsLoading(false);
    }
  };

  const updateLastAnswerSubmited = answer => {
    let questionIndex;
    const taxonomyIndex = _.findIndex(taxonomies, taxonomy => {
      questionIndex = _.findIndex(taxonomy.questions, {
        id: answer.questionId
      });
      return questionIndex > -1;
    });

    let newTaxonomies = taxonomies;
    const answerIndex = _.findIndex(newTaxonomies[taxonomyIndex]?.answers, { id: answer.id });

    if (answerIndex > -1) {
      newTaxonomies[taxonomyIndex].answers[answerIndex] = answer;
    } else if (taxonomyIndex > -1) {
      newTaxonomies[taxonomyIndex].answers.push(answer);
    }

    setTaxonomies([...newTaxonomies]);
  };

  useEffect(() => {
    setProgressPercent((currentQuestion * 100) / questions?.length);
  }, [currentQuestion, questions?.length]);

  const filteredQuestions = useMemo(() => taxonomies.map(taxonomy => questions.filter(question => question.taxonomies.some(questionTaxonomy => questionTaxonomy?.id === taxonomy.id))), [taxonomies, questions]);
  const taxonomyNames = useMemo(
    () =>
      questionBook?.['question-book-taxonomies']?.map(qbTaxonomy => {
        const taxonomy = taxonomies.find(taxonomy => taxonomy.id === qbTaxonomy.taxonomy_id);
        return taxonomy?.name;
      }),
    [questionBook, taxonomies]
  );

  const handleSelectedAnswersChange = newSelectedAnswers => {
    setSelectedAnswers(newSelectedAnswers);
  };

  return !notAllowed ? (
    isLoading ? (
      <Loader />
    ) : (
      <div className="main-screen">
        <Sidebar
          currentQuestion={currentQuestion}
          setCurrentQuestion={setCurrentQuestion}
          taxonomies={taxonomies}
          exam={questionBook}
          filteredQuestions={filteredQuestions}
          selectedAnswers={selectedAnswers}
          taxonomyNames={taxonomyNames}
          selectQuestionBookTaxonomy={selectQuestionBookTaxonomy}
        />

        <Content
          selectQuestionBookTaxonomy={selectQuestionBookTaxonomy}
          updateLastAnswerSubmited={updateLastAnswerSubmited}
          currentQuestion={currentQuestion}
          setCurrentQuestion={setCurrentQuestion}
          questions={questions}
          question={question}
          handleSelectedAnswersChange={handleSelectedAnswersChange}
          examTime={examTime}
          progressPercent={progressPercent}
          questionBooksUser={questionBookUser}
          questionBook={questionBook}
          taxonomies={taxonomies}
          activeGroupedQuestions={activeGroupedQuestions}
        />
      </div>
    )
  ) : (
    <Error404 />
  );
}

export default memo(StudentResponse);
