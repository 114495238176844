import { useLocation } from '@reach/router';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';

export default function StepVideolesson(props) {
  const { step, createLessonForm, urlError } = props;
  const [player, setPlayer] = useState('');
  const [videoId, setVideoId] = useState();

  const getPlayer = url => {
    const youtubeRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
    const vimeoRegex = /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;

    const youtubeResult = url?.match(youtubeRegex);
    const vimeoResult = url?.match(vimeoRegex);

    if (youtubeResult) {
      setPlayer('youtube');
      setVideoId(youtubeResult[6]);
    } else if (vimeoResult) {
      setPlayer('vimeo');
      setVideoId(vimeoResult[4]);
    } else {
      setPlayer('');
      setVideoId(null);
    }
  };

  useEffect(() => {
    getPlayer(createLessonForm.values.url);
  }, [createLessonForm.values.url]);

  return (
    step === 'videoaula' && (
      <div className="page__content fadeIn u-mb-5">
        <div className="new-questions__header">
          <h2 className="new-questions__title">{createLessonForm.values.body || t('textsCommon.lessonName')}</h2>
        </div>

        <div className="new-questions__body">
          <p className="new-questions__subtitle">{t('lessons.lessonVideoDescription')}</p>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="url"
            >
              {t('lessons.titleLinkVideo')}
            </label>
            <input
              className="form__control"
              placeholder={t('lessons.placeholderSetLink')}
              id="url"
              name="url"
              type="url"
              value={createLessonForm.values.url}
              onChange={e => createLessonForm.setFieldValue('url', e.target.value)}
            />
            {urlError && <span style={{ color: 'red' }}>{urlError}</span>}
          </div>
          {createLessonForm.values.url && player === 'youtube' && (
            <div className="classroom-video-content">
              <div className="classroom-video-content__video-frame">
                <div>
                  <iframe
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${videoId}`}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  />
                </div>
              </div>
            </div>
          )}
          {createLessonForm.values.url && player === 'vimeo' && (
            <div className="classroom-video-content">
              <div className="classroom-video-content__video-frame">
                <div>
                  <iframe
                    src={`https://player.vimeo.com/video/${videoId}`}
                    width="560"
                    height="315"
                    frameborder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowfullscreen
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  );
}
