import React, { useState, useEffect, useContext } from 'react';
import toast from 'react-hot-toast';
import { useClient, useQuery } from 'jsonapi-react';
import Loader from 'app/components/loader';
import MyProgressWidget from './MyProgressWidget';
import ActivityLog from './ActivityLog';
import KpiWidget from './KpiWidget';
import { useSession } from 'app/hooks/useSession';
import { useCourse } from 'app/hooks/useCourse';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import { useTranslation } from 'react-i18next';
import { usePlatformAbilities } from 'app/hooks/usePlatformAbilities';
import FrequentShortcutWidget from './FrequentShortcutWidget';
import ShortcutWidgetAdmin from './ShortcutWidgetAdmin';
import EngagementWidget from './EngagementWidget';

function DashboardMetrics(props) {
  const { t } = useTranslation();
  const client = useClient();
  const { session } = useSession();
  const { courses, getSimpleCourses } = useCourse();
  const { abilities } = usePlatformAbilities();
  const [loading, setLoading] = useState(true);
  const [cards, setCards] = useState([]);
  const [allWidgetsLoaded, setAllWidgetsLoaded] = useState(false);
  const [currCourse, setCurrCourse] = useState();
  const profiles = session?.user?.profileNames || (session?.user && session?.user['profile-names']) || [];
  const basePath = window.env.REACT_APP_BASE_PATH;

  const { data } = useQuery('essays?filter[status]=unreviewed');

  const getCards = async course => {
    setLoading(true);
    const courseFilter = course !== null ? `?filter[course_id]=${course.id}` : '';
    const { data, errors } = await client.fetch(`dashboards/cards${courseFilter}`);

    if (errors) {
      toast.error('Erro ao buscar informações do card.');
    } else {
      setCards(data);
    }

    setLoading(false);
  };

  const changeCourse = e => {
    let selectedCourse = e.target.value !== 'blank' ? courses.find(c => c.id === parseInt(e.target.value)) : null;
    setCurrCourse(selectedCourse);
    getCards(selectedCourse);
  };

  const unreviewedEssays = data?.filter(item => item?.status == 'unreviewed');

  const showPendingWidgetSection = !unreviewedEssays?.length || cards?.['project_versions_count'] > 0 || cards?.['activities_count'] > 0;

  useEffect(() => {
    getCards(null);
    getSimpleCourses();
  }, []);

  useEffect(() => {
    const projectsReady = abilities?.projetos && cards?.['project_versions_count'] >= 0;
    const activitiesReady = abilities?.['cursos-ativ-complementar'] && cards?.['activities_count'] >= 0;

    if (data && projectsReady && activitiesReady) {
      setAllWidgetsLoaded(true);
    }
  }, [data, cards, courses]);

  return loading ? (
    <Loader />
  ) : (
    <>
      {/* <div className="filter-bar">
        <FilterSelectionBox
          blankLabel={t('filter.blankLabel')}
          label={t('filter.labelSelectCourse')}
          value={currCourse?.id}
          onChange={changeCourse}
          options={courses}
        />
      </div> */}

      {profiles && profiles === 'Aluno' && <MyProgressWidget courseId={currCourse?.id} />}

      <FrequentShortcutWidget />

      {showPendingWidgetSection && (
        <div className="kpi-widget-dashboard">
          <div className="kpi-widget-wrapper u-mb-0">
            {allWidgetsLoaded && abilities?.projetos && cards?.['project_versions_count'] >= 0 && (
              <KpiWidget
                title={t('dashboard.projects')}
                count={cards && cards['project_versions_count'] ? cards['project_versions_count'] : 0}
                actionTitle={cards && cards['project_versions_count'] >= 2 ? t('dashboard.newProjectVersionsToEvaluate') : t('dashboard.newProjectVersionToEvaluate')}
                linkText={t('dashboard.accessProject')}
                route={`${basePath}/projetos`}
              />
            )}

            {allWidgetsLoaded && abilities?.['cursos-ativ-complementar'] && cards?.['activities_count'] >= 0 && (
              <KpiWidget
                title={t('dashboard.activities')}
                count={cards && cards['activities_count'] ? cards['activities_count'] : 0}
                actionTitle={cards && cards['activities_count'] >= 2 ? t('dashboard.activitiesToEvaluate') : t('dashboard.activityToEvaluate')}
                linkText={t('dashboard.accessActivities')}
                route={`${basePath}/atividade-complementar`}
              />
            )}
            {/* {abilities?.nps && (
            <KpiWidget
              title={t('dashboard.perceptionQuest')}
              count={cards && cards['surveys_count'] ? cards['surveys_count'] : 0}
              actionTitle={cards && cards['surveys_count'] >= 2 ? t('dashboard.answers') : t('dashboard.answer')}
              linkText={t('dashboard.accessQuest')}
              route={`${basePath}/questionario`}
            />
            )} */}

            {allWidgetsLoaded && unreviewedEssays?.length >= 0 && (
              <KpiWidget
                title="Redações"
                count={unreviewedEssays?.length}
                actionTitle="Para revisar"
                linkText="Acessar"
                route={`${basePath}/redacao`}
              />
            )}
          </div>
        </div>
      )}

      {/* TODO: Plano de Aulas e Diário de classe ainda não foram feitos (Precisa de backend) */}
      <ShortcutWidgetAdmin />

      {/* TODO: Falta fazer o relatório e os insights (Precisa de backend) */}
      <EngagementWidget />

      {/* <ActivityLog
        useType={2}
        courseId={currCourse?.id}
      /> */}
    </>
  );
}

export default DashboardMetrics;
