import React, { useEffect } from 'react';
import { useQuery } from 'jsonapi-react';
import toast from 'react-hot-toast';
import CourseCard from './CourseCard';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import EmptyState from 'app/components/EmptyState';
import Loader from 'app/components/loader';
import { useTranslation } from 'react-i18next';
import { Link } from '@reach/router';

export default function MyProgressWidget() {
  const { t } = useTranslation();
  const { data: courses = [], error, isLoading } = useQuery('/courses?detailed=true');
  const basePath = window.env.REACT_APP_BASE_PATH;

  useEffect(() => {
    if (error) {
      toast.error(t('toast.errorGetCourse'));
    }
  }, [error]);

  if (isLoading) return <Loader />;

  return (
    <div className="my-progress-widget u-mb-4">
      <div className="my-progress-widget__header">
        <h3 className="my-progress-widget__title">Meu progresso</h3>
        <Link
          className="btn btn--link"
          to={`${basePath}/curso`}
        >
          ver todos
        </Link>
      </div>

      {courses?.map(course => (
        <CourseCard
          course={course}
          key={course?.id}
        />
      ))}

      {courses.length === 0 && (
        <div className="first-time">
          <EmptyState
            type="data"
            title={t('emptyState.classTitle')}
            text={t('emptyState.classText')}
          />
        </div>
      )}
    </div>
  );
}
