import React, { useContext, useEffect, useState } from 'react';
import { useParams } from '@reach/router';
import { FiMoreHorizontal } from 'react-icons/fi';
import _ from 'lodash';
import Swal from 'sweetalert2';
import { useSession } from 'app/hooks/useSession';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import Dropdown from 'app/components/Dropdown';
import Loader from 'app/components/loader';
import { UpdateQuestionBookContext } from './provider';
import getPermission from 'app/utils/getPermission';
import { t } from 'i18next';

export const ListQuestions = () => {
  const [questionsSearch, setQuestionsSearch] = useState('');
  const [searchTermTimeout, setSearchTermTimeout] = useState();
  const { session } = useSession();

  const {
    taxonomiesLoading,
    selectedTaxonomy,
    getQuestionBookQuestions,
    selectedQuestionBookQuestions,
    selectedQuestion,
    setSelectedQuestion,
    setSelectedQuestionBookId,
    draftingQuestion,
    setDraftingQuestion,
    questionBook,
    selectedTaxonomyQuestions,
    setSelectedTaxonomyQuestions,
    searchTerm,
    setSearchTerm,
    deleteQuestionBookQuestions
  } = useContext(UpdateQuestionBookContext);

  const { questionBookId } = useParams();

  const authorityLevel = getAuthorityLevel(session);

  const draftQuestion = {
    description: '',
    modality: 'right_wrong',
    'motivator-texts': [
      {
        title: '',
        body: '',
        print: ''
      }
    ],
    alternatives: [
      {
        id: 1,
        name: '1',
        description: 'Certo',
        isNew: true
      },
      {
        id: 2,
        name: '2',
        description: 'Errado',
        isNew: true
      }
    ],
    taxonomies: [],
    'correct-alternative': {
      id: 1,
      name: '1',
      description: 'Certo',
      isNew: true
    },
    isDraft: true
  };

  // This param prop is saved on a state just to be used later.
  // This could be improved by finding all the places it is used and refactoring their logic.
  // Not a priority atm.
  useEffect(() => setSelectedQuestionBookId(questionBookId), []);

  useEffect(() => selectedQuestionBookQuestions.length === 0 && getQuestionBookQuestions(questionBookId), [selectedTaxonomy]);

  // When changing the selected taxonomy, updates the questions list
  // with the correct filter.
  useEffect(() => {
    if (selectedTaxonomy && questionBook) {
      const selectedQuestionBookTaxonomy = questionBook['question-book-taxonomies'].find(qb => qb.taxonomy_id === selectedTaxonomy.id);

      const taxonomyQuestions = selectedQuestionBookQuestions.filter(q => selectedQuestionBookTaxonomy.question_ids.includes(q.id));

      setSelectedTaxonomyQuestions(taxonomyQuestions);
    }
  }, [selectedTaxonomy, questionBook]);

  const searchQuestions = query => {
    clearTimeout(searchTermTimeout);
    setQuestionsSearch(query);

    const timeout = setTimeout(() => {
      setSearchTerm(query);
    }, 500);
    setSearchTermTimeout(timeout);
  };

  // if (!selectedTaxonomy) {
  //   return (
  //     <div className="new-questions__sidebar">
  //       <p className="new-questions__empty-state-text">
  //         Adicione uma taxonomia no caderno para poder adicionar perguntas.
  //       </p>
  //     </div>
  //   );
  // }

  // if (selectedTaxonomy && questionsLoading) {
  //   return (
  //     <div className="new-questions__sidebar">
  //       <Loader />
  //     </div>
  //   );
  // }

  // if (selectedQuestionBookQuestions.length === 0) {
  //   return (
  //     <div className="new-questions__sidebar">
  //       <p className="new-questions__empty-state-text">
  //         Adicione uma taxonomia no caderno para poder adicionar perguntas.
  //       </p>

  //       <button
  //         type="button"
  //         onClick={() => {
  //           setDraftingQuestion(true);
  //           setSelectedQuestion(draftQuestion);
  //         }}
  //         className="btn btn--full btn--outline u-mb-4"
  //       >
  //         Adicionar questão
  //       </button>
  //     </div>
  //   );
  // }

  const destroyQuestionForm = selectedQuestion => {
    Swal.fire({
      title: t('projects.warningDeleteQuestion'),
      text: t('projects.warningDeleteCannotBeUndoneText'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('button.delete'),
      showCloseButton: true
    }).then(result => {
      if (result.isConfirmed) {
        deleteQuestionBookQuestions(selectedQuestion);
      }
    });
  };

  return (
    <div className="new-questions__sidebar">
      {taxonomiesLoading && <Loader />}

      {!taxonomiesLoading && (
        <>
          <input
            aria-label="Buscar questões"
            className="form__control form__control--small form__control--search u-mb-4"
            placeholder="Pesquisar questão"
            type="search"
            name="questionsSearch"
            id="questionsSearch"
            value={questionsSearch}
            onChange={e => searchQuestions(e.target.value)}
          />
          {getPermission('Criar questões', 'Questões e apostilas') && (
            <button
              type="button"
              onClick={() => {
                setDraftingQuestion(true);
                setSelectedQuestion(draftQuestion);
              }}
              className="btn btn--full btn--outline u-mb-4"
            >
              Adicionar questão
            </button>
          )}

          {draftingQuestion && (
            <div className="taxonomies__item active">
              <span className="taxonomies__text">(Rascunho) Questão não salva</span>
              <button
                className="taxonomies__btn"
                type="button"
              >
                <FiMoreHorizontal />
              </button>
            </div>
          )}

          {selectedTaxonomyQuestions.map(question => (
            <div
              onClick={() => {
                setDraftingQuestion(false);
                setSelectedQuestion(question);
              }}
              key={question.id}
              className={`taxonomies__item${selectedQuestion && selectedQuestion.id && question.id === selectedQuestion.id ? ' active' : ''}`}
            >
              <span className="taxonomies__text">Questão #{question.id}</span>
              {getPermission('Excluir questões', 'Questões e apostilas') && (
                <Dropdown>
                  <Dropdown.Toggle>
                    <FiMoreHorizontal />
                  </Dropdown.Toggle>

                  <Dropdown.Content>
                    <Dropdown.Item onClick={() => destroyQuestionForm(selectedQuestion)}>{t('textsCommon.delete')}</Dropdown.Item>
                  </Dropdown.Content>
                </Dropdown>
              )}
            </div>
          ))}
        </>
      )}
    </div>
  );
};
