import React from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import userAvatar from 'app/images/user-avatar.svg';
import Progress from 'app/components/Progress';

export default function DataGridGenerated({ users, reorder, setNameOrder, nameOrder, setDateOrder, dateOrder }) {
  return (
    <div className="round-dg-wrapper">
      <table
        className="round-dg round-dg--light"
        style={{ minWidth: 800 }}
      >
        <thead className="round-dg__header">
          <tr className="round-dg__row">
            <th className="round-dg__cell-header round-dg__cell-header--sm">
              Nome e e-mail
              <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer">
                {nameOrder === 'descendent' ? (
                  <FiChevronDown
                    onClick={() => {
                      setNameOrder('ascendent');
                      reorder(users, 'name');
                    }}
                    className="card__icon"
                  />
                ) : (
                  <FiChevronUp
                    onClick={() => {
                      setNameOrder('descendent');
                      reorder(users, 'name');
                    }}
                    className="card__icon"
                  />
                )}
              </span>
            </th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: 200 }}
            >
              Insitutição
            </th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: 100 }}
            >
              Turma
            </th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: 126 }}
            >
              Último acesso
              <span className="card__icon-wrapper card__icon-wrapper--sm u-cursor-pointer">
                {dateOrder === 'descendent' ? (
                  <FiChevronDown
                    onClick={() => {
                      setDateOrder('ascendent');
                      reorder(users, 'last-access');
                    }}
                    className="card__icon"
                  />
                ) : (
                  <FiChevronUp
                    onClick={() => {
                      setDateOrder('descendent');
                      reorder(users, 'last-access');
                    }}
                    className="card__icon"
                  />
                )}
              </span>
            </th>
            <th
              className="round-dg__cell-header round-dg__cell-header--sm"
              style={{ width: 130 }}
            >
              % de conclusão
            </th>
          </tr>
        </thead>

        <tbody className="round-dg__body">
          {users.map((item, key) => (
            <tr
              className="round-dg__row"
              key={key}
            >
              <th className="round-dg__cell-header round-dg__cell-header--sm round-dg__cell-header--row">
                <div className="round-dg__user">
                  <img
                    className="avatar avatar--xs"
                    src={item['image-url'] || userAvatar}
                    alt="Imagem do usuário"
                  />

                  <div className="round-dg__user-inner">
                    <h3 className="round-dg__title">{item.name}</h3>
                    <p className="round-dg__subtitle">{item.email}</p>
                  </div>
                </div>
              </th>
              <td className="round-dg__cell round-dg__cell--sm">
                <span className="u-text-truncate">{item.institution}</span>
              </td>
              <td className="round-dg__cell round-dg__cell--sm">{item.classroom}</td>
              <td className="round-dg__cell round-dg__cell--sm">{new Date(item['last-access']).toLocaleDateString('pt-br')}</td>
              <td className="round-dg__cell round-dg__cell--sm">
                <Progress.Card percentage={item['course-progress']} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
