import { Link } from '@reach/router';
import { useTranslation } from 'react-i18next';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import getPermission from 'app/utils/getPermission';
import { useClient } from 'jsonapi-react';
import React, { useState } from 'react';
import ClassModuleItem from './ClassModuleItem';

function ClassroomSidebar(props) {
  const { learnigSystem, setActiveTabs, learnSystemContents, setSelectedItem, selectedItem, setSelectedContent, selectedContent, learnigSystemUser, getLearnSystemContents } = props;
  const { t } = useTranslation();
  const [active, setActive] = useState('');
  const [blocked, setBlocked] = useState(false);
  const basePath = window.env.REACT_APP_BASE_PATH;

  const changeTab = tab => {
    setActiveTabs(tab);
    window.scrollTo({
      top: 400,
      behavior: 'smooth'
    });
  };

  const authorityLevel = getAuthorityLevel();
  const client = useClient();

  const updateLearningSystemUserItems = async ls_id => {
    const requestParams = {
      has_new_message: false
    };
    const { error } = await client.mutate([`/learning_system_user_items/${ls_id}`], requestParams);

    if (error) {
      console.log(error);
    }
  };

  const updateLearningSystemItems = async ls_id => {
    const requestParams = {
      has_new_message: false
    };
    const { error } = await client.mutate([`/learning_system_items/${ls_id}`], requestParams);

    if (error) {
      console.log(error);
    }
  };

  return (
    <div className="classroom-video-content-sidebar">
      {getPermission('Gerenciar conteúdo', 'Cursos, módulos e aulas') && (
        <Link
          className="btn btn--primary btn--full"
          to={`${basePath}/curso/admin/gerenciar/${learnigSystem?.['course-id']}/${learnigSystem?.id}/disciplinas-e-aulas/d`}
        >
          Gerenciar módulo
        </Link>
      )}
      {authorityLevel === 'student' &&
        (learnigSystemUser?.percent === 100 ? (
          <div className="card__badges">
            <span className="badge badge--success">{t('textsCommon.tagConcluded')}</span>
          </div>
        ) : (
          <div className="card__progress">
            <div className="card__progress-container">
              <div
                className="card__progress-bar"
                style={{ width: `${learnigSystemUser?.percent || 0}%` }}
              ></div>
            </div>
            <div className="card__progress-text">{Math.round(learnigSystemUser?.percent || 0)}%</div>
          </div>
        ))}

      <div className="class-modules">
        {(learnSystemContents || []).map((learnSystemContent, index) => {
          const learningSystemItems = learnSystemContent['learning-system-items'] || [];
          if (learningSystemItems.some(learningSystemItem => learningSystemItem.unrequired)) {
            learnSystemContent['is-allowed'] = true;
          }

          return (
            <ClassModuleItem
              changeTab={changeTab}
              learnigSystem={learnigSystem}
              learningSystemItems={learningSystemItems}
              learnSystemContents={learnSystemContents}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              selectedContent={selectedContent}
              setSelectedContent={setSelectedContent}
              learnSystemContent={learnSystemContent}
              active={active}
              setActive={setActive}
              authorityLevel={authorityLevel}
              updateLearningSystemItems={updateLearningSystemItems}
              updateLearningSystemUserItems={updateLearningSystemUserItems}
              getLearnSystemContents={getLearnSystemContents}
              index={index}
              key={index}
            />
          );
        })}
      </div>
    </div>
  );
}
export default ClassroomSidebar;
