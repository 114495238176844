import React from 'react';
import { navigate, Link } from '@reach/router';
import userAvatar from 'app/images/user-avatar.svg';
import moment from 'moment';

export default function EssayStudentCard({ student, essayId }) {
  const basePath = window.env.REACT_APP_BASE_PATH;

  const status = {
    reviewed: { class: 'badge--success', text: 'Revisada' },
    unreviewed: { class: 'badge--warning', text: 'Em revisão' },
    not_sent: { class: 'badge--danger', text: 'Não enviada' },
    processing: { class: 'badge--warning', text: 'Em processamento' },
    annulled: { class: 'badge--danger', text: 'Anulada' },
    minimum_length_not_satisfied: { class: 'badge--danger', text: 'Arquivo inválido' },
    failed_to_process: { class: 'badge--danger', text: 'Falha ao processar' }
  };

  const statusClass = status?.[student.status]?.class;
  const badgeText = status?.[student.status]?.text;

  return (
    <tr className="round-dg__row">
      <th className="round-dg__cell-header round-dg__cell-header--row">
        <div
          className="round-dg__user u-cursor-pointer"
          onClick={() => navigate(`${basePath}/usuario/metricas/${student?.user?.id}`)}
        >
          <img
            className="avatar avatar--xs"
            src={student?.user?.image || userAvatar}
            alt="Imagem do usuário"
          />

          <div className="round-dg__user-inner">
            <h3
              className="round-dg__title"
              title={student?.user?.name}
            >
              {student?.user?.name}
            </h3>
            {/* <p className="round-dg__subtitle">email</p> */}
          </div>
        </div>
      </th>

      <td className="round-dg__cell">{student?.['delivery-date'] ? moment(student?.['delivery-date']).format('DD/MM/YYYY') : '-'}</td>

      <td className="round-dg__cell">{student?.grade}</td>

      <td className="round-dg__cell">
        <span className={`badge badge--tiny ${statusClass}`}>{badgeText}</span>
      </td>

      <td className="round-dg__cell">
        {(student?.id && student.status === 'reviewed') || student.status === 'unreviewed' || student.status === 'annulled' ? (
          <Link
            className="btn btn--tiny btn--full btn--outline"
            to={`${basePath}/redacao/correcao/${student?.id}/${essayId}`}
          >
            Acessar redação
          </Link>
        ) : null}
      </td>
    </tr>
  );
}
