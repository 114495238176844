import React from 'react';
import { FiChevronLeft, FiMoreHorizontal, FiPlus } from 'react-icons/fi';
import MainSidebar from 'app/components/Sidebar';
import { Link } from '@reach/router';
import Dropdown from 'app/components/Dropdown';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useEffect } from 'react';
import { navigate } from '@reach/router';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2';
import { useClient } from 'jsonapi-react';
import { useTranslation } from 'react-i18next';
import { i18n } from 'app/components/i18n/i18n';
import getPermission from 'app/utils/getPermission';
import { useSession } from 'app/hooks/useSession';

export default function Sidebar(props) {
  const { exams, courses, selectedExam, examId, setExamId, activeTab, path } = props;
  const [filteredExams, setFilteredExams] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const client = useClient();
  const [variable, setVariable] = useState();
  const currentUser = useSession().session.user;
  const basePath = window.env.REACT_APP_BASE_PATH;

  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(window.env.REACT_APP_LOCALE);
  }, []);

  useEffect(() => {
    if (path.includes('/apostilas')) {
      setVariable(t('sideBar.questionBooks'));
    } else if (path.includes('/provas')) {
      setVariable(t('exams.toggleExams'));
    } else {
      setVariable(t('textsCommon.simulations'));
    }
  }, [path]);

  const filters = useFormik({
    initialValues: {
      course: 'blank'
    }
  });

  useEffect(() => {
    if (courses && courses?.length > 0) {
      let filter = exams;
      if (filters.values.course !== 'blank') {
        filter = exams.filter(item => item?.['course-ids']?.[0] === parseInt(filters.values.course));
      }
      setFilteredExams(filter);
    }

    if (searchTerm !== '') {
      const searchExam = exams.filter(item => {
        if (searchTerm === '') {
          return item;
        }
        if (item.title != null && item.title.toLowerCase().includes(searchTerm.toLocaleLowerCase())) {
          return item;
        }
      });
      setFilteredExams(searchExam);
    }
  }, [filters.values.course, exams, courses, searchTerm]);

  const handleDeleteExam = examId => {
    Swal.fire({
      title: t('projects.warningDeleteQuestionBook'),
      text: t('projects.warningDeleteCannotBeUndoneText'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteExam(examId);
        setFilteredExams(exams.filter(item => item.id !== examId));
      }
    });
  };

  const deleteExam = async examId => {
    try {
      await client.delete(['question_books', examId]);
      toast.success(t('toast.successDeleteExam'));
    } catch {
      toast.error(t('toast.errorDeleteBook'));
    }
  };

  const shortByCreation = (a, b) => {
    const dateA = new Date(a?.['created-at']);
    const dateB = new Date(b?.['created-at']);
    return dateB - dateA;
  };

  filteredExams?.sort(shortByCreation);

  return (
    <MainSidebar hasBrand={false}>
      <nav className="notebook-sidebar__nav">
        <div className="filter-bar">
          <Link
            state={{ activeTab: activeTab }}
            to={
              path.includes('/simulados/gerenciar')
                ? `${basePath}/avaliacoes/simulados`
                : path.includes('/provas/gerenciar')
                  ? `${basePath}/avaliacoes/provas`
                  : `${basePath}/avaliacoes/apostilas`
            }
            className="filter-bar__back"
          >
            <div className="card__icon-wrapper">
              <FiChevronLeft />
            </div>
            <span className="filter-bar__back-container">
              <span className="filter-bar__title">{variable}</span>
            </span>
          </Link>
        </div>

        <div className="form__row">
          <label
            className="form__label"
            htmlFor="courseId"
          >
            {t('filter.labelSelectCourse')}
          </label>

          <select
            className="form__select"
            name="course"
            id="course"
            defaultValue="blank"
            value={filters.values.course}
            onChange={filters.handleChange}
          >
            <option value="blank">{t('filter.labelSelectCourse')}</option>
            {courses.map((item, index) => {
              return (
                <option
                  value={item.id}
                  key={index}
                >
                  {item.title}
                </option>
              );
            })}
          </select>
        </div>

        <div className="form__row">
          <input
            aria-label="Pesquisar módulos"
            className="form__control form__control--search-with-icon"
            placeholder={t('textsCommon.research')}
            type="search"
            name="moduleSearch"
            id="moduleSearch"
            onChange={e => setSearchTerm(e.target.value)}
          />
        </div>

        {variable === 'Apostilas' && (
          <Link
            className="btn btn--outline-dashed btn--small btn--full u-mb-3"
            to={`${basePath}/apostilas/criar/nome`}
          >
            <FiPlus className="btn__icon" /> {t('exams.newQuestionBook')}
          </Link>
        )}
        {variable === 'Provas' && (
          <Link
            className="btn btn--outline-dashed btn--small btn--full u-mb-3"
            to={`${basePath}/provas/criar/curso`}
          >
            <FiPlus className="btn__icon" /> {t('exams.newExam')}
          </Link>
        )}
        {variable === 'Simulados' && (
          <Link
            className="btn btn--outline-dashed btn--small btn--full u-mb-3"
            to={`${basePath}/simulados/criar/curso`}
          >
            <FiPlus className="btn__icon" /> {t('exams.newSimulation')}
          </Link>
        )}

        <ul className="module-list">
          {filteredExams.map(item => {
            return (
              <li
                key={item.id}
                className={selectedExam?.id == item.id ? 'module-list__item active' : 'module-list__item'}
                onClick={() => {
                  if (variable === 'Provas') {
                    window.history.pushState({ id: item.id }, '', `/provas/gerenciar/${item.id}`);
                    navigate(`${basePath}/provas/gerenciar/${item?.id}/${item?.['question-book-taxonomies']?.[0]?.id}`);
                  } else if (variable === 'Apostilas') {
                    window.history.pushState({ id: item.id }, '', `/apostilas/gerenciar/${item.id}`);
                    navigate(`${basePath}/apostilas/gerenciar/${item?.id}/${item?.['question-book-taxonomies']?.[0]?.id}`);
                  } else {
                    window.history.pushState({ id: item.id }, '', `/simulados/gerenciar/${item.id}`);
                    navigate(`${basePath}/simulados/gerenciar/${item?.id}/${item?.['question-book-taxonomies']?.[0]?.id}`);
                  }
                  setExamId(item.id);
                }}
              >
                <h3 className="module-list__title">{item.title}</h3>
                {currentUser.id === item.creator.id && (
                  <Dropdown>
                    <Dropdown.Toggle>
                      <FiMoreHorizontal />
                    </Dropdown.Toggle>

                    <Dropdown.Content>
                      {variable === 'Apostilas' && getPermission('Editar apostilas', 'Questões e apostilas') ? (
                        <Dropdown.Item
                          onClick={() => {
                            navigate(`${basePath}/apostilas/criar/nome/${item.id}`);
                          }}
                        >
                          {t('button.edit')}
                        </Dropdown.Item>
                      ) : null}
                      {variable === 'Provas' && getPermission('Editar provas', 'Avaliações') ? (
                        <Dropdown.Item
                          onClick={() => {
                            navigate(`${basePath}/provas/criar/curso/${item.id}`);
                          }}
                        >
                          {t('button.edit')}
                        </Dropdown.Item>
                      ) : null}
                      {variable === 'Simulados' && getPermission('Editar simulados', 'Avaliações') ? (
                        <Dropdown.Item
                          onClick={() => {
                            navigate(`${basePath}/simulados/criar/curso/${item.id}`);
                          }}
                        >
                          {t('button.edit')}
                        </Dropdown.Item>
                      ) : null}
                      {getPermission('Excluir apostilas', 'Questões e apostilas') && (
                        <Dropdown.Item
                          onClick={() => {
                            handleDeleteExam(item.id);
                          }}
                        >
                          {t('button.delete')}
                        </Dropdown.Item>
                      )}
                    </Dropdown.Content>
                  </Dropdown>
                )}
              </li>
            );
          })}
        </ul>
      </nav>
    </MainSidebar>
  );
}
