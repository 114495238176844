import { ReactFlowProvider } from 'reactflow';
import HierarchyFlow from './components/HierarchyFlow';
import Legend from './components/Legend';
import { useEffect, useState } from 'react';
import { useClient } from 'jsonapi-react';
import { useParams } from '@reach/router';
import { getInstitutionById, getOrganizationalChartByStructureId } from './utils/api';
import FlowModal from './modals/FlowModal';
import AddEditNodeForm from './modals/AddEditNodeForm';
import { dataToNodeAndEdge } from './utils/dataToNodeAndEdge';
import EmployeeList from './modals/EmployeeList';
import { FiChevronLeft } from 'react-icons/fi';
import { navigate } from '@reach/router';
import Loader from 'app/components/loader';

const InstitutionFlow = () => {
  const basePath = window.env.REACT_APP_BASE_PATH;
  const params = useParams();
  const id = params.id;

  const client = useClient();
  const [institution, setInstitution] = useState(null);
  const [flow, setFlow] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [organizationalStructure, setOrganizationalStructure] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalEmployeesVisible, setModalEmployeesVisible] = useState(false);

  const openModal = node => {
    setModalVisible(true);
    setSelectedNode(node);
  };

  const openModalEmployees = node => {
    setSelectedNode(node);
    setModalEmployeesVisible(true);
  };

  const closeModalEmployees = () => {
    setModalEmployeesVisible(false);
    setSelectedNode(null);
  };
  const closeModal = () => {
    setSelectedNode(null);
    setModalVisible(false);
  };
  const navigateToInstitutions = () => {
    navigate(`${basePath}/participantes/instituicoes`);
  };

  const fetchInstitutionData = async () => {
    try {
      const response = await getInstitutionById(client, id);
      setInstitution(response);
    } catch (error) {
      console.log(error);
    }
  };

  const afterSubmit = async node => {
    let newOrganizationalStructure = null;

    const response = await getOrganizationalChartByStructureId(client, institution?.['organization-structure']?.id);
    newOrganizationalStructure = response;

    setOrganizationalStructure(newOrganizationalStructure);
    const { nodes, edges } = dataToNodeAndEdge(newOrganizationalStructure, afterSubmit, openModal, setIsEdit, setSelectedNode, openModalEmployees);
    setNodes([]);
    setEdges([]);

    setTimeout(() => {
      setNodes(nodes);
      setEdges(edges);
    }, 1);

    closeModal();
  };

  const fetchOrganizationalStructureData = async () => {
    try {
      const response = await getOrganizationalChartByStructureId(client, institution?.['organization-structure']?.id);
      setOrganizationalStructure(response);
      const { nodes, edges } = dataToNodeAndEdge(response, afterSubmit, openModal, setIsEdit, setSelectedNode, openModalEmployees);
      setNodes(nodes);
      setEdges(edges);
    } catch (error) {
      console.log('error', error);
      setFlow(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInstitutionData();
  }, []);
  useEffect(() => {
    if (institution) {
      fetchOrganizationalStructureData();
    }
  }, [institution]);

  return (
    <>
      <div className="main-screen main-screen-admin main-screen--bg-white">
        <main className="flow-content">
          <div
            className="icon--back--container"
            onClick={navigateToInstitutions}
          >
            <div className="card__icon-wrapper icon--back ">
              <FiChevronLeft />
            </div>
            <h3>Voltar</h3>
          </div>
          <div className="flow-content__header-titles">
            <h1>{institution?.name}</h1>
            <span>{institution?.description}</span>
          </div>
        </main>

        {(!loading && nodes.length === 1) || (nodes.length > 0 && edges.length > 0) ? (
          <div style={{ width: '100%', height: '80vh' }}>
            <ReactFlowProvider>
              <HierarchyFlow
                nodes={nodes}
                edges={edges}
              />
            </ReactFlowProvider>
            <Legend nodes={nodes} />
          </div>
        ) : null}

        <div className="add-structure-button-container">
          {loading && <Loader />}
          {!loading && nodes.length === 0 && edges.length === 0 ? (
            <div
              className="btn btn--primary btn--wide add-structure-button"
              onClick={() => openModal(null)}
            >
              Montar Organograma
            </div>
          ) : null}
        </div>

        {modalVisible && (
          <FlowModal
            onClose={closeModal}
            isVisible={modalVisible}
          >
            {institution['organization-structure'].id && (
              <AddEditNodeForm
                node={
                  selectedNode
                    ? selectedNode
                    : {
                        'organization-structure-id': institution['organization-structure'].id,
                        'organizational-chart-parent-id': null,
                        afterSubmit: afterSubmit,
                        organizationalStructure: organizationalStructure
                      }
                }
                fetchOrganizationalStructureData={fetchOrganizationalStructureData}
                afterSubmit={afterSubmit}
              />
            )}
          </FlowModal>
        )}

        {modalEmployeesVisible && (
          <FlowModal
            onClose={closeModalEmployees}
            isVisible={modalEmployeesVisible}
          >
            <EmployeeList employees={selectedNode.user} />
          </FlowModal>
        )}
      </div>
    </>
  );
};
export default InstitutionFlow;
