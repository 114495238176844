import React, { useState, useEffect } from 'react';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { FiChevronLeft } from 'react-icons/fi';
import { navigate } from '@reach/router';
import ActivityDataPieChart from './ActivityData/ActivityDataPieChart';
import Loader from 'app/components/loader';
import ActivityCardHeader from 'app/components/ComplementaryActivity/activityCardHeader';
import ActivityStudentCard from 'app/components/ComplementaryActivity/activityStudentCard';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from 'app/components/BreadCrumbs';

export function ActivityEvaluationArchive(props) {
  const { uri } = props;
  const basePath = window.env.REACT_APP_BASE_PATH;

  const { t } = useTranslation();
  const client = useClient();
  const [isLoading, setIsLoading] = useState(true);
  const [activity, setActivity] = useState();
  const [activityStudents, setActivityStudents] = useState();
  const [activityReport, setActivityReport] = useState();

  useEffect(() => {
    getActivity();
    getActivityStudents();
  }, []);

  useEffect(() => {
    if (activity?.id) {
      getActivityReport();
    }
  }, [activity]);

  const getActivity = async () => {
    try {
      setIsLoading(true);
      const { data } = await client.fetch(['activities', props.id]);
      setActivity(data);
    } catch (e) {
      console.log(e);
      toast.error(t('toast.errorGetActivity'));
    }
  };

  const getActivityReport = async () => {
    setIsLoading(true);
    const { data } = await client.fetch(['activities', activity.id, 'activities_report?general=true']);
    setActivityReport(data);
    setIsLoading(false);
  };

  const getActivityStudents = async () => {
    try {
      const { data } = await client.fetch(['activities', props.id, 'activity_students']);
      setActivityStudents(data);
    } catch (e) {
      console.log(e);
      toast.error('Erro ao buscar atividades dos estudantes.');
    }
  };

  const breadCrumbs = {
    title: 'Atividades',
    nav: [
      {
        route: `${basePath}/atividade-complementar`,
        name: 'Atividade Complementar',
        isActive: false
      },
      {
        route: uri,
        name: activity?.title,
        isActive: true
      }
    ]
  };

  return (
    <>
      <main className="main-content main-content--block fadeIn">
        <BreadCrumbs data={breadCrumbs} />

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <ActivityCardHeader activity={activity} />

            <div className="page">
              <div className="page__col">
                {activityStudents && activityStudents.length > 0 ? (
                  <div className="round-dg-wrapper">
                    <table className="round-dg round-dg--light">
                      <tbody className="round-dg__body">
                        {activityStudents.map(activityStudent => (
                          <ActivityStudentCard
                            activity={activity}
                            activityStudent={activityStudent}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p className="new-questions__empty-state-text">
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    {t('activity.emptyInfo')}
                  </p>
                )}
              </div>

              <div className="page__aside">
                <div className="chart-widget">
                  <div className="chart-widget__header">
                    <h3 className="chart-widget__title">{t('activity.totalSubmissions')}</h3>
                  </div>
                  <div className="chart-widget__body">{isLoading ? <Loader /> : <ActivityDataPieChart activityReport={activityReport} />}</div>
                </div>
              </div>
            </div>
          </>
        )}
      </main>
    </>
  );
}
