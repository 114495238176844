import React, { useState, useEffect } from 'react';
import { Link, navigate } from '@reach/router';
import { FiLogOut, FiHome, FiFolder, FiCoffee, FiCodesandbox, FiCheckCircle, FiTarget, FiUsers, FiActivity, FiCalendar, FiClipboard, FiEdit3, FiStar, FiVideo, FiSettings, FiMap, FiEdit } from 'react-icons/fi';
import { FiMessageSquare } from 'react-icons/fi';
import { CiStreamOn } from 'react-icons/ci';
import { FaRegUserCircle } from 'react-icons/fa';
import { BiJoystickAlt, BiSearch } from 'react-icons/bi';
import _ from 'lodash';
import getPermission from 'app/utils/getPermission';
import { useSession } from '../hooks/useSession';
import MenuIcon from '../images/icons/menu-icon.svg';
import Simulated from './Simulated';
import getAuthorityLevel, { checkIncludeProfiles } from '../utils/getAuthorityLevel';
import { useTranslation } from 'react-i18next';
import { usePlatformAbilities } from 'app/hooks/usePlatformAbilities';
import Select from 'react-select';

const NavLink = props => {
  const { isActive, ...restProps } = props;

  return (
    <Link
      {...restProps}
      getProps={({ isCurrent }) => ({
        className: isCurrent || isActive ? 'main-sidebar__link active' : 'main-sidebar__link'
      })}
    />
  );
};

function MainSidebar(props) {
  const session = useSession();
  const { signOut, ssoToken, connections, sanitizeResponse, setSession, dbNumber } = useSession();

  const { abilities } = usePlatformAbilities();
  const authorityLevel = getAuthorityLevel();
  const { simulated, enabledClock, selectQuestionBookTaxonomy, questionBooksUser, questionBook, activeGroupedQuestions, lastAnswerSubmited, finishOpen } = props;
  const [active, setActive] = useState(false);
  const [openProgress, setOpenProgress] = useState(0);
  const { t } = useTranslation();
  const [avaliationsActiveTab, setAvaliationsActiveTab] = useState('banco-de-questoes');
  const sso = window.env.REACT_APP_SSO_API_URL;
  const basePath = window.env.REACT_APP_BASE_PATH;
  useEffect(() => {
    calculateOpenProgress();
  }, [lastAnswerSubmited]);

  const mobileNavToggle = () => {
    setActive(!active);
  };

  const filteredConnections = connections?.filter(item => item.db_number !== dbNumber);

  const currentConnection = connections?.find(item => item.db_number == dbNumber);

  const calculateOpenProgress = () => {
    if (questionBook) {
      const totalQuestions = _.reduce(_.map(questionBook.taxonomies, 'questionCount'), (a, b) => a + b);
      const totalAnswered = _.reduce(
        _.map(questionBook.taxonomies, t => t.answers?.length || 0),
        (a, b) => a + b
      );
      const percentage = (totalAnswered * 100) / totalQuestions;
      setOpenProgress(percentage.toFixed(1));
    }
  };

  const appName = window.env.REACT_APP_NAME;
  const Logo = require(`app/images/${appName}/horizontal-logo.svg`);
  const pathname = window.location.pathname;

  const viewTaxonomies = getPermission('Visualizar lista de taxonomias, tópicos e subtópicos', 'Taxonomias');

  const [participantsRoute, setParticipantsRoute] = useState('');

  const viewParticipantsList = getPermission('Visualizar lista de participantes', 'Participantes e perfis administrativos');

  const manageProfiles = getPermission('Visualizar lista e gerenciar perfis', 'Participantes e perfis administrativos');

  const sendNotifications = getPermission('Enviar notificações de engajamento', 'Participantes e perfis administrativos');

  useEffect(() => {
    if (viewParticipantsList) {
      setParticipantsRoute(`${basePath}/participantes`);
    } else if (manageProfiles) {
      setParticipantsRoute(`${basePath}/participantes/perfis-administrativos`);
    } else if (sendNotifications) {
      setParticipantsRoute(`${basePath}/participantes/notificacoes`);
    }
  }, []);

  const viewCourse = getPermission('Visualizar lista de cursos', 'Cursos, módulos e aulas');
  const viewClassroom = getPermission('Visualizar lista de turmas', 'Turmas');

  const viewMetrics =
    getPermission('Visualizar dados e métricas', 'Questionário de Percepção') ||
    getPermission('Visualizar dados e métricas', 'Projetos') ||
    getPermission('Visualizar dados e métricas', 'Participantes e perfis administrativos') ||
    getPermission('Visualizar dados e métricas', 'Materiais e atividades complementares') ||
    getPermission('Visualizar dados e métricas', 'Questões e apostilas') ||
    getPermission('Visualizar dados e métricas', 'Avaliações') ||
    getPermission('Visualizar dados e métricas', 'Taxonomias') ||
    getPermission('Visualizar dados e métricas', 'Feed') ||
    getPermission('Visualizar dados e métricas', 'Cursos, módulos e aulas') ||
    getPermission('Visualizar dados e métricas', 'Turmas') ||
    getPermission('Visualizar dados e métricas', 'Tarefas');

  const viewQuestionBank = getPermission('Visualizar banco de questões', 'Questões e apostilas');
  const viewQuestionbook = getPermission('Visualizar apostilas', 'Questões e apostilas');
  const viewExams = getPermission('Visualizar lista de provas', 'Avaliações');
  const viewLessonPlan = getPermission('Visualizar Plano de Aula', 'Plano de Aula');

  useEffect(() => {
    if (viewQuestionBank && viewQuestionbook && viewExams) {
      setAvaliationsActiveTab('banco-de-questoes');
    }
    if (!viewQuestionBank && viewQuestionbook && viewExams) {
      setAvaliationsActiveTab('apostilas');
    }
    if (!viewQuestionBank && !viewQuestionbook && viewExams) {
      setAvaliationsActiveTab('provas');
    }
    if (!viewQuestionBank && !viewQuestionbook && !viewExams) {
      setAvaliationsActiveTab('resultado-das-provas');
    }
    if (!viewQuestionBank && viewQuestionbook && !viewExams) {
      setAvaliationsActiveTab('apostilas');
    }
    if (viewQuestionbook && viewQuestionBank && !viewExams) {
      setAvaliationsActiveTab('banco-de-questoes');
    }
  }, [viewQuestionBank, viewExams, viewQuestionbook]);

  const metricsRoute = () => {
    if (getPermission('Mantenedor', 'Relatórios')) {
      return 'metricas/mantenedor';
    }
    if (getPermission('Regional', 'Relatórios') && !getPermission('Mantenedor', 'Relatórios')) {
      return '/metricas/regional';
    }
    if (getPermission('Institucional', 'Relatórios') && !getPermission('Mantenedor', 'Relatórios') && !getPermission('Regional', 'Relatórios')) {
      return '/metricas/institucional';
    }
    return '';
  };

  const handleSelectConnection = async select => {
    if (select && select.value && select.label) {
      const { value: dbNumber, host_url: host_url } = select;

      const redirectURL = `${host_url}/login-callback?token=${ssoToken}&db_number=${dbNumber}`;
      navigate(redirectURL);
    }
  };

  return (
    <div className="main-sidebar">
      <div className="main-sidebar__mobile-hero">
        <div
          className="main-sidebar__mobile-hero-brand u-cursor-pointer"
          onClick={() => {
            navigate(`${basePath}`);
            setActive(false);
          }}
        >
          <img src={Logo} />
        </div>
        <div
          className="main-sidebar__mobile-hero-menu"
          onClick={mobileNavToggle}
        >
          <img
            alt=""
            src={MenuIcon}
          />
        </div>
      </div>

      <div
        className={`main-sidebar__container ${active ? 'active' : ''}`}
        onClick={() => window.innerWidth < 784 && setActive(false)}
      >
        <div
          className="main-sidebar__brand u-cursor-pointer"
          onClick={() => navigate(`${basePath}`)}
        >
          <img src={Logo} />
        </div>
        {simulated ? (
          <Simulated
            enabledClock={enabledClock}
            selectQuestionBookTaxonomy={selectQuestionBookTaxonomy}
            questionBooksUser={questionBooksUser}
            questionBook={questionBook}
            activeGroupedQuestions={activeGroupedQuestions}
            lastAnswerSubmited={lastAnswerSubmited}
            finishOpen={finishOpen}
          />
        ) : (
          <nav className="main-sidebar__nav">
            {sso && connections?.length > 1 && (
              <Select
                value={[]}
                options={[
                  ...(filteredConnections?.map(connection => ({
                    value: connection?.db_number,
                    label: `Ir para o ${connection?.name}`,
                    host_url: connection?.host_url
                  })) || [])
                ]}
                className="react-multi-select filter-bar__multi-select u-w-100 u-mb-3"
                classNamePrefix="react-multi-select"
                placeholder={connections?.length === 0 ? t('loader.loading2') : `Você está no ${currentConnection?.name}`}
                noOptionsMessage={() => 'Sem opções'}
                onChange={handleSelectConnection}
              />
            )}

            {abilities?.dashboard && (
              <NavLink to={basePath}>
                <div className="main-sidebar__link-icon">
                  <FiHome />
                </div>
                <div className="main-sidebar__link-name">{t('sideBar.dashboard')}</div>
              </NavLink>
            )}

            {/* {viewCourse || viewClassroom ? (
              <NavLink to={viewCourse ? '/turma' : '/classrooms'}>
                <div className="main-sidebar__link-icon">
                  <FiUsers />
                </div>
                <div className="main-sidebar__link-name">Turmas</div>
              </NavLink>
            ) : null} */}

            {getPermission('Visualizar postagens e comentários', 'Feed') && abilities?.feed ? (
              <NavLink
                to={`${basePath}/feed`}
                isActive={pathname.includes('/feed')}
              >
                <div className="main-sidebar__link-icon">
                  <FiCoffee />
                </div>
                <div className="main-sidebar__link-name">{t('sideBar.feed')}</div>
              </NavLink>
            ) : null}

            {checkIncludeProfiles({ only: ['student'] }) && getPermission('Visualizar lista de módulos e seus conteúdos', 'Cursos, módulos e aulas') && abilities?.cursos ? (
              <NavLink
                to={`${basePath}/curso`}
                isActive={pathname.includes('/curso') && !pathname.includes('/gestao-de-tarefa')}
              >
                <div className="main-sidebar__link-icon">
                  <FiVideo />
                </div>
                <div className="main-sidebar__link-name">{t('sideBar.courses')}</div>
              </NavLink>
            ) : null}

            {!checkIncludeProfiles({ only: ['student'] }) && abilities?.cursos ? (
              <NavLink
                to={`${basePath}/curso/admin`}
                isActive={pathname.includes('/curso') && !pathname.includes('/gestao-de-tarefa')}
              >
                <div className="main-sidebar__link-icon">
                  <FiVideo />
                </div>
                <div className="main-sidebar__link-name">{t('sideBar.courses')}</div>
              </NavLink>
            ) : null}

            {getPermission('Visualizar lista de provas', 'Avaliações') ||
            getPermission('Visualizar banco de questões', 'Questões e apostilas') ||
            getPermission('Visualizar apostilas', 'Questões e apostilas') ||
            getPermission('Visualizar resultados', 'Avaliações')
              ? abilities?.avaliacoes &&
                authorityLevel !== 'student' && (
                  <NavLink
                    to={`${basePath}/avaliacoes/${avaliationsActiveTab}`}
                    isActive={pathname.includes('/avaliacoes') || pathname.includes('/lista-resultado-das-provas')}
                  >
                    <div className="main-sidebar__link-icon">
                      <FiTarget />
                    </div>
                    <div className="main-sidebar__link-name">{t('exams.thExams')}</div>
                  </NavLink>
                )
              : null}
            {getPermission('Visualizar Tarefas', 'Tarefas') && abilities?.tarefas ? (
              <NavLink
                to={authorityLevel === 'student' ? `${basePath}/minhas-tarefas` : `${basePath}/gestao-de-tarefa/curso`}
                isActive={pathname.includes('/gestao-de-tarefa')}
              >
                <div className="main-sidebar__link-icon">
                  <FiCalendar />
                </div>
                <div className="main-sidebar__link-name">{t('sideBar.tasks')}</div>
              </NavLink>
            ) : null}

            {checkIncludeProfiles({ only: ['student'] }) && (abilities?.['avaliacoes-simulados'] || abilities?.['avaliacoes-provas']) && (
              <NavLink
                to={`${basePath}]/simulados`}
                isActive={pathname === '/simulados' || pathname === '/avaliacoes' || pathname === '/resultado-das-avaliacoes'}
              >
                <div className="main-sidebar__link-icon">
                  <FiTarget />
                </div>
                <div className="main-sidebar__link-name">{t('sideBar.exams')}</div>
              </NavLink>
            )}

            {/* {checkIncludeProfiles({ only: ['student'] }) ? (
              <NavLink to={"${basePath}/materiais"}>
                <div className="main-sidebar__link-icon">
                  <FiFolder />
                </div>
                <div className="main-sidebar__link-name">{t('sideBar.materials')}</div>
              </NavLink>
            ) : null} */}

            {checkIncludeProfiles({ only: ['student'] }) && abilities?.['avaliacoes-apostilas'] ? (
              <NavLink
                to={`${basePath}/questions/apostilas`}
                isActive={pathname.includes('/questions')}
              >
                <div className="main-sidebar__link-icon">
                  <FiCheckCircle />
                </div>
                <div className="main-sidebar__link-name">{t('sideBar.questionBooks')}</div>
              </NavLink>
            ) : null}

            {!checkIncludeProfiles({ only: ['student'] }) && getPermission('Visualizar lista de atividades', 'Materiais e atividades complementares') && abilities?.['cursos-ativ-complementar'] ? (
              <NavLink
                to={`${basePath}/atividade-complementar`}
                isActive={pathname.includes('/atividade-complementar')}
              >
                <div className="main-sidebar__link-icon">
                  <FiEdit3 />
                </div>
                <div className="main-sidebar__link-name">{t('sideBar.activities')}</div>
              </NavLink>
            ) : null}

            {getPermission('Visualizar lista de projetos', 'Projetos') && abilities?.projetos ? (
              <NavLink
                to={checkIncludeProfiles({ only: ['student'] }) ? `${basePath}/projetos` : `${basePath}/projetos/grupos`}
                isActive={pathname.includes('/projetos')}
              >
                <div className="main-sidebar__link-icon">
                  <FiClipboard />
                </div>
                <div className="main-sidebar__link-name">{t('sideBar.projects')}</div>
              </NavLink>
            ) : null}

            {abilities?.redacao && (
              <NavLink
                to={`${basePath}/redacao`}
                isActive={pathname.includes('/redacao')}
              >
                <div className="main-sidebar__link-icon">
                  <FiEdit />
                </div>
                <div className="main-sidebar__link-name">{'Redação'}</div>
              </NavLink>
            )}

            {!checkIncludeProfiles({ only: ['student'] }) && viewTaxonomies ? (
              <NavLink
                to={`${basePath}/ementa/materias`}
                isActive={pathname.includes('/ementa')}
              >
                <div className="main-sidebar__link-icon">
                  <FiCheckCircle />
                </div>
                <div className="main-sidebar__link-name">{t('sideBar.taxonomyAndMaterials')}</div>
              </NavLink>
            ) : null}

            {(viewCourse || viewClassroom) && abilities?.cursos ? (
              <NavLink
                to={`${basePath}/turmas`}
                isActive={pathname.includes('/turmas')}
              >
                <div className="main-sidebar__link-icon">
                  <FiUsers />
                </div>
                <div className="main-sidebar__link-name">{t('sideBar.coursesAndClass')}</div>
              </NavLink>
            ) : null}

            {getPermission('Visualizar questionário de percepção', 'Questionário de Percepção') && abilities?.nps && (
              <NavLink
                to={`${basePath}/questionario`}
                isActive={pathname.includes('/questionario')}
              >
                <div className="main-sidebar__link-icon">
                  <FiStar />
                </div>
                <div className="main-sidebar__link-name">NPS</div>
              </NavLink>
            )}

            {viewMetrics && abilities?.metricas ? (
              <NavLink
                to={`${basePath}/metricas/institucional`}
                isActive={pathname.includes('/metricas') || pathname.includes('/relatorio')}
              >
                <div className="main-sidebar__link-icon">
                  <FiActivity />
                </div>
                <div className="main-sidebar__link-name">{t('sideBar.metrics')}</div>
              </NavLink>
            ) : null}

            {checkIncludeProfiles({ only: ['student'] }) && abilities?.jornada ? (
              <NavLink
                to={`${basePath}/usuario/metricas/${session?.session?.user?.id}`}
                isActive={pathname.includes('/usuario')}
              >
                <div className="main-sidebar__link-icon">
                  <FiActivity />
                </div>
                <div className="main-sidebar__link-name">{t('sideBar.journey')}</div>
              </NavLink>
            ) : null}

            {abilities?.auditorio && getPermission('Visualizar auditórios', 'Auditórios') ? (
              <NavLink
                to={`${basePath}/auditorio`}
                isActive={pathname.includes('/auditorio')}
              >
                <div className="main-sidebar__link-icon">
                  <FiMessageSquare />
                </div>
                <div className="main-sidebar__link-name">{t('auditorium.auditorium')}</div>
              </NavLink>
            ) : null}

            {abilities?.['aulas-ao-vivo'] && getPermission('Visualizar aulas ao vivo', 'Aulas ao vivo') ? (
              <NavLink
                to={`${basePath}/aulas-ao-vivo`}
                isActive={pathname.includes('/aulas-ao-vivo')}
              >
                <div className="main-sidebar__link-icon">
                  <CiStreamOn style={{ strokeWidth: 1 }} />
                </div>
                <div className="main-sidebar__link-name">{t('sideBar.liveClass')}</div>
              </NavLink>
            ) : null}

            {checkIncludeProfiles({ only: ['student'] }) && abilities?.['desafio-da-semana'] ? (
              <NavLink
                to={`${basePath}/desafio-da-semana/desafios`}
                isActive={pathname.includes('/desafio-da-semana')}
              >
                <div className="main-sidebar__link-icon">
                  <BiJoystickAlt />
                </div>
                <div className="main-sidebar__link-name">Desafio da semana</div>
              </NavLink>
            ) : null}

            {checkIncludeProfiles({ only: ['student'] }) && abilities?.['plano-de-estudo'] && (
              <NavLink
                to={`${basePath}/plano-de-estudos`}
                isActive={pathname.includes('/plano-de-estudos')}
              >
                <div className="main-sidebar__link-icon">
                  <FiMap />
                </div>
                <div className="main-sidebar__link-name">{t('sideBar.study')}</div>
              </NavLink>
            )}

            {!checkIncludeProfiles({ only: ['student'] }) && viewLessonPlan && abilities?.['plano-de-aula'] && (
              <NavLink
                to={`${basePath}/plano-de-aulas/inicio`}
                isActive={pathname.includes('/plano-de-aulas')}
              >
                <div className="main-sidebar__link-icon">
                  <FiMap />
                </div>
                <div className="main-sidebar__link-name">Plano de aulas</div>
              </NavLink>
            )}

            <div className="main-sidebar__bottom-links">
              <NavLink
                to={`${basePath}/profile`}
                isActive={pathname.includes('/profile')}
              >
                <div className="main-sidebar__link-icon">
                  <FaRegUserCircle />
                </div>
                <div className="main-sidebar__link-name">{t('sideBar.profile')}</div>
              </NavLink>

              {getPermission('Visualizar lista de participantes', 'Participantes e perfis administrativos') ||
              getPermission('Visualizar lista e gerenciar perfis', 'Participantes e perfis administrativos') ||
              getPermission('Enviar notificações de engajamento', 'Participantes e perfis administrativos') ? (
                <NavLink
                  to={participantsRoute}
                  isActive={pathname.includes('/participantes')}
                >
                  <div className="main-sidebar__link-icon">
                    <FiSettings />
                  </div>
                  <div className="main-sidebar__link-name">{t('sideBar.participants')}</div>
                </NavLink>
              ) : null}

              <a
                className="main-sidebar__link"
                onClick={() => navigate(`${basePath}/logout`)}
              >
                <div className="main-sidebar__link-icon">
                  <FiLogOut />
                </div>
                <div className="main-sidebar__link-name">{t('sideBar.leave')}</div>
              </a>
            </div>
          </nav>
        )}
      </div>
    </div>
  );
}

export default MainSidebar;
