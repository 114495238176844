import CustomNode from '../objects/CustomNode';
import Edge from '../objects/Edge';
import {COLORS} from '../utils/colors'

export const dataToNodeAndEdge = (data, afterSubmit, openModal, setIsEdit, setSelectedNode, openModalEmployees) => {
  const nodes = [];
  const edges = [];

  data.forEach((item, index) => {
    const node = CustomNode({
      id: item.id.toString(),
      role: item.title,
      user: item['user-ids'],
      organizationalChartParentId: item['organizational-chart-parent-id'],
      organizationStructureId: item['organization-structure-id'],
      afterSubmit: afterSubmit,
      organizationalStructure: data,
      openModal: openModal,
      openModalEmployees: openModalEmployees,
      isEdit: setIsEdit,
      borderColor: COLORS[index % COLORS.length],
      setSelectedNode
    });
    nodes.push(node);
  });

  data.forEach(item => {
    const parentId = item['organizational-chart-parent-id'];
    if (parentId !== null) {
      const childId = item.id;
      const edge = Edge({
        id: `e${parentId}-${childId}`,
        source: parentId.toString(),
        target: childId.toString()
      });
      edges.push(edge);
    }
  });

  return { nodes, edges };
};
