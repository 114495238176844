import React from 'react';
import QuestionCard from './questionCard';
import IconVerified from './Icons/IconVerified';

function GroupedQuestion(props) {
  const { groupedQuestion, taxonomyIds, setTaxonomyIds, updateLastAnswerSubmited } = props;

  const introduction = groupedQuestion[0]?.modality === 'right_wrong' ? groupedQuestion[0]?.introduction?.body : groupedQuestion[0]?.['motivator-texts']?.[0]?.body;

  const setFilterTaxonomy = taxonomyId => {
    if (props.taxonomyIds.length === 1 && props.taxonomyIds.includes(taxonomyId)) {
      props.setTaxonomyIds([]);
    } else {
      props.setTaxonomyIds([taxonomyId]);
    }
  };

  return (
    <div className="question-card">
      <div
        className="u-mb-3"
        style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
      >
        <span className="expanded-calendar__tag expanded-calendar__tag--bright">Q{groupedQuestion[0]?.id}</span> <IconVerified />
      </div>

      <div className="tag-list">
        {groupedQuestion &&
          groupedQuestion.length > 0 &&
          groupedQuestion[0]?.taxonomies &&
          groupedQuestion[0]?.taxonomies.map(
            (taxonomy, index) =>
              index < 6 && (
                <div
                  className="tag"
                  key={`${index}-${taxonomy.id}`}
                  onClick={() => setFilterTaxonomy(taxonomy.id)}
                >
                  {taxonomy.name}
                </div>
              )
          )}
      </div>
      <div
        className="question-card__introduction"
        dangerouslySetInnerHTML={{ __html: introduction }}
      />
      <div
        className="question-card__print"
        dangerouslySetInnerHTML={{ __html: groupedQuestion[0]?.['motivator-texts']?.[0]?.print }}
      />
      {groupedQuestion &&
        groupedQuestion.map(question => (
          <QuestionCard
            key={question?.id}
            question={question}
            taxonomyIds={taxonomyIds}
            setTaxonomyIds={setTaxonomyIds}
            updateLastAnswerSubmited={updateLastAnswerSubmited}
          />
        ))}
    </div>
  );
}

export default GroupedQuestion;
