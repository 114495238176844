import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import metricsBanner from 'app/images/placeholders/metrics-banner.jpg';
import { FiCheckCircle, FiGlobe, FiMonitor, FiTrendingUp, FiUsers } from 'react-icons/fi';
import { CiStreamOn } from 'react-icons/ci';
import { navigate } from '@reach/router';
import { useClient } from 'jsonapi-react';
import { toast } from 'react-hot-toast';
import Loader from 'app/components/loader';
import EmptyState from 'app/components/EmptyState';
import useReportAbilities from 'app/hooks/useReportAbilities';
import BreadCrumbs from 'app/components/BreadCrumbs';

export default function MetricsEmbed(props) {
  const { selectedTab, uri } = props;
  const client = useClient();
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const { reportAbilities, getReportAbilities } = useReportAbilities();
  const basePath = window.env.REACT_APP_BASE_PATH;

  const getReports = async () => {
    setLoading(true);
    const { data, error } = await client.fetch('/embed_reports');
    if (error) {
      toast.error('Erro ao buscar relatórios');
    } else {
      setReports(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getReports();
  }, []);

  const getIcon = icon => {
    switch (icon) {
      case 'FiCheckCircle':
        return <FiCheckCircle />;

      default:
        return <FiTrendingUp />;
    }
  };

  const getInfo = async () => {
    const { data, error } = await client.fetch('/reports/learning_systems/general');
    if (error) {
      toast.error('Erro ao obter dados');
    } else {
      setInfo(data);
    }
  };

  useEffect(() => {
    getInfo();
    getReportAbilities();
  }, []);

  const ReportCard = ({ route, icon, title, description }) => {
    return (
      <div
        className="card card--clickable"
        onClick={() => navigate(route)}
      >
        <div className="card__inner-header">
          <div className="check-card__icon">{icon}</div>
          <div style={{ width: 0, flexGrow: 1 }}>
            <h2
              className="card__title"
              title={title}
            >
              {title}
            </h2>
            <p className="card__subtitle u-mb-0">{description}</p>
          </div>
        </div>
      </div>
    );
  };

  const frontReports = [];

  if (reportAbilities?.['cadastro-e-notas']) {
    frontReports.push({ route: `${basePath}/relatorio/seduc-pi/geral`, title: 'Simulados e notas', description: 'Acompanhamento de simulados e notas no último simulado', icon: <FiUsers /> });
  }

  if (reportAbilities?.['aulas-ao-vivo']?.['media-de-satisfacao'] || reportAbilities?.['aulas-ao-vivo']?.['ranking']) {
    frontReports.push({ route: `${basePath}/relatorio/aula-ao-vivo`, title: 'Aulas ao vivo', description: 'Relatórios de satisfação do conteúdo das aulas', icon: <CiStreamOn /> });
  }

  if (reportAbilities?.['visao-global-dos-cadastros-de-usuarios']?.['mapa-de-usuarios-cadastrados'] || reportAbilities?.['visao-global-dos-cadastros-de-usuarios']?.['ranking']) {
    frontReports.push({
      route: `${basePath}/relatorio/cadastro`,
      title: 'Visão global dos cadastros de usuários',
      description: 'Este relatório oferece uma análise aprofundada e abrangente sobre os cadastros de usuários em nossa plataforma.',
      icon: <FiTrendingUp />
    });
  }
  if (reportAbilities?.['mapeamento-de-acesso-do-usuario']?.['horarios-mais-acessados'] || reportAbilities?.['mapeamento-de-acesso-do-usuario']?.['mapa-de-calor']) {
    frontReports.push({
      route: `${basePath}/relatorio/acesso`,
      title: 'Mapeamento de acesso do usuário',
      description: 'Padrões de acesso dos usuários, enfatizando: região geográfica, horários de maior atividade e dispositivos utilizados.',
      icon: <FiGlobe />
    });
  }

  if (reportAbilities?.['progresso-de-curso']) {
    frontReports.push({
      route: `${basePath}/relatorio/progresso-de-curso`,
      title: 'Progresso  dos Cursos',
      description: 'Acompanhe o andamento dos estudantes nos cursos.',
      icon: <FiMonitor />
    });
  }

  const allReports = frontReports.concat(reports);

  const breadCrumbs = {
    title: 'Análise',
    nav: [
      {
        route: uri,
        name: 'Relatórios',
        isActive: true
      }
    ]
  };

  return (
    <main
      className="main-content"
      style={{ flexDirection: 'column' }}
    >
      <BreadCrumbs data={breadCrumbs} />

      <div
        className="page__content"
        style={{ padding: '20px', width: '100%' }}
      >
        <div
          className="show-course-banner u-mb-4"
          style={{
            backgroundImage: `url(${metricsBanner})`
          }}
        >
          <div className="show-course-banner__title">{t('metrics.metricsTitle')}</div>
          <p className="show-course-banner__description">{t('metrics.metricsDescription')}</p>
        </div>

        <div className="kpi-widget-wrapper">
          {info?.items?.map(item => (
            <div
              className="kpi-widget__body"
              key={item?.label}
            >
              <h4 className="kpi-widget__count">{item?.value ?? ''}</h4>
              <p className="kpi-widget__action-title">{item?.label ?? ''}</p>
            </div>
          ))}
        </div>

        {reports?.length === 0 && loading && <Loader />}
        {allReports.length === 0 && !loading && (
          <EmptyState
            type="data"
            text="Nenhum relatório disponível"
          />
        )}

        <div className="tab__cards">
          {reports?.map(item => {
            return (
              <ReportCard
                route={`${basePath}/metricas/${selectedTab}/${item.id}`}
                title={item.label}
                description={item.description}
                icon={getIcon(item.icon)}
                key={item.id}
              />
            );
          })}

          {frontReports?.map(item => (
            <ReportCard
              route={item.route}
              title={item.title}
              description={item.description}
              icon={item.icon}
              key={item.route}
            />
          ))}
        </div>
      </div>
    </main>
  );
}
