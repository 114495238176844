const Edge = ({ id, source, target }) => {

    return {
        id: id,
        source: source,
        target: target,
        type: 'step',
        style:{strokeWidth:3, stroke: '#ECE9F8'}
    }
}

export default Edge