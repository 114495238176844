import React from 'react';
import { FiChevronDown, FiClock, FiMoreHorizontal } from 'react-icons/fi';
import moment from 'moment';
import { navigate } from '@reach/router';
import Dropdown from 'app/components/Dropdown';
import Swal from 'sweetalert2';
import getPermission from 'app/utils/getPermission';
import { useTranslation } from 'react-i18next';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';

function ActivityCard(props) {
  const { t } = useTranslation();
  const { activity, editActivity, onDelete } = props;
  const authorityLevel = getAuthorityLevel();
  const basePath = window.env.REACT_APP_BASE_PATH;

  const activityStatus = activity['activity-status-or-submitted-count'];
  const activeAction = authorityLevel === 'student' && activityStatus !== t('activity.activity_not_submitted');

  const activityStatusClass = () => {
    switch (activity['activity-status-or-submitted-count']) {
      case t('activity.activity_not_submitted'):
        return 'badge--danger';
      case t('activity.activity_submitted'):
        return 'badge--success';
      case t('activity.activity_evaluated'):
        return 'badge--success';
      case t('activity.awaiting_submission'):
        return 'badge--warning';
      default:
        return 'badge--warning';
    }
  };

  let className = 'card';
  if (activity['learning-system-activities'][0]?.is_required && authorityLevel === 'student') {
    className += ' card--danger';
  }

  const getClocks = () => {
    switch (activity['class-hours']) {
      case 0.5:
        return (
          <span className="card__icon card__icon--semicircle">
            <FiClock />
          </span>
        );
      case 1:
        return <FiClock className="card__icon" />;
      case 1.5:
        return (
          <>
            <FiClock className="card__icon" />
            <span className="card__icon card__icon--semicircle">
              <FiClock />
            </span>
          </>
        );
      case 2:
        return (
          <>
            <FiClock className="card__icon" />
            <FiClock className="card__icon" />
          </>
        );
      case 2.5:
        return (
          <>
            <FiClock className="card__icon" />
            <FiClock className="card__icon" />
            <span className="card__icon card__icon--semicircle">
              <FiClock />
            </span>
          </>
        );
      default:
        return (
          <>
            <FiClock className="card__icon" />
            <FiClock className="card__icon" />
            <FiClock className="card__icon" />
          </>
        );
    }
  };

  const openCard = () => {
    if (authorityLevel === 'student') {
      if (activityStatus === t('activity.awaiting_submission')) {
        navigate(`${basePath}/atividade-complementar/atividade/${activity.id}`);
      }
      if ([t('activity.activity_submitted'), t('activity.activity_evaluated')].includes(activityStatus)) {
        navigate(`${basePath}/atividade-complementar/avaliacao/${activity?.id}/${activity['activity-student-id']}`);
      }
    }
  };

  return (
    <div className={className}>
      <header className="card__header">
        <div className="card__inner-header">
          <h3
            className="card__title"
            title={activity.title}
          >
            {activity.title}
          </h3>
        </div>
        <div className="card__badges">
          {activity['activity-status-or-submitted-count'] ? <span className={`badge badge--tiny ${activityStatusClass()}`}>{activity['activity-status-or-submitted-count']}</span> : null}
          {activity['learning-system-activities'][0]?.is_required && authorityLevel === 'student' ? (
            <span className={activity['learning-system-activities'][0]?.is_required ? 'badge badge--danger badge--tiny' : ''}>{t('warning.required')}</span>
          ) : null}
        </div>
      </header>

      <div className="card__body">
        <div className="card__columns">
          <p
            className="card__info"
            title={activity['class-hours'] < 2 ? `${activity['class-hours']} ${t('tasks.hour')}` : `${activity['class-hours']} ${t('tasks.hours')}`}
            aria-label={activity['class-hours'] < 2 ? `${activity['class-hours']} ${t('tasks.hour')}` : `${activity['class-hours']} ${t('tasks.hours')}`}
          >
            {getClocks()}
          </p>
        </div>
      </div>

      {activeAction && authorityLevel === 'student' && (
        <footer className="card__footer">
          <button
            onClick={openCard}
            className="btn btn--outline btn--small btn--full"
          >
            {t('button.access')}
          </button>
        </footer>
      )}

      {!activeAction && authorityLevel === 'student' && (
        <footer
          className="card__footer"
          style={{ cursor: 'not-allowed' }}
        >
          <button
            className="btn btn--outline btn--small btn--full"
            disabled
          >
            {t('button.access')}
          </button>
        </footer>
      )}

      <footer className="card__footer">
        <div
          className="btn-group btn-group--with-dropdown"
          role="group"
          aria-label="Ações"
        >
          {getPermission('Visualizar e avaliar respostas', 'Materiais e atividades complementares') && (
            <div
              onClick={() => navigate(`${basePath}/atividade-complementar/avaliar-atividades/${activity.id}`)}
              className={`btn btn--outline btn--small`}
            >
              {t('activity.evaluate')}
            </div>
          )}
          {getPermission('Excluir atividades', 'Materiais e atividades complementares') ||
          getPermission('Editar atividades', 'Materiais e atividades complementares') ||
          getPermission('Visualizar e avaliar respostas', 'Materiais e atividades complementares') ? (
            <Dropdown className="btn--small btn btn--outline">
              <Dropdown.Toggle>
                <FiChevronDown />
              </Dropdown.Toggle>

              <Dropdown.Content>
                {getPermission('Editar atividades', 'Materiais e atividades complementares') && (
                  <Dropdown.Item
                    onClick={() => {
                      navigate(`${basePath}/atividades/criar/nome/${activity?.['learning-system-id']}/${activity?.['course-id']}/${activity?.id}`);
                    }}
                  >
                    {t('button.edit')}
                  </Dropdown.Item>
                )}

                {getPermission('Excluir atividades', 'Materiais e atividades complementares') && (
                  <Dropdown.Item
                    onClick={() => {
                      Swal.fire({
                        title: t('projects.warningDeleteActivity'),
                        text: t('projects.warningDeleteCannotBeUndoneText'),
                        confirmButtonText: t('button.yes'),
                        showDenyButton: true,
                        denyButtonText: t('button.no'),
                        showCloseButton: true
                      }).then(res => {
                        if (res.isConfirmed) {
                          onDelete();
                        }
                      });
                    }}
                  >
                    {t('button.delete')}
                  </Dropdown.Item>
                )}

                <Dropdown.Item to={`${basePath}/atividade-complementar/dados/${activity.id}`}>{t('button.viewData')}</Dropdown.Item>

                <Dropdown.Item to={activity['activity-file']?.url || activity['activity-file']?.file}>Acessar arquivo</Dropdown.Item>
              </Dropdown.Content>
            </Dropdown>
          ) : null}
        </div>
      </footer>
    </div>
  );
}

export default ActivityCard;
