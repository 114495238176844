import React, { useEffect, useState } from 'react';
import AddBookletModal from './AddBookletModal';
import BookletCard from './BookletCard';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import Loader from 'app/components/loader';
import EmptyState from 'app/components/EmptyState';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { t } from 'i18next';

export default function TabBooklet({ currentModuleId }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedBooklets, setSelectedBooklets] = useState([]);
  const [loading, setLoading] = useState(false);
  const client = useClient();

  const hasSelectedModule = currentModuleId !== 'm';

  const getModuleBooklets = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`/learning_system_question_books?filter[learning_system_id]=${currentModuleId}`);
    if (error) {
      toast.error('Erro ao buscar apostilas');
    } else {
      setSelectedBooklets(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getModuleBooklets();
  }, [currentModuleId]);

  const filterSelectedBooklets = selectedBooklets.filter(item => {
    if (searchTerm === '') {
      return true;
    }
    if (searchTerm && searchTerm !== '') {
      return item?.['question-book']?.title.toLowerCase().includes(searchTerm.toLowerCase());
    }
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const updateBookletsList = async values => {
    setLoading(true);
    const { destination, source } = values;
    if (!destination) {
      return null;
    }
    if (destination.index === source.index) {
      return null;
    }

    const dIndex = destination.index;
    const sIndex = source.index;
    const newItems = reorder(selectedBooklets, sIndex, dIndex);

    const newBookletsOrder = newItems.map((booklet, index) => {
      return { id: booklet.id, order: index };
    });

    const { error } = await client.mutate(
      ['learning_systems', currentModuleId, 'sort'],
      {
        learning_system_question_books: newBookletsOrder
      },
      { method: 'PUT' }
    );
    if (error) {
      toast.error('Erro ao reordenar apostilas!');
    } else {
      getModuleBooklets();
      toast.success('Apostilas reordenadas com sucesso!');
    }
  };

  return (
    <DragDropContext onDragEnd={values => updateBookletsList(values)}>
      <div className="tab__pane fadeIn">
        <div className="filter-bar">
          <div className="filter-bar__inner">
            <div className="filter-bar__row">
              <label
                htmlFor="search"
                className="filter-bar__label"
              >
                {t('textsCommon.search')}
              </label>
              <input
                className="form__control form__control--search-with-icon"
                placeholder={t('filter.searchQuestionBook')}
                type="search"
                name="search"
                id="search"
                onChange={e => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          <div className="filter-bar__actions">
            <button
              type="button"
              onClick={() => setShowModal(true)}
              className={hasSelectedModule ? 'btn btn--wide btn--primary' : 'btn btn--wide btn--primary disabled'}
            >
              {t('textsCommon.addQuestionBook')}
            </button>
          </div>
        </div>

        {loading && <Loader />}

        {!loading && filterSelectedBooklets?.length === 0 && hasSelectedModule && <EmptyState type="data" />}

        {!loading && !hasSelectedModule && (
          <EmptyState
            type="selectCard"
            text='Selecione um módulo para habilitar "Adicionar a apostila".'
          />
        )}

        {!loading && (
          <Droppable droppableId="droppable">
            {provided => (
              <div
                className="round-dg-wrapper"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <table
                  className="round-dg round-dg--light"
                  style={{ minWidth: '800px' }}
                >
                  <tbody className="round-dg__body">
                    {filterSelectedBooklets?.length > 0 &&
                      filterSelectedBooklets?.map((booklet, i) => (
                        <Draggable
                          key={i}
                          draggableId={booklet.id.toString()}
                          index={i}
                        >
                          {provided => (
                            <tr
                              className="round-dg__row"
                              key={i}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <BookletCard
                                setSelectedBooklets={setSelectedBooklets}
                                booklet={booklet}
                              />
                            </tr>
                          )}
                        </Draggable>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </Droppable>
        )}

        <AddBookletModal
          selectedBooklets={selectedBooklets}
          setSelectedBooklets={setSelectedBooklets}
          currentModuleId={currentModuleId}
          show={showModal}
          onClose={() => {
            setShowModal(false);
            getModuleBooklets();
          }}
        />
      </div>
    </DragDropContext>
  );
}
