import React from 'react';
import logo from './img/seduc-pi.svg';

export default function HeaderPrinted(props) {
  console.log("props", props)
  const { institution } = props;
  return (
    <header className="printed-exam__header">
      <div className="printed-exam__inner-header">
        <h1 className="printed-exam__title">{institution}</h1>
        <p className="printed-exam__subtitle">SIAPE/GO</p>
      </div>
    </header>
  );
}
