import React, { useState, useContext } from 'react';
import Dropdown from 'app/components/Dropdown';
import { FiChevronDown, FiMoreHorizontal } from 'react-icons/fi';
import Swal from 'sweetalert2';
import { useSession } from 'app/hooks/useSession';
import { ProjectsContext } from './provider';
import Modal from 'app/components/Modal';
import { Link } from '@reach/router';
import Avatar, { AvatarStack } from 'app/components/Avatar';
import { ProjectForm } from './ProjectForm';
import getPermission from 'app/utils/getPermission';
import { useTranslation } from 'react-i18next';

export default function ProjectsCard(props) {
  const { t } = useTranslation();
  const { data } = props;
  const [editShow, setEditShow] = useState(false);
  const { session } = useSession();
  const basePath = window.env.REACT_APP_BASE_PATH;

  const { setSelectedProject, deleteProject, selectedProject } = useContext(ProjectsContext);

  const handleEdit = project => {
    setSelectedProject(project);
    setEditShow(true);
  };

  // const thereArePendingVersions = projectVersions => {
  //   return projectVersions.some(
  //     pv => pv.approved === null || pv.approved === false,
  //   );
  // };

  return (
    <div
      className="card"
      key={data.id}
    >
      <div className="card__header">
        <div className="card__inner-header">
          <h3 className="card__title">{data.name}</h3>
        </div>

        {data.approved ? <span className="badge badge--success badge--tiny">{t('exams.statusInApproved')}</span> : <span className="badge badge--primary badge--tiny">{t('exams.inProgress')}</span>}

        {/* {thereArePendingVersions(data['project-versions']) ? (
          <span
            style={{ marginLeft: 5 }}
            className="badge badge--tiny badge--warning"
          >
            Avaliação pendente
          </span>
        ) : null} */}
      </div>

      <div className="card__body">
        <div className="card__columns">
          <div className="card__avatar">
            <h4 className="card__avatar-title">{t('projects.participants')}</h4>
            <AvatarStack
              data={data?.users}
              modalTitle={`${t('projects.titleProjectParticipants')} ${data.name}`}
              className="avatar--xs"
            />
          </div>
        </div>
      </div>

      <div className="card__footer">
        <div
          className="btn-group btn-group--with-dropdown"
          role="group"
          aria-label="Ações"
        >
          {data['project-users']?.some(projectUser => projectUser.user_id === session.user.id) ? (
            <Link
              to={`${basePath}/projetos/${data.id}`}
              type="button"
              className="btn btn--outline btn--small"
            >
              {t('button.accessProject')}
            </Link>
          ) : (
            <Link
              to={`${basePath}/projetos/${data.id}/sobre`}
              type="button"
              className="btn btn--outline btn--small"
            >
              {t('button.detailsProject')}
            </Link>
          )}
          {getPermission('Editar projetos', 'Projetos') || getPermission('Excluir projetos', 'Projetos') ? (
            <Dropdown className="btn--small btn btn--outline">
              <Dropdown.Toggle>
                <FiChevronDown />
              </Dropdown.Toggle>

              <Dropdown.Content>
                {getPermission('Editar projetos', 'Projetos') && <Dropdown.Item onClick={() => handleEdit(data)}>{t('button.edit')}</Dropdown.Item>}
                {getPermission('Excluir projetos', 'Projetos') && (
                  <Dropdown.Item
                    onClick={() => {
                      Swal.fire({
                        title: t('projects.warningDeleteProject'),
                        text: t('projects.warningDeleteCannotBeUndoneText'),
                        confirmButtonText: t('button.yes'),
                        showDenyButton: true,
                        denyButtonText: t('button.no'),
                        showCloseButton: true
                      }).then(res => {
                        if (res.isConfirmed) {
                          deleteProject(data.id);
                        }
                      });
                    }}
                  >
                    {t('textsCommon.delete')}
                  </Dropdown.Item>
                )}
              </Dropdown.Content>
            </Dropdown>
          ) : null}
        </div>
      </div>

      <Modal
        show={editShow}
        onClose={() => setEditShow(false)}
      >
        <ProjectForm
          labelForm={'Editar projeto'}
          labelFormButton={'Editar e continuar'}
          currentProject={selectedProject}
        />
      </Modal>
    </div>
  );
}
