import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useClient } from 'jsonapi-react';
import { FiMoreHorizontal } from 'react-icons/fi';

import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import EmptyState from 'app/components/EmptyState';
import Dropdown from 'app/components/Dropdown';

import TabNavBar from '../TabNavBar';
import { Link } from '@reach/router';
import toast from 'react-hot-toast';
import moment from 'moment';
// import planilha_modelo from './planilha_modelo.xlsx';
import Loader from 'app/components/loader';
import Swal from 'sweetalert2';
import { useSession } from 'app/hooks/useSession';
import { navigate } from '@reach/router';
import { t } from 'i18next';
import Select from 'react-select';
import BreadCrumbs from 'app/components/BreadCrumbs';

export default function ClassroomImports(props) {
  const { uri } = props;

  const [classrooms, setClassrooms] = useState([]);
  const [spreadsheets, setSpreadsheets] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [filteredSpreadsheets, setFilteredSpreadsheets] = useState([]);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const { session } = useSession();
  const client = useClient();
  const basePath = window.env.REACT_APP_BASE_PATH;

  const filters = useFormik({
    initialValues: {
      classroom: 'blank',
      institution: 'blank',
      course: 'blank'
    }
  });

  const spreadsheetForm = useFormik({
    initialValues: {
      spreadsheet: ''
    }
  });

  const getClassrooms = async () => {
    const { data, error } = await client.fetch('classrooms');
    if (error) {
      toast.error('Erro ao buscar turmas');
    } else {
      setClassrooms(data);
    }
  };

  const getInstitutions = async () => {
    const { data, error } = await client.fetch('/institutions');
    if (error) {
      toast.error('Erro ao buscar instituições');
    } else {
      setInstitutions(data);
    }
  };

  const getCourses = async () => {
    const { data, error } = await client.fetch('/courses');
    if (error) {
      toast.error('Erro ao buscar cursos');
    } else {
      setCourses(data);
    }
  };
  const getSpreadsheets = async () => {
    const { data, error } = await client.fetch('spreadsheet_imports');
    if (error) {
      toast.error('Erro ao buscar importações');
    } else {
      setSpreadsheets(data);
      setLoading(false);
    }
  };

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const sendSpreadsheet = async () => {
    if (spreadsheetForm.values.spreadsheet && spreadsheetForm.values.spreadsheet !== '') {
      const requestParams = {
        file: await toBase64(spreadsheetForm.values.spreadsheet),
        filename: spreadsheetForm.values.spreadsheet.name
      };
      const { error } = await client.mutate('spreadsheet_imports', requestParams);
      if (error) {
        toast.error('Erro ao enviar planilha.');
      } else {
        toast.success('Sucesso ao enviar planilha!');
        getSpreadsheets();
      }
    }
  };

  const handleDelete = id => {
    Swal.fire({
      title: t('warning.warningDeleteInvite'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteSpreadsheetImport(id);
      }
    });
  };

  const deleteSpreadsheetImport = async id => {
    const { error } = await client.delete(`spreadsheet_imports/${id}`);
    if (error) {
      toast.error('Erro ao excluir importação.');
    } else {
      getSpreadsheets();
      toast.success('Importação excluída com sucesso!');
    }
  };

  useEffect(() => {
    if (!session.user.profileNames.includes('Aluno')) {
      getClassrooms();
      getSpreadsheets();
      getInstitutions();
      getCourses();
    }
  }, [session]);

  useEffect(() => {
    sendSpreadsheet();
  }, [spreadsheetForm.values.spreadsheet]);

  useEffect(() => {
    if (session.user.profileNames.includes('Aluno')) {
      navigate(basePath);
    }
  }, [session]);

  const filterSpreadsheetByInstitution = spreadsheets.filter(item => {
    return filters.values.institution === 'blank' || item.classroom?.institution?.id === parseInt(filters.values.institution);
  });

  const filterSpreadsheetByCourse = filterSpreadsheetByInstitution.filter(item => {
    return filters.values.course === 'blank' || item.classroom?.course?.id === parseInt(filters.values.course);
  });

  const filterSpreadsheetByClassroom = filterSpreadsheetByCourse.filter(item => {
    return filters.values.classroom === 'blank' || item.classroom?.id === parseInt(filters.values.classroom);
  });

  const filterClassroomByInstitution = classrooms.filter(item => {
    return filters.values.institution === 'blank' || item?.institution?.id === parseInt(filters.values.institution);
  });

  useEffect(() => {
    filters.setFieldValue('course', 'blank');
    filters.setFieldValue('classroom', 'blank');
  }, [filters.values.institution]);

  const breadCrumbs = {
    title: 'Organização da Plataforma',
    nav: [
      {
        route: uri,
        name: 'Turmas',
        isActive: true
      }
    ]
  };

  return (
    <main className="main-content main-content--block">
      <BreadCrumbs data={breadCrumbs} />

      <TabNavBar selectedTab="importar" />

      <div className="filter-bar">
        <div className="filter-bar__inner">
          <div className="filter-bar__row">
            <label className="filter-bar__label">{t('filter.institution')}</label>
            <Select
              defaultValue={filters.values.institution}
              openMenuOnFocus
              options={[
                { value: 'blank', label: t('filter.blankLabelAll') },
                ...institutions?.map(item => {
                  return { value: item.id, label: item.name };
                })
              ]}
              className="react-multi-select filter-bar__multi-select"
              classNamePrefix="react-multi-select"
              placeholder={t('filter.blankLabelAll')}
              noOptionsMessage={() => 'Sem opções'}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null
              }}
              onChange={e => filters.setFieldValue('institution', e.value)}
              style={{ width: '300px' }}
            />
          </div>

          <div className="filter-bar__row">
            <label className="filter-bar__label">Cursos</label>
            <select
              value={filters.values.course}
              onChange={e => filters.setFieldValue('course', e.target.value)}
              className="filter-bar__select"
            >
              <option value="blank">Selecione para filtrar</option>
              {courses.map(item => {
                return <option value={item.id}>{item.title}</option>;
              })}
            </select>
          </div>

          <FilterSelectionBox
            label={t('createAccount.selectClass')}
            value={filters.values.classroom}
            onChange={e => filters.setFieldValue('classroom', e.target.value)}
            options={filterClassroomByInstitution.map(item => ({
              id: item.id,
              name: item.title
            }))}
          />
        </div>

        <div className="filter-bar__actions">
          <a
            href={'/planilha_modelo.xlsx'}
            download={'/planilha_modelo.xlsx'}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn--wide btn--outline"
          >
            {t('classes.buttonTemplateSpreadsheet')}
          </a>

          <label className="form__custom-file-input">
            <input
              type="file"
              name="file"
              id="file"
              accept=".xls, .xlsx"
              onChange={e => {
                spreadsheetForm.setFieldValue('spreadsheet', e.target.files[0]);
              }}
            />
            <span className="btn btn--wide btn--primary btn--wide">{t('classes.buttonImportSpreadsheet')}</span>
          </label>
        </div>
      </div>

      {loading && <Loader />}
      {!loading && filterSpreadsheetByClassroom.length === 0 && (
        <EmptyState
          type="add"
          title="Nenhum estudante importado."
          text="Faça o download da planilha modelo, preencha os dados e por fim, importe a nova planilha."
        />
      )}
      {!loading && filterSpreadsheetByClassroom.length > 0 && (
        <div className="round-dg-wrapper">
          <table
            className="round-dg round-dg--light"
            style={{ borderSpacing: '0 10px' }}
          >
            <thead className="round-dg__header">
              <tr className="round-dg__row">
                <th
                  className="round-dg__cell-header round-dg__cell-header--sm"
                  style={{ width: '126px' }}
                >
                  {t('textsCommon.class')}
                </th>
                <th
                  className="round-dg__cell-header round-dg__cell-header--sm"
                  style={{ width: '126px' }}
                >
                  {t('filter.institution')}
                </th>
                <th
                  className="round-dg__cell-header round-dg__cell-header--sm"
                  style={{ width: '126px' }}
                >
                  Curso
                </th>
                <th
                  className="round-dg__cell-header round-dg__cell-header--sm"
                  style={{ width: '116px' }}
                >
                  {t('classes.thIdentifier')}
                </th>
                <th
                  className="round-dg__cell-header round-dg__cell-header--sm"
                  style={{ width: '86px' }}
                >
                  {t('classes.thImportCount')}
                </th>
                <th
                  className="round-dg__cell-header round-dg__cell-header--sm"
                  style={{ width: '116px' }}
                >
                  {t('classes.thImportSpreadsheet')}
                </th>
                <th
                  className="round-dg__cell-header round-dg__cell-header--sm"
                  style={{ width: '70px' }}
                >
                  {t('classes.thImportDate')}
                </th>
                <th
                  className="round-dg__cell-header round-dg__cell-header--sm"
                  style={{ width: '160px' }}
                >
                  Status
                </th>
                <th
                  className="round-dg__cell-header round-dg__cell-header--sm"
                  style={{ width: '44px' }}
                />
              </tr>
            </thead>

            {filterSpreadsheetByClassroom.map((spreadsheet, index) => {
              return (
                <tbody
                  className="round-dg__body"
                  key={index}
                >
                  <tr
                    className="round-dg__row"
                    key={index}
                  >
                    <th className="round-dg__cell-header round-dg__cell-header--row round-dg__cell-header--sm">{spreadsheet?.classroom?.title}</th>
                    <td className="round-dg__cell round-dg__cell--sm">{spreadsheet?.classroom?.institution.name}</td>
                    <td className="round-dg__cell round-dg__cell--sm">{spreadsheet?.classroom?.course?.name}</td>
                    <td className="round-dg__cell round-dg__cell--sm">
                      <span className="expanded-calendar__tag expanded-calendar__tag--success">#{spreadsheet.id}</span>
                    </td>
                    <td className="round-dg__cell round-dg__cell--sm">
                      <Link
                        to={`${basePath}/turmas/importar/${spreadsheet.id}`}
                        className="btn btn--link u-p-0"
                      >
                        {spreadsheet['student-count']} {t('classes.users')}
                      </Link>
                    </td>
                    <td className="round-dg__cell round-dg__cell--sm">
                      <a
                        className="btn btn--link u-p-0 u-text-truncate"
                        style={{ justifyContent: 'left' }}
                        href={spreadsheet.file.url}
                        download={spreadsheet.file.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={spreadsheet.filename}
                      >
                        {spreadsheet.filename}
                      </a>
                    </td>
                    <td className="round-dg__cell round-dg__cell--sm">{moment(spreadsheet['created-at']).format('DD/MM/YYYY')}</td>
                    {spreadsheet.status === 'import_success' && (
                      <td className="round-dg__cell round-dg__cell--sm">
                        <span className="badge badge--tiny badge--success">{t('classes.tagImportSuccess')}</span>
                      </td>
                    )}
                    {spreadsheet.status === 'import_failed' && (
                      <td className="round-dg__cell round-dg__cell--sm">
                        <span className="badge badge--tiny badge--warning">{t('classes.tagimportError')}</span>
                      </td>
                    )}
                    {spreadsheet.status === 'import_created' && (
                      <td className="round-dg__cell round-dg__cell--sm">
                        <span className="badge badge--tiny badge--warning">{t('classes.tagImportWait')}</span>
                      </td>
                    )}
                    <td className="round-dg__cell round-dg__cell--sm">
                      <Dropdown>
                        <Dropdown.Toggle>
                          <FiMoreHorizontal />
                        </Dropdown.Toggle>

                        <Dropdown.Content>
                          {spreadsheet['student-count'] === 0 && <Dropdown.Item onClick={() => handleDelete(spreadsheet.id)}>{t('button.delete')}</Dropdown.Item>}
                          <Dropdown.Item to={`${basePath}/turmas/importar/${spreadsheet.id}`}>{t('classes.buttonDetails')}</Dropdown.Item>
                        </Dropdown.Content>
                      </Dropdown>
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>
      )}
    </main>
  );
}
