import React, { useEffect, useState } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { Formik, useFormik } from 'formik';
import toast from 'react-hot-toast';
import userAvatar from 'app/images/user-avatar.svg';
import photoIcon from 'app/images/icons/photo-icon.svg';
import InputMask from 'react-input-mask';
import { useClient } from 'jsonapi-react';
import Loader from 'app/components/loader';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import { t } from 'i18next';
import { navigate } from '@reach/router';
import * as yup from 'yup';
import promiseRequest from 'app/utils/promiseToast';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

export function CreateOrUpdateParticipant(props) {
  const { id } = props;
  const client = useClient();
  const [userDetailId, setUserDetailId] = useState();
  const [user, setUser] = useState();
  const [regionals, setRegionals] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [courses, setCourses] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [regionalLoading, setRegionalLoading] = useState(false);
  const [courseLoading, setCourseLoading] = useState(false);
  const [classroomLoading, setClassroomLoading] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const { promiseToast, isSubmitting } = promiseRequest();
  const [institutionsLoading, setInstitutionsLoading] = useState(false);
  const [semVinculoAlert, setSemVinculoAlert] = useState('')
  const animatedComponents = makeAnimated();

  const validationSchema = yup.object({
    selectedProfileName: yup.string().required('Campo obrigatório'),
    name: yup.string().required('Campo obrigatório'),
    email: yup.string().when('id', {
      is: false,
      then: yup.string().required('Campo obrigatório')
    }),
    id: yup.boolean()
  });

  const createUser = async () => {
    const auxValues = { ...form.values };
    auxValues.email = auxValues.email.toLowerCase();

    const institutionIds = institutions?.map(item => item?.id);
    const classroomsGRE = (classrooms || []).filter(item => institutionIds?.includes(item.institution?.id));

    if (form.values.GRE && !form.values.institution && !form.values.course && form.values['classroom-ids'].length === 0) {
      auxValues['classroom-ids'] = classroomsGRE?.map(item => item.id);
    }

    if (form.values.GRE && form.values.institution && !form.values.course && form.values['classroom-ids'].length === 0) {
      auxValues['classroom-ids'] = classroomsGRE?.filter(item => item.institution.id === parseInt(form.values.institution))?.map(item => item.id);
    }

    if (form.values.GRE && form.values.institution && form.values.course && form.values['classroom-ids'].length === 0) {
      auxValues['classroom-ids'] = classroomsGRE?.filter(item => item.course.id === parseInt(form.values.course) && item.institution.id === parseInt(form.values.institution))?.map(item => item.id);
    }

    if (form.values['classroom-ids']?.length > 0) {
      auxValues['classroom-ids'] = [parseInt(form.values['classroom-ids'])];
    }

    if (id) {
      auxValues.userDetailAttributes = {
        id: userDetailId,
        phone: auxValues.phone
      };

      delete auxValues['classroom-ids'];
    }
    const url = !id ? 'users' : `users/${id}`;

    const onSuccess = () => {
      history.back();
    };

    delete auxValues.GRE;
    delete auxValues.course;
    delete auxValues.institution;
    delete auxValues.id;

    promiseToast({
      url,
      request: auxValues,
      successText: !id ? t('toast.successRegistration') : 'Participante editado com sucesso!',
      errorText: !id ? 'Erro ao criar participante' : 'Erro ao editar participante',
      onSuccess
    }).catch(error => {
      if (error?.id === 'email') {
        toast.error(error?.title);
      }
    });
  };

  const form = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
      phone: '',
      selectedProfileName: '',
      image: '',
      'classroom-ids': '',
      GRE: '',
      institution: '',
      course: '',
      id: Boolean(id)
    },
    validationSchema,
    onSubmit: createUser
  });

  const getProfiles = async () => {
    const { data, error } = await client.fetch(`profiles?now=${new Date().getTime()}`);
    if (error) {
      toast.error('Falha ao carregar perfis');
    } else {
      setProfiles(
        data.map(profile => {
          return {
            id: profile.name,
            name: profile.name
          };
        })
      );
    }
  };

  const getRegionals = async () => {
    setRegionalLoading(true);
    const { data, error } = await client.fetch('/regionals');
    if (error) {
      toast.error('Erro ao carregar regionais');
    } else {
      setRegionals(data);
    }
    setRegionalLoading(false);
  };

  const getInstitutions = async () => {
    setInstitutionsLoading(true);
    const { data, error } = await client.fetch('/institutions');
    if (error) {
      toast.error('Erro ao carregar instituições');
    } else {
      setInstitutions(data);
    }
    setInstitutionsLoading(false);
  };

  const institutionsGre = regionals?.find(item => item.id === parseInt(form.values.GRE))?.institutions;

  const getCourses = async () => {
    setCourseLoading(true);
    const { data, error } = await client.fetch('/courses?simple=true');
    if (error) {
      toast.error('Erro ao carregar cursos');
    } else {
      setCourses(data);
    }
    setCourseLoading(false);
  };

  const getClassrooms = async () => {
    setClassroomLoading(true);
    const { data, error } = await client.fetch('/classrooms?simple=true');
    if (error) {
      toast.error('Erro ao buscar turmas');
    } else {
      setClassrooms(data);
    }
    setClassroomLoading(false);
  };

  const getUser = async () => {
    const { data, error } = await client.fetch(`users/${id}?with_classrooms=true`);

    if (error) {
      toast.error(t('toast.errorGetUser'));
    } else {
      setUser(data);
      setUserDetailId(data['user-detail'].id);
    }
  };

  useEffect(() => {
    if (user) {
      form.setValues({
        name: user?.name,
        email: user?.email?.toLowerCase(),
        phone: user?.['user-detail']?.phone,
        selectedProfileName: user['profile-names'][0],
        id: Boolean(id)
        // 'classroom-ids': user.classrooms[0].id,
        // GRE: user.classrooms?.[0]?.institution?.regional?.id,
        // institution: user.classrooms?.[0]?.institution?.id,
        // course: user.classrooms?.[0].course_id
      });
    }
  }, [user]);

  const handleChangeImage = e => {
    let file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = _handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  };

  const _handleReaderLoaded = readerEvt => {
    let bynaryString = readerEvt.target.result;
    form.setFieldValue('image', `data:image/jpeg;base64,${btoa(bynaryString)}`);
  };

  useEffect(() => {
    if (id) {
      getUser();
    }
  }, [id]);

  useEffect(() => {
    getProfiles();
    getRegionals();
    getInstitutions();
    getCourses();
    getClassrooms();
  }, []);

  const institutionIds = institutionsGre?.map(item => item?.id);
  const classroomsGRE = (classrooms || []).filter(item => institutionIds?.includes(item.institution?.id));

  const filteredClassrooms2 = classroomsGRE?.filter(item => {
    return item?.course?.id === parseInt(form.values.course) && item?.institution?.id === parseInt(form.values.institution);
  });

  const handleRegionalChange = event => {
    form.setFieldValue('GRE', event.target.value);
    const selectedIndex = event.target.selectedIndex;
    const selectedOption = event.target.options[selectedIndex];
    const selectedLabel = selectedOption.innerText;

    if (selectedLabel.toLowerCase() === 'Sem Vinculo'.toLocaleLowerCase()) {
      setSemVinculoAlert('Ao selecionar Sem Vinculo, o participante participará de todas as turmas e cursos automaticamente')
    } else {
      setSemVinculoAlert('')
    }
    form.setFieldValue('institution', '');
    form.setFieldValue('course', '');
    form.setFieldValue('classroom-ids', '');
  };

  const handleInstitutionChange = item => {
    form.setFieldValue('institution', item);
    form.setFieldValue('course', '');
    form.setFieldValue('classroom-ids', '');
  };

  const handleCourseChange = event => {
    form.setFieldValue('course', event.target.value);
    form.setFieldValue('classroom-ids', '');
  };

  return (
    <>
      <main className="main-content main-content--block">
        <div className="filter-bar">
          <button
            onClick={() => navigate(-1)}
            className="filter-bar__back"
          >
            <span className="card__icon-wrapper">
              <FiChevronLeft className="card__icon" />
            </span>

            <span className="filter-bar__back-container">
              <span className="filter-bar__title">{t('participants.buttonRegister')}</span>
            </span>
          </button>
        </div>
        <div className="page">
          <div className="page__col">
            <div className="page__content">
              <form
                onSubmit={form.handleSubmit}
                className="form"
              >
                <fieldset className="form__row">
                  <div className="profile-page__sidebar">
                    <div
                      className="profile-page__avatar"
                      style={{
                        backgroundImage: `url(${form.values.image || (user?.image?.url ? user?.image?.url + `?now=${new Date().getTime()}` : userAvatar)})`
                      }}
                    />
                    <label
                      htmlFor="image"
                      className="profile-page__avatar-btn"
                    >
                      <img
                        alt=""
                        src={photoIcon}
                      />
                      <input
                        type="file"
                        name="image"
                        id="image"
                        accept=".jpeg, .png, .jpg"
                        onChange={handleChangeImage}
                      />
                    </label>
                  </div>
                </fieldset>

                <div className="form__row">
                  <label
                    className="form__label"
                    htmlFor="profile"
                  >
                    {t('participants.profile')}
                  </label>
                  <select
                    className="form__select"
                    name="profile"
                    id="profile"
                    value={form.values.selectedProfileName}
                    onChange={e => {
                      form.setFieldValue('selectedProfileName', e.target.value);
                    }}
                  >
                    <option value="">{t('participants.labelChooseProfile')} </option>
                    {profiles &&
                      profiles.map(profile => (
                        <option
                          key={profile.id}
                          value={profile.id}
                        >
                          {profile.name}
                        </option>
                      ))}
                  </select>
                  {form.touched.selectedProfileName && form.errors.selectedProfileName && <span style={{ color: 'red' }}>{form.errors.selectedProfileName}</span>}
                </div>

                {!id && (
                  <>
                    {regionals?.length > 1 && (
                      <div className="form__row">
                        <label className="form__label">Regional</label>
                        <select
                          onChange={e => handleRegionalChange(e)}
                          className="form__select"
                          value={form.values.GRE}
                        >
                          <option value="">{regionalLoading ? t('loader.loading2') : t('filter.selectRegionals')}</option>
                          {regionals.map(item => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                        </select>
                        {form.touched.GRE && form.errors.GRE && <span style={{ color: 'red' }}>{form.errors.GRE}</span>}
                        {semVinculoAlert && semVinculoAlert !== '' && <span style={{ color: 'red' }}>{semVinculoAlert}</span>}
                      </div>
                    )}

                    <div className="form__row">
                      <label className="form__label">{t('filter.institution')}</label>
                      <Select
                        classNamePrefix="react-multi-select"
                        openMenuOnFocus
                        noOptionsMessage={() => 'Sem opções'}
                        isDisabled={institutionsLoading}
                        value={form.values.institution}
                        placeholder={institutionsLoading ? 'Carregando...' : 'Selecione a instituição'}
                        onChange={item => handleInstitutionChange(item)}
                        components={{
                          animatedComponents,
                          IndicatorSeparator: () => null,
                          ClearIndicator: () => null
                        }}
                        closeMenuOnSelect={false}
                        isSearchable={true}
                        options={
                          form.values.GRE
                            ? institutionsGre?.map(item => {
                                return { value: item?.id, label: item?.name };
                              })
                            : institutions?.map(item => {
                                return { value: item?.id, label: item?.name };
                              })
                        }
                        isMulti
                      />
                    </div>

                    <div className="form__row">
                      <label className="form__label">Curso</label>
                      <select
                        value={form.values.course}
                        disabled={form.values.institution === ''}
                        className="form__select"
                        onChange={e => handleCourseChange(e)}
                      >
                        <option value="">{courseLoading ? 'Carregando...' : 'Selecione um curso'}</option>
                        {courses.map(item => {
                          return <option value={item.id}>{item.title}</option>;
                        })}
                      </select>
                    </div>
                    <div className="form__row">
                      <label className="form__label">Turma</label>
                      <select
                        value={form.values['classroom-ids']}
                        disabled={form.values.course === ''}
                        className="form__select"
                        onChange={e => form.setFieldValue('classroom-ids', e.target.value)}
                      >
                        <option value="">{classroomLoading ? 'Carregando...' : 'Selecione uma turma'}</option>
                        {regionals?.length > 1
                          ? filteredClassrooms2?.map(item => {
                              return <option value={item.id}>{item.title}</option>;
                            })
                          : classrooms?.map(item => {
                              return <option value={item.id}>{item.title}</option>;
                            })}
                      </select>
                    </div>
                  </>
                )}

                <div className="form__row">
                  <label
                    className="form__label"
                    htmlFor="name"
                  >
                    {t('participants.labelFullName')}
                  </label>
                  <input
                    className="form__control"
                    type="text"
                    name="name"
                    id="name"
                    placeholder={t('textsCommon.writeHere')}
                    value={form.values.name}
                    onChange={form.handleChange}
                  />
                  {form.touched.name && form.errors.name && <span style={{ color: 'red' }}>{form.errors.name}</span>}
                </div>

                <div className="form__row">
                  <label
                    className="form__label"
                    htmlFor="email"
                  >
                    E-mail
                  </label>
                  <input
                    disabled={id}
                    className="form__control"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="seuemail@provedor.com"
                    value={form.values.email}
                    onChange={!id ? form.handleChange : null}
                  />
                  {form.touched.email && form.errors.email && <span style={{ color: 'red' }}>{form.errors.email}</span>}
                </div>

                <div className="form__row">
                  <label
                    className="form__label"
                    htmlFor="phone"
                  >
                    {t('participants.phone')}
                  </label>
                  <InputMask
                    className="form__control"
                    mask="(99) 99999-9999"
                    name="phone"
                    id="phone"
                    placeholder="(99) 99999-9999"
                    value={form.values.phone}
                    onChange={form.handleChange}
                  />
                </div>

                {!id && (
                  <div className="form__row">
                    <label
                      className="form__label"
                      htmlFor="message"
                    >
                      {t('participants.message')}
                    </label>
                    <textarea
                      className="form__control"
                      name="message"
                      id="message"
                      placeholder={t('participants.placeholderSendMessage')}
                      value={form.values.message}
                      onChange={form.handleChange}
                    />
                  </div>
                )}
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className={isSubmitting ? 'btn btn--primary disabled' : 'btn btn--primary'}
                >
                  {id ? t('participants.buttonEdit') : t('participants.buttonSendEmail')}
                </button>
              </form>
            </div>
          </div>
          <div className="page__aside" />
        </div>
      </main>
    </>
  );
}
