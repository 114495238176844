import { useTranslation } from 'react-i18next';
import { TopBar } from './TopBar';

export function ProjectsOnboarding() {
  const { t } = useTranslation();

  return (
    <section>
      <TopBar />

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.filterProjects')}</h1>
        <p className="text-option-onboarding">{t('onboarding.filterProjectsDesc')}</p>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.exploreProjectsCard')}</h1>
        <p className="text-option-onboarding">{t('onboarding.exploreProjectsCardDesc')}</p>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.accessProject')}</h1>
        <p className="text-option-onboarding">
          <u>{t('onboarding.clickInButton')}</u>, {t('onboarding.accessProjectDesc')}
        </p>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.navigateProjectDetails')}</h1>
        <p className="text-option-onboarding">
          <u>{t('onboarding.onAccessProject')}</u>, {t('onboarding.navigateProjectDetailsDesc')}
        </p>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.verifyInformations')}</h1>
        <p className="text-option-onboarding">
          <u>{t('onboarding.onTheRightSide')}</u>, {t('onboarding.verifyInformationsDesc')}
        </p>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.accessScreen')}</h1>
        <p className="text-option-onboarding">
          <u>{t('onboarding.clickAboutProject')}</u>, {t('onboarding.accessScreenDesc')}
        </p>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.sendNewProjectVersion')}</h1>
        <p className="text-option-onboarding">
          <u>{t('onboarding.ifStatusProject')}</u>, {t('onboarding.sendNewProjectVersionDesc')}
        </p>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.sendFile')}</h1>
        <p className="text-option-onboarding">{t('onboarding.sendFileDesc')}</p>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.followUpStatusProject')}</h1>
        <p className="text-option-onboarding">
          <u>{t('onboarding.afterSend')}</u>, {t('onboarding.followUpStatusProjectDesc')}
        </p>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.receiveFeedback')}</h1>
        <p className="text-option-onboarding">{t('onboarding.receiveFeedbackDesc')}</p>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.answerComments')}</h1>
        <p className="text-option-onboarding">{t('onboarding.answerCommentsDesc')}</p>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.sendProjectsUpdate')}</h1>
        <p className="text-option-onboarding">{t('onboarding.sendProjectsUpdateDesc')}</p>
      </div>

      <div>
        <h1 className="title-option-onboarding">{t('onboarding.projectApproval')}</h1>
        <p className="text-option-onboarding">{t('onboarding.projectApprovalDesc')}</p>
      </div>
    </section>
  );
}
