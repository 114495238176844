import React, { useEffect } from 'react';
import axios from 'axios';
import api, { baseURL } from '../services/api';
import { navigate } from '@reach/router';
import { useSession } from 'app/hooks/useSession';
import toast from 'react-hot-toast';

function LoginCallback(props) {
  const { session, setSession, sanitizeResponse, setUserData, signOut, storeSsoToken, storeConnections, storeDBNumber } = useSession();

  useEffect(() => {
    const authenticateUser = async () => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const token = urlSearchParams.get('token');
      const dbNumber = urlSearchParams.get('db_number');
      const hostUrl = urlSearchParams.get('host_url');
      const url = window.env.REACT_APP_SSO_API_URL;

      if (token && dbNumber) {
        const data = {
          token,
          db_number: parseInt(dbNumber),
          host_url: hostUrl
        };

        try {
          const response = await axios.post(`${url}/api/v1/authenticate`, data);
          storeSsoToken(response.data.token);
          storeConnections(response.data.connections);
          storeDBNumber(dbNumber);
          const newObject = sanitizeResponse(response.data.session.data.attributes);
          setSession({ user: newObject });
          navigate(window.env.REACT_APP_BASE_PATH || '/');
        } catch (e) {
          if (e.response.status === 401) {
            signOut();
          } else {
            toast.error(e.message);
          }
        }
      } else {
        toast.error('Formato de URL inválido');
      }
    };

    const authenticateVobysUser = async () => {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const token = urlSearchParams.get('token');

      if (token) {
        try {
          const response = await api.get(`cas_sso/${token}`);
          const newObject = sanitizeResponse(response.data.attributes);
          setSession({ user: newObject });

          navigate(window.env.REACT_APP_BASE_PATH || '/');
        } catch (e) {
          const ssoVobys = window.env.REACT_APP_VOBYS_SSO_API_URL;
          window.location.replace(`${ssoVobys}/sso/login?service=${baseURL()}/cas_sso/callback`);
        }
      } else {
        const ssoVobys = window.env.REACT_APP_VOBYS_SSO_API_URL;
        window.location.replace(`${ssoVobys}/sso/login?service=${baseURL()}/cas_sso/callback`);
      }
    };

    const ssoVobys = window.env.REACT_APP_VOBYS_SSO_API_URL;
    if (ssoVobys) {
      authenticateVobysUser();
    } else {
      authenticateUser();
    }
  }, []);

  return <div className="new-login"></div>;
}

export default LoginCallback;
