import React from 'react';
import { Link } from '@reach/router';
import { t } from 'i18next';

function TabNavBar(props) {
  const { selectedTab, viewCourse, viewClassroom } = props;
  const basePath = window.env.REACT_APP_BASE_PATH;
  return (
    <div className="tab">
      {viewCourse && (
        <Link
          className={selectedTab === 'courses' ? 'tab__link active' : 'tab__link'}
          to={`${basePath}/courses`}
        >
          {t('tasks.toggleCourses')}
        </Link>
      )}
      {viewClassroom && (
        <Link
          className={selectedTab === 'classrooms' ? 'tab__link active' : 'tab__link'}
          to={`${basePath}/classrooms`}
        >
          {t('tasks.toggleClass')}
        </Link>
      )}
    </div>
  );
}

export default TabNavBar;
