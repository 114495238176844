import React, { useState, useEffect } from 'react';
import Loader from 'app/components/loader';
import { useClient } from 'jsonapi-react';
import _ from 'lodash';
import { t } from 'i18next';

function QuestionsOptions(props) {
  const client = useClient();
  const { questionCount, randomizeQuestions, taxonomyIds } = props;
  const [filterTaxonomies, setFilterTaxonomies] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (taxonomyIds.length === 0 && filterTaxonomies[0] && filterTaxonomies[0].selected) {
      let newFilterTaxonomies = [...filterTaxonomies];
      newFilterTaxonomies[0].selected = '';
      newFilterTaxonomies.length = 1;
      setFilterTaxonomies(newFilterTaxonomies);
    }
  }, [taxonomyIds]);

  const fetchData = async () => {
    try {
      const { data } = await client.fetch(`taxonomies`);
      setFilterTaxonomies([{ selected: '', collection: data }]);
    } catch (e) {
      console.log(e);
    }
  };
  const handleChange = async (e, index) => {
    if (e.target.value !== '') {
      const taxonomy = _.find(filterTaxonomies[index].collection, {
        id: parseInt(e.target.value)
      });
      const { data } = await client.fetch(`taxonomies/${e.target.value}`);
      let newFilterTaxonomies = [...filterTaxonomies];
      newFilterTaxonomies[index].selected = taxonomy;
      if (newFilterTaxonomies[index + 1]) {
        newFilterTaxonomies[index + 1] = {
          selected: '',
          collection: data['taxonomy-children']
        };
        newFilterTaxonomies.length = index + 2;
      } else {
        newFilterTaxonomies.push({
          selected: '',
          collection: data['taxonomy-children']
        });
      }
      setFilterTaxonomies(newFilterTaxonomies);
      filterQuestions(index);
    }
  };
  const filterQuestions = index => {
    let ids = [];
    filterTaxonomies.forEach((filterTaxonomy, i) => {
      if (i >= index) {
        if (filterTaxonomy.selected) {
          ids.push(filterTaxonomy.selected.id);
        } else {
          filterTaxonomy.collection.map(taxonomy => ids.push(taxonomy.id));
        }
      }
    });
    props.setTaxonomyIds(ids);
  };

  return (
    <React.Fragment>
      <div className="questions-options__filter form">
        {filterTaxonomies.length === 0 ? (
          <Loader />
        ) : (
          filterTaxonomies.map(
            (filterTaxonomy, index) =>
              filterTaxonomy.collection.length > 0 && (
                <select
                  className="form__select"
                  key={index}
                  onChange={e => handleChange(e, index)}
                  value={filterTaxonomy.selected?.id || ''}
                >
                  <option value="">{t('filter.selectSubject')}</option>
                  {_.sortBy(filterTaxonomy.collection, 'name').map(taxonomy => (
                    <option
                      key={taxonomy.id}
                      value={taxonomy.id}
                    >
                      {taxonomy.name}
                    </option>
                  ))}
                </select>
              )
          )
        )}
      </div>
      {questionCount > 0 && (
        <>
          <div className="questions-options__results">
            <b>Todas as questões</b>
            <br />
            {questionCount} questões encontradas
            <div
              className="btn btn--success btn--small"
              onClick={randomizeQuestions}
            >
              Embaralhar questões
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
}

export default QuestionsOptions;
