import React, { useState, useEffect } from 'react';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import { Link } from '@reach/router';
import { useSession } from 'app/hooks/useSession';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import TabNavBar from './TabNavBar';
import { checkIncludeProfiles } from 'app/utils/getAuthorityLevel';
import Error404 from 'app/components/Error404';
import Loader from 'app/components/loader';
import EmptyState from 'app/components/EmptyState';
import Onboarding from 'app/components/Onboarding';
import getPermission from 'app/utils/getPermission';
import ModuleCard from './ModuleCard';
import FilterMultiSelect from 'app/components/FilterMultiSelect';
import { useTranslation } from 'react-i18next';

function LmsStudent(props) {
  const { t } = useTranslation();
  const client = useClient();
  const { session } = useSession();
  const profiles = session?.user?.profileNames || (session?.user && session?.user['profile-names']) || [];
  const path = props.path;
  const basePath = window.env.REACT_APP_BASE_PATH;
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [learningSystems, setLearningSystems] = useState([]);
  const [filteredLearningSystems, setFilteredLearningSystems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [relationships, setRelationships] = useState({
    courses: []
  });
  const authorityLevel = getAuthorityLevel();

  const filters = useFormik({
    initialValues: {
      course: 'blank',
      visibility: {
        started: false,
        notStarted: false,
        published: false,
        notPublished: false
      }
    }
  });

  const getLearningSystems = async () => {
    setLoading(true);
    const { data, error } = await client.fetch('learning_systems');
    if (error) {
      toast.error(t('toast.errorGetCourse'));
    } else {
      setLearningSystems(data);
      setLoading(false);
    }
  };

  const getRelationships = async () => {
    const { data: courses } = await client.fetch('courses');
    setRelationships({
      courses: courses || []
    });
  };

  useEffect(() => {
    getLearningSystems();
    getRelationships();
  }, []);

  useEffect(() => {
    let newLearningSystems = learningSystems;
    if (filters.values.course !== 'blank') {
      const id = +filters.values.course;
      newLearningSystems = filterLMS(id, learningSystems);
    }
    if (filters.values.visibility.started === true) {
      newLearningSystems = newLearningSystems.filter(system => system['learning-system-users'].some(user => user.user_id === session.user.id));
    }
    if (filters.values.visibility.notStarted === true) {
      newLearningSystems = newLearningSystems.filter(system => !system['learning-system-users'].some(user => user.user_id === session.user.id));
    }
    if (filters.values.visibility.published === true) {
      newLearningSystems = newLearningSystems.filter(system => system.publish === true);
    }
    if (filters.values.visibility.notPublished === true) {
      newLearningSystems = newLearningSystems.filter(system => system.publish === false);
    }
    setFilteredLearningSystems(newLearningSystems);
  }, [filters.values, learningSystems]);

  function filterLMS(id, learningSystems) {
    return learningSystems.filter(function (learningSystem) {
      return learningSystem['course-id'] === id;
    });
  }

  return (
    <>
      <main className="main-content main-content--block">
        {checkIncludeProfiles({ only: ['student'] }) && <TabNavBar selectedTab="lms" />}
        <div className="filter-bar filter-bar--left">
          <FilterSelectionBox
            label={t('filter.labelSelectCourse')}
            value={filters.values.course}
            onChange={e => filters.setFieldValue('course', e.target.value)}
            options={relationships.courses.map(o => ({
              id: o.id,
              name: o.title
            }))}
          />

          {authorityLevel === 'student' && (
            <FilterMultiSelect
              data={[
                {
                  label: t('filter.labelInitiates'),
                  value: filters.values.visibility.started,
                  onChange: () => {
                    filters.setFieldValue('visibility', {
                      started: !filters.values.visibility.started,
                      notStarted: false
                    });
                  }
                },
                {
                  label: t('filter.labelUninitiated'),
                  value: filters.values.visibility.notStarted,
                  onChange: () => {
                    filters.setFieldValue('visibility', {
                      started: false,
                      notStarted: !filters.values.visibility.notStarted
                    });
                  }
                }
              ]}
            />
          )}

          {authorityLevel !== 'student' && (
            <FilterMultiSelect
              data={[
                {
                  label: 'Publicado',
                  value: filters.values.visibility.published,
                  onChange: () => {
                    filters.setFieldValue('visibility', {
                      published: !filters.values.visibility.published,
                      notPublished: false
                    });
                  }
                },
                {
                  label: 'Não publicado',
                  value: filters.values.visibility.notStarted,
                  onChange: () => {
                    filters.setFieldValue('visibility', {
                      published: false,
                      notPublished: !filters.values.visibility.notPublished
                    });
                  }
                }
              ]}
            />
          )}

          {getPermission('Criar módulos, disciplinas e aulas', 'Cursos, módulos e aulas') && (
            <>
              <Link
                className="btn btn--primary btn--wide"
                to={`${basePath}/lms/criar/modulo/curso`}
              >
                {t('button.createModule')}
              </Link>
              <Link
                className="btn btn--primary btn--wide"
                to={`${basePath}/lms/gerenciar/modulos`}
              >
                {t('button.manageModules')}
              </Link>
            </>
          )}
        </div>
        <div className="fadeIn">
          {loading && <Loader />}

          {!loading && !learningSystems.length > 0 && (
            <EmptyState
              type="content"
              title={t('emptyState.moduleText')}
            />
          )}
          {!loading && !filteredLearningSystems.length > 0 && (
            <EmptyState
              type="content"
              title={t('emptyState.moduleFilteredText')}
            />
          )}
          <div className="tab__cards">
            {!loading &&
              filteredLearningSystems.length > 0 &&
              filteredLearningSystems.map(ls => (
                <ModuleCard
                  learningSystems={learningSystems}
                  ls={ls}
                  key={ls.id}
                  id={ls.id}
                />
              ))}
          </div>
        </div>
      </main>

      <Onboarding
        path={path}
        show={showOnboarding}
        setShowOnboarding={setShowOnboarding}
        onClose={() => setShowOnboarding(false)}
        profiles={profiles}
        title={t('onboarding.titleLms')}
        videoId="I16j5rMqt4s"
      />
    </>
  );
}

export default LmsStudent;
export * from './CreateModule';
export * from './ViewModule';
export * from './Lesson';
export * from './LessonResources';
export * from './LessonMetrics';
export * from './ModuleCertificate';
export * from './ManageModule';
export * from './CreateLesson';
