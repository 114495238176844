
import { useState } from 'react'
import FlowModal from '../modals/FlowModal'
import EmployeeList from '../modals/EmployeeList'
import Employee from './Employee'
import FlowModalAbsolute from '../modals/FlowModalAbsolute'

const EmployeeContainer = ({ employees = [], openModalEmployees }) => {

  return (
    <>
    {employees.length > 1  ?
      <div className='container--employee' style={{cursor: 'pointer'}} onClick={() => openModalEmployees()}>
        <span style={{height: '25px'}} >{employees.length} colaboradores </span>
      </div>
      :
      <div className='container--employee'> <Employee employeeId={employees[0]} /> </div>}

    </>
  )

}

export default EmployeeContainer