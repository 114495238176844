import React, { useState, useEffect } from 'react';
import { navigate } from '@reach/router';
import StepCourseClassroom from './StepCourseClassroom';
import StepName from './StepName';
import StepImage from './StepImage';
import defaultImage from 'app/images/placeholders/show-course.jpg';
import lampIcon from './img/lamp-icon.svg';
import imageAreaTip from './img/image-area-tip.svg';
import darkeningImageTip from './img/darkening-image-tip.png';
import StepOrganization from './StepOrganization';
import StepParticipants from './StepParticipants';
import StepManagers from './StepManagers';
import CreationGroupsModal from './CreationGroupsModal';
import CreationIndividualModal from './CreationIndividualModal';
import StepCustomParticipants from './StepCustomParticipants';
import CreationCustomModal from './CreationCustomModal';
import { t } from 'i18next';
import { useClient } from 'jsonapi-react';

export default function ProjectContent(props) {
  const { courses, step, steps, currentStep, setCurrentStep, editingPath, form, classrooms, handleSubmit, coursesLoading, isSubmitting } = props;

  const [imageLabel, setImageLabel] = useState();
  const [file, setFile] = useState();
  const [showModal, setShowModal] = useState(false);
  const [creationGroups, setCreationGroups] = useState(false);
  const [creationIndividual, setCreationIndividual] = useState(false);
  const [creationCustom, setCreationCustom] = useState(false);
  const [courseById, setCoursesById] = useState({});
  const [count, setCount] = useState(0);
  const [courseLoading, setCourseLoading] = useState(false);
  const client = useClient();
  const basePath = window.env.REACT_APP_BASE_PATH;

  useEffect(() => {
    form.setFieldValue('classroom', '');

    if (form.values.course) {
      async function loadData() {
        setCourseLoading(true);
        const fetchCourse = await client.fetch(`/courses/${parseInt(form.values.course)}?allow_users=true`);
        setCoursesById(fetchCourse.data);
        setCourseLoading(false);
      }

      loadData();
    }
  }, [form.values.course]);

  function handleSteps(e) {
    e.preventDefault();

    if (step === 'curso-turma' && !editingPath) {
      form.setFieldTouched('course', true);
      if (!form.errors.course) {
        navigate(`${basePath}/projetos/criar/passo/nome`);
        setCurrentStep(2);
      }
      return;
    } else if (step === 'curso-turma' && editingPath) {
      form.setFieldTouched('course', true);
      if (!form.errors.course) {
        navigate(`${basePath}/projetos/criar/passo/nome/${editingPath}`);
        setCurrentStep(2);
      }
    }

    if (step === 'nome' && !editingPath) {
      form.setFieldTouched('title', true);
      form.setFieldTouched('description', true);
      if (!form.errors.title && !form.errors.description) {
        navigate(`${basePath}/projetos/criar/passo/imagem`);
        setCurrentStep(3);
      }
      return;
    } else if (step === 'nome' && editingPath) {
      form.setFieldTouched('title', true);
      form.setFieldTouched('description', true);
      if (!form.errors.title && !form.errors.description) {
        navigate(`${basePath}/projetos/criar/passo/imagem/${editingPath}`);
        setCurrentStep(3);
      }
    }

    if (step === 'imagem' && !editingPath) {
      form.setFieldTouched('image', true);
      navigate(`${basePath}/projetos/criar/passo/organizacao`);
      setCurrentStep(4);
    } else if (step === 'imagem' && editingPath) {
      form.setFieldTouched('image', true);
      navigate(`${basePath}/projetos/criar/passo/organizacao/${editingPath}`);
      setCurrentStep(4);
    }

    if (step === 'organizacao' && !editingPath) {
      form.setFieldTouched('organization', true);
      if (form.values.organization === 'group') {
        navigate(`${basePath}/projetos/criar/passo/participantes`);
        setCurrentStep(5);
      } else if (form.values.organization === 'individual') {
        setCreationIndividual(true);
      } else if (form.values.organization === 'custom') {
        navigate(`${basePath}/projetos/criar/passo/personalizado-participantes`);
        setCurrentStep(5);
      } else {
        return;
      }
    } else if (step === 'organizacao' && editingPath) {
      form.setFieldTouched('organization', true);
      if (form.values.organization === 'group') {
        navigate(`${basePath}/projetos/criar/passo/participantes/${editingPath}`);
        setCurrentStep(5);
      } else if (form.values.organization === 'individual') {
        setCreationIndividual(true);
      } else if (form.values.organization === 'custom') {
        navigate(`${basePath}/projetos/criar/passo/personalizado-participantes/${editingPath}`);
        setCurrentStep(5);
      } else {
        return;
      }
    }

    if (step === 'participantes' && !editingPath) {
      form.setFieldTouched('groupSize', true);
      if (!form.errors.groupSize) {
        navigate(`${basePath}/projetos/criar/passo/gestores`);
        setCurrentStep(6);
      }
      return;
    } else if (step === 'participantes' && editingPath) {
      form.setFieldTouched('groupSize', true);
      if (!form.errors.groupSize) {
        navigate(`${basePath}/projetos/criar/passo/gestores/${editingPath}`);
        setCurrentStep(6);
      }
      return;
    }

    if (step === 'personalizado-participantes') {
      form.setFieldTouched('customParticipants', true);
      if (!form.errors.customParticipants) {
        setCreationCustom(true);
      }
      return;
    }

    if (step === 'gestores') {
      form.setFieldTouched('managers', true);
      if (!form.errors.managers) {
        handleSubmit();
      }
      return;
    }
  }

  const handleChangeImage = e => {
    let file = e.target.files[0];
    setImageLabel(e.target.files[0].name);
    setFile(URL.createObjectURL(e.target.files[0]));

    if (file) {
      const reader = new FileReader();
      reader.onload = _handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  };

  const _handleReaderLoaded = readerEvt => {
    let bynaryString = readerEvt.target.result;
    form.setFieldValue('image', `data:image/jpeg;base64,${btoa(bynaryString)}`);
  };

  return (
    <div className="page page--wrap">
      <div className="page__col">
        <form
          className="form"
          method="post"
          onSubmit={handleSteps}
        >
          {(step === 'curso-turma' || step === 'nome') && (
            <div className="page__content fadeIn u-mb-5">
              <div className="new-questions__header">
                <h2 className="new-questions__title">{form.values.title || t('projects.newProjects')}</h2>
              </div>

              <StepCourseClassroom
                courseLoading={courseLoading}
                courses={courses}
                classrooms={classrooms}
                form={form}
                coursesLoading={coursesLoading}
              />

              {step === 'nome' && <StepName form={form} />}

              <span className="form__text u-mt-3">
                <strong>* {t('warning.requiredField')}</strong>
              </span>
            </div>
          )}

          {step === 'imagem' && (
            <StepImage
              form={form}
              handleChangeImage={handleChangeImage}
              imageLabel={imageLabel}
              file={file}
              defaultImage={defaultImage}
              selectedCourse={courseById}
              showModal={showModal}
              setShowModal={setShowModal}
            />
          )}

          {step === 'organizacao' && <StepOrganization form={form} />}

          {step === 'participantes' && (
            <StepParticipants
              setCount={setCount}
              form={form}
              selectedCourse={courseById}
            />
          )}

          {step === 'gestores' && <StepManagers form={form} />}

          {step === 'personalizado-participantes' && (
            <StepCustomParticipants
              form={form}
              selectedCourse={courseById}
            />
          )}

          {step !== 'curso-turma' && (
            <button
              type="button"
              className="btn btn--outline btn--wide u-mr-4"
              onClick={() => {
                if (editingPath) {
                  navigate(`${basePath}/projetos/criar/passo/${steps[currentStep - 2].route}/${editingPath}`);
                  setCurrentStep(currentStep - 1);
                } else {
                  navigate(`${basePath}/projetos/criar/passo/${steps[currentStep - 2].route}`);
                  setCurrentStep(currentStep - 1);
                }
              }}
            >
              {t('exams.previous')}
            </button>
          )}

          <button
            type="submit"
            className={isSubmitting ? 'btn btn--primary btn--wide disabled' : 'btn btn--primary btn--wide'}
          >
            {step === 'gestores' ? t('button.finish') : t('button.next')}
          </button>
        </form>
      </div>

      <CreationGroupsModal
        show={creationGroups}
        onClose={() => setCreationGroups(false)}
        handleSubmit={handleSubmit}
        form={form}
      />
      <CreationIndividualModal
        show={creationIndividual}
        onClose={() => setCreationIndividual(false)}
        form={form}
        selectedCourse={courseById}
        handleSubmit={handleSubmit}
      />
      <CreationCustomModal
        show={creationCustom}
        onClose={() => setCreationCustom(false)}
        form={form}
        handleSubmit={handleSubmit}
      />
    </div>
  );
}
