import React, { useState, useEffect } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import EmptyState from 'app/components/EmptyState';
import Modal from 'app/components/Modal';
import DisciplineCard from './DisciplineCard';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import Loader from 'app/components/loader';
import { checkIncludeProfiles, checkIncludeAppName } from '../../../utils/getAuthorityLevel';
import Error404 from 'app/components/Error404';
import getPermission from 'app/utils/getPermission';
import { useTranslation } from 'react-i18next';

export function ViewModule(props) {
  const { t } = useTranslation();
  const { id } = props;
  const client = useClient();
  const [showModal, setShowModal] = useState(false);
  const [module, setModule] = useState();
  const [listDiscipline, setListDiscipline] = useState([]);
  const [disciplineName, setDisciplineName] = useState();
  const [disciplineOrder, setDisciplineOrder] = useState();
  const [editItemSelected, setEditItemSelected] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    getModule();
    getDiscipline();
  }, []);

  useEffect(() => {
    if (editItemSelected) {
      setShowModal(true);
      setDisciplineName(editItemSelected?.body);
      setDisciplineOrder(editItemSelected?.order);
    }
  }, [editItemSelected]);

  const getModule = async () => {
    const { data, error } = await client.fetch(['learning_systems', id]);
    if (error) {
      toast.error('Erro ao carregar o módulo');
    } else {
      setModule(data);
    }
  };

  const getDiscipline = async () => {
    setLoading(true);
    const { data, error } = await client.fetch([`/learning_system_contents?filter[learning_system_id]=${id}`]);
    setLoading(false);

    if (error) {
      toast.error(t('toast.errorCreateDiscipline'));
    } else {
      setListDiscipline(data);
    }
  };

  const requestCreateDiscipline = {
    body: disciplineName,
    'learning-system-id': id,
    order: disciplineOrder
  };

  const createDiscipline = async () => {
    setLoading(true);
    const { data, error } = await client.mutate('learning_system_contents', requestCreateDiscipline);
    setLoading(false);

    if (error) {
      toast.error('Erro ao adicionar disciplina');
    } else {
      toast.success(t('toast.successCreateDiscipline'));
      getDiscipline();
    }
  };

  const deleteDiscipline = async id => {
    setLoading(true);
    const { data, error } = await client.delete([`learning_system_contents/${id}`]);
    setLoading(false);

    if (error) {
      console.error(e);
      toast.error(t('toast.errorDeleteDiscipline'));
    } else {
      toast.success(t('toast.successDeleteDiscipline'));
      setListDiscipline(listDiscipline.filter(discipline => discipline.id !== id));
    }
  };

  const editDiscipline = async () => {
    setLoading(true);
    const { data, error } = await client.mutate(`learning_system_contents/${editItemSelected.id}`, requestCreateDiscipline);
    setLoading(false);

    if (error) {
      console.error(e);
      toast.error('Erro ao editar disciplina.');
    } else {
      toast.success(t('toast.successEditDiscipline'));
      getDiscipline();
    }
  };

  return (
    <>
      {getPermission('Gerenciar conteúdo', 'Cursos, módulos e aulas') ||
      getPermission('Criar módulos, disciplinas e aulas', 'Cursos, módulos e aulas') ||
      getPermission('Editar módulos, disciplinas e aulas', 'Cursos, módulos e aulas') ||
      getPermission('Excluir módulos, disciplinas e aulas', 'Cursos, módulos e aulas') ? (
        <>
          <main className="main-content main-content--block">
            <div className="filter-bar">
              <button
                onClick={() => history.back()}
                className="filter-bar__back"
              >
                <FiChevronLeft />

                <span className="filter-bar__back-container">
                  <span className="filter-bar__title">
                    {t('textsCommon.module')}: {module?.title}
                  </span>
                </span>
              </button>

              <button
                className="btn btn--primary btn--wide u-ml-auto"
                onClick={() => {
                  setDisciplineName('');
                  setShowModal(true);
                  setDisciplineOrder(null);
                }}
              >
                {t('button.createDiscipline')}
              </button>
            </div>

            {loading ? (
              <Loader />
            ) : (
              <>
                {listDiscipline.length === 0 ? (
                  <EmptyState
                    type="select"
                    title={t('button.createDiscipline')}
                    text="Comece a estruturar o módulo adicionando disciplinas e em cada disciplina, adicione aulas."
                  />
                ) : (
                  <>
                    <p>
                      Para visualizar os conteúdos de uma disciplina, clique em <strong>acessar</strong>
                    </p>

                    <div className="tab__cards">
                      {listDiscipline.map((item, index) => (
                        <DisciplineCard
                          moduleId={id}
                          deleteDiscipline={deleteDiscipline}
                          setEditItemSelected={setEditItemSelected}
                          item={item}
                          hasNewMessage={item['has-new-message']}
                        />
                      ))}
                    </div>
                  </>
                )}
              </>
            )}
          </main>

          <Modal
            show={showModal}
            onClose={() => {
              setShowModal(false);
              setEditItemSelected(undefined);
            }}
          >
            <form
              method="post"
              className="form"
              onSubmit={e => {
                e.preventDefault();
                setShowModal(false);
                setEditItemSelected(undefined);
                editItemSelected ? editDiscipline() : createDiscipline();
              }}
            >
              <h2 className="form__title">{editItemSelected ? 'Editar disciplina' : 'Criar nova disciplina'}</h2>

              <div className="form__row">
                <label
                  className="form__label"
                  htmlFor="disciplineName"
                >
                  {t('textsCommon.disciplineName')}
                </label>
                <input
                  className="form__control"
                  id="disciplineName"
                  name="disciplineName"
                  type="text"
                  placeholder="Digite o nome da disciplina"
                  onChange={e => setDisciplineName(e.target.value)}
                  value={disciplineName}
                />
              </div>

              <div className="form__row">
                <label
                  className="form__label"
                  htmlFor="disciplineName"
                >
                  Ordem da disciplina
                </label>
                <input
                  className="form__control"
                  id="disciplineOrder"
                  name="disciplineOrder"
                  type="number"
                  min={0}
                  placeholder="Ordem da disciplina"
                  onChange={e => setDisciplineOrder(e.target.value)}
                  value={disciplineOrder}
                />
              </div>

              <button
                className="btn btn--primary"
                type="submit"
              >
                {t('button.saveContinue')}
              </button>
            </form>
          </Modal>
        </>
      ) : (
        <Error404 />
      )}
    </>
  );
}
