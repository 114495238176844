import React, { useState, useEffect } from 'react';
import ExamSidebar from './CourseSidebar';
import CourseContent from './CourseContent';
import { t } from 'i18next';
import { i18n } from 'app/components/i18n/i18n';

export function CreateCourse(props) {
  const { step } = props;

  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    i18n.changeLanguage(window.env.REACT_APP_LOCALE);
  }, []);

  const steps = [
    {
      id: 0,
      route: 'nome',
      text: t('courses.routeName')
    },
    {
      id: 1,
      route: 'sobre',
      text: t('courses.routeAbout')
    },
    {
      id: 2,
      route: 'imagem',
      text: t('courses.routeImage')
    }
  ];

  function getStep() {
    switch (step) {
      case 'nome':
        setCurrentStep(0);
        break;
      case 'sobre':
        setCurrentStep(1);
        break;
      case 'imagem':
        setCurrentStep(2);
        break;
      default:
        setCurrentStep(0);
        break;
    }
  }

  useEffect(() => getStep());

  return (
    <div className="main-screen">
      <ExamSidebar
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        steps={steps}
      />

      <main className="main-content">
        <CourseContent
          step={step}
          steps={steps}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      </main>
    </div>
  );
}

export default CreateCourse;
