import React from 'react';
import { Link } from '@reach/router';
import { FiChevronDown, FiMoreHorizontal } from 'react-icons/fi';
import { AvatarStack } from 'app/components/Avatar';
import Dropdown from 'app/components/Dropdown';
import { useSession } from 'app/hooks/useSession';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import moment from 'moment';
import getPermission from 'app/utils/getPermission';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { i18n } from 'app/components/i18n/i18n';

export default function ClassroomCard(props) {
  const { t } = useTranslation();
  const { classroom, editClassroomForm, participants, deleteClassroom } = props;
  const basePath = window.env.REACT_APP_BASE_PATH;

  return (
    <div className="card">
      <div className="card__header">
        <div className="card__inner-header">
          <h3
            className="card__title"
            title={classroom?.title}
          >
            {classroom?.title}
          </h3>
        </div>

        <p className="expanded-calendar__tag expanded-calendar__tag--course">{classroom?.course?.title}</p>
      </div>

      <div className="card__body">
        <div className="card__columns">
          <p className="card__info">
            <span>{t('textsCommon.availableUntil')}:</span> {moment(classroom && classroom['end-date']).format(t('datesForm.formatDate'))}
          </p>
        </div>

        <div className="card__columns">
          <div className="card__avatar">
            <h4 className="card__avatar-title">{t('projects.participants')}</h4>
            {(participants || []).length > 0 && (
              <AvatarStack
                data={participants}
                modalTitle={`Participantes da Turma ${classroom.title}`}
                className="avatar--xs"
              />
            )}
          </div>
        </div>
      </div>

      <div className="card__footer">
        <div
          className="btn-group btn-group--with-dropdown"
          role="group"
          aria-label="Ações"
        >
          {getPermission('Gerenciar turmas', 'Turmas') && (
            <Link
              to={`${basePath}/classrooms/${classroom.id}/gerenciar`}
              type="button"
              className="btn btn--outline btn--small"
            >
              {t('sideBar.participants')}
            </Link>
          )}

          {getPermission('Editar turmas', 'Turmas') || getPermission('Excluir turmas', 'Turmas') ? (
            <Dropdown className="btn--small btn btn--outline">
              <Dropdown.Toggle>
                <FiChevronDown />
              </Dropdown.Toggle>

              <Dropdown.Content>
                {getPermission('Editar turmas', 'Turmas') && <Dropdown.Item onClick={() => editClassroomForm(classroom)}>{t('button.edit')}</Dropdown.Item>}
                {getPermission('Excluir turmas', 'Turmas') && (
                  <Dropdown.Item
                    onClick={() => {
                      Swal.fire({
                        title: t('projects.warningDeleteClass'),
                        text: t('projects.warningDeleteCannotBeUndoneText'),
                        confirmButtonText: t('button.yes'),
                        showDenyButton: true,
                        denyButtonText: t('button.no'),
                        showCloseButton: true
                      }).then(res => {
                        if (res.isConfirmed) {
                          deleteClassroom(classroom);
                        }
                      });
                    }}
                  >
                    {t('button.delete')}
                  </Dropdown.Item>
                )}
              </Dropdown.Content>
            </Dropdown>
          ) : null}
        </div>
      </div>
    </div>
  );
}
