import { Link, useParams } from '@reach/router';
import React from 'react';
import { FiClipboard, FiEdit3, FiTarget } from 'react-icons/fi';
import { useState, useEffect } from 'react';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import moment from 'moment';
import getIcon from 'app/helpers/getIcon';
import { t } from 'i18next';
import { useNotification } from 'app/hooks/useNotification';

export default function ImportantLog() {
  const { getNotificationsImportant, setNotificationsImportant, notificationsImportant } = useNotification();
  const client = useClient();
  const courseId = useParams().id;
  const basePath = window.env.REACT_APP_BASE_PATH;

  useEffect(() => {
    getNotificationsImportant(courseId);
  }, [courseId]);

  const handleNotificationRead = async notification => {
    await client.mutate(['notifications', notification.id], { unread: false });

    setNotificationsImportant(notificationsImportant);
  };

  return (
    notificationsImportant.length > 0 && (
      <ul className="activity-log u-mb-4">
        <li className="activity-log__header">
          <h2 className="activity-log__header-title">{t('dashboard.important')}</h2>
          <p className="activity-log__count">
            <strong>{notificationsImportant?.filter(item => item.unread === true).length}</strong> {t('dashboard.unreadNotifications')}
          </p>
        </li>

        {notificationsImportant?.map(notification => {
          return (
            <li
              key={notification.id}
              className={`activity-log__item ${notification.unread ? '' : 'is-read'}`}
            >
              <div className="activity-log__icon activity-log__icon--danger">{getIcon(notification.icon)}</div>
              <time className="activity-log__datetime">{moment(notification?.['created-at']).format('DD/MM/YYYY')}</time>
              <h3 className="activity-log__title">
                {notification.name} <span className="activity-log__tag activity-log__tag--class">{notification.tag}</span>
              </h3>
              <p className="activity-log__description">
                {notification.body &&
                  notification.body.split('*f6$').map((body, index) => {
                    if (body[0] == '>') {
                      return (
                        <Link
                          to={`${basePath}${notification.url}` || '/'}
                          className="activity-log__link"
                          onClick={() => handleNotificationRead(notification)}
                          key={index}
                        >
                          {body.substring(1, body.lenght)}
                        </Link>
                      );
                    } else {
                      return body;
                    }
                  })}
              </p>
            </li>
          );
        })}
      </ul>
    )
  );
}
