import { useTranslation } from 'react-i18next';

import { TopBar } from './TopBar';
import { ShadowContainer } from './ShadowContainer';

import generalViewPng from '../../images/onboarding/journey/general-view.png';
import metricsPng from '../../images/onboarding/journey/metrics.png';
import engagementMetricsPng from '../../images/onboarding/journey/engagement-metrics.png';
import ongoingCoursesPng from '../../images/onboarding/journey/ongoing-courses.png';
import averagePng from '../../images/onboarding/journey/average.png';
import tasksSolution1Png from '../../images/onboarding/journey/tasks-solution-1.png';
import tasksSolution2Png from '../../images/onboarding/journey/tasks-solution-2.png';
import subjectPng from '../../images/onboarding/journey/subject.png';

export function JourneyOnboarding() {
  const { t } = useTranslation();

  return (
    <section>
      <TopBar />

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.overviewProfile')}</h1>
        <p className="text-option-onboarding">{t('onboarding.overviewProfileDesc')}</p>

        <img src={generalViewPng} />
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.generalMetrics')}</h1>
        <p className="text-option-onboarding">{t('onboarding.generalMetricsDesc')}</p>

        <ShadowContainer width="70%">
          <img src={metricsPng} />
        </ShadowContainer>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.engagementMetrics')}</h1>
        <p className="text-option-onboarding">{t('onboarding.engagementMetricsDesc')}</p>

        <ShadowContainer width="70%">
          <img src={engagementMetricsPng} />
        </ShadowContainer>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.progressModules')}</h1>
        <p className="text-option-onboarding">{t('onboarding.progressModulesDesc')}</p>

        <img
          src={ongoingCoursesPng}
          className="w-70"
        />
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.movingAverage')}</h1>
        <p className="text-option-onboarding">{t('onboarding.movingAverageDesc')}</p>

        <ShadowContainer width="70%">
          <img src={averagePng} />
        </ShadowContainer>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.tasksSolution')}</h1>
        <p className="text-option-onboarding">{t('onboarding.tasksSolutionDesc')}</p>

        <div className="container-shadow">
          <ShadowContainer width="40%">
            <img src={tasksSolution1Png} />
          </ShadowContainer>

          <ShadowContainer width="40%">
            <img src={tasksSolution2Png} />
          </ShadowContainer>
        </div>
      </div>

      <div>
        <h1 className="title-option-onboarding">{t('onboarding.incomeBySubject')}</h1>
        <p className="text-option-onboarding">{t('onboarding.incomeBySubjectDesc')}</p>

        <ShadowContainer>
          <img src={subjectPng} />
        </ShadowContainer>
      </div>
    </section>
  );
}
