import React from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { Link } from '@reach/router';
import { t } from 'i18next';
import Dropdown from 'app/components/Dropdown';
import getPermission from 'app/utils/getPermission';
import moment from 'moment';

export default function EssayCard(props) {
  const { essay, isAdmin, deleteEssay } = props;
  const basePath = window.env.REACT_APP_BASE_PATH;

  const statusBadgeColor = {
    accepting_proposal: 'badge--warning',
    close_for_submissions: 'badge--danger',
    processing: 'badge--warning',
    unreviewed: 'badge--warning',
    reviewed: 'badge--success',
    annulled: 'badge--danger',
    minimum_length_not_satisfied: 'badge--danger',
    failed_to_process: 'badge--danger'
  };

  const statusBadgeText = {
    accepting_proposal: 'Aceitando respostas',
    close_for_submissions: 'Prazo encerrado',
    processing: 'Em correção',
    unreviewed: 'Em correção',
    reviewed: 'Corrigida',
    annulled: 'Anulada',
    minimum_length_not_satisfied: 'Arquivo inválido',
    failed_to_process: 'Falha ao processar'
  };

  const badgeColor = statusBadgeColor[essay?.status] ?? 'badge--success';

  return (
    <div className="card">
      <div className="card__header">
        <div className="card__inner-header u-mb-1">
          <h2
            className="card__title"
            title={essay?.topic}
          >
            {essay?.topic.length > 50 ? essay?.topic.slice(0, 50) + '...' : essay?.topic}
          </h2>
        </div>

        <span className={`badge badge--tiny ${badgeColor}`}>{essay?.status === 'reviewed' ? `${statusBadgeText[essay?.status]} ${essay?.['essay-student']?.grade}/1000` : statusBadgeText[essay?.status]}</span>
      </div>

      <div className="card__body">
        {essay?.status === 'Corrigida' ? (
          <div className="card__columns">
            <p className="card__count">
              <span className="u-w-100">{'Entrega até'}</span>
              {moment(essay?.['delivery-date']).format('DD/MM/YYYY')}
            </p>
            <p className="card__count">
              <span className="u-w-100">{'Realizadas'}</span>
              {essay?.['submissions-count']}
            </p>
          </div>
        ) : (
          <p className="card__count">
            <span className="u-w-100">{'Entrega até'}</span>
            {moment(essay?.['delivery-date']).format('DD/MM/YYYY')}
          </p>
        )}
      </div>

      <div className="card__footer">
        {isAdmin ? (
          <div
            className="btn-group btn-group--with-dropdown"
            role="group"
            aria-label="Ações"
          >
            <Link
              to={`${basePath}/redacao/acompanhamento/${essay?.id}`}
              className="btn btn--outline btn--small"
            >
              {t('button.access')}
            </Link>
            {getPermission('Excluir redação', 'Redação') || getPermission('Editar redação', 'Redação') ? (
              <Dropdown className="btn--small btn btn--outline">
                <Dropdown.Toggle>
                  <FiChevronDown />
                </Dropdown.Toggle>

                <Dropdown.Content>
                  {getPermission('Editar redação', 'Redação') && <Dropdown.Item to={`${basePath}/redacao/editar/curso/${essay?.id}`}>{t('button.edit')}</Dropdown.Item>}
                  {getPermission('Excluir redação', 'Redação') && <Dropdown.Item onClick={() => deleteEssay(essay?.id)}>{t('button.delete')}</Dropdown.Item>}
                </Dropdown.Content>
              </Dropdown>
            ) : null}
          </div>
        ) : essay?.status === 'reviewed' || essay.status === 'annulled' ? (
          <Link
            to={`${basePath}/redacao/consulta/${essay?.id}/${essay?.['essay-student']?.id}`}
            className="btn btn--outline btn--small"
          >
            Acessar correção
          </Link>
        ) : essay?.status === 'close_for_submissions' ? null : (
          <Link
            to={`${basePath}/redacao/sobre/${essay?.id}`}
            className={`btn btn--outline btn--small ${essay?.status === 'Em correção' && 'disabled'}`}
          >
            Acessar redação
          </Link>
        )}
      </div>
    </div>
  );
}
