import React, { useState } from 'react';
import { useFormik } from 'formik';
import { t } from 'i18next';
import { navigate } from '@reach/router';
import { FiTrash2 } from 'react-icons/fi';
import EssaySendTips from './EssaySendTips';
import promiseRequest from 'app/utils/promiseToast';

export default function EssaySendContent({ essayId }) {
  const [imageLabel, setImageLabel] = useState();
  const { promiseToast, isSubmitting } = promiseRequest();
  const basePath = window.env.REACT_APP_BASE_PATH;
  const essaySendForm = useFormik({
    initialValues: {
      image: ''
    },

    onSubmit: () => handleSubmit
  });

  const handleChangeImage = e => {
    let file = e.target.files[0];
    setImageLabel(URL.createObjectURL(file));

    if (file) {
      const reader = new FileReader();
      reader.onload = _handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  };

  const _handleReaderLoaded = readerEvt => {
    let bynaryString = readerEvt.target.result;
    essaySendForm.setFieldValue('image', `data:image/jpeg;base64,${btoa(bynaryString)}`);
  };

  const sendEssay = async () => {
    promiseToast({
      url: `/essays/${essayId}/essay_students`,
      request: { essay_image: essaySendForm.values.image },
      successText: 'Redação enviada com sucesso',
      errorText: 'Erro ao enviar redação',
      onSuccess: () => navigate(`${basePath}/redacao/sobre/${essayId}`)
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    sendEssay();
  };

  const btnsStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '24px'
  };

  return (
    <div className="page page--wrap">
      <div className="page__col">
        <form
          className="form form--step-by-step"
          method="post"
          onSubmit={handleSubmit}
        >
          <div className="page__content fadeIn u-mb-5">
            <div className="new-questions__header">
              <h2 className="new-questions__title">Nova redação</h2>
            </div>

            <div
              style={{ display: 'flex', gap: 20 }}
              className="u-mb-4"
            >
              <label className="form__custom-file-input u-d-inline-block">
                <input
                  type="file"
                  name="image"
                  id="image"
                  accept=".jpeg, .png, .jpg"
                  onChange={handleChangeImage}
                />
                <span className={`btn btn--outline btn--wide ${imageLabel && 'disabled'}`}>Enviar redação</span>
              </label>

              <div className="instruction-widget__footer">
                <a
                  href={basePath+'/redação.pdf'}
                  download={basePath+'/redação.pdf'}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn--outline btn--wide"
                >
                  Baixar folha
                </a>
              </div>
            </div>

            {imageLabel && (
              <div className="skill-evaluation__comment-item">
                <img
                  src={imageLabel}
                  alt="imagem da redação"
                />

                <FiTrash2
                  className="skill-evaluation__icon skill-evaluation__icon--danger"
                  onClick={() => essaySendForm.setFieldValue('image', null)}
                />
              </div>
            )}
          </div>

          <div style={btnsStyles}>
            <button
              disabled={isSubmitting}
              type="submit"
              className="btn btn--primary btn--wide"
            >
              {t('button.finish')}
            </button>
          </div>
        </form>
      </div>

      <EssaySendTips essayId={essayId} />
    </div>
  );
}
