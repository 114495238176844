import { useLocation, useParams } from '@reach/router';
import { useClient } from 'jsonapi-react';
import React, { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import toast from 'react-hot-toast';
import Content from './Content';
import DisciplinesList from './DisciplinesList';
import Sidebar from './Sidebar';

export default function ManageModule(props) {
  const [disciplines, setDisciplines] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [selectedDiscipline, setSelectedDiscipline] = useState();
  const [selectedModule, setSelectedModule] = useState();
  const [key, setKey] = useState(1);
  const [publish, setPublish] = useState(null);
  const [loading, setLoading] = useState(true);
  const [manageModules, setManageModules] = useState(false);
  const { path } = props;
  const location = useLocation();
  const [moduleId, setModuleId] = useState();

  useEffect(() => {
    if (path === '/lms/gerenciar/modulos') {
      setManageModules(true);
    } else {
      setManageModules(false);
    }
    if (path === '/lms/gerenciar/modulo/:moduleId/:disciplineId') {
      setModuleId(location.state.moduleId);
    } else {
      setModuleId(props.id);
    }
  }, [props, location.state]);

  const client = useClient();

  const getDisciplines = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`/learning_system_contents?filter[learning_system_id]=${selectedModule?.id}`);
    if (error) {
      toast.error('Falha ao carregar disciplinas');
    } else {
      setDisciplines(data);
      setLessons([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (path === '/lms/gerenciar/modulo/:moduleId/:disciplineId' && disciplines.length > 0) {
      const disciplineTeste = disciplines.find(e => e.id === location.state.disciplineId);
      setSelectedDiscipline(disciplineTeste);
    }
  }, [path, disciplines]);

  const getLessons = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`/learning_system_items?filter[learning_system_content_id]=${selectedDiscipline?.id}`);
    if (error) {
      toast.error('Falha ao carregar aulas');
    } else {
      setLessons(data);
      setLoading(false);
    }
  };

  const getPublish = async () => {
    const { data, error } = await client.fetch(`learning_systems/${selectedModule?.id}`);
    if (error) {
      toast.error('Erro ao buscar informações sobre o módulo');
    } else {
      setPublish(data);
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const updateDisciplineList = async values => {
    setLoading(true);
    const { destination, source } = values;
    if (!destination) {
      return null;
    }
    if (destination.index === source.index) {
      return null;
    }

    const dIndex = destination.index;
    const sIndex = source.index;
    const newItems = reorder(disciplines, sIndex, dIndex);

    const newDisciplinesOrder = newItems.map((discipline, index) => {
      return { id: discipline.id, order: index };
    });

    const { error } = await client.mutate(
      ['learning_systems', props.id, 'sort'],
      {
        ['learning-system-contents']: newDisciplinesOrder
      },
      { method: 'PUT' }
    );
    if (error) {
      toast.error('Erro ao reordenar disciplinas!');
    } else {
      getDisciplines();
      toast.success(t('toast.successReorderDisciplines'));
    }
  };

  const updateLessonList = async values => {
    setLoading(true);
    const { destination, source } = values;
    if (!destination) {
      return null;
    }
    if (destination.index === source.index) {
      return null;
    }

    const dIndex = destination.index;
    const sIndex = source.index;
    const newItems = reorder(lessons, sIndex, dIndex);

    const newLessonsOrder = newItems.map((lesson, index) => {
      return { id: lesson.id, order: index };
    });

    const { error } = await client.mutate(
      ['learning_system_contents', selectedDiscipline.id, 'sort'],
      {
        ['learning-system-items']: newLessonsOrder
      },
      { method: 'PUT' }
    );
    if (error) {
      toast.error('Erro ao reordenar as Aulas!');
    } else {
      getLessons();
      toast.success(t('toast.successReorderLessons'));
    }
  };

  const deleteDiscipline = async id => {
    const { error } = await client.delete(`learning_system_contents/${id}`);
    if (error) {
      toast.error(t('toast.errorDeleteDiscipline'));
    } else {
      setDisciplines(disciplines.filter(e => e.id !== id));
      toast.success(t('toast.successDeleteDiscipline'));
    }
  };

  useEffect(() => {
    if (selectedModule) {
      getDisciplines();
      getPublish();
    }
  }, [props.id, selectedModule, key]);

  useEffect(() => {
    if (selectedDiscipline) {
      getLessons();
    }
  }, [props.id, selectedDiscipline, key]);

  const onSuccess = () => {
    setKey(key + 1);
  };

  return (
    <>
      <Sidebar
        moduleId={moduleId}
        setSelectedModule={setSelectedModule}
        selectedModule={selectedModule}
        setManageModules={setManageModules}
        loading={loading}
        setModuleId={setModuleId}
      />

      <main className="main-content u-p-0">
        <div className="new-questions">
          <DragDropContext onDragEnd={values => updateDisciplineList(values)}>
            <DisciplinesList
              moduleId={moduleId}
              onSuccess={onSuccess}
              disciplines={disciplines}
              setDisciplines={setDisciplines}
              selectedDiscipline={selectedDiscipline}
              setSelectedDiscipline={setSelectedDiscipline}
              getDisciplines={getDisciplines}
              deleteDiscipline={deleteDiscipline}
              loading={loading}
              selectedModule={selectedModule}
              manageModules={manageModules}
              path={path}
              disciplineId={location.state.disciplineId}
            />
          </DragDropContext>

          <DragDropContext onDragEnd={values => updateLessonList(values)}>
            <Content
              lessons={lessons}
              selectedModule={selectedModule}
              selectedDiscipline={selectedDiscipline}
              disciplines={disciplines}
              publish={publish}
              loading={loading}
              learningSystemId={selectedModule?.id}
              path={props.path}
              manageModules={manageModules}
              getLessons={getLessons}
            />
          </DragDropContext>
        </div>
      </main>
    </>
  );
}
