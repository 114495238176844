import { useClient } from 'jsonapi-react';
import moment from 'moment';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { FiChevronLeft } from 'react-icons/fi';
import trashIcon from 'app/images/icons/trash-icon.svg';
import Loader from 'app/components/loader';
import { Link } from '@reach/router';
import Swal from 'sweetalert2';
import { useSession } from 'app/hooks/useSession';
import { useTranslation } from 'react-i18next';
import promiseRequest from 'app/utils/promiseToast';

export default function ForumTab(props) {
  const { t } = useTranslation();
  const { lessonId, showListLessons } = props;
  const { promiseToast, isSubmitting } = promiseRequest();
  const [commentsList, setCommentsList] = useState([]);
  const [comment, setComment] = useState('');
  const client = useClient();
  const [key, setKey] = useState(1);
  const { session } = useSession();
  const basePath = window.env.REACT_APP_BASE_PATH;

  const onSuccess = () => {
    setKey(key + 1);
    setComment('');
  };

  const addComment = async () => {
    const requestParams = {
      body: comment,
      'learning-system-item-id': lessonId
    };

    promiseToast({
      url: '/comments',
      request: requestParams,
      onSuccess,
      successText: 'Comentário enviado!',
      errorText: 'Erro ao salvar Módulo!'
    });
  };

  const onKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      addComment();
    }
  };

  const getComments = async () => {
    setLoading(true);

    const { data, error } = await client.fetch(`/comments?filter[learning_system_item]=${lessonId}`);

    setLoading(false);

    if (error) {
      toast.error(t('toast.errorGetComment'));
    } else {
      setCommentsList(data);
    }
  };

  const deleteComment = async id => {
    const { error } = await client.delete(['comments', id]);

    if (error) {
      toast.error(t('toast.errorDeleteComment'));
    } else {
      toast.success(t('toast.successCreateComment'));
      getComments();
    }
  };

  const handleDelete = id => {
    Swal.fire({
      title: t('projects.warningDeleteCommentTitle'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteComment(id);
      }
    });
  };

  useEffect(() => {
    getComments();
  }, [key]);

  const currentLesson = showListLessons.filter(item => item.id == lessonId);
  return (
    <>
      <div className="filter-bar">
        <button
          onClick={() => history.back()}
          className="filter-bar__back"
        >
          <FiChevronLeft />

          <span className="filter-bar__back-container">
            <span className="filter-bar__title">{currentLesson.map(item => item.body)}</span>
          </span>
        </button>
      </div>

      <div className="fadeIn">
        <div className="new-questions__col">
          <div className="post">
            <div
              className="post__comments"
              style={{ borderTop: '0', marginTop: '0' }}
            >
              {isSubmitting ? (
                <Loader />
              ) : (
                <>
                  {commentsList.map(item => {
                    return (
                      <div className="post-comment">
                        <Link
                          to={`${basePath}/usuario/metricas/${item.user.id}`}
                          state={{ isRedirected: true }}
                        >
                          <div
                            className="post-comment__avatar"
                            style={{
                              backgroundImage: `url(${item.user.image.url})`
                            }}
                          />
                        </Link>

                        <div className="post-comment__body">
                          <div className="post-comment__header">
                            <div className="post-comment__header-name">
                              <Link
                                to={`${basePath}/usuario/metricas/${item.user.id}`}
                                state={{ isRedirected: true }}
                              >
                                {item.user.name}
                              </Link>
                            </div>
                            <div className="post-comment__header-time">{moment(item['created-at']).format(t('datesForm.formatDate'))}</div>
                          </div>
                          <div className="post-comment__description">
                            <div className="post-comment__description-text">{item.body}</div>
                          </div>
                        </div>
                        {session.user.id === item['user-id'] && (
                          <div className="post-comment__actions">
                            <div className="post-comment__action">
                              <div className="post-comment__action-icon">
                                <img
                                  alt=""
                                  src={trashIcon}
                                  onClick={() => handleDelete(item.id)}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </>
              )}
            </div>
            <div className="comment-form form">
              <input
                disabled={isSubmitting}
                onChange={e => setComment(e.target.value)}
                value={comment}
                onKeyDown={loading ? null : onKeyDown}
                className="form__control disabled"
                placeholder={t('textsCommon.placeholderSendYourComment')}
              />
              <div className="textarea-instructions">{t('textsCommon.textPressEnter')}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
