import React from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import MainSidebar from 'app/components/Sidebar';
import { navigate } from '@reach/router';

export default function EssaySendSidebar(props) {
  return (
    <MainSidebar hasBrand={false}>
      <nav className="notebook-sidebar__nav">
        <button
          onClick={() => navigate(-1)}
          className="filter-bar__back"
        >
          <div className="card__icon-wrapper">
            <FiChevronLeft />
          </div>
          <span className="filter-bar__back-container">
            <span className="filter-bar__title">Redação</span>
          </span>
        </button>
      </nav>
    </MainSidebar>
  );
}
