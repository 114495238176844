import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import MultiSelect from 'app/components/MultiSelect';
import ModalTaxonomies from 'app/components/modalTaxonomies';
import { t } from 'i18next';
import { useClient } from 'jsonapi-react';
import moment from 'moment';
import React, { useState } from 'react';
import { useEffect } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import toast from 'react-hot-toast';

export default function StepAbout(props) {
  const { step, form } = props;
  const client = useClient();
  const [taxonomies, setTaxonomies] = useState();
  const [isOpenModalTaxonomies, setIsOpenModalTaxonomies] = useState(false);

  const getTaxonomies = async () => {
    try {
      const { data } = await client.fetch('taxonomies');
      setTaxonomies(data);
    } catch {
      toast.error('Erro ao carregar taxonomias');
    }
  };

  useEffect(() => {
    getTaxonomies();
  }, []);

  const knowledgeList = [
    { title: t('exams.knowledgeAcademy'), id: 'Academy' },
    { title: t('exams.knowledgeCommercial'), id: 'Comercial' },
    { title: t('exams.knowledgeMunicipality'), id: 'Concelho' },
    { title: t('exams.knowledgeData'), id: 'Dados' },
    { title: t('exams.knowledgeEsocial'), id: 'Esocial' },
    { title: t('exams.knowledgeFacilities'), id: 'Facitilies' },
    { title: t('exams.knowledgeImplantation'), id: 'Implantação' },
    { title: t('exams.knowledgeBusiness'), id: 'Negócio' },
    { title: t('exams.knowledgeOperation'), id: 'Operação' },
    { title: t('exams.knowledgePD'), id: 'P&D' },
    { title: t('exams.knowledgeParameterization'), id: 'Parametrização' },
    { title: t('exams.knowledgeProcesss'), id: 'Processos' },
    { title: t('exams.knowledgeProduct'), id: 'Produto' },
    { title: t('exams.knowledgeProjects'), id: 'Projetos' },
    { title: t('exams.knowledgeSupport'), id: 'Sustentação' }
  ];

  return (
    step === 'sobre' && (
      <div className="page__content fadeIn u-mb-5">
        <div className="new-questions__header">
          <h2 className="new-questions__title"> {t('courses.buttonNewCourse')}</h2>
        </div>

        <div className="new-questions__body">
          <div className="form__row">
            <label
              className="form__label"
              htmlFor="about"
            >
              *{t('courses.aboutCourse')}
            </label>

            <TextareaAutosize
              placeholder={t('courses.aboutCoursePlaceholder')}
              id="about"
              name="about"
              value={form?.values?.about}
              onChange={e => form.setFieldValue('about', e.target.value)}
            />
            {form?.touched?.about && form?.errors?.about && <span style={{ color: 'red' }}>{form?.errors?.about}</span>}
          </div>

          <div className="form__row">
            <FilterSelectionBox
              blankLabel={t('filter.labelSelectArea')}
              label={`*${t('courses.selectKnowledgeArea')}`}
              value={form.values.knowledge}
              onChange={e => form.setFieldValue('knowledge', e.target.value)}
              options={knowledgeList.map(o => ({
                id: o.id,
                name: o.title
              }))}
            />
            {form?.touched?.knowledge && form?.errors?.knowledge && <span style={{ color: 'red' }}>{form?.errors?.knowledge}</span>}
          </div>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="about"
            >
              *Selecione uma data para conclusão do curso
            </label>
            <input
              style={{ width: 'fit-content' }}
              className="form__control"
              placeholder={t('lms.typeAnumber')}
              id="finished-at"
              name="finished-at"
              type="date"
              value={moment(form?.values?.['finished-at']).format('YYYY-MM-DD')}
              onChange={e => form.setFieldValue('finished-at', e.target.value)}
            />
            {form.touched?.['finished-at'] && form?.errors?.['finished-at'] && <span style={{ color: 'red' }}>{form?.errors?.['finished-at']}</span>}
          </div>
          <fieldset className="form__row">
            <legend className="form__label">*{t('tasks.mattersRelated')}</legend>

            <MultiSelect
              selectedTaxonomies={form?.values?.selectedTaxonomies}
              setFieldValue={form?.setFieldValue}
              openModal={() => setIsOpenModalTaxonomies(true)}
              placeholder={t('tasks.mattersAdd')}
            />
            {form?.touched?.selectedTaxonomies && form?.errors?.selectedTaxonomies && <span style={{ color: 'red' }}>{form?.errors?.selectedTaxonomies}</span>}
          </fieldset>
        </div>

        {taxonomies?.length > 0 && isOpenModalTaxonomies && (
          <ModalTaxonomies
            taxonomies={taxonomies}
            selectedTaxonomies={form?.values?.selectedTaxonomies}
            setFieldValue={form?.setFieldValue}
            closeModal={() => setIsOpenModalTaxonomies(false)}
          />
        )}
      </div>
    )
  );
}
