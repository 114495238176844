import { useTranslation } from 'react-i18next';
import React from 'react';

function TabNavBar({ activeTabs, setActiveTabs }) {
  const { t } = useTranslation();
  return (
    <div className="tab">
      <button
        className={`tab__link ${activeTabs === 'geral' ? 'active' : ''}`}
        onClick={() => setActiveTabs('geral')}
      >
        {t('nps.toggleAll')}
      </button>
      <button
        className={`tab__link ${activeTabs === 'modulo' ? 'active' : ''}`}
        onClick={() => setActiveTabs('modulo')}
      >
        {t('textsCommon.module')}
      </button>
      <button
        className={`tab__link ${activeTabs === 'aluno' ? 'active' : ''}`}
        onClick={() => setActiveTabs('aluno')}
      >
        Estudante
      </button>
      <button
        className={`tab__link ${activeTabs === 'funil' ? 'active' : ''}`}
        onClick={() => setActiveTabs('funil')}
      >
        Funil
      </button>
    </div>
  );
}

export default TabNavBar;
