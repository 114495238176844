import React from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import MainSidebar from 'app/components/Sidebar';
import { Link } from '@reach/router';

export default function EssaySidebar(props) {
  const { steps, currentStep, setCurrentStep, edit } = props;
  const basePath = window.env.REACT_APP_BASE_PATH;

  return (
    <MainSidebar hasBrand={false}>
      <nav className="notebook-sidebar__nav">
        <Link
          to={`${basePath}/redacao`}
          className="filter-bar__back"
        >
          <div className="card__icon-wrapper">
            <FiChevronLeft />
          </div>
          <span className="filter-bar__back-container">
            <span className="filter-bar__title">Redações</span>
          </span>
        </Link>

        <ul className="step-by-step-nav">
          {steps.map((step, index) => {
            return (
              <li
                className={`step-by-step-nav__item ${index === currentStep ? 'active' : ''} ${currentStep > index ? 'step-by-step-nav__item--completed' : ''}`}
                onClick={() => edit && setCurrentStep(index)}
                key={index}
              >
                <Link
                  to={edit ? `/redacao/criar/${step.route}` : ''}
                  className="step-by-step-nav__link"
                  style={{ cursor: edit ? 'pointer' : 'default' }}
                >
                  {step.text}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </MainSidebar>
  );
}
