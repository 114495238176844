import { useFormik } from 'formik';
import * as yup from 'yup';
import ModalHeader from './ModalHeader';
import DeleteIcon from '../icon/DeleteIcon';
import AddEditNodeFormUserInputGroup from './AddEditNodeFormUserInputGroup';
import toast from 'react-hot-toast';
import { useState } from 'react';
import promiseRequest from 'app/utils/promiseToast';

const AddEditNodeForm = ({ node }) => {
  const { promiseToast, isSubmitting } = promiseRequest();

  const form = useFormik({
    initialValues: {
      title: node?.role || '',
      'user-ids': node?.user || [null],
      'organizational-chart-parent-id': node['organizational-chart-parent-id'] || null
    },
    validationSchema: yup.object({
      title: yup.string().required('Campo obrigatório'),
      'user-ids': yup.array().of(yup.number().typeError('Campo obrigatório')).min(1, 'Campo obrigatório')
    }),
    onSubmit: values => handleSubmit(values)
  });

  const handleSubmit = values => {
    const data = { attributes: values };
    const baseUrl = `/organization_structures/${node['organization-structure-id']}/organizational_charts/`;
    const url = node.id ? [baseUrl, node.id] : baseUrl;

    promiseToast({
      url: url,
      request: data,
      successText: 'Efetuado com Sucesso',
      errorText: 'Erro ao interagir'
    })
      .then(data => {
        node.afterSubmit(node);
      })
      .catch(e => {
        toast.error(e.title);
        node.afterSubmit(node);
      });
  };
  const addNewEmployee = () => {
    const newUsers = [...form.values['user-ids'], null];
    form.setFieldValue('user-ids', newUsers);
  };

  const handleDeleteUser = index => {
    const newUsers = [...form.values['user-ids']];
    newUsers.splice(index, 1);

    if (newUsers.length === 0) {
      newUsers.push(null);
    }

    form.setFieldValue('user-ids', newUsers);
  };

  const handleUserChange = (newUserId, index) => {
    const newUsers = [...form.values['user-ids']];
    newUsers[index] = newUserId;
    form.setFieldValue('user-ids', newUsers);
  };

  return (
    <div>
      <ModalHeader
        title={!node?.id ? 'Adicione a Lotação e Colaborador' : 'Editar a Lotação e colaborador'}
        hasUnderline={true}
      />
      <div className="form-container">
        <form
          className="form"
          onSubmit={form.handleSubmit}
        >
          <div className="form__row">
            <label
              htmlFor=""
              className="form__label"
            >
              *Lotação
            </label>
            <input
              type="text"
              name="title"
              className="form__control flow-input--border"
              value={form.values.title}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
            />
            {form.errors.title && <span style={{ color: 'red' }}>{form.errors.title}</span>}
          </div>
          <div className="form__row">
            <label
              htmlFor=""
              className="form__label"
            >
              *Nome do Colaborador
            </label>
            <div className="form-flow__container">
              {form?.values['user-ids'].map((user, index) => (
                <AddEditNodeFormUserInputGroup
                  key={index}
                  userId={user || null}
                  onUserChange={newUserId => handleUserChange(newUserId, index)}
                  onDelete={() => handleDeleteUser(index)}
                />
              ))}
            </div>
            {form.errors?.['user-ids'] && <span style={{ color: 'red' }}>{form.errors?.['user-ids']}</span>}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              color: 'darkblue',
              textDecoration: 'underline',
              textUnderlineOffset: '3px',
              cursor: 'pointer'
            }}
            onClick={addNewEmployee}
          >
            Adicionar colaborador
          </div>

          <button
            type="submit"
            className="btn btn--primary"
            disabled={isSubmitting}
          >
            Salvar
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddEditNodeForm;
