import React from 'react';
import { FiVideo, FiChevronDown } from 'react-icons/fi';
import { AiOutlineTrophy } from 'react-icons/ai';
import { useFormik } from 'formik';
import defaultImage from 'app/images/placeholders/show-course.jpg';
import { Link } from '@reach/router';
import Requirements from './Requirements';
import { AvatarStack } from 'app/components/Avatar';
import { mockStudents } from './mockData';
import Dropdown from 'app/components/Dropdown';
import { t } from 'i18next';

export default function ModuleCard(props) {
  const { id } = props;
  const progress = 50;
  const basePath = window.env.REACT_APP_BASE_PATH;

  const moduleForm = useFormik({
    initialValues: {
      publish: false
    }
  });

  return (
    <div className="card">
      <div className="form__check form__switch">
        <input
          className="form__check-input"
          id={`publish-${id}`}
          name={`publish-${id}`}
          type="checkbox"
          checked={moduleForm.values.publish}
          onClick={() => {
            moduleForm.setFieldValue('publish', !moduleForm.values.publish);
          }}
        />
        <label htmlFor={`publish-${id}`}>Publicar módulo</label>
      </div>

      <div className="card__header">
        <Requirements />

        <img
          src={defaultImage}
          alt=""
          className="card__header-img"
        />
        <div className="card__inner-header">
          <h2
            className="card__title"
            title="Módulo A"
          >
            Módulo A
          </h2>
        </div>
        <div className="card__badges">
          <span className="badge badge--tiny badge--warning">Nova mensagem</span>
        </div>
      </div>

      <div className="card__body">
        <p
          className="card__description"
          title="Lremlorem lorem lorem loremlorem em lorem loremlorem"
        >
          Lremlorem lorem lorem loremlorem em lorem loremlorem
        </p>

        {progress && progress === 100 && (
          <p
            className="card__count u-mb-3"
            title="Concluído"
            aria-label="Concluído"
          >
            <AiOutlineTrophy
              size={27}
              className="card__icon"
            />
          </p>
        )}

        <div className="card__columns">
          <p className="card__count">
            {mockStudents?.length > 0 && (
              <AvatarStack
                data={mockStudents}
                modalTitle="Estudantes do módulo"
                className="avatar--xs"
              />
            )}
          </p>
          <p
            className="card__count"
            title="Aulas"
            aria-label="Aulas"
          >
            <FiVideo className="card__icon" /> 10
          </p>
        </div>
      </div>

      <div className="card__footer">
        <div
          className="btn-group btn-group--with-dropdown"
          role="group"
          aria-label="Ações"
        >
          <Link
            to={`${basePath}/lms/gerenciar/modulos`}
            type="button"
            className="btn btn--outline btn--small"
          >
            Continuar editando
          </Link>
          <Dropdown className="btn--small btn btn--outline">
            <Dropdown.Toggle>
              <FiChevronDown />
            </Dropdown.Toggle>

            <Dropdown.Content>
              <Dropdown.Item to={`${basePath}/lms/criar/modulo/curso/${149}`}>{t('button.edit')}</Dropdown.Item>
              <Dropdown.Item>{t('button.delete')}</Dropdown.Item>
              <Dropdown.Item to={`${basePath}/lms/gerenciar/modulos`}>Gerenciar</Dropdown.Item>
            </Dropdown.Content>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
