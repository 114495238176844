import React, { useEffect, useState } from 'react';
import { FiPlus, FiMoreHorizontal } from 'react-icons/fi';
import dragAndDrop from 'app/images/icons/drag-and-drop.svg';
import Dropdown from 'app/components/Dropdown';

import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import EmptyState from 'app/components/EmptyState';
import Swal from 'sweetalert2';
import MatterModal from '../CreateExam/StepName/MatterModal';
import Loader from 'app/components/loader';
import { useTranslation } from 'react-i18next';
import { navigate } from '@reach/router';
import { i18n } from 'app/components/i18n/i18n';

export default function MatterList(props) {
  const { selectedExam, setTaxonomyId, selectedTaxonomy, setSelectedExam, loading, setExams, exams, setCount, count, path, getTaxonomies, taxonomies } = props;
  const client = useClient();
  const [filteredMattersList, setFilteredMattersList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedMatters, setSelectedMatters] = useState([]);
  const [variable, setVariable] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(window.env.REACT_APP_LOCALE);
  }, []);

  useEffect(() => {
    if (path.includes('/apostilas')) {
      setVariable(t('sideBar.questionBooks'));
    } else if (path.includes('simulados')) {
      setVariable(t('textsCommon.simulations'));
    } else {
      setVariable(t('exams.toggleExams'));
    }
  }, [path]);

  useEffect(() => {
    if (showModal && taxonomies?.length === 0) {
      getTaxonomies();
    }
  }, [showModal]);

  const addMatter = async matter => {
    const { error, data } = await client.mutate(['question_books', selectedExam.id], { 'taxonomy-ids': [matter.id] });
    if (error) {
      toast.error(t('toast.errorAddSubject'));
    } else {
      const selectedMatter = data['question-book-taxonomies'].find(item => item.taxonomy_id === matter.id);
      const newSelectedMatters = selectedMatters;
      newSelectedMatters.push({ ...selectedMatter, name: matter.name });
      setSelectedMatters(newSelectedMatters);
      const newExams = exams.map(exam => {
        if (selectedExam.id == exam.id) {
          exam['question-book-taxonomies'] = newSelectedMatters;
          setSelectedExam(exam);
        }
        return exam;
      });
      setExams(newExams);
      toast.success(t('toast.successAddSubject'));
    }
  };

  const addedMatters = matter => {
    setSelectedMatters([...selectedMatters, matter]);
  };

  useEffect(() => {
    if (selectedExam) {
      setSelectedMatters([...selectedExam?.['question-book-taxonomies']]);
    }
  }, [exams, selectedExam]);

  const removeMatter = async argument => {
    try {
      const taxonomy = !showModal ? argument : selectedMatters.find(item => item.taxonomy_id === argument.id);

      await client.delete(['question_book_taxonomies', taxonomy.id]);

      const newExams = exams.map(exam => {
        if (selectedExam.id == exam.id) {
          exam['question-book-taxonomies'] = exam['question-book-taxonomies'].filter(item => parseInt(item.taxonomy_id) !== parseInt(argument.id));
          setSelectedExam(exam);
        }
        return exam;
      });
      setExams(newExams);
      toast.success(t('toast.successSubjectRemoved'));
    } catch {
      toast.error(t('toast.errorSubjectRemoved'));
    }
  };

  useEffect(() => {
    setSearchTerm('');
  }, [selectedExam]);

  useEffect(() => {
    const filteredMattersList = selectedExam?.['question-book-taxonomies'];
    setFilteredMattersList(filteredMattersList);

    if (searchTerm !== '') {
      const searchMatter = filteredMattersList.filter(item => {
        if (searchTerm == '') {
          return item;
        }
        if (item.name != null && item.name.toLowerCase().includes(searchTerm.toLocaleLowerCase())) {
          return item;
        }
      });
      setFilteredMattersList(searchMatter);
    }
  }, [searchTerm, selectedExam, exams, filteredMattersList]);

  const handleDeleteMatter = matter => {
    Swal.fire({
      title: t('projects.warningDeleteSubject'),
      text: t('projects.warningDeleteCannotBeUndoneText'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        removeMatter(matter);
      }
    });
  };

  return (
    <div className="new-questions__sidebar">
      <div className="form__row">
        <label
          htmlFor="search"
          className="form__label"
        >
          {t('textsCommon.matter')}
        </label>

        <input
          aria-label={t('textsCommon.research')}
          className="form__control form__control--search-with-icon"
          placeholder={t('textsCommon.research')}
          type="search"
          name="search"
          id="search"
          onChange={e => setSearchTerm(e.target.value)}
        />
      </div>

      <fieldset className="form__row">
        <legend className="form__label">*{t('exams.examSubjects')}</legend>

        <button
          type="button"
          className="btn btn--outline-dashed btn--small btn--full"
          onClick={() => setShowModal(true)}
        >
          <FiPlus className="btn__icon" /> {t('textsCommon.buttonAddMatters')}
        </button>

        <MatterModal
          addedMatters={addedMatters}
          addMatter={addMatter}
          removeMatter={removeMatter}
          selectedMatters={selectedMatters}
          matters={taxonomies}
          showModal={showModal}
          countType={'exam'}
          onClose={() => {
            setShowModal(false);
            setCount(count + 1);
          }}
          loading={loading}
        />
      </fieldset>
      {loading && <Loader />}
      {!loading && filteredMattersList?.length === 0 ? (
        <EmptyState
          type="data"
          text={t('emptyState.noSubjectAdd')}
        />
      ) : (
        <Droppable droppableId="droppable">
          {provided => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {filteredMattersList?.map((item, i) => {
                return (
                  <Draggable
                    key={i}
                    draggableId={item.id.toString()}
                    index={i}
                  >
                    {provided => (
                      <div
                        key={i}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div
                          className={selectedTaxonomy?.id === item?.id ? 'taxonomies__item active' : 'taxonomies__item'}
                          onClick={() => {
                            if (variable === 'Provas') {
                              window.history.pushState({ id: selectedExam.id, taxonomyId: item?.id }, '', `/provas/gerenciar/${selectedExam?.id}/${item?.id}`);
                            } else if (variable === 'Apostilas') {
                              window.history.pushState({ id: selectedExam.id, taxonomyId: item?.id }, '', `/apostilas/gerenciar/${selectedExam?.id}/${item?.id}`);
                            } else {
                              window.history.pushState({ id: selectedExam.id, taxonomyId: item?.id }, '', `/simulados/gerenciar/${selectedExam?.id}/${item?.id}`);
                            }
                            setTaxonomyId(item?.id);
                          }}
                        >
                          <img
                            className="taxonomies__icon"
                            src={dragAndDrop}
                            alt=""
                          />
                          <span
                            className="taxonomies__text"
                            title="Português (04)"
                          >
                            {item.name}
                          </span>
                          <Dropdown>
                            <Dropdown.Toggle>
                              <FiMoreHorizontal />
                            </Dropdown.Toggle>

                            <Dropdown.Content>
                              <Dropdown.Item
                                onClick={() => {
                                  handleDeleteMatter(item);
                                }}
                              >
                                {t('button.delete')}
                              </Dropdown.Item>
                            </Dropdown.Content>
                          </Dropdown>
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
    </div>
  );
}
