import React, { useState, createContext, useEffect } from 'react';
import moment from 'moment';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import { useTranslation } from 'react-i18next';

export const AgendaContext = createContext();

export const AgendaProvider = ({ children }) => {
  const { t } = useTranslation();

  const [currentSprintIndex, setCurrentSprintIndex] = useState(0); //está considerando como atual, sempre a ultima semana que tem tarefas
  //criar uma função para atualizar corretamente o valor inicial do setCurrentsprintIndex

  const [sprints, setSprints] = useState([]);
  const [slots, setSlots] = useState([]);
  const [masterSlots, setMasterSlots] = useState([]);
  const [filterUser, setFilterUser] = useState(null);
  const [currentSlot, setCurrentSlot] = useState(null);
  const [weeklyOvertime, setWeeklyOvertime] = useState(null);
  const [dailyOvertime, setDailyOvertime] = useState([]);

  const [currentSelectedWeek, setCurrentSelectedWeek] = useState({
    sunday: moment().day(0),
    monday: moment().day(1),
    tuesday: moment().day(2),
    wednesday: moment().day(3),
    thursday: moment().day(4),
    friday: moment().day(5),
    saturday: moment().day(6)
  });
  const [weekSlots, setWeekSlots] = useState(null);
  const [weekMasterSlots, setWeekMasterSlots] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const authorityLevel = getAuthorityLevel();
  const [loading, setLoading] = useState(true);

  const client = useClient();

  useEffect(() => {
    filterMasterSlots();
  }, [masterSlots]);

  useEffect(() => {
    filterSlots();
  }, [slots]);

  const getSprints = async user => {
    try {
      setLoading(true);
      let url = 'sprints';
      if (user || filterUser) {
        if (user) {
          setFilterUser(user);
        }
        url = `users/${(user || filterUser)?.id}/sprints`;
      }
      const { data } = await client.fetch(url);
      setSprints(data);
      setLoading(false);
    } catch (e) {
      console.error(e);
      toast.error(t('toast.errorGetAgenda'));
    }
  };
  //função que criei pra pegar a sprint atual certa

  useEffect(() => {
    if (sprints) {
      const today = moment();
      const currentSprint = sprints.find(item => {
        return today.isBetween(item?.['initial-date'], item?.['end-date']);
      });
      const indexOfCurrentSprint = sprints.findIndex(item => item?.id === currentSprint?.id);
      setCurrentSprintIndex(indexOfCurrentSprint);
    }
  }, [sprints]);

  const getSelectedSprintSlots = async (sprintId, user) => {
    try {
      setLoading(true);
      setSlots([]);
      let url = sprintId ? `slots?filter[sprint_id]=${sprintId}` : 'slots?filter[last_sprint]=true'; //função que busca as tarefas do aluno

      if (user || filterUser) {
        if (user) {
          setFilterUser(user);
        }
        url = sprintId ? `users/${(user || filterUser)?.id}/slots?filter[sprint_id]=${sprintId}` : `users/${(user || filterUser)?.id}/slots?filter[last_sprint]=true`;
      }

      const { data } = await client.fetch(url);
      setSlots(data);
      setLoading(false);
    } catch (e) {
      console.error(e);
      toast.error(t('toast.errorGetAgenda'));
    }
  };

  const hasPreviousSprint = () => {
    return currentSprintIndex < sprints.length - 1;
  };

  const getPreviousSprintSlots = async () => {
    if (hasPreviousSprint()) {
      const newSprintIndex = currentSprintIndex + 1;

      if (authorityLevel === 'student') {
        await getSelectedSprintSlots(sprints[newSprintIndex].id);
      }
      setCurrentSprintIndex(newSprintIndex);

      setCurrentSelectedWeek({
        sunday: moment(sprints[newSprintIndex]['initial-date']),
        monday: moment(sprints[newSprintIndex]['initial-date']).add(1, 'd'),
        tuesday: moment(sprints[newSprintIndex]['initial-date']).add(2, 'd'),
        wednesday: moment(sprints[newSprintIndex]['initial-date']).add(3, 'd'),
        thursday: moment(sprints[newSprintIndex]['initial-date']).add(4, 'd'),
        friday: moment(sprints[newSprintIndex]['initial-date']).add(5, 'd'),
        saturday: moment(sprints[newSprintIndex]['end-date'])
      });
    }
  };

  const hasNextSprintSlots = () => {
    return currentSprintIndex !== 0;
  };

  const getNextSprintSlots = async () => {
    if (hasNextSprintSlots()) {
      const newSprintIndex = currentSprintIndex - 1;

      if (authorityLevel === 'student') {
        await getSelectedSprintSlots(sprints[newSprintIndex].id);
      }

      setCurrentSprintIndex(newSprintIndex);

      setCurrentSelectedWeek({
        sunday: moment(sprints[newSprintIndex]['initial-date']),
        monday: moment(sprints[newSprintIndex]['initial-date']).add(1, 'd'),
        tuesday: moment(sprints[newSprintIndex]['initial-date']).add(2, 'd'),
        wednesday: moment(sprints[newSprintIndex]['initial-date']).add(3, 'd'),
        thursday: moment(sprints[newSprintIndex]['initial-date']).add(4, 'd'),
        friday: moment(sprints[newSprintIndex]['initial-date']).add(5, 'd'),
        saturday: moment(sprints[newSprintIndex]['initial-date']).add(6, 'd')
      });
    }
  };

  const checkAccomplishSlot = async weekSlot => {
    weekSlot.accomplished ? (weekSlot.accomplished = false) : (weekSlot.accomplished = true);

    try {
      await client.mutate(['slots', weekSlot.id], {
        accomplished: weekSlot.accomplished
      });
    } catch (e) {
      console.error(e);
      toast.error(t('toast.errorEditTask'));

      setSlots(null);
    }
  };

  const createSlot = async form => {
    setLoading(true);
    const formData = form;
    delete formData.selectedTaxonomies;
    const { data, error } = await client.mutate('slots', formData);

    setLoading(false);
    if (error) {
      console.error(error);
      let body = error?.title == 'cannot be in past' ? 'Não é permitido criar tarefa antes da data atual' : 'Erro ao criar tarefa.';
      toast.error(body);
    } else {
      toast.success(t('toast.sucessCreateTask'));
      getSelectedSprintSlots();
    }
  };

  const updateSlot = async () => {
    const { data, error } = await client.mutate(['slots', currentSlot.id], {
      body: currentSlot.body
    });

    if (error) {
      toast.error(t('toast.errorEditTask'));
    } else {
      const newSlots = slots.map(slot => (slot.id === data.id ? data : slot));
      setSlots(newSlots);
      setSelectedTask(data);
    }
  };

  const deleteSlot = async id => {
    try {
      const { data, error } = await client.delete(['slots', id]);
      if (authorityLevel === 'student') {
        await getSelectedSprintSlots(sprints[currentSprintIndex].id); //aluno
      }
      if (error) {
        toast.error(t('error.errorDeleteTask'));
      } else {
        toast.success(t('toast.successRemovedTask'));
      }
    } catch (e) {
      console.error(e);
      toast.error(t('error.errorDeleteTask'));
    }
  };

  const getCurrentSlot = (id, day) => {
    setCurrentSlot(slots[day].filter(s => s.id === id)[0]);
  };

  const updateSlotOrderOnDrop = props => {
    const { destination, source, draggableId, isDropDisabled } = props;
    if (isDropDisabled) {
      return null;
    }
    // Stops if the user sends the slot outside of the day
    if (!destination && !isDropDisabled) {
      if (confirm('Deseja remover essa tarefa?')) {
        deleteSlot(draggableId);
      }
      return null;
    }

    // Stops if the user sends the draggable to the same place it was before
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return null;
    }
    const currentSlot = weekSlots[source.droppableId][source.index];
    const newWeekSlots = weekSlots;

    newWeekSlots[source.droppableId].splice(source.index, 1);
    newWeekSlots[destination.droppableId].splice(destination.index, 0, currentSlot);

    updateSlotOrder(newWeekSlots);
  };

  const updateSlotOrder = async newWeekSlots => {
    let requestParams = {};
    let sprintId;

    Object.keys(newWeekSlots).map((key, index) => {
      requestParams[index] = newWeekSlots[key].map(slot => {
        sprintId = slot['sprint-id'];
        return slot.id;
      });
    });
    const { data, error, errors } = await client.mutate(['sprints', sprintId], {
      slotSequence: requestParams
    });

    setWeekSlots(newWeekSlots);
  };

  const createMasterSlot = async form => {
    setLoading(true);
    const formData = {
      ...form,
      'taxonomy-ids': form.selectedTaxonomies.map(e => e.id)
    };
    delete formData.selectedTaxonomies;

    const { data, error } = await client.mutate('master_slots', formData);
    setLoading(false);
    if (error) {
      toast.error(t('toast.errorDeleteTask'));
    } else {
      setMasterSlots([...masterSlots, data]);
      toast.success(t('toast.sucessCreateTask'));
    }
  };

  const deleteMasterSlot = async id => {
    const { error } = await client.delete(['master_slots', id]);
    if (error) {
      toast.error(t('error.errorDeleteTask'));
    } else {
      setMasterSlots(masterSlots.filter(e => e.id !== id));
      toast.success(t('toast.successRemovedTask'));
    }
  };

  const updateMasterSlot = async () => {
    const { data, error } = await client.mutate(['master_slots', currentSlot.id], { body: currentSlot.body });
    if (error) {
      toast.error(t('toast.errorEditTask'));
    } else {
      const newMasterSlots = masterSlots.map(masterSlot => (masterSlot.id === data.id ? data : masterSlot));
      setMasterSlots(newMasterSlots);
      toast.success(t('toast.successEditTask'));
    }
  };

  const getMasterSlots = async (filterType, filterId, currentSelectedWeek) => {
    if (filterId !== 'blank') {
      try {
        setLoading(true);
        const { data } = await client.fetch(`master_slots?filter[initial_date]=${currentSelectedWeek?.sunday.format('YYYY-MM-DD')}&filter[end_date]=${currentSelectedWeek?.saturday.format('YYYY-MM-DD')}&filter[${filterType}_id]=${filterId}`);

        setMasterSlots(data);
        setLoading(false);
      } catch (e) {
        console.error(e);
        toast.error(t('toast.errorGetAgenda'));
      }
    }
  };

  const getStudentTasks = async (filters, currentSelectedWeek) => {
    if (filters.values.student !== 'blank') {
      try {
        setLoading(true);
        setMasterSlots([]);
        const { data } = await client.fetch(
          `slots?filter[date_between[initial]]=${currentSelectedWeek?.sunday.format('YYYY-MM-DD')}&filter[date_between[final]]=${currentSelectedWeek?.saturday.format('YYYY-MM-DD')}&user_id=${filters.values.student.id}`
        );
        setSlots(data);
        setLoading(false);
      } catch (e) {
        console.error(e);
        toast.error(t('toast.errorGetAgenda'));
      }
    }
  };

  const getWeeklyOvertime = async currentSelectedWeek => {
    if (weeklyOvertime === null) {
      setLoading(true);
      const { data, error } = await client.fetch(`overtime_check_by_week?filter[date_between[final]]=${currentSelectedWeek?.sunday.format('YYYY-MM-DD')}&filter[date_between[initial]]=${currentSelectedWeek?.saturday.format('YYYY-MM-DD')}`);
      if (error) {
        toast.error(t('toast.errorGetTaskOverflow'));
      } else {
        setWeeklyOvertime(data);
        setLoading(false);
      }
    }
  };

  const getDailyOvertime = async (form, formType) => {
    setLoading(true);
    const { data, error } = await client.fetch(`overtime_check?duration=${form.values.duration}&filter[date]=${form.values.date}&filter[${formType}_id]=${form.values[`${formType}-id`]}`);
    if (error) {
      toast.error(t('toast.errorGetDailyTaskOverload'));
    } else if (data.overtimeStudentCount === 0) {
      form.handleSubmit();
    } else {
      setDailyOvertime(data);
      setLoading(false);
    }
  };

  const filterMasterSlots = () => {
    const getSlotsByWeekday = (masterSlot, i) => new Date(masterSlot.date).getDay() === i;
    setWeekMasterSlots({
      sunday: masterSlots.filter(masterSlot => getSlotsByWeekday(masterSlot, 6)),
      monday: masterSlots.filter(masterSlot => getSlotsByWeekday(masterSlot, 0)),
      tuesday: masterSlots.filter(masterSlot => getSlotsByWeekday(masterSlot, 1)),
      wednesday: masterSlots.filter(masterSlot => getSlotsByWeekday(masterSlot, 2)),
      thursday: masterSlots.filter(masterSlot => getSlotsByWeekday(masterSlot, 3)),
      friday: masterSlots.filter(masterSlot => getSlotsByWeekday(masterSlot, 4)),
      saturday: masterSlots.filter(masterSlot => getSlotsByWeekday(masterSlot, 5))
    });
  };

  const filterSlots = () => {
    const getSlotsByWeekday = (slot, i) => new Date(slot.date).getDay() === i;
    setWeekSlots({
      sunday: slots.filter(slot => getSlotsByWeekday(slot, 0)),
      monday: slots.filter(slot => getSlotsByWeekday(slot, 1)),
      tuesday: slots.filter(slot => getSlotsByWeekday(slot, 2)),
      wednesday: slots.filter(slot => getSlotsByWeekday(slot, 3)),
      thursday: slots.filter(slot => getSlotsByWeekday(slot, 4)),
      friday: slots.filter(slot => getSlotsByWeekday(slot, 5)),
      saturday: slots.filter(slot => getSlotsByWeekday(slot, 6))
    });
  };

  const editTask = async (id, newTitle, selectedTab) => {
    if (selectedTab === 'curso' || selectedTab === 'turma') {
      const { data, error } = await client.mutate(['master_slots', id], {
        title: newTitle
      });
      if (error) {
        toast.error(t('toast.errorEditTask'));
      } else {
        toast.success(t('toast.successEditTask'));
        const newMasterSlots = masterSlots.map(masterSlot => (masterSlot.id === data.id ? data : masterSlot));
        setSelectedTask(data);
        setMasterSlots(newMasterSlots);
      }
    } else {
      const { data, error } = await client.mutate(['slots', id], {
        title: newTitle
      });
      if (error) {
        toast.error(t('toast.errorEditTask'));
      } else {
        toast.success(t('toast.successEditTask'));
        const newSlots = slots.map(slot => (slot.id === data.id ? data : slot));
        setSelectedTask(data);
        setSlots(newSlots);
      }
    }
  };

  return (
    <AgendaContext.Provider
      value={{
        sprints,
        slots,
        loading,
        currentSelectedWeek,
        getSprints,
        getCurrentSlot,
        currentSprintIndex,
        currentSlot,
        getSelectedSprintSlots,
        getPreviousSprintSlots,
        getNextSprintSlots,
        hasPreviousSprint,
        hasNextSprintSlots,
        setCurrentSlot,
        createSlot,
        updateSlot,
        deleteSlot,
        checkAccomplishSlot,
        updateSlotOrderOnDrop,
        createMasterSlot,
        deleteMasterSlot,
        updateMasterSlot,
        getMasterSlots,
        masterSlots,
        getWeeklyOvertime,
        weeklyOvertime,
        getDailyOvertime,
        dailyOvertime,
        setDailyOvertime,
        getStudentTasks,
        weekSlots,
        setWeekSlots,
        weekMasterSlots,
        setWeekMasterSlots,
        setFilterUser,
        editTask,
        selectedTask,
        setSelectedTask,
        loading,
        setLoading
      }}
    >
      {children}
    </AgendaContext.Provider>
  );
};
