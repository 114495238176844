import React from 'react';
import { Link, navigate } from '@reach/router';
import { FiPlayCircle } from 'react-icons/fi';
import { AiOutlineTrophy } from 'react-icons/ai';
import defaultImage from 'app/images/placeholders/show-course.jpg';
import moreMedal from 'app/images/more-medal.svg';
import { t } from 'i18next';

export default function ModuleCard(props) {
  const { module, courseId } = props;
  const basePath = window.env.REACT_APP_BASE_PATH;

  return (
    <div
      className="module-card"
      onClick={() => navigate(`${basePath}/curso/${courseId}/modulo/${module.id}/sobre`)}
    >
      <div className="module-card__header">
        <img
          className="module-card__img"
          src={module?.image?.url || defaultImage}
          alt=""
        />
        <div className="module-card__inner-header">
          <h3 className="module-card__title">{module?.title}</h3>
          <div className="card__badges u-mb-0">
            <span className={module?.learning_system_users.length === 0 ? 'badge badge--tiny badge--warning' : ''}>{module?.learning_system_users.length === 0 ? 'Novo' : ''}</span>
            <span className={module?.progress === 100 ? 'badge badge--tiny badge--success' : ''}>{module?.progress === 100 ? 'Concluído' : ''}</span>
          </div>
        </div>
      </div>

      {module?.progress === 100 ? (
        <div className="medal medal--sm">
          <img
            src={moreMedal}
            alt="nova medalha"
            className="medal__img"
          />
          <span className="medal__value">
            <AiOutlineTrophy />
          </span>
        </div>
      ) : (
        <p className="module-card__text">
          {module?.progress === 0 ? <strong>0</strong> : <strong>{module?.learning_system_items_finished_count}</strong>}/{module?.learning_system_items_total_count} {t('lms.lms')}
        </p>
      )}

      {/* <div className="module-card__footer">
        {newModule ? (
          <button
            type="button"
            className="btn btn--full btn--outline"
          >
            Visualizar
          </button>
        ) : (
          <>
            <p className="module-card__text">{t('lms.buttonContinue')}</p>
            <Link
              className="module-card__link"
              to={`${basePath}/show-course/${374}/classroom`}
            >
              <span className="card__icon-wrapper">
                <FiPlayCircle className="card__icon" />
              </span>
              <span className="module-card__link-text">Aula 2 - Física clássica</span>
            </Link>
          </>
        )}
      </div> */}
    </div>
  );
}
