import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/pt';
import { FiChevronLeft, FiFileText, FiMoreHorizontal } from 'react-icons/fi';
import Swal from 'sweetalert2';
import trashIcon from 'app/images/icons/trash-icon.svg';
import { useSession } from 'app/hooks/useSession';
import { ProjectsContext } from './provider';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import Modal from 'app/components/Modal';
import toast from 'react-hot-toast';
import Card from 'app/components/Card';
import EmptyState from 'app/components/EmptyState';
import Dropdown from 'app/components/Dropdown';
import { AvatarStack } from 'app/components/Avatar';
import ProjectSchedule from './ProjectSchedule';
import { Link } from '@reach/router';
import getPermission from 'app/utils/getPermission';
import Loader from 'app/components/loader';
import { useClient } from 'jsonapi-react';
import { useTranslation } from 'react-i18next';
import { ProjectsCardComment } from './ProjectsCardComment';

export default function ProjectsContent() {
  const basePath = window.env.REACT_APP_BASE_PATH;
  const { t } = useTranslation();
  const client = useClient();
  const [versionCommentDraft, setVersionCommentDraft] = useState('');
  const [showModal, setShowModal] = useState({
    addParticipant: false,
    removeParticipant: false
  });
  const { session } = useSession();
  const id = session.user.id;
  const [allProfiles, setAllProfiles] = useState();
  const [ownProject, setOwnProject] = useState(true);
  const [versionUser, setVersionUser] = useState({});
  const [versionUserLoading, setVersionUserLoading] = useState(true);
  const [comments, setComments] = useState([]);

  const {
    selectedProject,
    selectedProjectVersion,
    approveProjectVersion,
    confirmApproveProject,
    getAllStudents,
    studentFilter,
    filteredStudents,
    createProjectVersion,
    createProjectVersionComment,
    addStudentToSelectedProject,
    deleteStudentFromSelectedProject,
    deleteProjectVersion,
    loading,
    deleteProjectVersionComment
  } = useContext(ProjectsContext);

  const authorityLevel = getAuthorityLevel(session);

  function checkIfUserIsOnProjec(selectedProject, id) {
    return selectedProject?.users?.some(obj => obj.id === id);
  }

  const getAllProfiles = async () => {
    const { data, error } = await client.fetch('profiles');
    if (error) {
      toast.error(t('toast.errorGetParticipants'));
    } else {
      setAllProfiles(
        data
          .map(item => item.name)
          .reduce((item, i) => {
            return `${item},${i}`;
          })
      );
    }
  };

  useEffect(() => {
    if (selectedProject && authorityLevel === 'student') {
      if (checkIfUserIsOnProjec(selectedProject, id)) {
        setOwnProject(true);
      } else {
        setOwnProject(false);
      }
    }
  }, [selectedProject, session]);

  const destroyStudentForm = student => {
    Swal.fire({
      title: t('projects.warningDeleteStudentFromProject'),
      text: t('projects.warningDeleteCannotBeUndoneText'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('button.delete'),
      showCloseButton: true
    }).then(result => {
      if (result.isConfirmed) {
        deleteStudentFromSelectedProject(student.id);
        toast.success(t('toast.successRemoveStudentFromProject'));
      }
    });
  };

  const getUser = async () => {
    setVersionUserLoading(true);
    const { data, error } = await client.fetch(`users/${selectedProjectVersion['user_id']}`);
    if (error) {
      toast.error('Erro ao buscar informações de quem enviou esta versão.');
    } else {
      setVersionUser(data);
      setVersionUserLoading(false);
    }
  };

  const fetchComments = async () => {
    const { data, error } = await client.fetch(`/comments?filter[project_version_id]=${selectedProjectVersion?.id}`);

    if (!error) {
      setComments(data);
    } else {
      toast.error('Erro ao buscar comentários');
    }
  };

  const handleDeleteComment = async commentId => {
    Swal.fire({
      title: t('projects.warningDeleteCommentTitle'),
      text: t('projects.warningDeleteCannotBeUndoneText'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteProjectVersionComment(commentId);
        setComments(prev => prev.filter(item => item.id !== commentId));
      }
    });
  };

  useEffect(() => {
    if (selectedProjectVersion && selectedProjectVersion.user_id !== null) {
      getUser();
    }

    fetchComments();
  }, [selectedProjectVersion]);

  useEffect(() => {
    getAllProfiles();
  }, []);

  const commentsFiltered = comments?.filter(comment => comment?.['comment-parent-id'] === null);

  return (
    <>
      {ownProject && (
        <div className="new-questions__content">
          <div className="new-questions__body">
            {selectedProject && (
              <>
                <header className="new-questions__header">
                  <Link
                    to={`${basePath}/projetos`}
                    className="filter-bar__back"
                  >
                    <div className="card__icon-wrapper">
                      <FiChevronLeft />
                    </div>
                  </Link>
                  <div className="new-questions__group">
                    {selectedProject.approved ? <span className="badge badge--success badge--tiny">{t('exams.statusInApproved')}</span> : <span className="badge badge--primary badge--tiny">{t('exams.inProgress')}</span>}
                    <h1 className="new-questions__title">{selectedProject.name}</h1>
                    <div className="new-questions__date-time-group">
                      <span className="new-questions__date-time">
                        {t('projects.createdIn')} {moment(selectedProject['created-at']).format(t('datesForm.formatDate'))}
                      </span>
                      <span>
                        <Link
                          to={`${basePath}/projetos/${selectedProject.id}/sobre`}
                          type="button"
                          className="btn btn--outline btn--tiny btn--full"
                        >
                          {t('button.aboutProject')}
                        </Link>
                      </span>
                    </div>
                  </div>

                  {authorityLevel === 'student' && !selectedProject.approved && (
                    <label className="form__custom-file-input">
                      <input
                        type="file"
                        name="newVersion"
                        id="newVersion"
                        accept=".pdf, .doc, .docx, image/*"
                        onChange={e => {
                          const filename = e.target.value;
                          const regex = /^C:\\fakepath\\/;
                          let fileName = filename.replace(regex, '');
                          createProjectVersion(e.target.files, fileName);
                        }}
                        value=""
                      />
                      <span className="btn btn--primary">{t('projects.buttonSendNewVersion')}</span>
                    </label>
                  )}

                  {getPermission('Aprovar projetos', 'Projetos') && selectedProjectVersion && !selectedProject.approved ? (
                    <button
                      className="btn btn--primary"
                      type="button"
                      onClick={confirmApproveProject}
                    >
                      Aprovar projeto
                    </button>
                  ) : null}
                </header>

                <div className="new-questions__page">
                  <div className="new-questions__col">
                    {loading && <Loader />}

                    {selectedProjectVersion && !loading && (
                      <div className="post">
                        <div className="projects-header">
                          <div className="projects-header__avatar">
                            {!versionUserLoading && (
                              <div className="round-dg__flex">
                                <AvatarStack
                                  data={[versionUser]}
                                  className="avatar--xs"
                                />
                                {versionUser.name}
                              </div>
                            )}
                          </div>
                          <div className="projects-header__file">
                            <div className="documents-card__icon--with-bg">
                              <FiFileText />
                            </div>
                            <div className="documents-card__group">
                              <h2 className="documents-card__title">{selectedProjectVersion?.file?.filename?.toUpperCase()}</h2>
                            </div>
                          </div>
                          <div className="projects-header__action">
                            {selectedProjectVersion.file?.url && (
                              <>
                                <a
                                  href={selectedProjectVersion.file?.url}
                                  target="_blank"
                                  className="btn btn--outline btn--small"
                                  onClick={selectedProject.user.id == session.user.id || authorityLevel === 'admin' ? approveProjectVersion : ''}
                                >
                                  {authorityLevel === 'student' ? 'Download' : 'Avaliar'}
                                </a>

                                {(authorityLevel === 'admin' || selectedProjectVersion.user_id === session.user.id) && !selectedProject.approved && (
                                  <div className="post-comment__action-icon">
                                    <img
                                      alt="lixeira"
                                      src={trashIcon}
                                      style={{ marginLeft: '8px' }}
                                      onClick={() => {
                                        Swal.fire({
                                          title: t('projects.warningDeleteProjectVersionTitle'),
                                          text: t('projects.warningDeleteCannotBeUndoneText'),
                                          confirmButtonText: t('button.yes'),
                                          showDenyButton: true,
                                          denyButtonText: t('button.no'),
                                          showCloseButton: true
                                        }).then(res => {
                                          if (res.isConfirmed) {
                                            deleteProjectVersion(selectedProjectVersion);
                                          }
                                        });
                                      }}
                                    />
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>

                        <div className="post__comments">
                          {commentsFiltered?.length > 0 &&
                            commentsFiltered.map(c => (
                              <ProjectsCardComment
                                key={c.id}
                                comment={c}
                                comments={comments}
                                onDelete={handleDeleteComment}
                              />
                            ))}
                        </div>

                        {!selectedProject.approved && getPermission('Postar e comentar em forum', 'Projetos') ? (
                          <div className="comment-form form">
                            <input
                              className="form__control"
                              placeholder={t('textsCommon.placeholderSendYourComment')}
                              student
                              value={versionCommentDraft}
                              onChange={e => setVersionCommentDraft(e.target.value)}
                              onKeyPress={e => {
                                if (e.key === 'Enter') {
                                  createProjectVersionComment(versionCommentDraft);
                                  setVersionCommentDraft('');
                                }
                              }}
                            />
                            <div className="textarea-instructions">{t('textsCommon.textPressEnter')}</div>
                          </div>
                        ) : null}
                      </div>
                    )}

                    {!selectedProjectVersion && !loading && (
                      <EmptyState
                        type="data"
                        title={t('emptyState.missingDataTitle')}
                        text={authorityLevel === 'admin' ? t('emptyState.textNoDataYet') : t('emptyState.submittingNewVersionText')}
                      />
                    )}
                  </div>

                  <div className="new-questions__aside">
                    <section className="new-questions__widget">
                      <div className="new-questions__widget-header">
                        <h3 className="new-questions__widget-title">{t('projects.titleProjectParticipants')}</h3>
                        {!selectedProject.approved && (
                          <Dropdown>
                            <Dropdown.Toggle>{getPermission('Adicionar participantes', 'Projetos') || getPermission('Excluir participantes', 'Projetos') ? <FiMoreHorizontal /> : null}</Dropdown.Toggle>

                            <Dropdown.Content>
                              {getPermission('Adicionar participantes', 'Projetos') && (
                                <Dropdown.Item
                                  onClick={() => {
                                    getAllStudents(allProfiles);
                                    setShowModal({
                                      ...showModal,
                                      addParticipant: true
                                    });
                                  }}
                                >
                                  {t('courses.addParticpants')}
                                </Dropdown.Item>
                              )}

                              {getPermission('Excluir participantes', 'Projetos') && (
                                <Dropdown.Item
                                  onClick={() => {
                                    getAllStudents();
                                    setShowModal({
                                      ...showModal,
                                      removeParticipant: true
                                    });
                                  }}
                                >
                                  {t('courses.deleteParticipants')}
                                </Dropdown.Item>
                              )}
                            </Dropdown.Content>
                          </Dropdown>
                        )}
                      </div>

                      <div className="new-questions__widget-box">
                        <h4 className="new-questions__widget-box-header">{t('projects.participants')}</h4>
                        <div className="new-questions__widget-box-body">
                          {selectedProject?.users?.length > 0 ? (
                            <AvatarStack
                              data={selectedProject?.users}
                              modalTitle={t('projects.titleProjectParticipants')}
                              className="avatar--xs"
                            />
                          ) : (
                            authorityLevel === 'admin' &&
                            !selectedProject.approved && (
                              <button
                                className="new-questions__widget-box-btn"
                                onClick={() => {
                                  getAllStudents();
                                  setShowModal({
                                    ...showModal,
                                    addParticipant: true
                                  });
                                }}
                              >
                                Vamos adicionar estudantes?
                              </button>
                            )
                          )}
                        </div>
                      </div>
                    </section>

                    <ProjectSchedule selectedProject={selectedProject} />
                  </div>
                </div>
              </>
            )}
          </div>

          <Modal
            show={showModal.addParticipant}
            onClose={() => setShowModal({ ...showModal, addParticipant: false })}
          >
            <h2 className="form__title">{t('projects.selectParticipants')}</h2>

            <div
              className="u-overflow-y"
              style={{ minHeight: '56px', maxHeight: '88vh' }}
            >
              <input
                style={{ position: 'sticky', top: '0' }}
                className="form__control u-mb-3"
                type="search"
                placeholder={t('courses.searchParticipants')}
                onChange={e => studentFilter(e.target.value)}
              />

              {filteredStudents?.length > 0 &&
                filteredStudents.map(student => (
                  <Card.StudentEmail
                    student={student}
                    profileImage={student.image.url}
                    registered
                    key={student.id}
                    studentId={student.id}
                    name={student.name}
                    studentEmail={student.email}
                    buttonText={t('button.add')}
                    onClick={() => {
                      setShowModal({ ...showModal, addParticipant: false });
                      addStudentToSelectedProject(student.id);
                    }}
                  />
                ))}
            </div>
          </Modal>

          <Modal
            show={showModal.removeParticipant}
            onClose={() => setShowModal({ ...showModal, removeParticipant: false })}
          >
            <h2 className="form__title">{t('courses.deleteParticipants')}</h2>

            <div
              className="u-overflow-y"
              style={{ minHeight: '56px', maxHeight: '85vh' }}
            >
              {selectedProject?.users?.length > 0 ? (
                selectedProject?.users.map(u => (
                  <Card.StudentEmail
                    student={u}
                    registered
                    key={u.id}
                    name={u.name}
                    studentEmail={u.email}
                    buttonText={t('button.remove')}
                    photo={u.image.url}
                    onClick={() => {
                      destroyStudentForm(u);
                    }}
                  />
                ))
              ) : (
                <p>Nenhum participante incluído no projeto</p>
              )}
            </div>
          </Modal>
        </div>
      )}
      {!ownProject && (
        <div className="new-questions__content">
          <EmptyState
            type="content"
            title={t('emptyState.projectNoAccessTitle')}
            text={t('emptyState.contactATeacherText')}
          />
        </div>
      )}
    </>
  );
}
