import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { FiFileText } from 'react-icons/fi';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import Loader from 'app/components/loader';
import EmptyState from 'app/components/EmptyState';
import { CardArchive, SectionArchive } from 'app/components/Questions';
import { t } from 'i18next';

export default function CourseClassroomQuestionBooks(props) {
  const { selectedItemId, activeTab, setEmptyTabs, selectedContent } = props;
  const client = useClient();
  const [loading, setLoading] = useState(true);
  const [questionBooks, setQuestionBooks] = useState([]);

  const getQuestionBooks = async () => {
    setLoading(true);
    const url = `question_books?filter[visibility]=train`;
    const { data, error } = await client.fetch(url);

    setLoading(false);
    if (error) {
      toast.error(t('toast.errorGetQuestionBooks'));
    } else {
      setQuestionBooks(data.filter(q => q?.['learning-system-ids'].includes(selectedItemId)));
      if (data.length > 0) {
        setEmptyTabs(false);
      }
    }
  };

  useEffect(() => {
    if (selectedItemId) {
      getQuestionBooks();
    }
  }, [selectedItemId]);

  return (
    activeTab && (
      <div className="tab__pane">
        <div className="tab__cards">
          {loading ? (
            <Loader />
          ) : questionBooks.length > 0 ? (
            (questionBooks || []).map(book => (
              <CardArchive
                key={book.id}
                book={book}
              />
            ))
          ) : (
            <EmptyState
              type="content"
              title="Sem conteúdo"
            />
          )}
        </div>
      </div>
    )
  );
}
