import React from 'react';
import logo from '../../images/siape/white-logo.svg';
import { useSession } from 'app/hooks/useSession';

const DefaultTerm = () => {
  const { session } = useSession();

  const getUserEmail = () => {
    if (session?.user?.email?.split('@')[1].includes('gmail')) {
      return 'https://mail.google.com/mail/?view=cm&to=suporte@suporte-plataforma.com.br';
    } else if (session?.user?.email?.split('@')[1].includes('hotmail') || session?.user?.email?.split('@')[1].includes('outlook')) {
      return 'https://outlook.live.com/owa/?to=suporte@suporte-plataforma.com.br';
    } else if (session?.user?.email?.split('@')[1].includes('yahoo')) {
      return 'https://compose.mail.yahoo.com/?to=suporte@suporte-plataforma.com.br';
    } else {
      return 'mailto:suporte@suporte-plataforma.com.br';
    }
  };

  return (
    <div className="terms__screen">
      <div className="terms__brand">
        <img
          alt="Logo SIAPE"
          src={logo}
        />
      </div>
      <div className="terms__container">
      <h1>Termos de Uso</h1>

<h2>1. Aceitação dos Termos</h2>
<p>
  Bem-vindo ao [Nome do Serviço] (“Serviço”). Ao acessar ou usar o Serviço, você concorda em cumprir e ser legalmente vinculado por estes Termos de Uso (“Termos”). Se você não concordar com estes Termos, não utilize o Serviço.
</p>

<h2>2. Descrição do Serviço</h2>
<p>
  O [Nome do Serviço] é uma plataforma de tecnologia voltada para a educação, proporcionando [descrever brevemente as funcionalidades principais, como aulas online, gestão de cursos, etc.].
</p>

<h2>3. Elegibilidade</h2>
<p>
  O Serviço está disponível apenas para indivíduos que tenham pelo menos 18 anos de idade ou tenham permissão de seus pais ou responsáveis legais. Ao usar o Serviço, você declara e garante que tem o direito, a autoridade e a capacidade de aceitar estes Termos e cumprir com todos os seus termos e condições.
</p>

<h2>4. Conta de Usuário</h2>

<h3>4.1. Registro</h3>
<p>
  Para utilizar certas funcionalidades do Serviço, você deve registrar-se e criar uma conta de usuário. Você concorda em fornecer informações verdadeiras, precisas, atuais e completas durante o processo de registro.
</p>

<h3>4.2. Segurança</h3>
<p>
  Você é responsável por manter a confidencialidade da sua senha e por todas as atividades que ocorram sob sua conta. Você concorda em notificar imediatamente o [Nome do Serviço] de qualquer uso não autorizado da sua conta ou qualquer outra violação de segurança.
</p>

<h2>5. Uso Aceitável</h2>
<p>
  Você concorda em usar o Serviço apenas para fins legais e de acordo com estes Termos. Você não deve:
</p>
<ul>
  <li>Usar o Serviço de qualquer maneira que possa prejudicar, desabilitar, sobrecarregar ou comprometer o Serviço ou interferir no uso de qualquer outra parte do Serviço.</li>
  <li>Tentar obter acesso não autorizado a qualquer parte do Serviço ou suas redes, servidores, ou sistemas relacionados.</li>
  <li>Envolver-se em qualquer atividade que viole os direitos de propriedade intelectual de terceiros ou que seja ilegal, ameaçadora, abusiva, difamatória, invasiva da privacidade de outrem, ou de qualquer maneira inapropriada.</li>
</ul>

<h2>6. Conteúdo do Usuário</h2>

<h3>6.1. Responsabilidade</h3>
<p>
  Você é o único responsável por todo o conteúdo que você carregar, publicar, ou de outra forma disponibilizar através do Serviço.
</p>

<h3>6.2. Licença</h3>
<p>
  Ao enviar conteúdo ao Serviço, você concede ao [Nome do Serviço] uma licença mundial, não exclusiva, sem royalties, sublicenciável e transferível para usar, reproduzir, distribuir, preparar trabalhos derivados, exibir e executar esse conteúdo em conexão com o Serviço.
</p>

<h2>7. Propriedade Intelectual</h2>
<p>
  Todos os direitos, títulos e interesses no e para o Serviço e seu conteúdo original (excluindo Conteúdo do Usuário) são e permanecerão propriedade exclusiva do [Nome do Serviço] e seus licenciadores. O Serviço é protegido por direitos autorais, marcas comerciais e outras leis do Brasil e de países estrangeiros.
</p>

<h2>8. Política de Privacidade</h2>
<p>
  Seu uso do Serviço também está sujeito à nossa Política de Privacidade, que descreve como coletamos, usamos e protegemos suas informações pessoais. Leia nossa Política de Privacidade atentamente.
</p>

<h2>9. Limitação de Responsabilidade</h2>
<p>
  O Serviço é fornecido "como está" e "conforme disponível". Na medida máxima permitida pela lei aplicável, o [Nome do Serviço] se isenta de todas as garantias, expressas ou implícitas, incluindo, mas não se limitando a, garantias de comercialização, adequação a um propósito específico e não violação.
</p>

<h2>10. Alterações aos Termos</h2>
<p>
  Podemos revisar estes Termos a qualquer momento. Se fizermos alterações materiais, notificaremos você por e-mail ou por meio do Serviço antes que as alterações entrem em vigor. Ao continuar a usar o Serviço após essas alterações entrarem em vigor, você concorda em estar vinculado aos Termos revisados.
</p>

<h2>11. Rescisão</h2>
<p>
  Podemos rescindir ou suspender sua conta e acesso ao Serviço imediatamente, sem aviso prévio ou responsabilidade, por qualquer motivo, incluindo, sem limitação, se você violar os Termos. Após a rescisão, seu direito de usar o Serviço cessará imediatamente.
</p>

<h2>12. Disposições Gerais</h2>

<h3>12.1. Lei Aplicável</h3>
<p>
  Estes Termos serão regidos e interpretados de acordo com as leis do Brasil, sem considerar seu conflito de provisões legais.
</p>

<h3>12.2. Acordo Integral</h3>
<p>
  Estes Termos constituem o acordo integral entre você e o [Nome do Serviço] em relação ao Serviço e substituem todos os acordos e entendimentos anteriores ou contemporâneos, escritos ou orais.
</p>

<h2>Contato</h2>
<p>
  Se você tiver quaisquer perguntas sobre estes Termos, por favor, entre em contato conosco em [endereço de e-mail de suporte].
</p>

<p><strong>Data de Vigência:</strong> [Data]</p>
      </div>
    </div>
  );
};

export default DefaultTerm;
