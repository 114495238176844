import React, { useEffect } from 'react';
import { Link } from '@reach/router';
import finishPage from 'app/images/finish-page.png';
import { useTranslation } from 'react-i18next';
import { i18n } from 'app/components/i18n/i18n';

function QuestionsFinish() {
  const { t } = useTranslation();
  const basePath = window.env.REACT_APP_BASE_PATH;

  useEffect(() => {
    i18n.changeLanguage(window.env.REACT_APP_LOCALE);
  }, []);

  return (
    <div className="main-screen">
      <main className="finish-page">
        <div
          className="finish-page__container"
          style={{ alignItems: 'flex-start' }}
        >
          <img
            src={finishPage}
            alt="Finish page"
          />

          <article className="finish-page__message">
            <p>
              <strong className="finish-page__strong">{t('exams.congratulationsFinishAnswers')}</strong>
            </p>

            <h1 className="finish-page__title">{t('exams.titleFinishPageSendAnswers')}</h1>

            <p>
              Em alguns minutos você terá acesso a um <b>plano de estudos personalizado</b>
              <br />
              baseado no seu desempenho neste simulado.
            </p>

            <p>{t('exams.thanksForParticipating')}</p>

            <Link
              to={basePath}
              className="btn btn--primary btn--wide"
            >
              {t('exams.closeExam')}
            </Link>
          </article>
        </div>
      </main>
    </div>
  );
}

export default QuestionsFinish;
