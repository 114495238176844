import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import StepName from './StepName';
import StepSchool from './StepSchool';
import { useAuditorium } from 'app/hooks/useAuditorium';
import * as Yup from 'yup';
import Loader from 'app/components/loader';
import ScheduleAlert from 'app/components/ScheduleAlert';
import ScheduleModal from 'app/components/ScheduleModal';
import { useClient } from 'jsonapi-react';
import moment from 'moment';

export default function AuditoriumContent(props) {
  const { courses, getCourses, auditorium, updateAuditorium, setCourses, lastLesson } = useAuditorium();
  const { step, steps, currentStep, id, setCurrentStep } = props;
  const client = useClient();
  const basePath = window.env.REACT_APP_BASE_PATH;

  const [showScheduleAlert, setShowScheduleAlert] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentAuditoriumId, setCurrentAuditoriumId] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    getCourses();
    return () => {
      setCourses([]);
    };
  }, [auditorium?.courses]);

  useEffect(() => {
    createAuditoriumForm.setValues({
      ...createAuditoriumForm.values,
      name: id ? auditorium?.name : createAuditoriumForm.values.name,
      'course-ids': id ? courses.filter(course => auditorium['course-ids'].includes(course?.id)) : [],
      'auditorium-lessons-attributes': [
        {
          name: id ? lastLesson?.name : createAuditoriumForm?.values?.['auditorium-lessons-attributes']?.[0].name
        }
      ],
      authored_by_user: auditorium['authored-by-user'],
      official_content: auditorium['official-content']
    });
  }, [id, courses, auditorium]);

  const handleSubmit = async e => {
    e.preventDefault();
    switch (step) {
      case 'nome':
        createAuditoriumForm.setFieldTouched('name', true);
        createAuditoriumForm.setFieldTouched('auditorium-lessons-attributes[0].name', true);
        if (!createAuditoriumForm.errors.name && !createAuditoriumForm.errors['auditorium-lessons-attributes']?.[0].name && !id) {
          navigate(`${basePath}/auditorio/criar/escola`);
          setCurrentStep(1);
        } else if (!createAuditoriumForm.errors.name && !createAuditoriumForm.errors['auditorium-lessons-attributes']?.[0].name && id) {
          navigate(`${basePath}/auditorio/editar/escola/${auditorium?.id}`);
          setCurrentStep(1);
        }
        break;
      case 'escola':
        createAuditoriumForm.setFieldTouched('course-ids', true);
        if (!createAuditoriumForm.errors?.['course-ids']) {
          createAuditorium();
        }
        break;
    }
  };

  const schema = Yup.object({
    name: Yup.string().required(t('warning.requiredField')),
    'auditorium-lessons-attributes': Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(t('warning.requiredField'))
      })
    ),
    'course-ids': Yup.array().of(Yup.object()).min(1, t('warning.minSubject'))
  });

  const createAuditoriumForm = useFormik({
    initialValues: {
      name: '',
      'course-ids': [],
      'auditorium-lessons-attributes': [
        {
          name: ''
        }
      ],
      authored_by_user: false,
      official_content: false
    },
    validationSchema: schema,
    onSubmit: handleSubmit
  });

  const requestParams = {
    name: createAuditoriumForm?.values?.name,
    'course-ids': createAuditoriumForm.values?.['course-ids'].map(course => course?.id),
    'auditorium-lessons-attributes': [
      {
        name: createAuditoriumForm.values?.['auditorium-lessons-attributes']?.[0]?.name
      }
    ],
    authored_by_user: createAuditoriumForm.values.authored_by_user,
    official_content: createAuditoriumForm.values.official_content
  };

  const createAuditorium = async () => {
    if (id) {
      await updateAuditorium(requestParams, id);
      setShowScheduleAlert(true);
    } else {
      const { data, error } = await client.mutate(`auditoriums`, requestParams);

      if (error) {
        toast.error(t('toast.errorCreateAuditorium'));
        return;
      }

      setCurrentAuditoriumId(data?.id);
      setShowScheduleAlert(true);

      toast.success(t('toast.successCreateAuditorium'));
    }
  };

  const handleCloseAlertSchedule = () => {
    setShowScheduleAlert(false);
    navigate(`${basePath}/auditorio`);
  };

  const updateSchedule = async (publishedDate = null) => {
    const scheduleDate = publishedDate ? { 'published-at': moment(publishedDate).format() } : { published: true };

    await updateAuditorium(scheduleDate, id ? id : currentAuditoriumId);
  };

  const [fieldError, setFieldError] = useState(false);

  const btnsStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '24px'
  };

  return (
    <div className="page page--wrap">
      {courses.length > 0 ? (
        <div className="page__col">
          <form
            className="form"
            method="post"
            onSubmit={handleSubmit}
          >
            <StepName
              step={step}
              auditorium={auditorium}
              form={createAuditoriumForm}
              fieldError={fieldError}
            />
            <StepSchool
              step={step}
              form={createAuditoriumForm}
              courses={courses}
            />

            <div style={btnsStyles}>
              {step !== 'nome' && !id ? (
                <button
                  type="button"
                  className="btn btn--outline btn--wide"
                  onClick={() => {
                    navigate(`${basePath}/auditorio/criar/${steps?.[currentStep - 1]?.route}`);
                    setCurrentStep(currentStep - 1);
                  }}
                >
                  {t('button.previous')}
                </button>
              ) : (
                step !== 'nome' && (
                  <button
                    type="button"
                    className="btn btn--outline btn--wide"
                    onClick={() => {
                      navigate(`${basePath}/auditorio/editar/${steps?.[currentStep - 1]?.route}/${id}`);
                      setCurrentStep(currentStep - 1);
                    }}
                  >
                    {t('button.previous')}
                  </button>
                )
              )}

              <button
                type="submit"
                className="btn btn--primary btn--wide"
              >
                {step !== 'escola' ? t('button.next') : t('button.finish')}
              </button>
            </div>
          </form>

          <ScheduleAlert
            show={showScheduleAlert}
            onClose={() => handleCloseAlertSchedule()}
            setShowModal={setShowModal}
            onUpdate={updateSchedule}
            navigateLink={`${basePath}/auditorio`}
          />

          <ScheduleModal
            show={showModal}
            onClose={() => setShowModal(true)}
            onUpdate={updateSchedule}
            navigateLink={`${basePath}/auditorio`}
            fieldValue={id ? moment(auditorium['published-at']).format('YYYY-MM-DD') : ''}
            auditorium={auditorium}
          />
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}
