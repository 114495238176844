import React from 'react';
import { useTranslation } from 'react-i18next';

export const FilterSelectionBoxInstitution = props => {
  const { t } = useTranslation();
  const { onChange, defaultValue, label, options, optionBlank = 'blank', blankLabel = t('filter.blankLabel'), disabled, className = '', ...restProps } = props;

  return (
    <div className={disabled ? 'filter-bar__row disabled' : 'filter-bar__row'}>
      <label
        className="filter-bar__label"
        htmlFor={label}
      >
        {label}
      </label>  
      <select
        className={`filter-bar__select ${className}`}
        id={label}
        name={label}
        onChange={onChange}
        disabled={disabled}
        defaultValue={defaultValue}
        {...restProps}
      >
        {blankLabel && <option value={optionBlank}>{blankLabel}</option>}
        {options?.map(option => (
          <option
            key={option.id}
            value={option.id}
          >
            {option.name ?? option.title ?? option.body}
          </option>
        ))}
      </select>
    </div>
  );
};
