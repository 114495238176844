import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Link } from '@reach/router';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import FilterMultiSelect from 'app/components/FilterMultiSelect';
import ExamCard from './ExamCard';
import ExamsResolution from './ExamsResolution';
import AboutExam from './AboutExam';
import StudentResponse from './ExamsResolution/StudentResponse';
import getPermission from 'app/utils/getPermission';
import QuestionBook from '../ProgramOfStudies/QuestionBook';
import { useTranslation } from 'react-i18next';
import EmptyState from 'app/components/EmptyState';
import Loader from 'app/components/loader';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import Select from 'react-select';

function Exams(props) {
  const { t } = useTranslation();
  const client = useClient();
  const { courses, exams, handleDeleteExam, activeTabs, loading } = props;
  const [classrooms, setClassrooms] = useState([]);
  const [filteredExams, setFilteredExams] = useState(exams);
  const [filteredClassrooms, setfilteredClassrooms] = useState([]);
  const [loadingClassrooms, setLoadingClassrooms] = useState(false);
  const basePath = window.env.REACT_APP_BASE_PATH;

  const filters = useFormik({
    initialValues: {
      course: 'blank',
      classroom: 'blank',
      status: {
        naoRealizada: false,
        realizada: false,
        emAndamento: false
      }
    }
  });

  const getClassrooms = async () => {
    setLoadingClassrooms(true);
    const { data, error } = await client.fetch('/classrooms?simple=true');
    if (error) {
      toast.error('Erro ao buscar turmas');
    } else {
      setClassrooms(data);
    }
    setLoadingClassrooms(false);
  };

  useEffect(() => {
    getClassrooms();
  }, []);

  useEffect(() => {
    const filteredClassrooms = classrooms.filter(item => item?.course?.id === parseInt(filters.values.course));

    setfilteredClassrooms(filteredClassrooms);
  }, [filters.values.course]);

  useEffect(() => {
    const { course, status, classroom } = filters.values;

    const statusList = [];
    const items = {
      naoRealizada: 'Não realizada',
      realizada: 'Realizada',
      emAndamento: 'Em andamento'
    };

    Object.keys(items).map(key => {
      if (status[key]) {
        statusList.push(items[key]);
      }
    });

    setFilteredExams(
      exams.filter(exam => {
        return (statusList.length === 0 || statusList.includes(exam.status)) && (course === 'blank' || exam['course-ids'] == course) && (classroom.value === 'blank' || exam['classroom-ids'] == classroom.value);
      })
    );
  }, [exams, filters.values.classroom, filters.values.course, filters.values.status.naoRealizada, filters.values.status.realizada, filters.values.status.emAndamento]);

  const handleChange = field => {
    const newStatus = filters.values.status;
    newStatus[field] = !newStatus[field];
    filters.setFieldValue('status', newStatus);
  };

  useEffect(() => {
    if (filters.values.course === 'blank') {
      filters.setFieldValue('classroom', { value: 'blank', label: t('filter.blankLabelAll') });
    }
  }, [filters.values.course]);

  return (
    <>
      <div className="filter-bar">
        <div className="filter-bar__inner">
          <FilterSelectionBox
            label={t('filter.labelSelectCourse')}
            blankLabel={t('filter.blankLabelAll')}
            value={filters.values.course}
            onChange={e => {
              filters.setFieldValue('course', e.target.value);
            }}
            options={courses}
          />
          <div>
            <label className="form__label">Selecione uma turma</label>
            <Select
              data-for="select-tooltip"
              isDisabled={filters.values.course === 'blank'}
              value={filters.values.classroom}
              openMenuOnFocus
              options={[
                { value: 'blank', label: t('filter.blankLabelAll') },
                ...filteredClassrooms?.map(item => {
                  return { value: item.id, label: item?.institution?.name + ' - ' + item?.course?.title + ' - ' + item.title };
                })
              ]}
              className="react-multi-select filter-bar__multi-select"
              classNamePrefix="react-multi-select"
              placeholder={loadingClassrooms ? 'Carregando...' : t('filter.blankLabelAll')}
              noOptionsMessage={() => 'Sem opções'}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null
              }}
              onChange={e => filters.setFieldValue('classroom', e)}
            />
          </div>

          <FilterMultiSelect
            label={t('filter.labelSelectStatus')}
            data={[
              {
                label: t('filter.unrealized'),
                value: filters.values.status.naoRealizada,
                onChange: () => handleChange('naoRealizada')
              },
              {
                label: t('filter.performed'),
                value: filters.values.status.realizada,
                onChange: () => handleChange('realizada')
              },
              {
                label: t('filter.labelInProgress'),
                value: filters.values.status.emAndamento,
                onChange: () => handleChange('emAndamento')
              }
            ]}
          />
        </div>

        <div className="filter-bar__actions">
          {getPermission('Criar provas', 'Avaliações') && (
            <Link
              className="btn btn--primary btn--wide"
              to={`${basePath}/provas/criar/curso`}
            >
              {t('exams.buttonCreateExam')}
            </Link>
          )}
          {getPermission('Gerenciar provas', 'Avaliações') && (
            <Link
              state={{ activeTabs: activeTabs }}
              className="btn btn--primary btn--wide"
              to={`${basePath}/provas/gerenciar`}
            >
              {t('exams.buttonManageExams')}
            </Link>
          )}
        </div>
      </div>

      <div className="fadeIn">
        {loading && <Loader />}

        {filteredExams.length === 0 && !loading && (
          <EmptyState
            type="data"
            text="Nenhuma prova encontrada."
          />
        )}

        <div className="tab__cards">
          {filteredExams?.map(exam => (
            <ExamCard
              filteredExams={filteredExams}
              activeTabs={activeTabs}
              handleDeleteExam={handleDeleteExam}
              exam={exam}
              key={exam?.id}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export { Exams as default, ExamsResolution, AboutExam, StudentResponse };
