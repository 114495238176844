import React, { useState } from 'react';
import { Link, navigate, useLocation, useParams } from '@reach/router';
import { FiChevronLeft, FiMoreHorizontal, FiPlus } from 'react-icons/fi';
import Dropdown from 'app/components/Dropdown';
import MainSidebar from 'app/components/Sidebar';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import Swal from 'sweetalert2';
import { useEffect } from 'react';
import Loader from 'app/components/loader';
import EmptyState from 'app/components/EmptyState';
import { t } from 'i18next';

export default function Sidebar(props) {
  const { course, currentModuleId, setCurrentModuleId, loading, setCount, selectedTab } = props;
  const client = useClient();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredModules, setFilteredModules] = useState([]);
  const [click, setClick] = useState(0);
  const { moduleId } = useParams();
  const basePath = window.env.REACT_APP_BASE_PATH;

  useEffect(() => {
    setCurrentModuleId(window?.history?.state?.moduleId ?? moduleId);
  }, [click]);

  useEffect(() => {
    const filteredModules = course?.['learning-systems'].filter(item => {
      if (searchTerm == '') {
        return true;
      } else if (item.title.toLowerCase().includes(searchTerm.toLowerCase())) {
        return true;
      }
    });

    setFilteredModules(filteredModules);
  }, [course, searchTerm]);

  const deleteModule = async item => {
    const { error } = await client.delete(`learning_systems/${item.id}`);
    if (error) {
      toast.error('Erro ao deletar módulo');
    } else {
      toast.success('Módulo excluido com sucesso!');
      const updatedModulesList = filteredModules.filter(module => module.id !== item.id);
      setFilteredModules(updatedModulesList);
      setCount(prevState => prevState + 1);
      // window.history.pushState({ moduleId: course?.['learning-systems']?.[0]?.id }, '', `/curso/admin/gerenciar/${course?.id}/${course?.['learning-systems']?.[0]?.id}`);
      // setClick(prevState => prevState + 1);
    }
  };

  const shortByCreation = (a, b) => {
    const dateA = new Date(a?.['updated_at']);
    const dateB = new Date(b?.['updated_at']);
    return dateB - dateA;
  };

  filteredModules?.sort(shortByCreation);

  const handleDelete = item => {
    Swal.fire({
      title: t('projects.warningDeleteModule'),
      text: t('projects.warningDeleteCannotBeUndoneText'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteModule(item);
      }
    });
  };

  return (
    <MainSidebar hasBrand={false}>
      <nav className="notebook-sidebar__nav">
        <div className="filter-bar">
          <button
            className="filter-bar__back"
            onClick={() => navigate(`${basePath}/curso/admin`)}
          >
            <div className="card__icon-wrapper">
              <FiChevronLeft />
            </div>
            <span
              className="filter-bar__back-container"
              title={course?.title}
            >
              <span className="filter-bar__title">{course?.title}</span>
            </span>
          </button>
        </div>

        <div className="form__row">
          <label
            htmlFor="moduleSearch"
            className="form__label"
          >
            {t('textsCommon.modules')}
          </label>
          <input
            aria-label="Pesquisar módulos"
            className="form__control form__control--search-with-icon"
            placeholder={t('textsCommon.research')}
            type="search"
            name="moduleSearch"
            id="moduleSearch"
            onChange={e => setSearchTerm(e.target.value)}
          />
        </div>

        {course?.id ? (
          <Link to={`${basePath}/lms/criar/modulo/nome/${course?.id}`}>
            <button className="btn btn--outline-dashed btn--small btn--full">
              <FiPlus className="btn__icon" /> {t('button.newModule')}
            </button>
          </Link>
        ) : (
          <Loader />
        )}

        <hr className="u-hr" />

        <ul className="module-list">
          {loading && <Loader />}
          {filteredModules && !loading && filteredModules.length === 0 && <EmptyState type="data" />}
          {filteredModules &&
            !loading &&
            filteredModules?.map((item, index) => (
              <li
                onClick={() => {
                  setClick(prevState => prevState + 1);
                  navigate(`${basePath}/curso/admin/gerenciar/${course?.id}/${item.id}/${selectedTab}/d`);
                }}
                className={currentModuleId == item.id ? 'module-list__item active' : 'module-list__item'}
                key={index}
              >
                <h3 className="module-list__title">{item.title}</h3>

                <Dropdown>
                  <Dropdown.Toggle>
                    <FiMoreHorizontal />
                  </Dropdown.Toggle>

                  <Dropdown.Content>
                    <Dropdown.Item
                      onClick={() => {
                        navigate(`${basePath}/show-course/${item.id}/classroom`);
                      }}
                    >
                      {t('button.view')}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        navigate(`${basePath}/lms/criar/modulo/nome/${item.id}/${course?.id}`);
                      }}
                    >
                      {t('button.edit')}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        handleDelete(item);
                      }}
                    >
                      {t('button.delete')}
                    </Dropdown.Item>
                  </Dropdown.Content>
                </Dropdown>
              </li>
            ))}
        </ul>
      </nav>
    </MainSidebar>
  );
}
