import { useTranslation } from 'react-i18next';

import { TopBar } from './TopBar';
import { ShadowContainer } from './ShadowContainer';

import analysingPng from '../../images/onboarding/studyPlan/analysing.png';
import accessingPng from '../../images/onboarding/studyPlan/accessing.png';
import navigatingPng from '../../images/onboarding/studyPlan/navigating.png';
import studyingPng from '../../images/onboarding/studyPlan/studying.png';

export function StudyPlanOnboarding() {
  const { t } = useTranslation();

  return (
    <section>
      <TopBar />

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.analyzingYourIncome')}</h1>
        <p className="text-option-onboarding">{t('onboarding.analyzingYourIncomeDesc')}</p>

        <ShadowContainer>
          <img src={analysingPng} />
        </ShadowContainer>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.accessStudyPlan')}</h1>
        <p className="text-option-onboarding">{t('onboarding.accessStudyPlanDesc')}</p>

        <ShadowContainer width="40%">
          <img src={accessingPng} />
        </ShadowContainer>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.navigatingStudyPlan')}</h1>
        <p className="text-option-onboarding">{t('onboarding.navigatingStudyPlanDesc')}</p>

        <ShadowContainer>
          <img src={navigatingPng} />
        </ShadowContainer>
      </div>

      <div>
        <h1 className="title-option-onboarding">{t('onboarding.studyingStudyPlan')}</h1>
        <p className="text-option-onboarding">{t('onboarding.studyingStudyPlanDesc')}</p>

        <img src={studyingPng} />
      </div>
    </section>
  );
}
