import React, { useState, useEffect } from 'react';
import { FiStar } from 'react-icons/fi';
import { useSurvey } from 'app/hooks/useSurvey';
import Modal from 'app/components/Modal';
import SurveyAnswer from 'app/components/Survey/SurveyAnswer';
import FirstTime from 'app/components/EmptyState/FirstTime';
import MyProgressWidget from './MyProgressWidget';
import { t } from 'i18next';
import WallSlider from './WallSlider';
import ShortcutWidget from './ShortcutWidget';
import { useQuery } from 'jsonapi-react';
import Loader from 'app/components/loader';
import moment from 'moment';
import WarningWall from './WarningWall';

export default function DashboardStudent(props) {
  const { getStudentSurvey, currentStudentSurvey } = useSurvey();
  const initialDate = moment().startOf('month').format('YYYY-MM-DD');
  const endDate = moment().endOf('month').format('YYYY-MM-DD');
  const { data, isLoading } = useQuery(`/bulletin_boards?filter[date_between][initial]=${initialDate}&filter[date_between][final]=${endDate}`);

  const [showSurvey, setShowSurvey] = useState(false);

  useEffect(() => getStudentSurvey(), []);

  const onCloseSurvey = () => {
    setShowSurvey(false);
    getStudentSurvey();
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : data?.length >= 1 ? (
        <WallSlider data={data} />
      ) : (
        <WarningWall
          warning={data?.[0]}
          key={data?.[0]?.id}
        />
      )}

      <ShortcutWidget />

      {currentStudentSurvey && (
        <div className="info-banner u-mb-4">
          <div className="info-banner__inner">
            <span className="info-banner__icon">
              <FiStar />
            </span>
            <h3 className="info-banner__title">{t('nps.newQuiz')}</h3>
            <p className="info-banner__description">Queremos saber a sua opinião</p>
          </div>

          <button
            onClick={() => setShowSurvey(true)}
            className="btn btn--outline"
          >
            Responder
          </button>
        </div>
      )}

      <MyProgressWidget />

      {/* <ImportantLog />

      <ActivityLog useType={1} /> */}

      <Modal
        show={showSurvey}
        onClose={() => setShowSurvey(false)}
        contentClassName="modal__content--p-md"
      >
        {currentStudentSurvey && (
          <SurveyAnswer
            studentSurvey={currentStudentSurvey}
            onClose={onCloseSurvey}
          />
        )}
      </Modal>
    </>
  );
}
