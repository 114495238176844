import React, { useEffect } from 'react';
import { FiCheck, FiUser, FiUsers, FiHelpCircle } from 'react-icons/fi';
import { useFormik } from 'formik';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { t } from 'i18next';
import { useQuery } from 'jsonapi-react';
import Loader from 'app/components/loader';
import toast from 'react-hot-toast';
import { TableGRE } from './TableGRE';

export default function ViewGRE({ gre, setItems, filter }) {
  const requestQuery = gre ? `/regionals/${gre.id}/registration_report` : null
  const { data, isLoading, error } = useQuery([requestQuery, { filter }]);

  const filters = useFormik({
    initialValues: {
      searchTerm: ''
    }
  });

  const kpis = [
    {
      id: 1,
      icon: <FiCheck />,
      title: 'Estudantes cadastrados',
      value: data?.registeredStudents,
      help: 'Número total de estudantes cadastrados de acordo com o filtro selecionado.'
    },
    {
      id: 2,
      icon: <FiUsers />,
      title: 'Maior número de cadastros',
      value: data?.mostRegistrations?.name,
      help: 'Analise as estratégias da região com maior número de cadastros para identificar práticas bem-sucedidas.'
    },
    {
      id: 3,
      icon: <FiUser />,
      title: 'Menor número de cadastros',
      value: data?.leastRegistrations?.name,
      help: 'Analise as estratégias da região com menor número de cadastros para desenvolver ações de melhoria.'
    }
  ];

  useEffect(() => {
    if (error) {
      toast.error('Erro ao buscar dados da secretaria');
    }
  }, [error]);

  useEffect(() => {
    if (data?.institutions?.length > 0) {
      setItems(data?.institutions);
    }
  }, [data]);

  if (isLoading) return <Loader />;

  return (
    <div className="course-about__module fadeIn">
      <div className="widget-wrapper">
        <div className="kpi-cards">
          {kpis.map(kpi => (
            <div
              key={kpi.id}
              className="kpi-card"
            >
              <FiHelpCircle
                className="kpi-card__help-icon"
                data-tooltip-id={kpi.id}
                data-tooltip-content={kpi.help}
              />
              <ReactTooltip
                id={kpi.id}
                className="tooltip"
              />
              <div className="kpi-card__icon">{kpi.icon}</div>
              <div className="kpi-card__title">{kpi.title}</div>
              <div className="kpi-card__value">{kpi.value}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="filter-bar filter-bar--borderless">
        <div className="filter-bar__inner">
          <div className="filter-bar__row">
            <label
              htmlFor="search"
              className="filter-bar__label"
            >
              Buscar
            </label>
            <input
              className="form__control form__control--search-with-icon"
              placeholder={t('textsCommon.research')}
              type="search"
              name="search"
              id="search"
              onChange={e => filters.setFieldValue('searchTerm', e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="round-dg-wrapper">
        <TableGRE
          regionals={data?.institutions}
          searchValue={filters.values.searchTerm}
        />
      </div>
    </div>
  );
}
