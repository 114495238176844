import { SessionContext } from 'app/providers/SessionProvider';
import moment from 'moment';
import { useContext, useState } from 'react';
import trashIcon from 'app/images/icons/trash-icon.svg';
import { Link } from '@reach/router';
import Swal from 'sweetalert2';
import toast from 'react-hot-toast';
import userAvatar from '../../images/user-avatar.svg';
import { FiSend } from 'react-icons/fi';

export function AnswerCommentForm({ onSubmit, answers, isOpenAnswer, onDelete = () => null }) {
  const state = useContext(SessionContext);
  const [answerValue, setAnswerValue] = useState('');
  const [showLess, setShowLess] = useState(true);
  const basePath = window.env.REACT_APP_BASE_PATH;

  const handleSubmit = () => {    
    onSubmit(answerValue);
    setAnswerValue('');
  };

  const handleDeleteAnswer = answerId => {
    Swal.fire({
      title: 'Deseja mesmo excluir este comentário?',
      text: 'Esta ação não poderá ser desfeita',
      confirmButtonText: 'Sim',
      showDenyButton: true,
      denyButtonText: 'Não',
      showCloseButton: true
    }).then(async res => {
      if (res.isConfirmed) {
        await onDelete(answerId);
        toast.success('Comentário excluído com sucesso');
      }
    });
  };

  return (
    <div>
      {isOpenAnswer && (
        <div className="container-submit-answers">
          <input
            className="form__control"
            placeholder="Respondendo"
            value={answerValue}
            onChange={e => setAnswerValue(e.target.value)}
          />
          <button
            onClick={handleSubmit}
            className="btn btn--primary btn--send"
            disabled={answerValue.length === 0}
          >
            <FiSend />
          </button>
        </div>
      )}

      {answers.length > 0 && (
        <>
          {answers.map((item, index) => (
            <div
              key={item.id}
              className="post-comment"
              style={{ display: (showLess && index === 0) || (showLess && index === 1) ? 'grid' : (index !== 0 && showLess) || (index !== 1 && showLess) ? 'none' : 'grid', gridTemplateColumns: '30px 1fr 30px' }}
            >
              <Link to={`${basePath}/usuario/metricas/${item.user.id}`}>
                <div
                  className="post-comment__avatar"
                  style={{
                    backgroundImage: `url(${item?.user?.image?.url || userAvatar})`
                  }}
                ></div>
              </Link>

              <div className="post-comment__body w-full overflow-auto">
                <div className="post-comment__header">
                  <Link to={`${basePath}/usuario/metricas/${item.user.id}`}>
                    <div className="post-comment__header-name">{item?.user?.name}</div>
                  </Link>
                  <div className="post-comment__header-time">{moment(item['created_at'] || item['created-at']).fromNow()}</div>
                </div>
                <div className="post-comment__description">
                  <p className="post-comment__description-text m-0 word-break">{item.body}</p>
                </div>
              </div>

              <div
                className="post-comment__actions"
                style={{ alignSelf: 'flex-start' }}
              >
                {state.session && state.session.user.id === item?.user?.id && (
                  <div
                    className="post-comment__action"
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <div
                      className="post-comment__action-icon"
                      onClick={() => handleDeleteAnswer(item.id)}
                      style={{ margin: 0 }}
                    >
                      <img
                        alt=""
                        src={trashIcon}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}

          {answers.length > 2 && (
            <button
              className="btn--answers"
              onClick={() => setShowLess(prev => !prev)}
            >
              {showLess ? 'mostrar mais' : 'mostrar menos'}
            </button>
          )}
        </>
      )}
    </div>
  );
}
