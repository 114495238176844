import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import { Link } from '@reach/router';
import { FiAlertCircle } from 'react-icons/fi';
import Loader from 'app/components/loader';
import QuestionBookCard from './QuestionBookCard';

export default function QuestionForm(props) {
  const client = useClient();
  const { setShowModal, onSuccess, lessonId, deleteNotebook, questionListedIds } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [showQuestionBook, setShowQuestionBook] = useState([]);
  const [loading, setLoading] = useState(true);
  const [key, setKey] = useState(1);
  const [questionbooks, setQuestionbooks] = useState([]);
  const [questionbooksRequireds, setQuestionbooksRequireds] = useState({});
  const basePath = window.env.REACT_APP_BASE_PATH;

  const addQuestionbook = questionbook => {
    setQuestionbooks([...questionbooks, questionbook]);
  };
  const setRequireds = (questionbook, isRequired) => {
    setQuestionbooksRequireds({
      ...questionbooksRequireds,
      [questionbook.id]: isRequired
    });
  };

  function removeQuestionbook(questionbookid) {
    setQuestionbooks(questionbooks.filter(item => item['question-book-id'] !== questionbookid));
  }

  const getQuestionBook = async () => {
    const { data, error } = await client.fetch('question_books');
    if (error) {
      toast.error('Falha ao carregar cadernos de questões');
    } else {
      setShowQuestionBook(data.filter(d => !questionListedIds.includes(d.id)));
      setLoading(false);
    }
  };

  const addNotebook = async () => {
    const { error } = await client.mutate(`/learning_system_items/${lessonId}`, {
      'learning-system-question-books-attributes': questionbooks.map(question => {
        return { ...question, ['is-required']: questionbooksRequireds[question['question-book-id']] };
      })
    });
    if (error) {
      toast.error('Caderno já criado');
    } else {
      setShowModal(false);
      onSuccess();
      setKey(key + 1);
      setQuestionbooks([]);
    }
  };

  useEffect(() => {
    getQuestionBook();
  }, [key]);

  const filteredQuestionbook = showQuestionBook.filter(questionbook => {
    if (searchTerm == '') {
      return questionbook;
    }
    if (questionbook.title != null && questionbook.title.toLowerCase().includes(searchTerm.toLocaleLowerCase())) {
      return questionbook;
    }
  });

  return (
    <form
      method="post"
      className="form"
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <h2 className="modal__simple-title">Apostila</h2>

      <input
        style={{ position: 'sticky', top: '0' }}
        className="form__control u-mb-3"
        type="search"
        placeholder="Buscar apostila..."
        onChange={e => setSearchTerm(e.target.value)}
      />

      <div
        class="alert u-mb-2"
        role="alert"
      >
        <FiAlertCircle className="alert__icon" />
        <span>
          <strong>Resposta obrigatória:</strong> O estudante só avançará para a próxima aula após a realização do caderno.
        </span>
      </div>

      <div
        className="u-overflow-y"
        style={{
          minHeight: '56px',
          maxHeight: '368px',
          padding: '16px',
          margin: '0 -16px 1.5em -16px'
        }}
      >
        {loading && <Loader />}

        {filteredQuestionbook.map(questionbook => (
          <QuestionBookCard
            setRequireds={setRequireds}
            isSelected={Boolean(questionbooks.find(item => item['question-book-id'] === questionbook.id))}
            addQuestionbook={addQuestionbook}
            removeQuestionbook={removeQuestionbook}
            questionbook={questionbook}
            key={questionbook.id}
            isRequired={questionbooksRequireds[questionbook.id]}
          />
        ))}
      </div>

      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '12px'
        }}
      >
        <button
          onClick={addNotebook}
          className="btn btn--primary btn--wide"
          type="submit"
        >
          Salvar e finalizar
        </button>
        <Link
          to={`${basePath}/ementa/cadernos-de-questoes`}
          className="btn btn--link"
          type="submit"
        >
          Criar um novo caderno
        </Link>
      </div>
    </form>
  );
}
