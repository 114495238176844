import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { navigate } from '@reach/router';
import moment from 'moment';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import Modal from 'app/components/Modal';
import { useClassroom } from 'app/hooks/useClassroom';
import { useCourse } from 'app/hooks/useCourse';
import Error404 from 'app/components/Error404';
import getPermission from 'app/utils/getPermission';
import EmptyState from 'app/components/EmptyState';
import Loader from 'app/components/loader';
import Select from 'react-select';
import ClassroomCard from './ClassroomCard';
import TabNavBar from './TabNavBar';
import { ManageClassroomParticipants } from './ManageClassroomParticipants';
import ClassroomInvitations from './ClassroomInvitations';
import ViewInvitations from './ClassroomInvitations/ViewInvitations';
import ClassroomImports from './ClassroomImports';
import ViewImports from './ClassroomImports/ViewImports';
import { t } from 'i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as Yup from 'yup';
import promiseRequest from 'app/utils/promiseToast';
import BreadCrumbs from 'app/components/BreadCrumbs';
import { FiHelpCircle } from 'react-icons/fi';

export function Classrooms(props) {
  const { courseId, uri } = props;
  const basePath = window.env.REACT_APP_BASE_PATH;

  const [show, setShow] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editClassroomTitle, setEditClassroomTitle] = useState('');
  const [currentClassroom, setCurrentClassroom] = useState('');
  const [institutions, setInstitutions] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [roles, setRoles] = useState([]);
  const client = useClient();
  const { loading, classrooms, getClassrooms, selectedClassroom, setSelectedClassroom, hasMoreClassrooms, getClassroomsPagination } = useClassroom();
  const { courses, getCourses } = useCourse();
  const { promiseToast, isSubmitting } = promiseRequest();

  const filters = useFormik({
    initialValues: {
      discipline: courseId || 'blank',
      institution_id: ''
    }
  });

  const schema = Yup.object({
    title: Yup.string().required(t('warning.requiredField')),
    course_id: Yup.string().required(t('warning.requiredField'))
  });

  const newClassroomForm = useFormik({
    initialValues: {
      title: '',
      course_id: '',
      institution_id: '',
      initial_date: moment(),
      end_date: moment(),
      'organizational-chart-ids': []
    },
    validationSchema: schema,
    onSubmit: async values => {
      const parsedForm = {
        ...values,
        initial_date: moment(values.initial_date).format('YYYY-MM-DD'),
        end_date: moment(values.end_date).format('YYYY-MM-DD')
      };

      promiseToast({
        url: 'classrooms',
        request: parsedForm,
        successText: t('toast.successSaving'),
        errorText: t('toast.errorCreateClass')
      }).then(data => {
        getClassrooms();
        navigate(`${basePath}/turmas/gerenciar/${data.id}`);
      });
      newClassroomForm.resetForm();
    }
  });

  useEffect(() => {
    getClassroomsPagination(pageNumber);
    if (courses.length === 0) {
      getCourses();
    }
  }, []);

  const editClassroomForm = classroom => {
    setCurrentClassroom(classroom);
    setEditClassroomTitle(classroom.title);
    setEditModal(true);
  };

  const editCurrentClassroom = async e => {
    e.preventDefault();

    const { data, error } = await client.mutate(['classrooms', currentClassroom.id], {
      title: editClassroomTitle
    });

    if (error) {
      toast.error('Turma não pode ser editada!');
    } else {
      setSelectedClassroom({ ...selectedClassroom, name: data.title });

      let newListEdit = classrooms;
      let newClassroom = _.find(newListEdit, ['id', currentClassroom.id]);
      newClassroom.title = data.title;

      toast.success('Turma editada!');
    }

    setEditModal(false);
    setCurrentClassroom(null);
    setEditClassroomTitle('');
    getClassrooms();
  };

  const getInstitutions = async () => {
    const { data, error } = await client.fetch('/institutions');
    if (error) {
      toast.error('Erro ao buscar instituições');
    } else {
      setInstitutions(data);
    }
  };

  const getRoles = async () => {
    function buildItemMap(items) {
      const itemMap = new Map();
      items.forEach(item => {
        itemMap.set(item.id, { ...item, children: [] });
      });

      items.forEach(item => {
        const parentId = item['organizational-chart-parent-id'];
        if (parentId) {
          itemMap.get(parentId).children.push(item.id);
        }
      });

      return itemMap;
    }

    function countUsers(itemMap, itemId) {
      const item = itemMap.get(itemId);
      let count = item['user-ids'].length;

      item.children.forEach(childId => {
        count += countUsers(itemMap, childId);
      });

      return count;
    }

    function getUsersCount(items) {
      const itemMap = buildItemMap(items);
      return items.map(item => {
        const usersCount = countUsers(itemMap, item.id);
        return { id: item.id, title: item.title, 'users-count': usersCount };
      });
    }

    const orgId = institutions.find(item => item.id === newClassroomForm.values.institution_id)?.['organization-structure']?.id;
    const { data, error } = await client.fetch(`/organization_structures/${orgId}/organizational_charts`);
    if (error) {
      toast.error('Erro ao buscar lotações');
    } else {
      setRoles(getUsersCount(data));
    }
  };

  useEffect(() => {
    getInstitutions();
  }, []);

  useEffect(() => {
    if (newClassroomForm.values.institution_id) {
      getRoles();
    }
  }, [newClassroomForm.values.institution_id]);

  const deleteClassroom = async classroom => {
    const { error } = await client.delete(['classrooms', classroom.id]);
    if (error) {
      toast.error('Erro ao excluir turma.');
    } else {
      toast.success('Turma excluida com sucesso.');
      getClassrooms();
    }
  };

  let today = new Date();
  today = today.toISOString().split('T')[0];

  const viewClassroom = getPermission('Visualizar lista de turmas', 'Turmas');

  useEffect(() => {
    if (pageNumber !== 1) {
      getClassroomsPagination(pageNumber);
    }
  }, [pageNumber]);

  const breadCrumbs = {
    title: 'Organização da Plataforma',
    nav: [
      {
        route: uri,
        name: 'Turmas',
        isActive: true
      }
    ]
  };

  if (!viewClassroom) return <Error404 />;

  return (
    <main className="main-content main-content--block">
      <BreadCrumbs data={breadCrumbs} />

      <TabNavBar selectedTab="turmas" />

      <div className="tab__pane">
        <div className="filter-bar">
          <FilterSelectionBox
            label={t('exams.selectCourse')}
            value={filters.values.discipline}
            onChange={e => {
              filters.setFieldValue('discipline', e.target.value);
              if (!newClassroomForm.values.institution_id) {
                getClassrooms(e.target.value, false, 'blank');
              } else {
                getClassrooms(e.target.value, false, newClassroomForm.values.institution_id);
              }
            }}
            options={courses.map(c => ({ id: c.id, name: c.title }))}
          />
          <div>
            <label className="form__label">{t('filter.institution')}:</label>
            <Select
              defaultValue={filters.values.institution_id}
              openMenuOnFocus
              options={institutions?.map(item => {
                return { value: item.id, label: item.name };
              })}
              className="react-multi-select filter-bar__multi-select"
              classNamePrefix="react-multi-select"
              placeholder={t('filter.blankLabelAll')}
              noOptionsMessage={() => 'Sem opções'}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null
              }}
              onChange={e => {
                newClassroomForm.setFieldValue('institution_id', e.value);
                if (filters.values.discipline) {
                  getClassrooms(filters.values.discipline, false, e.value);
                } else {
                  getClassrooms('blank', false, e.value);
                }
              }}
              style={{ width: '300px' }}
            />
          </div>

          {getPermission('Criar turmas', 'Turmas') && (
            <button
              className="btn btn--wide btn--primary btn--wide"
              onClick={() => setShow(true)}
            >
              {t('courses.newClass')}
            </button>
          )}
        </div>

        {loading && <Loader />}

        {!classrooms.length > 0 && !loading && <EmptyState type="data" />}

        {!loading && classrooms.length > 0 && (
          <InfiniteScroll
            dataLength={classrooms.length}
            next={() => setPageNumber(pageNumber + 1)}
            hasMore={hasMoreClassrooms}
            loader={<Loader />}
            // endMessage={<EndScroll />}
          >
            <div className="tab__cards">
              {classrooms.map(c => (
                <ClassroomCard
                  key={c.id}
                  classroom={c}
                  editClassroomForm={editClassroomForm}
                  deleteClassroom={deleteClassroom}
                />
              ))}
            </div>
          </InfiniteScroll>
        )}

        <Modal
          show={editModal}
          onClose={() => {
            setEditModal(false);
            getClassrooms();
          }}
        >
          <form
            method="patch"
            onSubmit={editCurrentClassroom}
            className="form"
          >
            <h2 className="modal__simple-title">{t('classes.editClass')}</h2>

            <div className="form__row">
              <label
                className="form__label"
                htmlFor="classClassroom"
              >
                {t('textsCommon.name')}
              </label>

              <input
                className="form__control"
                id="classClassroom"
                name="classClassroom"
                type="text"
                placeholder={t('courses.placeholderClassName')}
                value={editClassroomTitle}
                onChange={e => setEditClassroomTitle(e.target.value)}
              />
            </div>

            <button
              className="btn btn--wide btn--primary"
              type="submit"
            >
              {t('classes.editClass')}
            </button>
          </form>
        </Modal>

        <Modal
          show={show}
          onClose={() => {
            newClassroomForm.resetForm();
            setShow(false);
          }}
        >
          <form
            method="post"
            className="form"
            onSubmit={newClassroomForm.handleSubmit}
          >
            <h2 className="modal__simple-title">{t('courses.newClass')}</h2>

            <p className="form__description">{t('courses.newClassDescription')}</p>

            <div className="form__row">
              <label
                className="form__label"
                htmlFor="classTitle"
              >
                *{t('courses.className')}
              </label>
              <input
                className="form__control"
                id="classTitle"
                name="classTitle"
                type="text"
                placeholder={t('courses.placeholderClassName')}
                value={newClassroomForm.values.title}
                onChange={e => newClassroomForm.setFieldValue('title', e.target.value)}
              />
              {newClassroomForm.touched.title && newClassroomForm.errors.title && <span style={{ color: 'red' }}>{newClassroomForm.errors.title}</span>}
            </div>

            <div className="form__Row u-mb-3 ">
              <label className="form__label">{t('filter.institution')}:</label>
              <Select
                defaultValue={filters.values.institution}
                openMenuOnFocus
                options={institutions?.map(item => {
                  return { value: item.id, label: item.name };
                })}
                className="react-multi-select filter-bar__multi-select"
                classNamePrefix="react-multi-select"
                placeholder="Selecione uma instituição"
                noOptionsMessage={() => 'Sem opções'}
                components={{
                  IndicatorSeparator: () => null,
                  ClearIndicator: () => null
                }}
                onChange={e => newClassroomForm.setFieldValue('institution_id', e.value)}
                style={{ width: '300px' }}
              />
            </div>

            <div className="form__row">
              <label
                className="form__label"
                htmlFor="course"
              >
                *{t('courses.relatedClasstoCourse')}
              </label>
              <select
                className="form__select"
                name="course"
                id="course"
                onChange={e => newClassroomForm.setFieldValue('course_id', e.target.value)}
              >
                <option>{t('exams.selectCourse')}</option>
                {courses.map(t => (
                  <option value={t.id}>{t.title}</option>
                ))}
              </select>
              {newClassroomForm.touched.course_id && newClassroomForm.errors.course_id && <span style={{ color: 'red' }}>{newClassroomForm.errors.course_id}</span>}
            </div>

            <div className="form__Row u-mb-3 ">
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <label
                  className="form__label"
                  style={{ width: 'fit-content', marginRight: 8 }}
                >
                  Adicione a lotação dos participantes:
                </label>
                <FiHelpCircle
                  className="widget__help-icon"
                  data-tooltip-id="roles"
                  data-tooltip-content="Ao adicionar uma lotação, todos as demais abaixo dela também serão enturmadas."
                />
                <ReactTooltip
                  id="roles"
                  className="tooltip"
                />
              </div>

              <Select
                isDisabled={!newClassroomForm.values.institution_id}
                isMulti
                defaultValue={filters.values?.['organizational-chart-ids']}
                openMenuOnFocus
                options={roles?.map(item => {
                  return { value: item.id, label: `${item.title}(${item?.['users-count']})` };
                })}
                className="react-multi-select filter-bar__multi-select"
                classNamePrefix="react-multi-select"
                placeholder="Selecionar"
                noOptionsMessage={() => 'Sem opções'}
                components={{
                  IndicatorSeparator: () => null,
                  ClearIndicator: () => null
                }}
                onChange={e => {
                  newClassroomForm.setFieldValue(
                    'organizational-chart-ids',
                    e.map(item => item.value)
                  );
                }}
              />
            </div>
            <div className="form__row">
              <label
                className="form__label"
                htmlFor="initial_date"
              >
                {t('courses.classAvailabilityPeriod')}
              </label>

              <div className="form__columns">
                <div className="form__col">
                  <input
                    className="form__control"
                    id="initial_date"
                    name="initial_date"
                    type="date"
                    min={today}
                    defaultValue={newClassroomForm.values.initial_date}
                    onChange={e => newClassroomForm.setFieldValue('initial_date', e.target.value)}
                  />
                </div>
                <div className="form__col">
                  <input
                    className="form__control"
                    id="end_date"
                    name="end_date"
                    type="date"
                    min={today}
                    defaultValue={newClassroomForm.values.end_date}
                    onChange={e => newClassroomForm.setFieldValue('end_date', e.target.value)}
                  />
                </div>
              </div>
            </div>

            <button
              className="btn btn--primary"
              type="submit"
              disabled={isSubmitting}
            >
              {t('courses.buttonNewClass')}
            </button>
          </form>
        </Modal>
      </div>
    </main>
  );
}

export { Classrooms as default, ManageClassroomParticipants, ClassroomInvitations, ViewInvitations, ClassroomImports, ViewImports };
