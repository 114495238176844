import React, { useState, useEffect } from 'react';
import { Link, navigate, useParams } from '@reach/router';
import { FiChevronDown, FiCheckCircle, FiChevronLeft, FiEdit3, FiTarget, FiVideo } from 'react-icons/fi';
import { AiOutlineTrophy } from 'react-icons/ai';
import { TbCopy } from 'react-icons/tb';
import { TfiMedall } from 'react-icons/tfi';
import { useFormik } from 'formik';
import CourseCover from 'app/images/placeholders/show-course.jpg';
import StarRating from 'app/components/StarRating';
import { AvatarStack } from 'app/components/Avatar';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import ModuleCard from 'app/screens/Course/CourseMonitoring/ModuleCard';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import Loader from 'app/components/loader';
import { t } from 'i18next';
import { usePlatformAbilities } from 'app/hooks/usePlatformAbilities';
import { useSession } from 'app/hooks/useSession';
import moreMedal from 'app/images/more-medal.svg';
import { checkIncludeProfiles } from 'app/utils/getAuthorityLevel';

export default function CourseAbout() {
  const params = useParams();
  const [course, setCourse] = useState();
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const { abilities } = usePlatformAbilities();
  const user = useSession().session.user;
  const startedCourse = course?.['learning-systems']?.some(item => item.learning_system_users.some(item => item.user_id === user.id));
  const basePath = window.env.REACT_APP_BASE_PATH;

  const getCourse = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`/courses/${parseInt(params.id)}?detailed=true`);
    if (error) {
      toast.error('Erro ao carregar dados do curso');
    } else {
      setCourse(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCourse();
  }, []);

  const managers = course?.users.filter(user => user['profile-names'][0] !== 'Aluno');
  const students = course?.users.filter(user => user['profile-names'][0] === 'Aluno');

  const totalLessons = () => {
    const parsedItems = course?.['learning-systems'].map(item => item.learning_system_items_total_count);

    const totalLessons = parsedItems?.reduce((sum, curr) => {
      return sum + curr;
    }, 0);

    return totalLessons;
  };

  const options = [
    { name: t('filter.labelCompleted'), id: 1 },
    { name: t('filter.labelInProgress'), id: 2 }
  ];

  const filters = useFormik({
    initialValues: {
      status: 'blank'
    }
  });

  const filteredModules = course?.['learning-systems'].filter(item => {
    if (filters.values.status === 'blank') {
      return item;
    }
    if (filters.values.status == 1) {
      return item.progress === 100;
    }
    if (filters.values.status == 2) {
      return item.progress < 100;
    }
  });

  const downloadCertificate = async () => {
    window.open(`${window.env.REACT_APP_DEV_URL}//user_certificates/download/${course?.certificate?.hash_id}.pdf`, '_blank');
  };

  const nextModule = course?.['learning-systems']?.find(item => item.progress < 100);

  const isStudent = checkIncludeProfiles({ only: ['student'] });

  return (
    <div className="main-content main-content--block">
      {loading && <Loader />}
      {!loading && (
        <>
          <div className="filter-bar">
            <div className="filter-bar__inner">
              <Link
                to={isStudent ? `${basePath}/curso` : `${basePath}/curso/admin`}
                className="filter-bar__back"
                style={{ alignSelf: 'center' }}
              >
                <div className="card__icon-wrapper">
                  <FiChevronLeft />
                </div>

                <span className="filter-bar__back-container">
                  <span className="filter-bar__title">{course?.title}</span>
                </span>
              </Link>
            </div>
          </div>
          <div className="course-about">
            <div
              className="show-course-banner"
              style={{
                backgroundImage: `url('${course?.image?.url ?? CourseCover}')`
              }}
            >
              <div className="show-course-banner__inner">
                <div className="show-course-banner__container">
                  <div className="card__badges">
                    <span className="badge badge--tiny badge--primary">{course?.knowledge}</span>
                    {course?.progress === 100 && <span className="badge badge--tiny badge--success">Concluído</span>}
                  </div>

                  <div className="show-course-banner__title">{course?.title}</div>
                  <div className="show-course-banner__cta">
                    {nextModule?.id && (
                      <Link
                        className="btn btn--wide btn--secondary"
                        to={`${basePath}/curso/${course?.id}/modulo/${nextModule?.id}/sobre`}
                      >
                        {startedCourse ? t('button.continueCourse') : t('button.startCourse')}
                      </Link>
                    )}

                    {course?.certificate?.hash_id && (
                      <button
                        className="btn btn--wide btn--outline-secondary"
                        onClick={() => {
                          downloadCertificate();
                        }}
                      >
                        {t('button.certification')}
                      </button>
                    )}
                  </div>
                </div>

                {course?.progress === 100 && (
                  <div className="medal">
                    <img
                      src={moreMedal}
                      alt="nova medalha"
                      className="medal__img"
                    />
                    <span className="medal__value">
                      <TfiMedall />
                    </span>
                  </div>
                )}

                {/* <div
              className="btn-group btn-group--with-dropdown"
              role="group"
              aria-label="Ações"
            >
              <Link
                to={`${basePath}/curso`}
                className="filter-bar__back"
                style={{ alignSelf: 'center' }}
              >
                <div className="card__icon-wrapper">
                  <FiChevronLeft />
                </div>

                <span className="filter-bar__back-container">
                  <span className="filter-bar__title">{course?.title}</span>
                </span>
              </Link>
            </div>
          </div>

          <div className="course-about">
            <div
              className="show-course-banner"
              style={{
                backgroundImage: `url(${course?.image?.url || CourseCover})`
              }}
            >
              <div className="show-course-banner__inner">
                <div className="show-course-banner__container">
                  <span className="badge badge--tiny badge--primary">{course?.knowledge}</span>
                  <div className="show-course-banner__title">{course?.title} </div>
                  <div className="show-course-banner__cta">
                    <Link
                      className="btn btn--wide btn--primary"
                      to={`${basePath}/curso/${course?.id}/modulo/${course?.['learning-systems']?.[0]?.id}`}
                    >
                      Começar curso
                    </Link>
                  </div>
                </div>

                {/* <div
            className="btn-group btn-group--with-dropdown"
            role="group"
            aria-label="Ações"
          >
            <Link
              to={`${basePath}/curso/${1}/modulo/${1}`}
              type="button"
              className="btn btn--outline btn--small"
            >
              Começar módulo
            </Link>
            <Dropdown className="btn btn--small btn--outline">
              <Dropdown.Toggle>
                <FiChevronDown />
              </Dropdown.Toggle>

              <Dropdown.Content>
                <Dropdown.Item>???</Dropdown.Item>
              </Dropdown.Content>
            </Dropdown>
          </div> */}
              </div>
            </div>

            <div className="course-about__header">
              <div className="course-about__container-header">
                <div className="course-about__inner-header">
                  <div className="course-about__infos">
                    <div className="course-about__rating">
                      <StarRating
                        staticRating
                        rating={parseInt(course?.rating)}
                      />
                      <p className="course-about__rating-text">
                        <strong>{parseInt(course?.rating).toFixed(1)}</strong> ({course?.['total-reviews']} {t('courses.evaluations')})
                      </p>
                    </div>
                    {/* <div className="course-card__tag">
                <AiOutlineTrophy /> Certificado Online
              </div> */}
                  </div>
                  <p className="course-about__description">{course?.about}</p>
                </div>
                <div className="course-about__participants">
                  <div className="card__avatar">
                    <h4 className="card__avatar-title">
                      {t('courses.managers')} ({managers?.length})
                    </h4>
                    {managers?.length > 0 && (
                      <AvatarStack
                        data={managers}
                        modalTitle={t('courses.managers')}
                      />
                    )}
                  </div>
                  <div className="card__avatar">
                    <h4 className="card__avatar-title">
                      {t('projects.titleStudents')} ({students?.length})
                    </h4>
                    {students?.length > 0 && (
                      <AvatarStack
                        data={students}
                        modalTitle={t('courses.students')}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="widget-wrapper u-mb-0">
                <div className="info-widget info-widget--t3">
                  <div className="info-widget__header">
                    <h3 className="info-widget__title">{t('textsCommon.modules')}</h3>
                  </div>

                  <div className="info-widget__body">
                    <p className="info-widget__value">
                      <TbCopy /> {course?.['learning-systems']?.length}
                    </p>
                  </div>
                </div>
                <div className="info-widget info-widget--t3">
                  <div className="info-widget__header">
                    <h3 className="info-widget__title">{t('metrics.titleClasses')}</h3>
                  </div>

                  <div className="info-widget__body">
                    <p className="info-widget__value">
                      <FiVideo /> {totalLessons()}
                    </p>
                  </div>
                </div>
                <div className="info-widget info-widget--t3">
                  <div className="info-widget__header">
                    <h3 className="info-widget__title">{t('courses.exams')}</h3>
                  </div>

                  <div className="info-widget__body">
                    <p className="info-widget__value">
                      <FiTarget /> {course?.['exam-count']}
                    </p>
                  </div>
                </div>

                {abilities?.['cursos-ativ-complementar'] && (
                  <div className="info-widget info-widget--t3">
                    <div className="info-widget__header">
                      <h3 className="info-widget__title">{t('courses.activities')}</h3>
                    </div>

                    <div className="info-widget__body">
                      <p className="info-widget__value">
                        <FiEdit3 /> {course?.['activity-count']}
                      </p>
                    </div>
                  </div>
                )}

                <div className="info-widget info-widget--t3">
                  <div className="info-widget__header">
                    <h3 className="info-widget__title">{t('courses.questionBooks')}</h3>
                  </div>

                  <div className="info-widget__body">
                    <p className="info-widget__value">
                      <FiCheckCircle /> {course?.['nonpublic-count']}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="course-about__body">
              <div className="course-about__filter">
                <h3 className="course-about__filter-title">{t('textsCommon.courseModules')}</h3>

                <div className="filter-bar filter-bar--borderless">
                  <div className="filter-bar__inner">
                    <FilterSelectionBox
                      blankLabel={t('filter.blankLabelAll2')}
                      label={t('filter.labelSelectStatus')}
                      value={filters.values.status}
                      onChange={e => filters.setFieldValue('status', e.target.value)}
                      options={options}
                    />
                  </div>
                </div>
              </div>

              <div className="module-wrapper module-wrapper--grid u-mh-none">
                {filteredModules?.map(item => (
                  <ModuleCard
                    courseId={course.id}
                    module={item}
                    key={item.id}
                  />
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
