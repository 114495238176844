import {COLORS} from "../utils/colors"

const Legend = ({ nodes = null }) => {
    return (
        <div className="flow-legend">
            <h2>Lotação</h2>

            <div className="legend-lotation-container">
                {nodes?.map((node, index) => (
                    <span key={index} className="legend-lotation"> <div style={{backgroundColor: COLORS[index % COLORS.length]}}></div> {node.data.role}</span>
                ))}
            </div>
        </div>
    )
}

export default Legend