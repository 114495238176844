import React from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import MainSidebar from 'app/components/Sidebar';
import { Link, useLocation } from '@reach/router';
import { navigate } from '@reach/router';
import { t } from 'i18next';

export default function LessonSidebar(props) {
  const { steps, currentStep, setCurrentStep, edit, courseId, moduleId, disciplineId } = props;
  const location = useLocation();
  const basePath = window.env.REACT_APP_BASE_PATH;

  return (
    <MainSidebar hasBrand={false}>
      <nav className="notebook-sidebar__nav">
        <div
          onClick={() =>
            navigate(`${basePath}/curso/admin/gerenciar/${courseId}/${moduleId}/disciplinas-e-aulas/${disciplineId}`, {
              state: {
                moduleId: location.state.moduleId,
                disciplineId: location.state.disciplineId
              }
            })
          }
          className="filter-bar__back"
        >
          <div className="card__icon-wrapper">
            <FiChevronLeft />
          </div>
          <span className="filter-bar__back-container">
            <span className="filter-bar__title">{t('lessons.lesson')}</span>
          </span>
        </div>

        <ul className="step-by-step-nav">
          {steps.map((step, index) => {
            return (
              <li
                className={`step-by-step-nav__item ${index === currentStep ? 'active' : ''} ${currentStep > index ? 'step-by-step-nav__item--completed' : ''}`}
                onClick={() => edit && setCurrentStep(index)}
                key={index}
              >
                <Link
                  to={edit ? `/lms/criar/modulo/${step.route}` : ''}
                  className="step-by-step-nav__link"
                  style={{ cursor: edit ? 'pointer' : 'default' }}
                >
                  {step.text}
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </MainSidebar>
  );
}
