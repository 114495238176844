import React, { useEffect, useState } from 'react';
import { Formik, useFormik } from 'formik';
import toast from 'react-hot-toast';
import TabNavBar from '../TabNavBar';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import Modal from 'app/components/Modal';
import Error404 from 'app/components/Error404';
import { checkIncludeProfiles } from 'app/utils/getAuthorityLevel';
import Loader from 'app/components/loader';
import EmptyState from 'app/components/EmptyState';
import ProfileCard from './ProfileCard';
import { useClient } from 'jsonapi-react';
import getPermission from 'app/utils/getPermission';
import { t } from 'i18next';
import BreadCrumbs from 'app/components/BreadCrumbs';

export default function AdministrativeProfiles(props) {
  const { uri } = props;

  const [showModal, setShowModal] = useState(false);
  const client = useClient();
  // const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [relationships, setRelationships] = useState({
    profiles: []
  });
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getProfiles();
    // getUsers();
  }, []);

  const getProfiles = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`profiles?now=${new Date().getTime()}`);
    if (error) {
      toast.error('Falha ao carregar perfis');
    } else {
      setProfiles(data);
      setLoading(false);
    }
  };

  const createProfile = async () => {
    const requestParams = {
      name: newProfileForm.values.title
    };
    const { error } = await client.mutate('profiles', requestParams);
    if (error) {
      toast.error('Falha ao criar perfil');
    } else {
      getProfiles();
    }
  };

  const filters = useFormik({
    initialValues: {
      profile: 'blank'
    }
  });

  const profilesFiltered = profiles.filter(item => item.name !== 'Aluno' && !item.name !== 'Administrador');

  const newProfileForm = useFormik({
    initialValues: {
      title: ''
    },
    onSubmit: async () => {
      toast.success(t('toast.successCreateProfile'));
      newProfileForm.resetForm();
      createProfile();
    }
  });

  const breadCrumbs = {
    title: 'Organização da Plataforma',
    nav: [
      {
        route: uri,
        name: 'Usuário',
        isActive: true
      }
    ]
  };

  return (
    <>
      <main className="main-content main-content--block">
        <BreadCrumbs data={breadCrumbs} />

        <TabNavBar selectedTab="perfis-administrativos" />

        <div className="filter-bar">
          <input
            aria-label="Buscar estudante"
            className="form__control form__control--search-with-icon"
            style={{ width: 'auto' }}
            placeholder={t('filter.labelSearchProfile')}
            type="search"
            name="studentsSearch"
            id="studentsSearch"
            onChange={e => setSearchTerm(e.target.value)}
          />

          {getPermission('Criar perfis', 'Participantes e perfis administrativos') && (
            <button
              type="button"
              onClick={() => setShowModal(true)}
              className="btn btn--primary btn--wide u-ml-auto"
            >
              Novo perfil
            </button>
          )}
        </div>

        {loading && <Loader />}

        <div className="tab__cards">
          {!loading &&
            profilesFiltered
              .filter(u => {
                if (searchTerm == '') {
                  return u;
                }
                if (u.name != null && u.name.toLowerCase().includes(searchTerm.toLocaleLowerCase())) {
                  return u;
                }
              })

              .map((profile, i) => (
                <ProfileCard
                  onSuccess={getProfiles}
                  name={profile.name}
                  profileId={profile.id}
                  key={i}
                  userCount={profile['user-count']}
                />
              ))}
        </div>
      </main>

      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
      >
        <form
          method="post"
          className="form"
          onSubmit={e => {
            e.preventDefault();
            newProfileForm.submitForm();
            setShowModal(false);
            getProfiles();
          }}
        >
          <h2 className="modal__simple-title">Nova perfil</h2>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="profileTitle"
            >
              Nome do perfil
            </label>
            <input
              className="form__control"
              id="profileTitle"
              name="profileTitle"
              type="text"
              placeholder={t('projects.placeholderProfile')}
              value={newProfileForm.values.title}
              onChange={e => newProfileForm.setFieldValue('title', e.target.value)}
            />
          </div>

          <button
            className="btn btn--primary"
            type="submit"
          >
            {t('button.saveContinue')}
          </button>
        </form>
      </Modal>
    </>
  );
}
