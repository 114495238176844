import React, { useState } from 'react';
import Loader from 'app/components/loader';
import UserInfo from 'app/components/UserInfo';

export default function ParticipantItemCard(props) {
  const { user, addParticipantToClassroom, deleteParticipantFromClassroom, classroom } = props;

  const isIncluded = classroom['user-ids'].includes(user.id);

  return (
    <div className="card card--h u-mb-2">
      <div style={{ flexGrow: '1' }}>
        <UserInfo
          user={user}
          viewEmail={true}
        />
      </div>

      <div className="card__footer">
        <button
          type="button"
          onClick={() => {
            if (!isIncluded) {
              addParticipantToClassroom(user.id);
            } else {
              deleteParticipantFromClassroom(user.id);
            }
          }}
          className={isIncluded ? 'btn btn--wide btn--outline active' : 'btn btn--wide btn--outline'}
        >
          {isIncluded ? 'Remover' : 'Adicionar'}
        </button>
      </div>
    </div>
  );
}
