import React, { useState } from 'react';
import LessonSidebar from './LessonSidebar';
import LessonContent from './LessonContent';
import { t } from 'i18next';
import { usePlatformAbilities } from 'app/hooks/usePlatformAbilities';

export default function CreateLesson(props) {
  const { step, contentId, lessonId, location } = props;
  const [currentStep, setCurrentStep] = useState(0);
  const disciplineId = location?.state?.disciplineId;
  const moduleId = location?.state?.moduleId;
  const editLesson = location?.state?.editLesson;
  const courseId = props?.location?.state?.courseId;
  const { abilities } = usePlatformAbilities();

  const steps = [
    {
      id: 0,
      route: 'nome',
      text: t('lessons.stepsLessonName')
    },
    {
      id: 1,
      route: 'videoaula',
      text: t('lessons.stepsLessonVideo')
    }
  ];

  if (abilities?.quiz && editLesson) {
    steps.push({
      id: 2,
      route: 'perguntas',
      text: t('lessons.stepsLessonQuestions')
    });
  }

  return (
    <div className="main-screen">
      <LessonSidebar
        disciplineId={disciplineId}
        moduleId={moduleId}
        courseId={courseId}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        steps={steps}
        edit={editLesson}
      />

      <main className="main-content">
        <LessonContent
          abilities={abilities}
          courseId={courseId}
          step={step}
          steps={steps}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          learningSystemContentId={contentId}
          lessonId={lessonId}
          moduleId={moduleId}
          disciplineId={disciplineId}
          editLesson={editLesson}
        />
      </main>
    </div>
  );
}
