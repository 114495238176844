import React, { useState } from 'react';
import { Link, navigate, useParams } from '@reach/router';
import { FiChevronLeft } from 'react-icons/fi';
import { t } from 'i18next';
import { useFormik } from 'formik';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import StudentCard from './StudentCard';
import { useQuestionBook } from 'app/hooks/useQuestionBook';
import { useEffect } from 'react';
import { useClient } from 'jsonapi-react';
import { toast } from 'react-hot-toast';
import { useCourse } from 'app/hooks/useCourse';
import { useClassroom } from 'app/hooks/useClassroom';
import Loader from 'app/components/loader';
import EmptyState from 'app/components/EmptyState';

export default function OfflineExam(props) {
  const { selectedTab } = props;
  const { id } = useParams();
  const { getQuestionBooks, getQuestionBook, questionBook } = useQuestionBook();
  const client = useClient();
  const [students, setStudents] = useState([]);
  const [metrics, setMetrics] = useState();
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [filteredClassrooms, setFilteredClassrooms] = useState([]);
  const { getSimpleCourses, courses } = useCourse();
  const { getClassrooms, classrooms } = useClassroom();
  const [institutions, setIstitutions] = useState();
  const [loading, setLoading] = useState(false);
  const basePath = window.env.REACT_APP_BASE_PATH;

  useEffect(() => {
    getSimpleCourses();
    getClassrooms();
  }, []);

  const getStudents = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`/users/question_books/${id}/able_to_answer_sheet`);
    if (error) {
      toast.error(t('toast.error'));
    } else {
      setStudents(data);
    }
    setLoading(false);
  };

  const getMetrics = async () => {
    const { data, error } = await client.fetch(`/reports/question_books/${id}/answer_sheets
    `);
    if (error) {
      toast.error(t('toast.error'));
    } else {
      setMetrics(data);
    }
  };

  const getInstitutions = async id => {
    const { data, error } = await client.fetch(`institutions`);
    if (error) {
      toast.error('Erro ao buscar escolas');
    } else {
      setIstitutions(data);
    }
  };
  useEffect(() => {
    getQuestionBook(parseInt(id));
    getQuestionBooks();
    getStudents();
    getMetrics();
    getInstitutions();
  }, []);

  const [relationships, setRelationships] = useState({
    courses: [],
    classrooms: [],
    status: []
  });

  const filters = useFormik({
    initialValues: {
      searchTerm: '',
      institution: 'blank',
      course: 'blank',
      classroom: 'blank',
      status: 'blank'
    }
  });

  const statusList = [
    { title: 'Ainda não realizada', id: null },
    { title: 'Processando', id: 'processing' },
    { title: 'Concluido', id: 'completed' },
    { title: 'Erro ao processar', id: 'failed' },
    { title: 'Realizado online', id: null }
  ];

  useEffect(() => {
    const applyFilters = () => {
      const { searchTerm, course, classroom, institution } = filters.values;
      const statusSelected = filters.values.status;
      const filtered = students.filter(student => {
        let filterResult = true;
        if (searchTerm !== '') {
          filterResult = filterResult && student.name.toLowerCase().includes(searchTerm.toLowerCase());
        }
        if (institution !== 'blank') {
          const institutionClassroomIds = institutions.find(item => item.id === parseInt(institution))['classroom-ids'];
          filterResult = filterResult && student?.['classroom-ids'].some(classroomId => institutionClassroomIds.includes(classroomId));
        }

        if (course !== 'blank') {
          filterResult = filterResult && student?.['course-ids'].some(courseId => courseId === parseInt(course));
        }
        if (classroom !== 'blank' && course !== 'blank') {
          filterResult = filterResult && student?.['classroom-ids'].some(classroomId => classroomId === parseInt(classroom));
        }

        if (filterResult) {
          if (statusSelected === 'Realizado online') {
            filterResult = student['question-books-user']?.['answer-sheet'] === null;
          } else if (statusSelected === 'Ainda não realizada') {
            filterResult = student['question-books-user'] === null;
          } else if (statusSelected != 'blank') {
            filterResult = student['question-books-user']?.['answer-sheet']?.['processing-status'] === statusSelected;
          }
        }

        return filterResult;
      });
      setFilteredStudents(filtered);
    };
    applyFilters();
  }, [filters.values, students]);

  useEffect(() => {
    const selectedCourse = courses.find(course => course.id === parseInt(filters.values.course));

    if (selectedCourse) {
      let filteredClassrooms = selectedCourse.classrooms;

      if (filters.values.institution !== 'blank') {
        const institutionClassroomIds = institutions.find(item => item.id === parseInt(filters.values.institution))['classroom-ids'];
        filteredClassrooms = filteredClassrooms.filter(classroom => institutionClassroomIds.includes(classroom.id));
      }

      const formattedClassrooms = filteredClassrooms.map(classroom => ({
        id: classroom.id,
        name: classroom.title
      }));

      setFilteredClassrooms(formattedClassrooms);
    } else {
      setFilteredClassrooms([]);
    }
  }, [filters.values.course, filters.values.institution, courses, institutions]);

  return (
    <main className="main-content main-content--block">
      <div className="filter-bar">
        <div className="filter-bar__inner">
          <button
            onClick={() => navigate(-1)}
            className="filter-bar__back"
          >
            <span className="card__icon-wrapper">
              <FiChevronLeft className="card__icon" />
            </span>

            <span className="filter-bar__back-container">
              <span className="filter-bar__title">{questionBook?.title}</span>
            </span>
          </button>
        </div>

        <div className="filter-bar__actions">
          <Link
            className="btn btn--wide btn--primary"
            to={`${basePath}/avaliacoes/${selectedTab}/impressao/${parseInt(id)}`}
          >
            {t('button.printExam')}
          </Link>
        </div>
      </div>

      <div className="widget-wrapper">
        <div className="info-widget info-widget--t3">
          <div className="info-widget__header">
            <h4 className="info-widget__title">{'Provas realizadas'}</h4>
          </div>
          <div className="info-widget__body">
            <p className="info-widget__value">{`${metrics?.['question-books-user']}/${metrics?.['student-total']}`}</p>
          </div>
        </div>

        <div className="info-widget info-widget--t3">
          <div className="info-widget__header">
            <h4 className="info-widget__title">{'Provas online'}</h4>
          </div>
          <div className="info-widget__body">
            <p className="info-widget__value">{metrics?.['question-books-user-without-answer-sheet']}</p>
          </div>
        </div>

        <div className="info-widget info-widget--t3">
          <div className="info-widget__header">
            <h4 className="info-widget__title">{'Provas offline'}</h4>
          </div>
          <div className="info-widget__body">
            <p className="info-widget__value">{metrics?.['answer-sheet']}</p>
          </div>
        </div>

        <div className="info-widget info-widget--t3">
          <div className="info-widget__header">
            <h4 className="info-widget__title">{'Em processamento'}</h4>
          </div>
          <div className="info-widget__body">
            <p className="info-widget__value">{metrics?.processing}</p>
          </div>
        </div>

        <div className="info-widget info-widget--t3">
          <div className="info-widget__header">
            <h4 className="info-widget__title">{'Erro ao processar'}</h4>
          </div>
          <div className="info-widget__body">
            <p className="info-widget__value">{metrics?.failed}</p>
          </div>
        </div>
      </div>

      <div className="filter-bar filter-bar--borderless">
        <div className="filter-bar__inner">
          <div className="filter-bar__row">
            <label
              htmlFor="search"
              className="filter-bar__label"
            >
              {t('textsCommon.research')}
            </label>
            <input
              className="form__control form__control--search-with-icon"
              placeholder={t('filter.placeholderStudentName')}
              type="search"
              name="search"
              id="search"
              onChange={e => filters.setFieldValue('searchTerm', e.target.value)}
            />
          </div>
          <FilterSelectionBox
            label={'Selecione uma escola'}
            blankLabel={t('filter.blankLabelAll')}
            value={filters.values.institution}
            onChange={e => {
              filters.setFieldValue('institution', e.target.value);
            }}
            options={institutions}
          />
          <FilterSelectionBox
            label={t('exams.selectCourse')}
            blankLabel={t('filter.labelSelect')}
            value={filters.values.course}
            onChange={e => filters.setFieldValue('course', e.target.value)}
            options={courses.map(o => ({
              id: o.id,
              name: o.title
            }))}
          />
          <FilterSelectionBox
            label={t('filter.labelSelectClassroom')}
            blankLabel={t('filter.labelSelect')}
            value={filters.values.classroom}
            onChange={e => filters.setFieldValue('classroom', e.target.value)}
            options={filteredClassrooms.map(o => ({
              id: o.id,
              name: o.name
            }))}
          />
          <FilterSelectionBox
            label={t('filter.labelSelectStatus')}
            blankLabel={t('filter.labelSelect')}
            value={filters.values.status}
            onChange={e => filters.setFieldValue('status', e.target.value)}
            options={statusList}
          />
        </div>
      </div>

      <div className="round-dg-wrapper">
        {loading ? (
          <Loader />
        ) : !loading && filteredStudents.length === 0 ? (
          <EmptyState type="content" />
        ) : (
          <table
            className="round-dg round-dg--light"
            style={{ minWidth: '868px' }}
          >
            <thead className="round-dg__header">
              <tr className="round-dg__row">
                <th className="round-dg__cell-header">{t('filter.placeholderStudentName')}</th>
                <th className="round-dg__cell-header">{t('exams.thCourse')}</th>
                <th className="round-dg__cell-header">{t('exams.thClass')}</th>
                <th className="round-dg__cell-header">{t('exams.thStats')}</th>
                <th className="round-dg__cell-header" />
              </tr>
            </thead>
            <tbody className="round-dg__body">
              {filteredStudents?.map((student, index) => (
                <StudentCard
                  key={index}
                  student={student}
                  courses={courses}
                  classrooms={classrooms}
                  id={parseInt(id)}
                  getStudents={getStudents}
                />
              ))}
            </tbody>
          </table>
        )}
      </div>
    </main>
  );
}
