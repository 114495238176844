export const getInstitutions = async (client) => {

  const { data, error } = await client.fetch('/institutions')

  if (error) {
    throw new Error('error_get_institutions')
  } else {
    return data
  }
}

export const getInstitutionById = async (client, id) => {
  const { data, error } = await client.fetch(`/institutions/${id}`)

  if (error) {
    throw new Error('error_get_institution_by_id')
  } else {
    return data
  }
}

export const getOrganizationalChartByStructureId = async (client, id) => {
  const { data, error } = await client.fetch(`/organization_structures/${id}/organizational_charts`)

  if (error) {
    throw new Error('error_get_structure')
  } else {
    return data
  }
}

export const getFundamentalUsers = async (client) => {
  const { data, error } = await client.fetch('/users?fundamental=true')

  if (error) {
    throw new Error('error_get_structure')
  } else {
    return data
  }
}

export const getUserById = async (client, id) => {
  const { data, error } = await client.fetch(`/users/${id}`)

  if (error) {
    throw new Error('error_get_user_by_id')
  } else {
    return data
  }
}

export const deleteInstitutionById = async (client, id) => {
  const { error } = await client.delete(['/institutions', id])

  if (error ) throw new Error('error_deleting_institution')
}

export const deleteNodeById = async (client, id, nodeId) => {
  const {error} = await client.delete([`/organization_structures/${id}/organizational_charts`, nodeId])

  if (error) throw new Error('error_deleting_node')
}