import React, { useEffect, useState } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { HiLightBulb } from 'react-icons/hi';
import { navigate } from '@reach/router';
import { useFormik } from 'formik';
import 'tippy.js/dist/tippy.css';
import EssayCover from 'app/images/placeholders/show-course.jpg';
import { SimpleModal } from 'app/components/Modal';
import { useClient } from 'jsonapi-react';
import TextareaAutosize from 'react-autosize-textarea';

export default function EssayConsult({ essayId, studentId }) {
  const [show, setShow] = useState(false);
  const [studentEssay, setStudentEssay] = useState({});
  const client = useClient();
  const basePath = window.env.REACT_APP_BASE_PATH;

  const getStudentEssay = async () => {
    const { data, error } = await client.fetch(`/essays/${essayId}/essay_students/${studentId}`);
    if (error) {
      toast.error('Erro ao buscar redação');
    } else {
      setStudentEssay(data);
    }
  };

  useEffect(() => {
    getStudentEssay();
  }, []);

  const switchDigital = useFormik({
    initialValues: {
      digital: true,
      competence: 'c1',
      points: 180
    }
  });

  useEffect(() => {
    if (studentEssay && studentEssay.status === 'annulled') {
      switchDigital.setFieldValue('digital', false);
    }
  }, [studentEssay]);

  const essayStatus = {
    accepting_proposal: { text: 'Aceitando respostas', badgeColor: 'warning' },
    close_for_submissions: { text: 'Prazo encerrado', badgeColor: 'danger' },
    processing: { text: 'Em correção', badgeColor: 'warning' },
    unreviewed: { text: 'Em correção', badgeColor: 'warning' },
    reviewed: { text: 'Corrigida', badgeColor: 'success' },
    annulled: { text: 'Anulada', badgeColor: 'danger' }
  };

  const competencies = [
    { name: 'Competencia 1', description: 'Domínio da Norma Culta da Língua Portuguesa' },
    { name: 'Competencia 2', description: 'Compreensão e Aplicação Interdisciplinar do Tema Proposto' },
    { name: 'Competencia 3', description: 'Seleção e Organização Lógica de Argumentos' },
    { name: 'Competencia 4', description: 'Construção Coesa e Coerente da Argumentação' },
    { name: 'Competencia 5', description: 'Proposição de Intervenção Respeitosa aos Direitos Humanos' }
  ];

  const renderTextWithMarks = text => {
    const selections = studentEssay?.corrections?.sort((a, b) => a.start_index - b.start_index);

    if (selections === undefined || selections.length === 0) {
      return text;
    } else {
      let new_text = '';
      let acumulator = '';
      let selectionIndex = 0;

      text?.split('').forEach((c, i) => {
        if (i >= selections[selectionIndex]?.start_index && i < selections[selectionIndex]?.end_index) {
          acumulator += c;
        } else if (i === selections[selectionIndex]?.end_index) {
          new_text += `<div class="tooltip-container">
            <span class="tooltip-text">
              ${selections?.[selectionIndex]?.body}
            </span>
            <mark>${acumulator}</mark>
            </div>${c}`;
          acumulator = '';
          if (selections.length > selectionIndex) {
            selectionIndex += 1;
          }
        } else {
          new_text += c;
        }
      });
      return new_text;
    }
  };

  return (
    <main className="main-content main-content--block">
      <div className="filter-bar">
        <div className="filter-bar__inner">
          <button
            onClick={() => navigate(`${basePath}/redacao`)}
            className="filter-bar__back"
          >
            <span className="card__icon-wrapper">
              <FiChevronLeft className="card__icon" />
            </span>

            <span className="filter-bar__back-container">
              <span className="filter-bar__title">Redação</span>
            </span>
          </button>
        </div>
      </div>

      <div className="course-about u-mb-4">
        <div
          className="show-course-banner"
          style={{
            backgroundImage: `url(${null || EssayCover})`
          }}
        >
          <div className="show-course-banner__inner">
            <div className="show-course-banner__container">
              <span className={`badge badge--tiny badge--${essayStatus[studentEssay?.status]?.badgeColor}`}>{essayStatus[studentEssay?.status]?.text}</span>
              <div
                title={studentEssay?.topic}
                className="show-course-banner__title"
              >
                {studentEssay?.topic?.length > 50 ? studentEssay?.topic?.slice(0, 50) + '...' : studentEssay?.topic}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="skill-evaluation">
        <div className="skill-evaluation__header skill-evaluation__header--student"></div>

        <div className="skill-evaluation__body">
          <div className="skill-evaluation__col">
            <div className="skill-evaluation__essay">
              <div className="skill-evaluation__essay-header">
                <h2 className="skill-evaluation__essay-title">Sua redação</h2>
                {studentEssay.status !== 'annulled' && (
                  <div className="form__check form__switch u-mb-0">
                    <input
                      className="form__check-input"
                      id="digital"
                      name="digital"
                      type="checkbox"
                      checked={switchDigital.values.digital}
                      onChange={() => {
                        switchDigital.setFieldValue('digital', !switchDigital.values.digital);
                      }}
                    />
                    <label htmlFor="digital">{switchDigital.values.digital ? 'Digital' : 'Cursiva'}</label>
                  </div>
                )}
              </div>

              {switchDigital.values.digital ? (
                <div className="skill-evaluation__essay-body">
                  <div
                    className="skill-evaluation__essay-text"
                    style={{ overflow: 'visible' }}
                  >
                    <span
                      className="skill-evaluation__essay-mark"
                      dangerouslySetInnerHTML={{ __html: renderTextWithMarks(studentEssay?.body) }}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <img
                    className="u-cursor-pointer"
                    src={studentEssay?.essay_image?.url}
                    alt="prova digitalizada"
                    onClick={() => setShow(true)}
                  />
                  <SimpleModal
                    show={show}
                    onClose={() => setShow(false)}
                    contentClassName="simple-modal__content--lg"
                  >
                    <img
                      src={studentEssay?.essay_image?.url}
                      alt="prova digitalizada"
                    />
                  </SimpleModal>
                </>
              )}
            </div>
          </div>

          <div className="skill-evaluation__col">
            {studentEssay.status !== 'annulled' && (
              <>
                <div className="skill-evaluation__card u-mb-4">
                  <h2
                    className="skill-evaluation__essay-title"
                    style={{ marginBottom: '12px' }}
                  >
                    Avaliação de competências
                  </h2>

                  <div
                    className="btn-group u-mb-4"
                    role="group"
                    aria-label="Navegue pelas competências"
                  >
                    {studentEssay?.competencies?.length > 0 &&
                      studentEssay?.competencies?.map(btn => {
                        return (
                          <React.Fragment key={btn.competency_id}>
                            <input
                              type="radio"
                              id={btn.competency_id}
                              name="competence"
                              value={btn.slug}
                              autoComplete="off"
                              checked={switchDigital.values.competence === btn.slug}
                              onChange={e => switchDigital.setFieldValue('competence', e.target.value)}
                            />
                            <label
                              htmlFor={btn.competency_id}
                              className="btn btn--outline"
                            >
                              {btn?.slug?.toUpperCase()}
                            </label>
                          </React.Fragment>
                        );
                      })}
                  </div>

                  <div className="skill-evaluation__competence-tag">
                    <div className="skill-evaluation__competence-tag-inner">
                      <h4 className="skill-evaluation__competence-tag-title">Sua pontuação</h4>
                      <p className="skill-evaluation__competence-tag-points">
                        {studentEssay?.grade} <span className="skill-evaluation__competence-tag-total">/{studentEssay?.max_grade}</span>
                      </p>
                    </div>
                  </div>

                  {studentEssay?.competencies?.map((item, index) => {
                    if (item?.slug === switchDigital.values.competence) {
                      return (
                        <div className="skill-evaluation__competence fadeIn">
                          <div className="skill-evaluation__competence-header">
                            <div className="skill-evaluation__competence-header-inner">
                              <h3 className="skill-evaluation__competence-title">{competencies?.[index]?.name}</h3>
                              <p className="skill-evaluation__competence-subtitle">{competencies?.[index]?.description}</p>
                            </div>

                            <span className="square-tag square-tag--success">
                              {item.score}
                              <span>/{item.max_grade}</span>
                            </span>
                          </div>

                          <div>
                            <div className="skill-evaluation__comment">
                              <h4 className="skill-evaluation__comment-title">Nível e comentário</h4>

                              <ul className="skill-evaluation__comment-list">
                                <li className="skill-evaluation__comment-item">
                                  <input
                                    style={{ pointerEvents: 'none' }}
                                    className="form__control"
                                    max={5}
                                    id="level"
                                    name="level"
                                    type="text"
                                    value={'Nível ' + item?.level}
                                  />
                                </li>
                                <li className="skill-evaluation__comment-item">
                                  <TextareaAutosize
                                    disabled
                                    style={{ minHeight: 60 }}
                                    value={item.comment}
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>

                <div className="skill-evaluation__competence fadeIn skill-evaluation__card u-mb-4">
                  <div>
                    <div className="skill-evaluation__comment">
                      <h4 className="skill-evaluation__comment-title">Marcações</h4>
                      <ul className="skill-evaluation__comment-list">
                        {studentEssay?.corrections?.map((item, index) => {
                          return (
                            <li className="skill-evaluation__comment-item">
                              <TextareaAutosize
                                disabled
                                style={{ minHeight: 60 }}
                                value={item.body}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="skill-evaluation__tip">
              <div className="skill-evaluation__tip-icon">
                <HiLightBulb />
              </div>

              <h3 className="skill-evaluation__tip-title">Sugestões para Melhorar a Redação</h3>

              <p className="skill-evaluation__tip-text">{studentEssay?.suggestion_to_improve}</p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
