import React, { useState, useEffect } from 'react';
import { FiCalendar, FiChevronLeft, FiClock, FiMoreHorizontal, FiMousePointer } from 'react-icons/fi';
import { Link, useParams } from '@reach/router';
import { useFormik } from 'formik';
import FilterMultiSelect from 'app/components/FilterMultiSelect';
import defaultImage from 'app/images/placeholders/show-course.jpg';
import StudentCard from './StudentCard';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import moment from 'moment';
import EmptyState from 'app/components/EmptyState';
import { t } from 'i18next';

export function ViewActivity() {
  const client = useClient();
  const { activityId, courseId } = useParams();
  const [activity, setActivity] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [activityData, setActivityData] = useState();
  const [students, setStudents] = useState();
  const [activityStudents, setActivityStudents] = useState([]);
  const [parsedStudents, setParsedStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const basePath = window.env.REACT_APP_BASE_PATH;

  const getActivity = async () => {
    const { data, error } = await client.fetch(`/activities/${activityId}`);
    if (error) {
      toast.error('Erro ao carregar atividade');
    } else {
      setActivity(data);
    }
  };

  const getActivityData = async () => {
    const { data, error } = await client.fetch(`activities/${activityId}/activities_report?general=true`);
    if (error) {
      toast.error('Erro ao carregar atividade');
    } else {
      setActivityData(data);
    }
  };

  const getStudents = async () => {
    const { data, error } = await client.fetch(`/users?filter[courses.id]=${courseId}`);
    if (error) {
      toast.error('Erro ao carregar estudantes');
    } else {
      setStudents(data);
    }
  };

  const getActivityStudents = async () => {
    try {
      const { data } = await client.fetch(['activities', activityId, 'activity_students']);
      setActivityStudents(data);
    } catch (e) {
      toast.error('Erro ao buscar atividades dos estudantes.');
    }
  };

  useEffect(() => {
    getActivity();
    getActivityData();
    getStudents();
    getActivityStudents();
  }, []);

  useEffect(() => {
    if (activityStudents) {
      setParsedStudents(
        students?.map(item => {
          const activityStudent = activityStudents?.find(student => student.user.id === item.id);
          if (activityStudent) {
            return { ...item, status: activityStudent.status, studentActivityId: activityStudent?.id };
          } else {
            return { ...item, status: 'Não enviada' };
          }
        })
      );
    }
  }, [activityStudents, students]);

  const filters = useFormik({
    initialValues: {
      avaliada: false,
      ['não-enviada']: false,
      ['aguardando-avaliação']: false
    }
  });

  useEffect(() => {
    const statusList = [];

    const items = {
      avaliada: 'Avaliada',
      ['não-enviada']: 'Não enviada',
      ['aguardando-avaliação']: 'Avaliação realizada'
    };

    Object.keys(items).map(key => {
      if (filters.values?.[key]) {
        statusList.push(items[key]);
      }
    });

    const filterStudentsByName = parsedStudents?.filter(item => {
      if (searchTerm === '') {
        return true;
      }
      if (searchTerm !== '') {
        return item.name.toLowerCase().includes(searchTerm.toLowerCase());
      }
    });

    const filterByStatus = filterStudentsByName?.filter(item => {
      if (statusList.length === 0) {
        return true;
      }
      if (statusList.includes(item.status)) {
        return true;
      }
      return false;
    });

    setFilteredStudents(filterByStatus);
  }, [filters?.values.avaliada, filters?.values?.['não-enviada'], filters?.values?.['aguardando-avaliação'], parsedStudents, searchTerm]);

  const activityBadge = () => {
    if (activity?.['learning-system-items'].length === 0) {
      return '';
    } else {
      return 'Obrigatória';
    }
  };

  return (
    <main className="main-content main-content--block">
      <div className="filter-bar">
        <div className="filter-bar__inner">
          <div className="filter-bar__back">
            <div
              className="card__icon-wrapper"
              onClick={() => history.back()}
            >
              <FiChevronLeft />
            </div>
            <span className="filter-bar__back-container">
              <span className="filter-bar__title">{activity?.title}</span>
            </span>
          </div>
        </div>

        <div className="filter-bar__actions">
          <Link
            className="btn btn--wide btn--small btn--outline"
            to={`${basePath}/curso/admin/gerenciar/${courseId}`}
          >
            {t('button.manageContent')}
          </Link>
        </div>
      </div>

      <article className="page-detail">
        <header className="page-detail__header">
          <figure className="page-detail__bg-wrapper">
            <img
              src={defaultImage}
              alt="imagem de fundo"
              className="page-detail__bg"
            />
          </figure>

          <div className="page-detail__banner">
            <span className="badge badge--tiny badge--danger">{activityBadge()}</span>
            <h1 className="page-detail__title">{activity?.title}</h1>
            <p className="page-detail__description">{activity?.description}</p>

            {activity?.['activity-file']?.url ||
              (activity?.['activity-file']?.file && (
                <a
                  className="btn btn--wide btn--small btn--outline"
                  href={activity?.['activity-file']?.url || activity?.['activity-file']?.file}
                  target="__blank"
                >
                  {t('materials.buttonAccessMaterial')}
                </a>
              ))}
          </div>
        </header>

        <div className="widget-wrapper u-mb-0">
          <div className="info-widget info-widget--t3">
            <div className="info-widget__header">
              <h3 className="info-widget__title">Horas/aulas</h3>
            </div>

            <div className="info-widget__body">
              <p className="info-widget__value">
                <FiClock /> {activity?.['class-hours']}
              </p>
            </div>
          </div>
          <div className="info-widget info-widget--t3">
            <div className="info-widget__header">
              <h3 className="info-widget__title">{t('textsCommon.submissionIndex')}</h3>
            </div>

            <div className="info-widget__body">
              <p className="info-widget__value">
                <FiMousePointer /> {parseInt(activityData?.percentActivitySend)}%
              </p>
            </div>
          </div>
          <div className="info-widget info-widget--t3">
            <div className="info-widget__header">
              <h3 className="info-widget__title">{t('textsCommon.availableUntil')}</h3>
            </div>

            <div className="info-widget__body">
              {/* <p className="info-widget__value">
                <FiCalendar /> {activity?.['finish-at'] ? moment(activity?.['finish-at'])?.format('DD/MM/YYYY') : '-'}
              </p> */}
            </div>
          </div>
        </div>

        <hr className="u-hr" />

        <div className="widget__header u-mb-4">
          <h3 className="widget__title">Atividades realizadas</h3>
          <p className="widget__subtitle u-mb-2">
            {activityStudents?.length}/{students?.length}
          </p>
          <div className="card__progress u-mb-0">
            <div className="card__progress-container">
              <div
                className="card__progress-bar"
                role="progressbar"
                style={{ width: `${activityData?.completeInfo?.[0]?.percent}%` }}
                aria-valuenow={20}
                aria-valuemin="0"
                aria-valuemax="100"
              />
            </div>
          </div>
        </div>

        <div className="filter-bar filter-bar--borderless">
          <div className="filter-bar__inner">
            <div className="filter-bar__row">
              <label
                htmlFor="search"
                className="filter-bar__label"
              >
                {t('textsCommon.search')}
              </label>
              <input
                className="form__control form__control--search-with-icon"
                placeholder="Pesquisar por estudante"
                type="search"
                name="search"
                id="search"
                onChange={e => setSearchTerm(e.target.value)}
              />
            </div>

            <FilterMultiSelect
              data={[
                {
                  label: t('activity.evaluated'),
                  value: filters.values.avaliada,
                  onChange: () => {
                    filters.setFieldValue('avaliada', !filters.values.avaliada);
                  }
                },
                {
                  label: t('activity.notSubmited'),
                  value: filters.values?.['Não-avaliada'],
                  onChange: () => {
                    filters.setFieldValue('não-enviada', !filters.values?.['não-enviada']);
                  }
                },
                {
                  label: t('activity.waitingEvaluation'),
                  value: filters.values?.['aguardando-avaliação'],
                  onChange: () => {
                    filters.setFieldValue('aguardando-avaliação', !filters.values?.['aguardando-avaliação']);
                  }
                }
              ]}
            />
          </div>
        </div>

        <div className="round-dg-wrapper">
          <table className="round-dg round-dg--light">
            <tbody className="round-dg__body">
              {filteredStudents?.length === 0 && <EmptyState type="data" />}
              {filteredStudents?.map(item => {
                return (
                  <StudentCard
                    activityId={activityId}
                    evaluate
                    student={item}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </article>
    </main>
  );
}
