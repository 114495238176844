import React, { useState } from 'react';
import { Link } from '@reach/router';
import Submenu from './Submenu';
import { FiChevronDown } from 'react-icons/fi';

const NavLink = ({ isActive, ...restProps }) => (
  <Link
    {...restProps}
    getProps={({ isCurrent }) => ({
      className: isCurrent || isActive ? 'menu__link menu__link--is-active' : 'menu__link'
    })}
  />
);

export default function MenuItem({ item }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const basePath = window.env.REACT_APP_BASE_PATH;
  const pathname = window.location.pathname;

  const subroutes = item?.submenu?.nav.map(item => item.route.split('/')[2]);

  const hasRoute = item?.route !== basePath && pathname.includes(item?.route);
  const hasSubroutes = pathname !== basePath && subroutes?.includes(pathname.split('/')[2]);

  const handleShowSubmenu = e => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  if (!item?.submenu)
    return (
      <li className="menu__item">
        <NavLink
          to={item?.route}
          isActive={hasRoute}
        >
          {item?.icon} {item?.label && <span className="menu__label">{item?.label}</span>}
        </NavLink>
      </li>
    );

  return (
    <li
      className={!isExpanded ? 'menu__item menu__item--has-submenu' : 'menu__item menu__item--has-submenu menu__item--is-expanded'}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <span
        className={hasSubroutes ? 'menu__link menu__link--is-active' : 'menu__link'}
        onClick={handleShowSubmenu}
      >
        {item?.icon} {item?.submenu?.title && <span className="menu__label">{item?.submenu?.title}</span>} <FiChevronDown className="menu__arrow" />
      </span>
      <Submenu data={item?.submenu} />
    </li>
  );
}
