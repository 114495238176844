import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import React, { useEffect, useRef, useState } from 'react';
import YouTube from 'react-youtube';

export default function YouTubePlayer(props) {
  const { videoId, intervalId, setIntervalId, onUpdateProgress, setUpdateContentNow, showQuizModal, setShowQuizModal, selectedItem, setCurrentQuiz } = props;
  const videoRef = useRef(null);
  const percent = useRef(null);
  const authorityLevel = getAuthorityLevel();
  const youtubeOpts = {
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 1
    }
  };
  const [player, setPlayer] = useState(null);
  const [count, setCount] = useState(0);
  const [lastModalOpenTime, setLastModalOpenTime] = useState(0);

  useEffect(() => {
    if (!showQuizModal && player) {
      player.playVideo();
    }
  }, [showQuizModal]);

  const parsedQuiz = selectedItem?.['quiz-items']?.map(item => {
    const time = item.duration;
    const parts = time.split(':');
    const hours = parseInt(parts[0], 10) * 3600;
    const minutes = parseInt(parts[1], 10) * 60;
    const seconds = parseInt(parts[2], 10);
    const totalSeconds = hours + minutes + seconds;
    return {
      body: item.body,
      id: item.id,
      duration: totalSeconds,
      'quiz-item-alternatives': item['quiz-item-alternatives'],
      taxonomies: item.taxonomies
    };
  });

  const openModalTimings = parsedQuiz?.map(item => item.duration);

  const onReady = e => {
    e.target.playVideo();
    videoRef.current = e.target;

    callInterval();
    setPlayer(e.target);
  };

  const onPause = () => clearInterval(intervalId);

  const callInterval = event => {
    clearInterval(intervalId);
    setIntervalId(setInterval(() => checkProgress(), 100));
    if (event && event.data === YouTube.PlayerState.PLAYING) {
      setTimeout(() => setCount(0), 1100);
    }
  };

  const checkProgress = async () => {
    const e = videoRef.current;
    const videoCurrentTime = parseInt(e.getCurrentTime());
    const videoDuration = parseInt(e.getDuration());

    if (openModalTimings?.includes(Math.floor(videoCurrentTime))) {
      if (videoCurrentTime !== lastModalOpenTime) {
        setCount(count + 1);
        setShowQuizModal(true);
        player?.pauseVideo();

        if (document.fullscreenElement !== null) {
          document.exitFullscreen();
        }
        setLastModalOpenTime(videoCurrentTime);
      }
    }

    setCurrentQuiz(parsedQuiz?.find(item => item.duration == Math.floor(videoCurrentTime)));

    const percentage = parseFloat((videoCurrentTime * 100) / videoDuration).toFixed(2);

    if (authorityLevel === 'student') {
      if (percent.current !== percentage && videoCurrentTime === videoDuration - 1) {
        const parsedPercentage = percentage >= 80 ? 100 : percentage;
        await onUpdateProgress(parsedPercentage);
        setUpdateContentNow(true);
        // } else if (percent.current !== percentage && percentage > 0 && percentage % 5 == 0) {
        //   onUpdateProgress(percentage);
      }

      if (videoCurrentTime === videoDuration - 1) {
        clearInterval(intervalId);
      }
    }
    percent.current = percentage;
  };

  return (
    <YouTube
      videoId={videoId}
      opts={youtubeOpts}
      onReady={onReady}
      onPause={onPause}
      onPlay={callInterval}
      // onEnd={checkProgress}
      // onStateChange={callInterval}
    />
  );
}
