import React, { useState, useEffect } from 'react';
import { Link } from '@reach/router';
import { FiLogOut, FiMenu, FiX } from 'react-icons/fi';
import { useSession } from 'app/hooks/useSession';
import { ReactComponent as UserAvatar } from 'app/images/user-avatar.svg';
import { navigate } from '@reach/router';
import MenuItem from './MenuItem';
import { studentMenu } from './studentMenu';
import { adminMenu } from './adminMenu';
import HelpSubmenu from './HelpSubmenu';
import { checkIncludeProfiles } from 'app/utils/getAuthorityLevel';

export default function Menu() {
  const { session, signOut } = useSession();

  const [isExpanded, setIsExpanded] = useState(false);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const appName = window.env.REACT_APP_NAME;
  const isStudent = checkIncludeProfiles({ only: ['student'] });
  const basePath = window.env.REACT_APP_BASE_PATH;

  let favicon;

  switch (appName) {
    case 'demo':
      favicon = `${basePath}/themes/demo/favicon/favicon-menu-32x32.png`;
      break;
    case 'etipi':
      favicon = `${basePath}/themes/etipi/favicon/favicon-menu-32x32.png`;
      break;
    case 'siape':
      favicon = `${basePath}/themes/siape/favicon/favicon-menu-32x32.png`;
      break;
    case 'iesb':
      favicon = `${basePath}/themes/iesb/favicon/favicon-32x32.png`;
      break;
    case 'oab':
      favicon = `${basePath}/themes/oab/favicon/favicon-32x32.png`;
      break;
    case 'quartosecretariado':
      favicon = `${basePath}/themes/quartosecretariado/favicon/favicon-32x32.png`;
      break;
    case 'retina':
      favicon = `${basePath}/themes/retina/favicon/favicon-32x32.png`;
      break;
    case 'fic':
      favicon = `${basePath}/themes/fic/favicon/favicon-32x32.png`;
      break;
    case 'brasilstartups':
      favicon = `${basePath}/themes/brasilstartups/favicon/favicon-32x32.png`;
      break;
    case 'canaleducacao':
      favicon = `${basePath}/themes/canaleducacao/favicon/favicon-menu-32x32.png`;
      break;
    case 'egepi':
      favicon = `${basePath}/themes/egepi/favicon/favicon-menu-32x32.png`;
      break;
    case 'vegalabs':
      favicon = `${basePath}/themes/vegalabs/favicon/favicon-menu-32x32.png`;
      break;
    case 'inkluziva':
      favicon = `${basePath}/themes/inkluziva/favicon/favicon-menu-32x32.png`;
      break;
    case 'seducpi':
      favicon = `${basePath}/themes/seducpi/favicon/favicon-menu-32x32.png`;
      break;
    case 'maiseducacao':
      favicon = `${basePath}/themes/maisformacao/favicon/favicon-menu-32x32.png`;
      break;
    case 'tjpara':
      favicon = `${basePath}/themes/tjpara/favicon/favicon-menu-32x32.png`;
      break;
    case 'powerforgirls':
      favicon = `${basePath}/themes/powerforgirls/favicon/favicon-menu-32x32.png`;
      break;
    case 'caboverde':
      favicon = `${basePath}/themes/caboverde/favicon/favicon-menu-32x32.png`;
      break;

    default:
      favicon = '/themes/demo/favicon/favicon-menu-32x32.png';
      throw new Error('No REACT_APP_NAME set.');
  }

  const getAvatar = session?.user?.image?.url ? (
    <img
      className="avatar"
      src={session?.user?.image?.url}
      alt="foto do usuário"
    />
  ) : (
    <UserAvatar className="avatar" />
  );

  const menu = isStudent ? studentMenu() : adminMenu();

  useEffect(() => {
    const handleResize = () => setScreenHeight(window.innerHeight);

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const filteredMenu = menu.filter(item => Object.values(item).includes('Home') || item.submenu.nav.some(item => item.visible));

  return (
    <div className="menu">
      <div className="menu__header">
        <img
          className="menu__favicon"
          src={favicon}
          alt="logotipo"
          onClick={() => {
            navigate(`${basePath}/`);
            setIsExpanded(false);
          }}
        />

        <button
          className="menu__toggle-btn"
          type="button"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {!isExpanded ? <FiMenu /> : <FiX />}
        </button>
      </div>

      <nav
        className={isExpanded ? 'menu__nav menu__nav--is-expanded' : 'menu__nav'}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <ul className={screenHeight < 625 ? 'menu__list menu__list--no-gap' : 'menu__list'}>
          {filteredMenu.map(item => (
            <MenuItem
              item={item}
              key={item.id}
            />
          ))}
        </ul>

        <ul className={screenHeight < 625 ? 'menu__list menu__list--no-gap' : 'menu__list'}>
          <li className="menu__item">
            <Link
              className="menu__link menu__link--avatar"
              to={`${basePath}/profile`}
            >
              {getAvatar} <span className="menu__label">{session?.user?.name}</span>
            </Link>
          </li>
          <HelpSubmenu />
          <li className="menu__item">
            <a
              className="menu__link"
              onClick={() => signOut()}
            >
              <FiLogOut className="menu__icon" /> <span className="menu__label">Sair</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}
