import React from 'react';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import { useFormik } from 'formik';
import { useClient } from 'jsonapi-react';
import { useEffect } from 'react';
import { useState } from 'react';
import toast from 'react-hot-toast';
import getPermission from 'app/utils/getPermission';
import { navigate } from '@reach/router';
import Loader from 'app/components/loader';
import EmptyState from 'app/components/EmptyState';
import MockQuestionBookCard from 'app/components/ProgramOfStudies/MockQuestionBookCard';
import FilterMultiSelect from 'app/components/FilterMultiSelect';
import { t } from 'i18next';
import BreadCrumbs from 'app/components/BreadCrumbs';

function OpenQuestionBooks({ courses, uri }) {
  const client = useClient();
  const [mockQuestionBooks, setMockQuestionBooks] = useState([]);
  const [filteredMockQuestionBooks, setFilteredMockQuestionBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const basePath = window.env.REACT_APP_BASE_PATH;

  const filters = useFormik({
    initialValues: {
      course: 'blank',
      status: {
        approved: false,
        notRealized: false,
        accepting_answers: false,
        review: false
      }
    }
  });

  const getMockQuestionBooks = async () => {
    setLoading(true);
    const { data, error } = await client.fetch('question_books?filter[visibility]=open');
    if (error) {
      toast.error(t('toast.errorGetSimulations'));
    } else {
      setMockQuestionBooks(data);
      setLoading(false);
    }
  };

  const handleFilterStatus = field => {
    let newStatus = { ...filters.values.status, [field]: !filters.values.status[field] };
    filters.setFieldValue('status', newStatus);

    const selectedStatus = Object.entries(newStatus)
      .filter(([_, isChecked]) => isChecked)
      .map(([status]) => (status === 'notRealized' ? null : status));

    const allStatusUnchecked = !Object.values(newStatus).some(Boolean);

    const filterFunction = item => {
      if (allStatusUnchecked) {
        return filters.values.course !== 'blank' ? item['course-ids'].includes(Number(filters.values.course)) : true;
      }

      return selectedStatus.includes(item.status) && (filters.values.course !== 'blank' ? item['course-ids'].includes(Number(filters.values.course)) : true);
    };

    setFilteredMockQuestionBooks(mockQuestionBooks.filter(filterFunction));
  };

  const handleFilterCourse = courseId => {
    filters.setFieldValue('course', courseId);

    const selectedStatus = Object.entries(filters.values.status)
      .filter(([_, isChecked]) => isChecked)
      .map(([status]) => (status === 'notRealized' ? null : status));

    if (courseId === 'blank') {
      const filterFunction = item => (selectedStatus.length > 0 ? selectedStatus.includes(item.status) : true);
      setFilteredMockQuestionBooks(mockQuestionBooks.filter(filterFunction));
      return;
    }

    const allStatusUnchecked = !Object.values(filters.values.status).some(Boolean);

    const filterFunction = item => {
      if (allStatusUnchecked) {
        return item['course-ids'].includes(Number(courseId));
      }

      return item['course-ids'].includes(Number(courseId)) && (selectedStatus.length > 0 ? selectedStatus.includes(item.status) : true);
    };

    setFilteredMockQuestionBooks(mockQuestionBooks.filter(filterFunction));
  };

  useEffect(() => {
    if (mockQuestionBooks) {
      setFilteredMockQuestionBooks(mockQuestionBooks);
    }
  }, [mockQuestionBooks]);

  useEffect(() => {
    getMockQuestionBooks();
  }, []);

  const breadCrumbs = {
    title: 'Avaliações e Questões',
    nav: [
      {
        route: uri,
        name: 'Simulados',
        isActive: true
      }
    ]
  };

  return (
    <>
      <BreadCrumbs data={breadCrumbs} />

      <div className="filter-bar">
        <div className="filter-bar__inner">
          <FilterSelectionBox
            label={t('filter.labelSelectCourse')}
            value={filters.values.course}
            onChange={e => handleFilterCourse(e.target.value)}
            options={courses.map(c => ({
              ...c,
              name: c.title
            }))}
          />
          <FilterMultiSelect
            label={t('filter.labelSelectStatus')}
            data={[
              {
                label: 'Em análise',
                value: filters.values.status.review,
                onChange: () => handleFilterStatus('review')
              },
              {
                label: 'Aceitando respostas',
                value: filters.values.status.accepting_answers,
                onChange: () => handleFilterStatus('accepting_answers')
              },
              {
                label: t('filter.performed'),
                value: filters.values.status.approved,
                onChange: () => handleFilterStatus('approved')
              },
              {
                label: t('filter.unrealized'),
                value: filters.values.status.notRealized,
                onChange: () => handleFilterStatus('notRealized')
              }
            ]}
          />
        </div>
        {getPermission('Criar simulados', 'Avaliações') && (
          <button
            type="button"
            onClick={() => navigate(`${basePath}/simulados/criar/curso`)}
            className="btn btn--wide btn--primary"
          >
            {t('button.newSimulation')}
          </button>
        )}
        {getPermission('Criar simulados', 'Avaliações') && (
          <button
            type="button"
            onClick={() => navigate(`${basePath}/simulados/gerenciar`)}
            className="btn btn--wide btn--primary"
          >
            {t('button.manageSimulations')}
          </button>
        )}
      </div>

      {loading && <Loader />}

      {!loading && !filteredMockQuestionBooks.length > 0 && (
        <EmptyState
          type="date"
          text="Em breve, você terá acesso ao nosso simulado. É a oportunidade perfeita para criar um plano de estudos personalizado só para você. Aguarde um pouquinho!"
        />
      )}

      <div className="tab__cards">
        {!loading &&
          filteredMockQuestionBooks.map(m => {
            return <MockQuestionBookCard mockQuestionBook={m} />;
          })}
      </div>
    </>
  );
}

export default OpenQuestionBooks;
