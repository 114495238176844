import React, { useState, useEffect } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import BtnGroup from 'app/components/BtnGroup';
import { t } from 'i18next';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

export default function StepType(props) {
  const { step, form, questionType, setQuestionType, question } = props;
  const [alternatives, setAlternatives] = useState([]);

  const parsedModality = () => {
    let result = '';
    if (questionType === t('exams.selectMultipleChoice')) {
      result = 'multiple_choice';
    }
    if (questionType === t('exams.selectTrueOrFalse')) {
      result = 'right_wrong';
    }
    return result;
  };

  const addAlternative = () => {
    form.setFieldValue('alternatives-attributes', [...form.values['alternatives-attributes'], { name: '', description: '', 'is-correct': false }]);
  };

  function removeAlternative(index) {
    const alternatives = form.values['alternatives-attributes'];
    const newAlternatives = [];
    alternatives.map((item, i) => {
      if (i === index && item.id) {
        newAlternatives.push({ ...item, _destroy: true });
      } else if (i !== index) {
        newAlternatives.push(item);
      }
    });
    if (newAlternatives.filter(item => !item._destroy && item['is-correct']).length === 0) {
      let markedIsCorrect = false;
      newAlternatives.forEach((item, index) => {
        if (!markedIsCorrect && !item._destroy) {
          newAlternatives[index]['is-correct'] = true;
          markedIsCorrect = true;
        }
      });
    }

    form.setFieldValue('alternatives-attributes', newAlternatives);
  }

  // const handleChange = (editor, index) => {
  //   // setDescription(editor.getData());
  //   form.setFieldValue(`alternatives-attributes.${index}.description`, editor.getData());
  // };

  const handleChange = (editor, index) => {
    const description = editor.getData();
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const name = alphabet[index];
    form.setFieldValue(`alternatives-attributes.${index}.description`, description);
    form.setFieldValue(`alternatives-attributes.${index}.name`, name);
  };

  useEffect(() => {
    form.setFieldValue('modality', parsedModality());
  }, [questionType]);

  const changeCorrectOption = index => {
    form?.values?.['alternatives-attributes'].forEach((_, i) => {
      form?.setFieldValue(`alternatives-attributes.${i}.is-correct`, false);
    });
    form?.setFieldValue(`alternatives-attributes.${index}.is-correct`, true);
  };

  return (
    step === 'tipo' && (
      <div className="page__content fadeIn u-mb-5">
        <div className="new-questions__header">
          <h2 className="new-questions__title">{form.values.code || t('exams.titleNewQuestion')}</h2>
        </div>

        <div className="new-questions__body">
          <p className="new-questions__subtitle">{t('exams.subtitleTypeQuestion')}</p>

          <BtnGroup
            options={[t('exams.selectMultipleChoice'), t('exams.selectTrueOrFalse')]}
            name={questionType}
            setName={setQuestionType}
          />

          {questionType === t('exams.selectMultipleChoice') && (
            <>
              <fieldset className="form__row">
                <legend className="form__label">{t('exams.titleAlternatives')}</legend>

                <p className="new-questions__subtitle">{t('exams.descriptionMultipleChoice')}</p>

                <ol className="list-alternatives">
                  {form?.values?.['alternatives-attributes'].map((a, index) => {
                    return (
                      !a._destroy && (
                        <li
                          className="list-alternatives__item"
                          key={index}
                        >
                          <div className="list-alternatives__container">
                            <input
                              className="form__check-input"
                              type="radio"
                              name={`alternatives-attributes.${index}.is-correct`}
                              onChange={() => changeCorrectOption(index)}
                              value={index}
                              checked={a['is-correct'] === true}
                              id={index}
                            />
                            {/* <input
                            type="text"
                            value={a.description}
                            onChange={e => form.setFieldValue(`alternatives-attributes.${index}.description`, e.target.value)}
                          /> */}
                            <CKEditor
                              editor={Editor}
                              data={a.description}
                              onChange={(event, editor) => {
                                handleChange(editor, index);
                              }}
                              onBlur={(event, editor) => {
                                handleChange(editor, index);
                              }}
                            />
                            {form?.values?.['alternatives-attributes'].filter(item => !item._destroy).length > 2 && (
                              <FiTrash2
                                className="list-alternatives__icon"
                                onClick={() => removeAlternative(index)}
                              />
                            )}
                          </div>
                          {form?.touched?.['alternatives-attributes'] && form?.errors?.['alternatives-attributes'] && <span style={{ color: 'red' }}>{form?.errors?.['alternatives-attributes']?.[index]?.description}</span>}
                        </li>
                      )
                    );
                  })}
                </ol>
              </fieldset>
              <div className="form__row u-text-right">
                <button
                  type="button"
                  className="btn btn--link"
                  onClick={addAlternative}
                >
                  {t('exams.buttonAddAlternative')}
                </button>
              </div>
            </>
          )}

          {questionType === t('exams.selectTrueOrFalse') && (
            <fieldset className="form__row">
              <legend className="form__label"> {t('exams.descriptionTrueOrFalse')}</legend>

              <div className="form__check form__check--inline">
                <input
                  name="isCorrect"
                  className="form__check-input"
                  type="radio"
                  onChange={() => {
                    form.setFieldValue('alternatives-attributes.0.is-correct', true);
                    form.setFieldValue('alternatives-attributes.1.is-correct', false);
                  }}
                  value={form.values['alternatives-attributes'][0]['is-correct']}
                  checked={form.values['alternatives-attributes'][0]['is-correct'] === true}
                />
                <label htmlFor="right">{t('exams.alternativeTrue')}</label>
              </div>
              <div className="form__check form__check--inline">
                <input
                  name="isCorrect"
                  className="form__check-input"
                  type="radio"
                  onChange={() => {
                    form.setFieldValue('alternatives-attributes.0.is-correct', false);
                    form.setFieldValue('alternatives-attributes.1.is-correct', true);
                  }}
                  value={form.values['alternatives-attributes'][1]['is-correct']}
                  checked={form.values['alternatives-attributes'][1]['is-correct'] === true}
                />
                <label htmlFor="wrong">{t('exams.alternativeFalse')}</label>
              </div>
            </fieldset>
          )}

          <span className="form__text">
            <strong>* {t('warning.requiredField')}</strong>
          </span>
        </div>
      </div>
    )
  );
}
