import React, { useState } from 'react';
import { useFormik } from 'formik';
import { checkIncludeProfiles } from 'app/utils/getAuthorityLevel';
import TabNavBar from '../TabNavBar';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import RecommendedCourseCard from './RecommendedCourseCard';
import { t } from 'i18next';

export default function CourseRecommended() {
  const [relationships, setRelationships] = useState({
    knowledgeAreas: []
  });

  const filters = useFormik({
    initialValues: {
      knowledgeArea: 'blank',
      seachTerm: ''
    }
  });

  return (
    <main className="main-content main-content--block">
      {checkIncludeProfiles({ only: ['student'] }) && <TabNavBar selectedTab="recomendado" />}

      <div className="filter-bar">
        <div className="filter-bar__inner">
          <FilterSelectionBox
            label={t('filter.knowledgeArea')}
            value={filters.values.knowledgeArea}
            onChange={e => filters.setFieldValue('knowledgeArea', e.target.value)}
            options={relationships.knowledgeAreas.map(o => ({
              id: o.id,
              name: o.title
            }))}
          />
          <div className="filter-bar__row">
            <label
              htmlFor="search"
              className="filter-bar__label"
            >
              {t('sideBar.courses')}
            </label>
            <input
              className="form__control form__control--search-with-icon"
              placeholder={t("textsCommon.research")}
              type="search"
              name="search"
              id="search"
              onChange={e => filters.setFieldValue('seachTerm', e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="recommended-course-card-wrapper">
        <RecommendedCourseCard />
        <RecommendedCourseCard />
        <RecommendedCourseCard />
        <RecommendedCourseCard />
      </div>
    </main>
  );
}
