import React from 'react';
import MainNav from 'app/components/mainNav';
import Menu from 'app/components/Menu';

function PageWithLayouts(props) {
  const { layoutType, children } = props;

  return (
    <div className={layoutType === 'mainLayout' ? 'main-screen' : 'main-screen main-screen--admin'}>
      {layoutType === 'mainLayout' && (
        <>
          <Menu />
          <MainNav />
        </>
      )}

      {children}
    </div>
  );
}

export default PageWithLayouts;
