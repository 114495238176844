import React, { useState } from 'react';
import { FiFileText, FiHeadphones, FiVideo, FiX, FiMoreHorizontal } from 'react-icons/fi';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import { useSession } from 'app/hooks/useSession';
import Dropdown from 'app/components/Dropdown';
import dragAndDrop from 'app/images/icons/drag-and-drop.svg';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import Swal from 'sweetalert2';
import { t } from 'i18next';

export default function MaterialCard(props) {
  const { material, currentModuleId, setFilteredMaterials, setSelectedMaterial, handleEdit } = props;
  const { session } = useSession();
  const client = useClient();
  const authorityLevel = getAuthorityLevel(session);
  const basePath = window.env.REACT_APP_BASE_PATH;

  function icon(type) {
    switch (type) {
      case 'Áudio':
        return <FiHeadphones className="card__icon" />;
      case 'Vídeo':
        return <FiVideo className="card__icon" />;
      default:
        return <FiFileText className="card__icon" />;
    }
  }

  const deleteMaterial = async () => {
    const { error } = await client.delete(['/learning_system_files', material.id]);
    if (error) {
      toast.error('Erro ao excluir material');
    } else {
      toast.success('Material excluído!');
      setFilteredMaterials(prevState => prevState.filter(item => item.id !== material.id));
    }
  };

  const handleDelete = () => {
    Swal.fire({
      title: t('projects.warningDeleteMaterial'),
      text: t('projects.warningDeleteCannotBeUndoneText'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteMaterial();
      }
    });
  };

  return (
    <>
      <th
        className="round-dg__cell-header round-dg__cell-header--row"
        style={{ width: '40%' }}
      >
        <div className="card__header u-mb-0">
          <div className="card__inner-header">
            <img
              src={dragAndDrop}
              alt="ícone de arrastar"
              style={{ width: '10px', cursor: 'move', cursor: 'grab' }}
            />
            <div className="card__icon-wrapper">{icon('documento')}</div>
            <h2
              className="card__title"
              style={{ flexGrow: 'initial' }}
            >
              {material?.title}
            </h2>
            <p className="card__meta">{material?.['file-size']}</p>
          </div>
        </div>
      </th>

      <td
        className="round-dg__cell"
        style={{ width: '200px' }}
      >
        <span className="expanded-calendar__tag expanded-calendar__tag--bright">{material?.['learning-system-items']?.[0]?.body ? `${t('lessons.lesson')} ${material?.['learning-system-items']?.[0]?.body}` : ''}</span>
      </td>

      <td className="round-dg__cell">
        <div className="card__badges card__badges--one-line u-mb-0">
          {material?.taxonomies?.length <= 3 &&
            material?.taxonomies?.map(item => {
              return (
                <span
                  className="badge badge--primary"
                  title={item.name}
                >
                  {item.name}
                </span>
              );
            })}
          {material?.taxonomies?.length > 3 && (
            <>
              {material?.taxonomies?.slice(0, 3).map(t => (
                <span
                  className="badge badge--primary"
                  key={t.id}
                  title={t.name}
                >
                  {t.name}
                </span>
              ))}
              <span
                className="badge badge--more"
                title={material?.taxonomies?.slice(3).map(t => ` ${t.name}`)}
              >
                +{material?.taxonomies?.length - 3}
              </span>
            </>
          )}
        </div>
      </td>

      {authorityLevel === 'admin' && (
        <td
          className="round-dg__cell"
          style={{ width: '66px' }}
        >
          <Dropdown>
            <Dropdown.Toggle>
              <FiMoreHorizontal />
            </Dropdown.Toggle>

            <Dropdown.Content>
              <Dropdown.Item to={`${basePath}/materiais/${material.id}/${currentModuleId}`}>{t('button.view')}</Dropdown.Item>
              <Dropdown.Item onClick={() => handleEdit(material)}>{t('button.edit')}</Dropdown.Item>
              <Dropdown.Item onClick={() => handleDelete()}>{t('button.delete')}</Dropdown.Item>
            </Dropdown.Content>
          </Dropdown>
        </td>
      )}
    </>
  );
}
