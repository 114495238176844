import React, { useState, useEffect } from 'react';
import spin from './img/spin-icon.svg';
import { useQuery } from 'jsonapi-react';
import { useWeeklyChallenge } from 'app/hooks/useWeeklyChallenge';

export default function SpinWheel() {
  const [rotate, setRotate] = useState(0);

  const { currentChallenge, setCurrentTaxonomy, currentRound, amountRounds, getTaxonomies, taxonomies, slices } = useWeeklyChallenge();

  useEffect(() => {
    if (currentChallenge) getTaxonomies();
  }, []);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * taxonomies?.length);
    const rotateValue = 360 / taxonomies?.length;
    setTimeout(() => setRotate(randomIndex * rotateValue + 1440), 500);
    setCurrentTaxonomy(taxonomies[randomIndex]);
  }, [taxonomies]);

  return (
    <div className="spin-wheel-wrapper">
      <span className="spin-wheel-wrapper__count">
        Rodada {currentRound}/{taxonomies?.length}
      </span>
      <div className="spin-wheel">
        <div
          className={`spin-wheel__roulette spin-wheel__roulette--${taxonomies?.length}-slices`}
          style={{ rotate: `-${rotate}deg` }}
        >
          {taxonomies?.map((slice, i) => {
            const rotateValue = 360 / taxonomies?.length;
            const rotate = rotateValue * i;

            return (
              <div
                className="spin-wheel__slice"
                style={{ backgroundColor: slice?.color, rotate: `${rotate}deg` }}
                key={i}
              >
                <span
                  className="spin-wheel__slice-text"
                  title={slice?.name}
                >
                  {slice?.name}
                </span>
              </div>
            );
          })}
        </div>

        <img
          className="spin-wheel__spin"
          src={spin}
          alt="ícone de girar"
        />
      </div>
    </div>
  );
}
