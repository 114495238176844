import React from 'react';
import { TfiMedallAlt } from 'react-icons/tfi';
import moreMedal from 'app/images/more-medal.svg';
import ToDo from './ToDo';
import { FiArrowLeft, FiArrowRight, FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import moment from 'moment';
import { t } from 'i18next';

const StudyBlocks = ({ studyPlan, activeWeek, currentWeek, onUpdateStudyStatus, setWeeks, handleWeekChange, totalWeeks }) => {
  const totalVideos = activeWeek?.['study-plan-videos']?.length || 0;
  const completedVideos = activeWeek?.['study-plan-videos']?.filter(item => item.done === true).length || 0;
  const percentage = totalVideos > 0 ? Math.round((completedVideos / totalVideos) * 100) : 0;

  const initialDate = moment(activeWeek?.['initial-date']).format(t('datesForm.formatDayMonth'));
  const endDate = moment(activeWeek?.['end-date']).format(t('datesForm.formatDayMonth'));

  return (
    <div className="card card--study-blocks">
      <div className="card__header">
        <div className="card__inner-header">
          <div className="card__week-nav">
            <button
              className="expanded-calendar__btn expanded-calendar__btn--equal-fill"
              aria-label="semana anterior"
              disabled={currentWeek === 0}
              onClick={() => handleWeekChange(Math.max(0, currentWeek - 1))}
            >
              <FiArrowLeft />{' '}
              <span
                className="expanded-calendar__btn-text"
                aria-hidden
              >
                {t('tasks.lastWeek')}
              </span>
            </button>

            <div className="card__week-nav-title">
              <h3 className="card__title">
                {t('studyPlan.week')} {currentWeek + 1}{' '}
                <span>
                  - {initialDate} {t('exams.to')} {endDate}
                </span>
              </h3>
            </div>

            <button
              className="expanded-calendar__btn expanded-calendar__btn--equal-fill"
              aria-label="próxima semana"
              disabled={currentWeek + 1 === totalWeeks}
              onClick={() => handleWeekChange(Math.min(totalWeeks - 1, currentWeek + 1))}
            >
              <span
                className="expanded-calendar__btn-text"
                aria-hidden
              >
                {t('tasks.nextWeek')}
              </span>{' '}
              <FiArrowRight />
            </button>
          </div>

          {percentage === 100 ? (
            <div className="medal medal--sm">
              <img
                src={moreMedal}
                alt="nova medalha"
                className="medal__img"
              />
              <span className="medal__value">
                <TfiMedallAlt />
              </span>
            </div>
          ) : null}
        </div>

        <div className="card__progress">
          <div className="card__progress-container">
            <div
              className="card__progress-bar"
              role="progressbar"
              style={{ width: `${percentage}%` }}
              aria-valuenow={percentage}
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div>
          <div className="card__progress-text">{percentage}%</div>
        </div>
      </div>

      <div
        className="card__body"
        style={{ justifyContent: 'flex-start' }}
      >
        <div className="card__todo-wrapper">
          {(activeWeek?.['study-plan-videos'] || []).map(item => (
            <ToDo
              key={item.id}
              studyPlan={studyPlan}
              studyPlanVideo={item}
              setWeeks={setWeeks}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default StudyBlocks;
