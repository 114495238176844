import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

import photoIcon from '../images/icons/photo-icon.svg';
import userAvatar from '../images/user-avatar.svg';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import _ from 'lodash';
import { useSession } from 'app/hooks/useSession';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import promiseRequest from 'app/utils/promiseToast';
import Onboarding from 'app/components/Onboarding';
import Loader from 'app/components/loader';

function TabNavBar({ activeTabs, setActiveTabs }) {
  const { t } = useTranslation();
  const ssoVobys = window.env.REACT_APP_VOBYS_SSO_API_URL;
  return (
    <div className="tab">
      <div
        className={`tab__link ${activeTabs === 'profileInformations' ? 'active' : ''}`}
        onClick={() => setActiveTabs('profileInformations')}
      >
        {t('profile.toggleProfileInfo')}
      </div>
      <div
        className={`tab__link ${activeTabs === 'notifications' ? 'active' : ''}`}
        onClick={() => setActiveTabs('notifications')}
      >
        {t('profile.toggleNotifications')}
      </div>
      {!ssoVobys && (
        <div
          className={`tab__link ${activeTabs === 'security' ? 'active' : ''}`}
          onClick={() => setActiveTabs('security')}
        >
          {t('profile.toggleSecurity')}
        </div>
      )}
    </div>
  );
}

function Profile(props) {
  const { t } = useTranslation();
  const basePath = window.env.REACT_APP_BASE_PATH;
  const [activeTabs, setActiveTabs] = useState('profileInformations');
  const { promiseToast, isSubmitting } = promiseRequest();
  const { session, sanitizeResponse, setSession } = useSession();
  const client = useClient();
  const [values, setValues] = useState({
    name: session.user?.name,
    email: session.user?.email,
    phone: session.user?.userDetail?.phone,
    career: session.user?.userDetail?.career
  });
  const [loading, setLoading] = useState(true);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [userDetail, setUserDetail] = useState();
  const userId = session.user.id;
  const { path } = props;
  const profiles = session?.user?.profileNames || (session?.user && session?.user['profile-names']) || [];

  const formik = useFormik({
    initialValues: {
      password: '',
      newPassword: '',
      confirmPassword: '',
      showPassword: true,
      showNewPassword: true,
      showConfirmPassword: true
    },
    onSubmit: async values => {
      const payloadParams = {
        password: values.newPassword,
        password_confirmation: values.confirmPassword,
        old_password: values.password
      };
      const { error } = await client.mutate(`/users/${userId}`, payloadParams);
      if (error) {
        toast.error(t('toast.errorUpdatePass'));
      } else {
        toast.success(t('toast.successUpdatePass'));
      }
    },
    validationSchema: yup.object({
      password: yup.string().required(t('profile.typeYourPass')),
      newPassword: yup.string().required(t('profile.typeYourNewPass')),
      confirmPassword: yup
        .string()
        .required(t('profile.confirmNewPass'))
        .oneOf([yup.ref('newPassword')], t('profile.passMustMatch'))
    })
  });

  const updateUser = e => {
    e.preventDefault();
    const payloadParams = {
      ...values,
      userDetailAttributes: {
        id: session.user?.userDetail?.id,
        phone: values.phone,
        career: values.career,
        hoursByWeek: 40
      }
    };
    delete payloadParams.phone;
    delete payloadParams.career;

    promiseToast({
      url: ['users', session.user.id],
      request: payloadParams,
      successText: t('toast.successUpdateProfile'),
      errorText: t('toast.error')
    })
      .then(data => {
        const newObject = sanitizeResponse(data);
        setSession({ user: newObject });
      })
      .catch(error => {
        toast.error(`Ops, ${error.id} ${error.title}`);
      });
  };

  const handleChange = e => {
    console.log(e);
    let auxValues = values;
    auxValues[e.target.name] = e.target.value;
    setValues({ ...auxValues });
  };

  console.log(values);
  const handleChangeImage = e => {
    let file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = _handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  };

  const _handleReaderLoaded = readerEvt => {
    let bynaryString = readerEvt.target.result;
    let auxValues = values;
    auxValues['image'] = `data:image/jpeg;base64,${btoa(bynaryString)}`;
    setValues({ ...auxValues });
    // document.getElementById('profileForm')
    // .dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  };

  const getNotificationSettings = async () => {
    const { data, error } = await client.fetch('notification_settings');
    if (error || data == null) {
      const { data: dataCreation, error: errorCreation } = await client.mutate('notification_settings');
      if (errorCreation || dataCreation == null) {
        toast.error(t('toast.errorGetNotificationSettings'));
      } else {
        notificationsFormik.setValues(dataCreation);
      }
    } else {
      notificationsFormik.setValues(data);
    }
  };

  const updateNotificationSettings = async fields => {
    const requestParams = {
      attributes: {
        ...fields
      }
    };
    const { data, error } = await client.mutate(['notification_settings', notificationsFormik?.values?.id], requestParams);
    if (error || data == null) {
      toast.error(t('toast.errorUpdateNotificationSettings'));
    } else {
      toast.success(t('toast.successUpdateNotifications'));
      notificationsFormik.setValues(data);
    }
  };

  const handleNotificationsChange = e => {
    notificationsFormik.handleChange(e);
    updateNotificationSettings({
      [e.target.name]: e.target.checked
    });
  };

  const notificationsFormik = useFormik({
    initialValues: {
      'post-create': true,
      'event-create': true,
      'slot-create': true,
      'activity-create': true,
      'project-create': true,
      'content-create': true
    },
    onSubmit: () => {}
  });

  const updateUserDetail = async body => {
    const payloadParams = {
      hoursByWeek: 40,
      ...body
    };

    const { data, error } = await client.mutate(['user_details', session.user.userDetail.id], payloadParams);

    if (error) {
      toast.error(t('toast.errorUpdateNotificationSettings'));
    } else {
      toast.success(t('toast.successUpdateNotifications'));
      setUserDetail(data);
    }
  };

  const getUserDetail = async () => {
    setLoading(true);
    const { data, error } = await client.fetch('user_details');
    if (error || data == null) {
      toast.error(t('toast.errorGetNotificationSettings'));
    } else {
      setUserDetail(data);
    }
    setLoading(false);
  };

  const updatePassword = async event => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
    }
    const payloadParams = {
      password: newPassword,
      password_confirmation: confirmPassword,
      old_password: password
    };

    const { error } = await client.mutate(`/users/${userId}`, payloadParams);
    if (error) {
      toast.error(t('toast.errorUpdatePass'));
    } else {
      toast.success(t('toast.successUpdatePass'));
    }
  };

  useEffect(() => {
    getUserDetail();
    getNotificationSettings();
  }, []);
  {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <main className="main-content main-content--block">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <TabNavBar
                activeTabs={activeTabs}
                setActiveTabs={setActiveTabs}
              />
              {activeTabs === 'profileInformations' && (
                <div className="page">
                  <div className="profile-page__wrapper">
                    <div className="profile-page__content">
                      <div
                        style={{ marginTop: 11 }}
                        className="profile-page__sidebar"
                      >
                        <div
                          className="profile-page__avatar"
                          style={{
                            backgroundImage: `url(${values.image ?? session.user?.image?.url ?? userAvatar})`
                          }}
                        ></div>
                        <label
                          htmlFor="image"
                          className="profile-page__avatar-btn"
                        >
                          <img
                            alt=""
                            src={photoIcon}
                          />
                          <input
                            type="file"
                            name="image"
                            id="image"
                            accept=".jpeg, .png, .jpg"
                            onChange={handleChangeImage}
                          />
                        </label>
                      </div>

                      <form
                        style={{ width: '100%' }}
                        className="form"
                        id="profileForm"
                        method="post"
                        onSubmit={e => updateUser(e)}
                      >
                        <div className="form__row">
                          <label
                            className="form__label"
                            htmlFor="name"
                          >
                            {t('profile.labelFullName')}
                          </label>
                          <input
                            className="form__control"
                            type="text"
                            name="name"
                            id="name"
                            value={values.name}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form__row">
                          <label
                            className="form__label"
                            htmlFor="email"
                          >
                            Email
                          </label>
                          <input
                            className="form__control"
                            type="email"
                            name="email"
                            id="email"
                            value={values.email}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="form__row">
                          <label
                            className="form__label"
                            htmlFor="phone"
                          >
                            {t('profile.labelPhone')}
                          </label>
                          <InputMask
                            className="form__control"
                            mask="(99) 99999-9999"
                            name="phone"
                            id="phone"
                            value={values.phone}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="form__row">
                          <label
                            className="form__label"
                            htmlFor="career"
                          >
                            {t('profile.career')}
                          </label>
                          <textarea
                            className="form__control"
                            name="career"
                            id="career"
                            value={values.career}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="profile-page__input-button">
                          <input
                            type="submit"
                            disabled={isSubmitting}
                            value={t('button.save')}
                          />
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="profile-page__disclaimer">
                    {t('profile.disclaimerLGPD')}{' '}
                    <a
                      href={`${basePath}/termos-de-uso`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <strong>termos de uso </strong>
                    </a>
                    e{' '}
                    <a
                      href={`${basePath}/politica-de-privacidade`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <strong>política de privacidade</strong>
                    </a>
                    .
                  </div>
                </div>
              )}

              {activeTabs === 'notifications' && (
                <>
                  <div className="page">
                    <div className="profile-page__box">
                      <div className="form__row">
                        <div className="form__check">
                          <input
                            className="form__check-input"
                            type="checkbox"
                            name="sendPhoneNotifications"
                            id="sendPhoneNotifications"
                            checked={userDetail?.['send-phone-notifications']}
                            onChange={() =>
                              updateUserDetail({
                                'send-phone-notifications': !userDetail['send-phone-notifications']
                              })
                            }
                          />
                          <label htmlFor="sendPhoneNotifications">{t('profile.wantSmsNotification')}</label>
                        </div>
                      </div>
                      <div className="form__row">
                        <div className="form__check">
                          <input
                            className="form__check-input"
                            type="checkbox"
                            name="sendEmailNotifications"
                            id="sendEmailNotifications"
                            checked={userDetail?.['send-email-notifications']}
                            onChange={() =>
                              updateUserDetail({
                                'send-email-notifications': !userDetail['send-email-notifications']
                              })
                            }
                          />
                          <label htmlFor="sendEmailNotifications">{t('profile.wantEmailNotification')}</label>
                        </div>
                      </div>

                      {(userDetail['send-email-notifications'] || userDetail['send-phone-notifications']) && (
                        <>
                          <p className="profile-page__header">{t('profile.notificatedByTitle')}</p>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 10
                            }}
                          >
                            <div className="form__check form__switch">
                              <input
                                className="form__check-input"
                                id="event-create"
                                type="checkbox"
                                name="event-create"
                                checked={notificationsFormik?.values?.['event-create']}
                                onChange={handleNotificationsChange}
                              />
                              <label htmlFor="event-create"> {t('profile.switchCreatedEvent')}</label>
                            </div>
                            <div className="form__check form__switch">
                              <input
                                className="form__check-input"
                                id="content-create"
                                name="content-create"
                                type="checkbox"
                                checked={notificationsFormik?.values?.['content-create']}
                                onChange={handleNotificationsChange}
                              />
                              <label htmlFor="content-create">{t('profile.switchNewMaterial')}</label>
                            </div>
                            <div className="form__check form__switch">
                              <input
                                className="form__check-input"
                                id="post-create"
                                name="post-create"
                                type="checkbox"
                                checked={notificationsFormik?.values?.['post-create']}
                                onChange={handleNotificationsChange}
                              />
                              <label htmlFor="post-create">{t('profile.switchNewPost')}</label>
                            </div>
                            <div className="form__check form__switch">
                              <input
                                className="form__check-input"
                                id="activity-create"
                                type="checkbox"
                                name="activity-create"
                                checked={notificationsFormik?.values?.['activity-create']}
                                onChange={handleNotificationsChange}
                              />
                              <label htmlFor="activity-create">{t('profile.switchNewActivity')}</label>
                            </div>
                            <div className="form__check form__switch">
                              <input
                                className="form__check-input"
                                id="slot-create"
                                name="slot-create"
                                type="checkbox"
                                checked={notificationsFormik?.values?.['slot-create']}
                                onChange={handleNotificationsChange}
                              />
                              <label htmlFor="slot-create">{t('profile.switchNewTask')}</label>
                            </div>
                            <div className="form__check form__switch">
                              <input
                                className="form__check-input"
                                id="project-create"
                                name="project-create"
                                type="checkbox"
                                checked={notificationsFormik?.values?.['project-create']}
                                onChange={handleNotificationsChange}
                              />
                              <label htmlFor="project-create">{t('profile.switchAddNewProject')}</label>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="profile-page__disclaimer">
                      {t('profile.disclaimerLGPD')}{' '}
                      <a
                        href={`${basePath}/termos-de-uso`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <strong>termos de uso </strong>
                      </a>
                      e{' '}
                      <a
                        href={`${basePath}/politica-de-privacidade`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <strong>política de privacidade</strong>
                      </a>
                      .
                    </div>
                  </div>
                </>
              )}

              {activeTabs === 'security' && (
                <>
                  <div className="page">
                    <div className="profile-page__box">
                      <form
                        style={{ width: '100%' }}
                        className="form"
                        id="profileForm"
                        method="post"
                        onSubmit={formik.handleSubmit}
                      >
                        <div className="form__row">
                          <label
                            className="form__label"
                            htmlFor="name"
                          >
                            {t('profile.currentPass')}
                          </label>
                          <div className="form__row--password">
                            <input
                              placeholder={t('profile.typeYourPass')}
                              className="form__control"
                              type={formik.values.showPassword ? 'password' : 'text'}
                              name="password"
                              id="password"
                              {...formik.getFieldProps('password')}
                            />
                            {formik.touched.password && formik.errors.password ? <div style={{ color: 'red' }}>{formik.errors.password}</div> : null}
                            <span onClick={() => formik.setFieldValue('showPassword', !formik.values.showPassword)}>{formik.values.showPassword ? <FiEyeOff /> : <FiEye />}</span>
                          </div>
                        </div>

                        <div className="form__row">
                          <label
                            className="form__label"
                            htmlFor="name"
                          >
                            {t('profile.newPass')}
                          </label>
                          <div className="form__row--password">
                            <input
                              placeholder={t('profile.typeYourPass')}
                              className="form__control"
                              type={formik.values.showNewPassword ? 'password' : 'text'}
                              name="newPassword"
                              id="newPassword"
                              {...formik.getFieldProps('newPassword')}
                            />
                            {formik.touched.newPassword && formik.errors.newPassword ? <div style={{ color: 'red' }}>{formik.errors.newPassword}</div> : null}
                            <span onClick={() => formik.setFieldValue('showNewPassword', !formik.values.showNewPassword)}>{formik.values.showNewPassword ? <FiEyeOff /> : <FiEye />}</span>
                          </div>
                        </div>

                        <div className="form__row">
                          <label
                            className="form__label"
                            htmlFor="name"
                          >
                            {t('profile.confirmYourPass')}
                          </label>
                          <div className="form__row--password">
                            <input
                              placeholder={t('profile.confirmNewPass')}
                              className="form__control"
                              type={formik.values.showConfirmPassword ? 'password' : 'text'}
                              name="confirmPassword"
                              id="confirmPassword"
                              {...formik.getFieldProps('confirmPassword')}
                            />
                            {formik.touched.confirmPassword && formik.errors.confirmPassword ? <div style={{ color: 'red' }}>{formik.errors.confirmPassword}</div> : null}
                            <span onClick={() => formik.setFieldValue('showConfirmPassword', !formik.values.showConfirmPassword)}>{formik.values.showConfirmPassword ? <FiEyeOff /> : <FiEye />}</span>
                          </div>
                        </div>
                        <div className="profile-page__input-button">
                          <input
                            type="submit"
                            value={t('button.save')}
                          />
                        </div>
                      </form>
                    </div>
                    <div className="profile-page__disclaimer">
                      {t('profile.disclaimerLGPD')}{' '}
                      <a
                        href={`${basePath}/termos-de-uso`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <strong>termos de uso </strong>
                      </a>
                      e{' '}
                      <a
                        href={`${basePath}/politica-de-privacidade`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <strong>política de privacidade</strong>
                      </a>
                      .
                    </div>
                  </div>
                </>
              )}
            </div>
          </main>

          <Onboarding
            show={showOnboarding}
            setShowOnboarding={setShowOnboarding}
            onClose={() => setShowOnboarding(false)}
            path={path}
            profiles={profiles}
            title={t('onboarding.titleProfile')}
          />
        </>
      );
    }
  }
}

export default Profile;
