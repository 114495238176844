import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import userAvatar from '../images/user-avatar.svg';
import { useSession } from '../hooks/useSession';
import PostCard from '../components/post';
import SharePost from '../components/SharePost';
import Loader from '../components/loader';
import EndScroll from '../components/endScroll';
import { Link, navigate } from '@reach/router';
import Onboarding from 'app/components/Onboarding';
import getPermission from 'app/utils/getPermission';
import UserInfo from 'app/components/UserInfo';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from 'app/components/BreadCrumbs';

function Feed(props) {
  const { uri } = props;

  const { t } = useTranslation();
  const client = useClient();
  const { session } = useSession();
  const profiles = session?.user?.profileNames || (session?.user && session?.user['profile-names']) || [];

  const path = props.path;
  const basePath = window.env.REACT_APP_BASE_PATH;

  const [pageNumber, setPageNumber] = useState(1);

  const [studentClassrooms, setStudentClassrooms] = useState([]);
  const [selectedClassroomId, setSelectedClassroomId] = useState(null);

  const [professors, setProfessors] = useState([]);

  const [hasMore, setHasMore] = useState(true);
  const [posts, setPosts] = useState([]);
  const [showOnboarding, setShowOnboarding] = useState(false);

  const addPosts = async post => {
    const newPosts = posts;
    newPosts.unshift(post);
    setPosts([...newPosts]);
  };

  const getPostsList = async () => {
    setHasMore(true);
    setPageNumber(1);
    try {
      const { data } = await client.fetch(`posts?page[number]=1&page[size]=10${selectedClassroomId ? `&filter[classroom_id]=${selectedClassroomId}` : ''}`);
      if (data.length === 0) {
        setHasMore(false);
      }
      setPosts([...data]);
    } catch (e) {
      toast.error(t('toast.errorGetPost'));
    }
  };

  const updatePostsList = async () => {
    try {
      const { data } = await client.fetch(`posts?page[number]=${pageNumber}&page[size]=10${selectedClassroomId ? `&filter[classroom_id]=${selectedClassroomId}` : ''}`);
      if (data.length === 0) {
        setHasMore(false);
      }
      setPosts([...posts, ...data]);
    } catch (e) {
      toast.error(t('toast.errorGetPost'));
    }
  };

  const getClassrooms = async () => {
    const { error, data } = await client.fetch(`classrooms`);

    if (error) {
      toast.error(t('toast.errorGetClassroom'));
    } else {
      setStudentClassrooms(data);
    }
  };

  const getProfessors = async () => {
    const { error, data } = await client.fetch('users?filter[profile]=Professor');

    if (error) {
      toast.error(t('toast.errorGetClassroom'));
    } else {
      setProfessors(data);
    }
  };

  useEffect(() => {
    getClassrooms();
    getProfessors();
  }, []);

  useEffect(() => {
    getPostsList();
  }, [selectedClassroomId]);

  useEffect(() => {
    if (pageNumber !== 1) {
      updatePostsList();
    }
  }, [pageNumber]);

  const breadCrumbs = {
    title: 'Comunidade',
    nav: [
      {
        route: uri,
        name: 'Fórum',
        isActive: true
      }
    ]
  };

  return (
    <>
      <div className="main-content">
        <div className="page">
          <div className="page__col">
            <BreadCrumbs data={breadCrumbs} />

            <div className="filter-bar">
              <FilterSelectionBox
                label={t('filter.labelSelectClassroom')}
                value={selectedClassroomId}
                onChange={e => setSelectedClassroomId(e.target.value)}
                options={studentClassrooms?.map(o => ({
                  id: o?.id,
                  name: o?.institution?.name + '-' + o?.course?.title + '-' + o?.title
                }))}
              />
            </div>
            {getPermission('Criar postagens e comentários', 'Feed') && (
              <SharePost
                addPosts={e => addPosts(e)}
                selectedClassroomId={selectedClassroomId}
              />
            )}
            <InfiniteScroll
              dataLength={posts.length}
              next={() => setPageNumber(pageNumber + 1)}
              hasMore={hasMore}
              loader={<Loader />}
              // endMessage={<EndScroll />}
              style={{ padding: '0 10px', margin: '0 -10px' }}
            >
              {posts.map(post => (
                <PostCard
                  key={post.id}
                  post={post}
                  setPosts={setPosts}
                />
              ))}
            </InfiniteScroll>
          </div>

          <aside className="page__aside">
            <h3 className="user-dashboard-task-list__title u-mb-4">{t('feed.mentorsAndTeachers')}</h3>

            {professors &&
              professors.map((p, key) => (
                <div
                  className="u-cursor-pointer"
                  onClick={() => navigate(`${basePath}/usuario/metricas/${p?.id}`)}
                  state={{ isRedirected: true }}
                  key={key}
                >
                  <div className="student-card u-mb-3">
                    <header className="student-card__header">
                      <UserInfo user={p} />
                    </header>
                  </div>
                </div>
              ))}
          </aside>
        </div>
      </div>

      <Onboarding
        path={path}
        show={showOnboarding}
        setShowOnboarding={setShowOnboarding}
        onClose={() => setShowOnboarding(false)}
        profiles={profiles}
        title={t('onboarding.titleFeed')}
        videoId="SS_aqXW4ob0"
      />
    </>
  );
}

export default Feed;
