import React from 'react';
import TabNavBar from './TabNavBar';
import AdministrativeProfiles from './AdministrativeProfiles';
import ManageProfile from './AdministrativeProfiles/ManageProfile';
import NotificationExtract from './Notifications/NotificationExtract';
import Notifications from './Notifications';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Link } from '@reach/router';
import Loader from 'app/components/loader';
import { FiMoreHorizontal } from 'react-icons/fi';
import EmptyState from 'app/components/EmptyState';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import { FilterCheckBox } from 'app/components/FilterCheckBox';
import Dropdown from 'app/components/Dropdown';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import moment from 'moment';
import Swal from 'sweetalert2';
import getPermission from 'app/utils/getPermission';
import UserInfo from 'app/components/UserInfo';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import EndScroll from 'app/components/endScroll';
import BreadCrumbs from 'app/components/BreadCrumbs';

function Participants(props) {
  const { uri } = props;
  const basePath = window.env.REACT_APP_BASE_PATH;
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');

  const [users, setUsers] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [allUsers, setAllUsers] = useState([]);

  const [loading, setLoading] = useState(true);
  const client = useClient();
  const [profiles, setProfiles] = useState([]);
  const [intervalId, setIntervalId] = useState();

  const isHeightLarge = window.innerHeight >= 1060;

  const filters = useFormik({
    initialValues: {
      profile: 'blank',
      classroomless: false,
      student: 'blank'
    }
  });

  const getUsers = async array => {
    setLoading(true);
    let endPoint = `users/full?page[number]=${pageNumber}&page[size]=${isHeightLarge ? '45' : '30'}`;

    if (filters.values.classroomless) {
      endPoint += `&class=false`;
    }

    if (filters.values.profile !== 'blank') {
      endPoint += `&filter[profiles.name]=${filters.values.profile}`;
    }

    if (searchTerm) {
      endPoint += `&q[name_or_email_cont_any]=${searchTerm}`;
    }

    const { data, error } = await client.fetch(endPoint);

    if (error) {
      setHasMore(false);
      toast.error(t('toast.errorGetParticipants'));
      return;
    }

    if ((isHeightLarge && data.length < 15) || (!isHeightLarge && data.length < 10)) {
      setHasMore(false);
    }

    if (array) {
      setUsers(data);
    } else {
      setUsers([...users, ...data]);
    }
    setLoading(false);
  };

  const getProfiles = async () => {
    const { data, error } = await client.fetch(`profiles?now=${new Date().getTime()}`);
    if (error) {
      toast.error('Falha ao carregar perfis');
    } else {
      setProfiles(
        data.map(profile => {
          return {
            id: profile.name,
            name: profile.name
          };
        })
      );
    }
  };

  const deleteUser = async u => {
    setLoading(true);
    const { error } = await client.delete(['users', u.id]);
    setLoading(false);

    if (error) {
      toast.error(t('toast.errorDeleteParticipants'));
    } else {
      toast.success(t('toast.successDeleteParticipant'));
      const newParticipants = users.filter(user => user.id !== u.id);
      setUsers(newParticipants);
    }
  };

  useEffect(async () => {
    await getUsers();
  }, [pageNumber]);

  useEffect(async () => {
    getProfiles();
    await getUsers();
  }, []);

  const handleSearch = async () => {
    setPageNumber(1);
    setHasMore(true);
    await getUsers([]);
  };

  const breadCrumbs = {
    title: 'Organização da Plataforma',
    nav: [
      {
        route: uri,
        name: 'Usuário',
        isActive: true
      }
    ]
  };

  return (
    <>
      <main className="main-content main-content--block">
        <BreadCrumbs data={breadCrumbs} />

        <TabNavBar selectedTab={'participantes'} />

        <div className="tab__pane">
          <div className="filter-bar">
            <div className="filter-bar__row">
              <label
                htmlFor="participantsSearch"
                className="filter-bar__label"
              >
                {t('materials.search')}
              </label>

              <input
                aria-label="Buscar estudante"
                className="form__control form__control--search-with-icon"
                style={{ width: 'auto' }}
                placeholder={t('courses.searchParticipants')}
                type="search"
                name="studentsSearch"
                id="studentsSearch"
                onChange={e => setSearchTerm(e.target.value)}
                value={searchTerm}
              />
            </div>

            <FilterSelectionBox
              label="Perfil"
              blankLabel={t('filter.blankLabelAll')}
              value={filters.values.profile}
              onChange={e => {
                filters.setFieldValue('profile', e.target.value);
              }}
              options={profiles}
            />
            <FilterCheckBox
              label={t('filter.labelParticipantsWithoutClass')}
              value={filters.values.classroomless}
              onChange={() => filters.setFieldValue('classroomless', !filters.values.classroomless)}
            />

            <div>
              <button
                type="button"
                className="btn btn--primary btn--outline"
                onClick={handleSearch}
              >
                Filtrar
              </button>
            </div>

            {getPermission('Cadastrar participantes', 'Participantes e perfis administrativos') && (
              <Link
                to={`${basePath}/course/create/participant`}
                className="btn btn--primary btn--wide u-ml-auto"
              >
                {t('participants.buttonRegister')}
              </Link>
            )}
          </div>

          {!loading && users.length === 0 && !hasMore && (
            <EmptyState
              type="data"
              text="Nenhum usuario encontrado."
            />
          )}

          {loading && <Loader />}

          {users?.length > 0 && (
            <div className="round-dg-wrapper">
              <InfiniteScroll
                dataLength={users.length}
                next={() => setPageNumber(pageNumber + 1)}
                hasMore={hasMore}
                loader={<Loader />}
                endMessage={<EndScroll />}
                className="infinite-scroll__full"
              >
                <table className="round-dg round-dg--light u-mb-4">
                  <thead className="round-dg__header">
                    <tr className="round-dg__row">
                      <th className="round-dg__cell-header">{t('exams.thParticipant')}</th>
                      <th className="round-dg__cell-header">{t('profile.labelEmail')}</th>
                      <th className="round-dg__cell-header">{t('journey.thCourses')}</th>
                      <th className="round-dg__cell-header">{t('journey.thClasses')}</th>
                      {/* <th className="round-dg__cell-header">{t('profile.labelPhone')}</th> */}
                      <th className="round-dg__cell-header">{t('journey.thLastAccess')}</th>
                      <th
                        className="round-dg__cell-header"
                        style={{ width: '66px' }}
                      />
                    </tr>
                  </thead>

                  <tbody className="round-dg__body">
                    {users.map((user, i) => (
                      <tr
                        className="round-dg__row"
                        key={i}
                      >
                        <th className="round-dg__cell-header round-dg__cell-header--row">
                          <UserInfo user={user} />
                        </th>

                        <td className="round-dg__cell">{user.email}</td>

                        <td className="round-dg__cell">
                          {user.courses.length > 0 && (
                            <>
                              {user.courses[0].title}{' '}
                              {user.courses.length > 1 && (
                                <span
                                  className="badge badge--tiny badge--more"
                                  title={user.courses.map(course => course.title)}
                                  style={{ verticalAlign: 'sub' }}
                                >
                                  +{user.courses.length - 1}
                                </span>
                              )}
                            </>
                          )}
                        </td>

                        <td className="round-dg__cell">
                          {user.classrooms.length > 0 && (
                            <>
                              {user.classrooms[0].title}{' '}
                              {user.classrooms.length > 1 && (
                                <span
                                  className="badge badge--tiny badge--more"
                                  title={user.classrooms.map(classroom => classroom.title)}
                                  style={{ verticalAlign: 'sub' }}
                                >
                                  +{user.classrooms.length - 1}
                                </span>
                              )}
                            </>
                          )}
                        </td>

                        {/* <td className="round-dg__cell">{user?.['user-detail'].phone}</td> */}

                        <td className="round-dg__cell">
                          {user['last-access'] ? <>{moment(user['last-access']).format(t('datesForm.formatDate'))}</> : <span className="badge badge--tiny badge--warning">{t('participants.tagRegistrationSent')}</span>}
                        </td>

                        <td className="round-dg__cell">
                          <Dropdown>
                            <Dropdown.Toggle>
                              {getPermission('Editar participantes', 'Participantes e perfis administrativos') || getPermission('Excluir participantes', 'Participantes e perfis administrativos') ? <FiMoreHorizontal /> : null}
                            </Dropdown.Toggle>

                            <Dropdown.Content>
                              {getPermission('Editar participantes', 'Participantes e perfis administrativos') && <Dropdown.Item to={`${basePath}/course/edit/participant/${user.id}`}>{t('button.edit')}</Dropdown.Item>}
                              {getPermission('Excluir participantes', 'Participantes e perfis administrativos') && (
                                <Dropdown.Item
                                  onClick={() => {
                                    Swal.fire({
                                      title: t('projects.warningDeleteParticipant'),
                                      text: t('projects.warningDeleteCannotBeUndoneText'),
                                      confirmButtonText: t('button.yes'),
                                      showDenyButton: true,
                                      denyButtonText: t('button.no'),
                                      showCloseButton: true
                                    }).then(res => {
                                      if (res.isConfirmed) {
                                        deleteUser(user);
                                      }
                                    });
                                  }}
                                >
                                  {t('button.delete')}
                                </Dropdown.Item>
                              )}
                            </Dropdown.Content>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </InfiniteScroll>
            </div>
          )}
        </div>
      </main>
    </>
  );
}

export { Participants as default, AdministrativeProfiles, ManageProfile, Notifications, NotificationExtract };
