import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useClient } from 'jsonapi-react';
import _ from 'lodash';
import { QuestionsSidebar } from '../components/Questions';
import GroupedQuestion from '../components/groupedQuestion';
import QuestionsOptions from '../components/questionsOptions';
import Loader from '../components/loader';
import EndScroll from '../components/endScroll';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import { FiChevronLeft } from 'react-icons/fi';
import { navigate } from '@reach/router';
import BreadCrumbs from 'app/components/BreadCrumbs';

function Questions(props) {
  const { uri } = props;
  const basePath = window.env.REACT_APP_BASE_PATH;

  const client = useClient();
  const [pageNumber, setPageNumber] = useState(1);
  const [lastAnswerSubmited, setLastAnswerSubmited] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [groupedQuestions, setGroupedQuestions] = useState();
  const [taxonomyIds, setTaxonomyIds] = useState([]);
  const [questionCount, setQuestionCount] = useState();
  const [questionBook, setQuestionBook] = useState();

  useEffect(() => {
    fetchQuestionBook();
  }, []);

  useEffect(() => {
    fetchData();
  }, [pageNumber]);

  useEffect(() => {
    fetchData(true);
  }, [taxonomyIds]);

  useEffect(() => {
    groupQuestionsByCode();
  }, [questions]);

  const fetchData = async clearData => {
    if (clearData && pageNumber !== 1) {
      setQuestions([]);
      return setPageNumber(1);
    }

    try {
      let filterIds = '';
      taxonomyIds.forEach(id => {
        filterIds = `${filterIds}&filter[taxonomies.id][]=${id}`;
      });

      const queryParams = props.id ? `&filter[question_book_id]=${props.id}` : '';
      //filter[visibility]=train&
      const { data, meta } = await client.fetch(`questions?page[number]=${pageNumber}&page[size]=10${filterIds}${queryParams}`);
      setQuestionCount(meta.total);
      if (data.length === 0) {
        setHasMore(false);
      }
      clearData ? setQuestions(data) : setQuestions([...questions, ...data]);
    } catch (e) {
      console.log(e);
    }
  };

  const groupQuestionsByCode = () => {
    if (questions.length > 0) {
      const groupedByCode = _.groupBy(questions, 'code');
      setGroupedQuestions(groupedByCode);
    }
  };
  const fetchQuestionBook = async () => {
    if (props.id) {
      try {
        const { data } = await client.fetch(`question_books/${props.id}`);
        setQuestionBook(data);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const randomizeQuestions = () => {
    setQuestions(_.sampleSize(questions, questions.length));
  };

  const clearFilters = () => {
    setTaxonomyIds([]);
  };

  const authorityLevel = getAuthorityLevel();

  const breadCrumbs = {
    title: 'Avaliações e Questões',
    nav: [
      {
        route: `${basePath}/questions/apostilas`,
        name: 'Exercícios',
        isActive: false
      },
      {
        route: uri,
        name: questionBook?.title,
        isActive: true
      }
    ]
  };

  return (
    <>
      <div className="main-content">
        <BreadCrumbs data={breadCrumbs} />

        <div className="question-list">
          {taxonomyIds.length > 0 && (
            <div
              className="btn btn--outline btn--small"
              onClick={clearFilters}
            >
              Limpar filtros
            </div>
          )}
          <InfiniteScroll
            dataLength={questions.length}
            next={() => setPageNumber(pageNumber + 1)}
            hasMore={hasMore}
            loader={<Loader />}
            // endMessage={<EndScroll />}
          >
            {groupedQuestions &&
              Object.entries(groupedQuestions).map(([key, groupedQuestion]) => (
                <>
                  <GroupedQuestion
                    key={key}
                    groupedQuestion={groupedQuestion}
                    taxonomyIds={taxonomyIds}
                    setTaxonomyIds={setTaxonomyIds}
                    updateLastAnswerSubmited={setLastAnswerSubmited}
                  />
                </>
              ))}
          </InfiniteScroll>
        </div>
        <div className="questions-options">
          {!props.id && (
            <QuestionsOptions
              questionCount={questionCount}
              randomizeQuestions={randomizeQuestions}
              taxonomyIds={taxonomyIds}
              setTaxonomyIds={setTaxonomyIds}
            />
          )}
          {authorityLevel === 'student' && (
            <QuestionsSidebar
              reply
              questionBook={questionBook}
              lastAnswerSubmited={lastAnswerSubmited}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default Questions;
