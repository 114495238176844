import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { Link } from '@reach/router';
import { FiAlertCircle } from 'react-icons/fi';
import Loader from 'app/components/loader';
import ActivityModalCard from './ActivityModalCard';
import { t } from 'i18next';

export default function ActivityForm(props) {
  const { setShowModal, lessonId, onSuccess, activityListedIds } = props;
  const client = useClient();
  const [searchTerm, setSearchTerm] = useState('');
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [selectedActivitiesRequireds, setSelectedActivitiesRequireds] = useState({});
  const basePath = window.env.REACT_APP_BASE_PATH;

  function selectActivity(activity) {
    setSelectedActivities([...selectedActivities, activity]);
  }

  const setRequireds = (activity, isRequired) => {
    setSelectedActivitiesRequireds({
      ...selectedActivitiesRequireds,
      [activity.id]: isRequired
    });
  };

  function removeActivity(activityId) {
    setSelectedActivities(selectedActivities.filter(item => item['activity-id'] !== activityId));
  }

  const getActivities = async () => {
    const { data, error } = await client.fetch('/activities');
    if (error) {
      toast.error('Falha ao carregar atividades');
    } else {
      setActivities(data.filter(d => !activityListedIds.includes(d.id)));
      setLoading(false);
    }
  };

  const addActivity = async () => {
    const { error } = await client.mutate(`/learning_system_items/${lessonId}`, {
      'learning-system-activities-attributes': selectedActivities.map(activity => {
        return {
          ...activity,
          ['is-required']: selectedActivitiesRequireds[activity['activity-id']]
        };
      })
    });
    if (error) {
      toast.error('Atividade já vinculada');
    } else {
      onSuccess();
      setShowModal(false);
      setSelectedActivities([]);
    }
  };

  useEffect(() => {
    getActivities();
  }, []);

  const filteredActivity = activities.filter(activity => {
    if (searchTerm == '') {
      return activity;
    }
    if (activity.title != null && activity.title.toLowerCase().includes(searchTerm.toLocaleLowerCase())) {
      return activity;
    }
  });

  return (
    <form
      method="post"
      className="form"
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <h2 className="modal__simple-title">{t('activiy.activityTitle')}</h2>

      <input
        style={{ position: 'sticky', top: '0' }}
        className="form__control u-mb-3"
        type="search"
        placeholder="Buscar atividade..."
        onChange={e => setSearchTerm(e.target.value)}
      />

      <div
        class="alert u-mb-2"
        role="alert"
      >
        <FiAlertCircle className="alert__icon" />
        <span>
          <strong>Resposta obrigatória:</strong> O estudante só avançará para a próxima aula após a realização da atividade.
        </span>
      </div>

      <div
        className="u-overflow-y"
        style={{
          minHeight: '56px',
          maxHeight: '368px',
          padding: '16px',
          margin: '0 -16px 1.5em -16px'
        }}
      >
        {loading && <Loader />}

        {filteredActivity.map(activity => (
          <ActivityModalCard
            setRequireds={setRequireds}
            isRequired={selectedActivitiesRequireds[activity.id]}
            isSelected={Boolean(selectedActivities.find(item => item['activity-id'] === activity.id))}
            selectActivity={selectActivity}
            removeActivity={removeActivity}
            activity={activity}
            key={activity.id}
          />
        ))}
      </div>

      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '12px'
        }}
      >
        <button
          onClick={addActivity}
          className="btn btn--primary btn--wide"
          type="submit"
        >
          Salvar e finalizar
        </button>
        <Link
          to={`${basePath}/atividade-complementar`}
          className="btn btn--link"
          type="submit"
        >
          Criar um nova atividade
        </Link>
      </div>
    </form>
  );
}
