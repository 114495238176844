import { useTranslation } from 'react-i18next';

import { TopBar } from './TopBar';
import { ShadowContainer } from './ShadowContainer';

import avaliationPng from '../../images/onboarding/avaliations/navigation.png';
import courseSelectPng from '../../images/onboarding/avaliations/course-select.png';
import cardsAvaliationPng from '../../images/onboarding/avaliations/cards-avaliation.png';

export function AvaliationsOnboarding() {
  const { t } = useTranslation();

  return (
    <section>
      <TopBar />

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.testsSimulationsResults')}</h1>
        <p className="text-option-onboarding">{t('onboarding.testsSimulationsResultsDesc')}</p>

        <img
          src={avaliationPng}
          className="w-auto"
        />
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.filters')}</h1>
        <p className="text-option-onboarding">{t('onboarding.filtersDesc')}</p>

        <ShadowContainer width="fit-content">
          <img src={courseSelectPng} />
        </ShadowContainer>
      </div>

      <div>
        <h1 className="title-option-onboarding">{t('onboarding.exploreCardAvaliations')}</h1>
        <p className="text-option-onboarding">{t('onboarding.exploreCardAvaliationsDesc')}</p>

        <img src={cardsAvaliationPng} />
      </div>
    </section>
  );
}
