import { useTranslation } from 'react-i18next';

import { TopBar } from './TopBar';
import { ShadowContainer } from './ShadowContainer';

import ongoingCourses from '../../images/onboarding/dashboard/ongoingCourses.png';
import timelinePng from '../../images/onboarding/dashboard/timeline.png';
import calendarPng from '../../images/onboarding/dashboard/calendar.png';

export function DashboardOnboarding() {
  const { t } = useTranslation();

  return (
    <section>
      <TopBar />

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.quickAccessCourse')}</h1>
        <p className="text-option-onboarding">{t('onboarding.quickAccessCourseDescription')}</p>

        <ShadowContainer width="70%">
          <img src={ongoingCourses} />
        </ShadowContainer>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.timeline')}</h1>
        <p className="text-option-onboarding">{t('onboarding.timelineDesc')}</p>
        <ShadowContainer width="70%">
          <img src={timelinePng} />
        </ShadowContainer>
      </div>

      <div>
        <h1 className="title-option-onboarding">{t('onboarding.monthlyCalendar')}</h1>
        <p className="text-option-onboarding">{t('onboarding.monthlyCalendarDesc')}</p>

        <ShadowContainer width="fit-content">
          <img src={calendarPng} />
        </ShadowContainer>
      </div>
    </section>
  );
}
