import React, { useEffect, useState } from 'react';
import { Link, navigate } from '@reach/router';
import { FiChevronLeft, FiCalendar } from 'react-icons/fi';
import EssayCover from 'app/images/placeholders/show-course.jpg';
import mockImg from './mockImg.png';
import { useClient } from 'jsonapi-react';
import moment from 'moment';
import BreadCrumbs from 'app/components/BreadCrumbs';

export default function EssayAbout({ essayId, uri }) {
  const client = useClient();
  const [essay, setEssay] = useState();
  const basePath = window.env.REACT_APP_BASE_PATH;

  const essayStatus = {
    accepting_proposal: 'Aceitando respostas',
    close_for_submissions: 'Prazo encerrado',
    processing: 'Em correção',
    unreviewed: 'Em correção',
    reviewed: 'Corrigida',
    minimum_length_not_satisfied: 'Arquivo inválido',
    annulled: 'Anulada',
    failed_to_process: 'Falha ao processar'
  };

  const statusBadgeColor = {
    accepting_proposal: 'badge--warning',
    close_for_submissions: 'badge--danger',
    processing: 'badge--warning',
    unreviewed: 'badge--warning',
    reviewed: 'badge--success',
    annulled: 'badge--danger',
    minimum_length_not_satisfied: 'badge--danger',
    failed_to_process: 'badge--danger'
  };

  const getEssay = async () => {
    const { data, error } = await client.fetch(`/essays/${essayId}`);
    if (error) {
      toast.error('Erro ao buscar redação');
    } else {
      setEssay(data);
    }
  };

  useEffect(() => getEssay(), []);

  const breadCrumbs = {
    title: 'Atividades',
    nav: [
      {
        route: `${basePath}/redacao`,
        name: 'Redação',
        isActive: false
      },
      {
        route: uri,
        name: essay?.title ? essay?.title : essay?.topic,
        isActive: true
      }
    ]
  };

  return (
    <main className="main-content main-content--block">
      <BreadCrumbs data={breadCrumbs} />

      <div className="course-about u-mb-4">
        <div
          className="show-course-banner"
          style={{
            backgroundImage: `url(${null || EssayCover})`
          }}
        >
          <div className="show-course-banner__inner">
            <div className="show-course-banner__container">
              <span className={`badge badge--tiny ${statusBadgeColor[essay?.status]}`}>{essayStatus[essay?.status]}</span>
              <div
                title={essay?.title ? essay?.title : essay?.topic}
                className="show-course-banner__title"
              >
                {essay?.title ? essay?.title : essay?.topic}
              </div>

              {(essay && essay?.status === 'accepting_proposal') || essay?.status === 'minimum_length_not_satisfied' ? (
                <div className="show-course-banner__cta">
                  <Link
                    className="btn btn--wide btn--outline-secondary"
                    to={`${basePath}/redacao/enviar/${essay?.id}`}
                  >
                    Enviar a redação
                  </Link>
                </div>
              ) : (
                <div className="show-course-banner__cta">
                  <p>Sua redação está sendo revisada pelo professor e o resultado estará disponível aqui em breve.</p>
                </div>
              )}

              {essay?.status === 'annulled' && (
                <div className="show-course-banner__cta">
                  <Link
                    className="btn btn--wide btn--outline-secondary"
                    to={`${basePath}/redacao/consulta/${essay?.id}/${essay?.['essay-student-id']}`}
                  >
                    Acessar redação
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>

        <p className="card__count card__count--shadow">
          <FiCalendar className="card__icon" />
          <span>
            Disponível até <strong>{moment(essay?.['delivery-date']).format('DD/MM/YYYY')}</strong>
          </span>
        </p>

        <div className="course-about__header">
          <h1 className="course-about__title">Enunciado</h1>

          <p className="course-about__description">{essay?.statement}</p>

          <p className="course-about__description">
            Tema: {essay?.topic}
            <br />
            {/* Título: <strong>{essay?.title}</strong> */}
          </p>
        </div>

        <div className="course-about__body">
          <h2 className="course-about__title">Textos motivadores</h2>
          {essay?.['essay-motivator-texts']?.map((item, index) => {
            return (
              <article>
                <h3 className="course-about__subtitle">Texto {index + 1}</h3>
                <p
                  className="course-about__description"
                  dangerouslySetInnerHTML={{ __html: item.body }}
                />
                {/* <p className="course-about__description">Disponível em: https://brasilescola.uol.com.br/portugues/preconceito-linguistico.htm. Acesso em: 19 ago. 2022 (adaptado).</p> */}
              </article>
            );
          })}
        </div>
      </div>
    </main>
  );
}
