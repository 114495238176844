import React from 'react';
import EssaySendSidebar from './EssaySendSidebar';
import EssaySendContent from './EssaySendContent';

export default function EssaySend({ essayId }) {
  return (
    <div className="main-screen" style={{marginTop: "50px"}}>
      <EssaySendSidebar />

      <main className="main-content main-content--admin-layout">
        <EssaySendContent essayId={essayId} />
      </main>
    </div>
  );
}
