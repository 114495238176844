import React, { useState } from 'react';
import api from '../services/api.js';
import { Link } from '@reach/router';
import PostActions from './PostActions';
import userAvatar from '../images/user-avatar.svg';
import moment from 'moment';
import 'moment/locale/pt';
import toast from 'react-hot-toast';
import ReactHtmlParser from 'react-html-parser';
import MagicLink from './magicLink';
import { navigate } from '@reach/router';

function PostCard(props) {
  const [post, setPost] = useState(props.post);
  const basePath = window.env.REACT_APP_BASE_PATH;

  const deletePost = post => {
    api
      .delete(`posts/${post.id}`)
      .then(response => {
        setPost(null);
        toast('Mensagem deletada!', {
          icon: '🗑️'
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleNavigateToPost = (event, route, options = undefined) => {
    event.stopPropagation();
    navigate(route, options);
  };

  return (
    post && (
      <div
        className="post"
        onClick={event => {
          handleNavigateToPost(event, `${basePath}/posts/${post.id}`);
        }}
        style={{ cursor: 'pointer' }}
      >
        <div
          className="post__avatar"
          onClick={event => handleNavigateToPost(event, `${basePath}/usuario/metricas/${props.post['user-id']}`, { state: { isRedirected: true } })}
          style={{
            backgroundImage: `url(${post?.user?.image?.url || userAvatar})`
          }}
        ></div>
        <div className="post__body">
          <div className="post__header">
            <div
              className="post__header-name"
              onClick={event => handleNavigateToPost(event, `${basePath}/usuario/metricas/${props.post['user-id']}`, { state: { isRedirected: true } })}
            >
              {post?.user?.name}
            </div>
            <div className="post__header-time">{moment(post['created-at']).fromNow()}</div>
          </div>
          <div className="post__description">
            <div className="post__description-text">{ReactHtmlParser(post?.body)}</div>
          </div>

          {post?.['magic-links'] &&
            post?.['magic-links'].map(link => (
              <a
                target="_blank"
                rel="noreferrer"
                href={link.url}
                key={link.id}
              >
                <MagicLink magicLink={link} />
              </a>
            ))}
        </div>
        <PostActions
          post={post}
          deletePost={deletePost}
          setPosts={props.setPosts}
        />
      </div>
    )
  );
}

export default PostCard;
