import React from 'react';

export default function BtnGroup(props) {
  const { options, name, setName, className } = props;

  return (
    <div
      className={`btn-group ${className}`}
      role="group"
    >
      {options?.map((alternative, index) => (
        <React.Fragment key={index}>
          <input
            type="radio"
            name={name}
            autoComplete="off"
            id={`${name}-${index}`}
            checked={alternative === name}
            onChange={() => setName(alternative)}
          />
          <label
            className="btn btn--outline"
            htmlFor={`${name}-${index}`}
          >
            {alternative}
          </label>
        </React.Fragment>
      ))}
    </div>
  );
}
