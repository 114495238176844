import Avatar from "app/components/Avatar"

import  { useClient } from "jsonapi-react"
import  { useState, useEffect } from "react"
import { getUserById } from "../utils/api"

const Employee = ({employeeId}) => {
    const [employee, setEmployee] = useState(null)
    const client = useClient()

    const fetchEmployee = async () => {
        const response = await getUserById(client, employeeId)
        setEmployee(response)
    }


    useEffect(()=> {
        if(employeeId)
            fetchEmployee()
    },[]) 

    return (
        employee && <>
            <Avatar src={employee?.image?.url} className="avatar-image--employee" /> {employee?.name}
        </>
    )
}

export default Employee