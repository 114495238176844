import { Link } from '@reach/router';
import moment from 'moment';
import React from 'react';

function MockQuestionBookCard(props) {
  const { mockQuestionBook } = props;
  const basePath = window.env.REACT_APP_BASE_PATH;

  return (
    <div
      className="sidebar-card"
      style={{ marginBottom: '20px' }}
    >
      <div>
        <h1>{mockQuestionBook.title}</h1>
        <p>Responda ao simulado agora e desbloqueie seu plano de estudos personalizado! Não deixe para depois, seu futuro espera!</p>
        <Link
          to={`${basePath}/simulados/sobre/${mockQuestionBook.id}`}
          className="btn btn--outline"
          title="Mais detalhes"
        >
          Mais detalhes
        </Link>
      </div>
    </div>
  );
}

export default MockQuestionBookCard;
