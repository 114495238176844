import React, { useState, useEffect } from 'react';
import YouTubePlayer from './YoutubePlayer';
import VimeoPlayer from './VimeoPlayer';
import { useClient } from 'jsonapi-react';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import EmptyExam from './EmptyExam';
import DisapprovedExam from './DisapprovedExam';

export default function CourseClassroomVideo(props) {
  const {
    unfinishedExam,
    url,
    learnSystemUseItem,
    setLearnSystemUseItem,
    setUpdateContentNow,
    selectedItem,
    updateLearnSystemContents,
    showQuizModal,
    setShowQuizModal,
    setCurrentQuiz,
    checkLearnSystemUseItem
  } = props;
  const [player, setPlayer] = useState();
  const [videoId, setVideoId] = useState();
  const [intervalId, setIntervalId] = useState();

  const client = useClient();
  const authorityLevel = getAuthorityLevel();

  const isStudent = authorityLevel === 'student';

  useEffect(() => {
    if (url) checkVideoPlayer(url);
  }, [url]);

  const onUpdateProgress = async (percent) => {
    if (isStudent && (percent >= learnSystemUseItem.percent || !learnSystemUseItem?.finished)) {
      const params =
        percent >= learnSystemUseItem?.percent
          ? {
              percent
            }
          : {};

      const { data } = await client.mutate(['learning_system_user_items', learnSystemUseItem?.id], params);
      setLearnSystemUseItem(data);
      if (data.finished) {
        updateLearnSystemContents(data);
      }
    }
  };

  const checkVideoPlayer = url => {
    const youtubeRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
    const vimeoRegex = /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;

    const youtubeResult = url.match(youtubeRegex);
    if (youtubeResult) {
      clearInterval(intervalId);
      setPlayer(null);
      setTimeout(() => setPlayer('youtube'), 500);
      setVideoId(youtubeResult[6]);
      return;
    }

    const vimeoResult = url.match(vimeoRegex);
    if (vimeoResult) {
      clearInterval(intervalId);
      setPlayer(null);
      setTimeout(() => setPlayer('vimeo'), 500);
      setVideoId(vimeoResult[4]);
    }
  };

  return (
    <div className="classroom-video-content fadeIn">
      {unfinishedExam === undefined ? (
        <div className="classroom-video-content__video-frame">
          {player && player === 'youtube' && (
            <YouTubePlayer
              setCurrentQuiz={setCurrentQuiz}
              selectedItem={selectedItem}
              showQuizModal={showQuizModal}
              setShowQuizModal={setShowQuizModal}
              videoId={videoId}
              intervalId={intervalId}
              setIntervalId={setIntervalId}
              onUpdateProgress={onUpdateProgress}
              setUpdateContentNow={setUpdateContentNow}
            />
          )}
          {player && player === 'vimeo' && (
            <VimeoPlayer
              setCurrentQuiz={setCurrentQuiz}
              selectedItem={selectedItem}
              showQuizModal={showQuizModal}
              setShowQuizModal={setShowQuizModal}
              videoId={videoId}
              intervalId={intervalId}
              setIntervalId={setIntervalId}
              onUpdateProgress={onUpdateProgress}
              setUpdateContentNow={setUpdateContentNow}
              onFetchLearnSystemUserItem={checkLearnSystemUseItem}
            />
          )}
          {player !== 'youtube' && player !== 'vimeo' && <iframe src={url?.replace('view?usp=share_link', 'preview')}></iframe>}
        </div>
      ) : unfinishedExam?.['question-book-user-ids'].length > 0 ? (
        <DisapprovedExam unfinishedExam={unfinishedExam} />
      ) : (
        <EmptyExam unfinishedExam={unfinishedExam} />
      )}
    </div>
  );
}
