import React, { useState, useEffect } from 'react';
import { useClient } from 'jsonapi-react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import ViewAuditorium from './ViewAuditorium';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import EmptyState from 'app/components/EmptyState';
import Loader from 'app/components/loader';
import AuditoriumCard from './AuditoriumCard';
import { checkIncludeProfiles } from 'app/utils/getAuthorityLevel';
import { useAuditorium } from 'app/hooks/useAuditorium';
import CreateAuditorium from './CreateAuditorium';
import { Link } from '@reach/router';
import { useSession } from 'app/hooks/useSession';
import { navigate } from '@reach/router';
import Onboarding from 'app/components/Onboarding';
import ParticipantsModal from './ParticipantsModal';
import getPermission from 'app/utils/getPermission';
import ActionCable from 'actioncable';
import moment from 'moment';
import BreadCrumbs from 'app/components/BreadCrumbs';

const TEN_SECONDS_IN_MILLISECONDS = 10 * 1000;

function Auditorium(props) {
  if (!getPermission('Visualizar auditórios', 'Auditórios')) {
    return null;
  }
  const { path, uri } = props;
  const basePath = window.env.REACT_APP_BASE_PATH;
  const { getAuditoriums, loading, auditoriums } = useAuditorium();
  const [courses, setCourses] = useState([]);
  const [filteredAuditoriums, setFilteredAuditoriums] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activeAuditorium, setActiveAuditorium] = useState();
  const [messageChannel, setMessageChannel] = useState(null);
  const { session } = useSession();
  const isAdmin = session.user.profileNames.includes('Administrador');
  const { t } = useTranslation();
  const client = useClient();
  const [showOnboarding, setShowOnboarding] = useState(false);
  const profiles = session?.user?.profileNames || (session?.user && session?.user['profile-names']) || [];

  const apiBaseUrl = client.config.url;
  const cableUrl = apiBaseUrl.replace(/^http(s)?:\/\/(.*?)\/api\/v1\/?$/, 'wss://$2/cable');
  const cable = ActionCable.createConsumer(cableUrl);

  const filters = useFormik({
    initialValues: {
      courses: 'blank',
      gre: 'blank',
      school: 'blank',
      auditorium_name: ''
    }
  });

  const getCourses = async () => {
    try {
      const { data } = await client.fetch('courses?simple=true');
      setCourses(data);
    } catch {
      toast.error(t('toast.errorGetCourse'));
    }
  };

  if (auditoriums.length === 1 && !isAdmin) {
    navigate(`${basePath}/auditorio/${auditoriums[0].id}`, { state: { singleAuditorium: true } });
  }

  useEffect(() => {
    if (messageChannel) {
      const auditoriumsStatusChanged = filteredAuditoriums.map(item => {
        if (item.id === Number(messageChannel.auditorium_id)) {
          return {
            ...item,
            status: 'Online',
            updated: moment().format()
          };
        } else {
          return { ...item, updated: null };
        }
      });

      setFilteredAuditoriums(auditoriumsStatusChanged);
    }
  }, [messageChannel]);

  useEffect(() => {
    getCourses();
    getAuditoriums();
  }, []);

  useEffect(() => {
    if (activeAuditorium) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [activeAuditorium]);

  useEffect(() => {
    const { courses, gre, school, auditorium_name } = filters.values;

    const filtered = auditoriums?.filter(item => {
      let filterResult = true;
      if (courses !== 'blank') {
        filterResult = filterResult && item?.['course-ids']?.includes(parseInt(courses));
      }
      if (gre !== 'blank') {
        filterResult = filterResult && item?.courses?.some(courseItem => courseItem['regional-id'] === parseInt(gre));
      }
      if (school !== 'blank') {
        filterResult = filterResult && item?.courses?.some(courseItem => courseItem['institution-id'] === parseInt(school));
      }

      if (auditorium_name !== '') {
        filterResult = filterResult && item?.name?.toLowerCase().includes(auditorium_name.toLowerCase());
      }

      return filterResult;
    });
    setFilteredAuditoriums(filtered);
  }, [filters.values, auditoriums]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const auditoriumsStatusChanged = filteredAuditoriums.map(item => {
        if (moment(item.updated).isBefore(moment().subtract(30, 'seconds'))) {
          return {
            ...item,
            status: 'Offline'
          };
        } else {
          return item;
        }
      });

      if (auditoriumsStatusChanged.length) {
        setFilteredAuditoriums(auditoriumsStatusChanged);
      }
    }, TEN_SECONDS_IN_MILLISECONDS);

    return () => clearInterval(intervalId);
  }, [filteredAuditoriums]);

  useEffect(() => {
    const subscription = cable.subscriptions.create(
      {
        channel: 'AuditoriumStatusChannel'
      },
      {
        received: data => {
          setMessageChannel(data);
        }
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [cableUrl]);

  const breadCrumbs = {
    title: 'Comunidade',
    nav: [
      {
        route: uri,
        name: 'Monitoria',
        isActive: true
      }
    ]
  };

  return (
    <>
      <main className="main-content main-content--block">
        <BreadCrumbs data={breadCrumbs} />

        {!checkIncludeProfiles({ only: ['student'] }) ? (
          <div className="filter-bar">
            <div className="filter-bar__inner">
              {/* <FilterSelectionBox
                label={'Selecione uma GRE'}
                blankLabel={t('filter.blankLabelAll')}
                value={filters.values.gre}
                onChange={e => {
                  filters.setFieldValue('gre', e.target.value);
                }}
                options={regionals}
              /> */}
              {/* <FilterSelectionBox
                label={'Selecione uma escola'}
                blankLabel={t('filter.blankLabelAll')}
                value={filters.values.school}
                onChange={e => {
                  filters.setFieldValue('school', e.target.value);
                }}
                options={istitutions}
              /> */}
              <FilterSelectionBox
                label={t('filter.schoolYear')}
                blankLabel={t('filter.blankLabelAll')}
                value={filters.values.courses}
                onChange={e => {
                  filters.setFieldValue('courses', e.target.value);
                }}
                options={courses}
              />
            </div>
            {getPermission('Criar auditórios', 'Auditórios') && (
              <div className="filter-bar__actions">
                <Link
                  className="btn btn--primary btn--wide"
                  to={`${basePath}/auditorio/criar/nome`}
                >
                  {t('auditorium.newAuditorium')}
                </Link>
              </div>
            )}
          </div>
        ) : (
          <div className="filter-bar">
            <div className="filter-bar__inner">
              <div className="filter-bar__row">
                <label
                  htmlFor="auditoriumSearch"
                  className="filter-bar__label"
                >
                  {t('materials.search')}
                </label>
                <input
                  aria-label="Buscar auditório"
                  className="form__control form__control--search-with-icon"
                  style={{ width: 'auto' }}
                  placeholder="Pesquisar auditório"
                  type="search"
                  name="auditoriumSearch"
                  id="auditoriumSearch"
                  value={filters.values.auditorium_name}
                  onChange={e => filters.setFieldValue('auditorium_name', e.target.value)}
                />
              </div>
              <FilterSelectionBox
                label={t('filter.labelSelectCourse')}
                blankLabel={t('filter.blankLabelAll')}
                value={filters.values.courses}
                onChange={e => {
                  filters.setFieldValue('courses', e.target.value);
                }}
                options={courses}
              />
            </div>
          </div>
        )}

        {loading && <Loader />}

        {filteredAuditoriums.length === 0 && !loading && <EmptyState type="content" />}

        {!loading && (
          <div className="fadeIn">
            <div className="tab__cards">
              {filteredAuditoriums?.map(auditorium => (
                <AuditoriumCard
                  auditorium={auditorium}
                  setActiveAuditorium={setActiveAuditorium}
                  key={auditorium.id}
                />
              ))}
            </div>
          </div>
        )}
      </main>

      <Onboarding
        show={showOnboarding}
        setShowOnboarding={setShowOnboarding}
        onClose={() => setShowOnboarding(false)}
        path={path}
        profiles={profiles}
        title={t('onboarding.titleAuditorium')}
      />

      <ParticipantsModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        auditorium={activeAuditorium}
      />
    </>
  );
}

export { Auditorium as default, ViewAuditorium, CreateAuditorium };
