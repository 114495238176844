import React from 'react';
import MainTabNavBar from 'app/components/MainTabNavBar';
import BannerChallenge from './BannerChallenge';
import Challenges from './Challenges';
import RankingByRegion from './RankingByRegion';
import RankingByParticipant from './RankingByParticipant';
import { useQuery } from 'jsonapi-react';
import { WeeklyChallengeProvider } from 'app/providers/WeeklyChallenge';
import BreadCrumbs from 'app/components/BreadCrumbs';

function WeekChallenge(props) {
  return (
    <WeeklyChallengeProvider>
      <WeekChallengeContent {...props} />
    </WeeklyChallengeProvider>
  );
}

function WeekChallengeContent(props) {
  const { selectedTab, uri } = props;
  const { data: challenges, isLoading } = useQuery('/challenges');

  const tabs = [
    {
      name: 'Desafios',
      url: '/desafio-da-semana/desafios',
      slug: 'desafios',
      order: 1
    },
    {
      name: 'Ranking por secretaria',
      url: '/desafio-da-semana/ranking-por-regiao',
      slug: 'ranking-por-regiao',
      order: 2
    },
    {
      name: 'Ranking por participante',
      url: '/desafio-da-semana/ranking-por-participante',
      slug: 'ranking-por-participante',
      order: 3
    }
  ];

  const breadCrumbs = {
    title: 'Aprendizado Dinâmico',
    nav: [
      {
        route: uri,
        name: 'Desafio da Semana',
        isActive: true
      }
    ]
  };

  return (
    <main className="main-content main-content--block">
      <BreadCrumbs data={breadCrumbs} />

      <MainTabNavBar
        tabs={tabs}
        selectedTab={selectedTab}
      />

      <BannerChallenge
        challenges={challenges}
        isLoading={isLoading}
      />

      {selectedTab === 'desafios' && (
        <Challenges
          challenges={challenges}
          isLoading={isLoading}
        />
      )}
      {selectedTab === 'ranking-por-regiao' && <RankingByRegion />}
      {selectedTab === 'ranking-por-participante' && <RankingByParticipant />}
    </main>
  );
}

export { WeekChallenge as default };
