import { navigate } from '@reach/router';
import Dropdown from 'app/components/Dropdown';
import { useState } from 'react';
import { FiChevronDown, FiUsers } from 'react-icons/fi';
import Swal from 'sweetalert2';
import { deleteInstitutionById } from './utils/api';
import { useClient } from 'jsonapi-react';

const InstitutionCard = ({ institution, onEdit, setEditing, afterSubmit }) => {
  const { name } = institution;
  const id = institution.id;
  const basePath = window.env.REACT_APP_BASE_PATH;
  const client = useClient();

  const navigateToStructure = () => {
    navigate(`${basePath}/participantes/instituicoes/${id}`);
  };

  const editInsitutions = () => {
    setEditing(true);
    onEdit(institution);
  };

  const deleteInstitution = async () => {
    try {
      await deleteInstitutionById(client, id);
      afterSubmit(id);
    } catch (e) {
      console.error(e);
    }
  };

  const confirmDelete = event => {
    event.stopPropagation();
    Swal.fire({
      title: 'Deseja deletar a instituição',
      text: 'Essa alteração não pode ser revertida',
      confirmButtonText: 'Sim',
      showDenyButton: true,
      denyButtonText: 'Não',
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteInstitution();
      }
    });
  };

  return (
    <div className="card card-width">
      <div className="card__header">
        <h2 className="card__title">{name}</h2>
      </div>

      <div className="card__body">
        <div className="flex-column-gap-5">
          <div className="flex-gap-10">
            {institution?.['organization-structure']?.['organizational-charts'] &&
              institution?.['organization-structure']?.['organizational-charts'].slice(0, 2).map(role => (
                <span
                  className="badge badge--tiny "
                  key={role.id}
                >
                  {' '}
                  {role.title}
                </span>
              ))}

            {institution?.['organization-structure']?.['organizational-charts']?.length > 2 && <span className="badge badge--tiny badge--more"> +{institution['organization-structure']['organizational-charts'].length - 2}</span>}
          </div>
          <div className="flex-gap-10">
            <FiUsers className="employeesCount__icon" /> {institution?.['organization-user-count']}
          </div>
        </div>
      </div>

      <div className="card__footer">
        <div
          className="btn-group btn-group--with-dropdown"
          role="group"
          aria-label="Ações"
        >
          <div
            className="btn btn--outline btn--small"
            onClick={() => navigateToStructure()}
          >
            {' '}
            Gerenciar{' '}
          </div>
          <Dropdown className="btn--small btn btn--outline">
            <Dropdown.Toggle>
              <FiChevronDown />
            </Dropdown.Toggle>

            <Dropdown.Content>
              <Dropdown.Item onClick={() => editInsitutions()}>Editar</Dropdown.Item>
              <Dropdown.Item onClick={confirmDelete}>Deletar</Dropdown.Item>
            </Dropdown.Content>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default InstitutionCard;
