import React, { useMemo, useState } from 'react';
import { navigate } from '@reach/router';
import { FiZap } from 'react-icons/fi';
import { useClient } from 'jsonapi-react';
import { ReactComponent as MedalCheck } from 'app/images/icons/medal-check.svg';
import { ReactComponent as MedalCheckUndone } from 'app/images/icons/medal-check-undone.svg';
import FlashcardModal from '../StudyBlock/FlashcardModal';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { usePlatformAbilities } from 'app/hooks/usePlatformAbilities';
import { useEffect } from 'react';

export default function ToDo(props) {
  const { studyPlanVideo, studyPlan, setWeeks } = props;

  const client = useClient();
  const { t } = useTranslation();
  const { abilities } = usePlatformAbilities();
  const [done, setDone] = useState(studyPlanVideo?.done);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [flashcardData, setFlashcardData] = useState(null);
  const [flashcardQuestions, setFlashcardQuestions] = useState([]);
  const [quantityFlashcardsAccepted, setQuantityFlashcardsAccepted] = useState(0);
  const [restart, setRestart] = useState(false);
  const [advice, setAdvice] = useState(false);
  const basePath = window.env.REACT_APP_BASE_PATH;

  const handleStartFlashCard = async () => {
    setIsLoading(true);
    const { data, error } = await client.mutate('user_flashcards', {
      'study-plan-video-id': studyPlanVideo.id
    });

    if (error) {
      setIsLoading(false);
      console.log(error);
      toast.error(t('toast.errorStartFlashCard'));
      return;
    }

    const flashcards = data?.flashcards.map((val, index) => {
      return {
        ...val,
        position: index + 1
      };
    });

    const flashcardsAccepted = flashcards.filter(item => data['user-answer-flashcards'].find(item2 => item.id === item2['flashcard-id'] && item2['accepted-answer'] && item2['know-answer']));

    setQuantityFlashcardsAccepted(flashcardsAccepted.length);
    setFlashcardQuestions(
      data?.flashcards.filter(f => {
        if (restart) {
          return true;
        } else {
          const answeredFlashCardsIds = data?.['user-answer-flashcards'].map(item => item?.['flashcard-id']);
          return !answeredFlashCardsIds.includes(f.id);
        }
      })
    );
    setFlashcardData(data);
    setIsLoading(false);
    setShowModal(true);
  };

  useEffect(() => {
    if (restart) {
      handleStartFlashCard();
    }
  }, [restart]);

  const handleCloseModal = () => {
    setShowModal(false);
    setRestart(false);
    setAdvice(false);
  };
  return (
    <div className="card__todo">
      {/* Input utilizado na versão anterior
      <input
        className="card__todo-check-input"
        type="checkbox"
        name="done"
        id="done"
        checked={done}
        onChange={checkStudyPlan}
        onClick={e => e.stopPropagation()}
      /> */}

      <div className="card__todo-header">
        {done ? <MedalCheck className="card__todo-medal-check" /> : <MedalCheckUndone className="card__todo-medal-check" />}

        {flashcardData && (
          <FlashcardModal
            restart={restart}
            setRestart={setRestart}
            show={showModal}
            onClose={() => handleCloseModal()}
            taxonomyName={studyPlanVideo?.taxonomy?.name}
            flashcardData={flashcardData}
            questions={flashcardQuestions}
            allQuestionsQuantity={flashcardData?.flashcards?.length}
            quantityFlashcardsAccepted={quantityFlashcardsAccepted}
            onChangePercentage={() => null}
            done={done}
            setDone={setDone}
            advice={advice}
            setAdvice={setAdvice}
          />
        )}
      </div>

      <div className="card__todo-body">
        <h4 className="card__todo-title">{studyPlanVideo?.taxonomy?.name}</h4>

        {/* Taxonomidas da segunda versão
        {studyPlanVideo?.video?.taxonomies?.length > 0 && (
          <div className="card__badges card__badges--one-line u-mb-0">
            {studyPlanVideo?.video?.taxonomies?.length <= 2 &&
              studyPlanVideo?.video?.taxonomies?.map(t => (
                <span
                  className="badge badge--primary"
                  key={t.id}
                  title={t.name}
                >
                  {t.name}
                </span>
              ))}

            {studyPlanVideo?.video?.taxonomies?.length > 2 && (
              <>
                {studyPlanVideo?.video?.taxonomies?.slice(0, 2).map(t => (
                  <span
                    className="badge badge--primary"
                    key={t.id}
                    title={t.name}
                  >
                    {t.name}
                  </span>
                ))}
                <span
                  className="badge badge--more"
                  title={studyPlanVideo?.video?.taxonomies?.slice(2).map(t => ` ${t.name}`)}
                >
                  +{studyPlanVideo?.video?.taxonomies?.length - 2}
                </span>
              </>
            )}
          </div>
        )} */}

        {/* Assets da primeira versão
        <div className="card__todo-assets">
          <div className="card__todo-count">
            <FiVideo /> 1
          </div>
          <div className="card__todo-count">
            <FiFileText /> {studyPlanVideo.video['video-materials'].length}
          </div>
        </div> */}
      </div>

      <div className="card__todo-footer">
        {abilities?.flashcards && studyPlanVideo.video?.['flashcards-count'] > 0 ? (
          <button
            className={isLoading ? 'btn btn--small btn--review disabled' : 'btn btn--small btn--review'}
            onClick={handleStartFlashCard}
          >
            <FiZap className="btn__icon" /> {t('flashCard.review')}
          </button>
        ) : null}

        <button
          className="btn btn--small btn--outline"
          onClick={() =>
            navigate(`${basePath}/plano-de-estudos/${studyPlan?.id}/bloco-de-estudos/${studyPlanVideo.id}`, {
              state: {
                name: studyPlanVideo?.taxonomy?.name
              }
            })
          }
        >
          {t('studyPlan.access')}
        </button>
      </div>
    </div>
  );
}
