import React from 'react';
import { t } from 'i18next';
import AccordionItem from './AccordionItem';
import { FiInfo } from 'react-icons/fi';

export default function TabAbout({ currentModule }) {
  return (
    <div
      className="course-about fadeIn"
      style={{ gap: '0' }}
    >
      <div style={{ display: 'flex', flexWrap: 'wrap-reverse', alignItems: 'flex-end', justifyContent: 'space-between' }}>
        <div className="show-course-section">
          <div className="show-course-section__title">{t('lms.titleAboutModule')}</div>
          <div className="show-course-section__description">{currentModule?.description}</div>
        </div>

        <div className="deadline u-mb-4">
          <FiInfo size={20} />
          <span>
            O <b>certificado do módulo</b> estará disponível a partir de {new Date(currentModule?.['finished-at']).toLocaleDateString('pt-BR')}
          </span>
        </div>
      </div>

      {currentModule?.['learning-system-subjects']?.length > 0 && (
        <div className="show-course-section">
          <div className="show-course-section__title">{t('lms.titleWhatLearn')}</div>
          {currentModule?.['learning-system-subjects']?.map(item => {
            return (
              <div className="show-course-section__description">
                • {item.body} <br />
              </div>
            );
          })}
        </div>
      )}

      {currentModule?.['learning-system-requirements']?.length > 0 && (
        <div className="show-course-section">
          <div className="show-course-section__title">{currentModule?.['learning-system-requirements']?.length === 0 ? '' : t('lms.requirements')}</div>
          <div className="card__badges">
            {currentModule?.['learning-system-requirements']?.map(item => {
              return <span className="badge badge--primary">{item.title}</span>;
            })}
          </div>
        </div>
      )}

      <div className="show-course-section">
        <div className="show-course-section__title">Disciplinas e aulas</div>

        <div className="accordion">
          {currentModule?.['learning-system-contents']?.map(item => (
            <AccordionItem
              key={item.id}
              module={item}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
