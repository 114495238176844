import React, { useState, useEffect, useContext } from 'react';

import Loader from 'app/components/loader';
import { useSession } from 'app/hooks/useSession';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import Modal from 'app/components/Modal';
import { ProjectsProvider } from './provider';
import { ProjectsContext } from './provider';
import ProjectsCard from './ProjectsCard';
import { ProjectForm } from './ProjectForm';
import EmptyState from 'app/components/EmptyState';
import Onboarding from 'app/components/Onboarding';
import getPermission from 'app/utils/getPermission';
import { useFormik } from 'formik';
import FilterMultiSelect from 'app/components/FilterMultiSelect';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import { useClient } from 'jsonapi-react';
import { FilterCheckBox } from 'app/components/FilterCheckBox';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from 'app/components/BreadCrumbs';

export const Projects = props => (
  <ProjectsProvider>
    <ScreenContentProjects {...props} />
  </ProjectsProvider>
);

export default Projects;
export * from './ProjectsSingle';
export * from './ShowProject';
export * from './GroupProjects';
export * from './GroupProjects/ViewGroupProject';
export * from './IndividualProjects';
export * from './IndividualProjects/ViewIndividualProject';
export * from './PersonalizedProjects';
export * from './CreateProject';

const ScreenContentProjects = props => {
  const { uri } = props;

  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const path = props.path;
  const { session } = useSession();
  const client = useClient();
  const basePath = window.env.REACT_APP_BASE_PATH;
  const profiles = session?.user?.profileNames || (session?.user && session?.user['profile-names']) || [];
  const [relationships, setRelationships] = useState({
    courses: [],
    classrooms: []
  });

  const authorityLevel = getAuthorityLevel();

  const { loading, projects, setProjects, getProjects } = useContext(ProjectsContext);

  const filters = useFormik({
    initialValues: {
      course: 'blank',
      classroom: 'blank',
      visibility: {
        approved: false,
        ongoing: false
      }
    }
  });

  const getRelationships = async () => {
    const { data: courses } = await client.fetch('courses');
    const { data: classrooms } = await client.fetch('classrooms');

    setRelationships({
      courses: courses || [],
      classrooms: classrooms || []
    });
  };

  function filterProjects(id, type, projects) {
    return projects.filter(function (project) {
      return project['projectable-type'] === type && project['projectable-id'] === id;
    });
  }

  function filterApproved(projects) {
    return projects.filter(function (project) {
      return project.approved === true;
    });
  }

  function filterOngoing(projects) {
    return projects.filter(function (project) {
      return project.approved === null;
    });
  }

  useEffect(() => {
    let newProjects = projects;
    if (filters.values.course !== 'blank') {
      const id = +filters.values.course;
      const type = 'Course';
      newProjects = filterProjects(id, type, projects);
    }
    if (filters.values.classroom !== 'blank') {
      const id = +filters.values.classroom;
      const type = 'Classroom';
      newProjects = filterProjects(id, type, projects);
    }
    if (searchTerm !== '') {
      newProjects = newProjects.filter(p => p.name != null && p.name.toLowerCase().includes(searchTerm.toLocaleLowerCase()));
    }
    if (filters.values.visibility.approved === true) {
      newProjects = filterApproved(newProjects);
    }
    if (filters.values.visibility.ongoing === true) {
      newProjects = filterOngoing(newProjects);
    }
    setFilteredProjects(newProjects);
  }, [filters.values, searchTerm, projects]);

  useEffect(() => {
    setFilteredProjects(projects);
    filters.setFieldValue('course', 'blank');
    filters.setFieldValue('classroom', 'blank');
  }, [projects]);

  useEffect(() => {
    getProjects();
    getRelationships();
  }, []);

  useEffect(() => {
    if (authorityLevel !== 'student') {
      navigate(`${basePath}/projetos/grupos`);
    }
  }, [authorityLevel]);

  const breadCrumbs = {
    title: 'Atividades',
    nav: [
      {
        route: uri,
        name: 'Projetos',
        isActive: true
      }
    ]
  };

  return (
    <>
      {getPermission('Visualizar lista de projetos', 'Projetos') && (
        <main className="main-content main-content--block">
          <BreadCrumbs data={breadCrumbs} />

          <div className="filter-bar filter-bar--left">
            <div className="filter-bar__row">
              <label
                htmlFor="projectsSearch"
                className="filter-bar__label"
              >
                {t('materials.search')}
              </label>
              <input
                aria-label="Buscar projeto"
                className="form__control form__control--search-with-icon"
                style={{ width: 'auto' }}
                placeholder={t('filter.searchProject')}
                type="search"
                name="projectsSearch"
                id="projectsSearch"
                onChange={e => setSearchTerm(e.target.value)}
              />
            </div>

            <FilterSelectionBox
              label={t('filter.labelSelectCourse')}
              value={filters.values.course}
              onChange={e => {
                filters.setFieldValue('course', e.target.value);
                filters.setFieldValue('classroom', 'blank');
              }}
              options={relationships.courses.map(c => ({
                ...c,
                name: c.title
              }))}
            />

            <FilterSelectionBox
              label={t('filter.labelSelectClassroom')}
              value={filters.values.classroom}
              onChange={e => {
                filters.setFieldValue('classroom', e.target.value);
                filters.setFieldValue('course', 'blank');
              }}
              options={relationships.classrooms.map(c => ({
                ...c,
                name: c.title
              }))}
            />

            <FilterMultiSelect
              data={[
                {
                  label: t('exams.statusInApproved'),
                  value: filters.values.visibility.approved,
                  onChange: () => {
                    filters.setFieldValue('visibility', {
                      approved: !filters.values.visibility.approved,
                      ongoing: false
                    });
                  }
                },
                {
                  label: t('filter.labelInProgress'),
                  value: filters.values.visibility.ongoing,
                  onChange: () => {
                    filters.setFieldValue('visibility', {
                      approved: false,
                      ongoing: !filters.values.visibility.ongoing
                    });
                  }
                }
              ]}
            />

            {getPermission('Criar projetos', 'Projetos') && (
              <button
                type="button"
                className="btn btn--primary btn--wide"
                onClick={() => setShow(true)}
              >
                {t('projects.newProjects')}
              </button>
            )}
          </div>

          {loading && <Loader />}

          {!loading && !filteredProjects.length > 0 && (
            <EmptyState
              type="data"
              title={t('emptyState.inAnyProjectTitle')}
              text={t('emptyState.inAnyProjectText')}
            />
          )}

          {!loading && filteredProjects.length > 0 && (
            <div className="tab__cards">
              {filteredProjects.map(p => (
                <ProjectsCard
                  data={p}
                  key={p.id}
                />
              ))}
            </div>
          )}
        </main>
      )}

      <Modal
        show={show}
        onClose={() => setShow(false)}
      >
        <ProjectForm
          labelForm={'Criar novo projeto'}
          labelFormButton={t('button.saveContinue')}
        />
      </Modal>

      <Onboarding
        path={path}
        show={showOnboarding}
        setShowOnboarding={setShowOnboarding}
        onClose={() => setShowOnboarding(false)}
        profiles={profiles}
        title={t('onboarding.titleProjects')}
        videoId="_RHp2GVpIj4"
      />
    </>
  );
};
