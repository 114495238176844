import React, { useEffect, useState } from 'react';
import TabNavBar from './TabNavBar';
import ExamlistTab from './ExamListTab';
import toast from 'react-hot-toast';
import { useQuery } from 'jsonapi-react';
import { useTranslation } from 'react-i18next';
import OpenQuestionBooks from 'app/screens/OpenQuestionBooks';
import Onboarding from 'app/components/Onboarding';
import { useSession } from 'app/hooks/useSession';
import { UserMetrics } from 'app/screens/ExamMetrics/UserMetrics';
import { AdminMetrics } from 'app/screens/ExamMetrics/AdminMetrics';

function ExamsResolution({ path }) {
  const { t } = useTranslation();
  const { session } = useSession();
  const sessionUser = session.user.profileNames[0];
  const [showOnboarding, setShowOnboarding] = useState(false);
  const { data: courses = [], error: errorCourses, isLoading: isLoadingCourses } = useQuery('courses?simple=true');
  const { data: exams = [], error: errorExams, isLoading: isLoadingExams } = useQuery('question_books?filter[visibility]=exam&filter[except_learning_systems]=true');
  const basePath = window.env.REACT_APP_BASE_PATH;

  const pathName = window.location.pathname;
  const selectedTab = pathName.split(`${basePath}/`).filter(e => e)[0];
  const profiles = session?.user?.profileNames || (session?.user && session?.user['profile-names']) || [];
  const isLoading = isLoadingCourses || isLoadingExams;

  useEffect(() => {
    if (errorCourses) {
      toast.error(t('toast.errorGetCourse'));
    }
    if (errorExams) {
      toast.error(t('toast.errorGetExams'));
    }
  }, [errorCourses, errorExams]);

  return (
    <>
      <main className="main-content main-content--block">
        <TabNavBar selectedTab={selectedTab} />

        <div className="tab__content">
          {selectedTab === 'simulados' && <OpenQuestionBooks courses={courses} />}
          {selectedTab === 'avaliacoes' && (
            <ExamlistTab
              courses={courses}
              exams={exams}
              loading={isLoading}
            />
          )}
          {selectedTab === 'resultado-das-avaliacoes' && <>{sessionUser === 'Aluno' ? <UserMetrics courses={courses} /> : <AdminMetrics />}</>}
        </div>
      </main>

      <Onboarding
        show={showOnboarding}
        setShowOnboarding={setShowOnboarding}
        onClose={() => setShowOnboarding(false)}
        path={path}
        profiles={profiles}
        title={t('onboarding.titleExamsResolution')}
      />
    </>
  );
}

export default ExamsResolution;
