import React from 'react';
import { navigate } from '@reach/router';
import PostCardShow from '../components/postShow';
import Loader from '../components/loader';
import { useQuery } from 'jsonapi-react';
import { FiChevronLeft } from 'react-icons/fi';
import EmptyState from 'app/components/EmptyState';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from 'app/components/BreadCrumbs';

function Post(props) {
  const { uri } = props;

  const { t } = useTranslation();
  const { data = {}, isLoading, setData } = useQuery(['posts', props.slug]);
  const basePath = window.env.REACT_APP_BASE_PATH;
  const addComments = comment => {
    let post = data;
    post.comments.push(comment.data);
    post['comments-count'] = post.comments.length;
    setData({ ...post });
  };

  const breadCrumbs = {
    title: 'Comunidade',
    nav: [
      {
        route: `${basePath}/feed`,
        name: 'Fórum',
        isActive: false
      },
      {
        route: uri,
        name: 'Voltar',
        isActive: true
      }
    ]
  };

  return (
    <>
      <div className="main-content">
        <div className="post-list">
          <BreadCrumbs data={breadCrumbs} />

          {isLoading && <Loader />}
          {!isLoading && data && (
            <PostCardShow
              key={data.id}
              post={data}
              addComments={addComments}
              setPost={setData}
            />
          )}
          {!isLoading && !data && (
            <EmptyState
              type="content"
              title={t('emptyState.contentNotAvaliable')}
              text={t('emptyState.contactATeacherText')}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default Post;
