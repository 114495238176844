import React, { useEffect } from 'react';
import CountdownTimer from './CountdownTimer';
import ChallengeQuestion from './ChallengeQuestion';
import { useWeeklyChallenge } from 'app/hooks/useWeeklyChallenge';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';

export default function Question() {
  const { currentRound, amountRounds, currentChallenge, setCurrentAttempt, getQuestions } = useWeeklyChallenge();
  const client = useClient();

  const createAttempt = async () => {
    const { data, error } = await client.mutate(`/challenges/${currentChallenge.id}/challenge_attempt_items`);
    if (error) {
      toast.error('Erro ao criar uma tentativa');
    } else {
      setCurrentAttempt(data.id);
    }
  };

  useEffect(() => {
    createAttempt();
    getQuestions();
  }, []);

  const percentage = (currentRound * 100) / amountRounds;

  return (
    <section className="challenge-modal__section challenge-modal__section--question">
      <div className="challenge-modal__header">
        <div className="challenge-modal__header-content">
          <h3 className="challenge-modal__title">{currentChallenge?.taxonomy?.name}</h3>
          <p className="challenge-modal__subtitle">
            Rodada {currentRound}/{amountRounds}
          </p>
        </div>
        <CountdownTimer className="challenge-modal__countdown-timer" />
      </div>

      <div className="card__progress u-mb-0">
        <div className="card__progress-container">
          <div
            className="card__progress-bar"
            role="progressbar"
            style={{ width: `${percentage}%` }}
            aria-valuenow={percentage}
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
        <div className="card__progress-text">{`${percentage?.toFixed(2)}%`}</div>
      </div>

      <ChallengeQuestion />

      {/* <QuestionCountdown /> */}
    </section>
  );
}
