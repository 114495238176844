import React, { useState } from 'react';
import { useClient } from 'jsonapi-react';
import moment from 'moment';
import getIcon from 'app/helpers/getIcon';

export default function Notification({ notification, notifications, setNotificationCount, notificationCount, setNotifications }) {
  const client = useClient();
  const basePath = window.env.REACT_APP_BASE_PATH;

  async function handleNotificationRead() {
    const { data, error, errors } = await client.mutate(['notifications', notification.id], {
      unread: false
    });

    setNotificationCount(notificationCount - 1);
    setNotifications(notifications.map(n => (n.id === notification.id ? { ...n, unread: false } : n)));
    window.location.replace(`${basePath}${notification?.url}`);
  }

  return (
    <div
      onClick={() => {
        handleNotificationRead();
      }}
      className={notification.unread === true ? 'notification unread' : 'notification read'}
    >
      <div className="notification__header">
        <div className="notification__icon">{getIcon(notification.icon)}</div>
      </div>

      <div className="notification__body">
        <h4 className="notification__title">{notification.name}</h4>
        <div className="notification__text">
          {notification.body && (
            <div onClick={() => handleNotificationRead()}>
              {notification.body.split('*f6$').map((n, i) => {
                if (n[0] == '>') {
                  return (
                    <a
                      href={`${basePath}${notification.url}`}
                      key={i}
                    >
                      {n.substring(1, n?.length)}
                    </a>
                  );
                } else {
                  return n;
                }
              })}
            </div>
          )}
        </div>
      </div>

      <div className="notification__footer">{notification['created-at'] && <span className="notification__datetime">{moment(notification['created-at']).fromNow()}</span>}</div>
    </div>
  );
}
