import React, { useEffect, useState } from 'react';
import multipleChoice from 'app/images/icons/multiple-choice.svg';
import rightOrWrong from 'app/images/icons/right-or-wrong.svg';
import { toUpper } from 'lodash';
import { t } from 'i18next';
import IconVerified from 'app/components/Icons/IconVerified';

export default function TipsSidebar(props) {
  const { step, form, knowledgeList } = props;

  const correctAnswer = toUpper(String.fromCharCode(97 + (form?.values?.['alternatives-attributes'] || []).findIndex(item => item['is-correct'] === true)));
  const [answerState, setAnswerState] = useState('');

  useEffect(() => {
    if (form?.values?.modality === 'multiple_choice') {
      setAnswerState(correctAnswer);
    } else if (form?.values?.modality === 'right_wrong' && correctAnswer === 'A') {
      setAnswerState('Certo');
    } else if (form?.values?.modality === 'right_wrong' && correctAnswer === 'B') {
      setAnswerState('Errado');
    }
  }, [correctAnswer, form?.values?.modality]);

  function renderExclusivityText(visibility) {
    switch (visibility) {
      case 'train':
        return t('exams.textAsideNotExclusive');
        break;
      case 'exam':
        return <p>exclusiva de provas</p>;
        break;
      case 'mock':
        return <p>exclusiva de simulados</p>;
        break;
      case 'exam_and_mock':
        return <p>exclusiva de provas e simulados</p>;
        break;
    }
  }

  return (
    <>
      {step === 'nome' && <div className="page__aside" />}

      {(step === 'informacoes' || step === 'tipo' || step === 'visibilidade') && (
        <div className="page__aside page__aside--bg">
          <h3 className="page__aside-title">
            {form?.values?.code || t('exams.titleNewQuestion')} <IconVerified />
          </h3>

          <p className="page__aside-text">{knowledgeList[+form?.values?.knowledge - 1]?.title}</p>

          {form?.values?.selectedTaxonomies?.length > 0 && (
            <div className="card__badges card__badges--one-line">
              {form?.values?.selectedTaxonomies?.length <= 1 &&
                form?.values?.selectedTaxonomies?.map(t => (
                  <span
                    className="badge badge--primary"
                    key={t.id}
                    title={t.name}
                  >
                    {t.name}
                  </span>
                ))}

              {form?.values?.selectedTaxonomies?.length > 1 && (
                <>
                  {form?.values?.selectedTaxonomies?.slice(0, 1).map(t => (
                    <span
                      className="badge badge--primary"
                      key={t.id}
                      title={t.name}
                    >
                      {t.name}
                    </span>
                  ))}
                  <span
                    className="badge badge--more"
                    title={form?.values?.selectedTaxonomies?.slice(1).map(t => ` ${t.name}`)}
                  >
                    +{form?.values?.selectedTaxonomies?.length - 1}
                  </span>
                </>
              )}
            </div>
          )}

          {step !== 'informacoes' && (
            <>
              <p className="page__aside-text u-mb-0">
                <strong>{t('exams.formTitleMotivatorText')}</strong>
              </p>
              <p
                className="page__aside-text page__aside-text--excerpt"
                dangerouslySetInnerHTML={{ __html: form?.values?.['motivator-texts-attributes']?.[0]?.body }}
              />

              <p className="page__aside-text u-mb-0">
                <strong>{t('exams.formTitleStatementQuestion')}</strong>
              </p>
              <p
                className="page__aside-text page__aside-text--excerpt"
                dangerouslySetInnerHTML={{ __html: form?.values?.description }}
              />

              {step === 'visibilidade' && (
                <>
                  <p className="page__aside-text u-mb-0">
                    <strong>Tipo</strong>
                  </p>
                  <p className="page__aside-text">
                    <div
                      className="card__icon-wrapper"
                      style={{ height: '32px', width: '32px', display: 'grid', placeContent: 'center', float: 'left', margin: '4px 4px 0 0' }}
                    >
                      <img
                        className="card__icon"
                        src={form?.values?.modality == 'multiple_choice' ? multipleChoice : rightOrWrong}
                        alt="multitpla escolha"
                        style={{ width: '16px' }}
                      />
                    </div>
                    {form?.values?.modality == 'multiple_choice' ? t('exams.selectMultipleChoice') : t('exams.trueOrFalse')}
                    <br />
                    {t('exams.correctAnswer')} <strong>{answerState}</strong>
                  </p>
                  <p className="page__aside-text page__aside-text--highlight">
                    {t('exams.textAsideQuestion')}{' '}
                    <strong>
                      {form?.values?.published ? t('exams.textAsidePublished') : t('exams.textAsideNotPublished')} {t('exams.and')} {renderExclusivityText(form.values.visibility)}
                    </strong>
                  </p>
                </>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}
