import Modal from 'app/components/Modal';
import { useSession } from 'app/hooks/useSession';
import { t } from 'i18next';

const ImageStep = props => {
  const { values, handleChangeImage, imageLabel, file, defaultImage, showModal, setShowModal, currentModule } = props;

  const { session } = useSession();

  return (
    <>
      <div className="page__content fadeIn u-mb-3">
        <div className="new-questions__header">
          <h2 className="new-questions__title">{values?.title}</h2>
        </div>

        <fieldset className="form__row">
          <legend className="form__label">{t('courses.coverImage')} (1020x252 pixels)</legend>

          <label className="form__custom-file-input">
            <input
              type="file"
              name="image"
              id="image"
              accept=".jpeg, .png, .jpg"
              onChange={handleChangeImage}
              // defaultValue={}
            />
            <span className="form__control">{imageLabel ? imageLabel : t('textsCommon.placeholderUploadImage')}</span>
          </label>
        </fieldset>

        {file || currentModule?.image?.url ? (
          <div className="card__header-img-wrapper">
            <img
              className="card__header-img"
              src={file || currentModule?.image?.url + `?now=${new Date().getTime()}` || defaultImage}
              alt="imagem do curso"
              style={{ height: '160px' }}
            />
          </div>
        ) : null}

        <Modal
          show={showModal}
          onClose={() => setShowModal(false)}
        >
          <div className="show-course-banner u-mb-4">
            <img
              src={file || currentModule?.image?.url + `?now=${new Date().getTime()}` || defaultImage}
              alt="imagem do curso"
              className="show-course-banner__img"
            />
            <p className="show-course-banner__hat">{values.name}</p>
            <div className="show-course-banner__title">{values?.title}</div>
            <div className="show-course-banner__description">{values?.shortDescription}</div>
            <p className="show-course-banner__by">{session.user.name}</p>
          </div>

          <div className="card__badges">
            {values?.selectedTaxonomies.map(item => (
              <span className="badge badge--primary">{item.name}</span>
            ))}
          </div>

          <div className="show-course-section__title">{t('lms.titleAboutModule')}</div>
          <div className="show-course-section__description">{values?.description}</div>

          <div className="show-course-section__title">{t('lms.whatWillLearnTitle')}</div>
          <ul className="show-course-section__list">
            {values?.systemSubjects.map(item => (
              <li>{item.body}</li>
            ))}
          </ul>

          <div className="show-course-section__title">{t('lms.requirementModuleTitle')}</div>
          <div className="card__badges">
            {values?.selectedRequirements.map(item => (
              <span className="badge badge--primary">{item.label}</span>
            ))}
          </div>
        </Modal>
      </div>

      <button
        style={{ alignSelf: 'flex-start' }}
        type="button"
        className="btn btn--link btn--wide u-mb-5"
        onClick={() => setShowModal(true)}
      >
        {t('lms.previewModule')}
      </button>
    </>
  );
};

export default ImageStep;
