import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import Loader from 'app/components/loader';
import { useQuestionBook } from 'app/hooks/useQuestionBook';
import ModalTaxonomies from 'app/components/modalTaxonomies';
import { useTranslation } from 'react-i18next';

function StudentQuestionBookForm(props) {
  const { t } = useTranslation();
  const { setShowModal } = props;
  const client = useClient();
  const { createQuestionBook } = useQuestionBook();
  const [taxonomies, setTaxonomies] = useState([]);
  const [examsOptions, setExamsOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenModalTaxonomies, setIsOpenModalTaxonomies] = useState(false);

  useEffect(() => {
    fetchTaxonomies();
  }, []);

  const fetchTaxonomies = async () => {
    try {
      const { data } = await client.fetch('taxonomies');

      setTaxonomies(data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      toast.error(t('toast.errorGetTags'));
    }
  };

  const schema = Yup.object({
    title: Yup.string().required(),
    interactionTypes: Yup.array().min(1).required(),
    selectedTaxonomies: Yup.array().min(1).required()
  });

  const form = useFormik({
    initialValues: {
      title: '',
      interactionTypes: [],
      selectedTaxonomies: []
    },
    validationSchema: schema,
    onSubmit: values => {
      const taxonomyIds = values.selectedTaxonomies.map(t => t.id);

      createQuestionBook({
        ...values,
        selectedTaxonomies: undefined,
        taxonomyIds: taxonomyIds,
        published: true
      });
      setShowModal(false);
    }
  });

  return (
    <>
      <form
        method="post"
        className="form"
        onSubmit={form.submitForm}
      >
        <div className="form__row">
          <label
            htmlFor="notebookName"
            className="form__label"
          >
            {t('exams.questionBookName')}
          </label>
          <input
            className="form__control"
            id="notebookName"
            name="notebookName"
            type="text"
            value={form.values.title}
            onChange={e => form.setFieldValue('title', e.target.value)}
          />
          {form.errors.title && form.touched.title && form.errors.title}
        </div>

        <fieldset className="form__row">
          <legend className="form__label">{t('textsCommon.matters')}</legend>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {form.values.selectedTaxonomies.length > 0 &&
                form.values.selectedTaxonomies.map(t => (
                  <div className="form__check form__check--inline">
                    <input
                      className="form__check-input"
                      type="checkbox"
                      checked
                      onChange={() => {
                        const newSelectedTaxonomies = form.values.selectedTaxonomies.filter(t2 => t.id !== t2.id);

                        setFieldValue('selectedTaxonomies', newSelectedTaxonomies);
                      }}
                    />
                    <label className="form__check-label">{t.name}</label>
                  </div>
                ))}
              <br />
              <button
                type="button"
                className="btn btn--primary"
                onClick={() => setIsOpenModalTaxonomies(true)}
              >
                {form.values.selectedTaxonomies.length > 0 ? 'Editar matérias' : t('textsCommon.buttonAddMatters')}
              </button>
              <br />
              {form.errors.selectedTaxonomies && form.touched.selectedTaxonomies && form.errors.selectedTaxonomies}
            </>
          )}
        </fieldset>

        <fieldset className="form__row">
          <legend className="form__label">{t('questionBooks.labelMyInteractions')}</legend>

          <div className="form__check form__check--inline">
            <input
              className="form__check-input"
              type="checkbox"
              name="interactionTypes"
              value="neverAnswered"
              id="neverAnswered"
              onChange={form.handleChange}
            />
            <label
              className="form__check-label"
              htmlFor="neverAnswered"
            >
              {t('questionBooks.labelNeverAnswered')}
            </label>
          </div>

          <div className="form__check form__check--inline">
            <input
              className="form__check-input"
              name="interactionTypes"
              type="checkbox"
              value="neverGot"
              id="neverGot"
              onChange={form.handleChange}
            />
            <label
              className="form__check-label"
              htmlFor="neverGot"
            >
              {t('questionBooks.labelNeverGot')}
            </label>
          </div>

          <div className="form__check form__check--inline">
            <input
              className="form__check-input"
              name="interactionTypes"
              type="checkbox"
              value="gotAndWrong"
              id="gotAndWrong"
              onChange={form.handleChange}
            />
            <label
              className="form__check-label"
              htmlFor="gotAndWrong"
            >
              {t('questionBooks.labelGotAndWrong')}
            </label>
          </div>
          <br />
          {form.errors.interactionTypes && form.touched.interactionTypes && form.errors.interactionTypes}
        </fieldset>
        <footer className="notebook__side-footer">
          <button
            type="button"
            className="btn btn--primary btn--full"
            onClick={form.submitForm}
          >
            Criar apostila
          </button>
        </footer>
      </form>
      {taxonomies.length > 0 && isOpenModalTaxonomies && (
        <ModalTaxonomies
          taxonomies={taxonomies}
          selectedTaxonomies={form.values.selectedTaxonomies}
          setFieldValue={form.setFieldValue}
          closeModal={() => setIsOpenModalTaxonomies(false)}
        />
      )}
    </>
  );
}

export default StudentQuestionBookForm;
