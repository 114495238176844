import React, { useState } from 'react';
import TabNavBar from './TabNavBar';
import MaterialTab from './MaterialTab';
import QuestionTab from './QuestionTab';
import ActivityTab from './ActivityTab';
import ForumTab from './ForumTab';
import { useEffect } from 'react';
import { useClient } from 'jsonapi-react';

export function LessonResources(props) {
  const [showListLessons, setShowListLessons] = useState([]);

  const client = useClient();

  const showLesson = async () => {
    const { data, error } = await client.fetch(`learning_system_items?filter[learning_system_content_id]=${props?.disciplineId}`);
    if (error) {
      toast.error('Erro ao carregar aulas');
    } else {
      setShowListLessons(data);
    }
  };

  useEffect(() => {
    showLesson();
  }, []);

  const [activeTabs, setActiveTabs] = useState('forum');
  return (
    <div className="main-screen">
      <>
        <main className="main-content main-content--block">
          <TabNavBar
            activeTabs={activeTabs}
            setActiveTabs={setActiveTabs}
          />

          {activeTabs === 'material' && (
            <MaterialTab
              showListLessons={showListLessons}
              lessonId={props.lessonId}
            />
          )}
          {activeTabs === 'question' && (
            <QuestionTab
              showListLessons={showListLessons}
              lessonId={props.lessonId}
            />
          )}
          {activeTabs === 'activity' && (
            <ActivityTab
              showListLessons={showListLessons}
              lessonId={props.lessonId}
            />
          )}
          {activeTabs === 'forum' && (
            <ForumTab
              showListLessons={showListLessons}
              lessonId={props.lessonId}
            />
          )}
        </main>
      </>
    </div>
  );
}
