import React from 'react';
import { setLocale } from 'yup';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactDOMServer from 'react-dom';
import { Routes } from './app/routes';
import { Providers } from './app/providers';
import HttpsRedirect from 'react-https-redirect';

// ReactDOMServer.renderToString(<Helmet />);
// const helmet = Helmet.renderStatic();
require('./app/stylesheets/application.sass');
const appName = window.env.REACT_APP_NAME;
const basePath = window.env.REACT_APP_BASE_PATH;

let pTitle = '';
let pDescription = '';
let pFavicon = '';
let pOgimage = '';

switch (appName) {
  case 'demo':
    pTitle = 'Estudologia Demo';
    pDescription = 'A Estudologia é uma plataforma online que ajuda estudantes a aprenderem mais e melhor.';
    pFavicon = `${basePath}/themes/demo/favicon/favicon-32x32.png`;
    pOgimage = '/themes/demo/og-image.png';
    break;
  case 'etipi':
    pTitle = 'ETIPI';
    pDescription = '';
    pFavicon = `${basePath}/themes/etipi/favicon/favicon-32x32.png`;
    pOgimage = '/themes/demo/og-image.png';
    break;
  case 'siape':
    pTitle = 'Siape';
    pDescription = 'Nascemos para levar educação de qualidade para quem precisa, onde quer que seja.';
    pFavicon = `${basePath}/themes/siape/favicon/favicon-32x32.png`;
    pOgimage = '/themes/siape/og-image.png';
    break;
  case 'iesb':
    pTitle = 'Estudologia IESB';
    pDescription = 'A Estudologia é uma plataforma online que ajuda estudantes a aprenderem mais e melhor.';
    pFavicon = `${basePath}/themes/iesb/favicon/favicon-32x32.png`;
    pOgimage = '/themes/iesb/og-image.png';
    break;
  case 'oab':
    pTitle = 'Estudologia OAB';
    pDescription = 'A Estudologia é uma plataforma online que ajuda estudantes a aprenderem mais e melhor.';
    pFavicon = `${basePath}/themes/oab/favicon/favicon-32x32.png`;
    pOgimage = '/themes/oab/og-image.png';
    break;
  case 'quartosecretariado':
    pTitle = '4º Secretariado';
    pDescription = 'Somos uma iniciativa que busca pluralizar o acesso aos estudos para a carreira diplomática.';
    pFavicon = `${basePath}/themes/quartosecretariado/favicon/favicon-32x32.png`;
    pOgimage = '/themes/quartosecretariado/og-image.jpg';
    break;
  case 'retina':
    pTitle = 'Retina • Cultura de inovação educacional';
    pDescription = 'Somos uma iniciativa que busca pluralizar o acesso aos estudos para a carreira diplomática.';
    pFavicon = `${basePath}/themes/retina/favicon/favicon-32x32.png`;
    pOgimage = '/themes/retina/og-image.jpg';
    break;
  case 'fic':
    pTitle = 'FIC • Desenvolvimento de games e empreendedorismo';
    pDescription = 'Somos uma iniciativa que busca pluralizar o acesso aos estudos para a carreira de games e empreendedorismo.';
    pFavicon = `${basePath}/themes/fic/favicon/favicon-32x32.png`;
    pOgimage = '/themes/fic/og-image.jpg';
    break;
  case 'brasilstartups':
    pTitle = 'Brasil Startups';
    pDescription = 'SEJA UM PROTAGONISTA NO ECOSSISTEMA DE INOVAÇÃO.';
    pFavicon = `${basePath}/themes/brasil-startups/favicon/favicon-32x32.png`;
    pOgimage = '/themes/brasil-startups/og-image.jpg';
    break;

  case 'canaleducacao':
    pTitle = 'Canal Educação';
    pDescription = 'Nascemos para levar educação de qualidade para quem precisa, onde quer que seja.';
    pFavicon = `${basePath}/themes/canaleducacao/favicon/favicon-32x32.png`;
    pOgimage = '/themes/canaleducacao/og-image.jpg';
    break;
  case 'egepi':
    pTitle = 'Escola do Governo do Piauí';
    pDescription = 'Nascemos para levar educação de qualidade para quem precisa, onde quer que seja.';
    pFavicon = `${basePath}/themes/egepi/favicon/favicon-32x32.png`;
    pOgimage = '/themes/egepi/og-image.jpg';
    break;

  case 'vegalabs':
    pTitle = 'Vega Labs';
    pDescription = 'Nascemos para levar educação de qualidade para quem precisa, onde quer que seja.';
    pFavicon = `${basePath}/themes/vegalabs/favicon/favicon-32x32.png`;
    pOgimage = '/themes/vegalabs/og-image.jpg';
    break;

  case 'inkluziva':
    pTitle = 'Inkluziva';
    pDescription = 'Nascemos para levar educação de qualidade para quem precisa, onde quer que seja.';
    pFavicon = `${basePath}/themes/inkluziva/favicon/favicon-32x32.png`;
    pOgimage = '/themes/inkluziva/og-image.jpg';
    break;
  case 'seducpi':
    pTitle = 'Seduc PI';
    pDescription = 'Nascemos para levar educação de qualidade para quem precisa, onde quer que seja.';
    pFavicon = `${basePath}/themes/seducpi/favicon/favicon-32x32.png`;
    pOgimage = '/themes/seducpi/og-image.jpg';
    break;
  case 'maiseducacao':
    pTitle = 'Mais Formação';
    pDescription = 'Nascemos para levar educação de qualidade para quem precisa, onde quer que seja.';
    pFavicon = `${basePath}/themes/maisformacao/favicon/favicon-32x32.png`;
    pOgimage = '/themes/maiseducacao/og-image.jpg';
    break;
  case 'tjpara':
    pTitle = 'Tribunal de Justiça do Estado do Pará';
    pDescription = 'Tribunal de Justiça do Estado do Pará.';
    pFavicon = `${basePath}/themes/tjpara/favicon/favicon-32x32.png`;
    pOgimage = '/themes/tjpara/og-image.jpg';
    break;
  case 'powerforgirls':
    pTitle = 'Power 4 Girls • Empower to lead!';
    pDescription = 'Somos uma iniciativa que busca pluralizar o acesso aos estudos para a carreira diplomática.';
    pFavicon = `${basePath}/themes/powerforgirls/favicon/favicon-32x32.png`;
    pOgimage = '/themes/powerforgirls/og-image.jpg';
    break;
  case 'caboverde':
    pTitle = 'Cabo Verde';
    pDescription = 'Governo de Cabo Verde';
    pFavicon = `${basePath}/themes/caboverde/favicon/favicon-32x32.png`;
    pOgimage = '/themes/caboverde/og-image.svg';
    break;
  default:
    throw new Error('No REACT_APP_NAME set.');
}

setLocale({
  mixed: {
    required: 'Requerido'
  },
  array: {
    min: 'Escolha pelo menos ${min}'
  }
});

const App = () => (
  <HelmetProvider>
    <Providers>
      <Helmet>
        <title>{pTitle}</title>
        <body className={appName} />
        <meta charset="utf-8" />
        <meta
          name="description"
          content={pDescription}
        />
        <meta
          property="og:image"
          content={pOgimage}
        />
        <link
          rel="icon"
          href={pFavicon}
          type="image/png"
        />
      </Helmet>
      <Routes />
    </Providers>
  </HelmetProvider>
);

export default App;
