import React, { useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';

import './globals.css';
import './styleguide.css';
import './style.css';

import check1 from './img/check-1.svg';
import iconDefaultUsers1 from './img/icon-default-user-1.svg';
import iconDefaultTrophy8 from './img/icon-default-trophy-8.svg';
import iconDefaultTrophy9 from './img/icon-default-trophy-9.svg';
import iconDefaultTrophy10 from './img/icon-default-trophy-10.svg';
import iconDefaultTrophy28 from './img/icon-default-trophy-28.svg';
import medal from './img/medal.svg';
import { FiChevronLeft } from 'react-icons/fi';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import { useFormik } from 'formik';
import Loader from 'app/components/loader';

export default function SeducPiGeral() {
  const client = useClient();
  const [data, setData] = useState([]);
  const [fristItem, setFristItem] = useState(true);
  const [lastItem, setLastItem] = useState(true);
  const [openQuestionBooks, setOpenQuestionBooks] = useState([]);
  const [loading, setLoading] = useState(false);
  const basePath = window.env.REACT_APP_BASE_PATH;

  const filter = useFormik({
    initialValues: {
      openQuestionBook: null
    }
  });

  const getOpenQuestionBooks = async () => {
    const { data, error } = await client.fetch(`/question_books?filter[visibility]=open`);
    if (error) {
      toast.error('Falha ao carregar simulados');
    } else {
      setOpenQuestionBooks(data);
      filter.setFieldValue('openQuestionBook', data[0]?.id);
    }
  };

  const getData = async () => {
    setLoading(true);
    let url = `reports/user_registrations`;
    if (filter.values.openQuestionBook) {
      url = `reports/user_registrations?question_book_id=${filter.values.openQuestionBook}`;
    }
    const { data, error } = await client.fetch(url);

    if (error) {
      toast.error('Falha ao carregar dados');
    } else {
      setData(data);
      setFristItem(data.items[0]);
      setLastItem(data.items[data.items.length - 1]);
    }
    setLoading(false);
  };

  const fetchData = async () => {
    setLoading(true);
    await Promise.all([getData(), getOpenQuestionBooks()]);
    setLoading(false);
  };

  const getTrophyIcon = index => {
    switch (index) {
      case 0:
        return iconDefaultTrophy8;
      case 1:
        return iconDefaultTrophy9;
      case 2:
        return iconDefaultTrophy10;
      default:
        return iconDefaultTrophy28;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    getData();
  }, [filter.values.openQuestionBook]);

  useEffect(() => {}, [openQuestionBooks]);

  return (
    <div className="main-content">
      <div className="page">
        <div className="page__col">
          <div className="relatrio-geral-SEDUC">
            <div className="filter-bar">
              <div className="filter-bar__inner">
                <button
                  onClick={() => navigate(`${basePath}/metricas/institucional`)}
                  className="filter-bar__back"
                  style={{ alignSelf: 'center' }}
                >
                  <span className="card__icon-wrapper">
                    <FiChevronLeft className="card__icon" />
                  </span>

                  <span className="filter-bar__back-container">
                    <span className="text-wrapper">Simulados e notas</span>
                  </span>
                </button>
              </div>
            </div>
            <div style={{ marginBottom: 20 }}>
              <h2
                className="text-wrapper"
                title="Relatório Geral"
              >
                Relatório Geral
              </h2>
              <p className="text-wrapper-3">Acompanhamento de cadastros e média</p>

              <label className="filter-bar__label">Selecine uma avaliação</label>
              <select
                onChange={e => filter.setFieldValue('openQuestionBook', e.target.value)}
                className={`filter-bar__select`}
              >
                {openQuestionBooks?.map(item => (
                  <option
                    key={item.id}
                    value={item.id}
                  >
                    {item.title}
                  </option>
                ))}
              </select>

              {/* <FilterSelectionBox
            label="Selecine uma avaliação"
            onChange={e => filter.setFieldValue('openQuestionBook', e.target.value)}
            value={filter.values.openQuestionBook}
            options={openQuestionBooks.map(item => ({
              ...item,
              name: item.title
            }))}
          /> */}
            </div>

            <div className="frame-2">
              <div className="frame-3">
                <div className="KPI">
                  <div className="frame-4">
                    <div className="tipo-de-notificaes">
                      <img
                        className="check"
                        src={check1}
                      />
                    </div>
                    <div className="text-wrapper-3">Total de estudantes cadastrados</div>
                  </div>
                  <div className="text-wrapper-4">{data.totalStudentsCount || 0}</div>
                </div>
                <div className="KPI">
                  <div className="frame-5">
                    <div className="tipo-de-notificaes">
                      <img
                        className="icon-default-users"
                        src={iconDefaultUsers1}
                      />
                    </div>
                    <div className="text-wrapper-3">Maior número de cadastros</div>
                  </div>
                  <div className="text-wrapper-4">
                     {fristItem?.name} - {fristItem?.studentsCount ? fristItem?.studentsCount  : "0"} cadastros
                  </div>
                </div>
                <div className="KPI">
                  <div className="frame-5">
                    <div className="tipo-de-notificaes">
                      <img
                        className="icon-default-user"
                        src={iconDefaultUsers1}
                      />
                    </div>
                    <div className="text-wrapper-3">Menor número de cadastros</div>
                  </div>
                  <div className="text-wrapper-4">
                    {fristItem?.name} - {fristItem?.studentsCount ? fristItem?.studentsCount  : "0"} cadastros
                  </div>
                </div>

                <div className="KPI">
                  <div className="frame-5">
                    <div className="tipo-de-notificaes">
                      <img
                        className="icon-default-user"
                        src={medal}
                      />
                    </div>
                    <div className="text-wrapper-3">Média geral do último simulado</div>
                  </div>
                  <div className="text-wrapper-4">{data?.totalAverage?.toFixed(2) || 0}</div>
                </div>
              </div>
              <div className="frame-6">
                <div className="text-wrapper-5">Ranking das Secretarias</div>
                <div className="frame-7">
                  <div className="group-2">
                    <div className="escola-3">Posição</div>
                    <div className="escola">Secretaria</div>
                    <div className="escola-2">Nota média</div>
                    <div className="p">Cadastros</div>
                    <div className="p">% do total de cadastros</div>
                  </div>
                  <div className="frame-8">
                    {loading && <Loader />}
                    {!loading &&
                      (data.items || []).map((item, index) => (
                        <div
                          className="nome u-cursor-pointer"
                          onClick={() => navigate(`${basePath}/relatorio/seduc-pi/geral/gre/${item.id}/${filter.values.openQuestionBook}`)}
                        >
                          <div className="frame-9">
                            <div className="frame-10">
                              <div className="ordem">{index + 1}</div>
                              <div className="nome-2 u-text-truncate">{item.name}</div>
                            </div>
                            <img
                              className="icon-default-trophy"
                              src={getTrophyIcon(index)}
                            />
                            <div style={{ paddingRight: 80, paddingLeft: 80 }}>{item.average.toFixed(2)}</div>
                            <div className="escola-4">{item.studentsCount}</div>
                            <div className="nota">{item.studentsPercentage.toString().replace('.', ',')}%</div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
