import React from 'react';
import { Link } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { usePlatformAbilities } from 'app/hooks/usePlatformAbilities';

function TabNavBar(props) {
  const { t } = useTranslation();
  const { selectedTab } = props;
  const { abilities } = usePlatformAbilities();
  const basePath = window.env.REACT_APP_BASE_PATH;

  return (
    <div className="tab">
      {abilities?.['avaliacoes-simulados'] && (
        <Link
          className={selectedTab === 'simulados' ? 'tab__link active' : 'tab__link'}
          to={`${basePath}/simulados`}
        >
          {t('textsCommon.simulations')}
        </Link>
      )}

      {abilities?.['avaliacoes-provas'] && (
        <Link
          className={selectedTab === 'avaliacoes' ? 'tab__link active' : 'tab__link'}
          to={`${basePath}/avaliacoes`}
        >
          {t('exams.toggleExams')}
        </Link>
      )}

      {abilities?.avaliacoes && (
        <Link
          className={selectedTab === 'resultado-das-avaliacoes' ? 'tab__link active' : 'tab__link'}
          to={`${basePath}/resultado-das-avaliacoes`}
        >
          {t('exams.toggleMyResults')}
        </Link>
      )}
    </div>
  );
}

export default TabNavBar;
