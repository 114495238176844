import React, { useEffect, useState } from 'react';
import { Router } from '@reach/router';
import { ComplementaryActivity, ActivitySingle, ActivityEvaluationView, ActivityEvaluationArchive, ActivityEvaluation, ActivityData, ViewActivity } from 'app/screens/ComplementaryActivity';
import Quiz, { QuizData, QuizDataProposal, QuizDataOpenAnswers, QuizCreate } from 'app/screens/Quiz';
import GeneralMetrics from 'app/screens/GeneralMetrics';
import { LessonsMetrics, EngagementMetrics, ViewClassroom, MetricsEmbed, ShowEmbed, LiveClassesReport, AccessReport, RegistrationReport, CourseProgressReport } from 'app/screens/Metrics';
import Participants, { AdministrativeProfiles, ManageProfile, Notifications, NotificationExtract } from 'app/screens/Participants';
import ManageModule from 'app/screens/LmsStudent/ManageModule';
import Dashboard, { ViewEvent } from 'app/screens/Dashboard';
import Exams, { ExamsResolution, AboutExam, StudentResponse, OfflineExam, PrintedExam } from '../screens/Exams';
import AboutSimulations from 'app/components/ProgramOfStudies/AboutSimulations';
import CreateExam from '../screens/Exams/CreateExam';
import ManageExam from '../screens/Exams/ManageExam';
import PrivateRoute from './components/PrivateRoute';
import Terms from '../screens/Terms';
import Login from '../screens/Login';
import LoginCallback from '../screens/LoginCallback';
import OpenRegister from '../screens/OpenRegister';
import OpenResults from '../screens/OpenResults';
import OpenAnswerBook from '../screens/OpenAnswerBook';
import Register from '../screens/Register';
import ForgorPassword from '../screens/ForgorPassword';
import MyTasks, { ViewTask } from '../screens/MyTasks';
// import Dashboard from 'app/screens/Admin/Dashboard';
import Feed from '../screens/Feed';
// Course links
import Course, { CourseMonitoring, CourseAbout, CourseRecommended, CourseCertificate, ModuleAbout } from 'app/screens/Course';
import ManageCourse from 'app/screens/Admin/Course/ManageCourse';
import CourseAboutAdmin from 'app/screens/Admin/Course/CourseAboutAdmin';
import CourseAdmin from 'app/screens/Admin/Course';
// LMS links
import LmsStudent, { CreateModule, ViewModule, Lesson, LessonResources, ModuleCertificate } from '../screens/LmsStudent/index';
import CreateLesson from '../screens/LmsStudent/CreateLesson';
import ShowCourse from '../screens/LmsStudent/ShowCourse';
import CourseClassroom from '../screens/LmsStudent/CourseClassroom';

import Profile from '../screens/Profile';
import { ShowUser } from '../screens/Users/ShowUser';
import Post from '../screens/Post';
import Questions from '../screens/Questions';
import QuestionsSimulated from '../screens/QuestionsSimulated';
import QuestionsFinish from '../screens/QuestionsFinish';
import DashboardTeacher from '../screens/DashboardTeacher';
import DashboardAdmin from '../screens/Admin';
import Classroom, { ManageParticipants, CreateOrUpdateParticipant } from '../screens/Admin/Classroom';
// Classrooms links
import Classrooms, { ManageClassroomParticipants, ClassroomInvitations, ViewInvitations, ClassroomImports, ViewImports } from 'app/screens/Admin/Classrooms';
import { Metrics1, MetricsProfile } from '../screens/Metrics/index1';
import StudentMaterials, { ViewMaterial } from '../screens/Materials';
import Journey from '../screens/Journey';
import OpenOnboarding from '../screens/OpenOnboarding';
import Notfound from '../screens/Notfound';
import { Taxonomies } from 'app/screens/ProgramOfStudies/Taxonomies';
import { QuestionsDatabase } from 'app/screens/ProgramOfStudies/QuestionsDatabase';
import { UpdatePublicQuestions } from 'app/screens/UpdatePublicQuestions';
import { UpdateQuestionBook } from 'app/screens/QuestionBooks/UpdateQuestionBook';
import Projects, { CreateProject, GroupProjects, IndividualProjects, PersonalizedProjects, ProjectsSingle, ShowProject, ViewGroupProject, ViewIndividualProject } from 'app/screens/Projects';
import TaskManagment, { ManageExcessTasks, ManageExcessStudentTasks } from 'app/screens/TaskManagment';
import StudyPlan, { StudyBlock, ViewStudyPlan } from 'app/screens/StudyPlan';
import HelpCenter, { HelpViewVideo } from 'app/screens/HelpCenter';
import { default as AdmQuestions, CreateQuestion, CommentQuestion } from '../screens/Admin/Questions';
import QuestionBooks from 'app/screens/UserQuestionBooks';
import PageWithLayouts from 'app/layouts/PageWithLayouts';
import Example from 'app/components/Example';

import ExamMetrics from '../screens/ExamMetrics/index';
import ExamMetricsList from '../screens/Exams/ExamMetricsList';
import { CreateCourse } from 'app/screens/Course/CreateCourse';
import { CreateActivity } from 'app/screens/Admin/Course/ManageCourse/CreateActivity';
import CreateAccount from 'app/screens/CreateAccount';
import CreateQuestionBook from 'app/screens/ProgramOfStudies/CreateQuestionBook';
import CreateMockQuestionBook from 'app/screens/ProgramOfStudies/CreateMockQuestionBook';
import Goals from 'app/screens/ProgramOfStudies/Goals';
import { usePlatformAbilities } from 'app/hooks/usePlatformAbilities';
import Auditorium, { ViewAuditorium, CreateAuditorium } from 'app/screens/Auditorium';
import SeducPi, { SeducPiGeral, SeducPiGRE } from 'app/screens/Reports/SeducPi';
import Privacy from 'app/screens/Privacy';
import LiveClasses, { ViewLiveClass } from 'app/screens/LiveClasses';
import Essay, { CreateEssay, EssayMonitoring, EssayCorrection, EssayConsult, EssayAbout, EssaySend, EssayPrint } from 'app/screens/Essay';
import LessonPlan from 'app/screens/LessonPlan';
import WeekChallenge from 'app/screens/WeekChallenge';
import CheckIn from 'app/screens/CheckIn';
import Logout from 'app/screens/Logout';
import Institutions from 'app/screens/Participants/Institutions';
import InstitutionFlow from 'app/screens/Participants/Institutions/InstitutionFlow';

export const Routes = () => {
  const [currentPath, setCurrentPath] = useState();
  const [layoutType, setLayoutType] = useState(); // TODO: loginLayout
  const { abilities } = usePlatformAbilities();
  const sso = window.env.REACT_APP_SSO_API_URL;
  const ssoVobys = window.env.REACT_APP_VOBYS_SSO_API_URL;
  const basePath = window.env.REACT_APP_BASE_PATH;

  useEffect(() => {
    updateLayoutType();
  }, [currentPath]);

  const updateLayoutType = () => {
    let newLayoutType = '';
    switch (currentPath) {
      case '/':
        newLayoutType = localStorage.getItem('session') === null || localStorage.getItem('session') === 'null' ? 'adminLayout' : 'mainLayout';
        break;
      case '/projetos/grupos':
      case '/feed':
      case '/projetos/grupos/:id':
      case '/avaliacoes/:selectedTab':
      case '/projetos/individuais':
      case '/projetos/personalizados':
      case '/ementa/questoes-publicas':
      case '/avaliacoes/:selectedTab/impressao/:id':
      case '/atividade-complementar/avaliar-atividades/:id':
      case '/atividade-complementar/atividade/:id':
      case '/atividade-complementar':
      case '/provas/:id':
      case '/simulados/:id':
      case '/slide':
      case '/questions/finish':
      case '/ajuda/:id':
      case '/ajuda':
      case '/auditorio/:auditoriumId':
      case '/relatorio/aula-ao-vivo':
      case '/relatorio/seduc-pi/geral/gre/:greId/:questionBookId':
      case '/relatorio/seduc-pi/geral':
      case '/curso/admin':
      case '/provas/banco-de-questoes/comentar/:questionId':
      case '/redacao':
      case '/ementa/materias':
      case '/redacao/acompanhamento/:essayId':
      case '/gestao-de-tarefa/:selectedTab':
      case '/turmas':
      case '/turmas/convites':
      case '/turmas/convites/:inviteId':
      case '/turmas/importar':
      case '/turmas/importar/:importId':
      case '/turmas/gerenciar/:classroomId':
      case '/questionario/criar/:representation/:id':
      case '/questionario':
      case '/aulas-ao-vivo':
      case '/auditorio':
      case '/plano-de-aulas/:selectedTab':
      case '/profile':
      case '/participantes':
      case '/course/create/participant':
      case '/participantes/perfis-administrativos':
      case '/participantes/notificacoes':
      case '/participantes/notificacoes/extrato':
      case '/participantes/instituicoes':
      case '/usuario/:selectedTab/:id':
      case '/participantes/perfis-administrativos/:id':
      case '/metricas/:selectedTab':
      case '/metricas/:selectedTab/:reportId':
      case '/curso/admin/:id':
      case '/curso/:id':
      case '/show-course/:id/classroom':
      case '/show-course/:id/classroom/:lessonId':
      case '/curso/:courseId/modulo/:moduleId/:selectedTab':
      case '/course/edit/participant/:id':
      case '/lista-resultado-das-provas/:id':
      case '/posts/:slug':
      case '/curso':
      case '/curso/certificado':
      case '/minhas-tarefas':
      case '/simulados':
      case '/simulados/sobre/:id':
      case '/avaliacoes/sobre/:id':
      case '/aulas-ao-vivo/:liveClassId':
      case '/avaliacoes':
      case '/resultado-das-avaliacoes':
      case '/questions/:selectedTab':
      case '/projetos':
      case '/redacao/correcao/:studentId/:essayId':
      case '/redacao/consulta/:essayId/:studentId':
      case '/redacao/sobre/:essayId':
      case '/plano-de-estudos':
      case '/plano-de-estudos/:planId':
      case '/plano-de-estudos/:planId/bloco-de-estudos/:blockId':
      case '/curso/acompanhamento/:id':
      case '/questions/:selectedTab/:id':
      case '/redacao/impressao/:essayId':
      case '/redacao/enviar/:essayId':
      case '/projetos/grupos/:groupId/:projectId/sobre':
      case '/projetos/:projectId/sobre':
      case '/relatorio/acesso':
      case '/relatorio/cadastro':
      case '/desafio-da-semana/:selectedTab':
      case '/evento/:eventId':
      case '/atividade-complementar/avaliacao/:activityId/:id':
        newLayoutType = 'mainLayout';
        break;
      case '/relatorio/seduc-pi':
      case '/curso/admin/gerenciar/:courseId/:moduleId/:selectedTab/:disciplineId':
      case '/simulados/criar/:step':
      case '/simulados/criar/:step/:id':
      case '/questoes/criar/:step/:id':
      case '/questoes/criar/:step':
      case '/lms/criar/modulo/curso':
      case '/lms/criar/modulo/:step/:courseId':
      case '/lms/criar/modulo/:step/:id/:courseId':
      case '/lms/criar/modulo/:id':
      case '/lms/gerenciar/modulos':
      case '/provas/criar/:step/:id':
      case '/provas/criar/:step':
      case '/apostilas/criar/:step':
      case '/apostilas/criar/:step/:id':
      case '/lms/gerenciar/modulo/:id':
      case '/lms/gerenciar/modulo/:moduleId/:disciplineId':
      case '/lms/criar/aula/:contentId/:step/:courseId':
      case '/lms/criar/aula/:contentId/:step/:lessonId/:courseId':
      case '/atividades/criar/:step/:moduleId/:courseId':
      case '/atividades/criar/:step/:moduleId/:courseId/:activityId':
      case '/projetos/criar/passo/:step':
      case '/projetos/criar/passo/:step/:id':
      case '/apostilas/gerenciar/:id':
      case '/apostilas/gerenciar/:id/:taxonomyId':
      case '/apostilas/gerenciar/:questionbookId':
      case '/apostilas/gerenciar/:questionbookId/:taxonomyId':
      case '/apostilas/gerenciar':
      case '/simulados/gerenciar/:id':
      case '/simulados/gerenciar/:id/:taxonomyId':
      case '/simulados/gerenciar/:questionbookId':
      case '/simulados/gerenciar/:questionbookId/:taxonomyId':
      case '/simulados/gerenciar':
      case '/provas/gerenciar':
      case '/provas/gerenciar/:questionbookId':
      case '/provas/gerenciar/:questionbookId/:taxonomyId':
      case '/projetos/:projectId':
      case '/redacao/impressao/:essayId':
      case '/redacao/enviar/:essayId':
        newLayoutType = 'adminLayout'; // TODO: managementLayout
        break;
      case '/login':
      case '/politica-de-privacidade':
      case '/termos-de-uso':
        newLayoutType = 'loginLayout'; // TODO: loginLayout
        break;
      default:
      // newLayoutType = localStorage.getItem('session') === null || localStorage.getItem('session') === 'null' ? 'adminLayout' : 'mainLayout';
    }
    setLayoutType(newLayoutType);
  };

  const RedirectToSupport = () => {
    React.useEffect(() => {
      const platformSupportUrl = window.env.REACT_APP_SUPPORT_LINK;
      window.location.href = `${platformSupportUrl}/requests/new`;
    }, []);
    return null;
  };

  return (
    <PageWithLayouts layoutType={layoutType}>
      <Router basepath={`${basePath}`}>
        <RedirectToSupport path="/ajuda" />
        <OpenRegister //desuso
          exact
          path="/inscricoes-open"
        />
        <Terms
          setCurrentPath={setCurrentPath}
          exact
          path="/termos-de-uso"
        />
        <Privacy
          setCurrentPath={setCurrentPath}
          exact
          path="/politica-de-privacidade"
        />
        <Login
          exact
          path="/login"
        />
        <Login
          exact
          path="/login/:link"
        />
        {(sso || ssoVobys) && (
          <LoginCallback
            exact
            path="/login-callback"
          />
        )}
        <Logout
          exact
          path="/logout"
        />
        <Register path="/register" />
        <ForgorPassword path="/esqueci-a-senha" />
        <CreateAccount path="/criar-uma-conta" />
        <CreateAccount path="/criar-uma-conta/:link" />
        <CheckIn path="/check-in" />

        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/"
          as={Dashboard}
          layoutType="mainLayout"
          enabled={abilities?.dashboard}
        />

        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/evento/:eventId"
          as={ViewEvent}
          layoutType="mainLayout"
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/open-results/:id"
          as={OpenResults}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/answer-book/:id"
          as={OpenAnswerBook}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/admin-dashboard" //desuso
          as={Dashboard}
          layoutType="mainLayout"
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/minhas-tarefas/:taskType/:taskId"
          as={ViewTask}
          layoutType="mainLayout"
          enabled={abilities?.tarefas}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/minhas-tarefas"
          as={MyTasks}
          layoutType="mainLayout"
          enabled={abilities?.tarefas}
        />

        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/feed"
          layoutType="mainLayout"
          as={Feed}
          roleName="Visualizar postagens e comentários"
          roleGroup="Feed"
          enabled={abilities?.feed}
        />

        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/gestao-de-tarefa/:selectedTab"
          layoutType="mainLayout"
          as={TaskManagment}
          roleName="Visualizar Tarefas"
          roleGroup="Tarefas"
          enabled={abilities?.tarefas}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/gestao-de-tarefa/:selectedTab/tarefas/:studentId"
          layoutType="mainLayout"
          as={ManageExcessStudentTasks}
          enabled={abilities?.tarefas}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/gestao-de-tarefa/:selectedTab/tarefas"
          layoutType="mainLayout"
          as={ManageExcessTasks}
          enabled={abilities?.tarefas}
        />
        {/* Course routes */}

        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/curso/recomendado"
          as={CourseRecommended}
          layoutType="mainLayout"
          enabled={abilities?.cursos}
        />

        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/curso/certificado"
          as={CourseCertificate}
          enabled={abilities?.cursos}
        />

        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/curso/acompanhamento/:id"
          as={CourseMonitoring}
          layoutType="mainLayout"
          enabled={abilities?.cursos}
        />

        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/curso/:courseId/modulo/:moduleId/:selectedTab"
          as={ModuleAbout}
          layoutType="mainLayout"
          enabled={abilities?.cursos}
        />

        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/curso/:id"
          as={CourseAbout}
          layoutType="mainLayout"
          enabled={abilities?.cursos}
        />

        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/curso"
          as={Course}
          layoutType="mainLayout"
          enabled={abilities?.cursos}
        />

        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/curso/admin/:id"
          as={CourseAboutAdmin}
          layoutType="mainLayout"
          enabled={abilities?.cursos}
        />

        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/curso/admin/gerenciar/:courseId/:moduleId/:selectedTab/:disciplineId"
          as={ManageCourse}
          enabled={abilities?.cursos}
        />

        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/curso/admin"
          as={CourseAdmin}
          layoutType="mainLayout"
          enabled={abilities?.cursos}
        />

        {/* Classrooms routes */}
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/turmas/importar/:importId"
          as={ViewImports}
          enabled={abilities?.cursos}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/turmas/importar"
          as={ClassroomImports}
          enabled={abilities?.cursos}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/turmas/convites/:inviteId"
          as={ViewInvitations}
          enabled={abilities?.cursos}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/turmas/convites"
          as={ClassroomInvitations}
          enabled={abilities?.cursos}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/turmas/gerenciar/:classroomId"
          as={ManageClassroomParticipants}
          enabled={abilities?.cursos}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/turmas"
          as={Classrooms}
          layoutType="mainLayout"
          enabled={abilities?.cursos}
        />
        {/* Lms routes */}
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/lms/gerenciar/modulo/:id"
          as={ManageModule}
          roleName="Editar módulos, disciplinas e aulas"
          roleGroup="Cursos, módulos e aulas"
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/lms/gerenciar/modulo/:moduleId/:disciplineId"
          as={ManageModule}
          roleName="Editar módulos, disciplinas e aulas"
          roleGroup="Cursos, módulos e aulas"
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/lms/gerenciar/modulos"
          as={ManageModule}
          roleName="Editar módulos, disciplinas e aulas"
          roleGroup="Cursos, módulos e aulas"
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/lms/criar/aula/:contentId/:step/:courseId"
          as={CreateLesson}
          roleName="Criar módulos, disciplinas e aulas"
          roleGroup="Cursos, módulos e aulas"
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/lms/criar/aula/:contentId/:step/:courseId"
          as={CreateLesson}
          roleName="Criar módulos, disciplinas e aulas"
          roleGroup="Cursos, módulos e aulas"
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          roleName="Editar módulos, disciplinas e aulas"
          roleGroup="Cursos, módulos e aulas"
          path="/lms/criar/aula/:contentId/:step/:lessonId/:courseId"
          as={CreateLesson}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/lms/criar/modulo/:step/:courseId"
          as={CreateModule}
          roleName="Criar módulos, disciplinas e aulas"
          roleGroup="Cursos, módulos e aulas"
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/lms/criar/modulo/:step/:id/:courseId"
          as={CreateModule}
          roleName="Editar módulos, disciplinas e aulas"
          roleGroup="Cursos, módulos e aulas"
        />
        {/* <PrivateRoute
        setCurrentPath={setCurrentPath} path="/lms/criar/modulo/:id" as={CreateModule} /> */}
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/lms/visualizar/modulo/:id"
          as={ViewModule}
          roleName="Visualizar lista de módulos e seus conteúdos"
          roleGroup="Cursos, módulos e aulas"
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/lms/visualizar/modulo/:id/disciplina/:disciplineId"
          as={Lesson}
          roleName="Visualizar lista de módulos e seus conteúdos"
          roleGroup="Cursos, módulos e aulas"
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/lms/visualizar/modulo/:id/disciplina/:disciplineId/aula/:lessonId"
          as={LessonResources}
          roleName="Visualizar lista de módulos e seus conteúdos"
          roleGroup="Cursos, módulos e aulas"
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/lms"
          as={LmsStudent}
          roleName="Visualizar lista de módulos e seus conteúdos"
          roleGroup="Cursos, módulos e aulas"
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/show-course/:id"
          as={ShowCourse}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/show-course/:id/classroom"
          as={CourseClassroom}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/show-course/:id/classroom/:lessonId"
          as={CourseClassroom}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/questoes/criar/:step/:id"
          as={CreateQuestion}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/questoes/criar/:step"
          as={CreateQuestion}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/atividades/criar/:step/:moduleId/:courseId"
          as={CreateActivity}
          enabled={abilities?.['cursos-ativ-complementar']}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/atividades/criar/:step/:moduleId/:courseId/:activityId"
          as={CreateActivity}
          enabled={abilities?.['cursos-ativ-complementar']}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/plano-de-estudos"
          as={StudyPlan}
          enabled={abilities?.['plano-de-estudo']}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/questions/finish"
          as={QuestionsFinish}
          enabled={abilities?.avaliacoes}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/open/:id"
          as={QuestionsSimulated}
          enabled={abilities?.avaliacoes}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/questions/answer"
          as={Questions}
          enabled={abilities?.['avaliacoes-apostilas']}
        />

        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/questions/:selectedTab"
          as={QuestionBooks}
          enabled={abilities?.['avaliacoes-apostilas']}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/questions/:selectedTab/:id"
          as={Questions}
          enabled={abilities?.['avaliacoes-apostilas']}
        />
        {/* <PrivateRoute
        setCurrentPath={setCurrentPath} path="/dashboard" as={DashboardTeacher} /> */}

        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/materiais/:materialId/:moduleId"
          layoutType="mainLayout"
          as={ViewMaterial}
          enabled={abilities?.['cursos-materiais']}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/materiais"
          layoutType="mainLayout"
          as={StudentMaterials}
          enabled={abilities?.['cursos-materiais']}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/metricas/institucional/engajamento/turma/:classroomId"
          as={ViewClassroom}
          roleName="Visualizar dados e métricas"
          layoutType="mainLayout"
          roleGroup={['Questionário de Percepção', 'Cursos, módulos e aulas', 'Projetos', 'Participantes e perfis administrativos', 'Questões e apostilas', 'Avaliações', 'Taxonomias', 'Materiais e atividades complementares', 'Feed', 'Tarefas']}
          enabled={abilities?.metricas}
        />
        {/* TODO: Alterações nas telas de métricas do admin */}

        <PrivateRoute //metabase
          setCurrentPath={setCurrentPath}
          path="/metricas/:selectedTab/:reportId"
          as={ShowEmbed}
          roleName={['Visualizar dados e métricas', 'Mantenedor', 'Institucional', 'Regional']}
          layoutType="mainLayout"
          roleGroup={[
            'Questionário de Percepção',
            'Cursos, módulos e aulas',
            'Projetos',
            'Participantes e perfis administrativos',
            'Questões e apostilas',
            'Avaliações',
            'Taxonomias',
            'Materiais e atividades complementares',
            'Feed',
            'Turmas',
            'Tarefas',
            'Relatórios'
          ]}
          enabled={abilities?.metricas}
        />

        <PrivateRoute //metabase
          setCurrentPath={setCurrentPath}
          path="/metricas/:selectedTab"
          as={MetricsEmbed}
          roleName={['Visualizar dados e métricas', 'Mantenedor', 'Institucional', 'Regional']}
          layoutType="mainLayout"
          roleGroup={[
            'Questionário de Percepção',
            'Cursos, módulos e aulas',
            'Projetos',
            'Participantes e perfis administrativos',
            'Questões e apostilas',
            'Avaliações',
            'Taxonomias',
            'Materiais e atividades complementares',
            'Feed',
            'Cursos, módulos e aulas',
            'Tarefas',
            'Relatórios',
            'Turmas'
          ]}
          enabled={abilities?.metricas}
        />

        {/* <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/metricas/institucional/engajamento"
          as={EngagementMetrics}
          roleName="Visualizar dados e métricas" //ANTIGO
          layoutType="mainLayout"
          roleGroup={[
            'Questionário de Percepção',
            'Cursos, módulos e aulas',
            'Projetos',
            'Participantes e perfis administrativos',
            'Atividades complementares',
            'Questões e apostilas',
            'Avaliações',
     'Taxonomias',
            'Materiais e atividades complementares',
            'Feed',
            'Cursos, módulos e aulas',
            'Tarefas'
          ]}
          enabled={abilities?.metricas}
        />

        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/metricas/institucional/engajamento/:courseId/:classroomId" //ANTIGO
          layoutType="mainLayout"
          as={EngagementMetrics}
          roleName="Visualizar dados e métricas"
          roleGroup={[
            'Questionário de Percepção',
            'Cursos, módulos e aulas',
            'Projetos',
            'Participantes e perfis administrativos',
            'Atividades complementares',
            'Questões e apostilas',
            'Avaliações',
        'Taxonomias',
            'Materiais e atividades complementares',
            'Feed',
            'Cursos, módulos e aulas',
            'Tarefas'
          ]}
          enabled={abilities?.metricas}
        /> */}

        {/* <PrivateRoute
          setCurrentPath={setCurrentPath}                           //ANTIGO
          path="/metricas/institucional/engajamento/:courseId"
          layoutType="mainLayout"
          as={EngagementMetrics}
          roleName="Visualizar dados e métricas"
          roleGroup={[
            'Questionário de Percepção',
            'Cursos, módulos e aulas',
            'Projetos',
            'Participantes e perfis administrativos',
            'Atividades complementares',
            'Questões e apostilas',
            'Avaliações',
      'Taxonomias',
            'Materiais e atividades complementares',
            'Feed',
            'Cursos, módulos e aulas',
            'Tarefas'
          ]}
          enabled={abilities?.metricas}
        /> */}

        {/* <PrivateRoute //antigo
          setCurrentPath={setCurrentPath}
          path="/metricas/institucional/aulas"
          layoutType="mainLayout"
          as={LessonsMetrics}
          roleName="Visualizar dados e métricas"
          roleGroup={[
            'Questionário de Percepção',
            'Cursos, módulos e aulas',
            'Projetos',
            'Participantes e perfis administrativos',
            'Questões e apostilas',
            'Avaliações',
            'Taxonomias',
            'Materiais e atividades complementares',
            'Feed',
            'Cursos, módulos e aulas',
            'Tarefas'
          ]}
          enabled={abilities?.metricas}
        /> */}
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/metricas-1"
          as={Metrics1}
          layoutType="mainLayout"
          enabled={abilities?.metricas}
        />

        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/metricas/perfil/:id"
          as={MetricsProfile}
          layoutType="mainLayout"
          enabled={abilities?.metricas}
        />
        {/* <PrivateRoute                                           //rota em desuso
          setCurrentPath={setCurrentPath}
          path="/metricas-gerais"
          as={GeneralMetrics}
          layoutType="mainLayout"
          enabled={abilities?.metricas}
        /> */}
        {/* questionario de percepção */}
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/questionario"
          as={Quiz}
          roleName="Visualizar questionário de percepção"
          roleGroup="Questionário de Percepção"
          enabled={abilities?.nps}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/questionario/dados/:id"
          as={QuizData}
          roleName="Visualizar questionário de percepção"
          roleGroup="Questionário de Percepção"
          enabled={abilities?.nps}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/questionario/:representation/:surveyId/estudante/:studentId"
          as={QuizDataOpenAnswers}
          roleName="Visualizar questionário de percepção"
          roleGroup="Questionário de Percepção"
          enabled={abilities?.nps}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/questionario/criar/:representation/:id"
          as={QuizCreate}
          roleName="Visualizar questionário de percepção"
          roleGroup="Questionário de Percepção"
          enabled={abilities?.nps}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/questionario/:representation/:id"
          as={QuizData}
          roleName="Visualizar questionário de percepção"
          roleGroup="Questionário de Percepção"
          enabled={abilities?.nps}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/questionario/dados/:surveyId/propostas/:questionId"
          as={QuizDataProposal}
          roleName="Visualizar questionário de percepção"
          roleGroup="Questionário de Percepção"
          enabled={abilities?.nps}
        />
        {/* atividades complementares */}
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/atividade-complementar"
          as={ComplementaryActivity}
          layoutType="mainLayout"
          roleName="Visualizar lista de atividades"
          roleGroup="Materiais e atividades complementares"
          enabled={abilities?.['cursos-ativ-complementar']}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/atividade-complementar/:activityId/:courseId"
          layoutType="mainLayout"
          as={ViewActivity}
          enabled={abilities?.['cursos-ativ-complementar']}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/atividade-complementar/atividade/:id"
          layoutType="mainLayout"
          as={ActivitySingle}
          roleName="Visualizar lista de atividades"
          roleGroup="Materiais e atividades complementares"
          enabled={abilities?.['cursos-ativ-complementar']}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          roleName="Visualizar lista de atividades"
          roleGroup="Materiais e atividades complementares"
          path="/atividade-complementar/avaliacao/:activityId/:id"
          as={ActivityEvaluationView}
          enabled={abilities?.['cursos-ativ-complementar']}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          roleName="Visualizar lista de atividades"
          roleGroup="Materiais e atividades complementares"
          path="/atividade-complementar/avaliar-atividades/:id"
          as={ActivityEvaluationArchive}
          enabled={abilities?.['cursos-ativ-complementar']}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          roleName="Visualizar lista de atividades"
          roleGroup="Materiais e atividades complementares"
          path="/atividade-complementar/avaliar/:activityId/:id"
          as={ActivityEvaluation}
          enabled={abilities?.['cursos-ativ-complementar']}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/atividade-complementar/dados/:id"
          as={ActivityData}
          roleName="Visualizar lista de atividades"
          roleGroup="Materiais e atividades complementares"
          enabled={abilities?.['cursos-ativ-complementar']}
        />
        {/* <PrivateRoute
        setCurrentPath={setCurrentPath} path="/admin/users/show" as={ShowUsers} /> */}
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/admin"
          as={DashboardAdmin}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/classrooms"
          as={Classroom}
          roleName="Visualizar lista de turmas"
          roleGroup="Cursos, módulos e aulas"
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/turma"
          as={Classroom}
          roleName="Visualizar lista de cursos"
          roleGroup="Cursos, módulos e aulas"
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/classrooms/:courseId/:institutionId"
          as={Classroom}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/course/create/participant"
          as={CreateOrUpdateParticipant}
          roleName={'Visualizar lista de cursos' || 'Visualizar lista de turmas'}
          roleGroup="Cursos, módulos e aulas"
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/course/edit/participant/:id"
          as={CreateOrUpdateParticipant}
          roleName={'Visualizar lista de cursos' || 'Visualizar lista de turmas'}
          roleGroup="Cursos, módulos e aulas"
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/classrooms/:classroomId/gerenciar"
          as={ManageParticipants}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/jornada"
          as={Journey}
          // enabled={abilities?.jornada}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/posts/:slug"
          as={Post}
          roleName="Visualizar postagens e comentários"
          roleGroup="Feed"
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/simulados"
          as={ExamsResolution}
          enabled={abilities?.['avaliacoes-simulados']}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/minha-inscricao"
          as={OpenOnboarding}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/profile"
          as={Profile}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/participantes/notificacoes/extrato"
          as={NotificationExtract}
          roleName="Enviar notificações de engajamento"
          roleGroup="Participantes e perfis administrativos"
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/participantes/notificacoes"
          as={Notifications}
          roleName="Enviar notificações de engajamento"
          roleGroup="Participantes e perfis administrativos"
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/participantes/perfis-administrativos/:id"
          as={ManageProfile}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          roleName="Visualizar lista e gerenciar perfis"
          roleGroup="Participantes e perfis administrativos"
          path="/participantes/perfis-administrativos"
          as={AdministrativeProfiles}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          roleName="Visualizar lista e gerenciar perfis"
          roleGroup="Participantes e perfis administrativos"
          path="/participantes/perfis-administrativos"
          as={AdministrativeProfiles}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          roleName="Visualizar lista e gerenciar perfis"
          roleGroup="Participantes e perfis administrativos"
          path="/participantes/instituicoes"
          as={Institutions}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          roleName="Visualizar lista e gerenciar perfis"
          roleGroup="Participantes e perfis administrativos"
          path="/participantes/instituicoes/:id"
          as={InstitutionFlow}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/participantes"
          as={Participants}
          roleName={['Visualizar lista de participantes', 'Visualizar lista e gerenciar perfis', 'Enviar notificações de engajamento']}
          roleGroup="Participantes e perfis administrativos"
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/usuario/:selectedTab/:id"
          as={ShowUser}
          enabled={abilities?.jornada}
        />

        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/projetos/:projectId"
          as={ProjectsSingle}
          roleName={'Visualizar lista de projetos'}
          roleGroup={'Projetos'}
          enabled={abilities?.projetos}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/projetos/grupos/:groupId/:projectId/sobre"
          as={ShowProject}
          roleName={'Visualizar lista de projetos'}
          roleGroup={'Projetos'}
          enabled={abilities?.projetos}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/projetos/:projectId/sobre"
          as={ShowProject}
          roleName={'Visualizar lista de projetos'}
          roleGroup={'Projetos'}
          enabled={abilities?.projetos}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/projetos/grupos/:id"
          as={ViewGroupProject}
          roleName={'Visualizar lista de projetos'}
          roleGroup={'Projetos'}
          enabled={abilities?.projetos}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/projetos/grupos"
          as={GroupProjects}
          roleName={'Visualizar lista de projetos'}
          roleGroup={'Projetos'}
          enabled={abilities?.projetos}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/projetos/individuais/:id"
          as={ViewIndividualProject}
          roleName={'Visualizar lista de projetos'}
          roleGroup={'Projetos'}
          enabled={abilities?.projetos}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/projetos/individuais"
          as={IndividualProjects}
          roleName={'Visualizar lista de projetos'}
          roleGroup={'Projetos'}
          enabled={abilities?.projetos}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/projetos/personalizados"
          as={PersonalizedProjects}
          roleName={'Visualizar lista de projetos'}
          roleGroup={'Projetos'}
          enabled={abilities?.projetos}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/projetos/criar/passo/:step"
          as={CreateProject}
          roleName={'Visualizar lista de projetos'}
          roleGroup={'Projetos'}
          enabled={abilities?.projetos}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/projetos/criar/passo/:step/:id"
          as={CreateProject}
          roleName={'Visualizar lista de projetos'}
          roleGroup={'Projetos'}
          enabled={abilities?.projetos}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/projetos"
          as={Projects}
          roleName={'Visualizar lista de projetos'}
          roleGroup={'Projetos'}
          enabled={abilities?.projetos}
        />
        {/** Redação * */}
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/redacao/impressao/:essayId"
          as={EssayPrint}
          enabled={abilities?.redacao}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/redacao/enviar/:essayId"
          as={EssaySend}
          enabled={abilities?.redacao}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/redacao/consulta/:essayId/:studentId"
          as={EssayConsult}
          enabled={abilities?.redacao}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/redacao/sobre/:essayId"
          as={EssayAbout}
          enabled={abilities?.redacao}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/redacao/correcao/:studentId/:essayId"
          roleName={'Realizar correção'}
          roleGroup={'Redação'}
          as={EssayCorrection}
          enabled={abilities?.redacao}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/redacao/acompanhamento/:essayId"
          as={EssayMonitoring}
          roleName={'Realizar correção'}
          roleGroup={'Redação'}
          enabled={abilities?.redacao}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/redacao/criar/:step"
          roleName={'Criar redação'}
          roleGroup={'Redação'}
          as={CreateEssay}
          enabled={abilities?.redacao}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/redacao/editar/:step/:essayId"
          as={CreateEssay}
          enabled={abilities?.redacao}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/redacao"
          as={Essay}
          enabled={abilities?.redacao}
        />
        {/** Ementa * */}
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/ementa/materias"
          as={Taxonomies}
          roleName="Visualizar lista de taxonomias, tópicos e subtópicos"
          roleGroup="Taxonomias"
        />
        {/** Gerenciamento de provas * */}
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/provas/banco-de-questoes/comentar/:questionId"
          as={CommentQuestion}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/avaliacoes/:selectedTab/impressao/:id"
          as={PrintedExam}
          roleName={['Visualizar apostilas', 'Visualizar lista de provas', 'Visualizar lista de simulados', 'Visualizar banco de questões', 'Visualizar apostilas', 'Visualizar resultados']}
          roleGroup={['Questões e apostilas', 'Avaliações']}
          enabled={abilities?.avaliacoes}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/avaliacoes/:selectedTab/offline/:id"
          as={OfflineExam}
          layoutType="mainLayout"
          roleName={['Visualizar apostilas', 'Visualizar lista de provas', 'Visualizar lista de simulados', 'Visualizar banco de questões', 'Visualizar apostilas', 'Visualizar resultados']}
          roleGroup={['Questões e apostilas', 'Avaliações']}
          enabled={abilities?.avaliacoes}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/avaliacoes/:selectedTab"
          as={Exams}
          layoutType="mainLayout"
          roleName={['Visualizar apostilas', 'Visualizar lista de provas', 'Visualizar lista de simulados', 'Visualizar banco de questões', 'Visualizar apostilas', 'Visualizar resultados']}
          roleGroup={['Questões e apostilas', 'Avaliações']}
          enabled={abilities?.avaliacoes}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/resultado-das-provas/"
          as={Exams}
          layoutType="mainLayout"
          roleName="Visualizar apostilas"
          roleGroup="Questões e apostilas"
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/lista-resultado-das-provas/:id"
          as={ExamMetricsList}
          layoutType="mainLayout"
          roleName="Visualizar apostilas"
          roleGroup="Questões e apostilas"
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/provas/criar/:step/:id"
          as={CreateExam}
          roleName="Editar provas"
          roleGroup="Avaliações"
          enabled={abilities?.avaliacoes}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/provas/objetivos"
          as={Goals}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/provas/criar/:step"
          as={CreateExam}
          roleName="Criar provas"
          roleGroup="Avaliações"
          enabled={abilities?.avaliacoes}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/curso/criar/:step/:id"
          as={CreateCourse}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/curso/criar/:step"
          as={CreateCourse}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/provas/gerenciar"
          as={ManageExam}
          roleName="Visualizar apostilas"
          roleGroup="Questões e apostilas"
          enabled={abilities?.avaliacoes}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/provas/gerenciar/:questionbookId"
          as={ManageExam}
          roleName="Visualizar apostilas"
          roleGroup="Questões e apostilas"
          enabled={abilities?.avaliacoes}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/provas/gerenciar/:questionbookId/:taxonomyId"
          as={ManageExam}
          roleName="Visualizar apostilas"
          roleGroup="Questões e apostilas"
          enabled={abilities?.avaliacoes}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/apostilas/gerenciar"
          as={ManageExam}
          roleGroup="Questões e apostilas"
          roleName="Visualizar apostilas"
          enabled={abilities?.['avaliacoes-apostilas']}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/apostilas/gerenciar/:questionbookId"
          as={ManageExam}
          roleGroup="Questões e apostilas"
          roleName="Visualizar apostilas"
          enabled={abilities?.['avaliacoes-apostilas']}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/apostilas/gerenciar/:questionbookId/:taxonomyId"
          as={ManageExam}
          roleGroup="Questões e apostilas"
          roleName="Visualizar apostilas"
          enabled={abilities?.['avaliacoes-apostilas']}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/apostilas/criar/:step"
          as={CreateQuestionBook}
          roleGroup="Questões e apostilas"
          roleName="Visualizar apostilas"
          enabled={abilities?.['avaliacoes-apostilas']}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/apostilas/criar/:step/:id"
          as={CreateQuestionBook}
          roleGroup="Questões e apostilas"
          roleName="Visualizar apostilas"
          enabled={abilities?.['avaliacoes-apostilas']}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/simulados/gerenciar"
          as={ManageExam}
          roleGroup="Questões e apostilas"
          roleName="Visualizar apostilas"
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/simulados/gerenciar/:questionbookId"
          as={ManageExam}
          roleGroup="Questões e apostilas"
          roleName="Visualizar apostilas"
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/simulados/gerenciar/:questionbookId/:taxonomyId"
          as={ManageExam}
          roleGroup="Questões e apostilas"
          roleName="Visualizar apostilas"
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/simulados/criar/:step"
          as={CreateMockQuestionBook}
          roleGroup="Questões e apostilas"
          roleName="Visualizar apostilas"
          enabled={abilities?.['avaliacoes-simulados']}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/simulados/criar/:step/:id"
          as={CreateMockQuestionBook}
          roleGroup="Questões e apostilas"
          roleName="Visualizar apostilas"
          enabled={abilities?.['avaliacoes-simulados']}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/simulados/sobre/:id"
          as={AboutSimulations}
          layoutType="mainLayout"
          enabled={abilities?.['avaliacoes-simulados']}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/avaliacoes/sobre/:id"
          as={AboutExam}
          layoutType="mainLayout"
          enabled={abilities?.['avaliacoes-provas']}
        />

        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/provas/:id"
          as={StudentResponse}
          enabled={abilities?.['avaliacoes-provas']}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/simulados/:id"
          as={StudentResponse}
          enabled={abilities?.['avaliacoes-simulados']}
        />

        {/* <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/avaliacoes/meu-desempenho"
          as={ExamMetrics}
          roleName={'Visualizar resultados'}
          roleGroup={'Avaliações'}
          enabled={abilities?.avaliacoes}
        /> */}

        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/avaliacoes"
          as={ExamsResolution}
          layoutType="mainLayout"
          enabled={abilities?.avaliacoes}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/resultado-das-avaliacoes"
          as={ExamsResolution}
          layoutType="mainLayout"
          enabled={abilities?.avaliacoes}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/provas/resultados"
          as={ExamMetrics}
          roleName={'Visualizar resultados'}
          roleGroup={'Avaliações'}
        />
        {/* ACRESCENTAR ROLE DE CONTROLE DE VISUALIZAÇÃO DE BANCO DE QUESTÕES */}
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/ementa/banco-de-questoes"
          as={QuestionsDatabase}
          roleName="Visualizar banco de questões"
          roleGroup="Questões e apostilas"
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/ementa/questoes-publicas"
          as={UpdatePublicQuestions}
        />
        {/* <PrivateRoute                                 //rota em desuso
          setCurrentPath={setCurrentPath}
          path="/ementa/cadernos-de-questoes"
          as={QuestionBook}
          roleName="Visualizar apostilas"
          roleGroup="Questões e apostilas"
        /> */}
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/ementa/cadernos-de-questoes/:questionBookId"
          as={UpdateQuestionBook}
        />
        {/* <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/ementa/materiais"
          as={Materials}
          roleName="Visualizar lista de taxonomias, tópicos e subtópicos"
          roleGroup="Taxonomias"
        /> */}
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/plano-de-estudos/:planId/bloco-de-estudos/:blockId"
          as={StudyBlock}
          enabled={abilities?.['plano-de-estudo']}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/plano-de-estudos/:planId"
          as={ViewStudyPlan}
          enabled={abilities?.['plano-de-estudo']}
        />
        {/* <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/ajuda"
          as={HelpCenter}
        /> */}
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/ajuda/:id"
          as={HelpViewVideo}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/componentes"
          as={Example}
          layoutType="mainLayout"
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/auditorio/criar/:step"
          as={CreateAuditorium}
          enabled={abilities?.auditorio}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/auditorio/editar/:step/:id"
          as={CreateAuditorium}
          enabled={abilities?.auditorio}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/auditorio/:auditoriumId"
          as={ViewAuditorium}
          enabled={abilities?.auditorio}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/auditorio"
          as={Auditorium}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/aulas-ao-vivo/:liveClassId"
          as={ViewLiveClass}
          enabled={abilities?.['aulas-ao-vivo']}
          roleGroup="Aulas ao vivo"
          roleName="Visualizar aulas ao vivo"
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/aulas-ao-vivo"
          as={LiveClasses}
          roleGroup="Aulas ao vivo"
          roleName="Visualizar aulas ao vivo"
          enabled={abilities?.['aulas-ao-vivo']}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/desafio-da-semana/:selectedTab"
          as={WeekChallenge}
          enabled={abilities?.['desafio-da-semana']}
        />

        <PrivateRoute
          setCurrentPath={setCurrentPath}
          enabled={abilities?.auditorio}
          path="/relatorio/seduc-pi/geral/gre/:greId/:questionBookId"
          layoutType="mainLayout"
          as={SeducPiGRE}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/relatorio/aula-ao-vivo"
          as={LiveClassesReport}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/relatorio/cadastro"
          as={RegistrationReport}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/relatorio/acesso"
          as={AccessReport}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/relatorio/progresso-de-curso"
          as={CourseProgressReport}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          enabled={abilities?.auditorio}
          path="/relatorio/seduc-pi/geral"
          as={SeducPiGeral}
          layoutType="mainLayout"
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          enabled={abilities?.auditorio}
          path="/relatorio/seduc-pi"
          as={SeducPi}
        />
        <PrivateRoute
          setCurrentPath={setCurrentPath}
          path="/plano-de-aulas/:selectedTab"
          as={LessonPlan}
          enabled={abilities?.['plano-de-aula']}
        />
        <Notfound default />
      </Router>
    </PageWithLayouts>
  );
};
