import { useTranslation } from 'react-i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { i18n } from 'app/components/i18n/i18n';

export default function StepDuration(props) {
  const { t } = useTranslation();
  const { step, form, values, touched } = props;
  moment.locale(t('language.language'));

  useEffect(() => {
    i18n.changeLanguage(window.env.REACT_APP_LOCALE);
  }, []);

  let hours = [];
  for (let i = 0; i < 24; i++) {
    hours.push(`${String(i).padStart(2, '0')}`);
  }

  let minutes = [];
  for (let i = 0; i < 60; i++) {
    minutes.push(`${String(i).padStart(2, '0')}`);
  }

  const limitTime = [
    { id: 1, title: `1 ${t('tasks.hour')}`, value: '01:00' },
    { id: 2, title: `2 ${t('tasks.hours')}`, value: '02:00' },
    { id: 3, title: `3 ${t('tasks.hours')}`, value: '03:00' },
    { id: 4, title: `4 ${t('tasks.hours')}`, value: '04:00' },
    { id: 5, title: `5 ${t('tasks.hours')}`, value: '05:00' },
    { id: 6, title: `6 ${t('tasks.hours')}`, value: '06:00' },
    { id: 7, title: `7 ${t('tasks.hours')}`, value: '07:00' },
    { id: 8, title: `8 ${t('tasks.hours')}`, value: '08:00' }
  ];

  const correctionFactor = [
    { id: 1, title: t('exams.textAsideNoCorrectFactor'), value: 'without_factor' },
    { id: 2, title: t('exams.textAsideWrongCancelRight'), value: 'one_wrong_cancels_out_one_right' }
  ];

  return (
    step === 'duracao' && (
      <div className="page__content fadeIn u-mb-5">
        <div className="new-questions__header">
          <h2 className="new-questions__title">{form.values.title || 'Nova prova'}</h2>
        </div>
        <div className="new-questions__body">
          <p className="new-questions__subtitle">{t('exams.detailsDescription')}</p>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="resolutionTime"
            >
              *{t('exams.limitTimeTitle')}
            </label>

            <select
              className="form__select"
              name="resolutionTime"
              id="resolutionTime"
              onChange={e => form.setFieldValue('resolutionTime', e.target.value)}
              value={form?.values?.resolutionTime}
            >
              <option value="">{t('filter.labelSelect')}</option>
              {limitTime?.map(t => (
                <option
                  key={t.id}
                  value={t.value}
                >
                  {t.title}
                </option>
              ))}
            </select>
            {form?.touched.resolutionTime && form?.errors.resolutionTime && <span style={{ color: 'red' }}>{form?.errors.resolutionTime}</span>}
          </div>

          {(form?.values?.['classroom-ids']?.length || 0) > 0 && (
            <>
              <div className="form__row form__row--columns">
                <div className="form__col">
                  <label
                    className="form__label"
                    htmlFor="startDate"
                  >
                    *{t('exams.startDateTitle')}
                  </label>
                  <input
                    className="form__control"
                    id="evaluationStart"
                    name="evaluationStart"
                    type="date"
                    onChange={e =>
                      form.setFieldValue('evaluationStart', {
                        ...form.values.evaluationStart,
                        date: e.target.value
                      })
                    }
                    value={moment(form?.values?.evaluationStart?.date).format('YYYY-MM-DD')}
                  />
                  {form?.touched?.evaluationStart?.date && form?.errors?.evaluationStart?.date && <span style={{ color: 'red' }}>{form?.errors?.evaluationStart?.date}</span>}
                </div>
                <fieldset className="form__col">
                  <legend className="form__label">*{t('exams.startHourTitle')}</legend>
                  <div className="form__columns">
                    <div className="form__col">
                      <select
                        className="form__select"
                        name="startHour"
                        id="startHour"
                        aria-label="Hora"
                        onChange={e =>
                          form.setFieldValue('evaluationStart', {
                            ...form.values.evaluationStart,
                            hour: e.target.value
                          })
                        }
                        value={form?.values?.evaluationStart?.hour}
                      >
                        {hours.map(i => (
                          <option
                            key={i}
                            value={parseInt(i)}
                          >
                            {i}
                          </option>
                        ))}
                      </select>
                      {form?.touched?.evaluationStart?.hour && form?.errors?.evaluationStart?.hour && <span style={{ color: 'red' }}>{form?.errors?.evaluationStart?.hour}</span>}
                    </div>

                    <div className="form__col">
                      <select
                        className="form__select"
                        name="startMinute"
                        id="startMinute"
                        aria-label="Minuto"
                        onChange={e =>
                          form.setFieldValue('evaluationStart', {
                            ...form.values.evaluationStart,
                            minute: e.target.value
                          })
                        }
                        value={form?.values?.evaluationStart?.minute}
                      >
                        {minutes.map(i => (
                          <option
                            key={i}
                            value={parseInt(i)}
                          >
                            {i}
                          </option>
                        ))}
                      </select>
                      {form?.touched?.evaluationStart?.minute && form?.errors?.evaluationStart?.minute && <span style={{ color: 'red' }}>{form?.errors?.evaluationStart?.minute}</span>}
                    </div>
                  </div>
                </fieldset>
              </div>

              <div className="form__row form__row--columns">
                <div className="form__col">
                  <label
                    className="form__label"
                    htmlFor="endDate"
                  >
                    *{t('exams.endDateTitle')}
                  </label>
                  <input
                    className="form__control"
                    id="endDate"
                    name="endDate"
                    type="date"
                    onChange={e =>
                      form.setFieldValue('evaluationEnd', {
                        ...form.values.evaluationEnd,
                        date: e.target.value
                      })
                    }
                    value={moment(form?.values?.evaluationEnd?.date).format('YYYY-MM-DD')}
                  />
                  {form?.touched?.evaluationEnd?.date && form?.errors?.evaluationEnd?.date && <span style={{ color: 'red' }}>{form?.errors?.evaluationEnd?.date}</span>}
                </div>

                <fieldset className="form__col">
                  <legend className="form__label">*{t('exams.endHourTitle')}</legend>

                  <div className="form__columns">
                    <div className="form__col">
                      <select
                        className="form__select"
                        name="startHour"
                        id="startHour"
                        aria-label="Hora"
                        onChange={e =>
                          form.setFieldValue('evaluationEnd', {
                            ...form.values.evaluationEnd,
                            hour: e.target.value
                          })
                        }
                        value={form?.values?.evaluationEnd?.hour}
                      >
                        {hours.map(i => (
                          <option
                            key={i}
                            value={parseInt(i)}
                          >
                            {i}
                          </option>
                        ))}
                      </select>
                      {form?.touched?.evaluationEnd?.hour && form?.errors?.evaluationEnd?.hour && <span style={{ color: 'red' }}>{form?.errors?.evaluationEnd?.hour}</span>}
                    </div>

                    <div className="form__col">
                      <select
                        className="form__select"
                        name="startMinute"
                        id="startMinute"
                        aria-label="Minuto"
                        onChange={e =>
                          form.setFieldValue('evaluationEnd', {
                            ...form.values.evaluationEnd,
                            minute: e.target.value
                          })
                        }
                        value={form?.values?.evaluationEnd?.minute}
                      >
                        {minutes.map(i => (
                          <option
                            key={i}
                            value={parseInt(i)}
                          >
                            {i}
                          </option>
                        ))}
                      </select>
                      {form?.touched?.evaluationEnd?.minute && form?.errors?.evaluationEnd?.minute && <span style={{ color: 'red' }}>{form?.errors?.evaluationEnd?.minute}</span>}
                    </div>
                  </div>
                </fieldset>
              </div>
            </>
          )}

          <div className="form__row form__row--columns">
            <div className="form__col">
              <label
                className="form__label"
                htmlFor="passingScore"
              >
                *{t('exams.passingScoreTitle')}
              </label>
              <input
                className="form__control"
                placeholder={`${t('exams.placeholderExample')}: 7.3`}
                id="passingScore"
                name="passingScore"
                type="number"
                value={form?.values?.passingScore}
                onChange={e => form.setFieldValue('passingScore', e.target.value)}
              />
              {form?.touched?.passingScore && form?.errors?.passingScore && <span style={{ color: 'red' }}>{form?.errors?.passingScore}</span>}
            </div>

            <div className="form__col">
              <label
                className="form__label"
                htmlFor="limitTime"
              >
                *{t('exams.correctionFactorTitle')}
              </label>

              <select
                className="form__select"
                name="correctionFactor"
                id="correctionFactor"
                onChange={e => form.setFieldValue('correctionFactor', e.target.value)}
                value={form?.values?.correctionFactor}
              >
                <option value="">{t('filter.labelSelect')}</option>
                {correctionFactor?.map(o => (
                  <option
                    key={o.id}
                    value={o.value}
                  >
                    {o.title}
                  </option>
                ))}
              </select>
              {form?.touched?.correctionFactor && form?.errors?.correctionFactor && <span style={{ color: 'red' }}>{form?.errors?.correctionFactor}</span>}
            </div>
          </div>
        </div>
      </div>
    )
  );
}
