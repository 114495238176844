import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import Modal from 'app/components/Modal';
import ModalTaxonomies from 'app/components/modalTaxonomies';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import MultiSelect from 'app/components/MultiSelect';
import { t } from 'i18next';
import * as yup from 'yup';
import promiseRequest from 'app/utils/promiseToast';

export default function NewMaterialModal(props) {
  const { show, onClose, currentModuleId, selectedMaterial, count } = props;

  const client = useClient();
  const [isOpenModalTaxonomies, setIsOpenModalTaxonomies] = useState(false);
  const [taxonomies, setTaxonomies] = useState([]);
  const [lessons, setLessons] = useState([]);
  const { promiseToast, isSubmitting } = promiseRequest();

  const handleSubmit = () => {
    createMaterial();
  };

  const validationSchema = yup.object({
    title: yup.string().required(t('warning.requiredField')),
    file: yup
      .mixed()
      // .ensure()
      .when('contentType', {
        is: t('activity.toggleFile'),
        then: yup.mixed().required(t('warning.requiredField'))
      }),
    url: yup
      .string()
      .ensure()
      .when('contentType', {
        is: 'URL',
        then: yup.string().required(t('warning.requiredField'))
      }),
    contentType: yup.string()
  });

  const createMaterialForm = useFormik({
    initialValues: {
      'learning-system-id': currentModuleId,
      'learning-system-item-ids': [],
      title: '',
      selectedTaxonomies: [],
      file: null,
      url: null,
      contentType: t('activity.toggleFile')
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit
  });

  useEffect(() => {
    if (selectedMaterial) {
      createMaterialForm.setValues({
        'learning-system-id': selectedMaterial['learning-system-id'],
        'learning-system-item-ids': selectedMaterial['learning-system-item-ids'],
        title: selectedMaterial.title,
        selectedTaxonomies: selectedMaterial.taxonomies,
        url: selectedMaterial.url ?? selectedMaterial.file.url
      });
    }
  }, [selectedMaterial]);

  useEffect(() => {
    if ((selectedMaterial && selectedMaterial.file.url) || selectedMaterial?.url) {
      createMaterialForm.setFieldValue('contentType', 'URL');
    } else {
      createMaterialForm.setFieldValue('contentType', t('activity.toggleFile'));
    }
  }, [selectedMaterial]);

  useEffect(() => {
    createMaterialForm.resetForm();
  }, [count]);

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const createMaterial = async () => {
    const auxValues = { ...createMaterialForm.values };
    auxValues['taxonomy-ids'] = auxValues.selectedTaxonomies.map(t => t.id);

    if (auxValues.file?.name && auxValues.file?.name !== null) {
      auxValues.file = await toBase64(auxValues.file);
      delete auxValues.url;
    }

    if (auxValues.file?.url) {
      auxValues.file = auxValues.file;
      auxValues.remote_file_url = auxValues.file;
      delete auxValues.url;
    }

    if (auxValues.url && auxValues.url !== null) {
      auxValues.url = auxValues.url;
      delete auxValues.file;
    }

    if (auxValues?.['learning-system-item-ids'].length === 0) {
      auxValues['learning-system-id'] = parseInt(currentModuleId);
    }

    if (auxValues?.['learning-system-item-ids'] === '') {
      auxValues['learning-system-item-ids'] = [];
    }
    delete auxValues.contentType;
    delete auxValues.selectedTaxonomies;

    const url = selectedMaterial ? ['/learning_system_files', selectedMaterial.id] : '/learning_system_files';

    const onSuccess = () => {
      if (selectedMaterial) {
        onClose();
      } else {
        createMaterialForm.resetForm();
      }
    };

    promiseToast({
      url,
      request: auxValues,
      onSuccess,
      successText: selectedMaterial ? 'Material editado!' : 'Material criado!',
      errorText: selectedMaterial ? 'Erro ao editar material' : 'Erro ao criar material'
    });
  };

  const getTaxonomies = async () => {
    try {
      const { data } = await client.fetch('taxonomies');
      setTaxonomies(data);
    } catch {
      toast.error('Erro ao carregar taxonomias');
    }
  };

  const fetchLessons = async () => {
    const { data, error } = await client.fetch(`/learning_system_items?simple=true&filter[learning_systems.id]=${currentModuleId}`);
    if (error) {
      toast.error('Erro ao carregar aulas');
    } else {
      setLessons(
        data.map(item => {
          return { id: item.id, name: item.body };
        })
      );
    }
  };

  useEffect(() => {
    getTaxonomies();
    fetchLessons();
  }, []);

  useEffect(() => {
    fetchLessons();
  }, [currentModuleId]);

  return (
    <>
      <Modal
        show={show}
        onClose={onClose}
      >
        <form
          method="post"
          className="form"
          onSubmit={createMaterialForm.handleSubmit}
        >
          <h2 className="form__title">{t('taxonomyAndMaterials.addMaterialTitle')}</h2>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="classCourse"
            >
              {t('taxonomyAndMaterials.fileName')}
            </label>
            <input
              className="form__control"
              id="title"
              name="title"
              type="text"
              placeholder={t('taxonomyAndMaterials.placeholderFileName')}
              value={createMaterialForm.values.title}
              onChange={createMaterialForm.handleChange}
            />
            {createMaterialForm.touched.title && createMaterialForm.errors.title && <span style={{ color: 'red' }}>{createMaterialForm.errors.title}</span>}
          </div>

          <div
            className="btn-group"
            role="group"
            aria-label="Escolha o tipo do material"
          >
            {[t('activity.toggleFile'), 'URL'].map((alternative, index) => (
              <React.Fragment key={index}>
                <input
                  type="radio"
                  name="contentType"
                  autoComplete="off"
                  checked={alternative === createMaterialForm.values.contentType}
                />
                <label
                  className="btn btn--outline"
                  htmlFor="contentType"
                  onClick={() => {
                    if (alternative === t('activity.toggleFile')) {
                      createMaterialForm.setFieldValue('url', '');
                    } else {
                      createMaterialForm.setFieldValue('file', '');
                    }
                    createMaterialForm.setFieldValue('contentType', alternative);
                  }}
                >
                  {alternative}
                </label>
              </React.Fragment>
            ))}
          </div>

          {createMaterialForm.values.contentType === t('activity.toggleFile') && (
            <fieldset className="form__row">
              <label className="form__custom-file-input">
                <input
                  type="file"
                  name="file"
                  id="file"
                  onChange={e => {
                    createMaterialForm.setFieldValue('file', e.target.files[0]);
                  }}
                />
                <span className="form__control">{createMaterialForm?.values?.file ? createMaterialForm?.values?.file?.name ?? createMaterialForm?.values?.file?.url : t('taxonomyAndMaterials.placeholderSelectMaterial')}</span>
              </label>
              {createMaterialForm.touched.file && createMaterialForm.errors.file && <span style={{ color: 'red' }}>{createMaterialForm.errors.file}</span>}
            </fieldset>
          )}
          {createMaterialForm.values.contentType === 'URL' && (
            <div className="form__row">
              <input
                className="form__control"
                id="url"
                name="url"
                type="url"
                placeholder={t('activity.placeholderSentUrl')}
                value={createMaterialForm?.values?.url}
                onChange={createMaterialForm.handleChange}
              />
              {createMaterialForm.touched.url && createMaterialForm.errors.url && <span style={{ color: 'red' }}>{createMaterialForm.errors.url}</span>}
            </div>
          )}

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="classCourse"
            >
              {t('tasks.mattersRelated')}
            </label>

            <MultiSelect
              selectedTaxonomies={createMaterialForm?.values?.selectedTaxonomies}
              setFieldValue={createMaterialForm?.setFieldValue}
              openModal={() => setIsOpenModalTaxonomies(true)}
              placeholder={t('tasks.mattersAdd')}
            />
          </div>

          <fieldset className="form__row">
            <label
              className="form__label"
              htmlFor="startDate"
            >
              {t('activity.associateToLesson')}
            </label>
            <FilterSelectionBox
              optionBlank={[]}
              blankLabel={t('activity.selectLesson')}
              options={lessons}
              onChange={e => createMaterialForm.setFieldValue('learning-system-item-ids', e.target.value)}
              value={createMaterialForm.values?.['learning-system-item-ids']}
            />
          </fieldset>

          <button
            className={isSubmitting ? 'btn btn--primary disabled' : 'btn btn--primary'}
            type="submit"
          >
            {t('button.saveContinue')}
          </button>
        </form>
      </Modal>

      {taxonomies?.length > 0 && isOpenModalTaxonomies && (
        <ModalTaxonomies
          taxonomies={taxonomies}
          selectedTaxonomies={createMaterialForm.values.selectedTaxonomies}
          setFieldValue={createMaterialForm.setFieldValue}
          closeModal={() => setIsOpenModalTaxonomies(false)}
        />
      )}
    </>
  );
}
