import React, { useEffect } from 'react';
import Loader from 'app/components/loader';
import { useParams } from '@reach/router';
import { useTranslation } from 'react-i18next';
import { i18n } from 'app/components/i18n/i18n';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';

export default function StepName(props) {
  const { t } = useTranslation();
  const { step, createLessonForm, loading, taxonomies, taxonomieError, bodyError, loadingTaxonomies } = props;
  const { lessonId } = useParams();
  const unrequired = createLessonForm.values.unrequired;
  const animatedComponents = makeAnimated();

  useEffect(() => {
    i18n.changeLanguage(window.env.REACT_APP_LOCALE);
  }, []);

  return (
    step === 'nome' && (
      <div className="page__content fadeIn u-mb-5">
        <div className="new-questions__header">
          <h2 className="new-questions__title">
            {loading && <Loader />}
            {!loading && lessonId && createLessonForm.values.body}
            {!loading && !lessonId && createLessonForm.values.body === '' && 'Nova aula'}
            {!loading && !lessonId && createLessonForm.values.body}
          </h2>
        </div>

        <div className="new-questions__body">
          <label className="form__label">{t('lessons.isLessonMandatory')}</label>

          <div className="form__check form__switch">
            <input
              className="form__check-input"
              id={unrequired}
              name={unrequired}
              type="checkbox"
              checked={unrequired}
              onClick={() => {
                createLessonForm.setFieldValue('unrequired', !unrequired);
              }}
            />
            <label> {t('textsCommon.mandatoryLesson')}</label>
          </div>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="body"
            >
              *{t('textsCommon.lessonName')}
            </label>
            <input
              className="form__control"
              placeholder={t('textsCommon.writeHere')}
              id="body"
              name="body"
              type="text"
              value={createLessonForm.values.body}
              onChange={e => createLessonForm.setFieldValue('body', e.target.value)}
            />
            {bodyError && !createLessonForm.values.body && <span style={{ color: 'red' }}>{bodyError}</span>}
          </div>

          <fieldset className="form__row">
            <legend className="form__label">*{t('tasks.mattersRelated')}</legend>
            {taxonomies?.length > 0 ? (
              <Select
                classNamePrefix="react-multi-select"
                openMenuOnFocus
                noOptionsMessage={() => 'Sem opções'}
                value={createLessonForm?.values?.selectedTaxonomies}
                placeholder={loadingTaxonomies ? 'Carregando...' : 'Selecione a matéria'}
                onChange={item => createLessonForm.setFieldValue('selectedTaxonomies', item)}
                components={{
                  animatedComponents,
                  IndicatorSeparator: () => null,
                  ClearIndicator: () => null
                }}
                closeMenuOnSelect={false}
                isSearchable={true}
                options={taxonomies?.map(item => ({ value: item.id, label: item.name }))}
                isMulti
              />
            ) : (
              <Loader />
            )}
            {taxonomieError && !createLessonForm.values.selectedTaxonomies && <span style={{ color: 'red' }}>{taxonomieError}</span>}
          </fieldset>
        </div>
      </div>
    )
  );
}
