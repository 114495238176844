import React, { useState, createContext, useEffect } from 'react';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';

export const CourseContext = createContext();

export const CourseProvider = ({ children }) => {
  const { t } = useTranslation();
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [detailedCourses, setDetailedCourses] = useState([]);
  const [detailedCourse, setDetailedCourse] = useState();
  const [students, setStudents] = useState([]);
  const [managers, setManagers] = useState([]);
  const basePath = window.env.REACT_APP_BASE_PATH;

  const client = useClient();

  const getCourses = async () => {
    try {
      setLoading(true);
      const { data } = await client.fetch('courses');

      setCourses(data);
      setLoading(false);
    } catch (e) {
      console.error(e);
      toast.error(t('toast.errorGetCourse'));
    }
  };

  const getDetailedCourses = async id => {
    const url = id === undefined ? `/courses?detailed=true` : `/courses/${id}?detailed=true`;
    setLoading(true);
    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error('Erro ao carregar cursos');
    } else {
      if (id === undefined) {
        setDetailedCourses(data);
      } else {
        setDetailedCourse(data);
      }
      if (id) {
        const students = [];
        const managers = [];
        data.users.forEach(item => {
          if (item['profile-names'][0] === 'Aluno') {
            students.push(item);
          } else {
            managers.push(item);
          }
        });
        setStudents(students);
        setManagers(managers);
      }
    }
    setLoading(false);
  };

  const getSimpleCourses = async () => {
    try {
      setLoading(true);
      const { data } = await client.fetch('courses?simple=true');
      setCourses(data);
      setLoading(false);
    } catch (e) {
      console.error(e);
      toast.error(t('toast.errorGetCourse'));
    }
  };

  const createCourse = async form => {
    setLoading(true);
    const formData = {
      ...form,
      'taxonomy-ids': form.selectedTaxonomies.map(e => e.id)
    };
    delete formData.selectedTaxonomies;
    const { data, error } = await client.mutate('courses', formData);

    if (error) {
      toast.error(t('toast.errorCreateCourse'));

      setLoading(false);
    } else {
      toast.success(t('toast.successCreateCourse'));
      getCourses();
      navigate(`${basePath}/classrooms/${data.id}`);
    }
  };

  const updateCourse = async course => {
    try {
      const { error } = await client.mutate(['courses', course.id], course);
      if (error) {
        toast.error(t('toast.errorEditCourse'));
      } else {
        getCourses();
        toast.success(t('toast.successEditCourse'));
      }
    } catch (e) {
      console.error(e);
      toast.error(t('toast.errorEditCourse'));
    }
  };

  const deleteCourse = async id => {
    try {
      setLoading(true);
      const { error } = await client.delete(['courses', id]);
      if (error) {
        toast.error(t('toast.errorDeleteCourse'));
      } else {
        toast.success(t('toast.successDeleteCourse'));
        setCourses(courses.filter(c => c.id !== id));
      }
    } catch (e) {
      console.error(e);
      toast.error(t('toast.errorDeleteCourse'));
    }
    setLoading(false);
  };

  const changeSelectedCourse = course => {
    setSelectedCourse(course);
  };

  return (
    <CourseContext.Provider
      value={{
        loading,
        courses,
        getCourses,
        getSimpleCourses,
        createCourse,
        updateCourse,
        deleteCourse,
        selectedCourse,
        changeSelectedCourse,
        getDetailedCourses,
        detailedCourses,
        detailedCourse,
        students,
        managers
      }}
    >
      {children}
    </CourseContext.Provider>
  );
};
