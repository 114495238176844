import React from 'react';
import { FiMap, FiMenu } from 'react-icons/fi';
import { Link } from '@reach/router';

export default function ShortcutWidgetAdmin() {
  const basePath = window.env.REACT_APP_BASE_PATH;
  return (
    <div className="shortcut-widget u-mb-4">
      <Link
        to={basePath}
        className="shortcut-widget__item shortcut-widget__item--study-plan"
      >
        <span className="shortcut-widget__icon">
          <FiMap />
        </span>

        <div className="shortcut-widget__content">
          <h3 className="shortcut-widget__title">Plano de Aulas</h3>
          <p className="shortcut-widget__text">Em breve....</p>
        </div>
      </Link>

      <Link
        to={basePath}
        className="shortcut-widget__item shortcut-widget__item--class-diary"
      >
        <span className="shortcut-widget__icon">
          <FiMenu />
        </span>

        <div className="shortcut-widget__content">
          <h3 className="shortcut-widget__title">Diário de classe</h3>
          <p className="shortcut-widget__text">Em breve...</p>
        </div>
      </Link>
    </div>
  );
}
