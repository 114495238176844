import React, { useState, useEffect } from 'react';
import { useClient, useMutation } from 'jsonapi-react';
import { FiMoreHorizontal } from 'react-icons/fi';
import Swal from 'sweetalert2';
import Dropdown from 'app/components/Dropdown';
import Modal from 'app/components/Modal';
import Loader from 'app/components/loader';
import EmptyState from 'app/components/EmptyState';
import { TabNavbar } from './TabNavbar';
import _ from 'lodash';
import { checkIncludeProfiles, checkIncludeAppName } from '../../utils/getAuthorityLevel';
import Error404 from 'app/components/Error404';
import toast from 'react-hot-toast';
import getPermission from 'app/utils/getPermission';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from 'app/components/BreadCrumbs';

export const Taxonomies = props => {
  const { uri } = props;

  const { t } = useTranslation();
  const client = useClient();
  const [addTaxonomy] = useMutation('taxonomies');
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [currentTaxonomy, setCurrentTaxonomy] = useState();
  const [taxonomies, setTaxonomies] = useState([]);
  const [topics, setTopics] = useState([]);
  const [subTopics, setSubTopics] = useState([]);
  const [newTaxonomyName, setNewTaxonomyName] = useState('');
  const [editTaxonomyName, setEditTaxonomyName] = useState('');
  const [activeColumn, setActiveColumn] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const { data } = await client.fetch(`taxonomies`);
      setTaxonomies(data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const selectTaxonomy = (taxonomy, currentList, nextList) => {
    let newTopics = taxonomy['taxonomy-children'] || [];

    setTaxonomies([...taxonomies]);
    if (nextList === 'subTopics') {
      topics.map(t => (t.isActive = false));
      taxonomy.isActive = true;
      setSubTopics([...newTopics]);
    } else if (nextList === 'topics') {
      taxonomies.map(t => (t.isActive = false));
      taxonomy.isActive = true;
      setTopics([...newTopics]);
      setSubTopics([]);
    } else {
      subTopics.map(t => (t.isActive = false));
      taxonomy.isActive = true;
      setSubTopics([...subTopics]);
    }
  };

  const newTaxonomyForm = currentList => {
    setActiveColumn(currentList);
    setShowModal(true);
  };

  const editTaxonomyForm = (currentList, taxonomy) => {
    setCurrentTaxonomy(taxonomy);
    setActiveColumn(currentList);
    setEditTaxonomyName(taxonomy.name);
    setEditModal(true);
  };

  const destroyTaxonomyForm = (currentList, taxonomy) => {
    Swal.fire({
      title: t('taxonomyAndMaterials.warningDeleteTaxonomy'),
      text: t('projects.warningDeleteCannotBeUndoneText'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(result => {
      if (result.isConfirmed) {
        destroyCurrentTaxonomy(currentList, taxonomy);
      }
    });
  };

  const destroyCurrentTaxonomy = async (currentList, taxonomy) => {
    const { error, errors } = await client.delete(['taxonomies', taxonomy.id]);

    if (error) {
      Swal.fire('Ops...', t('toast.errorTryAgain'), 'error');
    } else {
      if (currentList === 'topics') {
        let newList = topics;
        _.remove(newList, ['id', taxonomy.id]);
        setTopics([...newList]);
      } else if (currentList === 'subTopics') {
        let newList = subTopics;
        _.remove(newList, ['id', taxonomy.id]);
        setSubTopics([...newList]);
      } else {
        let newList = taxonomies;
        _.remove(newList, ['id', taxonomy.id]);
        setTaxonomies([...newList]);
      }

      Swal.fire('Excluida!', 'A taxonomia foi deletada.', 'success');
    }
  };

  const editCurrentTaxonomy = async e => {
    e.preventDefault();

    const { data, error } = await client.mutate(['taxonomies', currentTaxonomy.id], {
      name: editTaxonomyName
    });

    if (error) {
      toast.error('Erro ao editar taxonomia.');
    } else {
      const newTaxonomyList = updateTaxonomyList(taxonomies, currentTaxonomy.id, data);
      setTaxonomies(newTaxonomyList);

      setEditModal(false);
      setCurrentTaxonomy(null);
      setEditTaxonomyName('');
      toast.success(t('toast.successEditTaxonomy'));
    }
  };

  const updateTaxonomyList = (list, id, newItem) => {
    return list.map(item => {
      if (item.id === id) {
        item.name = newItem.name;
      } else if (item['taxonomy-children']) {
        item['taxonomy-children'] = updateTaxonomyList(item['taxonomy-children'], id, newItem);
      }

      return item;
    });
  };

  const addNewTaxonomy = e => {
    e.preventDefault();

    let taxonomyParentId = undefined;
    if (activeColumn === 'topics') {
      taxonomyParentId = _.find(taxonomies, 'isActive')?.id;
    } else if (activeColumn === 'subTopics') {
      taxonomyParentId = _.find(topics, 'isActive')?.id;
    }

    addTaxonomy({
      name: newTaxonomyName,
      taxonomyParentId: taxonomyParentId
    }).then(({ data, error }) => {
      if (error) {
        toast.error(t('toast.errorCreateTaxonomy'));
      } else {
        if (activeColumn === 'topics') {
          setTopics([...topics, data]);
        } else if (activeColumn === 'subTopics') {
          setSubTopics([...subTopics, data]);
        } else {
          setTaxonomies([...taxonomies, data]);
        }
        setShowModal(false);
        setNewTaxonomyName('');
        toast.success(t('toast.successCreateTaxonomy'));
      }
    });
  };

  const breadCrumbs = {
    title: 'Organização da Plataforma',
    nav: [
      {
        route: uri,
        name: 'Ementa',
        isActive: true
      }
    ]
  };

  return (
    <>
      {getPermission('Visualizar lista de taxonomias, tópicos e subtópicos', 'Taxonomias') ? (
        <>
          <main className="main-content main-content--block">
            <BreadCrumbs data={breadCrumbs} />

            {/* <TabNavbar selectedTab="taxonomies" /> */}

            <div className="taxonomies">
              <div className="taxonomies__column">
                {getPermission('Criar taxonomias', 'Taxonomias') && (
                  <button
                    className="btn btn--outline btn--full"
                    onClick={() => newTaxonomyForm('taxonomies')}
                  >
                    {t('taxonomyAndMaterials.buttonAddTaxonomy')}
                  </button>
                )}

                {loading && <Loader />}

                <div className="taxonomies__list">
                  {taxonomies &&
                    taxonomies.map((taxonomy, index) => (
                      <div
                        key={index}
                        className={`taxonomies__item ${taxonomy.isActive ? 'active' : ''}`}
                        onClick={() => selectTaxonomy(taxonomy, 'taxonomies', 'topics')}
                      >
                        <span className="taxonomies__text">{taxonomy.name}</span>

                        {getPermission('Editar taxonomias', 'Taxonomias') || getPermission('Excluir taxonomias', 'Taxonomias') ? (
                          <Dropdown>
                            <Dropdown.Toggle>
                              <FiMoreHorizontal />
                            </Dropdown.Toggle>

                            <Dropdown.Content>
                              {getPermission('Editar taxonomias', 'Taxonomias') && <Dropdown.Item onClick={() => editTaxonomyForm('taxonomies', taxonomy)}>{t('button.edit')}</Dropdown.Item>}
                              {getPermission('Excluir taxonomias', 'Taxonomias') && <Dropdown.Item onClick={() => destroyTaxonomyForm('taxonomies', taxonomy)}>{t('button.delete')}</Dropdown.Item>}
                            </Dropdown.Content>
                          </Dropdown>
                        ) : null}
                      </div>
                    ))}
                </div>
              </div>

              <div className="taxonomies__column">
                {getPermission('Criar taxonomias', 'Taxonomias') && taxonomies.some(t => t.isActive) && (
                  <button
                    className="btn btn--outline btn--full"
                    onClick={() => newTaxonomyForm('topics')}
                  >
                    {t('taxonomyAndMaterials.buttonAddTaxonomy')}
                  </button>
                )}

                <div className="taxonomies__list">
                  {topics &&
                    topics.map((taxonomy, index) => (
                      <div
                        key={index}
                        className={`taxonomies__item ${taxonomy.isActive ? 'active' : ''}`}
                        onClick={() => selectTaxonomy(taxonomy, 'topics', 'subTopics')}
                      >
                        <span className="taxonomies__text">{taxonomy.name}</span>

                        <Dropdown>
                          <Dropdown.Toggle>{getPermission('Editar taxonomias', 'Taxonomias') && <FiMoreHorizontal />}</Dropdown.Toggle>

                          <Dropdown.Content>
                            <Dropdown.Item onClick={() => editTaxonomyForm('taxonomies', taxonomy)}>{t('button.edit')}</Dropdown.Item>
                            <Dropdown.Item
                                onClick={() =>
                                  destroyTaxonomyForm('taxonomies', taxonomy)
                                }
                              >
                              Excluir
                            </Dropdown.Item>
                          </Dropdown.Content>
                        </Dropdown>
                      </div>
                    ))}

                  {!loading && topics.length === 0 && (
                    <EmptyState
                      type="select"
                      text={t('emptyState.selectTopicText')}
                    />
                  )}
                </div>
              </div>

              <div className="taxonomies__column">
                {topics.some(t => t.isActive) && (
                  <button
                    className="btn btn--outline btn--full"
                    onClick={() => newTaxonomyForm('subTopics')}
                  >
                    {t('taxonomyAndMaterials.buttonAddTaxonomy')}
                  </button>
                )}

                <div className="taxonomies__list">
                  {subTopics &&
                    subTopics.map((taxonomy, index) => (
                      <div
                        key={index}
                        className="taxonomies__item"
                      >
                        <span className="taxonomies__text">{taxonomy.name}</span>

                        <Dropdown>
                          <Dropdown.Toggle>
                            <FiMoreHorizontal />
                          </Dropdown.Toggle>

                          <Dropdown.Content>
                            <Dropdown.Item onClick={() => editTaxonomyForm('taxonomies', taxonomy)}>{t('button.edit')}</Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                destroyTaxonomyForm('taxonomies', taxonomy)
                              }
                            >
                              Excluir
                            </Dropdown.Item>
                          </Dropdown.Content>
                        </Dropdown>
                      </div>
                    ))}

                  {!loading && subTopics.length === 0 && (
                    <EmptyState
                      type="select"
                      text={t('emptyState.selectSubtopicText')}
                    />
                  )}
                </div>
              </div>

              <Modal
                show={showModal}
                onClose={() => setShowModal(false)}
              >
                <form
                  method="post"
                  onSubmit={addNewTaxonomy}
                  className="form"
                >
                  <h2 className="form__title">{t('taxonomyAndMaterials.createTaxonomy')}</h2>

                  <div className="form__row">
                    <label
                      className="form__label"
                      htmlFor="classCourse"
                    >
                      {t('textsCommon.name')}
                    </label>
                    <input
                      className="form__control"
                      id="name"
                      name="name"
                      type="text"
                      placeholder={t('taxonomyAndMaterials.placeholderTaxonomyName')}
                      value={newTaxonomyName}
                      onChange={e => setNewTaxonomyName(e.target.value)}
                    />
                  </div>

                  <button
                    className="btn btn--primary"
                    type="submit"
                  >
                    {t('taxonomyAndMaterials.buttonCreateNewTaxonomy')}
                  </button>
                </form>
              </Modal>

              <Modal
                show={editModal}
                onClose={() => setEditModal(false)}
              >
                <form
                  method="patch"
                  onSubmit={editCurrentTaxonomy}
                  className="form"
                >
                  <h2 className="form__title">{t('taxonomyAndMaterials.buttonEditTaxonomy')}</h2>

                  <div className="form__row">
                    <label
                      className="form__label"
                      htmlFor="classCourse"
                    >
                      {t('textsCommon.name')}
                    </label>
                    <input
                      className="form__control"
                      id="name"
                      name="name"
                      type="text"
                      placeholder={t('taxonomyAndMaterials.placeholderNewTaxonomyName')}
                      value={editTaxonomyName}
                      onChange={e => setEditTaxonomyName(e.target.value)}
                    />
                  </div>

                  <button
                    className="btn btn--primary"
                    type="submit"
                  >
                    {t('taxonomyAndMaterials.buttonEditTaxonomy')}
                  </button>
                </form>
              </Modal>
            </div>
          </main>
        </>
      ) : (
        <Error404 />
      )}
    </>
  );
};
