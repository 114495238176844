import React, { useState, useEffect } from 'react';
import { useCourse } from 'app/hooks/useCourse';
import Modal from 'app/components/Modal';
import { useFormik } from 'formik';
import Loader from 'app/components/loader';
import { FiMoreHorizontal } from 'react-icons/fi';
import { useClient } from 'jsonapi-react';
import Dropdown from 'app/components/Dropdown';
import toast from 'react-hot-toast';
import EmptyState from 'app/components/EmptyState';
import MultiSelect from 'app/components/MultiSelect';
import ModalTaxonomies from 'app/components/modalTaxonomies';
import Swal from 'sweetalert2';
import getPermission from 'app/utils/getPermission';
import { useTranslation } from 'react-i18next';

function CourseTab() {
  const { t } = useTranslation();
  const { loading, courses, getCourses, createCourse, updateCourse, selectedCourse, changeSelectedCourse, deleteCourse } = useCourse();
  const basePath = window.env.REACT_APP_BASE_PATH;

  const [show, setShow] = useState(false);
  const client = useClient();
  const [isOpenModalTaxonomies, setIsOpenModalTaxonomies] = useState(false);
  const [taxonomies, setTaxonomies] = useState([]);

  const submitFormModal = async values => {
    if (selectedCourse) {
      updateCourse({ id: selectedCourse.id, title: values.title, 'taxonomy-ids': values.selectedTaxonomies.map(t => t.id) });
      changeSelectedCourse(null);
    } else {
      createCourse(values);
    }
  };

  const courseForm = useFormik({
    initialValues: {
      title: '',
      selectedTaxonomies: []
    },
    onSubmit: submitFormModal
  });

  const fetchTaxonomies = async () => {
    try {
      const { data } = await client.fetch('taxonomies');
      setTaxonomies(data);
    } catch (e) {
      console.error(e);
      toast.error(t('toast.errorGetTags'));
    }
  };

  useEffect(() => {
    getCourses();
    fetchTaxonomies();
  }, []);

  const editCourseForm = course => {
    changeSelectedCourse(course);
    courseForm.setValues({
      title: course.title,
      selectedTaxonomies: course.taxonomies
    });
    setShow(true);
  };

  const oncloseFormModal = () => {
    setShow(false);
    if (selectedCourse) {
      changeSelectedCourse(null);
    }
  };

  return (
    <div className="tab__pane">
      <div className="filter-bar">
        {getPermission('Criar cursos', 'Cursos, módulos e aulas') && (
          <button
            className="btn btn--wide btn--primary"
            onClick={() => setShow(true)}
          >
            {t('courses.buttonNewCourse')}
          </button>
        )}
      </div>

      {!courses.length && !loading && (
        <EmptyState
          type="data"
          title={t('emptyState.missingDataTitle')}
        />
      )}

      {loading && <Loader />}

      {!loading && courses.length > 0 && (
        <div className="round-dg-wrapper">
          <table className="round-dg round-dg--light">
            <thead className="round-dg__header">
              <tr className="round-dg__row">
                <th className="round-dg__cell-header">{t('exams.thCourse')}</th>
                <th className="round-dg__cell-header">{t('textsCommon.matters')}</th>
                <th
                  className="round-dg__cell-header"
                  style={{ width: '100px' }}
                >
                  {t('journey.thClasses')}
                </th>
                <th
                  className="round-dg__cell-header"
                  style={{ width: '66px' }}
                />
              </tr>
            </thead>
            <tbody className="round-dg__body">
              {courses?.map((c, i) => (
                <tr
                  className="round-dg__row"
                  key={i}
                >
                  <th className="round-dg__cell-header round-dg__cell-header--row">
                    <div className="card__header">
                      <h3
                        className="card__title"
                        title={c?.title}
                      >
                        {c?.title}
                      </h3>
                    </div>
                  </th>

                  <td className="round-dg__cell">
                    {c?.taxonomies?.length > 0 && (
                      <div className="card__badges card__badges--one-line">
                        {c?.taxonomies.slice(0, 3).map(t => (
                          <span
                            className="badge badge--primary"
                            key={t.id}
                            title={t.name}
                          >
                            {t.name}
                          </span>
                        ))}
                        {c?.taxonomies.length > 3 && (
                          <span
                            className="badge badge--more"
                            title={c?.taxonomies.slice(3).map(t => ` ${t.name}`)}
                          >
                            +{c?.taxonomies.length - 3}
                          </span>
                        )}
                      </div>
                    )}
                  </td>

                  <td className="round-dg__cell">{c.classrooms.length}</td>

                  {getPermission('Editar cursos', 'Cursos, módulos e aulas') || getPermission('Excluir cursos', 'Cursos, módulos e aulas') || getPermission('Gerenciar turmas', 'Turmas') ? (
                    <td className="round-dg__cell">
                      <Dropdown>
                        <Dropdown.Toggle>
                          <FiMoreHorizontal />
                        </Dropdown.Toggle>
                        <Dropdown.Content>
                          {getPermission('Gerenciar turmas', 'Turmas') && <Dropdown.Item to={`${basePath}/classrooms/${c.id}`}>{t('button.manageClasses')}</Dropdown.Item>}

                          {getPermission('Editar cursos', 'Cursos, módulos e aulas') && <Dropdown.Item onClick={() => editCourseForm(c)}>{t('button.edit')}</Dropdown.Item>}

                          {getPermission('Excluir cursos', 'Cursos, módulos e aulas') && (
                            <Dropdown.Item
                              onClick={() =>
                                Swal.fire({
                                  title: t('projects.warningDeleteCourse'),
                                  confirmButtonText: t('button.yes'),
                                  showDenyButton: true,
                                  denyButtonText: t('button.no'),
                                  showCloseButton: true
                                }).then(res => {
                                  if (res.isConfirmed) {
                                    deleteCourse(c.id);
                                  }
                                })
                              }
                            >
                              {t('button.delete')}
                            </Dropdown.Item>
                          )}
                        </Dropdown.Content>
                      </Dropdown>
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <Modal
        show={show}
        onClose={oncloseFormModal}
      >
        <form
          className="form"
          onSubmit={e => {
            e.preventDefault();
            courseForm.submitForm();
            setShow(false);
          }}
        >
          <h2 className="modal__simple-title">{selectedCourse ? t('courses.editCourse') : t('courses.createNewCourse')}</h2>
          {!selectedCourse && <p className="form__description">{t('courses.createCourseDescription')}</p>}

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="classCourse"
            >
              {t('courses.courseTitle')}
            </label>
            <input
              className="form__control"
              id="classCourse"
              name="classCourse"
              type="text"
              placeholder={t('courses.placeholderCourseTitle')}
              value={courseForm.values.title}
              onChange={e => courseForm.setFieldValue('title', e.target.value)}
            />
          </div>

          <fieldset className="form__row">
            <legend className="form__label">{t('tasks.mattersRelated')}</legend>

            <MultiSelect
              selectedTaxonomies={courseForm?.values?.selectedTaxonomies}
              setFieldValue={courseForm.setFieldValue}
              openModal={() => setIsOpenModalTaxonomies(true)}
              placeholder={t('tasks.mattersAdd')}
            />
          </fieldset>

          <button
            className="btn btn--primary"
            type="submit"
          >
            {selectedCourse ? t('courses.editCourse') : t('courses.buttonCreateCourse')}
          </button>

          {taxonomies.length > 0 && isOpenModalTaxonomies && (
            <ModalTaxonomies
              taxonomies={taxonomies}
              selectedTaxonomies={courseForm?.values?.selectedTaxonomies}
              setFieldValue={courseForm.setFieldValue}
              closeModal={() => setIsOpenModalTaxonomies(false)}
            />
          )}
        </form>
      </Modal>
    </div>
  );
}

export default CourseTab;
