import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useClient } from 'jsonapi-react';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import LiveClassesCalendar from './LiveClassesCalendar';
import LiveClassForm from './LiveClassForm';
import ViewLiveClass from './ViewLiveClass';
import getPermission from 'app/utils/getPermission';
import LiveClassSurvey from './LiveClassSurvey';
import { checkIncludeProfiles } from 'app/utils/getAuthorityLevel';
import BreadCrumbs from 'app/components/BreadCrumbs';

function LiveClasses(props) {
  const { uri } = props;

  const [relationships, setRelationships] = useState({
    courses: []
  });
  const isStudent = checkIncludeProfiles({ only: ['student'] });

  const [currentLive, setCurrentLive] = useState();
  const [showLiveClassForm, setShowLiveClassForm] = useState(false);
  const [editingLiveClass, setEditingLiveClass] = useState(false);
  const [weekDays, setWeekDays] = useState([]);

  const [notAnsweredSurveys, setNotAnsweredSurveys] = useState([]);
  const [currentSurvey, setCurrentSurvey] = useState(0);

  const { t } = useTranslation();

  const filters = useFormik({
    initialValues: {
      course: 'blank'
    }
  });

  const client = useClient();

  const getRelationships = async () => {
    const { data: courses } = await client.fetch('courses');

    setRelationships({
      courses: courses || []
    });
  };

  const closeLiveClassForm = () => {
    setShowLiveClassForm(false);
    setEditingLiveClass(false);
    setCurrentLive({});
  };

  useEffect(() => {
    getRelationships();
  }, []);

  const breadCrumbs = {
    title: 'Cursos e Aulas',
    nav: [
      {
        route: uri,
        name: 'Aulas ao vivo',
        isActive: true
      }
    ]
  };

  return (
    <main className="main-content main-content--block">
      <BreadCrumbs data={breadCrumbs} />

      <div className="filter-bar filter-bar--borderless">
        <div className="filter-bar__inner">
          <FilterSelectionBox
            blankLabel={t('filter.labelSelectCourse')}
            label={t('exams.thCourse')}
            value={filters.values.course}
            onChange={e => filters.setFieldValue('course', e.target.value)}
            options={relationships.courses.map(o => ({
              id: o.id,
              name: o.title
            }))}
          />
        </div>

        {getPermission('Criar aulas ao vivo', 'Aulas ao vivo') ? (
          <div className="filter-bar__actions">
            <button
              className="btn btn--wide btn--primary"
              onClick={() => setShowLiveClassForm(true)}
            >
              {t('liveClass.buttonCreate')}
            </button>
            <LiveClassForm
              currentLive={currentLive}
              show={showLiveClassForm}
              onClose={closeLiveClassForm}
              editingLiveClass={editingLiveClass}
              weekDays={weekDays}
              setWeekDays={setWeekDays}
            />
          </div>
        ) : null}
      </div>

      <LiveClassesCalendar
        filterCourseId={filters.values.course}
        setCurrentLive={setCurrentLive}
        currentLive={currentLive}
        setEditingLiveClass={setEditingLiveClass}
        setShowLiveClassForm={setShowLiveClassForm}
        showLiveClassForm={showLiveClassForm}
        weekDays={weekDays}
        setWeekDays={setWeekDays}
        setNotAnsweredSurveys={setNotAnsweredSurveys}
      />

      {isStudent && notAnsweredSurveys.length > 0 && notAnsweredSurveys?.[currentSurvey] && (
        <LiveClassSurvey
          setCurrentSurvey={setCurrentSurvey}
          liveClassId={notAnsweredSurveys?.[currentSurvey]?.id}
          liveClassName={notAnsweredSurveys?.[currentSurvey]?.title}
        />
      )}
    </main>
  );
}

export { LiveClasses as default, ViewLiveClass };
