import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import ExamMetricsAswerGuideListItem from './ExamMetricsAswerGuideListItem';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import Loader from 'app/components/loader';

export function AttemptCard(props) {
  const { index, attemptData } = props;

  const { t } = useTranslation();
  const client = useClient();
  const [active, setActive] = useState(false);
  const [studentAnswers, setStudentAnswers] = useState();
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const currentTime = Date.now();
  const finishDateMillis = new Date(attemptData['finished-at']).getTime();

  const getStudentAnswers = async () => {
    const { data, error } = await client.fetch(`question_books_users/${attemptData.id}/answers`);
    if (error) {
      if (error?.id === 'base') {
        toast.error(error?.title);
      } else {
        toast.error('Erro ao obter informações desta prova'); //default
      }
    } else {
      setStudentAnswers(data);
    }
  };

  const getQuestions = async () => {
    const { data, error } = await client.fetch(`questions?filter[question_book_id]=${attemptData['question-book-id']}`);
    if (error) {
      toast.error('Erro ao buscar alternativas');
    } else {
      setQuestions(data);
    }
  };

  const handleGetStudentAnswers = async () => {
    if (!active) {
      Promise.all([getStudentAnswers(), getQuestions()]).finally(() => setIsLoading(false));
    }

    setActive(prev => !prev);
  };

  return (
    <section
      className="container-attempt-card"
      style={{ paddingBottom: active ? '20px' : '0' }}
    >
      <div
        className="attempt-card u-cursor-pointer"
        onClick={handleGetStudentAnswers}
      >
        <span className="attempt-title">{attemptData.attempt}º Tentativa</span>
        <span className="text-md">{moment(attemptData?.['finished-at']).format('DD/MM/YYYY')}</span>
        <span className="text-md">
          Nota <b className="text-primary">{attemptData?.score && !isNaN(parseFloat(attemptData.score).toFixed(2)) && attemptData.done ? parseFloat(attemptData.score).toFixed(2) : '--'}</b>

        </span>

        {index === 0 ? (
          currentTime < finishDateMillis ? (
            <div className="badge badge--tiny badge--warning">{t('exams.statusInAnalysis')}</div>
          ) : attemptData.status === 'approved' ? (
            <div className="badge badge--tiny badge--success">{t('exams.statusInApproved')}</div>
          ) : attemptData.status === 'disapproved' ? (
            <div className="badge badge--tiny badge--danger">{t('exams.statusInDisapproved')}</div>
          ) : null
        ) : (
          <div style={{ width: '68px' }}></div>
        )}

        {active ? (
          <FiChevronUp
            className="card__icon"
            size={20}
          />
        ) : (
          <FiChevronDown
            className="card__icon"
            size={20}
          />
        )}
      </div>

      {active && (
        <div className="border-t">
          <div className="mt-20">
            {isLoading ? (
              <Loader />
            ) : (
              <>
                <tr className="border-t">
                  <th className="round-dg__cell-header font-bold">{t('exams.tableHeaderQuestionId')}</th>
                  <th className="round-dg__cell-header font-bold">{t('exams.tableHeaderAnswerKey')}</th>
                  <th className="round-dg__cell-header font-bold">{t('exams.tableHeaderStudentResponse')}</th>
                  <th className="round-dg__cell-header font-bold">{t('exams.tableHeaderStudentResult')}</th>
                  <th className="round-dg__cell-header font-bold">{t('exams.tableHeaderOverallPerformace')}</th>
                </tr>

                {studentAnswers?.['with-alternatives'].map(item => {
                  const currentQuestion = questions?.find(q => q.id === item['question_id']);
                  return (
                    <ExamMetricsAswerGuideListItem
                      key={item.id}
                      studentAnswers={item}
                      currentQuestion={currentQuestion}
                      feedback={item?.student_alternative.name === '3' ? 'blank' : item?.correct_alternative?.id === item?.student_alternative?.id ? 'positive' : 'negative'}
                    />
                  );
                })}
              </>
            )}
          </div>
        </div>
      )}
    </section>
  );
}
