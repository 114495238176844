import React, { useState, useEffect } from 'react';
import Progress from '../Progress';
import SimulatedClock from '../../components/SimulatedClock';
import _ from 'lodash';

function Simulated(props) {
  
  const {
    enabledClock,
    selectQuestionBookTaxonomy,
    questionBooksUser,
    questionBook,
    activeGroupedQuestions,
    lastAnswerSubmited,
    finishOpen,
  } = props;

  
  const [openProgress, setOpenProgress] = useState(0);

  useEffect(() => {
    calculateOpenProgress();
  }, [lastAnswerSubmited]);


  const calculateOpenProgress = () => {
    if (questionBook) {
      const totalQuestions = _.reduce(
        _.map(questionBook.taxonomies, 'questionCount'),
        (a, b) => a + b,
      );
      const totalAnswered = _.reduce(
        _.map(questionBook.taxonomies, t => t.answers?.length || 0),
        (a, b) => a + b,
      );
      const percentage = (totalAnswered * 100) / totalQuestions;
      setOpenProgress(percentage.toFixed(1));
    }
  };

  return (
    <div className="simulated">
      <div className="simulated__section">
        <div className="simulated__header">
          {enabledClock && (
            <SimulatedClock
              questionBooksUser={questionBooksUser}
              questionBook={questionBook}
              finishOpen={finishOpen}
            />
          )}

          <div className="simulated__container">
            {enabledClock ? (
              <h3 className="simulated__notebook">Open</h3>
            ) : (
              <h3 className="simulated__notebook">
                Caderno de respostas
                <br />
                Open
              </h3>
            )}
            <p className="simulated__teacher">Estudologia</p>
          </div>
        </div>
      </div>

      {enabledClock && (
        <div className="simulated__progress">
          <Progress valueNow={openProgress} />
        </div>
      )}

      {questionBook &&
        questionBook.taxonomies.map((taxonomy, index) => (
          <div
            className={`simulated__questions ${
              activeGroupedQuestions === index ? 'is-selected' : ''
            }`}
            key={index}
            onClick={() => selectQuestionBookTaxonomy(index)}
          >
            <div className="simulated__questions-name" title={taxonomy.name}>
              {taxonomy.name}
            </div>
            <div className="simulated__count">
              {taxonomy.answers.length}/{taxonomy.questionCount}
            </div>
          </div>
        ))}
    </div>
  );
}

export default Simulated;
