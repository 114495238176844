import React from 'react';
import { Link } from '@reach/router';
import moment from 'moment';

export default function LessonCard({ data, moduleId }) {
  const duration = moment.utc(data.duration * 1000).format('HH:mm:ss');
  const basePath = window.env.REACT_APP_BASE_PATH;

  return (
    <tr className="round-dg__row">
      <th className="round-dg__cell-header round-dg__cell-header--row">
        <div className="round-dg__user">
          <div className="round-dg__user-inner">
            <h3
              className="round-dg__title"
              title={data.body}
            >
              {data.body}
            </h3>
          </div>
        </div>
      </th>

      <td className="round-dg__cell">{data.discipline_name}</td>

      <td className="round-dg__cell">{data['learning-system-files-count']}</td>

      {/* TODO: Integrar plano de aula quando estiver pronto. */}
      {/* <td className="round-dg__cell">Plano de aula</td> */}

      <td className="round-dg__cell">{duration}</td>

      <td className="round-dg__cell">
        <Link
          className="btn btn--full btn--tiny btn--outline"
          to={`${basePath}/show-course/${moduleId}/classroom`}
        >
          Assistir aulas
        </Link>
      </td>
    </tr>
  );
}
