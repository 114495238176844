import React, { useState, useEffect } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { TfiMedall } from 'react-icons/tfi';
import { Link, useLocation, useParams } from '@reach/router';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import CourseCover from 'app/images/placeholders/show-course.jpg';
import ClassModuleItem from 'app/screens/LmsStudent/ClassModuleItem';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import moreMedal from 'app/images/more-medal.svg';
import TabNavBar from './TabNavBar';
import TabFiles from './TabFiles';
import TabActivities from './TabActivities';
import TabBooklet from './TabBooklet';
import { useSession } from 'app/hooks/useSession';
import Loader from 'app/components/loader';
import { t } from 'i18next';
import { navigate } from '@reach/router';
import getPermission from 'app/utils/getPermission';
import TabLessons from './TabLessons';

export default function ViewModule(props) {
  const { moduleId } = props;
  const basePath = window.env.REACT_APP_BASE_PATH;

  const [currentModule, setCurrentModule] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState('aulas');

  const client = useClient();
  const { session } = useSession();

  const authorityLevel = getAuthorityLevel();
  const userId = session?.user?.id;

  const getCurrentModule = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`/learning_systems/${moduleId}?detailed=true`);
    if (error) {
      toast.error('Falha ao carregar módulo');
    } else {
      setCurrentModule(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCurrentModule();
  }, []);

  const downloadCertificate = async () => {
    window.open(`${window.env.REACT_APP_DEV_URL}//user_certificates/download/${currentModule?.['user-certificate-hash-id']}.pdf`, '_blank');
  };

  const isModuleEnabled = currentModule?.['learning-system-requirements']?.every(item => item.finished);
  return loading ? (
    <Loader />
  ) : (
    <div className="course-about__module">
      <div
        className="show-course-banner u-mb-4"
        style={{
          backgroundImage: `url(${currentModule?.image?.url || CourseCover})`,
          minHeight: '132px'
        }}
      >
        <div className="show-course-banner__inner">
          <div className="show-course-banner__container">
            {authorityLevel === 'student' && !currentModule?.['learning-system-users']?.find(item => item?.['user-id'] === parseInt(userId)) && <span className="badge badge--tiny badge--warning">{t('lms.tagNew')}</span>}
            {currentModule?.progress === 100 && <span className="badge badge--tiny badge--success">Concluído</span>}
            <div className="show-course-banner__title">{currentModule?.title}</div>
            {authorityLevel === 'student' && (
              <div className="card__progress card__progress--cover u-mb-0">
                <div className="card__progress-container">
                  <div
                    className="card__progress-bar"
                    style={{ width: `${currentModule?.progress ? parseInt(currentModule?.progress) : 0}%` }}
                  />
                </div>
                <div className="card__progress-text">{currentModule?.progress ? parseInt(currentModule?.progress) : 0}%</div>
              </div>
            )}
            {authorityLevel === 'student' && isModuleEnabled ? (
              <div className="show-course-banner__cta">
                <Link
                  className="btn btn--wide btn--outline"
                  to={`${basePath}/show-course/${currentModule?.id}/classroom`}
                >
                  {currentModule.progress === 100 ? 'Visualizar conteúdo' : currentModule?.['learning-system-users']?.find(item => item?.['user-id'] === parseInt(userId)) ? t('lms.buttonContinue') : t('lms.buttonStartModule')}
                </Link>
                {currentModule?.['user-certificate-hash-id'] ? (
                  <button
                    className="btn btn--wide btn--outline-secondary"
                    onClick={() => {
                      downloadCertificate();
                    }}
                  >
                    {t('button.certification')}
                  </button>
                ) : null}
              </div>
            ) : null}
          </div>

          {currentModule?.progress === 100 && (
            <div className="medal">
              <img
                src={moreMedal}
                alt="nova medalha"
                className="medal__img"
              />
              <span className="medal__value">
                <TfiMedall />
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="u-mb-4">
        <TabNavBar
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          questionbooksCount={currentModule?.['learning-system-question-books']?.length}
        />
      </div>

      {selectedTab === 'aulas' && <TabLessons currentModule={currentModule} />}
      {selectedTab === 'arquivos' && <TabFiles currentModule={currentModule} />}
      {selectedTab === 'atividades' && <TabActivities currentModule={currentModule} />}
      {selectedTab === 'apostilas' && <TabBooklet currentModule={currentModule} />}
    </div>
  );
}
