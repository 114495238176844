import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import EmptyState from 'app/components/EmptyState';
import StudyBlock from './StudyBlock';
import StudyPlanCard from './StudyPlanCard';
import RadarChart from './RadarChart';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import BarChart from './BarChart';
import ViewStudyPlan from './ViewStudyPlan';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import Loader from 'app/components/loader';
import { useSession } from 'app/hooks/useSession';
import Onboarding from 'app/components/Onboarding';
import { navigate } from '@reach/router';
import BreadCrumbs from 'app/components/BreadCrumbs';

function StudyPlan(props) {
  const { t } = useTranslation();
  const [radarScore, setRadarScore] = useState();
  const [studyPlans, setStudyPlans] = useState();
  const [selectedMatterId, setSelectedMatterId] = useState('');
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [loading, setLoading] = useState(true);
  const client = useClient();
  const { session } = useSession();
  const { path } = props;
  const profiles = session?.user?.profileNames || (session?.user && session?.user['profile-names']) || [];
  const basePath = window.env.REACT_APP_BASE_PATH;

  const getRadarScore = async () => {
    try {
      const { data } = await client.fetch('/reports/study_plans/taxonomies');
      setRadarScore(data);
    } catch {
      toast.error(t('Erro ao buscar matérias'));
    }
  };

  const getStudyPlans = async () => {
    try {
      const { data } = await client.fetch('/study_plans');
      setStudyPlans(data);
      setLoading(false);
    } catch {
      toast.error(t('Erro ao buscar plano de estudos'));
    }
  };

  useEffect(() => {
    getRadarScore();
    getStudyPlans();
  }, []);

  const filters = useFormik({
    initialValues: {
      matter: 'blank'
    }
  });

  const handeFilterSelectionBox = e => {
    const selectedId = e.target.value;
    filters.setFieldValue('matter', selectedId);
    setSelectedMatterId(selectedId);
  };

  const breadCrumbs = {
    title: 'Aprendizado Dinâmico',
    nav: [
      {
        route: '/plano-de-estudos',
        name: 'Guia de estudos',
        isActive: true
      }
    ]
  };

  return (
    <>
      <main className="main-content main-content--block">
        {radarScore?.['chart-data'].length === 0 ? (
          <EmptyState
            cta={{ text: 'Simulados', onClick: () => navigate(`${basePath}/simulados`) }}
            type="content"
            title={t('emptyState.titleNoSimulation')}
            text="Responda ao simulado agora e desbloqueie seu plano de estudos personalizado! Não deixe para depois, seu futuro espera!"
          />
        ) : studyPlans?.length === 0 ? (
          <EmptyState
            type="content"
            title={t('emptyState.titleSimulationResultNotAvailable')}
            text={t('emptyState.textSimulationResultNotAvailable')}
          />
        ) : studyPlans && radarScore?.['chart-data'].length > 0 ? (
          <>
            <BreadCrumbs data={breadCrumbs} />

            <h3 className="widget__title u-mb-2">{t('studyPlan.select')}</h3>
            {loading && <Loader />}
            <div
              className="tab__cards u-mb-5"
              style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))', maxHeight: '290px', overflowY: 'auto', paddingRight: '16px' }}
            >
              {!loading &&
                studyPlans.map((plan, i) => (
                  <StudyPlanCard
                    plan={plan}
                    title={`${t('studyPlan.studyPlan')} #${i + 1}`}
                    key={i}
                  />
                ))}
            </div>

            <div className="widget">
              <div className="widget__header">
                <h3 className="widget__title">{t('studyPlan.performaceExamsTitle')}</h3>
              </div>

              <div className="widget__body">
                <div
                  className="page page--wrap"
                  style={{ minHeight: '488px' }}
                >
                  <div
                    className="page__col"
                    style={{ alignSelf: 'center' }}
                  >
                    <RadarChart radarScore={{ ...radarScore, 'chart-data': radarScore?.['chart-data']?.slice(0, 3) }} />
                  </div>
                  <div className="page__col">
                    <div className="filter-bar filter-bar--borderless">
                      <div className="filter-bar__inner">
                        <FilterSelectionBox
                          label={t('textsCommon.matters')}
                          blankLabel={t('filter.selectSubject')}
                          value={filters.values.matter}
                          onChange={handeFilterSelectionBox}
                          options={radarScore?.['chart-data'].reduce((accumulator, item) => {
                            item.data.forEach(data => {
                              const existingObject = accumulator.find(obj => obj.id === data.id);
                              if (!existingObject) {
                                accumulator.push({
                                  id: data.id,
                                  title: data.name
                                });
                              }
                            });
                            return accumulator;
                          }, [])}
                        />
                      </div>
                    </div>
                    {filters.values.matter === 'blank' ? (
                      <EmptyState
                        type="select"
                        text={t('emptyState.selectMatterText')}
                        bgless
                      />
                    ) : (
                      <BarChart
                        matterId={selectedMatterId}
                        client={client}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </main>

      <Onboarding
        show={showOnboarding}
        setShowOnboarding={setShowOnboarding}
        onClose={() => setShowOnboarding(false)}
        path={path}
        profiles={profiles}
        title={t('onboarding.titleStudyPlan')}
      />
    </>
  );
}
export { StudyPlan as default, StudyBlock, ViewStudyPlan };
