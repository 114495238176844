import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import { t } from 'i18next';
import { useClient } from 'jsonapi-react';
import { useEffect } from 'react';
import { useState } from 'react';
import toast from 'react-hot-toast';

const StepDuration = props => {
  const { step, form, moduleId, bondLesson, setBondLesson } = props;
  const hours = [0.5, 1.0, 1.5, 2.0, 2.5, 3.0];
  const client = useClient();
  const [lessons, setLessons] = useState([]);

  const fetchLessons = async () => {
    const { data, error } = await client.fetch(`/learning_system_items?simple=true&filter[learning_systems.id]=${moduleId}`);
    if (error) {
      toast.error('Erro ao carregar aulas');
    } else {
      setLessons(
        data.map(item => {
          return { id: item.id, name: item.body };
        })
      );
    }
  };

  useEffect(() => {
    fetchLessons();
  }, []);

  // useEffect(() => {
  //   if (bondLesson === false) {
  //     form.values?.['learning-system-activities-attributes'].shift();
  //   }
  // }, [bondLesson]);

  return (
    step === 'prazo' && (
      <div className="page__content fadeIn u-mb-5">
        <div className="new-questions__header">
          <h2 className="new-questions__title">{form?.values?.title || t('textsCommon.newActivity')}</h2>
        </div>
        <p className="new-questions__subtitle">{t('activity.periodDescription')}</p>
        <div className="form__row form__row--columns">
          <label
            className="form__label"
            htmlFor="startDate"
          >
            *{t('activity.periodName')}
          </label>
          <div className="form__col">
            <input
              className="form__control"
              id="publish_at"
              name="publish_at"
              type="date"
              onChange={e => form.setFieldValue('publish_at', e.target.value)}
              value={form?.values?.publish_at}
            />
            {form?.touched?.publish_at && form?.errors?.publish_at && <span style={{ color: 'red' }}>{t('warning.requiredField')}</span>}
          </div>

          {/* <div className="form__col">
            <input
              className="form__control"
              id="finish_at"
              name="finish_at"
              type="date"
              onChange={e => form?.setFieldValue('finish_at', e.target.value)}
              value={form?.values?.finish_at}
            />
            {form?.touched?.finish_at && form?.errors?.finish_at && <span style={{ color: 'red' }}>{t('warning.requiredField')}</span>}
          </div> */}
        </div>
        <div className="form__col">
          <label
            htmlFor="hoursClasses"
            className="form__label"
          >
            {t('activity.hoursLesson')}
          </label>
          <select
            style={{ width: '120px' }}
            className="form__select"
            name="class_hours"
            id="class_hours"
            aria-label="Hora início"
            onChange={form?.handleChange}
            value={form?.values?.class_hours}
          >
            {hours.map((hour, i) => (
              <option
                key={i}
                value={hour}
              >
                {hour === 1 ? `${hour} ${t('tasks.hour')}` : `${hour} ${t('tasks.hours')}`}
              </option>
            ))}
          </select>
        </div>

        <fieldset className="form__row">
          <div className="form__check form__check--inline form__switch">
            <input
              className="form__check-input"
              id="bondLesson"
              type="checkbox"
              checked={bondLesson}
              onChange={() => setBondLesson(prevState => !prevState)}
            />
            <label htmlFor="publish">{t('activity.mandatoryActivity')}</label>
          </div>
        </fieldset>

        {bondLesson ? (
          <fieldset className="form__row">
            <label
              className="form__label"
              htmlFor="startDate"
            >
              {t('activity.associateToLesson')}
            </label>
            <FilterSelectionBox
              blankLabel={t('activity.selectLesson')}
              options={lessons}
              onChange={e => {
                form.setFieldValue('learning-system-activities-attributes.[0].learning_system_item_id', e.target.value), form.setFieldValue('learning-system-activities-attributes.[0].is_required', true);
              }}
              value={form?.values?.['learning-system-activities-attributes']?.[0]?.['learning_system_item_id']}
            />
          </fieldset>
        ) : null}

        <span className="form__text">
          <strong>* {t('warning.requiredField')}</strong>
        </span>
      </div>
    )
  );
};

export default StepDuration;
