import React, { useState, useEffect } from 'react';
import Modal from 'app/components/Modal';
import EmptyState from 'app/components/EmptyState';
import Loader from 'app/components/loader';
import ActivityCard from './ActivityCard';
import { useSession } from 'app/hooks/useSession';
import { useClient } from 'jsonapi-react';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import toast from 'react-hot-toast';
import ActivityForm from './ActivityForm';
import Onboarding from 'app/components/Onboarding';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { FilterCheckBox } from 'app/components/FilterCheckBox';
import BreadCrumbs from 'app/components/BreadCrumbs';

export function ComplementaryActivity(props) {
  const { uri } = props;

  const { t } = useTranslation();
  const { session } = useSession();
  const profiles = session?.user?.profileNames || (session?.user && session?.user['profile-names']) || [];
  const path = props.path;
  const client = useClient();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activities, setActivities] = useState([]);
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [editingActivity, setEditingActivity] = useState();
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const authorityLevel = getAuthorityLevel();
  const [relationships, setRelationships] = useState({
    courses: [],
    classrooms: []
  });

  const filters = useFormik({
    initialValues: {
      course: 'blank',
      classroom: 'blank',
      'activity-status': false
    }
  });

  const getRelationships = async () => {
    const { data: courses } = await client.fetch('courses');
    const { data: classrooms } = await client.fetch('classrooms');

    setRelationships({
      courses: courses || [],
      classrooms: classrooms || []
    });
  };

  const getActivities = async () => {
    setLoading(true);
    const { data, error } = await client.fetch('activities');
    if (error) {
      toast.error('Erro ao buscar atividades complementares.');
    } else {
      setActivities(data);
      setLoading(false);
    }
  };

  const deleteActivity = async id => {
    setLoading(true);
    const { error } = await client.delete(['activities', id]);
    if (error) {
      toast.error(t('toast.errorDeleteActivity'));
    } else {
      getActivities();
      toast.success(t('toast.successDeleteActivity'));
      setLoading(false);
    }
  };

  const newActivity = () => {
    setEditingActivity({});
    setShowModal(true);
  };

  const editActivity = activity => {
    setEditingActivity(activity);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setEditingActivity(null);
  };

  function filterActivitiesByCourse(id, activities) {
    return activities.filter(function (activity) {
      return activity['course-id'] === id;
    });
  }

  function filterActivitiesByClassroom(id, activities) {
    return activities.filter(function (activity) {
      return activity['classroom-id'] === id;
    });
  }

  useEffect(() => {
    getActivities();
    getRelationships();
  }, []);

  useEffect(() => {
    let newActivities = activities;
    if (filters.values.course !== 'blank') {
      const id = +filters.values.course;
      newActivities = filterActivitiesByCourse(id, activities);
    }
    if (filters.values.classroom !== 'blank') {
      const id = +filters.values.classroom;
      newActivities = filterActivitiesByClassroom(id, activities);
    }
    if (searchTerm !== '') {
      newActivities = newActivities.filter(a => a.title != null && a.title.toLowerCase().includes(searchTerm.toLocaleLowerCase()));
    }
    if (filters.values['activity-status']) {
      newActivities = newActivities.filter(activity => activity['activity-status-or-submitted-count'] !== null);
    }
    setFilteredActivities(newActivities);
  }, [filters.values, searchTerm, activities]);

  const breadCrumbs = {
    title: 'Atividades',
    nav: [
      {
        route: uri,
        name: 'Atividade Complementar',
        isActive: true
      }
    ]
  };

  return (
    <>
      <main className="main-content main-content--block fadeIn">
        <BreadCrumbs data={breadCrumbs} />

        <div className="filter-bar filter-bar--left">
          <div className="filter-bar__row">
            <label
              htmlFor="activitySearch"
              className="filter-bar__label"
            >
              {t('materials.search')}
            </label>
            <input
              className="form__control form__control--search-with-icon"
              placeholder={t('filter.searchActivity')}
              type="search"
              name="activitySearch"
              id="activitySearch"
              onChange={e => setSearchTerm(e.target.value)}
            />
          </div>

          <FilterSelectionBox
            label={t('filter.labelSelectCourse')}
            value={filters.values.course}
            onChange={e => {
              filters.setFieldValue('course', e.target.value);
              filters.setFieldValue('classroom', 'blank');
            }}
            options={relationships.courses.map(c => ({
              ...c,
              name: c.title
            }))}
          />

          <FilterSelectionBox
            label={t('filter.labelSelectClassroom')}
            value={filters.values.classroom}
            onChange={e => {
              filters.setFieldValue('classroom', e.target.value);
              filters.setFieldValue('course', 'blank');
            }}
            options={relationships.classrooms.map(c => ({
              ...c,
              name: c.title
            }))}
          />

          <FilterCheckBox
            label={t('filter.pendingEvaluation')}
            value={filters.values['activity-status']}
            onChange={() => filters.setFieldValue('activity-status', !filters.values['activity-status'])}
          />
        </div>

        {loading && <Loader />}

        {!loading && !filteredActivities.length && <EmptyState type="data" />}

        {!loading && filteredActivities.length > 0 && (
          <div className="tab__cards">
            {filteredActivities.map((activity, i) => (
              <ActivityCard
                admin={true}
                authorityLevel={authorityLevel}
                key={i}
                activity={activity}
                editActivity={() => editActivity(activity)}
                onDelete={() => deleteActivity(activity.id)}
              />
            ))}
          </div>
        )}

        <Modal
          show={showModal}
          onClose={() => setShowModal(false)}
        >
          <ActivityForm
            onClose={() => setShowModal(false)}
            editingActivity={editingActivity}
            activities={activities}
            setActivities={setActivities}
          />
        </Modal>

        <Onboarding
          path={path}
          show={showOnboarding}
          setShowOnboarding={setShowOnboarding}
          onClose={() => setShowOnboarding(false)}
          profiles={profiles}
          title={t('onboarding.titleActivities')}
          videoId="l-9Am7IbsEY"
        />
      </main>
    </>
  );
}

export * from './ActivitySingle';
export * from './ActivityEvaluationView';
export * from './ActivityEvaluationArchive';
export * from './ActivityEvaluation';
export * from './ActivityData';
export * from './ViewActivity';
