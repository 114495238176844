import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiMoreHorizontal } from 'react-icons/fi';
import Dropdown from 'app/components/Dropdown';
import Avatar from 'app/components/Avatar';
import { useNote } from 'app/hooks/useNote';
import { useClient } from 'jsonapi-react';
import { toast } from 'react-hot-toast';
import Modal from 'app/components/Modal';
import Swal from 'sweetalert2';
import { useSession } from 'app/hooks/useSession';
import { checkIncludeProfiles } from 'app/utils/getAuthorityLevel';
import getPermission from 'app/utils/getPermission';

export default function NoteCard(props) {
  const { note, index, activeNote, setActiveNote, user } = props;
  const [editModal, setEditModal] = useState(false);
  const [editNoteTitle, setEditNoteTitle] = useState('');
  const client = useClient();
  const { t } = useTranslation();
  const { session } = useSession();
  const isAdmin = !checkIncludeProfiles({ only: ['student'] });

  const { deleteNote, getNotes, activeTab } = useNote();

  const isAuthUser = session.user.id === user.id;

  const isMarked = note.bookmarked;

  const importantClassroom = () => {
    if (activeTab === 'anotacoes-importantes') {
      return true;
    }
  };

  const editNoteForm = note => {
    setActiveNote(note);
    setEditNoteTitle(note.title);
    setEditModal(true);
  };

  const editCurrentNote = async e => {
    e.preventDefault();

    const { data, error } = await client.mutate(['notes', activeNote.id], {
      title: editNoteTitle
    });
    if (error) {
      toast.error('Anotação não pode ser editada!');
    } else {
      setActiveNote({ ...activeNote, title: data.title });
      let newListEdit = note;
      newListEdit.title = data.title;
      toast.success('Anotação editada!');
    }
    setEditModal(false);
    setActiveNote(null);
    setEditNoteTitle('');
    getNotes(note?.classroom);
  };

  async function handleViewNote(note) {
    if (note?.new_note) {
      const { data } = await client.fetch(['notes', note.id], {
        new_note: false
      });
      getNotes(note?.classroom);
    }
  }

  const confirmDeleteNote = note => {
    Swal.fire({
      title: 'Deseja mesmo excluir esta anotação?',
      text: 'Esta ação não poderá ser desfeita',
      confirmButtonText: 'Sim',
      showDenyButton: true,
      denyButtonText: 'Não',
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        deleteNote(note, note.classroom).catch(error => {
          console.error(error);
        });
      }
    });
  };

  return (
    <div
      onClick={() => {
        handleViewNote(note);
      }}
    >
      <Modal
        show={editModal}
        onClose={() => {
          setEditModal(false);
        }}
      >
        <form
          method="patch"
          onSubmit={editCurrentNote}
          className="form"
        >
          <h2 className="modal__simple-title">Editar anotação</h2>

          <div className="form__row">
            <label
              className="form__label"
              htmlFor="note"
            >
              {t('textsCommon.name')}
            </label>

            <input
              className="form__control"
              id="note"
              name="note"
              type="text"
              placeholder="Digite o nome da anotação"
              value={editNoteTitle}
              onChange={e => setEditNoteTitle(e.target.value)}
            />
          </div>

          <button
            className="btn btn--wide btn--primary"
            type="submit"
          >
            Salvar
          </button>
        </form>
      </Modal>

      <button
        key={`${note}-${index}`}
        className={`notes__btn ${activeNote?.id === note?.id ? 'active' : ''}`}
        onClick={() => setActiveNote(note)}
      >
        <span className="notes__btn-header">
          <span className="notes__btn-date">
            {t('projects.updatedIn')} {note['updated_at']}
          </span>
          {(isAuthUser || isAdmin) && (getPermission('Editar anotações', 'Anotações') || getPermission('Excluir anotações', 'Anotações')) && (
            <Dropdown>
              <Dropdown.Toggle>
                <span className="card__icon-wrapper">
                  <FiMoreHorizontal className="card__icon" />
                </span>
              </Dropdown.Toggle>
              <Dropdown.Content>
                {getPermission('Editar anotações', 'Anotações') && <Dropdown.Item onClick={() => editNoteForm(note)}>{'Editar nome'}</Dropdown.Item>}
                {getPermission('Excluir anotações', 'Anotações') && <Dropdown.Item onClick={() => confirmDeleteNote(note)}>{t('button.delete')}</Dropdown.Item>}
              </Dropdown.Content>
            </Dropdown>
          )}

          {isMarked && (
            <svg
              className="notes__tag"
              width="10"
              height="15"
              viewBox="0 0 10 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 10.75V1C0 0.447715 0.447716 0 1 0H9C9.55229 0 10 0.447715 10 1V10.75C10 11.0648 9.85181 11.3611 9.6 11.55L5.6 14.55C5.24444 14.8167 4.75556 14.8167 4.4 14.55L0.4 11.55C0.148194 11.3611 0 11.0648 0 10.75Z"
                fill="#FDB462"
              />
            </svg>
          )}
        </span>
        <span className="notes__btn-body">
          <span className="notes__btn-text">{note.title}</span>

          {note?.new_note && <span className="badge badge--tiny badge--warning">Nova</span>}
          {importantClassroom() && <span className="expanded-calendar__tag expanded-calendar__tag--class">{note.classroom.title}</span>}
        </span>

        <span className="notes__btn-footer">
          <span className="notes__author">
            <Avatar
              src={user?.image}
              alt={user?.name}
              style={{ width: '24px', height: '24px' }}
            />
            <span className="notes__author-name">{user?.name}</span>
          </span>
        </span>
      </button>
    </div>
  );
}
