import React, { useState } from 'react';
import { FiChevronDown, FiHeadphones, FiHelpCircle } from 'react-icons/fi';
import { Link } from '@reach/router';
import help from './img/help.svg';

export default function HelpSubmenu() {
  const [isExpanded, setIsExpanded] = useState(false);

  const appName = window.env.REACT_APP_NAME;

  const platformSupportUrl = window.env.REACT_APP_SUPPORT_LINK;
  let supportUrl;

  switch (appName) {
    case 'maiseducacao':
      supportUrl = 'https://suportemaisformacao.zendesk.com/hc/pt-br/requests/new';
      break;
    case 'canaleducacao':
      supportUrl = 'https://suportecanaleducacao.zendesk.com/hc/pt-br/requests/new';
      break;
    case 'egepi':
      supportUrl = 'https://escoladegoverno.zendesk.com/hc/pt-br/requests/new';
      break;
    default:
      supportUrl = 'https://wa.me/5511978432586';
  }

  const handleShowSubmenu = e => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  return (
    <li className={!isExpanded ? 'menu__item menu__item--has-submenu' : 'menu__item menu__item--has-submenu menu__item--is-expanded'}>
      <span
        className="menu__link"
        onClick={handleShowSubmenu}
      >
        <FiHelpCircle className="menu__icon" /> <span className="menu__label">Precisa de ajuda?</span> <FiChevronDown className="menu__arrow" />
      </span>

      <div className="submenu">
        <div className="submenu__header">
          <img
            className="submenu__img"
            src={help}
            alt=""
          />
          <h3 className="submenu__title">Precisa de ajuda?</h3>
          <p className="submenu__subtitle">Nosso time está pronto para te auxiliar </p>
        </div>

        <div className="submenu__body">
          <nav className="submenu__nav">
            <ul className="submenu__list">
              {platformSupportUrl && (
                <li className="submenu__item">
                  <a
                    className="submenu__link"
                    href={platformSupportUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FiHelpCircle className="submenu__icon" />
                    <span className="submenu__link-content">
                      <span className="submenu__link-title">Perguntas frequentes</span>
                      <span className="submenu__link-description">Canal de consulta e manuais de uso da plataforma</span>
                    </span>
                  </a>
                </li>
              )}
              <li className="submenu__item">
                <a
                  className="submenu__link"
                  href={supportUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FiHeadphones className="submenu__icon" />
                  <span className="submenu__link-content">
                    <span className="submenu__link-title">Suporte</span>
                    <span className="submenu__link-description">Fale conosco</span>
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </li>
  );
}
