import React, { useCallback, useEffect, useState } from "react"
import ReactFlow, { useNodesState, useEdgesState, Background, useReactFlow } from "reactflow"
import { stratify, tree } from "d3-hierarchy"
import { dataToNodeAndEdge } from "../utils/dataToNodeAndEdge"

import "reactflow/dist/style.css"
import Node from './Node'

const nodeType = { customNode: Node }

const HierarchyFlow = ({ nodes, edges }) => {

    const { fitView, getViewport, project } = useReactFlow()

    const [initialNodes, setNodes, onNodesChange] = useNodesState(nodes)
    const [initialEdges, setEdges, onEdgesChange] = useEdgesState(edges)
    const [loading, setLoading] = useState(false)

    const generateLayout = () => {
        if (initialNodes.length > 0) {
            const hierarchy = stratify()
                .id((node) => node.id)
                .parentId((node) =>
                    initialEdges.find((edge) => edge.target === node.id)?.source
                )

            const root = hierarchy(nodes)
            const layout = tree().nodeSize([201 * 1.5, 106 * 1.5]).separation(() => 1)(root)

            const updatedNodes = layout.descendants().map((node) => ({
                ...node.data,
                position: { x: node.x, y: node.y },
            }))

            setNodes(updatedNodes)
        }
        fitView()
        setLoading(false)
    }

    useEffect(() => {
        generateLayout()
        fitView()
    }, [])

    return (
        <ReactFlow nodeTypes={nodeType} nodes={initialNodes} edges={initialEdges} fitView >
            <Background />
        </ReactFlow>
    )
}

export default HierarchyFlow
