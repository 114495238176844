import React, { useEffect, useRef, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { t } from 'i18next';
import { useFormik } from 'formik';
import Select from 'react-select';
import { SimpleModal } from 'app/components/Modal';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import EmptyState from 'app/components/EmptyState';
import Loader from '../loader';
import getIcon from 'app/helpers/getIcon';
import { useClient } from 'jsonapi-react';
import moment from 'moment';
import { navigate } from '@reach/router';
import { useNote } from 'app/hooks/useNote';
import getPermission from 'app/utils/getPermission';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import { usePlatformAbilities } from 'app/hooks/usePlatformAbilities';

export default function Search() {
  const client = useClient();
  const timerRef = useRef(null);
  const authorityLevel = getAuthorityLevel();
  const { abilities } = usePlatformAbilities();
  const { showNotes, setShowNotes, setNotesModalOpen, setActiveNote } = useNote();
  const isStudent = authorityLevel === 'student';

  const [isOpen, setIsOpen] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const filters = useFormik({
    initialValues: {
      updated: 'ever',
      value: '',
      except: []
    }
  });

  const isEmptyField = filters.values.value === '';

  const lastUpdateOptions = [
    { title: 'Hoje', id: 'today' },
    { title: 'Ontem', id: 'yesterday' },
    { title: 'Semana passada', id: 'last_week' },
    { title: 'Mês passado', id: 'last_month' },
    { title: 'Ano passado', id: 'last_year' },
    { title: 'Sempre', id: 'ever' }
  ];

  const typeOptions = [
    {
      value: 'posts',
      label: 'Feed',
      permission: getPermission('Visualizar postagens e comentários', 'Feed')
    },
    {
      value: 'courses',
      label: 'Cursos',
      permission: getPermission(isStudent ? 'Visualizar lista de módulos e seus conteúdos' : 'Visualizar lista de cursos', 'Cursos, módulos e aulas')
    },
    {
      value: 'learning_systems',
      label: 'Módulos',
      permission: getPermission(isStudent ? 'Visualizar lista de módulos e seus conteúdos' : 'Visualizar lista de cursos', 'Cursos, módulos e aulas')
    },
    {
      value: 'learning_system_items',
      label: 'Aulas',
      permission: getPermission(isStudent ? 'Visualizar lista de módulos e seus conteúdos' : 'Visualizar lista de cursos', 'Cursos, módulos e aulas')
    },
    {
      value: 'question_books',
      label: 'Avaliações',
      permission: getPermission('Visualizar lista de provas', 'Avaliações')
    },
    {
      value: 'essays',
      label: 'Redação',
      permission: getPermission('Visualizar redação', 'Redação')
    },
    {
      value: 'auditoriums',
      label: 'Auditórios',
      permission: getPermission('Visualizar auditórios', 'Auditórios')
    },
    {
      value: 'live_classrooms',
      label: 'Aulas ao vivo',
      permission: getPermission('Visualizar aulas ao vivo', 'Aulas ao vivo')
    },
    {
      value: 'notes',
      label: 'Anotações',
      permission: getPermission('Visualizar anotações', 'Anotações')
    },
    {
      value: 'activities',
      label: 'Atividades',
      permission: getPermission('Visualizar lista de atividades', 'Materiais e atividades complementares')
    },
    {
      value: 'projects',
      label: 'Projetos',
      permission: getPermission('Visualizar lista de projetos', 'Projetos')
    },
    {
      value: 'study_plans',
      label: 'Plano de estudos',
      permission: abilities?.['plano-de-estudo'] && isStudent
    }
  ].filter(option => option.permission);

  const defaultValues = filters.values.except
    .map(value => {
      const option = typeOptions.find(option => option.value === value);
      return option ? { value: option.value, label: option.label } : null;
    })
    .filter(Boolean);

  async function fetchSearchResults() {
    setIsLoading(true);

    const optionsNotSelected = filters.values.except.length > 0 ? typeOptions.filter(option => !filters.values.except.includes(option.value)) : [];
    const except = optionsNotSelected.map(option => option.value);
    const exceptString = except.map(item => `"${item}"`).join(',');

    const { updated, value } = filters.values;
    const { data } = await client.fetch(`/search?filter[updated]=${updated}&filter[value]=${value}&filter[except]=[${exceptString}]`);

    if (data) {
      setSearchData([...data]);
    }

    setIsLoading(false);
  }

  const handleOpenNotesModal = () => {
    setShowNotes(!showNotes);
    setNotesModalOpen(true);
  };

  function icon(type) {
    switch (type) {
      case 'LearningSystemItem':
        return getIcon('FiVideo');
      case 'LearningSystem':
        return getIcon('TbCopy');
      case 'Auditorium':
        return getIcon('LuMessagesSquare');
      case 'QuestionBook':
        return getIcon('FiTarget');
      case 'Post':
        return getIcon('FiCoffee');
      case 'LiveClassroom':
        return getIcon('CiStreamOn');
      case 'Note':
        return getIcon('FiClipboard');
      case 'Essay':
        return getIcon('FiEdit');
      case 'Activity':
        return getIcon('FiEdit3');
      default:
        return getIcon('FiVideo');
    }
  }

  async function handleNavigate(url, noteId = '') {
    if (url === '/') {
      setIsOpen(false);
      handleOpenNotesModal();
      const { data } = await client.fetch(['notes', noteId], {
        new_note: false
      });
      setActiveNote(data);
    } else {
      navigate(url);
    }

    setIsOpen(false);
  }

  useEffect(() => {
    if (isOpen && !isEmptyField) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      timerRef.current = setTimeout(() => {
        fetchSearchResults();
      }, 1000);
    }

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [filters.values.value]);

  useEffect(() => {
    if (filters.values.updated && !isEmptyField) {
      fetchSearchResults();
    }
  }, [filters.values.updated]);

  useEffect(() => {
    if (filters.values.except && isOpen) {
      fetchSearchResults();
    }
  }, [filters.values.except]);

  return (
    <>
      <div
        className="notifications u-cursor-pointer"
        aria-label="Pesquisar"
        onClick={() => setIsOpen(true)}
      >
        <button className="notifications__toggle">
          <FiSearch />
        </button>
        {/* <span className="notifications__label">Pesquisar</span> */}
      </div>

      <SimpleModal
        show={isOpen}
        onClose={() => setIsOpen(false)}
        contentClassName="simple-modal__content--md simple-modal__content--br-md"
        hideCloseButton
      >
        <div className="search">
          <div className="search__header">
            <div className="filter-bar filter-bar--borderless">
              <div className="filter-bar__row u-fg-1">
                <label
                  htmlFor="search"
                  className="filter-bar__label"
                >
                  {t('textsCommon.research')}
                </label>
                <input
                  disabled={isLoading}
                  className="form__control form__control--search-left u-w-100"
                  placeholder={'Pesquisar'}
                  type="search"
                  name="search"
                  id="search"
                  value={filters.values.value}
                  onChange={e => filters.setFieldValue('value', e.target.value)}
                />
              </div>

              <FilterSelectionBox
                disabled={isLoading}
                label={'Última atualização'}
                blankLabel={false}
                value={filters.values.updated}
                onChange={e => filters.setFieldValue('updated', e.target.value)}
                options={lastUpdateOptions.map(o => ({
                  id: o.id,
                  name: o.title
                }))}
              />
            </div>

            <fieldset
              className="filter-bar__row"
              style={{ cursor: isEmptyField ? 'not-allowed' : 'pointer' }}
            >
              <legend className="filter-bar__label">Filtrar por tipo</legend>

              <Select
                defaultValue={defaultValues}
                isMulti
                options={typeOptions}
                isDisabled={isEmptyField || isLoading}
                className="react-multi-select"
                classNamePrefix="react-multi-select"
                placeholder={isEmptyField ? 'Preencha o campo pesquisar...' : 'Selecione...'}
                noOptionsMessage={() => 'Sem opções'}
                onChange={e => {
                  filters.setFieldValue(
                    'except',
                    e.map(o => o.value)
                  );
                }}
                components={{
                  IndicatorSeparator: () => null,
                  ClearIndicator: () => null
                }}
              />
            </fieldset>
          </div>

          <div className="search__body">
            {!isLoading && searchData.length === 0 && (
              <EmptyState
                type="data"
                bgless
                title={filters.values.value === '' ? 'Nenhuma busca foi realizada' : 'Dados não encontrados'}
                text={' '}
              />
            )}

            {isLoading && (
              <div className="search__loader">
                <Loader />
                <h3 className="search__loader-title">Buscando os melhores resultados</h3>
              </div>
            )}

            {!isLoading &&
              searchData.length > 0 &&
              searchData.map(result => (
                <div
                  key={result.id}
                  className="search__result"
                  onClick={() => handleNavigate(result.path, result['entity-id'])}
                >
                  <div className="search__result-icon">{icon(result['entity-type'])}</div>

                  <div className="search__result-content">
                    <div className="search__result-header">
                      <h3 className="search__result-title">{result['translated-entity-type']}</h3>
                    </div>

                    <div className="search__result-body">
                      <p className="search__result-text">{result.content}</p>
                    </div>
                  </div>

                  <p className="search__result-date">{moment(result['updated-at']).format('DD/MM/YYYY')}</p>
                </div>
              ))}
          </div>
        </div>
      </SimpleModal>
    </>
  );
}
