import React, { useState, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { useClient, useIsFetching, useMutation } from 'jsonapi-react';

import { navigate } from '@reach/router';
import toast from 'react-hot-toast';
import EmptyState from 'app/components/EmptyState';
import ModalTaxonomies from 'app/components/modalTaxonomies';
import * as yup from 'yup';
import lampIcon from './img/lamp-icon.svg';
import learningTip from './img/learning-tip.svg';
import requirementsTip from './img/requirements-tip.svg';
import relatedMaterialsTip from './img/related-materials-tip.svg';
import imageAreaTip from './img/image-area-tip.svg';
import darkeningImageTip from './img/darkening-image-tip.png';
import defaultImage from 'app/images/placeholders/show-course.jpg';
import TipsSidebar from './TipsSidebar';
import CourseStep from './CourseStep';
import NameStep from './NameStep';
import ObjectivesStep from './ObjectivesStep';
import RequirementStep from './RequirementStep';
import ImageStep from './ImageStep';
import Loader from 'app/components/loader';
import { useTranslation } from 'react-i18next';
import promiseRequest from 'app/utils/promiseToast';

export default function ModuleContent(props) {
  const { currentModule, modules, step, steps, currentStep, setCurrentStep, editingPath, courseId } = props;
  const { t } = useTranslation();
  const { promiseToast, isSubmitting } = promiseRequest();
  const basePath = window.env.REACT_APP_BASE_PATH;

  const client = useClient();
  const [imageLabel, setImageLabel] = useState();
  const [file, setFile] = useState();
  const [showModal, setShowModal] = useState(false);
  const [taxonomies, setTaxonomies] = useState([]);
  const [isOpenModalTaxonomies, setIsOpenModalTaxonomies] = useState(false);
  const [currentCourse, setCurrentCourse] = useState();
  const [removedItems, setRemovedItems] = useState([]);
  const [deadlineError, setDeadlineError] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    fetchTaxonomies();
  }, []);

  useEffect(() => {
    createModuleForm.setValues({
      ...createModuleForm.values,
      systemSubjects: currentModule ? currentModule['learning-system-subjects'] : createModuleForm.values.systemSubjects,
      title: currentModule?.title,
      publish: currentModule?.publish,
      modality: currentModule ? (currentModule['is-public'] ? 'public' : 'private') : 'private',
      description: currentModule?.description,
      shortDescription: currentModule && currentModule['short-description'] ? currentModule['short-description'] : '',
      image: currentModule?.image?.url,
      selectedTaxonomies: currentModule?.taxonomies || [],
      selectedRequirements:
        (currentModule &&
          currentModule['learning-system-requirements']?.map(e => ({
            label: e.title,
            value: e['requirement-id'],
            id: e.id
          }))) ||
        [],
      courseId: courseId,
      workload: currentModule?.workload,
      order: currentModule?.order,
      'finished-at': currentModule?.['finished-at']
    });
  }, [currentModule]);

  const handleSubmit = async () => {
    const selectedRequirements = values?.selectedRequirements?.map(item => ({
      'requirement-id': item?.value,
      id: item?.id
    }));

    const removedRequirements = removedItems.map(item => {
      return { 'requirement-id': item?.value, id: item?.id, _destroy: true };
    });

    const requirements = [...removedRequirements, ...selectedRequirements];

    const taxonomyIds = values?.selectedTaxonomies?.map(item => item?.id);

    const systemSubjects = values?.systemSubjects?.map(item => ({
      body: item?.body,
      id: item?.id
    }));

    const requestParams = {
      title: values?.title,
      description: values?.description,
      shortDescription: values?.shortDescription,
      'is-public': values?.modality !== 'private',
      publish: values?.publish,
      courseId: courseId,
      'learning-system-subjects-attributes': systemSubjects.filter(item => !item?.body?.length == 0),
      'taxonomy-ids': taxonomyIds,
      'learning-system-requirements-attributes': requirements,
      image: values?.image,
      workload: values?.workload,
      order: parseInt(values?.order),
      'finished-at': values?.['finished-at']
    };

    const path = currentModule?.id ? ['/learning_systems', currentModule.id] : '/learning_systems';

    promiseToast({
      url: path,
      request: requestParams,
      successText: t('toast.successSave')
    }).then(data => {
      if (data) {
        navigate(`${basePath}/curso/admin/gerenciar/${courseId}/${data.id}/disciplinas-e-aulas/d`);
      }
    });
  };

  const createModuleForm = useFormik({
    initialValues: {
      title: currentModule?.title,
      publish: currentModule?.publish,
      modality: 'private',
      name: '',
      description: '',
      shortDescription: '',
      image: '',
      selectedTaxonomies: [],
      selectedRequirements: [],
      systemSubjects: [{ placeholder: t('lms.placeholderModule1'), value: 0 }],
      courseId: courseId,
      workload: '',
      order: '',
      'finished-at': ''
    },
    validationSchema: yup.object({
      title: yup.string().required(t('warning.requiredField')),
      courseId: yup.string().required(t('warning.requiredField')),
      shortDescription: yup.string().required(t('warning.requiredField')),
      description: yup.string().required(t('warning.requiredField')),
      systemSubjects: yup.array(yup.object().shape({ body: yup.string().required(t('warning.requiredField')) })).min(1, t('warning.minObjective'))
    }),

    onSubmit: handleSubmit
  });

  const { values, setFieldValue, errors, touched } = createModuleForm;

  const fetchTaxonomies = async () => {
    try {
      const { data } = await client.fetch('taxonomies');
      setTaxonomies(data);
    } catch (e) {
      console.error(e);
      toast.error(t('toast.errorGetTags'));
    }
  };

  const handleChangeImage = e => {
    let file = e.target.files[0];
    setImageLabel(e.target.files[0]?.name);
    setFile(URL.createObjectURL(e.target.files[0]));

    if (file) {
      const reader = new FileReader();
      reader.onload = _handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  };

  const _handleReaderLoaded = readerEvt => {
    let bynaryString = readerEvt.target.result;
    createModuleForm.setFieldValue('image', `data:image/jpeg;base64,${btoa(bynaryString)}`);
  };

  const trashIcon = {
    all: 'unset',
    cursor: 'pointer',
    color: '#BCC4D6',
    padding: '6px 8px',
    lineHeight: '0',
    position: 'absolute',
    right: '0',
    top: '0'
  };

  const btnsStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '24px'
  };

  const tipsSidebar = useMemo(() => {
    return (
      <TipsSidebar
        step={step}
        setCurrentCourse={setCurrentCourse}
        currentCourse={currentCourse}
        courseId={courseId}
        values={createModuleForm.values}
        lampIcon={lampIcon}
        learningTip={learningTip}
        requirementsTip={requirementsTip}
        relatedMaterialsTip={relatedMaterialsTip}
        imageAreaTip={imageAreaTip}
        darkeningImageTip={darkeningImageTip}
      />
    );
  }, [currentCourse]);

  const handleNextStep = e => {
    e.preventDefault();
    if (step === 'nome' && !editingPath) {
      createModuleForm.setFieldTouched('title', true);
      createModuleForm.setFieldTouched('description', true);
      createModuleForm.setFieldTouched('shortDescription', true);
      setSubmitted(true);
      if (!deadlineError && !createModuleForm.errors.title && !createModuleForm.errors.description && !createModuleForm.errors.shortDescription) {
        setCurrentStep(2);
        navigate(`${basePath}/lms/criar/modulo/objetivos/${courseId}`);
      }
      return;
    } else if (step === 'nome' && editingPath) {
      createModuleForm.setFieldTouched('title', true);
      createModuleForm.setFieldTouched('description', true);
      createModuleForm.setFieldTouched('shortDescription', true);
      setSubmitted(true);
      if (!createModuleForm.errors.title && !createModuleForm.errors.description && !createModuleForm.errors.shortDescription) {
        setCurrentStep(2);
        navigate(`${basePath}/lms/criar/modulo/objetivos/${editingPath}/${courseId}`);
      }
    }
    if (step === 'objetivos') {
      createModuleForm.setFieldTouched('systemSubjects', true);
      if (!createModuleForm.errors.systemSubjects) {
        setCurrentStep(3);
        if (editingPath) {
          navigate(`${basePath}/lms/criar/modulo/requisitos/${editingPath}/${courseId}`);
        } else {
          navigate(`${basePath}/lms/criar/modulo/requisitos/${courseId}`);
        }
      }
    }
    if (step === 'requisitos' && !editingPath) {
      navigate(`${basePath}/lms/criar/modulo/imagem/${courseId}`);
      setCurrentStep(4);
    }
    if (step === 'requisitos' && editingPath) {
      navigate(`${basePath}/lms/criar/modulo/imagem/${editingPath}/${courseId}`);
      setCurrentStep(4);
    }
    if (step === 'imagem') {
      createModuleForm.handleSubmit();
    }
  };

  return (
    <div className="page page--wrap">
      <div className="page__col">
        {currentModule === 'blank' ? (
          <EmptyState
            type="select"
            text="Selecione uma opção Para começar a criação do módulo de aulas online"
          />
        ) : (
          <>
            <form
              className="form form--step-by-step"
              method="post"
              onSubmit={e => handleNextStep(e)}
            >
              {(step === 'curso' || step === 'nome') && (
                <div className="page__content fadeIn u-mb-5">
                  <div className="new-questions__header">
                    <h2 className="new-questions__title">
                      {values.title === undefined && editingPath && <Loader />}
                      {values.title !== undefined && values.title}
                      {values.title === undefined && !editingPath && t('lms.newModule')}
                    </h2>
                  </div>

                  {step === 'nome' && (
                    <NameStep
                      setSubmitted={setSubmitted}
                      submitted={submitted}
                      setDeadlineError={setDeadlineError}
                      deadlineError={deadlineError}
                      currentCourse={currentCourse}
                      values={values}
                      touched={touched}
                      errors={errors}
                      setFieldValue={createModuleForm.setFieldValue}
                      createModuleForm={createModuleForm}
                    />
                  )}

                  <span className="form__text">
                    <strong>* {t('warning.requiredField')}</strong>
                  </span>
                  <span className="form__text">
                    <strong>** O campo “Ordem do módulo” mostra como os módulos serão ordenados dentro do curso.</strong>
                  </span>
                </div>
              )}

              {step === 'objetivos' && (
                <ObjectivesStep
                  errors={errors}
                  trashIcon={trashIcon}
                  values={values}
                  createModuleForm={createModuleForm}
                  touched={touched}
                  setFieldValue={createModuleForm.setFieldValue}
                />
              )}

              {step === 'requisitos' && (
                <RequirementStep
                  removedItems={removedItems}
                  setRemovedItems={setRemovedItems}
                  setIsOpenModalTaxonomies={setIsOpenModalTaxonomies}
                  values={values}
                  modules={modules}
                  setFieldValue={setFieldValue}
                  currentModule={currentModule}
                />
              )}

              {step === 'imagem' && (
                <ImageStep
                  values={values}
                  handleChangeImage={handleChangeImage}
                  imageLabel={imageLabel}
                  file={file}
                  defaultImage={defaultImage}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  currentModule={currentModule}
                />
              )}
              <div style={btnsStyles}>
                {step !== 'nome' && (
                  <button
                    type="button"
                    className="btn btn--outline btn--wide u-mr-4"
                    onClick={() => {
                      if (editingPath) {
                        setSubmitted(false);
                        navigate(`${basePath}/lms/criar/modulo/${steps[currentStep - 2].route}/${currentModule.id}/${courseId}`);
                        setCurrentStep(currentStep - 1);
                      } else {
                        setSubmitted(false);
                        navigate(`${basePath}/lms/criar/modulo/${steps[currentStep - 2].route}/${courseId}`);
                        setCurrentStep(currentStep - 1);
                      }
                    }}
                  >
                    {t('exams.previous')}
                  </button>
                )}

                <button
                  type="submit"
                  className={isSubmitting ? 'btn btn--primary btn--wide disabled' : 'btn btn--primary btn--wide'}
                >
                  {step !== 'imagem' ? t('button.next') : t('button.finish')}
                </button>
              </div>
            </form>

            {taxonomies.length > 0 && isOpenModalTaxonomies && (
              <ModalTaxonomies
                taxonomies={taxonomies}
                selectedTaxonomies={values?.selectedTaxonomies}
                setFieldValue={setFieldValue}
                closeModal={() => setIsOpenModalTaxonomies(false)}
              />
            )}
          </>
        )}
      </div>

      {tipsSidebar}
    </div>
  );
}
