import React, { useRef, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';

export default function AccordionItem({ module }) {
  const accordionContent = useRef(null);
  const [active, setActive] = useState(false);

  const amountLessons = module.learning_system_items.length;

  const formatDuration = seconds => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secondsLeft = seconds % 60;

    const parsedDuration = value => value.toString().padStart(2, '0');

    return `${parsedDuration(hours)}:${parsedDuration(minutes)}:${parsedDuration(secondsLeft)}`;
  };

  return (
    <div className={active ? 'accordion__item active' : 'accordion__item'}>
      <div
        className="accordion__header"
        onClick={() => setActive(!active)}
      >
        <h3 className="accordion__title">
          {module.body} {module.finished && <span className="badge badge--tiny badge--success">Concluído</span>}
        </h3>
        <span className="widget__count">
          <span>{amountLessons > 0 ? `${amountLessons} aulas` : 'Sem aulas'}</span>
        </span>
        <span className="card__icon-wrapper">
          <FiChevronDown className="card__icon" />
        </span>
      </div>

      <div
        className="accordion__collapse"
        style={{
          height: active ? `${accordionContent.current.scrollHeight}px` : '0'
        }}
      >
        <div
          className="accordion__content"
          ref={accordionContent}
        >
          <div className="lesson-dg">
            {module.learning_system_items.map((item, i) => (
              <div
                key={item.id}
                className="lesson-dg__row"
              >
                <div className="lesson-dg__td">
                  {/* Retornar se a aula está completa do backend */}
                  {item.finished && <span className="lesson-dg__check lesson-dg__check--checked" />}

                  <h3 className="lesson-dg__title">{item.body}</h3>
                </div>
                {/* O que é isso? */}
                <div className="lesson-dg__td">{/* <p className="lesson-dg__link">Plano de aula</p> */}</div>
                {/* Retornar quantidade de arquivos para cada aula do backend */}
                <div className="lesson-dg__td lesson-dg__td--files">
                  <p className="lesson-dg__text">
                    {item?.['learning-system-files-count']} {'arquivos'}
                  </p>
                </div>
                <div className="lesson-dg__td lesson-dg__td--time">
                  <p className="lesson-dg__text">{formatDuration(item.duration)}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
