import Modal from 'app/components/Modal';
import defaultImage from 'app/images/placeholders/show-course.jpg';
import { useState } from 'react';
import PreVisualizeModal from './PreVisualizeModal';
import { t } from 'i18next';

const StepImage = props => {
  const { step, handleChangeImage, file, form, courseId } = props;

  const [showModal, setShowModal] = useState(false);

  return (
    step === 'imagem' && (
      <div className="u-mb-5">
        <div className="page__content fadeIn u-mb-3">
          <div className="new-questions__header">
            <h2 className="new-questions__title">{t('courses.buttonNewCourse')}</h2>
          </div>

          <fieldset className="form__row">
            <legend className="form__label">{t('courses.coverImage')} (1020x252 pixels)</legend>

            <label className="form__custom-file-input">
              <input
                type="file"
                name="image"
                id="image"
                accept=".jpeg, .png, .jpg"
                onChange={handleChangeImage}
                // defaultValue={}
              />
              <span className="form__control">{false ? '' : t('textsCommon.placeholderUploadImage')}</span>
            </label>
          </fieldset>

          <div className="card__header-img-wrapper">
            <img
              className="card__header-img"
              src={form?.values?.image || file || defaultImage}
              alt="imagem do curso"
              style={{ height: '160px' }}
            />
          </div>

          <PreVisualizeModal
            courseId={courseId}
            form={form}
            show={showModal}
            onClose={() => setShowModal(false)}
            src={form?.values?.image || file || defaultImage}
          />
        </div>

        <button
          type="button"
          className="btn btn--link btn--wide"
          onClick={() => setShowModal(true)}
        >
          {t('courses.previewCourse')}
        </button>
      </div>
    )
  );
};

export default StepImage;
