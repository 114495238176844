import { useEffect, useState, useRef } from 'react';
import DeleteIcon from '../icon/DeleteIcon';
import { getFundamentalUsers } from '../utils/api';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import { FiTrash2 } from 'react-icons/fi';

const AddEditNodeFormUserInputGroup = ({ userId = null, onUserChange, onDelete }) => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(userId);
  const [hoverUser, setHoverUser] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  const inputRef = useRef(null);

  const client = useClient();

  const fetchUsers = async () => {
    try {
      const response = await getFundamentalUsers(client);
      setUsers(response);
      setFilteredUsers(response);
    } catch (e) {
      toast.error('Erro ao acessar usuários');
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (userId) {
      const user = users.find(user => user.id === userId);
      if (user) {
        setSelectedUser(user);
        setInputValue(user.name);
      }
    }
  }, [userId, users]);

  const handleInputChange = e => {
    const value = e.target.value;
    setInputValue(value);
    setShowDropdown(true);
    filterUsers(value);
  };

  const filterUsers = value => {
    const filtered = users.filter(user => user.name.toLowerCase().includes(value.toLowerCase()) || user.id === parseInt(value));
    setFilteredUsers(filtered);
  };

  const handleUserSelection = e => {
    const user = users.find(user => user.id === parseInt(e.target.value));
    setSelectedUser(user);
    setInputValue(user.name);
    setShowDropdown(false);
    inputRef.current.focus();
    onUserChange(user.id);
  };

  const handleDeleteUser = () => {
    setSelectedUser(null);
    setInputValue('');
    setShowDropdown(false);
    onDelete();
  };

  const handleUserHover = user => {
    setHoverUser(user);
    setSelectedUser(user);
    userId = user.id;
    setInputValue(user.name);
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter' || (e.key == 'Tab' && filteredUsers.length > 0)) {
      e.preventDefault();
      handleUserSelection(hoverUser);
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      const currentIndex = filteredUsers.findIndex(user => user.id === selectedUser?.id);
      if (currentIndex < filteredUsers.length - 1) {
        handleUserHover(filteredUsers[currentIndex + 1]);
      } else {
        handleUserHover(filteredUsers[0]);
      }
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      const currentIndex = filteredUsers.findIndex(user => user.id === selectedUser?.id);
      if (currentIndex > 0) {
        handleUserHover(filteredUsers[currentIndex - 1]);
      } else {
        handleUserHover(filteredUsers[filteredUsers.length - 1]);
      }
    }
  };

  return (
    <div className="add-edit-node-form-user-input-group">
      <input
        type="hidden"
        value={selectedUser ? selectedUser.id : ''}
      />
      <div
        className="list-alternatives__container"
        style={{ flexGrow: '1' }}
      >
        <select
          value={selectedUser?.id}
          className="form__select"
          onChange={handleUserSelection}
          style={{ flexGrow: '1', width: 'auto' }}
          ref={inputRef}
        >
          <option value="blank">Selecione um colaborador</option>
          {(filteredUsers || []).map(user => (
            <option
              key={user.id}
              value={user.id}
              onMouseEnter={() => setHoverUser(user)}
            >
              {user.name}
            </option>
          ))}
        </select>

        {selectedUser && (
          <FiTrash2
            className="list-alternatives__icon"
            onClick={() => handleDeleteUser()}
          />
        )}
      </div>
    </div>
  );
};

export default AddEditNodeFormUserInputGroup;
