import React, { useState, useEffect } from 'react';
import { useClient } from 'jsonapi-react';
import moment from 'moment';
import toast from 'react-hot-toast';
import { FiChevronLeft, FiFileText, FiLink } from 'react-icons/fi';
import { Link } from '@reach/router';
import Loader from 'app/components/loader';
import ActivityCardHeader from 'app/components/ComplementaryActivity/activityCardHeader';
import { useTranslation } from 'react-i18next';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import { navigate } from '@reach/router';

export function ActivityEvaluationView(props) {
  const { t } = useTranslation();
  const client = useClient();
  const [isLoading, setIsLoading] = useState(true);
  const [activity, setActivity] = useState();
  const [activityStudent, setActivityStudent] = useState();
  const basePath = window.env.REACT_APP_BASE_PATH;

  useEffect(() => {
    getActivity();
    getActivityStudent();
  }, []);

  const getActivity = async () => {
    try {
      setIsLoading(true);
      const { data } = await client.fetch(['activities', props.activityId]);
      setActivity(data);
      setIsLoading(false);
    } catch (e) {
      toast.error(t('toast.errorGetActivity'));
    }
  };

  const getActivityStudent = async () => {
    try {
      setIsLoading(true);
      const { data } = await client.fetch(['activities', props.activityId, 'activity_students', props.id]);
      setActivityStudent(data);
      setIsLoading(false);
    } catch (e) {
      toast.error(t('toast.errorGetActivityStudent'));
    }
  };

  const authorityLevel = getAuthorityLevel();
  return (
    <>
      <main className="main-content main-content--block fadeIn">
        <div className="filter-bar">
          <button
            onClick={() => navigate(authorityLevel === 'admin' ? `${basePath}/atividade-complementar` : -1)}
            className="filter-bar__back"
          >
            <FiChevronLeft />

            <span className="filter-bar__back-container">
              <span className="filter-bar__title">{t('activity.titleTeacherEvaluation')}</span>
            </span>
          </button>
        </div>

        <div className="page">
          {isLoading || !activity || !activityStudent ? (
            <Loader />
          ) : (
            <div className="page__content">
              <ActivityCardHeader
                activity={activity}
                activityStudent={activityStudent}
                showStatus
              />

              <div className="activity-card__body">
                {activityStudent.comments.map(comment => (
                  <>
                    <div className="activity-card__header">
                      <div className="activity-card__group">
                        <h3 className="activity-card__subtitle">{comment.user.includes('Aluno') ? t('activity.titleStudentAnswer') : t('activity.teacherEvaluation')}</h3>
                        <span className="activity-card__info">
                          {t('activity.in')} {moment(comment['created-at']).format(t('datesForm.formatDate'))}
                        </span>
                      </div>

                      {comment.user.includes('Aluno') && (
                        <a
                          href={activityStudent['activity-file']?.url || activityStudent['activity-file']?.file}
                          target="_blank"
                          className="btn btn--link"
                        >
                          <span
                            className="activity-card__tag"
                            style={{ marginRight: '8px' }}
                          >
                            {activityStudent['activity-file']?.url ? <FiFileText /> : <FiLink />}
                          </span>
                          {activityStudent['activity-file']?.url ? t('activity.sendUrl') : t('activity.sendFiles')}
                        </a>
                      )}
                    </div>
                    <p className="activity-card__description">{comment.body}</p>
                  </>
                ))}

                <div className="activity-card__header">
                  <div className="activity-card__group">
                    <h3 className="activity-card__subtitle">{t('activity.titleActivityDescription')}</h3>
                    <span className="activity-card__info">
                      {t('activity.in')} {moment(activity['publish-at']).format(t('datesForm.formatDate'))}
                    </span>
                  </div>

                  <a
                    href={activity['activity-file']?.url || activity['activity-file']?.file}
                    target="_blank"
                    className="btn btn--outline"
                  >
                    {t('button.supportMaterial')}
                  </a>
                </div>

                <p className="activity-card__description">{activity.description}</p>
              </div>
            </div>
          )}

          <div className="page__aside" />
        </div>
      </main>
    </>
  );
}
