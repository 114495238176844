import { useClient } from "jsonapi-react"
import { getUserById } from "../utils/api"
import ModalHeader from "./ModalHeader"

import { useEffect, useState } from "react"
import Employee from "../components/Employee"

const EmployeeList = ({employees = []}) => {

    return (
        <div>
            <ModalHeader title="Colaboradores" hasUnderline={true} />
            <div className="employee-list--container fadeIn">
                    {employees.map(employee => <div className="container--employee container--employee--border">
                        <Employee key={employee} employeeId={employee} />
                    </div>)}
                
            </div> 
        </div>
    )
}

export default EmployeeList