import React from 'react';
import UserInfo from 'app/components/UserInfo';
import { Link } from '@reach/router';
import { t } from 'i18next';

export default function StudentCard(props) {
  const { student, activityId } = props;
  const basePath = window.env.REACT_APP_BASE_PATH;

  return (
    <tr className="round-dg__row">
      <th className="round-dg__cell-header round-dg__cell-header--row">
        <UserInfo
          user={{
            id: 32,
            name: student?.name,
            image: {
              url: student?.image?.url
            },
            email: student?.email,
            status: 'Ativo'
          }}
          viewEmail
        />
      </th>
      <td className="round-dg__cell">
        {student?.status === 'Avaliação realizada' && <span className="badge badge--tiny badge--warning">{t('activity.waitingEvaluation')}</span>}
        {student?.status === 'Avaliada' && <span className="badge badge--tiny badge--success">{t('activity.evaluated')}</span>}
        {student?.status === 'Não enviada' && <span className="badge badge--tiny badge--danger">{t('activity.notSubmited')}</span>}
      </td>
      <td
        className="round-dg__cell"
        style={{ width: '200px' }}
      >
        {student?.status === 'Avaliação realizada' && (
          <Link
            to={`${basePath}/atividade-complementar/avaliar/${activityId}/${student?.studentActivityId}`}
            className="btn btn--small btn--full btn--outline"
          >
            {t('activity.evaluateActivity')}
          </Link>
        )}
        {student?.status === 'Avaliada' && (
          <Link
            to={`${basePath}/atividade-complementar/avaliar/${activityId}/${student?.studentActivityId}`}
            className="btn btn--small btn--full btn--outline"
          >
            Visualizar atividade
          </Link>
        )}
        {student?.status === 'Não enviada' && (
          <Link
            to={`${basePath}/atividade-complementar/avaliar/${activityId}/${student?.studentActivityId}`}
            className="btn btn--small btn--full btn--outline disabled"
          >
            {t('activity.evaluateActivity')}
          </Link>
        )}
      </td>
    </tr>
  );
}
