import React from 'react';
import defaultImage from 'app/images/placeholders/show-course.jpg';
import { Link, navigate, useLocation } from '@reach/router';
import { FiPlayCircle } from 'react-icons/fi';
import { AiOutlineTrophy } from 'react-icons/ai';
import { t } from 'i18next';

export default function CourseCard(props) {
  const { status } = props;
  const location = useLocation();
  const progress = 50;
  const basePath = window.env.REACT_APP_BASE_PATH;

  return (
    <div
      className="course-card"
      onClick={() => navigate(location.pathname === '/curso' ? `/curso/${1}` : `/curso/acompanhamento/${1}`)}
    >
      <div className="course-card__body">
        <div className="course-card__img-wrapper">
          <span className="badge badge--tiny badge--primary">Tecnologia</span>
          <img
            className="course-card__img"
            src={defaultImage}
            alt=""
          />
        </div>

        <div className="course-card__inner-body">
          <h3 className="course-card__title">Curso prático de física</h3>
          <p className="course-card__subtitle">
            <strong>10</strong>/12 {t('textsCommon.modules')}
          </p>
          <div>
            <span className="badge badge--tiny badge--warning u-mb-2">Notificações não lidas</span>
          </div>
          {status === 'finalized' ? (
            <button
              type="button"
              className="btn btn--wide btn--tiny btn--outline"
            >
              {t('button.certification')}
            </button>
          ) : (
            <div className="card__progress u-mb-0">
              <div className="card__progress-container">
                <div
                  className="card__progress-bar"
                  role="progressbar"
                  style={{ width: `${progress}%` }}
                  aria-valuenow={progress}
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
              <div className="card__progress-text">{progress}%</div>
            </div>
          )}
        </div>
      </div>

      {status === 'finalized' ? (
        <div className="course-card__tag">
          <AiOutlineTrophy /> Concluído
        </div>
      ) : (
        <div className="course-card__footer">
          <p className="course-card__text">{t('lms.buttonContinue')}</p>
          <Link
            className="course-card__link"
            to={`${basePath}/show-course/${374}/classroom`}
            onClick={e => e.stopPropagation()}
          >
            <span className="card__icon-wrapper">
              <FiPlayCircle className="card__icon" />
            </span>
            <span className="course-card__link-text">Aula 2 - Física clássica</span>
          </Link>
        </div>
      )}
    </div>
  );
}
