import React from 'react';
import { FiMoreHorizontal, FiCheckCircle, FiClock } from 'react-icons/fi';
import { Link } from '@reach/router';
import Dropdown from 'app/components/Dropdown';
import { useTranslation } from 'react-i18next';

export default function QuestionCard(props) {
  const { t } = useTranslation();
  const { title, isRequired, taxonomies, questionCount, deleteNotebook, editNotebook } = props;
  const basePath = window.env.REACT_APP_BASE_PATH;

  return (
    <div className={isRequired ? 'card card--danger' : 'card'}>
      <div className="card__header">
        <div className="card__inner-header">
          <h3 className="card__title">{title}</h3>
          <Dropdown>
            <Dropdown.Toggle>
              <FiMoreHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Content>
              <Dropdown.Item onClick={editNotebook}>{t('button.edit')}</Dropdown.Item>
              <Dropdown.Item onClick={deleteNotebook}>{t('button.delete')}</Dropdown.Item>
            </Dropdown.Content>
          </Dropdown>
        </div>
        {isRequired ? (
          <span className="badge badge--tiny badge--danger">Obrigatório</span>
        ) : (
          <span
            style={{ opacity: 0 }}
            className="badge badge--tiny badge--danger"
          >
            Obrigatório
          </span>
        )}
      </div>

      <div className="card__body">
        <div className="card__badges card__badges--one-line">
          {taxonomies.slice(0, 3).map((t, i) => {
            return (
              <span
                key={i}
                className="badge badge--primary"
                title={t.name}
              >
                {t.name}
              </span>
            );
          })}

          {taxonomies.length >= 3 ? (
            <span
              className="badge badge--more"
              title={`mais ${taxonomies.length - 3} taxonomias`}
            >
              +{taxonomies.length - 3}
            </span>
          ) : (
            <span
              style={{ opacity: 0 }}
              className="badge badge--more"
              title={`mais ${taxonomies.length - 3} taxonomias`}
            >
              void
            </span>
          )}
        </div>

        <div className="card__columns">
          <p
            className="card__info"
            title="3"
            aria-label="3"
          >
            <FiClock className="card__icon" />
            <FiClock className="card__icon" />
            <FiClock className="card__icon" />
          </p>
          <p
            className="card__count"
            title={`${questionCount} questões`}
            aria-label={`${questionCount} questões`}
          >
            <FiCheckCircle className="card__icon" /> {questionCount}
          </p>
        </div>
      </div>

      <div className="card__footer">
        <Link
          className="btn btn--outline"
          to={basePath}
        >
          {t('questionBooks.buttonAccessQuestionBook')}
        </Link>
      </div>
    </div>
  );
}
