import { useTranslation } from 'react-i18next';

import { TopBar } from './TopBar';
import { ShadowContainer } from './ShadowContainer';

import informationPng from '../../images/onboarding/profile/informations.png';
import notificationsPng from '../../images/onboarding/profile/notifications.png';
import securityPng from '../../images/onboarding/profile/security.png';

export function ProfileOnboarding() {
  const { t } = useTranslation();

  return (
    <div>
      <TopBar />

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.profileInformations')}</h1>
        <p className="text-option-onboarding">{t('onboarding.profileInformationsDesc')}</p>

        <ShadowContainer width="fit-content">
          <img src={informationPng} />
        </ShadowContainer>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('header.notificationTitle')}</h1>
        <p className="text-option-onboarding">{t('onboarding.notificationsDesc')}</p>

        <div className="container-onboarding-list">
          <ul className="onboarding-list">
            <li className="text-option-onboarding">{t('onboarding.eventCreation')}</li>
            <li className="text-option-onboarding">{t('onboarding.addNewMaterials')}</li>
            <li className="text-option-onboarding">{t('onboarding.newPosts')}</li>
          </ul>

          <ul className="onboarding-list">
            <li className="text-option-onboarding">{t('onboarding.creatingComplementaryActivity')}</li>
            <li className="text-option-onboarding">{t('onboarding.tasksCreation')}</li>
            <li className="text-option-onboarding">{t('onboarding.addANewProjects')}</li>
          </ul>
        </div>

        <img
          src={notificationsPng}
          className="w-70"
        />
      </div>

      <div>
        <h1 className="title-option-onboarding">{t('onboarding.security')}</h1>
        <p className="text-option-onboarding">{t('onboarding.securityDesc')}</p>

        <ShadowContainer width="fit-content">
          <img src={securityPng} />
        </ShadowContainer>
      </div>
    </div>
  );
}
