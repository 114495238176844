import React from 'react';
import { Link } from '@reach/router';
import { t } from 'i18next';

function TabNavBar(props) {
  const { selectedTab } = props;
  const basePath = window.env.REACT_APP_BASE_PATH;
  return (
    <div className="tab">
      <Link
        className={selectedTab === 'turmas' ? 'tab__link active' : 'tab__link'}
        to={`${basePath}/turmas`}
      >
        {t('courses.classes')}
      </Link>
      <Link
        className={selectedTab === 'convites' ? 'tab__link active' : 'tab__link'}
        to={`${basePath}/turmas/convites`}
      >
        {t('classes.toggleInvitations')}
      </Link>
      <Link
        className={selectedTab === 'importar' ? 'tab__link active' : 'tab__link'}
        to={`${basePath}/turmas/importar`}
      >
        {t('classes.toggleImportSpreadsheet')}
      </Link>
    </div>
  );
}

export default TabNavBar;
