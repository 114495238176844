import { navigate } from '@reach/router';
import { usePlatformAbilities } from 'app/hooks/usePlatformAbilities';
import { t } from 'i18next';
import React from 'react';

function TabNavBar({ selectedTab, courseId, moduleId, questionbooksCount }) {
  const { abilities } = usePlatformAbilities();
  const basePath = window.env.REACT_APP_BASE_PATH;

  return (
    <div className="tab">
      <button
        className={selectedTab === 'sobre' ? 'tab__link active' : 'tab__link'}
        onClick={() => navigate(`${basePath}/curso/${courseId}/modulo/${moduleId}/sobre`)}
      >
        {t('courses.toggleAbout')}
      </button>

      {abilities?.['cursos-materiais'] && (
        <button
          className={selectedTab === 'arquivos' ? 'tab__link active' : 'tab__link'}
          onClick={() => navigate(`${basePath}/curso/${courseId}/modulo/${moduleId}/arquivos`)}
        >
          {t('courses.toggleFiles')}
        </button>
      )}

      {abilities?.['cursos-ativ-complementar'] && (
        <button
          className={selectedTab === 'atividades' ? 'tab__link active' : 'tab__link'}
          onClick={() => navigate(`${basePath}/curso/${courseId}/modulo/${moduleId}/atividades`)}
        >
          Atividades
        </button>
      )}
      {questionbooksCount > 0 && abilities?.['avaliacoes-apostilas'] && (
        <button
          className={selectedTab === 'apostilas' ? 'tab__link active' : 'tab__link'}
          onClick={() => navigate(`${basePath}/curso/${courseId}/modulo/${moduleId}/apostilas`)}
        >
          {t('courses.toggleQuestionBooks')}
        </button>
      )}
    </div>
  );
}

export default TabNavBar;
