import { navigate } from '@reach/router';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { FiCheckCircle, FiClock } from 'react-icons/fi';
import { CSSTransition } from 'react-transition-group';
import multiDevice from './img/multi-device.gif';
import { useTranslation } from 'react-i18next';

export default function SubmittedAnswersModal(props) {
  const { t } = useTranslation();
  const { show, questionBook, questions, countAnswered, finishQuestionBook, elapsedTimeInMinutes, formattedDate } = props;
  const basePath = window.env.REACT_APP_BASE_PATH;

  useEffect(() => {
    if (show) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'visible';
  }, [show]);

  const handleModalClose = () => {
    if (questionBook?.['learning-system-ids']?.length === 0) {
      navigate(`${basePath}/questions/finish`);
    } else {
      navigate(`${basePath}/resultado-das-avaliacoes`);
    }
  };

  const nodeRef = React.useRef(null);

  return ReactDOM.createPortal(
    <CSSTransition
      in={show}
      unmountOnExit
      nodeRef={nodeRef}
      timeout={{ enter: 0, exit: 300 }}
    >
      <div
        className={show ? 'success-modal active' : 'success-modal'}
        ref={nodeRef}
      >
        <div
          className="success-modal__content"
          onClick={event => event.stopPropagation()}
        >
          <img
            className="success-modal__img"
            src={multiDevice}
            alt=""
          />

          <div className="success-modal__inner">
            <div className="success-modal__header">
              <h2 className="success-modal__title">{t('exams.titleSendAnswers')}</h2>
            </div>

            <div className="success-modal__body">
              <p className="success-modal__text">{t('exams.textCongratulations')}</p>
              <h3 className="success-modal__name">{questionBook?.title}</h3>
              {questionBook?.['learning-system-ids'].length > 0 ? (
                <p className="success-modal__text">{t('exams.textResultPerformace')}</p>
              ) : (
                <p className="success-modal__text">
                  {t('exams.textAbleToPerformace')} <strong>{formattedDate}</strong>
                </p>
              )}
            </div>

            <div className="success-modal__kpis u-mb-3">
              <div className="success-modal__kpi">
                <FiClock className="success-modal__kpi-icon" />
                <div className="success-modal__kpi-inner">
                  <h4 className="success-modal__kpi-value">{elapsedTimeInMinutes}</h4>
                  <p className="success-modal__kpi-label">{t('exams.examMinutes')}</p>
                </div>
              </div>
              <div className="success-modal__kpi">
                <FiCheckCircle className="success-modal__kpi-icon" />
                <div className="success-modal__kpi-inner">
                  <h4 className="success-modal__kpi-value">
                    {countAnswered()}/{questions.length}
                  </h4>
                  <p className="success-modal__kpi-label">{t('exams.answeredQuestions')}</p>
                </div>
              </div>
            </div>

            <button
              className="btn btn--wide btn--primary"
              onClick={handleModalClose}
            >
              {questionBook?.['learning-system-ids'].length > 0 ? t('exams.thResults') : t('exams.closeExam')}
            </button>
          </div>
        </div>
      </div>
    </CSSTransition>,
    document.getElementById('modal_root')
  );
}
