import { Link, useParams } from '@reach/router';
import { useClient } from 'jsonapi-react';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { icons } from 'react-icons';
import { CSSTransition } from 'react-transition-group';
import moreMedal from 'app/images/more-medal.svg';
import trophy from './img/trophy.svg';
import * as Fi from 'react-icons/fi';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import Medal from '../Medal';
import courseMedal from 'app/images/course-medal.svg';
import Loader from '../loader';

export default function SucessModal(props) {
  const { t } = useTranslation();
  const { show, onClose, header, title, moduleCertificate, learningSystemUser, learningSystem } = props;
  const [certificates, setCertificates] = useState([]);
  const [loading, setLoading] = useState(false);
  const client = useClient();
  const { id } = useParams();

  function closeOnEscapeKeyDown(event) {
    if (event.key === 'Escape') onClose();
  }

  const now = new Date();

  const downloadCertificate = () => {
    window.open(`${window.env.REACT_APP_DEV_URL}//user_certificates/download/${learningSystemUser?.course?.status === 'completed' ? learningSystemUser?.course?.hash_id : certificates?.[0]?.['hash-id']}.pdf`, '_blank');
  };

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);

    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
      document.body.style.overflow = 'visible';
    };
  }, []);

  useEffect(() => {
    if (show) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'visible';
  }, [show]);

  const nodeRef = React.useRef(null); //Fix react-transition-group

  const getCertificates = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`user_certificates?filter[learning_systems.id]=${id}`);
    if (error) {
      toast.error(t('toast.errorGetCertifications'));
    } else {
      setCertificates(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id && show) {
      getCertificates();
    }
  }, [id, show]);

  function getIcon(icon) {
    switch (icon) {
      case 'FiVideo':
        return <Fi.FiVideo className="success-modal__kpi-icon" />;
      case 'FiMousePointer':
        return <Fi.FiMousePointer className="success-modal__kpi-icon" />;
      case 'FiEdit3':
        return <Fi.FiEdit3 className="success-modal__kpi-icon" />;
      case 'FiCheckCircle':
        return <Fi.FiCheckCircle className="success-modal__kpi-icon" />;
      case 'FiMessageCircle':
        return <Fi.FiMessageCircle className="success-modal__kpi-icon" />;
      case 'FiFolder':
        return <Fi.FiFolder className="success-modal__kpi-icon" />;
    }
  }

  return ReactDOM.createPortal(
    <CSSTransition
      in={show}
      unmountOnExit
      nodeRef={nodeRef}
      timeout={{ enter: 0, exit: 300 }}
    >
      <div
        className={show ? 'success-modal active' : 'success-modal'}
        onClick={onClose}
        ref={nodeRef}
      >
        {loading ? (
          <Loader />
        ) : (
          <div
            className="success-modal__content"
            onClick={event => event.stopPropagation()}
          >
            <span className="success-modal__tag">{t('textsCommon.tagConcluded')}</span>

            <img
              className="success-modal__img"
              src={trophy}
              alt=""
            />

            <div className="success-modal__inner">
              <div className="success-modal__header">
                <h2 className="success-modal__title">
                  {header}{' '}
                  <div className="medal">
                    <img
                      src={learningSystemUser?.course?.status !== 'completed' ? moreMedal : courseMedal}
                      alt="nova medalha"
                      className="medal__img"
                    />
                    <span className="medal__value">+1</span>
                  </div>
                </h2>
              </div>

              <div className="success-modal__body">
                <p className="success-modal__text">{title}</p>
                <h3 className="success-modal__name">{certificates?.[0]?.['learning-system']?.title}</h3>

                {certificates.length > 0 && now >= new Date(learningSystem?.['finished-at']) ? (
                  <>
                    <p className="success-modal__text">{t('lms.moduleCompletedCertificateReady')}</p>
                    <button
                      className="btn btn--wide btn--primary"
                      onClick={downloadCertificate}
                    >
                      {t('lms.buttonGetCertificate')}
                    </button>
                  </>
                ) : (
                  <p className="success-modal__text">Seu certificado estará disponível em {new Date(learningSystem?.['finished-at'])?.toLocaleDateString('pt-br')}</p>
                )}
              </div>

              <div className="success-modal__kpis">
                {learningSystemUser?.course?.status !== 'completed' &&
                  certificates?.[0]?.info?.map((item, index) => {
                    if (item.count > 0) {
                      return (
                        <div className="success-modal__kpi">
                          {getIcon(item.icon)}
                          <div className="success-modal__kpi-inner">
                            {index === 1 && <Medal value={1} />}
                            <h4 className="success-modal__kpi-value">{item.count}</h4>
                            <p className="success-modal__kpi-label">{item.label}</p>
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          </div>
        )}
      </div>
    </CSSTransition>,
    document.getElementById('modal_root')
  );
}
