import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Link } from '@reach/router';
import { checkIncludeProfiles } from 'app/utils/getAuthorityLevel';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import getPermission from 'app/utils/getPermission';
import Error404 from 'app/components/Error404';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import FilterMultiSelect from 'app/components/FilterMultiSelect';
import TabNavBar from './TabNavBar';
import CourseMonitoring from './CourseMonitoring';
import CourseCard from './CourseCard';
import CourseAbout from './CourseAbout';
import CourseRecommended from './CourseRecommended';
import { CourseCertificate } from './CourseCertificate';
import ModuleAbout from './CourseAbout/ModuleAbout';
import { useClient } from 'jsonapi-react';
import Loader from 'app/components/loader';
import { t } from 'i18next';
import { toast } from 'react-hot-toast';
import EmptyState from 'app/components/EmptyState';
import Onboarding from 'app/components/Onboarding';
import { useSession } from 'app/hooks/useSession';
import BreadCrumbs from 'app/components/BreadCrumbs';

function Course(props) {
  const { uri } = props;

  const [courses, setCourses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const path = props.path;
  const { session } = useSession();
  const [showOnboarding, setShowOnboarding] = useState(false);
  const profiles = session?.user?.profileNames || (session?.user && session?.user['profile-names']) || [];
  const basePath = window.env.REACT_APP_BASE_PATH;

  const getCourses = async () => {
    setLoading(true);
    const { data, error } = await client.fetch('/courses?detailed=true');
    if (error) {
      toast.error('Erro ao carregar cursos');
    } else {
      setCourses(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCourses();
  }, []);

  const authorityLevel = getAuthorityLevel();

  const filters = useFormik({
    initialValues: {
      visibility: {
        inProgress: false,
        finalized: false,
        published: false,
        notPublished: false
      },
      status: 'blank',
      knowledgeArea: 'blank'
    }
  });

  const options = [
    { name: t('filter.labelInProgress'), id: 1 },
    { name: t('filter.labelCompleted'), id: 2 }
  ];

  const knowledgeList = [
    { title: t('exams.knowledgeAcademy'), id: 1 },
    { title: t('exams.knowledgeCommercial'), id: 2 },
    { title: t('exams.knowledgeMunicipality'), id: 3 },
    { title: t('exams.knowledgeData'), id: 4 },
    { title: t('exams.knowledgeEsocial'), id: 5 },
    { title: t('exams.knowledgeFacilities'), id: 6 },
    { title: t('exams.knowledgeImplantation'), id: 7 },
    { title: t('exams.knowledgeBusiness'), id: 8 },
    { title: t('exams.knowledgeOperation'), id: 9 },
    { title: t('exams.knowledgePD'), id: 10 },
    { title: t('exams.knowledgeParameterization'), id: 11 },
    { title: t('exams.knowledgeProcesss'), id: 12 },
    { title: t('exams.knowledgeProduct'), id: 13 },
    { title: t('exams.knowledgeProjects'), id: 14 },
    { title: t('exams.knowledgeSupport'), id: 15 }
  ];

  const filteredCourses = courses.filter(course => {
    if (searchTerm !== '' && !course.title?.toLowerCase().includes(searchTerm.toLowerCase())) {
      return false;
    }
    const knowledgeTitle = knowledgeList.find(knowledge => knowledge.id === parseInt(filters.values.knowledgeArea))?.title;
    if (knowledgeTitle && course.knowledge !== knowledgeTitle) {
      return false;
    }

    if (filters.values.status !== 'blank') {
      if (filters.values.status == 1 && course.progress >= 100) {
        return false;
      } else if (filters.values.status == 2 && course.progress < 100) {
        return false;
      }
    }
    return true;
  });

  const breadCrumbs = {
    title: 'Cursos e Aulas',
    nav: [
      {
        route: uri,
        name: 'Cursos',
        isActive: true
      }
    ]
  };

  return (
    <>
      <main className="main-content main-content--block">
        <BreadCrumbs data={breadCrumbs} />

        {checkIncludeProfiles({ only: ['student'] }) && <TabNavBar selectedTab="curso" />}

        <div className="filter-bar">
          <div className="filter-bar__inner">
            <div className="filter-bar__row">
              <label
                htmlFor="search"
                className="filter-bar__label"
              >
                {t('textsCommon.search')}
              </label>
              <input
                aria-label="Buscar"
                className="form__control form__control--search-with-icon"
                style={{ width: 'auto' }}
                placeholder={t('textsCommon.research')}
                type="search"
                name="search"
                id="search"
                onChange={e => setSearchTerm(e.target.value)}
              />
            </div>

            <FilterSelectionBox
              label={t('filter.knowledgeArea')}
              value={filters.values.knowledgeArea}
              onChange={e => filters.setFieldValue('knowledgeArea', e.target.value)}
              options={knowledgeList}
            />

            {authorityLevel === 'student' && (
              <FilterSelectionBox
                blankLabel={t('filter.blankLabelAll2')}
                label={t('filter.labelSelectStatus')}
                value={filters.values.status}
                onChange={e => filters.setFieldValue('status', e.target.value)}
                options={options}
              />
            )}

            {authorityLevel !== 'student' && (
              <FilterMultiSelect
                data={[
                  {
                    label: 'Publicado',
                    value: filters.values.visibility.published,
                    onChange: () => {
                      filters.setFieldValue('visibility', {
                        published: !filters.values.visibility.published,
                        notPublished: false
                      });
                    }
                  },
                  {
                    label: 'Não publicado',
                    value: filters.values.visibility.finalized,
                    onChange: () => {
                      filters.setFieldValue('visibility', {
                        published: false,
                        notPublished: !filters.values.visibility.notPublished
                      });
                    }
                  }
                ]}
              />
            )}
          </div>

          {getPermission('Criar módulos, disciplinas e aulas', 'Cursos, módulos e aulas') && (
            <div className="filter-bar__actions">
              <Link
                className="btn btn--primary btn--wide"
                to={`${basePath}/lms/criar/modulo/curso`}
              >
                {t('button.createModule')}
              </Link>

              <Link
                className="btn btn--primary btn--wide"
                to={`${basePath}/lms/gerenciar/modulos`}
              >
                {t('button.manageModules')}
              </Link>
            </div>
          )}
        </div>
        {loading ? (
          <Loader />
        ) : filteredCourses.length === 0 ? (
          <EmptyState type="data" />
        ) : (
          <div className="page">
            <div className="page__col">
              <div className="course-wrapper">
                {filteredCourses.map(course => (
                  <CourseCard
                    key={course.id}
                    course={course}
                  />
                ))}
              </div>
            </div>

            <aside className="page__aside"></aside>
          </div>
        )}
      </main>

      <Onboarding
        show={showOnboarding}
        setShowOnboarding={setShowOnboarding}
        onClose={() => setShowOnboarding(false)}
        path={path}
        profiles={profiles}
        title={t('onboarding.titleCourse')}
      />
    </>
  );
}

export { Course as default, CourseMonitoring, CourseAbout, CourseRecommended, CourseCertificate, ModuleAbout };
