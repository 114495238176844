import { CKEditor } from '@ckeditor/ckeditor5-react';
import getPermission from 'app/utils/getPermission';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { t } from 'i18next';
import React, { useMemo } from 'react';
import { FiSend, FiX } from 'react-icons/fi';

export default function MessageForm(props) {
  const { messageForm, lessonId, createMessage, auditoriumId, auditorium, currentUser, isAdmin } = props;

  const hasFile = messageForm?.values?.file;

  const handleSubmit = e => {
    e.preventDefault();
    if (!messageForm.values.body && !hasFile) {
      return;
    }
    createMessage(lessonId, messageForm.values, auditoriumId);
    messageForm.setFieldValue('body', '');
    messageForm.setFieldValue('file', null);
  };

  const handleFileRemove = () => {
    messageForm.setFieldValue('file', null);
  };

  const shouldRenderOptions = useMemo(() => {
    if (auditorium?.['authored-by-user'] === true) {
      if (auditorium?.creator?.id !== currentUser.id) {
        return false;
      } else return true;
    }

    if (auditorium?.['authored-by-user'] === false) {
      if (isAdmin && getPermission('Interagir com auditório', 'Auditórios')) {
        return true;
      } else return false;
    }
  }, [auditorium, isAdmin]);

  return (
    <div className="lesson-widget__footer">
      <form
        className="lesson-widget__message-form"
        onSubmit={handleSubmit}
        onClick={() => {
          messageForm.setFieldValue(body, messageForm.values.body);
        }}
      >
        <div className="lesson-widget__rich-text-editor">
          {shouldRenderOptions && (
            <CKEditor
              editor={Editor}
              data={messageForm.values.body}
              config={{
                placeholder: t('auditorium.placeholderWriteMessageHere')
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                messageForm.setFieldValue('body', data);
              }}
            />
          )}

          <div className="lesson-widget__file">
            {shouldRenderOptions && (
              <label
                className="form__custom-file-input"
                style={{ pointerEvents: hasFile ? 'none' : 'all' }}
              >
                <input
                  type="file"
                  name="file"
                  id="file"
                  onChange={e => messageForm.setFieldValue('file', e.target.files[0])}
                />

                <span className={hasFile ? 'btn btn--small btn--outline disabled' : 'btn btn--small btn--outline'}>{t('auditorium.buttonAttachFile')}</span>
              </label>
            )}

            {hasFile && (
              <div className="lesson-widget__file-preview">
                <span className="lesson-widget__file-name">{messageForm?.values?.file?.name ?? messageForm?.values?.file?.url}</span>
                <span
                  className="card__icon-wrapper u-cursor-pointer"
                  onClick={handleFileRemove}
                >
                  <FiX className="card__icon" />
                </span>
              </div>
            )}
          </div>
        </div>
        {shouldRenderOptions && (
          <button
            className="btn btn--primary btn--send"
            type="submit"
          >
            <FiSend />
          </button>
        )}
      </form>
    </div>
  );
}
