import { Handle, Position } from "reactflow"
import AddNodeButton from "./AddNodeButton"
import FlowModal from '../modals/FlowModal'
import AddEditNodeForm from '../modals/AddEditNodeForm'
import { useState } from 'react'
import EmployeeContainer from './EmployeeContainer'
import Dropdown from "app/components/Dropdown"

import Swal from 'sweetalert2';
import { deleteNodeById } from "../utils/api"
import { useClient } from "jsonapi-react"

const Node = ({ data }) => {
    const client = useClient()
    const editNode = () => {
        data.openModal(data)
    }

    const newNode = () => {
        data.openModal({"organization-structure-id": data["organization-structure-id"], "organizational-chart-parent-id": data.id, afterSubmit: data.afterSubmit})
    }

    const deleteNode = async () => {
        try {
            await deleteNodeById(client, data["organization-structure-id"], data.id)
        } catch (e) {
            console.error(e)
        } finally {
            data.afterSubmit()
        }
    }

    const confirmDelete = event => {
        event.stopPropagation();
        Swal.fire({
          title: 'Deseja deletar a Lotação e Usuarios',
          text: 'Essa alteração não pode ser revertida',
          confirmButtonText: 'Sim',
          showDenyButton: true,
          denyButtonText: 'Não',
          showCloseButton: true
        }).then(res => {
          if (res.isConfirmed) {
            deleteNode();
          }
        })
      } 
    return (
        <div className="node" style={{borderColor: data.borderColor}}>
            <Handle type="target" position={Position.Top} style={{ opacity: 0, marginTop: '10px' }} />
            <div className="edit-delete--button"> 
                <Dropdown>
                    <Dropdown.Toggle>
                        ...
                    </Dropdown.Toggle>

                    <Dropdown.Content>
                        <Dropdown.Item onClick={editNode}> Editar </Dropdown.Item>
                        {data["organizational-chart-parent-id"] && <Dropdown.Item onClick={confirmDelete}> Deletar </Dropdown.Item>}
                    </Dropdown.Content>
                </Dropdown>
            </div>
            <span className="node__role">{data.role}</span>
            <EmployeeContainer employees={data.user} openModalEmployees={() => data.openModalEmployees(data)} />
            <Handle type="source" position={Position.Bottom} style={{ opacity: 0, marginBottom: '10px' }} />
            <div className="add-node-button-container" onClick={newNode}>
                <AddNodeButton />
            </div>
        </div>
    )
}

export default Node