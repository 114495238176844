import React, { useRef, useEffect } from 'react'

const FlowModal = ({ children, onClose, isVisible }) => {
    const modalRef = useRef(null)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose()
            }
        }

        const handleEscapeKey = (event) => {
            if (event.keyCode === 27) {
                onClose()
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        document.addEventListener('keydown', handleEscapeKey)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
            document.removeEventListener('keydown', handleEscapeKey)
        }
    }, [onClose])

    return (
        <div className="flow-modal" >
            <div className="flow-modal__container" ref={modalRef}>
                {children}
            </div>
        </div>
    )
}

export default FlowModal