import React, { useState, useEffect } from 'react';
import TabNavBar from './TabNavBar';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import { useFormik } from 'formik';
import { useClient } from 'jsonapi-react';
import { FiAlertCircle } from 'react-icons/fi';
import { Link } from '@reach/router';
import { useAgenda } from 'app/hooks/useAgenda';
import Calendar from 'app/components/Calendar';
import EmptyState from 'app/components/EmptyState';
import { useTranslation } from 'react-i18next';
import { i18n } from 'app/components/i18n/i18n';
import BreadCrumbs from 'app/components/BreadCrumbs';

export default function ManageByCourse(props) {
  const { t } = useTranslation();
  const { selectedTab, uri } = props;
  const { weeklyOvertime, currentSelectedWeek, getWeeklyOvertime } = useAgenda();
  const basePath = window.env.REACT_APP_BASE_PATH;

  const [relationships, setRelationships] = useState({
    courses: []
  });
  const [limitAlertMessage, setLimitAlertMessage] = useState(false);
  const CourseTasks = true;

  const client = useClient();

  const filters = useFormik({
    initialValues: {
      course: 'blank'
    }
  });

  const getRelationships = async () => {
    const { data: courses } = await client.fetch('courses');

    setRelationships({
      courses: courses || []
    });
  };

  useEffect(() => {
    getRelationships();
  }, []);

  useEffect(() => {
    getWeeklyOvertime(currentSelectedWeek);
    checkWeeklyOvertime(weeklyOvertime);
  }, [currentSelectedWeek]);

  function checkWeeklyOvertime() {
    if ((weeklyOvertime?.overtimeStudentCount || 0) !== 0) {
      setLimitAlertMessage(true);
    }
  }

  const breadCrumbs = {
    title: 'Atividades',
    nav: [
      {
        route: uri,
        name: 'Tarefas',
        isActive: true
      }
    ]
  };

  return (
    <main className="main-content main-content--block">
      <BreadCrumbs data={breadCrumbs} />

      <TabNavBar selectedTab={selectedTab} />

      <div className="tab__pane">
        <div className="filter-bar">
          <FilterSelectionBox
            blankLabel={t('filter.labelSelectCourse')}
            label={t('exams.thCourse')}
            value={filters.values.course}
            onChange={e => filters.setFieldValue('course', e.target.value)}
            options={relationships.courses.map(o => ({
              id: o.id,
              name: o.title
            }))}
          />

          {limitAlertMessage && (
            <div className="filter-bar__box u-ml-auto fadeIn">
              <span className="filter-bar__icon">
                <FiAlertCircle />
              </span>
              <span>
                <strong>
                  Atenção! {weeklyOvertime?.overtimeStudentCount} estudante(s) excederam o limite de 8h diárias
                  <br /> de tarefas
                </strong>{' '}
                na semana de {currentSelectedWeek?.sunday.format('DD/MM')} a {currentSelectedWeek?.saturday.format(i18n.t('datesForm.formatDate'))}.
                <br /> <Link to={`${basePath}/gestao-de-tarefa/curso/tarefas`}>Clique aqui</Link> para gerenciar as tarefas dos estudantes
              </span>
            </div>
          )}
        </div>

        {filters.values.course === 'blank' && (
          <EmptyState
            type="select"
            text={t('emptyState.selectCourseToStart')}
          />
        )}

        {filters.values.course !== 'blank' && (
          <div className="home-content">
            <Calendar.Expanded
              CourseTasks={CourseTasks}
              selectedTab={selectedTab}
              setLimitAlertMessage={setLimitAlertMessage}
              filters={filters}
              slotType="master_slot"
            />
          </div>
        )}
      </div>
    </main>
  );
}
