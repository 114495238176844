import React, { useState } from 'react';
import { FiVideo } from 'react-icons/fi';
import { BiJoystick } from 'react-icons/bi';
import EmptyState from '../EmptyState';
import { t } from 'i18next';

export default function LogConquestActivities({ challenges }) {
  return (
    <div className="log-conquest-activities">
      <div className="log-conquest-activities__header">
        <h3 className="log-conquest-activities__title">Conquistas da semana</h3>
      </div>

      <div className="log-conquest-activities__body u-mb-0">
        {challenges && challenges.length === 0 ? (
          <EmptyState
            type="data"
            text={t('emptyState.achievementsText')}
          />
        ) : (
          challenges.map((item, i) => {
            if (item.done) {
              return (
                <div
                  className="log-conquest-activities__activity"
                  key={i}
                >
                  <span className="log-conquest-activities__icon">
                    <BiJoystick />
                  </span>
                  <div className="log-conquest-activities__activity-content">
                    <span className="log-conquest-activities__activity-title">{item.title}</span>
                    <span className="log-conquest-activities__activity-name">{item.user.name}</span>
                  </div>
                  <span className="log-conquest-activities__activity-value">{item.done ? `${item['challenge-attempt-items'][0].score}pts` : ''}</span>
                </div>
              );
            }
          })
        )}
      </div>
    </div>
  );
}
