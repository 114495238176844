import React from 'react';

function Select(props) {
  const { disabled, label = 'Selecione', value, onChange, blankLabel = 'Selecione para filtrar', optionBlank = 'blank', options, ...restProps } = props;

  return (
    <select
      className={disabled ? 'report-form__select report-form__select--disabled' : 'report-form__select'}
      aria-label={label}
      name={label}
      id={label}
      value={value || ''}
      onChange={onChange}
      {...restProps}
    >
      {blankLabel && <option value={optionBlank}>{blankLabel}</option>}
      {options?.map(option => (
        <option
          key={option.id}
          value={option.id}
        >
          {option.name ?? option.title ?? option.body}
        </option>
      ))}
    </select>
  );
}

function ReportForm(props) {
  const { children, ...restProps } = props;

  return (
    <form
      className="report-form"
      {...restProps}
    >
      {children}
    </form>
  );
}

ReportForm.Select = Select;

export { ReportForm as default };
