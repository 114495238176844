import React, { useEffect, useState } from 'react';
import { FiStar } from 'react-icons/fi';
import InfoWidget from 'app/components/Widget/InfoWidget';
import StarRating from './StarRating';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { useFormik } from 'formik';
import moment from 'moment';
import 'moment/locale/pt';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import EmptyState from 'app/components/EmptyState';
import { Link } from '@reach/router';
import userAvatar from 'app/images/user-avatar.svg';
import { useTranslation } from 'react-i18next';

export default function StudentListTab(props) {
  const { t } = useTranslation();
  const { surveyData } = props;
  const client = useClient();
  const [isLoading, setIsLoading] = useState(true);
  const [surveyReport, setSurveyReport] = useState([]);
  const [student, setStudent] = useState([]);
  const [surveyQuestions, setSurveyQuestions] = useState([]);
  const basePath = window.env.REACT_APP_BASE_PATH;

  const filters = useFormik({
    initialValues: {
      question: 'blank',
      rate: 'blank'
    }
  });

  useEffect(() => {
    getSurveyReport();
    getSurveyQuestions();
  }, [surveyData]);

  useEffect(() => {
    getStudent();
  }, []);

  const getSurveyReport = async () => {
    if (surveyData.id) {
      setIsLoading(true);
      const { data } = await client.fetch(['surveys', surveyData.id, 'report']);
      setSurveyReport(data);
      setIsLoading(false);
    }
  };

  const getStudent = async () => {
    if (surveyData.id) {
      setIsLoading(true);
      const { data, error } = await client.fetch(`survey_answers?filter[survey_id]=${surveyData.id}`);
      if (error) {
        toast.error('Erro ao buscar perguntas.');
      } else {
        setStudent(data);
        setIsLoading(false);
      }
    }
  };

  const getSurveyQuestions = async () => {
    if (surveyData.id) {
      setIsLoading(true);
      const { data } = await client.fetch(`survey_questions?filter[survey_id]=${surveyData.id}`);
      setSurveyQuestions(data);
      setIsLoading(false);
    }
  };

  const quizStatusClass = () => {
    switch (surveyData?.about?.status) {
      case 'Inativo':
        return 'activity-card__status--danger';
        break;
      case 'Ativo':
        return 'activity-card__status--success';
        break;
    }
  };

  return (
    <div className="chart-widget fadeIn u-mb-3">
      <div
        className="chart-widget__container u-mb-4"
        style={{ alignItems: 'center' }}
      >
        <div
          className="chart-widget__group"
          style={{ gap: '0' }}
        >
          <div className="chart-widget__meta">
            <span className={`activity-card__status ${quizStatusClass()} u-mr-2`}>{surveyData?.about?.status}</span>
          </div>

          <h3 className="chart-widget__title chart-widget__title--lg">{surveyData?.title}</h3>
        </div>
        <div className="widget-wrapper widget-wrapper--sm-space">
          <InfoWidget
            title={t('nps.totalResponses')}
            className="info-widget--light"
          >
            <InfoWidget.Value>{surveyData?.about?.['answers-count']}</InfoWidget.Value>
          </InfoWidget>

          <InfoWidget
            title={t('nps.totalQuestions')}
            className="info-widget--light"
          >
            <InfoWidget.Value>{surveyData?.about?.['questions-count']}</InfoWidget.Value>
          </InfoWidget>
        </div>
      </div>

      <div
        className="chart-widget__container u-mb-4"
        style={{ alignItems: 'flex-start' }}
      >
        <div
          className="chart-widget__group"
          style={{ gap: '0' }}
        >
          <h3 className="chart-widget__title chart-widget__title--lg">{surveyQuestions.find(item => item.id === parseInt(filters.values.question))?.question}</h3>
          <span className="activity-card__scale">
            {t('nps.graphicScale')}: <FiStar />
          </span>
        </div>
        <select
          name="question"
          id="question"
          className="filter-bar__select"
          onChange={e => filters.setFieldValue('question', e.target.value)}
        >
          <option value="blank">{t('nps.question')}</option>
          {surveyQuestions.map(surveyQuestions => (
            <option
              key={surveyQuestions.id}
              value={surveyQuestions.id}
            >
              {surveyQuestions.question}
            </option>
          ))}
        </select>

        <select
          name="rate"
          id="rate"
          className="filter-bar__select"
          onChange={e => filters.setFieldValue('rate', e.target.value)}
        >
          <option value="blank">{t('nps.filterResponseSent')}</option>
          <option value="1">1 {t('nps.star')}</option>
          <option value="2">2 {t('nps.stars')}</option>
          <option value="3">3 {t('nps.stars')}</option>
          <option value="4">4 {t('nps.stars')}</option>
          <option value="5">5 {t('nps.stars')}</option>
        </select>
      </div>

      <div className="round-dg-wrapper">
        <table className="round-dg round-dg--light">
          <thead className="round-dg__header">
            <tr className="round-dg__row">
              <th className="round-dg__cell-header">{t('textsCommon.name')}</th>
              <th className="round-dg__cell-header">{t('textsCommon.response')}</th>
              <th className="round-dg__cell-header">{t('nps.yearCourse')}</th>
              <th className="round-dg__cell-header">{t('textsCommon.class')}</th>
              <th className="round-dg__cell-header">{t('textsCommon.dateResponse')}</th>
            </tr>
          </thead>

          <tbody className="round-dg__body">
            {filters.values.question !== 'blank' &&
              student.map((student, i) => {
                const surveyItem = student['survey-items'].find(item => item.survey_question_id === parseInt(filters.values.question) && (filters.values.rate === 'blank' || item.rate === parseInt(filters.values.rate)));
                return (
                  surveyItem && (
                    <tr
                      className="round-dg__row"
                      key={i}
                    >
                      <th className="round-dg__cell-header round-dg__cell-header--row">
                        <div className="round-dg__user">
                          <Link
                            to={`${basePath}/usuario/metricas/${student.id}`}
                            state={{ isRedirected: true }}
                          >
                            <img
                              className="avatar avatar--sm"
                              src={student.user.url || userAvatar}
                              alt={student.user.name}
                            />
                          </Link>

                          <div className="round-dg__user-inner">
                            <h3
                              className="round-dg__title"
                              title={student.user.name}
                            >
                              <Link
                                to={`${basePath}/usuario/metricas/${student.id}`}
                                state={{ isRedirected: true }}
                              >
                                {student.user.name}
                              </Link>
                            </h3>
                            {/* <p className="round-dg__subtitle">email</p> */}
                          </div>
                        </div>
                      </th>

                      <td className="round-dg__cell">
                        <StarRating rating={surveyItem?.rate} />
                      </td>

                      <td className="round-dg__cell">
                        {student.user.courses.length > 0 && (
                          <>
                            {`${student.user.courses[0]} `}
                            {student.user.courses.length > 1 && (
                              <span
                                className="badge badge--tiny badge--more"
                                title={student.user.courses.map(course => course)}
                                style={{ verticalAlign: 'sub' }}
                              >
                                +{student.user.courses.length - 1}
                              </span>
                            )}
                          </>
                        )}
                      </td>

                      <td className="round-dg__cell">
                        {student.user.classrooms.length > 0 && (
                          <>
                            {`${student.user.classrooms[0]} `}
                            {student.user.classrooms.length > 1 && (
                              <span
                                className="badge badge--tiny badge--more"
                                title={student.user.classrooms.map(classroom => classroom)}
                                style={{ verticalAlign: 'sub' }}
                              >
                                +{student.user.classrooms.length - 1}
                              </span>
                            )}
                          </>
                        )}
                      </td>

                      <td className="round-dg__cell">{moment(student['updated-at']).format(t('datesForm.formatDate'))}</td>
                    </tr>
                  )
                );
              })}
          </tbody>
        </table>
        {filters.values.question === 'blank' && (
          <EmptyState
            type="select"
            text={t('emptyState.selectQuestionAnalysis')}
          />
        )}
      </div>
    </div>
  );
}
