import { navigate, useParams } from '@reach/router';
import { Link } from '@reach/router';
import { i18n } from 'app/components/i18n/i18n';
import * as Yup from 'yup';
import { useSession } from 'app/hooks/useSession';
import { useFormik } from 'formik/dist';
import { useTranslation } from 'react-i18next';
import { useClient } from 'jsonapi-react';
import React, { useEffect } from 'react';
import { useState } from 'react';
import toast from 'react-hot-toast';
import InputMask from 'react-input-mask';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { FilterSelectionBox } from 'app/components/FilterSelectionBox';
import Select from 'react-select';
import { validateCPF } from 'app/utils/validateCPF';
import { SignInEtipi } from 'app/components/SignInEtipi';
import Error404 from 'app/components/Error404';

export default function CreateAccount() {
  const hasETIPI = window.env.REACT_APP_HAS_ETIPI_INTEGRATION;
  const canCreateAccount = window.env.REACT_APP_ALLOW_CREATE_USER;
  const basePath = window.env.REACT_APP_BASE_PATH;

  if (canCreateAccount === 'false') {
    return <Error404 />;
  }

  if (hasETIPI) {
    return <SignInEtipi />;
  }

  const { t } = useTranslation();
  const client = useClient();
  const { session, signIn } = useSession();
  const [showPassword, setShowPassword] = useState(false);
  const [institutions, setInstitutions] = useState([]);
  const [courses, setCourses] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(true);
  const appName = window.env.REACT_APP_NAME;
  const logo = require(`app/images/${appName}/horizontal-logo.svg`);
  const backimage = appName === 'canaleducacao' || appName === 'caboverde' ? require(`app/images/${appName}/login-bg.png`) : require(`app/images/login-bg.jpg`);
  const params = useParams();
  const { link } = params;
  const [loading, setLoading] = useState(false);
  const institutionsRadioString = ['Já sou formado', 'Sem escola'];
  const institutionsRadioButton = institutions.filter(item => institutionsRadioString.includes(item.name));
  const filteredInstitutions = institutions.filter(item => item.name !== 'Já sou formado' && item.name !== 'Sem escola');

  const schema = Yup.object({
    cpfCnpj: Yup.lazy(value => {
      switch (typeof value) {
        case 'undefined':
          return Yup.string().notRequired().nullable();
        case 'string':
          return Yup.string().test('validCPF', 'CPF Inválido', value => validateCPF(value));
        default:
          throw new Yup.ValidationError('CPF Inválido');
      }
    }),
    institution: Yup.mixed().required(t('warning.requiredField')),
    ra: Yup.string().nullable().notRequired(),
    name: Yup.string().required(t('warning.requiredField')),
    email: Yup.string().required(t('warning.requiredField')),
    phone: Yup.string().nullable().notRequired(),
    classroom_ids: Yup.string().required(t('warning.requiredField')),
    password: Yup.string().required(t('warning.requiredField')),
    course: Yup.string().required(t('warning.requiredField'))
  });

  const authenticateUser = async () => {
    const requestParams = { ...studentForm.values };
    requestParams.classroom_ids = [requestParams.classroom_ids];
    delete requestParams.course;
    delete requestParams.institution;
    const { error } = await client.mutate('users', requestParams);
    if (error) {
      toast.error(error.title);
    } else {
      toast.success('Conta criada com sucesso!');
      signIn(studentForm.values);
    }
  };

  const studentForm = useFormik({
    initialValues: {
      'selected-profile-name': 'Aluno',
      classroom_ids: '',
      cpfCnpj: '',
      ra: '',
      name: '',
      email: '',
      phone: '',
      password: '',
      link_key: link,
      institution: '',
      course: ''
    },
    validationSchema: schema,
    onSubmit: authenticateUser
  });

  const getInstitutions = async () => {
    const { data, error } = await client.fetch('/institutions');
    if (error) {
      toast.error('Erro ao buscar instituições');
    } else {
      setInstitutions(data);
    }
  };

  const getCourses = async () => {
    const { data, error } = await client.fetch('/courses');
    if (error) {
      toast.error('Erro ao buscar cursos');
    } else {
      setCourses(data);
    }
  };

  const getClassrooms = async () => {
    const url = `/classrooms?filter[institution_id]=${studentForm.values.institution?.value}&filter[course_id]=${studentForm.values.course}`;
    const { data, error } = await client.fetch(url);
    if (error) {
      toast.error('Erro ao buscar turmas');
    } else {
      setClassrooms(data);
      studentForm.setFieldValue('classroom_ids', data[0]?.id);
    }
  };

  const updateUser = async () => {
    const requestParams = {
      link_key: studentForm.values.link_key
    };
    const { error } = await client.mutate(`users/${session.user.id}`, requestParams);
    if (error) {
      toast.error('Erro ao aceitar o convite.');
    } else {
      toast.success('Convite aceito com sucesso!');
      navigate(basePath);
    }
  };

  useEffect(() => {
    getInstitutions();
    getCourses();
  }, []);

  useEffect(() => {
    if (studentForm.values.course !== '' && studentForm.values.institution?.value !== '') {
      getClassrooms();
    }
  }, [studentForm.values.course, studentForm.values.institution?.value]);

  useEffect(() => {
    if (session !== null && link) {
      updateUser();
    }
  }, [session, link]);

  useEffect(() => {
    if (session && Object.keys(session).length !== 0) {
      const redirectUrl = localStorage.getItem('redirectUrl');
      window.location.pathname = redirectUrl || '/';
    }
  }, [session]);

  useEffect(() => {
    if (selectedRadio) {
      studentForm.setFieldValue('course', courses[0].id);
    }
  }, [selectedRadio]);

  return (
    <div className="new-login">
      <div className="new-login__container">
        <div className="new-login__body">
          <div className="new-login__body-header">
            <div className="new-login__body-logo">
              <img
                alt="Logo Estudologia"
                src={logo}
              />
            </div>
            <div className="new-login__body-title">{t('createAccount.createYourAccount')}</div>
          </div>
          <div className="new-login__body-form">
            <form
              className="form"
              method="post"
              onSubmit={studentForm.handleSubmit}
            >
              {/* {hasETIPI && (
                <>
                  <div className="form__row">
                    <label
                      className="form__label"
                      htmlFor="cpfCnpj"
                    >
                      CPF
                    </label>
                    <InputMask
                      className="form__control"
                      name="cpfCnpj"
                      id="cpfCnpj"
                      mask="999.999.999-99"
                      placeholder="CPF"
                      value={studentForm.values.cpfCnpj}
                      onBlur={handleBlurCPF}
                      onChange={e => studentForm.setFieldValue('cpfCnpj', e.target.value)}
                    />
                    {(studentForm.values.cpfCnpj?.replaceAll('_', '').length === 14 || studentForm.errors.cpfCnpj) && <span style={{ color: 'red' }}>{studentForm.errors.cpfCnpj}</span>}
                  </div>
                  <div className="form__row">
                    <label
                      className="form__label"
                      htmlFor="ra"
                    >
                      RA (Registro Acadêmico)
                    </label>
                    <InputMask
                      disabled={loading}
                      className="form__control"
                      mask="99999999999"
                      name="ra"
                      id="ra"
                      placeholder="RA (Registro Acadêmico)"
                      value={studentForm.values.ra}
                      onBlur={handleBlurRA}
                      onChange={e => studentForm.setFieldValue('ra', e.target.value)}
                    />
                    {studentForm.values.ra?.replaceAll('_', '').length === 11 && studentForm.errors.ra && <span style={{ color: 'red' }}>{studentForm.errors.ra}</span>}
                  </div>
                </>
              )} */}

              <div className="form__row">
                <label
                  className="form__label"
                  htmlFor="name"
                >
                  {t('createAccount.name')}
                </label>
                <input
                  disabled={loading}
                  className="form__control disabled"
                  type="text"
                  name="name"
                  id="name"
                  value={studentForm.values.name}
                  onChange={e => studentForm.setFieldValue('name', e.target.value)}
                />
                {studentForm.touched.name && studentForm.errors.name && <span style={{ color: 'red' }}>{studentForm.errors.name}</span>}
              </div>
              <div className="form__row">
                <label
                  className="form__label"
                  htmlFor="email"
                >
                  * Email:
                </label>
                <input
                  disabled={loading}
                  className="form__control disabled"
                  type="email"
                  name="email"
                  id="email"
                  value={studentForm.values.email}
                  onChange={e => studentForm.setFieldValue('email', e.target.value)}
                />
                {studentForm.touched.email && studentForm.errors.email && <span style={{ color: 'red' }}>{studentForm.errors.email}</span>}
              </div>

              <div className="form__row">
                <label
                  className="form__label"
                  htmlFor="cellphone"
                >
                  {t('createAccount.cellphone')}
                </label>
                <InputMask
                  disabled={loading}
                  className="form__control"
                  mask="(99) 99999-9999"
                  name="cellphone"
                  id="cellphone"
                  value={studentForm.values.phone}
                  onChange={e => studentForm.setFieldValue('phone', e.target.value)}
                />
                {studentForm.touched.phone && studentForm.errors.phone && <span style={{ color: 'red' }}>{studentForm.errors.phone}</span>}
              </div>
              <div className="form__row">
                <label className="form__label">Escola:</label>
                <Select
                  disabled={loading}
                  // defaultValue={studentForm.values.institution}
                  value={studentForm.values.institution}
                  openMenuOnFocus
                  options={[
                    { value: '', label: t('filter.blankLabelAll') },
                    ...filteredInstitutions?.map(item => {
                      return { value: item.id, label: item.name };
                    })
                  ]}
                  className="react-multi-select filter-bar__multi-select u-w-100"
                  classNamePrefix="react-multi-select"
                  placeholder={t('filter.blankLabelAll')}
                  noOptionsMessage={() => 'Sem opções'}
                  components={{
                    IndicatorSeparator: () => null,
                    ClearIndicator: () => null
                  }}
                  onChange={e => {
                    studentForm.setFieldValue('institution', e);
                    setSelectedRadio(false);
                  }}
                />
                {studentForm.touched.institution && studentForm.errors.institution && <span style={{ color: 'red' }}>{studentForm.errors.institution}</span>}
              </div>

              <div className="form__row">
                {institutionsRadioButton.map(institution => (
                  <div className="form__check form__check--inline">
                    <input
                      disabled={loading}
                      className="form__check-input"
                      type="radio"
                      id={`institution-${institution.id}`}
                      name="institution-radio"
                      checked={institution.id === studentForm.values.institution?.value}
                      onChange={() => {
                        studentForm.setFieldValue('institution', { value: institution.id, label: institution.name });
                        setSelectedRadio(true);
                      }}
                    />
                    <label htmlFor={`institution-${institution.id}`}>{institution.name}</label>
                  </div>
                ))}
              </div>

              <div className="form__Row u-mb-3">
                <label className="form__label">{t('textsCommon.course')}:</label>
                <FilterSelectionBox
                  value={studentForm.values.course}
                  disabled={studentForm.values.institution === '' || selectedRadio}
                  options={courses}
                  onChange={e => studentForm.setFieldValue('course', e.target.value)}
                  className="u-w-100"
                />
                {studentForm.touched.course && studentForm.errors.course && <span style={{ color: 'red' }}>{studentForm.errors.course}</span>}
              </div>
              <div className="form__Row u-mb-3">
                <label className="form__label">{t('textsCommon.class')}:</label>
                <FilterSelectionBox
                  value={studentForm.values.classroom_ids}
                  onChange={e => studentForm.setFieldValue('classroom_ids', e.target.value)}
                  options={classrooms}
                  disabled={studentForm.values.course === '' || studentForm.values.institution === '' || selectedRadio}
                  className="u-w-100"
                />
                {studentForm.touched.classroom_ids && studentForm.errors.classroom_ids && <span style={{ color: 'red' }}>{studentForm.errors.classroom_ids}</span>}
              </div>

              <div className="form__row">
                <label
                  className="form__label"
                  htmlFor="password"
                >
                  * {i18n.t('login.password')}
                </label>
                <div className="form__row--password">
                  <input
                    disabled={loading}
                    title={i18n.t('profile.typeYourPass')}
                    className="form__control "
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    id="password"
                    onChange={e => studentForm.setFieldValue('password', e.target.value)}
                  />
                  <span onClick={() => setShowPassword(!showPassword)}>{showPassword ? <FiEyeOff /> : <FiEye />}</span>
                </div>
                {studentForm.touched.password && studentForm.errors.password && <span style={{ color: 'red' }}>{studentForm.errors.password}</span>}
              </div>

              <label
                className="form__row back-login label-terms"
                htmlFor="agreeTerms"
              >
                <input
                  type="radio"
                  name="agreeTerms"
                  id="agreeTerms"
                  checked={agreeTerms}
                  onClick={() => setAgreeTerms(!agreeTerms)}
                />
                {t('login.termsText')}{' '}
                <a
                  href={`${basePath}/termos-de-uso`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('login.userTerm')}{' '}
                </a>
                {t('exams.and')}{' '}
                <a
                  href={`${basePath}/politica-de-privacidade`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('login.privacyTerm')}
                </a>
                .
              </label>

              <div className="form__row">
                <input
                  type="submit"
                  value={t('createAccount.buttonCreateAccount')}
                />
              </div>
              <Link
                to={`${basePath}/login/${link}`}
                className="form__row rember-link"
              >
                {t('createAccount.loginWithMyAccount')}
              </Link>
            </form>
          </div>
        </div>
      </div>
      <div className="new-login__image">
        <div
          className="new-login__login-image"
          style={{ backgroundImage: `url(${backimage})` }}
        ></div>
      </div>
    </div>
  );
}
