import { Position } from 'reactflow';

const CustomNode = ({ id, role, user, afterSubmit, organizationalChartParentId, organizationStructureId, openModal, setIsEdit, selectedNode, organizationalStructure, openModalEmployees, borderColor }) => {
  return {
    id: id,
    type: 'customNode',
    data: {
      id: id,
      role: role,
      user: user,
      'organizational-chart-parent-id': organizationalChartParentId,
      'organization-structure-id': organizationStructureId,
      afterSubmit: afterSubmit,
      openModal: openModal,
      organizationalStructure: organizationalStructure,
      isEdit: setIsEdit,
      openModalEmployees,
      selectedNode,
      borderColor
    },
    sourcePosition: Position.Bottom,
    targetPosition: Position.Top,
    position: { x: 0, y: 0 }
  };
};

export default CustomNode;
