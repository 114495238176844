import React, { useState, useEffect } from 'react';
import MainNav from '../components/mainNav';
import MainSidebar from '../components/mainSidebar';
import GroupedQuestionOpen from '../components/groupedQuestionOpen';
import Loader from '../components/loader';
import Modal from 'app/components/Modal/Simulated';
import { useSession } from '../hooks/useSession';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import moment from 'moment';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

function Questions(props) {
  const { t } = useTranslation();
  const client = useClient();
  const { session } = useSession();
  const [isLoading, setIsLoading] = useState(true);
  const [showFinishModal, setShowFinishModal] = useState(false);
  const [questionBook, setQuestionBook] = useState();
  const [questionBooksUser, setQuestionBooksUser] = useState();
  const [questionBookTaxonomies, setQuestionBookTaxonomies] = useState([]);
  const [activeGroupedQuestions, setActiveGroupedQuestions] = useState(0);
  const [lastAnswerSubmited, setLastAnswerSubmited] = useState();

  useEffect(() => {
    try {
      getQuestionBook();
    } catch (e) {
      console.error(e);
      toast.error('Erro ao buscar prova do OPEN.');
    }
  }, []);

  const isBetweenOpenDays = questionBook => {
    return questionBook.visibility === 'open' && moment().isBetween(questionBook['published-at'], questionBook['finished-at'], undefined, true);
  };

  const groupQuestionsByCode = items => {
    if (items.length > 0) {
      return _.groupBy(items, 'code');
    }
  };

  const selectQuestionBookTaxonomy = newIndex => {
    setActiveGroupedQuestions(newIndex);
    window.scrollTo(0, 0);
  };

  const getQuestionBook = async () => {
    setIsLoading(true);
    const filterQuestionBook = { filter: { visibility: 'open' }, current_user: session.user.id };
    const questionBookResponse = await client.fetch(['question_books', props.id, filterQuestionBook]);
    const questionBookId = questionBookResponse.data.id;
    setQuestionBook(questionBookResponse.data);

    if (isBetweenOpenDays(questionBookResponse.data)) {
      if (questionBookResponse.data['last-question-books-user-id']) {
        if (questionBookResponse.data['is-question-book-done']) {
          window.location.pathname = '/questions/finish';
        } else {
          const response = await client.fetch(['question_books_users', questionBookResponse.data['last-question-books-user-id']]);
          setQuestionBooksUser(response);
        }
      } else {
        const response = await client.mutate('question_books_users', { question_book_id: questionBookId });
        setQuestionBooksUser(response);
      }

      const queryParams = `filter[visibility]=open&filter[question_book_id]=${questionBookId}`;
      const { data } = await client.fetch(`questions?${queryParams}`);

      let questionsByTaxonomy = {};
      questionBookResponse.data['question-book-taxonomies'].forEach(qbt => {
        questionsByTaxonomy[qbt.taxonomy_id] = _.filter(data, q => qbt.question_ids.includes(q.id));
      });

      let questionTaxonomies = questionBookResponse.data.taxonomies;
      questionTaxonomies.forEach(questionTaxonomy => {
        const tQuestions = questionsByTaxonomy[questionTaxonomy.id];
        questionTaxonomy.selected = false;
        questionTaxonomy.questions = tQuestions;
        questionTaxonomy.groupedQuestions = groupQuestionsByCode(tQuestions);
      });

      questionTaxonomies[0].selected = true;
      setQuestionBookTaxonomies(questionTaxonomies);
    } else {
      window.location.pathname = '/simulados';
    }

    setIsLoading(false);
  };

  const updateLastAnswerSubmited = answer => {
    let questionIndex;
    const taxonomyIndex = _.findIndex(questionBook.taxonomies, taxonomy => {
      questionIndex = _.findIndex(taxonomy.questions, { id: answer.questionId });
      return questionIndex > -1;
    });

    let newQuestionBook = questionBook;
    const answerIndex = _.findIndex(newQuestionBook.taxonomies[taxonomyIndex].answers, { id: answer.id });

    if (answerIndex > -1) {
      newQuestionBook.taxonomies[taxonomyIndex].answers[answerIndex] = answer;
    } else {
      newQuestionBook.taxonomies[taxonomyIndex].answers.push(answer);
    }

    setQuestionBook(newQuestionBook);
    setLastAnswerSubmited(answer);
  };

  const finishOpen = () => {
    client
      .mutate(['question_books_users', questionBooksUser.id], { done: true })
      .then(response => {
        if (response.error) {
          toast.error(response.error.title);
        } else {
          window.location.pathname = '/questions/finish';
        }
      })
      .catch(error => {
        toast.error(t('toast.error'));
        console.error(error);
      });
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className="main-screen">
      <MainSidebar
        simulated
        enabledClock
        questionBook={questionBook}
        activeGroupedQuestions={activeGroupedQuestions}
        selectQuestionBookTaxonomy={selectQuestionBookTaxonomy}
        questionBooksUser={questionBooksUser}
        lastAnswerSubmited={lastAnswerSubmited}
        finishOpen={finishOpen}
      />
      <MainNav />
      <div className="main-content">
        <div className="question-list">
          {questionBook &&
            questionBook.taxonomies[activeGroupedQuestions].groupedQuestions &&
            Object.entries(questionBook.taxonomies[activeGroupedQuestions].groupedQuestions).map(([key, groupedQuestion]) => (
              <GroupedQuestionOpen
                key={key}
                answers={questionBook.taxonomies[activeGroupedQuestions].answers || []}
                groupedQuestion={groupedQuestion}
                questionBooksUser={questionBooksUser}
                updateLastAnswerSubmited={updateLastAnswerSubmited}
              />
            ))}
          <p>
            <strong>Para continuar selecione a próxima matéria no menu.</strong>
          </p>
          <br />
          <br />
          <br />
        </div>

        <div className="questions-options">
          <button
            onClick={() => setShowFinishModal(true)}
            className="btn btn--outline btn--full"
          >
            Finalizar simulado
          </button>
          {questionBook && (
            <Modal
              questionBook={questionBook}
              show={showFinishModal}
              onSubmit={finishOpen}
              onClose={() => setShowFinishModal(false)}
              title="Open"
              teacher="4º Secretariado"
              finalize={true}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Questions;
