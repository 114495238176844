import React from 'react';
import DefaultBanner from 'app/images/canaleducacao/Boas_vindas_CanalEducacao.png';
import EgepiBanner from 'app/images/egepi/EgepiBanner.png';

export default function WarningWall(props) {
  const { warning } = props;
  const appName = window.env.REACT_APP_NAME;

  return warning ? (
    <div className="warning-wall u-mb-4">
      <img
        className="warning-wall__img"
        src={warning?.image?.url}
        alt="imagem do aviso"
      />

      {warning?.link && warning?.image?.url && !warning?.title && !warning?.description && (
        <a
          className="btn btn--wide btn--outline"
          href={warning?.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          Acessar
        </a>
      )}

      {warning?.title && warning?.description && (
        <div className="warning-wall__content">
          <div className="warning-wall__header">
            <h3
              className="warning-wall__title"
              title={warning?.title}
            >
              {warning?.title}
            </h3>
          </div>

          <div className="warning-wall__body">
            <p
              className="warning-wall__description"
              title={warning?.description}
            >
              {warning?.description}
            </p>
          </div>

          {warning?.link && (
            <div className="warning-wall__footer">
              <a
                className="btn btn--wide btn--outline"
                href={warning?.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Acessar
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  ) : (
    <div className="warning-wall u-mb-4">
      <img
        className="warning-wall__img"
        src={appName === 'egepi' ? EgepiBanner : DefaultBanner}
        alt="imagem do aviso"
      />
    </div>
  );
}
