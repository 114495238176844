import React, { useState } from 'react';
import moment from 'moment';
import { Link } from '@reach/router';
import { useClient } from 'jsonapi-react';
import toast from 'react-hot-toast';
import { FiCalendar, FiXCircle } from 'react-icons/fi';
import closeIcon from '../../images/icons/close-icon.svg';
import { useSession } from 'app/hooks/useSession';

function MockQuestionBook(props) {
  const { mockQuestionBook, setShowMockQuestionBook, showMockQuestionBook } = props;
  const client = useClient();
  const { session, setSession } = useSession();
  const [showModal, setShowModal] = useState(true);
  const basePath = window.env.REACT_APP_BASE_PATH;

  const updateReminder = async () => {
    const requestParams = { show_question_book_banner: false };
    const { error, data } = await client.mutate(['user_details', session?.user?.userDetail?.id], requestParams);
    if (error) {
      toast.error('Erro ao atualizar preferências de usuário.');
    } else {
      let newObject = session.user;
      newObject.userDetail.showQuestionBookBanner = data['show-question-book-banner'];
      setSession({ user: newObject });
    }
    setShowMockQuestionBook(false);
    setShowModal(false);
  };

  return (
    showModal && (
      <section className="mock mock--overlay">
        <div className="mock__content">
          <div>
            {moment().isBefore(mockQuestionBook['published-at']) && <span className="badge badge--tiny badge--warning">Em breve</span>}
            <h1 className="mock__title">{mockQuestionBook.title}</h1>
          </div>
          <div className="mock__reminder">
            <input
              onClick={() => updateReminder()}
              type="checkbox"
              name="reminder"
              id="reminder"
            />
            <label for="reminder">Não mostrar novamente</label>
          </div>
          <div
            className="mock__close-btn"
            onClick={() => {
              setShowModal(false);
            }}
          >
            <FiXCircle />
          </div>
          <div className="clearfix" />
          <div className="card__columns">
            <p className="card__count">
              <FiCalendar />
              {moment(mockQuestionBook['published-at']).format('DD/MM')} - {moment(mockQuestionBook['published-at']).format('H[h]mm')}
            </p>
            <p className="card__count">
              <FiCalendar />
              {moment(mockQuestionBook['finished-at']).format('DD/MM')} - {moment(mockQuestionBook['finished-at']).format('H[h]mm')}
            </p>
          </div>
          <div className="card__columns">Responda ao simulado agora e desbloqueie seu plano de estudos personalizado! Não deixe para depois, seu futuro espera!</div>
          <div className="clearfix"></div>
          <div>
            <Link
              to={`${basePath}/simulados/sobre/${mockQuestionBook.id}`}
              className="btn btn--primary btn--wide"
            >
              Mais detalhes
            </Link>
          </div>
          <div className="mock__image"> </div>
        </div>
      </section>
    )
  );
}

export default MockQuestionBook;
