import { useClient } from 'jsonapi-react';
import React, { useState } from 'react';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import ParticipantItemCard from './ParticipantItemCard';
import Loader from 'app/components/loader';
import { t } from 'i18next';

export default function ParticipantsForm(props) {
  const { setShowModal, classroom, allUsers: classroomUsers, addParticipantToClassroom, deleteParticipantFromClassroom } = props;
  const classroomUserIds = classroomUsers.map(user => user.id);
  const [searchTerm, setSearchTerm] = useState('');
  const [otherUsers, setOtherUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState([]);
  const client = useClient();

  const getOtherUsers = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`users/full`);
    if (error) {
      toast.error(t('toast.errorGetParticipants'));
    } else {
      setOtherUsers(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getOtherUsers();
  }, []);

  useEffect(() => {
    const filterUser = otherUsers
      ?.filter(participant => {
        if (searchTerm.length < 3) {
          return participant;
        }
        return participant.name.toLowerCase().includes(searchTerm.toLowerCase());
      })
      .sort((a, b) => {
        if (classroomUserIds.includes(a.id)) {
          return -1;
        } else if (classroomUserIds.includes(b.id)) {
          return 1;
        } else {
          return 0;
        }
      });

    setFilteredUsers(filterUser);
  }, [searchTerm, otherUsers]);

  return (
    <form
      method="post"
      className="form"
      onSubmit={e => {
        e.preventDefault();
        setShowModal(false);
      }}
    >
      <h2 className="modal__simple-title">{t('courses.addParticpants')}</h2>

      <input
        style={{ position: 'sticky', top: '0' }}
        className="form__control u-mb-3"
        type="search"
        placeholder={t('courses.searchParticipants')}
        onChange={e => setSearchTerm(e.target.value)}
      />

      <div
        className="form__row u-overflow-y"
        style={{
          minHeight: '56px',
          maxHeight: '75vh',
          padding: '16px',
          margin: '0 -16px 1.5em -16px'
        }}
      >
        {loading && <Loader />}
        {!loading &&
          filteredUsers.map(user => (
            <ParticipantItemCard
              user={user}
              key={user.id}
              addParticipantToClassroom={addParticipantToClassroom}
              deleteParticipantFromClassroom={deleteParticipantFromClassroom}
              classroom={classroom}
            />
          ))}
      </div>
    </form>
  );
}
