import React from 'react';
import { Link } from '@reach/router';
import getPermission from 'app/utils/getPermission';
import { t } from 'i18next';

export default function TabNavBar({ selectedTab }) {
  const basePath = window.env.REACT_APP_BASE_PATH;
  return (
    <div className="tab">
      {getPermission('Visualizar lista de participantes', 'Participantes e perfis administrativos') && (
        <Link
          className={selectedTab === 'participantes' ? 'tab__link active' : 'tab__link'}
          to={`${basePath}/participantes`}
        >
          {t('sideBar.participants')}
        </Link>
      )}
      {getPermission('Visualizar lista e gerenciar perfis', 'Participantes e perfis administrativos') && (
        <Link
          className={selectedTab === 'perfis-administrativos' ? 'tab__link active' : 'tab__link'}
          to={`${basePath}/participantes/perfis-administrativos`}
        >
          {t('participants.toggleAdminProfiles')}
        </Link>
      )}

      {getPermission('Enviar notificações de engajamento', 'Participantes e perfis administrativos') && (
        <Link
          className={selectedTab === 'notificacoes' ? 'tab__link active' : 'tab__link'}
          to={`${basePath}/participantes/notificacoes`}
        >
          {t('participants.toggleNotifications')}
        </Link>
      )}

      {getPermission('Enviar notificações de engajamento', 'Participantes e perfis administrativos') && (
        <Link
          className={selectedTab === 'instituicoes' ? 'tab__link active' : 'tab__link'}
          to={`${basePath}/participantes/instituicoes`}
        >
          {t('participants.tabInstitutions')}
        </Link>
      )}
    </div>
  );
}
