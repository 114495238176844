import React, { useEffect, useState } from 'react';
import { navigate, useParams } from '@reach/router';
import StepName from './StepName';
import StepAbout from './StepAbout';
import StepImage from './StepImage/ImageStep';
import TipsSidebar from './TipsSidebar';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import * as yup from 'yup';
import { t } from 'i18next';
import promiseRequest from 'app/utils/promiseToast';

export default function CourseContent(props) {
  const { step, steps, currentStep, setCurrentStep } = props;
  const [imageLabel, setImageLabel] = useState();
  const [file, setFile] = useState();
  const client = useClient();
  const courseId = useParams().id;
  const [error, setError] = useState(false);
  const [course, setCourse] = useState([]);
  const { promiseToast, isSubmitting } = promiseRequest();
  const basePath = window.env.REACT_APP_BASE_PATH;

  const courseForm = useFormik({
    initialValues: {
      title: '',
      about: '',
      knowledge: '',
      selectedTaxonomies: [],
      image: '',
      'finished-at': ''
    },
    validationSchema: yup.object({
      title: yup.string().required(t('warning.requiredField')),
      about: yup.string().required(t('warning.requiredField')),
      knowledge: yup.string().required(t('warning.requiredField')),
      selectedTaxonomies: yup.array().min(1, t('warning.selectTaxonomy')),
      'finished-at': yup.string().required(t('warning.requiredField'))
    }),
    onSubmit: () => handleSubmit
  });

  const getCourse = async () => {
    const { data, error } = await client.fetch(`/courses/${courseId}`);
    if (error) {
      toast.error('Erro ao carregar curso');
    } else {
      setCourse(data);
    }
  };

  useEffect(() => {
    if (courseId) {
      getCourse();
    }
  }, []);

  useEffect(() => {
    if (courseId) {
      courseForm.setValues({
        title: course?.title,
        about: course?.about,
        knowledge: course?.knowledge,
        selectedTaxonomies: course?.taxonomies,
        image: course?.image?.url,
        'finished-at': course?.['finished-at']
      });
    }
  }, [course]);

  const createCourse = async () => {
    const path = courseId ? `/courses/${courseId}` : '/courses';
    const auxValues = { ...courseForm.values };
    auxValues['taxonomy-ids'] = auxValues?.selectedTaxonomies?.map(t => t.id);
    delete auxValues.selectedTaxonomies;

    promiseToast({
      url: path,
      request: auxValues,
      successText: courseId ? 'Curso editado com sucesso!' : 'Curso criado com sucesso!',
      errorText: 'Erro ao criar curso'
    }).then(data => navigate(`${basePath}/curso/admin/${data.id}`));

    // const { data, error } = await client.mutate(path, auxValues);
    // if (error) {
    //   toast.error('Erro ao criar curso');
    // } else {
    //   if (courseId) {
    //     toast.success('Curso editado com sucesso!');
    //   } else {
    //     toast.success('Curso criado com sucesso!');
    //   }

    // }
  };

  const handleSubmit = e => {
    e.preventDefault();
    switch (step) {
      case 'nome':
        if (courseId) {
          courseForm.setFieldTouched('title', true);
          if (courseForm.values.title !== '') {
            navigate(`${basePath}/curso/criar/sobre/${courseId}`);
            setCurrentStep(1);
            setError(false);
          } else {
            setError(true);
          }
          break;
        } else {
          courseForm.setFieldTouched('title', true);
          if (courseForm.values.title !== '') {
            navigate(`${basePath}/curso/criar/sobre`);
            setCurrentStep(1);
            setError(false);
          } else {
            setError(true);
          }
          break;
        }
      case 'sobre':
        if (courseId) {
          courseForm.setFieldTouched('about', true);
          courseForm.setFieldTouched('knowledge', true);
          courseForm.setFieldTouched('selectedTaxonomies', true);
          courseForm.setFieldTouched('finished-at', true);
          if (!courseForm.errors.about && !courseForm.errors.knowledge && !courseForm.errors.selectedTaxonomies) {
            navigate(`${basePath}/curso/criar/imagem/${courseId}`);
            setCurrentStep(2);
          }
          break;
        } else {
          courseForm.setFieldTouched('about', true);
          courseForm.setFieldTouched('knowledge', true);
          courseForm.setFieldTouched('selectedTaxonomies', true);
          courseForm.setFieldTouched('finished-at', true);
          if (!courseForm.errors.about && !courseForm.errors.knowledge && !courseForm.errors.selectedTaxonomies) {
            navigate(`${basePath}/curso/criar/imagem`);
            setCurrentStep(2);
          }
        }
        break;
      case 'imagem':
        courseForm.setFieldTouched('image', true);
        createCourse();
        break;
    }
  };

  const handleChangeImage = e => {
    let file = e.target.files[0];
    setImageLabel(e.target.files[0]?.name);
    setFile(URL.createObjectURL(e.target.files[0]));

    if (file) {
      const reader = new FileReader();
      reader.onload = _handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
    }
  };

  const _handleReaderLoaded = readerEvt => {
    let bynaryString = readerEvt.target.result;
    courseForm.setFieldValue('image', `data:image/jpeg;base64,${btoa(bynaryString)}`);
  };

  const btnsStyles = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '24px'
  };

  return (
    <div className="page page--wrap">
      <div className="page__col">
        <form
          className="form"
          method="post"
          onSubmit={handleSubmit}
        >
          <StepName
            error={error}
            step={step}
            form={courseForm}
          />
          <StepAbout
            step={step}
            form={courseForm}
          />
          <StepImage
            courseId={courseId}
            file={file}
            handleChangeImage={handleChangeImage}
            step={step}
            form={courseForm}
          />

          <div style={btnsStyles}>
            {step !== 'nome' && (
              <button
                type="button"
                className="btn btn--outline btn--wide"
                onClick={() => {
                  navigate(courseId ? `${basePath}/curso/criar/${steps?.[currentStep - 1]?.route}/${courseId}` : `${basePath}/curso/criar/${steps?.[currentStep - 1]?.route}`);
                  setCurrentStep(currentStep - 1);
                }}
              >
                {t('button.previous')}
              </button>
            )}

            <button
              disabled={isSubmitting}
              type="submit"
              className="btn btn--primary btn--wide"
            >
              {step !== 'imagem' ? t('button.next') : t('button.finish')}
            </button>
          </div>
        </form>
      </div>
      <TipsSidebar
        step={step}
        form={courseForm}
      />
    </div>
  );
}
