import React, { useState, useEffect } from 'react';
import Question from './Question';
import Modal from 'app/components/Modal';
import Survey from 'app/components/Survey';
import toast from 'react-hot-toast';
import { useClient } from 'jsonapi-react';
import { navigate } from '@reach/router';
import EmptyState from 'app/components/EmptyState';
import Loader from 'app/components/loader';
import { t } from 'i18next';

export default function Create(props) {
  const { representation, surveyData } = props;
  const client = useClient();

  const [questions, setQuestions] = useState([]);
  const [showSurvey, setShowSurvey] = useState(false);
  const [loading, setLoading] = useState(true);
  const basePath = window.env.REACT_APP_BASE_PATH;

  useEffect(() => {
    if (surveyData.id !== undefined) {
      getSurveyQuestions();
    }
  }, [surveyData]);

  const onCloseSurvey = () => {
    setShowSurvey(false);
  };

  const onOpenSurvey = () => {
    setShowSurvey(true);
  };

  const submitForm = () => {
    createSurveyQuestion();
    setShowSurvey(true);
  };

  const createSurveyQuestion = async () => {
    const myDeepCopy = structuredClone(questions);
    let params = {
      'survey-questions-attributes': myDeepCopy.map(question => {
        question['survey-items-attributes'] = question['survey-items'];
        delete question['survey-items'];
        return question;
      })
    };
    const { error } = await client.mutate(['surveys', surveyData.id], params);
    if (error) {
      toast.error(t('toast.errorCreateQuestion'));
    } else {
      toast.success(t('toast.successCreateQuestions'));
      navigate(`${basePath}/questionario`);
    }
  };

  const deleteQuestion = async question => {
    if (question.id !== undefined) {
      const { error } = await client.delete(`survey_questions/${question.id}`);
      if (error) {
        toast.error(t('toast.errorDeleteQuestion'));
      } else {
        let newQuestions = questions.filter(data => data.id !== question.id);
        setQuestions([...newQuestions]);
      }
    } else {
      let newQuestions = questions.filter(data => data.question !== question.question);
      toast.success(t('toast.successDeleteQuestion'));
      setQuestions([...newQuestions]);
    }
  };

  const getSurveyQuestions = async () => {
    setLoading(true);
    const { data, error } = await client.fetch(`survey_questions?filter[survey_id]=${surveyData.id}`);
    if (error) {
      toast.error('Erro ao buscar perguntas deste questionário em questão.');
    } else {
      const reverseQuestions = data.reverse();
      setQuestions(reverseQuestions);
      setLoading(false);
    }
  };

  const updateCurrentQuestion = (index, question) => {
    let newQuestions = questions;
    newQuestions[index] = question;
    setQuestions([...newQuestions]);
  };

  const addQuestion = () => {
    let newQuestion = { question: '' };
    if (representation === 'estrela') {
      newQuestion['survey-items'] = [
        {
          subtitle: t('nps.stronglyDisagree'),
          rate: 1,
          proposal: ''
        },
        {
          subtitle: t('nps.partiallyDisagree'),
          rate: 2,
          proposal: ''
        },
        {
          subtitle: t('nps.neitherAgreeNorDisagree'),
          rate: 3,
          proposal: ''
        },
        {
          subtitle: t('nps.partiallyAgree'),
          rate: 4,
          proposal: ''
        },
        {
          subtitle: t('nps.stronglyAgree'),
          rate: 5,
          proposal: ''
        }
      ];
    }
    setQuestions([...questions, newQuestion]);
  };

  return (
    <div style={{ flexGrow: '1' }}>
      <div className="u-mt-3 u-mb-5">
        <button
          type="button"
          onClick={addQuestion}
          className="btn btn--primary u-mr-4"
        >
          {t('nps.buttonAddQuestions')}
        </button>
        <button
          type="button"
          className="btn btn--outline"
          onClick={onOpenSurvey}
        >
          {t('nps.buttonFinishView')}
        </button>
      </div>
      {loading && <Loader />}

      {!loading &&
        surveyData &&
        questions.map((question, index) => (
          <Question
            key={index}
            question={question}
            questionNumber={index + 1}
            representation={representation}
            index={index}
            updateCurrentQuestion={updateCurrentQuestion}
            deleteQuestion={deleteQuestion}
          />
        ))}

      {!questions.length > 0 && !loading && (
        <EmptyState
          type="data"
          text={t('emptyState.questionNotCreated')}
        />
      )}

      <Modal
        show={showSurvey}
        onClose={() => setShowSurvey(false)}
        contentClassName="modal__content--p-md"
      >
        {surveyData && (
          <Survey
            questions={questions}
            surveyData={surveyData}
            onClose={onCloseSurvey}
            submitForm={submitForm}
          />
        )}
      </Modal>
    </div>
  );
}
