import React, { useEffect, useState } from 'react';
import { FiArrowLeft, FiArrowRight, FiTrash2, FiClock } from 'react-icons/fi';
import { Link } from '@reach/router';
import { useSession } from 'app/hooks/useSession';
import getAuthorityLevel from 'app/utils/getAuthorityLevel';
import Modal, { SimpleModal } from 'app/components/Modal';
import { useAgenda } from 'app/hooks/useAgenda';
import Task from './Task';
import Loader from 'app/components/loader';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import TextareaAutosize from 'react-autosize-textarea';
import { NewSlotForm } from './NewSlotForm';
import moment, { duration } from 'moment';
import Swal from 'sweetalert2';
import getPermission from 'app/utils/getPermission';
import EmptyState from '../EmptyState';
import { useTranslation } from 'react-i18next';

function Expanded(props) {
  const { t } = useTranslation();
  const { selectedTab, excessTasks, title, filters, myTasks, selectedUser, CourseTasks, ClassroomTasks, StudentTasks, student, setLimitAlertMessage, slotType } = props;

  const { session } = useSession();
  const [show, setShow] = useState(false);
  const currentWeekDay = new Date().getDay();
  const [currentData, setCurrentData] = useState();
  const authorityLevel = getAuthorityLevel(session);
  const [showModal, setShowModal] = useState(false);
  const [currentSlotOriginalBody, setCurrentSlotOriginalBody] = useState(null);
  const [editingSlot, setEditingSlot] = useState();
  const basePath = window.env.REACT_APP_BASE_PATH;

  const {
    currentSelectedWeek,
    currentSlot,
    setCurrentSlot,
    updateSlot,
    deleteSlot,
    checkAccomplishSlot,
    getSprints,
    getSelectedSprintSlots,
    getPreviousSprintSlots,
    hasPreviousSprint,
    hasNextSprintSlots,
    getNextSprintSlots,
    sprints,
    currentSprintIndex,
    updateSlotOrderOnDrop,
    updateMasterSlot,
    deleteMasterSlot,
    loading,
    getMasterSlots,
    weeklyOvertime,
    getStudentTasks,
    weekSlots,
    weekMasterSlots,
    setFilterUser,
    slots,
    masterSlots,
    selectedTask,
    setSelectedTask
  } = useAgenda();

  useEffect(() => {
    if ((!myTasks && CourseTasks) || ClassroomTasks) {
      const filterType = Object.keys(filters.values).includes('course') ? 'course' : 'classroom';
      getMasterSlots(filterType, filters.values[filterType], currentSelectedWeek);
    }
  }, [filters?.values, currentSelectedWeek]);

  useEffect(() => {
    getSprints(); //separar
  }, []);

  useEffect(() => {
    if (authorityLevel === 'student' && sprints) {
      const today = moment();
      const currentSprint = sprints.find(item => {
        //função que criei pra pegar a sprint atual certa
        return today.isBetween(item?.['initial-date'], item?.['end-date']);
      });

      // const indexOfCurrentSprint = sprints.findIndex(item => item.id === currentSprint.id);

      getSelectedSprintSlots(currentSprint?.id);
    }
  }, [sprints]);

  useEffect(() => {
    if (selectedUser) {
      setFilterUser(selectedUser);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (excessTasks || selectedUser) {
      getSprints(student || selectedUser);
      getSelectedSprintSlots(null, student);
    }
  }, [student, selectedUser]);

  useEffect(() => {
    if (StudentTasks) {
      getSprints();
      getStudentTasks(filters, currentSelectedWeek);
    }
  }, [filters?.values, currentSelectedWeek]);

  const getCurrentSlot = (id, day) => {
    if (myTasks || StudentTasks || excessTasks) {
      setCurrentSlot(weekSlots[day].filter(s => s.id === id)[0]);
    } else {
      setCurrentSlot(weekMasterSlots[day].filter(s => s.id === id)[0]);
    }
  };

  const editSlot = weekSlot => {
    setEditingSlot(weekSlot);
    setShowModal(true);
  };

  const DaysOfTheWeek = [
    {
      id: 0,
      nome: t('weekDays.sunday'),
      name: 'sunday'
    },
    {
      id: 1,
      nome: t('weekDays.monday'),
      name: 'monday'
    },
    {
      id: 2,
      nome: t('weekDays.tuesday'),
      name: 'tuesday'
    },
    {
      id: 3,
      nome: t('weekDays.wednesday'),
      name: 'wednesday'
    },
    {
      id: 4,
      nome: t('weekDays.thursday'),
      name: 'thursday'
    },
    {
      id: 5,
      nome: t('weekDays.friday'),
      name: 'friday'
    },
    {
      id: 6,
      nome: t('weekDays.saturday'),
      name: 'saturday'
    }
  ];

  function handleModalTag(currentSlot) {
    switch (currentSlot.activity) {
      case 'learn':
        return t('tasks.typeLearn');
      case 'train':
        return t('tasks.typeTraining');
      default:
        break;
    }
  }

  function handleTag(hours) {
    const intHours = parseInt(hours);
    if (intHours <= 5 || isNaN(intHours)) {
      return 'calendar-dg__tag calendar-dg__tag--success';
    } else if (intHours <= 8) {
      return 'calendar-dg__tag calendar-dg__tag--warning';
    } else {
      return 'calendar-dg__tag calendar-dg__tag--danger';
    }
  }

  function dayHours(dayoftheweek) {
    let totalTime = moment(`1970-01-01 00:00:00`);
    weekSlots[dayoftheweek.name]?.map(slot => {
      const durationSeconds = moment(slot.duration, 'HH:mm').diff(moment().startOf('day'), 'seconds');
      totalTime.add(durationSeconds, 'seconds');
    });
    return totalTime.format('HH');
  }

  const isDroppableDisabled = !(StudentTasks || excessTasks || myTasks);

  const handleDelete = () => {
    Swal.fire({
      title: t('projects.warningDeleteTask'),
      confirmButtonText: t('button.yes'),
      showDenyButton: true,
      denyButtonText: t('button.no'),
      showCloseButton: true
    }).then(res => {
      if (res.isConfirmed) {
        if (myTasks || StudentTasks || excessTasks) {
          deleteSlot(currentSlot.id);
          if (authorityLevel === 'admin') {
            setTimeout(() => getStudentTasks(filters, currentSelectedWeek), 500);
          }
        } else {
          deleteMasterSlot(currentSlot.id);
        }
        setShow(false);
      }
    });
  };

  const disabled = !getPermission('Editar tarefas', 'Tarefas');

  const getType = () => {
    switch (currentSlot?.slotable?.type) {
      case 'Course':
        return 'expanded-calendar__tag--course';
      case 'Classroom':
        return 'expanded-calendar__tag--class';

      default:
        return '';
    }
  };

  return (
    <div className="expanded-calendar">
      {authorityLevel === 'student' && (
        <div className="expanded-calendar__tags">
          <span className="expanded-calendar__tag expanded-calendar__tag--course">{t('tasks.course')}</span>
          <span className="expanded-calendar__tag expanded-calendar__tag--class">{t('tasks.class')}</span>
          <span className="expanded-calendar__tag expanded-calendar__tag--individual">{t('tasks.individual')}</span>
          <span className="expanded-calendar__tag">{t('tasks.dynamic')}</span>
        </div>
      )}

      {title && <h2 className="expanded-calendar__title">{t('tasks.studentTaskTracking')}</h2>}

      <header className="expanded-calendar__header">
        {hasPreviousSprint() && (
          <button
            className="expanded-calendar__btn"
            aria-label="Semana anterior"
            onClick={() => getPreviousSprintSlots()}
          >
            <FiArrowLeft />{' '}
            <span
              className="expanded-calendar__btn-text"
              aria-hidden
            >
              {t('tasks.lastWeek')}
            </span>
          </button>
        )}

        <h2 className="expanded-calendar__selected-week">
          {currentSelectedWeek.sunday.format(t('datesForm.formatDayMonth'))} - {currentSelectedWeek.saturday.format(t('datesForm.formatDayMonth'))}
        </h2>

        {hasNextSprintSlots() && (
          <button
            className="expanded-calendar__btn"
            aria-label="próxima semana"
            onClick={() => getNextSprintSlots()}
          >
            <span
              className="expanded-calendar__btn-text"
              aria-hidden
            >
              {t('tasks.nextWeek')}
            </span>{' '}
            <FiArrowRight />
          </button>
        )}
      </header>

      <div className="expanded-calendar__body">
        {loading ? (
          <div className="expanded-calendar__loader">
            <Loader />
          </div>
        ) : (
          <DragDropContext
            onDragEnd={props =>
              updateSlotOrderOnDrop({
                ...props,
                isDropDisabled: isDroppableDisabled
              })
            }
          >
            {DaysOfTheWeek &&
              DaysOfTheWeek.map((dayoftheweek, i) => {
                const isBeforeToday = moment().isBefore(currentSelectedWeek[dayoftheweek.name].format('YYYY-MM-DD'));
                const isToday = moment().format('YYYY-MM-DD') === currentSelectedWeek[dayoftheweek.name].format('YYYY-MM-DD');
                return (
                  <div className={currentWeekDay === i ? 'expanded-calendar__day current' : 'expanded-calendar__day'}>
                    <h3 className="expanded-calendar__day-header">
                      <span className="expanded-calendar__day-number">{currentSelectedWeek[dayoftheweek.name].format('DD')}</span>
                      <span className={`expanded-calendar__weekday${currentWeekDay === i ? '--current' : ''}`}>{dayoftheweek.nome}</span>
                      {excessTasks && (
                        <div className="u-w-100">
                          <span className={handleTag(dayHours(dayoftheweek))}>
                            <FiClock /> {dayHours(dayoftheweek)}h
                          </span>
                        </div>
                      )}
                    </h3>

                    <Droppable
                      droppableId={dayoftheweek.name.toString()}
                      isDropDisabled={isDroppableDisabled}
                    >
                      {provided => (
                        <div
                          className="expanded-calendar__hours-container"
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {weekMasterSlots &&
                            weekMasterSlots[dayoftheweek.name]?.map((weekMasterSlot, index) => (
                              <Task
                                filters={filters}
                                excessTasks={excessTasks}
                                key={weekMasterSlot.id}
                                weekMasterSlot={weekMasterSlot}
                                index={index}
                                onClick={() => {
                                  getCurrentSlot(weekMasterSlot.id, [dayoftheweek.name]);
                                  setCurrentSlotOriginalBody(weekMasterSlot.body);
                                  setShow(true);
                                }}
                                deleteSlot={() => deleteMasterSlot(weekMasterSlot.id)}
                                StudentTasks={StudentTasks}
                                setShowModal={setShowModal}
                                editSlot={() => editSlot(weekMasterSlot)}
                                selectedTab={selectedTab}
                              />
                            ))}

                          {(myTasks || StudentTasks || excessTasks) &&
                            weekSlots &&
                            weekSlots?.[dayoftheweek.name]?.map((weekSlot, index) => (
                              <Task
                                filters={filters}
                                excessTasks={excessTasks}
                                key={weekSlot.id}
                                weekSlot={weekSlot}
                                index={index}
                                onClick={() => {
                                  getCurrentSlot(weekSlot.id, [dayoftheweek.name]);
                                  setCurrentSlotOriginalBody(weekSlot.body);
                                  setShow(true);
                                }}
                                deleteSlot={() => deleteSlot(weekSlot.id)}
                                StudentTasks={StudentTasks}
                                setShowModal={setShowModal}
                                editSlot={() => editSlot(weekSlot)}
                                selectedTab={selectedTab}
                                myTasks={myTasks}
                              />
                            ))}
                          {provided.placeholder}
                          {getPermission('Criar tarefas', 'Tarefas') && (isBeforeToday || isToday) && (
                            <span
                              className="expanded-calendar__plus-btn"
                              onClick={() => {
                                setCurrentData(currentSelectedWeek[dayoftheweek.name].format('YYYY-MM-DD'));
                                setShowModal(true);
                              }}
                            />
                          )}
                        </div>
                      )}
                    </Droppable>
                  </div>
                );
              })}
          </DragDropContext>
        )}
      </div>
      {masterSlots.length === 0 && slots.length === 0 && !loading && (
        <EmptyState
          type="content"
          title={t('emptyState.tasksTitle')}
          text={t('emptyState.tasksText')}
        />
      )}

      <Modal
        show={showModal}
        onClose={() => {
          setShowModal(false);
          setSelectedTask({});
        }}
      >
        <NewSlotForm
          filters={filters}
          day={currentData}
          sprint_id={sprints[currentSprintIndex]?.id}
          setShowModal={setShowModal}
          setLimitAlertMessage={setLimitAlertMessage}
          selectedTab={selectedTab}
          myTasks={myTasks}
          weeklyOvertime={weeklyOvertime}
          selectedUser={selectedUser}
          StudentTasks={StudentTasks}
          excessTasks={excessTasks}
          editingSlot={editingSlot}
          selectedTask={selectedTask}
        />
      </Modal>

      <SimpleModal
        show={show}
        onClose={() => setShow(false)}
        contentClassName="u-p-0"
        hideCloseButton
      >
        <div className={`calendar-task-modal calendar-task-modal--${currentSlot && currentSlot.activity}`}>
          <label className="calendar-task-modal__header">
            {authorityLevel === 'student' && (
              <input
                disabled={disabled}
                onChange={() => checkAccomplishSlot(currentSlot)}
                type="checkbox"
                name="checkbox"
                id="checkbox"
                defaultChecked={currentSlot?.accomplished}
                className="calendar-task-modal__check-input"
              />
            )}

            <span className="calendar-task-modal__matter">
              <span className={`calendar-task-modal__category calendar-task-modal__category--${currentSlot && currentSlot.activity}`}>{currentSlot && handleModalTag(currentSlot)}</span> {currentSlot && currentSlot.title}
            </span>

            <span className={`expanded-calendar__tag ${getType()} u-ml-auto`}>{currentSlot?.slotable?.title}</span>

            {getPermission('Excluir tarefas', 'Tarefas') && (
              <button
                className="archive-card__btn"
                onClick={() => {
                  handleDelete();
                }}
                type="button"
              >
                <div className="calendar-task-modal__delete-icon">
                  <FiTrash2 />
                </div>
              </button>
            )}
          </label>

          <div className="calendar-task-modal__body">
            <p className="calendar-task-modal__footer-instructions">{t('tasks.titleEditDescription')}</p>

            {currentSlot && (authorityLevel === 'admin' || authorityLevel === 'teacher' || authorityLevel === 'student') ? (
              <TextareaAutosize
                className="calendar-task-modal__description"
                disabled={disabled}
                placeholder={t('textsCommon.placeholderTaskDescription')}
                onChange={e => setCurrentSlot({ ...currentSlot, body: e.target.value })}
                value={currentSlot?.body}
              />
            ) : (
              <span className="calendar-task-modal__matter">{currentSlot?.body}</span>
            )}
          </div>

          <div className="calendar-task-modal__footer">
            {(authorityLevel === 'admin' || authorityLevel === 'teacher') && (
              <>
                {/*<button className="btn btn--small btn--outline">
							Materiais relacionados
						</button>
						<button className="btn btn--small btn--outline">
							Duplicar tarefa
						</button>*/}

                <button
                  disabled={disabled}
                  type="button"
                  className="btn btn--wide btn--small btn--outline"
                  onClick={() => {
                    if (currentSlotOriginalBody !== currentSlot?.body && (myTasks || StudentTasks)) {
                      updateSlot();
                    }
                    if (currentSlotOriginalBody !== currentSlot?.body && !myTasks && !StudentTasks) {
                      updateMasterSlot();
                    }
                    setShow(false);
                  }}
                >
                  {t('button.save')}
                </button>

                <Link
                  to={`${basePath}/minhas-tarefas/${slotType === 'master_slot' ? 'admin' : 'tarefa'}/${currentSlot?.id}`}
                  className="btn btn--link u-ml-auto"
                >
                  mais detalhes
                </Link>
              </>
            )}
          </div>
        </div>
      </SimpleModal>
    </div>
  );
}

export default Expanded;
