import { useTranslation } from 'react-i18next';

import { TopBar } from './TopBar';
import { ShadowContainer } from './ShadowContainer';

import selectCoursePng from '../../images/onboarding/courses/select-course.png';
import viewCertificatesPng from '../../images/onboarding/courses/view-certificates.png';
import filterCoursePng from '../../images/onboarding/courses/filter-course.png';
import overviewCourseCardPng from '../../images/onboarding/courses/overview-course-card.png';
import aboutCoursePng from '../../images/onboarding/courses/about-course.png';
import accessingLessonsPng from '../../images/onboarding/courses/accessing-lessons.png';
import informationSvg from '../../images/onboarding/courses/icon-information.svg';

export function CourseOnboarding() {
  const { t } = useTranslation();

  return (
    <div>
      <TopBar />

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.selectCourseOrCertificates')}</h1>
        <p className="text-option-onboarding">{t('onboarding.selectCourseOrCertificatesDescription')}</p>

        <img
          src={selectCoursePng}
          className="w-auto"
        />
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.viewYourCertificates')}</h1>
        <p className="text-option-onboarding">{t('onboarding.viewYourCertificatesDescription')}</p>

        <ShadowContainer width="70%">
          <img src={viewCertificatesPng} />
        </ShadowContainer>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.filterAndCourseSelect')}</h1>
        <p className="text-option-onboarding">{t('onboarding.filterAndCourseSelectDescription')}</p>

        <ShadowContainer width="fit-content">
          <img src={filterCoursePng} />
        </ShadowContainer>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.courseCardsOverview')}</h1>
        <p className="text-option-onboarding">{t('onboarding.courseCardsOverviewDescription')}</p>

        <ShadowContainer width="70%">
          <img src={overviewCourseCardPng} />
        </ShadowContainer>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.aboutCourse')}</h1>
        <p className="text-option-onboarding">
          <u>{t('onboarding.onSelectCourse')}</u>, {t('onboarding.aboutCourseDescription')}
        </p>

        <ShadowContainer width="70%">
          <img src={aboutCoursePng} />
        </ShadowContainer>
      </div>

      <div className="mb-50">
        <h1 className="title-option-onboarding">{t('onboarding.accessingAndViewingLessons')}</h1>
        <p className="text-option-onboarding mb-0">
          <u>{t('onboarding.whenClickCourseToExpand')}</u>, {t('onboarding.accessingAndViewingLessonsDesc')}
        </p>

        <ul className="onboarding-list">
          <li className="text-option-onboarding">
            <b>{t('onboarding.materialsAndForum')}</b> - {t('onboarding.materialsAndForumDescription')}
          </li>
          <li className="text-option-onboarding">
            <b>{t('onboarding.handouts')}</b> – {t('onboarding.handoutsDescription')}
          </li>
          <li className="text-option-onboarding">
            <b>{t('onboarding.complementaryActivity')}</b> – {t('onboarding.complementaryActivityDescription')}
          </li>
        </ul>

        <ShadowContainer width="70%">
          <img src={accessingLessonsPng} />
        </ShadowContainer>
      </div>

      <div className="container-pay-attention">
        <div className="bg-icon-pay-attention">
          <img
            src={informationSvg}
            className="info-icon"
          />
        </div>

        <div className="text-option-onboarding">
          <h1 className="text-option-onboarding m-0">
            <b>{t('onboarding.payAttentionToTheRequirements')}</b>
          </h1>
          <p className="text-option-onboarding m-0">{t('onboarding.payAttentionToTheRequirementsDescription')}</p>
        </div>
      </div>
    </div>
  );
}
